import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './NavbarScreen.css';
import Menu from '../../../containers/Menu';
import SideBar from '../../../containers/SideBar';
import LanguageSelector from '../../common/LanguageSelector/LanguageSelector';
import logoFull from '../../../assets/images/logo_full.png';
import { withRouter } from 'react-router';
// import { languages } from '../../../i18n/index'
import { I18n } from 'react-redux-i18n';
import { languagesCodes } from '../../../i18n';
import DropdownHelp from '../../common/Dropdown/DropdownHelp';
import Dropdown from '../../common/Dropdown/Dropdown';
import MainModal from '../../common/MainModal';
import Onboarding from '../../common/Onboarding';
import NewIndicator from '../../common/NewIndicator/NewIndicator';
class NavbarScreen extends Component {
  static defaultProps;
  constructor(props) {
    super(props);
    this.state = {
      onBoardingState: false,
    };
  }
  handleLogout = (value, option) => {
    if (option.code === 'logout') {
      this.props.logout();
    }
  };

  showOnBoarding() {
    this.setState({ ...this.state, onBoardingState: true });
    let onBoarding = localStorage.getItem('onBoarding')
      ? localStorage.getItem('onBoarding')
      : localStorage.setItem('onBoarding', 0);

    if (onBoarding <= 2) {
      this.props.showOnBoarding(true);
      localStorage.setItem('onBoarding', parseInt(onBoarding) + 1);
    }
  }
  render() {
    if (
      this.props.from === 'login' &&
      this.state.onBoardingState === false &&
      window.location.pathname === '/main/home'
    ) {
      this.showOnBoarding();
    }
    return (
      <>
        {this.props.onBoardingState && (
          <MainModal
            slides={4}
            onBoardingState={this.props.onBoardingState}
            repeatOnBoardingFunction={this.props.repeatOnBoardingFunction}
            onBoarding={true}
            showOnBoarding={this.props.showOnBoarding}
          >
            <Onboarding language={this.props.language} />
          </MainModal>
        )}
        {/* <div className="nav-brand">
          <img className={'logo-text'} src={logoFull} alt={'logoText'} />
        </div> */}

        <SideBar
          userMenu={
            <div className="left-container">
              {/* <div className={`help ${this.props.user ? " bordered" : ""}`} onClick={this.props.showHelp} /> */}

              <div>
                {window.location.pathname !== '/main/bapro' && (
                  <DropdownHelp showOnBoarding={this.props.showOnBoarding} />
                )}
              </div>

              {/* <div className={`help ${this.props.user ? " bordered" : ""}`} onClick={this.props.showHelp} /> */}
              {this.props.user &&
              (this.props.user.account || this.props.user.branch) ? (
                <div
                  className="user-container"
                  style={{
                    background: `url(${
                      this.props.user.account
                        ? this.props.user.account.logo_url
                        : this.props.user.branch.logo_url
                    })`,
                  }}
                >
                  {(this.props.user.account &&
                    this.props.user.account?.logo_url) ||
                  (this.props.user.branch &&
                    this.props.user.branch?.logo_url) ? (
                    <img
                      className="partner-logo"
                      src={
                        this.props.user.account
                          ? this.props.user.account.logo_url
                          : this.props.user.branch.logo_url
                      }
                      alt=""
                    />
                  ) : (
                    <div className="person-logo">
                      <span className="material-symbols-filled">person</span>
                    </div>
                  )}
                </div>
              ) : (
                <div className="user-container">
                  {(this.props.user.account &&
                    this.props.user.account?.logo_url) ||
                  (this.props.user.branch &&
                    this.props.user.branch?.logo_url) ? (
                    <img
                      className="partner-logo"
                      src={
                        this.props.user.account
                          ? this.props.user.account.logo_url
                          : this.props.user.branch.logo_url
                      }
                      alt=""
                    />
                  ) : (
                    <div className="person-logo">
                      <span className="material-symbols-filled">person</span>
                    </div>
                  )}
                </div>
              )}
              <div>
                <Menu
                  company_name={
                    this.props.user?.account?.name ||
                    this.props.user?.branch?.name ||
                    'Admin'
                  }
                  user_name={this.props.user?.full_name}
                  showUserMenu={
                    this.props.user.groups.includes(9) &&
                    this.props.location.pathname !== '/main/bapro'
                  }
                />
              </div>
            </div>
          }
        />
      </>
    );
  }
}

NavbarScreen.defaultProps = {};
NavbarScreen.propTypes = {
  logout: PropTypes.func,
  showHelp: PropTypes.func,
};

export default withRouter(NavbarScreen);
