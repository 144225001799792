import React, { Component } from 'react';
import './JobsScreen.css';
import 'react-table/react-table.css';
import ReactTable from 'react-table';
import { I18n } from 'react-redux-i18n';
import Button from '../../../components/common/Button';
import { success, error } from '../../../utils/modals';
import { notify } from 'react-notify-toast';
import DeleteJobModal from '../../../components/common/DeleteJobModal';
import Loader from '../../../components/common/Loader/Loader';
import TablePaginationDrawer from '../../../components/common/TablePaginationDrawer/TablePaginationDrawer';
import TableLoader from '../../../components/common/TableLoader/TableLoader';
import { Tabs, Tab } from 'react-bootstrap';
import Dropdown from '../../../components/common/Dropdown';
import Input from '../../../components/common/Input';
import dayjs from 'dayjs';
import DateRangePickerFilter from '../../../components/common/DateRangePickerFilter/DateRangePickerFilter';
import FormDropdown from '../../../components/common/Dropdown/FormDropdown';
import JobDetail from '../../containers/JobDetail';
import {
  errorMessage,
  SnackBar,
  successMessage,
} from '../../../utils/snackbar';
import Profile from '../../../profile/containers/Profile';
import DeleteModal from '../../../components/common/ModalAlert/ModalAlert';
import LargeTextTooltip from '../../../components/common/LargeTextTooltip';
import TableMenuItem from '../../../components/common/TableMenuItem/TableMenuItem';

class JobsScreen extends Component {
  state = {
    jobId: undefined,
    showModal: false,
    jobs: undefined,
    values: {
      search: { inputValue: '' },
      profiles_ids: { inputValue: [] },
      campaigns_ids: { inputValue: [] },
    },
    range: { start: null, end: null },
    jobDateFiltering: false,
  };

  /** Fetch jobs */
  componentDidMount() {
    this.props.unselectCampaign();
    if (this.props.defaultTab !== 1) {
      this.props.updatePageSize(10);
    }
    !(this.isAdmin && this.isSuperDashboard) &&
      this.props.getAllCampaigns({ partners: null, campaign_dropdown: true });
    (this.isAdmin || this.isSuperDashboard || this.props.user?.account?.id) &&
      this.props.getAllClients({
        accountDropdown: true,
        view_branch: true,
        view_account: false,
      });
    this.props.getAllProfiles({ search: '' });
    this.props.updateFilterValues({ search: '' });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.selectedCampaign !== this.props.selectedCampaign &&
      this.props.selectedCampaign.id
    ) {
      this.props.history.push('/main/campaign');
    }
  }

  filterPartnerCampaignsOptions() {
    let options = this.props.allCampaigns.map((campaign) => ({
      code: campaign.id,
      description: campaign.name,
    }));

    return options.filter((n) => n.description != null);
  }

  filterProfilesOptions() {
    let options = this.props.profiles.map((profile) => ({
      code: profile.id,
      description: profile.name,
    }));

    return options.filter((n) => n.description != null);
  }

  removeFilters() {
    this.setState({
      ...this.state,
      search: { inputValue: '' },
      values: {
        search: { inputValue: '' },
        profiles_ids: { inputValue: [] },
        campaigns_ids: { inputValue: [] },
      },
      range: {
        start: '',
        end: '',
      },
      jobDateFiltering: false,
    });
    this.props.updateFilterValues({
      campaign_ids: '',
      end_date: '',
      ordering: 'created',
      page: 1,
      page_size: 10,
      profiles: '',
      start_date: '',
      search: '',
    });
    !(this.isAdmin && this.isSuperDashboard) &&
      this.props.getAllCampaigns({ partners: null, campaign_dropdown: true });
    (this.isAdmin || this.isSuperDashboard || this.props.user?.account?.id) &&
      this.props.getAllClients({
        accountDropdown: true,
        view_branch: true,
        view_account: false,
      });
  }

  handleFiltersChange = async (type, name, option) => {
    let newArray = [...this.state.values[name].inputValue];
    let campaignsValues = this.state.values.campaigns_ids.inputValue;

    if (option.code === -1) return;
    else if (type === 'add') {
      newArray = newArray.concat([option]);
    } else {
      newArray = newArray.filter((opt) => opt.code !== option.code);
    }
    if (name === 'profiles_ids') {
      let partners_ids_filter = newArray.map(({ code }) => code);

      await this.props.getAllCampaigns({
        partners: partners_ids_filter,
        campaign_dropdown: true,
      });

      newArray.sort((profile1, profile2) => {
        return profile2.code - profile1.code;
      });
    }
    this.setState(
      {
        ...this.state,
        values: {
          ...this.state.values,
          campaigns_ids: { inputValue: campaignsValues },
          [name]: {
            ...this.state.values[name],
            inputValue: [...newArray],
          },
        },
      },
      () => this.applyfilter(),
    );
  };

  handleShowHide = (show, id) => {
    this.setState({ ...this.state, showModal: show, jobId: id });
  };

  handleInputChange = (key, value) => {
    this.setState({
      values: {
        ...this.state.values,
        [key]: { ...this.state.values[key], inputValue: value },
      },
    });
  };

  handleSearch = (key, value) => {
    this.setState({
      ...this.state,
      values: {
        ...this.state.values,
        [key]: { ...this.state.values[key], inputValue: value },
      },
    });
    this.searchTimeout = setTimeout(
      function () {
        this.props.updateFilterValues({ search: value });
      }.bind(this),
      300,
    );
  };

  filterByDates = (filter) => {
    this.handleShowHide();
    this.setState(
      {
        ...this.state,
        range: { start: filter.start_range, end: filter.end_range },
        jobDateFiltering: true,
      },
      () => {
        this.props.updateFilterValues({
          start_date: this.state.range.start,
          end_date: this.state.range.end,
        });
      },
    );
  };

  applyfilter = (date_filter = {}) => {
    let filter = {};

    // Custom Filter
    let profiles_ids = [];
    let campaign_ids = [];

    profiles_ids = this.state.values.profiles_ids.inputValue.map(
      (profile) => profile.code,
    );

    campaign_ids = this.state.values.campaigns_ids.inputValue.map(
      (campaign) => campaign.code,
    );
    if (campaign_ids.length === 0) {
      filter = {
        profiles: profiles_ids,
      };
    } else {
      filter = {
        profiles: profiles_ids,
        campaign_ids: campaign_ids,
      };
    }
    // Date filters
    if (date_filter.start_range && date_filter.end_range) {
      this.setState({
        ...this.state,
        range: { start: date_filter.start_range, end: date_filter.end_range },
        executedFilters: true,
      });
      filter['start_range'] = date_filter.start_range;
      filter['end_range'] = date_filter.end_range;
    } else {
      filter['start_range'] = this.state.range.start;
      filter['end_range'] = this.state.range.end;
    }
    this.props.updateFilterValues(filter);
  };

  handleSelect = (e) => {
    if (this.props.defaultTab !== e) {
      if (e === 1) this.props.history.push('/main/tags');
      if (e === 2) this.props.history.push('/main/jobs');
    }
  };

  render() {
    /** Notifications */
    if (this.props.createStatus.success) {
      notify.show(
        <SnackBar
          icon={'priority'}
          title={I18n.t('common.excellent')}
          body={I18n.t('jobs.success.create')}
          type="success"
          iconFilled
        />,
        'custom',
        5000,
        successMessage,
      );
      this.props.clearUiValue('create', 'success');
      this.props.updateFilterValues({
        ordering: 'created',
        page: 1,
        page_size: 10,
        profiles: '',
        campaign_ids: '',
      });
    }

    if (this.props.updateStatus.success) {
      notify.show(
        <SnackBar
          icon={'priority'}
          title={I18n.t('jobs.updatePosition')}
          body={I18n.t('jobs.success.update')}
          type="success"
          iconFilled
        />,
        'custom',
        5000,
        successMessage,
      );
      this.props.clearUiValue('update', 'success');
    }

    if (this.props.deleteStatus.success) {
      notify.show(
        <SnackBar
          icon={'priority'}
          title={I18n.t('jobs.deleteModalTitle')}
          body={I18n.t('jobs.success.delete', {
            name: this.props.selectedJob?.name,
          })}
          type="success"
          iconFilled
        />,
        'custom',
        5000,
        successMessage,
      );
      this.props.clearUiValue('delete', 'success');
    }

    if (this.props.deleteStatus.error) {
      notify.show(
        <SnackBar
          icon={'report'}
          title={I18n.t('jobs.deleteModalTitle')}
          body={I18n.t('jobs.error.delete')}
          type="error"
        />,
        'custom',
        5000,
        errorMessage,
      );
      // notify.show(I18n.t('jobs.error.delete'), 'custom', 5000, error);
      this.props.clearUiValue('delete', 'error');
    }

    return (
      <div className="container-fluid jobs-screen ">
        <DeleteModal
          show={this.state.showModal}
          onHide={() => this.handleShowHide(false)}
          actionCancel={() => this.handleShowHide(false)}
          actionConfirm={() => {
            this.props.deleteJob(this.state.jobId);
          }}
          message={I18n.t('jobs.deleteModalBody', {
            name: this.props.selectedJob?.name,
          })}
          //.replace('X', this.props.selectedJob?.name)}
          title={I18n.t('common.attention')}
          textButtonConfirm={I18n.t('buttons.deleteJob')}
        />
        <Tabs
          defaultActiveKey={this.props.defaultTab || 1}
          id="uncontrolled-tab-example"
          onSelect={this.handleSelect}
          value={this.state.selectedTab}
        >
          <div className={'card-header-right'}>
            {this.props.history.location.pathname === '/main/jobs' && (
              <Button
                className="primary"
                text={
                  <span>
                    <span class="material-symbols-rounded">add</span>
                    {I18n.t('jobs.newPost')}
                  </span>
                }
                onClick={() => this.props.history.push('/main/new-job')}
              />
            )}
          </div>

          <Tab eventKey={1} title={I18n.t('profiles.title')}>
            {this.props.history.location.pathname === '/main/tags' && (
              <Profile />
            )}
          </Tab>
          <Tab eventKey={2} title={I18n.t('jobs.jobs')}>
            {this.props.history.location.pathname === '/main/jobs' &&
              this.renderTable()}
            {/* {this.props.history.location.pathname === '/main/new-job' && (
              <JobDetail />
            )} */}
          </Tab>
        </Tabs>
      </div>
    );
  }

  renderTable() {
    const { loading, success, error } = this.props.jobsStatus;
    let name_language = `name_${localStorage.getItem('language')}`;
    let description_language = `description_${localStorage.getItem(
      'language',
    )}`;
    const { filterValues } = this.props;
    const orderingFilterObj = {
      desc: filterValues.ordering?.length && filterValues.ordering[0] === '-',
      id:
        filterValues.ordering?.length && filterValues.ordering[0] === '-'
          ? filterValues.ordering.slice(1, filterValues.ordering.length)
          : filterValues.ordering,
    };
    let optionsDropdown = (props) => {
      let options = [
        {
          code: 'edit',
          label: (
            <Button
              text={I18n.t('common.edit')}
              iconButton={true}
              iconFilled={true}
              iconClass="edit"
              className="text-link-button normal-button"
              onClick={(e, handleOriginal) => {
                if (handleOriginal) handleOriginal();
                this.props.selectJob(props.original.id);
                this.props.history.push('/main/job');
              }}
            />
          ),
          disabled: false,
        },
        {
          code: 'delete',
          label: (
            <Button
              text={I18n.t('common.delete')}
              iconFilled={true}
              iconButton={true}
              iconClass="delete"
              className="text-link-button delete-button user-screen"
              onClick={(e, handleOriginal) => {
                if (handleOriginal) handleOriginal();
                this.props.selectJob(props.original.id);
                this.setState({
                  ...this.state,
                  jobId: props.original.id,
                  showModal: true,
                });
              }}
            />
          ),
          disabled: false,
        },
      ];

      if (this.props.user.branch) {
        options.unshift({
          code: 'create campaign',
          label: (
            <Button
              text={I18n.t('campaigns.createCampaign')}
              iconButton={true}
              iconClass="campaign"
              iconFilled={true}
              className="text-link-button normal-button"
              onClick={(e, handleOriginal) => {
                if (handleOriginal) handleOriginal();
                this.props.selectJob(props.original.id);
                this.props.history.push(
                  `/main/new-campaign?id_position=${props.original.id}`,
                );
              }}
            />
          ),
          disabled: false,
        });
      }
      return options;
    };
    if (error)
      return <div className="text-warning">{I18n.t('jobs.error.list')}</div>;
    if (success || loading)
      return (
        <>
          {this.props.history.location.pathname === '/main/jobs' && (
            <div className="filters-container">
              <div className="left-side">
                <div className={'search-bar'}>
                  <div className="bar-filter-container">
                    <Input
                      name="search"
                      value={this.state.values.search}
                      placeholder={I18n.t('persons.search')}
                      change={this.handleSearch}
                      iconClass={'search'}
                      iconInput={true}
                      className={'input-search'}
                    />
                  </div>
                </div>
                <DateRangePickerFilter
                  rangeDefault={{
                    start: dayjs(),
                    end: dayjs().subtract(1, 'month'),
                  }}
                  previewRange={{
                    start: this.state.range.start || dayjs(),
                    end: this.state.range.end || dayjs().subtract(1, 'month'),
                  }}
                  classNameButton="date-person"
                  filter={this.filterByDates}
                  jobDateFiltering={this.state.jobDateFiltering}
                  start_date={
                    this.state.range.start || this.props.jobs?.length
                      ? dayjs(this.props.jobs[0]?.created).subtract(1, 'month')
                      : dayjs().subtract(1, 'month')
                  }
                  end_date={
                    this.state.range.end || this.props.jobs?.length
                      ? this.props.jobs[0]?.created
                      : dayjs()
                  }
                  hiddenPopup={true}
                />
                <FormDropdown
                  name={'profiles_ids'}
                  placeholder={I18n.t('jobs.profile')}
                  multiSelect={true}
                  change={this.handleInputChange}
                  search={this.props.getAllProfiles}
                  addOption={this.handleFiltersChange}
                  value={this.state.values.profiles_ids}
                  options={this.filterProfilesOptions()}
                  loading={this.props.getAllCampaignsStatus.loading}
                  showEmptyOption
                  getAllClientsPrompts={{}}
                  cleanOption={true}
                  cleanSelected={() =>
                    this.setState(
                      {
                        ...this.state,
                        values: {
                          ...this.state.values,
                          profiles_ids: { inputValue: [] },
                        },
                      },
                      () => this.handleFiltersChange(null, 'profiles_ids', ''),
                    )
                  }
                />
                <FormDropdown
                  name={'campaigns_ids'}
                  placeholder={I18n.t('campaigns.identifier')}
                  multiSelect={true}
                  change={this.handleInputChange}
                  search={this.props.getAllCampaigns}
                  addOption={this.handleFiltersChange}
                  value={this.state.values.campaigns_ids}
                  options={this.filterPartnerCampaignsOptions()}
                  loading={this.props.getAllCampaignsStatus.loading}
                  showEmptyOption
                  getAllClientsPrompts={{
                    partners:
                      this.state.values.campaigns_ids.inputValue.flatMap(
                        (item) => {
                          return item.code;
                        },
                      ),
                    campaign_dropdown: true,
                  }}
                  cleanOption={true}
                  cleanSelected={() =>
                    this.setState(
                      {
                        ...this.state,
                        values: {
                          ...this.state.values,
                          campaigns_ids: { inputValue: [] },
                        },
                      },
                      () => this.handleFiltersChange(null, 'campaigns_ids', ''),
                    )
                  }
                />
              </div>
              <div className="delete-button-container">
                <Button
                  className="text-link-button delete-button"
                  text={I18n.t('buttons.jobsClearFilter')}
                  onClick={() => this.removeFilters()}
                  iconButton={true}
                  iconClass={'close'}
                  disabled={
                    this.state.values.campaigns_ids.inputValue.length < 1 &&
                    this.state.values.profiles_ids.inputValue.length < 1 &&
                    this.state.values.search.inputValue === '' &&
                    [null, ''].includes(this.state.range.start) &&
                    [null, ''].includes(this.state.range.end)
                  }
                />
              </div>
            </div>
          )}
          <div className="table">
            {this.props?.jobs?.map((c) => (
              <>
                <LargeTextTooltip
                  id={'name-job-' + c.id}
                  idToOverflow={'name-job' + c.id}
                />
              </>
            ))}
            <ReactTable
              columns={[
                {
                  Header: () => {
                    return this.props.ordering === '-name' ? (
                      <div className="header-container">
                        <span>{I18n.t('jobs.name')}</span>
                        <span class="material-symbols-rounded">
                          arrow_drop_up
                        </span>
                      </div>
                    ) : (
                      <div className="header-container">
                        <span>{I18n.t('jobs.name')}</span>
                        <span class="material-symbols-rounded">
                          arrow_drop_down
                        </span>
                      </div>
                    );
                  },
                  headerClassName: 'wordwrap',
                  accessor: (d) => d,
                  id: 'name',
                  Cell: (props) => {
                    return (
                      <span
                        className="number"
                        data-tooltip-content={props.value.name}
                        data-tooltip-id={'name-job-' + props.original.id}
                        id={'name-job' + props.original.id}
                      >
                        {props.value.name}
                      </span>
                    );
                  },
                },
                {
                  Header: () => {
                    return this.props.ordering ===
                      '-reference_profile__name' ? (
                      <div className="header-container">
                        <span>{I18n.t('jobs.profile')}</span>
                        <span class="material-symbols-rounded">
                          arrow_drop_up
                        </span>
                      </div>
                    ) : (
                      <div className="header-container">
                        <span>{I18n.t('jobs.profile')}</span>
                        <span class="material-symbols-rounded">
                          arrow_drop_down
                        </span>
                      </div>
                    );
                  },
                  headerClassName: 'wordwrap',
                  accessor: (d) => d.profile[name_language] || '',
                  id: 'reference_profile__name',
                  Cell: (props) => (
                    <span className="number">{props.value}</span>
                  ),
                },
                {
                  Header: () => {
                    return this.props.ordering === '-created' ? (
                      <div className="header-container">
                        <span>{I18n.t('post.created')}</span>
                        <span class="material-symbols-rounded">
                          arrow_drop_up
                        </span>
                      </div>
                    ) : (
                      <div className="header-container">
                        <span>{I18n.t('post.created')}</span>
                        <span class="material-symbols-rounded">
                          arrow_drop_down
                        </span>
                      </div>
                    );
                  },
                  headerClassName: 'wordwrap created-date-header',
                  className: 'created-date-cell',
                  accessor: (d) => d.created || '',
                  id: 'created',
                  Cell: (props) =>
                    props.value ? (
                      <span className="number">
                        {dayjs(props.value).format('DD/MM/YYYY')}
                      </span>
                    ) : (
                      '-'
                    ),
                },
                {
                  Header: (
                    <div className="header-container">
                      <span>{I18n.t('jobs.behavior')}</span>
                    </div>
                  ),
                  headerClassName: 'wordwrap',
                  id: 'reference_profile__description',
                  sortable: false,
                  accessor: (d) => d.profile[description_language] || '',
                  Cell: (props) => (
                    <span className="number">{props.value}</span>
                  ),
                },
                {
                  Header: () => {
                    return this.props.ordering === '-campaign' ? (
                      <div className="header-container">
                        <span>{I18n.t('clients.campaign')}</span>
                        <span class="material-symbols-rounded">
                          arrow_drop_up
                        </span>
                      </div>
                    ) : (
                      <div className="header-container">
                        <span>{I18n.t('clients.campaign')}</span>
                        <span class="material-symbols-rounded">
                          arrow_drop_down
                        </span>
                      </div>
                    );
                  },
                  headerClassName: 'wordwrap',
                  className: 'job-campaign-cell',
                  // className: `${props.value.length ? '' : ' empty-center'}`,
                  accessor: (d) => d.nwm_campaign || '',
                  id: 'campaign',
                  Cell: (props) => (
                    <div
                      className={`campaigns-container ${
                        props.value.length ? '' : 'empty-center'
                      }`}
                    >
                      <div className="campaigns-name">
                        {props.value.length ? (
                          <>
                            <div className={`all-campaigns-container`}>
                              {props.value.map((campaign, i) => (
                                <div
                                  className={`campaigns-name-container ${
                                    i > 1 && 'all-campaigns'
                                  }`}
                                  key={i}
                                >
                                  <span
                                    className="number"
                                    onClick={() => {
                                      this.props.getCampaigns(campaign.id);
                                      // this.props.history.push('/main/campaign');
                                    }}
                                  >
                                    {campaign.name}
                                  </span>
                                </div>
                              ))}
                            </div>
                            <div>
                              {props.value.length - 2 > 0 && (
                                <span className="others-campaigns">
                                  + {props.value.length - 2}
                                </span>
                              )}
                            </div>
                          </>
                        ) : (
                          '-'
                        )}
                      </div>
                    </div>
                  ),
                },
                {
                  Header: '',
                  headerClassName: 'wordwrap dropdown-options',
                  id: 'delete',
                  sortable: false,
                  className: 'dropdown-options',
                  width: 170,
                  accessor: (d) => d.id,
                  Cell: (props) => {
                    return (
                      <TableMenuItem>
                        <Dropdown
                          value={{
                            inputValue: {
                              code: '1',
                              label: (
                                <span class="material-symbols-rounded">
                                  more_vert
                                </span>
                              ),
                            },
                          }}
                          className="users-dropdown"
                          noBorder
                          change={() => props.value}
                          options={optionsDropdown(props)}
                        />
                      </TableMenuItem>
                    );
                  },
                },
              ]}
              data={this.props.jobs}
              manual
              minRows={0}
              minWith={5}
              resizable={false}
              noDataText={I18n.t('jobs.table.noRows')}
              defaultSorted={[
                {
                  id: 'company',
                  desc: false,
                },
              ]}
              sorted={[
                {
                  id: orderingFilterObj.id,
                  desc: orderingFilterObj.desc,
                },
              ]}
              // TODO: ReactTable server-side sorting
              onSortedChange={(newSorted, column, shiftKey) => {
                let filter = column.id;
                this.props.updateFilterValues({
                  ordering: (newSorted[0].desc ? '-' : '') + filter,
                });
              }}
              getTrProps={(state, rowInfo, column, instance) => {
                return {
                  // onClick: (e, handleOriginal) => {
                  //   if (handleOriginal) {
                  //     handleOriginal();
                  //   }
                  //   this.props.selectJob(rowInfo.original.id);
                  // },
                  style: {
                    cursor: 'pointer',
                  },
                };
              }}
              manualPagination
              pageSize={this.props.pageSize}
              PaginationComponent={() => {
                const {
                  currentPage,
                  updatePage,
                  updatePageSize,
                  totalPages,
                  pageSize,
                  totalCount,
                } = this.props;
                const isOnFirstPage = currentPage === 1;
                const isOnLastPage = currentPage === totalPages;
                return (
                  <TablePaginationDrawer
                    data={{
                      totalCount,
                      currentPage,
                      pageSize,
                      isOnFirstPage,
                      isOnLastPage,
                      updatePage,
                      updatePageSize,
                      totalPages,
                    }}
                    table={'jobs'}
                  />
                );
              }}
              loading={loading}
              LoadingComponent={TableLoader}
            />
          </div>
        </>
      );
    return <Loader />;
  }

  /** Update page if navigating outside jobs screen */
  componentWillUnmount() {
    const nextLocation = this.props.history.location.pathname;
    if (
      nextLocation !== '/main/job' &&
      nextLocation !== '/main/new-job' &&
      nextLocation !== '/'
    ) {
      this.props.updatePage(1);
    }
  }
}

export default JobsScreen;
