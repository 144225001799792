import React, { Component } from 'react';
import './DemographicFilter.css';
import Button from '../../../components/common/Button';
import { I18n } from 'react-redux-i18n';
import { Map, TileLayer, Marker } from 'react-leaflet';

class DemographicFilter extends Component {
  state = {
    filerApplied: false,
    markerLatLng: null,
  };

  handleAddMarker = (e) => {
    this.setState({ markerLatLng: e.latlng });
  };

  render() {
    return (
      <div className="container-fluid demographic-filter">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header">
                <div className="back">
                  <Button
                    className={'bg-primary text-white p-0'}
                    iconButton={true}
                    iconClass={'fa fa-arrow-left fa-lg'}
                    onClick={() => this.props.history.push('/main/persons')}
                  />
                  <p>{I18n.t('persons.demographicFilters')}</p>
                </div>
              </div>
              <div className="card-map">
                <div className={'row'}>
                  <div className={'col-lg-8'}>
                    {this.renderMap()}
                    <div className={'floating-button-wrapper'}>
                      <Button
                        className={'bg-primary text-white no-border'}
                        text={I18n.t('persons.applyFilter')}
                        disabled={!this.state.markerLatLng}
                        onClick={() => {
                          this.setState({ ...this.state, filterApplied: true });
                          this.props.demographicFilter(this.state.markerLatLng);
                        }}
                      />
                    </div>
                  </div>
                  <div className={'col-lg-4'}>
                    {this.state.filterApplied ? (
                      <div>
                        {this.props.demoFilterStatus.error && (
                          <span className="text-warning">
                            {I18n.t('persons.error.getFilter')}
                          </span>
                        )}
                        {this.props.demoFilterStatus.success &&
                          this.props.results.length < 1 && (
                            <span className="text-warning">
                              {I18n.t('persons.noResults')}
                            </span>
                          )}
                        {this.props.demoFilterStatus.success &&
                          this.props.results.length >= 1 && (
                            <div>
                              <span className="result-title">
                                {I18n.t('persons.closestCandidatesRes')}:
                              </span>
                              <ul className="demo-filter-list">
                                {/* {this.props.results.map((person) => <li>{person.first_name}</li>)} */}
                                {this.props.results
                                  .sort((a, b) => {
                                    return a.distance - b.distance;
                                  })
                                  .slice(0, 9)
                                  .map((person) => (
                                    <li className={'demo-filter-item'}>
                                      <div>
                                        {person.first_name} {person.last_name}
                                      </div>
                                      <div className="distance">
                                        {' '}
                                        {person.distance.toFixed(1)} km
                                      </div>
                                    </li>
                                  ))}
                              </ul>
                            </div>
                          )}
                      </div>
                    ) : (
                      <p className="result-title">
                        {I18n.t('persons.closestCandidates')}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderMap() {
    return (
      <Map
        center={[-34.4565578, -58.8611673]}
        minZoom={1.5}
        zoom={1.5}
        maxZoom={18}
        onClick={this.handleAddMarker}
        style={{ minHeight: 500 }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        {this.state.markerLatLng && (
          <Marker position={this.state.markerLatLng} />
        )}
      </Map>
    );
  }
}

DemographicFilter.defaultProps = {};

export default DemographicFilter;
