import React, { Component } from 'react';
import LargeTextTooltip from '../LargeTextTooltip';
import './OptionalField.sass';

export class OptionalField extends Component {
  render() {
    const {
      material_symbol,
      title,
      value,
      type,
      renderStyle,
      disabled,
      iconFilled,
    } = this.props;
    return (
      <div
        className={`option-field-container card ${type} ${
          !disabled && 'disabled'
        }`}
      >
        <div className={`title-wrapper ${type} ${renderStyle}`}>
          <div className="icon-container">
            <span
              className={`material-symbols-rounded ${type} ${
                iconFilled && 'material-symbols-filled'
              }`}
            >
              {material_symbol}
            </span>
          </div>
          <div className={`title-container ${type}`}>
            <h3 className={`title ${type}`}>{title}</h3>
          </div>
        </div>
        <LargeTextTooltip
          id="referenced-job-field"
          idToOverflow="value-container-reference-job"
        />
        <div
          className={`option-field-body-container ${type}`}
          id={`value-container-reference-job`}
        >
          <span
            className={`value-container ${type} ${renderStyle}`}
            data-tooltip-id="referenced-job-field"
            data-tooltip-content={value}
          >
            {value}
          </span>
        </div>
      </div>
    );
  }
}

export default OptionalField;
