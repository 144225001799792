import { I18n } from 'react-redux-i18n';
import './NewIndicator.sass';
const NewIndicator = () => {
  return (
    <div className="newIndicator">
      <span className="material-symbols-rounded material-symbols-filled">
        auto_awesome
      </span>
      <h6 className="text">{I18n.t('common.new')}!</h6>
    </div>
  );
};

export default NewIndicator;
