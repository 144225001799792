import { connect } from 'react-redux';
import actions from '../../actions/index';
import PersonDetailScreen from '../components/PersonDetailScreen/PersonDetailScreen';
import { withRouter } from 'react-router';

const mapDispatchToProps = (dispatch) => ({
  getPerson: (id) => dispatch(actions.persons.selectPerson(id)),
  updatePerson: (id, person, userid, user) =>
    dispatch(
      actions.persons.updatePerson(id, { ...person }, userid, { ...user }),
    ),
  createPerson: (body) => dispatch(actions.persons.createPerson(body)),
  deletePerson: (id) => dispatch(actions.persons.deletePerson(id)),
  getCountries: () => dispatch(actions.persons.getCountries()),
  getCities: (country_id) => dispatch(actions.persons.getCities(country_id)),
  clearUiValue: (type, condition) =>
    dispatch(actions.persons.clearUiValue(type, condition)),
  getProfile: (id) => dispatch(actions.persons.getProfile(id)),
  getCampaigns: (id) => dispatch(actions.campaigns.reloadSelectedCampaign(id)),
  // getCampaign: (campaign_id) =>
  //   dispatch(actions.campaigns.getCampaign(campaign_id)),
  selectCampaign: (id) => dispatch(actions.campaigns.selectCampaign(id, true)),
  sendPersonReportEmail: (values) =>
    dispatch(actions.persons.sendPersonReportEmail(values)),
  downloadProfileReport: (id, reportType, reportFolder) =>
    dispatch(actions.persons.downloadProfileReport(id, reportType, reportFolder)),
  getUserPlayerInformation: (id_invitation) =>
    dispatch(actions.persons.getUserPlayerInformation(id_invitation)),
  addNote: (body) => dispatch(actions.persons.addNote(body)),
  updateNote: (body) => dispatch(actions.persons.updateNote(body)),
  deleteNote: (body) => dispatch(actions.persons.deleteNote(body)),
  getNotes: (id_user_player) =>
    dispatch(actions.persons.getNotes(id_user_player)),
  addCV: ({ id_user_player, curriculum }) =>
    dispatch(actions.persons.addCV({ id_user_player, curriculum })),

  // updateCV: ({ id, curriculum }) =>
  //   dispatch(actions.persons.updateCV({ id, curriculum })),

  deleteAttached: (id_user_player, fileId) => {
    dispatch(actions.persons.deleteAttached(id_user_player, fileId));
  },

  getCV: (id_user_player) => dispatch(actions.persons.getCV(id_user_player)),
  clearCV: () => dispatch(actions.persons.clearCV()),
  shareFile: ({ id_invitation, sendCv, sendNotes, emails }) =>
    dispatch(
      actions.persons.shareFile({ id_invitation, sendCv, sendNotes, emails }),
    ),
  addIeInvitation: ({ id_campaign, email, id_invitation, lang }) =>
    dispatch(
      actions.persons.addIeInvitation({
        id_campaign,
        email,
        id_invitation,
        lang,
      }),
    ),
});

const mapStateToProps = (state, ownProps) => ({
  users: state.persons.users,
  createStatus: state.persons.create,
  retrieveStatus: state.persons.retrieve,
  updateStatus: state.persons.update,
  updateUserStatus: state.persons.userUpdate,
  deleteStatus: state.persons.delete,
  person: state.persons.person,
  countries: state.persons.countries,
  cities: state.persons.cities,
  currentProfile: state.persons.currentProfile,
  user: state.session.user,
  language: state.i18n.locale,
  locale: state.i18n.locale,
  sendPersonReportEmailStatus: state.persons.sendPersonReportEmailStatus,
  shareFileStatus: state.persons.shareFileStatus,
  userPlayerInformation: state.persons.userPlayerInformation,
  notes: state.persons.notes,
  deleteNoteStatus: state.persons.deleteNoteStatus,
  attachedFiles: state.persons.attached_files,
  attachedFilesDeleted: state.persons.attachedFilesStatus,
  getCVStatus: state.persons.getCVStatus,
  addCVStatus: state.persons.addCVStatus,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(PersonDetailScreen));
