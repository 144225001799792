export const GET_TAGS = 'GET TAGS';
export const GET_TAGS_RESPONSE = 'GET TAGS RESPONSE';
export const GET_TAGS_ERROR = 'GET TAGS ERROR';

export const GET_PROFILES = 'GET PROFILES';
export const GET_PROFILES_RESPONSE = 'GET PROFILES RESPONSE';
export const GET_PROFILES_ERROR = 'GET PROFILES ERROR';

/* export const GET_ALL_PROFILES = 'GET ALL PROFILES';
export const GET_ALL_PROFILES_RESPONSE = 'GET ALL PROFILES RESPONSE';
export const GET_ALL_PROFILES_ERROR = 'GET ALL PROFILES ERROR'; */

export const GET_PROFILE_SKILLS = 'GET PROFILE SKILLS';
export const GET_PROFILE_SKILLS_RESPONSE = 'GET PROFILE SKILLS RESPONSE';
export const GET_PROFILE_SKILLS_ERROR = 'GET PROFILE SKILLS ERROR';

export const GET_PROFILE_SKILLS_RANGE = 'GET PROFILE SKILLS RANGE';
export const GET_PROFILE_SKILLS_RANGE_RESPONSE =
  'GET PROFILE SKILLS RANGE RESPONSE';
export const GET_PROFILE_SKILLS_RANGE_ERROR = 'GET PROFILE SKILLS RANGE ERROR';

export const GET_ALL_PROFILE_SKILLS_RANGE = 'GET ALL PROFILE SKILLS RANGE';
export const GET_ALL_PROFILE_SKILLS_RANGE_RESPONSE =
  'GET ALL PROFILE SKILLS RANGE RESPONSE';
export const GET_ALL_PROFILE_SKILLS_RANGE_ERROR =
  'GET ALL PROFILE SKILLS RANGE ERROR';

export const ADD_SKILL = 'ADD SKILL';
export const REMOVE_SKILL = 'REMOVE SKILL';

export const CLEAR_UI_VALUE = 'CLEAR UI VALUE';
export const LOGOUT = 'LOGOUT';

export const profile = {
  getTags: () => ({ type: GET_TAGS }),
  getTagsResponse: (response) => ({ type: GET_TAGS_RESPONSE, response }),
  getTagsError: (error) => ({ type: GET_TAGS_ERROR, response: error }),

  getProfiles: (tags) => ({ type: GET_PROFILES, tags }),
  getProfilesResponse: (response) => ({
    type: GET_PROFILES_RESPONSE,
    response,
  }),
  getProfilesError: (error) => ({ type: GET_PROFILES_ERROR, response: error }),

  /* getAllProfiles: () => ({ type: GET_ALL_PROFILES }),
  getAllProfilesResponse: (response) => ({
    type: GET_ALL_PROFILES_RESPONSE,
    response,
  }),
  getAllProfilesError: (error) => ({
    type: GET_ALL_PROFILES_ERROR,
    response: error,
  }), */

  getProfileSkills: () => ({ type: GET_PROFILE_SKILLS }),
  getProfileSkillsResponse: (response) => ({
    type: GET_PROFILE_SKILLS_RESPONSE,
    response,
  }),
  getProfileSkillsError: (error) => ({
    type: GET_PROFILE_SKILLS_ERROR,
    response: error,
  }),

  getProfileSkillsRange: (skills, excel) => {
    return {
      type: GET_PROFILE_SKILLS_RANGE,
      skills,
      excel,
    };
  },
  getProfileSkillsRangeResponse: (response) => ({
    type: GET_PROFILE_SKILLS_RANGE_RESPONSE,
    response,
  }),
  getProfileSkillsRangeError: (error) => ({
    type: GET_PROFILE_SKILLS_RANGE_ERROR,
    response: error,
  }),

  getAllProfileSkillsRange: (skills) => ({
    type: GET_ALL_PROFILE_SKILLS_RANGE,
    skills: skills,
  }),
  getAllProfileSkillsRangeResponse: (response) => ({
    type: GET_ALL_PROFILE_SKILLS_RANGE_RESPONSE,
    response,
  }),
  getAllProfileSkillsRangeError: (error) => ({
    type: GET_ALL_PROFILE_SKILLS_RANGE_ERROR,
    response: error,
  }),

  addSelectedSkill: () => ({ type: ADD_SKILL }),
  removeSelectedSkill: () => ({ type: REMOVE_SKILL }),

  clearUiValue: (type, condition) => ({
    type: CLEAR_UI_VALUE,
    op_type: type,
    condition: condition,
  }),
  logout: () => ({ type: LOGOUT }),
};
