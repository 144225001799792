import ClientsScreen from '../components/ClientsScreen/ClientsScreen';
import actions from '../../actions/index';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

const mapDispatchToProps = (dispatch) => ({
  getClient: (id) => dispatch(actions.clients.getClient(id)),
  getClients: (view_branch, search, filterDates, current, client_type) =>
    dispatch(
      actions.clients.getClients(
        view_branch,
        search,
        filterDates,
        current,
        client_type,
      ),
    ),
  getCreditsHistory: () => dispatch(actions.common.getCreditsHistory()),
  getFilterDates: (filterDates) =>
    dispatch(actions.common.getFilterDates(filterDates)),
  getCreditsByName: () => dispatch(actions.common.getCreditsHistory()),
  selectClient: (id) => dispatch(actions.clients.selectClient(id)),
  updatePage: (page) => dispatch(actions.clients.updatePage(page)),
  updatePageSize: (pageSize) =>
    dispatch(actions.clients.updatePageSize(pageSize)),
  updateFilterValues: (filter) =>
    dispatch(actions.clients.updateFilterValues(filter)),
  updateCommonFilterValues: (filter) =>
    dispatch(actions.common.updateFilterValues(filter)),
  filterClients: (filter) => dispatch(actions.clients.getClients(filter)),
  clearUiValue: (type, condition) =>
    dispatch(actions.clients.clearUiValue(type, condition)),
  deleteClient: (id) => dispatch(actions.clients.deleteClient(id)),
  getClientTypes: () => dispatch(actions.clients.getClientTypes()),
  setCreditFilterClear: (value) =>
    dispatch(actions.common.setCreditFilterClear(value)),
  getBranchs: (account_id) => dispatch(actions.clients.getBranchs(account_id)),
  setCreditOptionValues: (state) =>
    dispatch(actions.common.setCreditOption(state)),
  getCampaigns: () => dispatch(actions.campaigns.getCampaigns()),
  unselectCampaign: () => dispatch(actions.campaigns.unselectCampaign()),
  getCreditsHistory: () => dispatch(actions.common.getCreditsHistory()),
  getClientsScreen: (screen) =>
    dispatch(actions.clients.getClientsScreen(screen)),
  backFromDetail: (to) => dispatch(actions.clients.backFromDetail(to)),
  updateViewBranch: (bool) => dispatch(actions.clients.updateViewBranch(bool)),
  cleanTable: () => dispatch(actions.clients.cleanTable()),
  updateCreditsWidgets: () => {
    dispatch(actions.dashboard.updateCreditsWidgets());
  },
});

const mapStateToProps = (state) => ({
  clients: state.clients.clients,
  clientTypes: state.clients.clientTypes,
  typeSelection: state.clients.typeSelection,
  campaigns: state.campaigns.results,
  getCampaignsStatus: state.campaigns.getCampaignsStatus,
  selectedCampaign: state.campaigns.selectedCampaign,
  totalCount: state.clients.count,
  currentPage: state.clients.current,
  totalPages: state.clients.total_pages,
  pageSize: state.clients.page_size,
  desc: state.clients.desc,
  filterDates: {
    start_date: state.dashboard.start_date,
    end_date: state.dashboard.end_date,
  },
  filterValues: state.campaigns.filterValues,
  filterClientValues: state.clients.filterValues,
  user: state.session.user,
  view_branch_global: state.clients.view_branch,
  getClientsStatus: state.clients.getClientsStatus,
  createClientStatus: state.clients.createClientStatus,
  updateClientStatus: state.clients.updateClientStatus,
  deleteClientStatus: state.clients.deleteClientStatus,
  selectedClient: state.clients.selectedClient,
  credits_user_status: state.clients.credits_user_status,
  credits_account_status: state.clients.credits_account_status,
  createUserStatus: state.users.createFromPartner,
  customFilter: state.campaignsMigrations.customFilter,
  start_range: state.dashboard.start_date,
  end_range: state.dashboard.end_date,
  dateStatus: state.dashboard.dateStatus,
  creditFilterClear: state.common.creditFilterClear,
  creditOptionValues: state.common.creditOptionValues,
  commonFilterValues: state.common.filterValues,
  clients_screen: state.clients.clients_screen,
  back_from_detail: state.clients.back_from_detail,
  dateFromCreditsHistory: state.common.filterDates,
  language: state.i18n.locale,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ClientsScreen));
