import React, { Component } from 'react';
import { I18n } from 'react-redux-i18n';
import dayjs from 'dayjs';
import './PlayingStatus.sass';

export class PlayingStatus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      playTime: '',
      uniqueTooltip: `tooltipBadge_${Math.random()}`,
    };
  }

  handleClick = (e) => {
    if (this.props.status === 'playing') {
      return this.setState({
        playTime: dayjs(
          dayjs(this.props?.redemptionDate).add(60, 'minutes') - dayjs(),
        ).minute(),
      });
    }
  };

  render() {
    return (
      <div className="badge-container dropdown">
        <span
          className={`playing-badge ${this.props.status} dropdown-toggle`}
          onMouseDown={this.handleClick}
          id={this.state.uniqueTooltip}
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="true"
        >
          <span className="material-symbols-filled icon">psychology</span>
          {I18n.t(`campaigns.badge.${this.props.status}`)}
        </span>
        <ul
          class="dropdown-menu tooltip-container"
          aria-labelledby={this.state.uniqueTooltip}
        >
          <li className="tooltip-box">
            <span className="icon material-symbols-rounded">privacy_tip</span>
            <p className="tooltip-text">
              {I18n.t(`campaigns.badge.${this.props.status}Tooltip`, {
                badgeMin: this.state.playTime,
              })}
            </p>
          </li>
        </ul>
      </div>
    );
  }
}

export default PlayingStatus;
