import { connect } from 'react-redux';
import ActivateScreen from '../components/ActivateScreen/ActivateScreen';
import { withRouter } from 'react-router';
import actions from '../../actions/index';

const mapStateToProps = (state) => ({
  activationState: state.session.activationState,
});

const mapDispatchToProps = (dispatch) => ({
  activationUser: (token) => dispatch(actions.session.activateRequest(token)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ActivateScreen));
