export const UPLOAD_FILE = 'UPLOAD_FILE';
export const UPLOAD_FILE_RESPONSE = 'UPLOAD_FILE_RESPONSE';
export const UPLOAD_FILE_ERROR = 'UPLOAD_FILE_ERROR';
export const GET_BAPRO_FILES = 'GET_BAPRO_FILES';
export const GET_BAPRO_FILES_RESPONSE = 'GET_BAPRO_FILES_RESPONSE';
export const GET_BAPRO_FILES_ERROR = 'GET_BAPRO_FILES_ERROR';
export const RESET_BAPRO_FILES = 'RESET_BAPRO_FILES';

export const bapro = {
  uploadFile: (body) => ({ type: UPLOAD_FILE, body }),
  uploadFileResponse: (response) => ({ type: UPLOAD_FILE_RESPONSE, response }),
  uploadFileError: (error) => ({ type: UPLOAD_FILE_ERROR, error }),
  getBaproFiles: ({ id_user_player, partner }) => ({
    type: GET_BAPRO_FILES,
    id_user_player: id_user_player,
    partner: partner,
  }),
  resetBaproFile: (response) => ({ type: RESET_BAPRO_FILES, response }),
  getBaproFilesResponse: (response) => ({
    type: GET_BAPRO_FILES_RESPONSE,
    response,
  }),
  getBaproFilesError: (error) => ({ type: GET_BAPRO_FILES_ERROR, error }),
};
