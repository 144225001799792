import {
  // GET_PERSONS, GET_PERSONS_RESPONSE, GET_PERSONS_ERROR,
  /* GET_ALL_PROFILES,
  GET_ALL_PROFILES_RESPONSE,
  GET_ALL_PROFILES_ERROR, */
  GET_ALL_PARTNERS,
  GET_ALL_PARTNERS_RESPONSE,
  GET_ALL_PARTNERS_ERROR,
  /* GET_PROFILE,
  GET_PROFILE_RESPONSE,
  GET_PROFILE_ERROR, */
  SELECT_PERSON,
  GET_CAPABILITIES,

  // table and filters
  UPDATE_PERSONS_FILTER_VALUES,
  ADD_REMOVE_PERSON_TO_SELECTION,
  TOGGLE_SELECTION_ALL_MIGRATIONS,
  CLEAR_SELECTION,
  CLEAR_PERSON_CUSTOM_FILTER,
  SAVE_SEARCH_INPUT,
  UPDATE_PAGE,
  UPDATE_PAGE_SIZE,
  FILTER_PERSONS_RESPONSE_MIGRATION,
  FILTER_PERSONS_ERROR,
  CLEAR_FILTER,
  CLEAR_UI_VALUE,
  LOGOUT,
  MIGRATE_RESULTS,
  MIGRATE_RESULTS_RESPONSE,
  MIGRATE_RESULTS_ERROR,
  SELECT_ALL,
  LOAD_ALL,
  SAVE_ALL,
  ALL_LOADED,
  FILTER_PERSONS_MIGRATION,
} from './campaignsMigrations.actions';
import { REHYDRATE } from 'redux-persist/lib/index';

const initialState = {
  count: 0, // table rows
  current: 1, // page number
  total_pages: 1,
  page_size: 10, // rows to fetch and show
  results: [],
  desc: true,
  customFilter: { ordering: '-game_date', search: '', page: 1 },
  filterApplied: false,
  demo_filter_results: [],
  person: {},
  selection: [],
  selectionData: [],
  profiles: [],
  partners: [],
  job_compatibility: [],
  all_job_compatibility: [],
  capabilities: [],
  countries: [],
  cities: [],
  persons: [], // TODO: not used?
  personsToMigrate: [],
  allPersons: [],
  filters: [], // !! this is persons
  create: { loading: false, success: false, error: false },
  update: { loading: false, success: false, error: false },
  userUpdate: { loading: false, success: false, error: false },
  delete: { loading: false, success: false, error: false },
  demoFilterStatus: { loading: false, success: false, error: false },
  reportsStatus: { loading: false, success: false, error: false },

  retrieve: { loading: false, success: false, error: false },
  sendPersonReportEmailStatus: { loading: false, success: false, error: false },
  jobsCompatibilityStatus: { loading: false, success: false, error: false },
  allJobsCompatibilityStatus: { loading: false, success: false, error: false },
  savedSearchInput: { inputValue: '' },
  currentProfile: '',
  allSelected: false,
  allResultsSelected: [],
  allLoaded: false,
  retrieveFilter: { loading: false, success: false, error: false },
  serverDateFilterShouldChange: false,
  historicalStartDate: null,
};

const campaignsMigrations = (state = initialState, action) => {
  switch (action.type) {
    case FILTER_PERSONS_MIGRATION:
      const serverDateFilterShouldChange =
        JSON.stringify(state.customFilter?.campaigns) !==
          JSON.stringify(action.filter?.campaigns) ||
        JSON.stringify(state.customFilter?.partners) !==
          JSON.stringify(action.filter?.partners);
      let customFilter = action.filter?.isPageUpdate
        ? state.customFilter
        : action.filter || state.customFilter;
      // if (serverDateFilterShouldChange && !action.filter?.isPageUpdate) {
      // customFilter.start_range = undefined;
      // customFilter.end_range = undefined;
      // }
      const filterConst = [
        'campaigns',
        'code',
        'max_age',
        'min_age',
        'profiles',
      ];
      if (!customFilter.ordering) customFilter.ordering = '-game_date';
      return {
        ...state,
        customFilter,
        filterApplied: Object.keys(action.filter || {}).some((k) =>
          filterConst.includes(k),
        ),
        serverDateFilterShouldChange,
        retrieveFilter: { loading: true, success: false, error: false },
      };
    case FILTER_PERSONS_RESPONSE_MIGRATION:
      /* const start_date =
        !state.start_date || state.serverDateFilterShouldChange
          ? action.response.game_date_range.start_date
          : state.start_date;
      const end_date =
        !state.start_date || state.serverDateFilterShouldChange
          ? action.response.game_date_range.end_date
          : state.end_date; 
      const historicalStartDate = action.response.historical_start_date; */
      const tempState = {
        ...state,
        retrieveFilter: { loading: false, success: true, error: false },
        retrieve: { loading: false, success: true, error: false },
        filters: action.response.results,
        /* start_date,
        end_date,
        historicalStartDate, */
        ...action.response,
      };
      if (state.allSelected) {
        return {
          ...tempState,
          allSelected: false,
          selection: [],
          selectionData: [],
        };
      }
      return tempState;
    case FILTER_PERSONS_ERROR:
      return {
        ...state,
        retrieveFilter: { loading: false, success: false, error: true },
      };

    /** Profiles */
    /*     case GET_ALL_PROFILES:
      return {
        ...state,
        retrieve: { loading: true, success: false, error: false },
        ...action.response,
      };
    case GET_ALL_PROFILES_RESPONSE:
      return {
        ...state,
        retrieve: { loading: false, success: true, error: false },
        profiles: action.response.results,
      };
    case GET_ALL_PROFILES_ERROR:
      return {
        ...state,
        retrieve: { loading: false, success: false, error: true },
      }; */

    /** Partners */
    case GET_ALL_PARTNERS:
      return {
        ...state,
        retrieve: { loading: true, success: false, error: false },
      };
    case GET_ALL_PARTNERS_RESPONSE:
      return {
        ...state,
        retrieve: { loading: false, success: true, error: false },
        partners: action.response.results,
      };
    case GET_ALL_PARTNERS_ERROR:
      return {
        ...state,
        retrieve: { loading: false, success: false, error: true },
      };

    /** Profile */
    /* case GET_PROFILE:
      return {
        ...state,
        retrieve: { loading: true, success: false, error: false },
      };
    case GET_PROFILE_RESPONSE:
      return {
        ...state,
        retrieve: { loading: false, success: true, error: false },
        currentProfile: action.response[0].name,
      };
    case GET_PROFILE_ERROR:
      return {
        ...state,
        retrieve: { loading: false, success: false, error: true },
      };
 */
    /** */
    case GET_CAPABILITIES:
      return { ...state, capabilities: action.response };

    /** */
    case SELECT_PERSON:
      return {
        ...state,
        person: state.results.filter((t) => t.result === action.id)[0],
      };

    /** */
    case UPDATE_PERSONS_FILTER_VALUES:
      return {
        ...state,
        current: action.filter.search ? 1 : state.customFilter.current,
        customFilter: {
          ...state.customFilter,
          ...action.filter,
        },
        desc: !state.desc,
      };
    case CLEAR_FILTER:
      return {
        ...state,
        customFilter: {
          ordering: state.customFilter.ordering,
          search: state.customFilter.search,
        },
      };

    /**  */
    case UPDATE_PAGE:
      return { ...state, current: action.pageNumber };
    case UPDATE_PAGE_SIZE:
      return { ...state, page_size: action.pageSize, current: 1 };

    case MIGRATE_RESULTS:
      return {
        ...state,
        create: { loading: true, success: false, error: false },
      };
    case MIGRATE_RESULTS_RESPONSE:
      return {
        ...state,
        create: { loading: false, success: true, error: false },
      };
    case MIGRATE_RESULTS_ERROR:
      return {
        ...state,
        create: { loading: false, success: false, error: true },
      };

    /**  */
    case ADD_REMOVE_PERSON_TO_SELECTION:
      let add =
        state.selection.filter(
          (selected) => selected.id_invitation === action.result.id_invitation,
        ).length === 0;
      let newSelection = add
        ? state.selection.concat([
            {
              id_result: action.result.id_result,
              id_invitation: action.result.id_invitation,
            },
          ])
        : state.selection.filter(
            (sel) => sel.id_invitation !== action.result.id_invitation,
          );
      let extraSelData = add
        ? state.selectionData.concat(
            state.results.filter(
              (res) => res.id === action.result.id_invitation,
            )[0],
          )
        : state.selectionData.filter(
            (sel) => sel !== action.result.id_invitation,
          );

      return {
        ...state,
        selection: [...new Set(newSelection)],
        selectionData: extraSelData,
        allSelected: state.allSelected && add,
      };

    case TOGGLE_SELECTION_ALL_MIGRATIONS:
      let result = state.selection;

      // find if persons in filters are ALL selected
      const filtersFound = state.selection.filter((result) => {
        const found = state.filters.find(
          (person) => person.result.id === result.id_result,
        );

        return found;
      });

      if (filtersFound.length === state.filters.length) {
        // enters here if all persons in filters are in my selection
        result = state.selection.filter(
          (result) =>
            !state.filters.find(
              (person) => person.result.id === result.id_result,
            ),
        );
      } else {
        result = [
          ...new Set([
            ...result,
            ...state.filters.flatMap((person) => ({
              id_result: person.result.id,
              id_invitation: person.id,
            })),
          ]),
        ];
      }
      return { ...state, selection: result };

    /**  */
    case CLEAR_SELECTION:
      return {
        ...state,
        selection: [],
        selectionData: [],
        allSelected: false,
        allResultsSelected: [],
        allLoaded: false,
        personsToMigrate: [],
      };

    case CLEAR_PERSON_CUSTOM_FILTER:
      return {
        ...state,
        customFilter: { ordering: '-game_date', search: '', page: 1 },
      }; // ver si ahora también está volviendo a la página 1

    /**  */
    case CLEAR_UI_VALUE:
      return {
        ...state,
        [action.op_type]: {
          ...action.op_type,
          [action.condition]: false,
        },
      };

    /**  */
    case SAVE_SEARCH_INPUT:
      return { ...state, savedSearchInput: action.value };

    /**  */
    case LOGOUT:
      return { ...initialState };

    case SELECT_ALL:
      return { ...state, allSelected: action.flag };

    //OPTIONS

    case REHYDRATE:
      let incoming = action.payload;
      if (incoming) {
        return {
          ...state,
          ...incoming.persons,
          create: { loading: false, success: false, error: false },
          update: { loading: false, success: false, error: false },
          userUpdate: { loading: false, success: false, error: false },
          delete: { loading: false, success: false, error: false },
          demoFilterStatus: { loading: false, success: false, error: false },
          retrieve: { loading: false, success: false, error: false },
          sendPersonReportEmailStatus: {
            loading: false,
            success: false,
            error: false,
          },
          customFilter: initialState.customFilter,
        };
      }
      return state;

    // Filters

    case LOAD_ALL:
      return { ...state };

    case ALL_LOADED:
      return { ...state, allLoaded: true };

    case SAVE_ALL:
      return {
        ...state,
        results: action.results,
        allResultsSelected: [...action.results],
        selection: [...action.results],
      };

    default:
      return state;
  }
};

export default campaignsMigrations;
