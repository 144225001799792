import React, { PureComponent } from 'react';
import './ReportsScreen.css';
import 'react-table/react-table.css';
import { I18n } from 'react-redux-i18n';
import ReactTable from 'react-table';
import { Tabs, Tab } from 'react-bootstrap';
import Loader from '../../../components/common/Loader';
import TableLoader from '../../../components/common/TableLoader/TableLoader';
import PropTypes from 'prop-types';
import { reportDictionary } from '../../../utils/reportDictionary';
import Button from '../../../components/common/Button';
import TableMenuItem from '../../../components/common/TableMenuItem/TableMenuItem';
import Dropdown from '../../../components/common/Dropdown';
import ModalAlert from '../../../components/common/ModalAlert';
import dayjs from 'dayjs';
import { notify } from 'react-notify-toast';
import {
  SnackBar,
  successMessage,
  errorMessage,
} from '../../../utils/snackbar';

class ReportsScreen extends PureComponent {
  state = {
    infoReport: {
      name: '',
      date: '',
      individual: '',
      company: '',
    },
    showModal: false,
    selectedReport: '',
  };

  componentDidMount() {
    const { user, getInfoReports } = this.props;

    if (user.account || user.branch) {
      getInfoReports({
        account: !!user.account,
        branch: !!user.branch,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.restoreReportStatus !== this.props.restoreReportStatus) {
      this.props.getInfoReports({
        account: !!this.props.user.account,
        branch: !!this.props.user.branch,
      });
    }
    if (prevProps.restoreReportStatus !== this.props.restoreReportStatus) {
      this.showNotifications();
    }
  }

  showNotifications() {
    if (this.props.restoreReportStatus.success) {
      notify.show(
        <SnackBar
          icon={'priority'}
          title={I18n.t('common.excellent')}
          body={I18n.t('reports.successRestore')}
          type={'success'}
        />,
        'custom',
        5000,
        successMessage,
      );
    }
    if (this.props.restoreReportStatus.error) {
      notify.show(
        <SnackBar
          icon={'report'}
          title={'Error'}
          body={'No se pudo cargar el archivo'}
          type={'error'}
        />,
        'custom',
        5000,
        errorMessage,
      );
    }
  }

  restoreReports() {
    if (this.state.selectedReport.individual) {
      const report = 'individual';
      this.props.restoreReports({ report });
    }
    if (this.state.selectedReport.company) {
      const report = 'company';
      this.props.restoreReports({ report });
    }
  }

  modalShow = (type) => {
    this.setState({ showModal: true, selectedReport: type });
  };

  modalHide = () => {
    this.setState({ showModal: false });
  };

  getColumns = () => [
    {
      Header: I18n.t('reports.name'),
      accessor: 'name',
      Cell: ({ value }) => this.renderCell(value),
    },
    {
      Header: I18n.t('reports.report'),
      accessor: 'report_folder',
      Cell: ({ value }) =>
        this.renderCell(
          I18n.t(`reports.${reportDictionary('individual', value)}`),
        ),
    },
    {
      Header: I18n.t('reports.reportType'),
      accessor: 'type',
      Cell: ({ value }) => {
        if (value.individual) {
          return I18n.t('reports.individual');
        }
        if (value.company) {
          return I18n.t('reports.company');
        }
      },
    },
    {
      Header: I18n.t('reports.date'),
      accessor: 'updated',
      Cell: ({ value }) => {
        if (value) {
          return this.renderCell(dayjs(value).format('DD/MM/YYYY'));
        } else {
          return this.renderCell('-');
        }
      },
    },
    {
      Header: <span></span>,
      headerClassName: 'wordwrap',
      id: 'share',
      accessor: (d) => d,
      sortable: false,
      width: 97,
      Cell: (props) => {
        const { created, type } = props.value;
        return (
          <TableMenuItem>
            <div className="share-row">
              <Dropdown
                value={{
                  inputValue: {
                    code: '1',
                    label: (
                      <span class="material-symbols-rounded">more_vert</span>
                    ),
                  },
                }}
                className="users-dropdown"
                noBorder
                // change={() => this.handleActions(props.value)}
                options={[
                  {
                    code: 'share',
                    label: (
                      <Button
                        className={'bg-primary text-white p-0'}
                        iconButton={true}
                        iconClass={'edit'}
                        iconFilled
                        text={I18n.t('common.edit')}
                        // disabled={!created}
                        onClick={(e) => {
                          e.stopPropagation();
                          this.props.history.push('/main/edit-report');
                          /* if (campaign_type === 'DIRECT') {
                        } else this.showModalFunc(id); */
                        }}
                      />
                    ),
                    disabled: false,
                  },
                  {
                    code: 'delete',
                    label: (
                      <Button
                        className={'bg-warning text-white p-0'}
                        iconButton={true}
                        iconClass={'undo'}
                        iconFilled
                        text={I18n.t('reports.restore')}
                        onClick={() => this.modalShow(type)}
                      />
                    ),
                    disabled: false,
                  },
                ]}
              />
            </div>
          </TableMenuItem>
        );
      },
      getProps: () => ({
        onClick: (e) => e.stopPropagation(),
        style: { cursor: 'default' },
      }),
    },
  ];

  renderCell = (value) => (
    <div className="name-field">
      <span className="number" data-tooltip-content={value}>
        {value}
      </span>
    </div>
  );

  renderTable() {
    const { getInfoReportsResponse } = this.props;

    return getInfoReportsResponse && getInfoReportsResponse.length > 0 ? (
      <div className="table">
        <ModalAlert
          show={this.state.showModal}
          title={I18n.t('common.attention')}
          name="cleanLogo"
          message={I18n.t('reports.wantRestore')}
          textButtonConfirm={I18n.t('reports.restore')}
          actionConfirm={() => {
            this.restoreReports();
          }}
          onHide={this.modalHide}
          actionCancel={this.modalHide}
        />

        <ReactTable
          manualPagination={false}
          data={getInfoReportsResponse}
          minRows={0}
          minWith={5}
          resizable={false}
          noDataText={I18n.t('persons.table.noRows')}
          LoadingComponent={TableLoader}
          columns={this.getColumns()}
        />
      </div>
    ) : (
      <Loader />
    );
  }

  render() {
    return (
      <div className="reports-container">
        <Tabs>
          <Tab
            title={I18n.t('common.sidebar.reports')}
            eventKey={I18n.t('common.sidebar.reports')}
          >
            {this.renderTable()}
          </Tab>
        </Tabs>
      </div>
    );
  }
}

ReportsScreen.propTypes = {
  user: PropTypes.shape({
    account: PropTypes.bool,
    branch: PropTypes.bool,
  }).isRequired,
  getInfoReports: PropTypes.func.isRequired,
  getInfoReportsResponse: PropTypes.array,
};

export default ReportsScreen;
