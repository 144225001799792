import { connect } from 'react-redux';
import RecoverPasswordScreen from '../components/RecoverPassword/RecoverPasswordScreen';
import actions from '../../actions';
import { withRouter } from 'react-router';

const mapStateToProps = (state) => ({
  emailStatus: state.session.emailStatus,
});

const mapDispatchToProps = (dispatch) => ({
  RecoverPassword: (email) => {
    dispatch(actions.session.recoverPassword(email));
  },
  recoverPasswordCleanError: () => {
    dispatch(actions.session.recoverPasswordCleanError());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(RecoverPasswordScreen));
