import { deleteRequest, get, patch, post, put, webApi } from '../utils/http';
import queryString from 'query-string';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
dayjs.extend(timezone);
export const services = {
  getPerson: async (filter, searchFilter) => {
    return get(webApi + `api/results/${filter.code}${searchFilter.toString()}`);
  },
  getPersons: async (filter, searchFilter) => {
    return get(
      webApi +
        `api/persons${searchFilter.toString()}&${queryString.stringify(
          filter,
        )}`,
    );
  },
  filterPersons: async (filter, searchFilter) => {
    return get(
      webApi +
        `api/results${searchFilter.toString()}&${queryString.stringify(
          filter,
        )}`,
    );
  },
  getUserPlayerInformation: (id_invitation) =>
    get(webApi + `api/invitations/${id_invitation}`),
  createPerson: (body) => post(webApi + 'api/person/', body),
  updatePerson: (id, person) => put(webApi + 'api/person/' + id + '/', person),
  patchUser: (id, user) => patch(webApi + 'api/users/' + id + '/', user),
  deletePerson: (id) => deleteRequest(webApi + 'api/person/' + id + '/'),
  getAllProfiles: (filter, search = '') =>
    get(
      webApi +
        `api/profiles` +
        filter.toString() +
        `&lang=${localStorage.getItem('language')}&search=${search}`,
    ),
  getAllPartners: (filter) =>
    get(
      webApi +
        `api/partner` +
        filter.toString() +
        `&lang=${localStorage.getItem('language')}`,
    ),
  demographicFilter: (latlng) =>
    get(webApi + `api/demographic_filter/${latlng.lat}/${latlng.lng}`),
  getJobCompatibility: (body) => {
    let id_results = body.results;
    let queryObject = {
      id_results,
      id_positions: body.positions,
    };
    return get(
      webApi +
        `api/results/reports/profile?lang=${localStorage.getItem(
          'language',
        )}&${queryString.stringify(queryObject)}`,
    );
  },
  getCapabilities: () => {},
  getCountries: () =>
    get(
      webApi +
        `api/countries?page_size=250&lang=${localStorage.getItem('language')}`,
    ),
  getCities: (country_id) =>
    get(
      webApi +
        `api/cities?country_id=${country_id}&lang=${localStorage.getItem(
          'language',
        )}`,
    ),
  downloadProfileReport: (ids, reportType) => {
    let id_result = ids.map((res) => res.id_result);
    let id_invitations = ids.map((res) => res.id_invitation);
    let queryObject = {
      id_result,
      id_invitations,
      type: reportType,
    };
    return get(
      webApi +
        `api/results/reports/download?lang=${localStorage.getItem(
          'language',
        )}&${queryString.stringify(queryObject)}`,
      { extra: { responseType: 'blob' } },
    );
  },
  sendPersonReportEmail: (value, id_invitation, campaign) => {
    const queryObject = {
      id_result: value,
      id_invitation,
      campaign,
      type: 'individual',
    };

    return get(
      webApi + `api/results/reports/send?${queryString.stringify(queryObject)}`,
    );
  },
  getProfile: (id) =>
    get(
      webApi + `api/profiles?id=${id}&lang=${localStorage.getItem('language')}`,
    ),
  migrateCampaign: (body) =>
    post(
      webApi + `api/migrate_campaign/?lang=${localStorage.getItem('language')}`,
      body,
      { headers: { 'Content-Type': 'multipart/form-data' } },
    ),
  exportTrend: (body) => {
    let id_results = body.results;
    let id_skills = false;
    let id_positions = false;
    body.capabilities
      ? (id_skills = body.data.map((id_sk) => id_sk))
      : (id_positions = body.data.map((id_pos) => id_pos));

    let queryObject = {
      id_results,
      id_skills,
      id_positions,
    };

    const response = body.capabilities
      ? get(
          webApi +
            `api/results/reports/skills/download?lang=${localStorage.getItem(
              'language',
            )}&${queryString.stringify(queryObject)}`,
          {
            extra: { responseType: 'blob' },
          },
        )
      : get(
          webApi +
            `api/results/reports/profile/download?lang=${localStorage.getItem(
              'language',
            )}&${queryString.stringify(queryObject)}`,
          {
            extra: { responseType: 'blob' },
          },
        );
    return response;
  },

  addNote: ({ description, player_id, invitation_id }) =>
    post(webApi + 'api/notes', { description, player_id, invitation_id }),

  updateNote: ({ featured, player_id, note_id }) =>
    patch(webApi + `api/notes/${note_id}`, { player_id, featured }),

  deleteNote: (note_id) => deleteRequest(webApi + `api/notes/${note_id}`),

  getNotes: (id_user_player) =>
    get(webApi + `api/notes?id_user_player=${id_user_player}`),

  addCV: ({ id_user_player, curriculum }) => {
    const formData = new FormData();
    curriculum.map((file) => {
      formData.append('id_user_player', id_user_player);
      formData.append('file', file ? file : null);
      formData.append('directory', file.type);
    });

    return post(webApi + 'api/files', formData, {
      headers: {
        'Content-Type': `multipart/form-data`,
      },
    });
  },

  getCV: (id_user_player) => {
    return get(webApi + `api/files?id_user_player=${id_user_player}`);
  },

  deleteAttached: ({ id_user_player, fileId }) => {
    return deleteRequest(
      webApi + `api/files?id_user_player=${id_user_player}&fileId=${fileId}`,
    );
  },

  // updateCV: ({ id, curriculum }) => {
  //   const formData = new FormData();
  //   formData.append('file', curriculum ? curriculum : null);
  //   return patch(webApi + `api/files/${id}`, formData);
  // },

  shareFile: ({ id_invitation, sendCv, sendNotes, emails }) => {
    return post(webApi + `api/companies/send-file`, {
      id_invitation,
      sendCv,
      sendNotes,
      emails,
      time_zone: dayjs.tz.guess(),
    });
  },
  addIeInvitation: (id_campaign, email, lang) => {
    return post(webApi + 'api/invitations', {
      id_campaign,
      emails: [email],
      type: 'IE',
      lang: lang,
    });
  },

  massiveIeInvitation: (persons) => {
    return post(webApi + 'api/invitations', {
      persons: persons,
      type: 'IE',
      massive: true,
    });
  },
};
