import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router';

const LoginRequiredRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        !rest.loggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            key="reverse-auth-route-redirect"
            to={{
              pathname: '/main/home',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default connect((state) => ({
  loggedIn: state.session.isLoggedIn,
}))(LoginRequiredRoute);
