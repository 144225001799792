import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './DeleteCampaignModal.css';
import { Modal } from 'react-bootstrap';
import exit from '../../../assets/icons/cross-DARK-32x32.png';
import { I18n } from 'react-redux-i18n';
import Button from '../Button';

class DeleteCampaignModal extends Component {
  static defaultProps;

  render() {
    return (
      <Modal
        show={this.props.show}
        dialogClassName="delete-campaign"
        onHide={this.props.onHide}
        width={{}}
      >
        <Modal.Header className={'modal-header'}>
          <span class="material-symbols-rounded">report</span>
          <h4 className="modal-title">{I18n.t('common.attention')}</h4>
        </Modal.Header>
        <Modal.Body>
          <p>
            {I18n.t('campaigns.deleteCampModalBody', {
              campaign: this.props.campaign,
            })}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="alternative-gray-modal"
            text={I18n.t('buttons.cancel')}
            onClick={this.props.onHide}
          />
          <Button
            className="button-delete"
            text={I18n.t('common.confirmDelete')}
            onClick={() => {
              this.props.action();
              this.props.onHide();
            }}
          />
        </Modal.Footer>
      </Modal>
    );
  }
}

DeleteCampaignModal.defaultProps = {};
DeleteCampaignModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
};

export default DeleteCampaignModal;
