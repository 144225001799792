import {} from './profile.actions';
import { services } from './profile.services';
import actions from '../actions';
import { GET_TAGS } from './profile.actions';
import { GET_PROFILES } from './profile.actions';
//import { GET_ALL_PROFILES } from '../persons/persons.actions';
import { GET_PROFILE_SKILLS } from './profile.actions';
import {
  GET_PROFILE_SKILLS_RANGE,
  GET_ALL_PROFILE_SKILLS_RANGE,
} from './profile.actions';

const profileMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    const { selection, allSelected, allResultsSelected } = getState().persons;
    switch (action.type) {
      case GET_TAGS:
        services
          .getTags()
          .then((data) => dispatch(actions.profile.getTagsResponse(data)))
          .catch((error) => dispatch(actions.profile.getTagsError(error)));
        break;

      case GET_PROFILES:
        services
          .getProfiles(action.tags)
          .then((data) => dispatch(actions.profile.getProfilesResponse(data)))
          .catch((error) => dispatch(actions.profile.getProfilesError(error)));
        break;

      /* case GET_ALL_PROFILES:
        services
          .getAllProfiles(action.tags)
          .then((data) =>
            dispatch(actions.profile.getAllProfilesResponse(data)),
          )
          .catch((error) =>
            dispatch(actions.profile.getAllProfilesError(error)),
          );
        break; */

      case GET_PROFILE_SKILLS:
        services
          .getProfileSkills()
          .then((data) => {
            dispatch(
              actions.profile.getAllProfileSkillsRange(
                data.results.map((skill) => skill.id),
              ),
            );
            dispatch(actions.profile.getProfileSkillsResponse(data));
          })
          .catch((error) => {
            dispatch(actions.profile.getProfileSkillsError(error));
          });
        break;

      case GET_PROFILE_SKILLS_RANGE:
        services
          .getProfileSkillsRange({
            skills: action.skills,
            results: allSelected
              ? allResultsSelected.map((item) => item.id_result)
              : selection.map((item) => item.id_result),
            excel: action.excel,
          })
          .then((data) => {
            dispatch(actions.profile.removeSelectedSkill());
            dispatch(actions.profile.getProfileSkillsRangeResponse(data));
          })
          .catch((error) =>
            dispatch(actions.profile.getProfileSkillsRangeError(error)),
          );
        break;

      case GET_ALL_PROFILE_SKILLS_RANGE:
        services
          .getProfileSkillsRange({
            skills: action.skills,
            results: allSelected ? allResultsSelected : selection,
          })
          .then((data) =>
            dispatch(actions.profile.getAllProfileSkillsRangeResponse(data)),
          )
          .catch((error) =>
            dispatch(actions.profile.getAllProfileSkillsRangeError(error)),
          );
        break;

      default:
        break;
    }
  };

export default profileMiddleware;
