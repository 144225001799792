import { combineReducers } from 'redux';
import sessionReducer from '../session/session.reducer';
import persons from '../persons/persons.reducer';
import campaigns from '../campaigns/campaigns.reducer';
import campaignsMigrations from '../campaignsMigrations/campaignsMigrations.reducer';
import dashboard from '../dashboard/dashboard.reducer';
import users from '../users/users.reducer';
import jobs from '../jobs/jobs.reducer';
import clients from '../clients/clients.reducer';
import profile from '../profile/profile.reducer';
import common from '../components/common.reducer';
import bapro from '../bapro/bapro.reducer';
import reports from '../reports/reports.reducer';
import { i18nReducer } from 'react-redux-i18n';

const rootReducer = combineReducers({
  session: sessionReducer,
  persons,
  campaigns,
  campaignsMigrations,
  dashboard,
  users,
  jobs,
  clients,
  profile,
  common,
  bapro,
  reports,
  i18n: i18nReducer,
});

export default rootReducer;
