import { connect } from 'react-redux';
import ResetPasswordScreen from '../components/ResetPassword/ResetPasswordScreen';
import actions from '../../actions';
import { withRouter } from 'react-router';

const mapStateToProps = (state) => ({
  url: state.router.location.pathname,
  resetPasswordStatus: state.session.resetPasswordStatus,
  validateTokenChangePasswordStatus:
    state.session.validateTokenChangePasswordStatus,
  lang: state.i18n.locale,
});

const mapDispatchToProps = (dispatch) => ({
  resetPassword: (data) => {
    dispatch(actions.session.resetPassword(data));
  },
  validateTokenChangePassword: (data) => {
    dispatch(actions.session.validateTokenChangePassword(data));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ResetPasswordScreen));
