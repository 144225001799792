import React, { Component } from 'react';
import './ExternalLoginScreen.css';
import { I18n } from 'react-redux-i18n';
import reqStates from '../../../utils/reqStates';
import OpenIDConnectCard from './OpenIdConnectCard';

class ExternalLoginScreen extends Component {
  static defaultProps;

  constructor(props) {
    super(props);
    const urlMail = props.match.params.mail || '';
    this.state = {
      forgot_password: false,
      after_link_request: false,
      show_loader: false,
      disableInput: !!urlMail,
      username: { inputValue: urlMail, required: true },
      password: { inputValue: '', required: true, forgotPassword: true },
      sentActivationLink: false,
      isFormValid: true,
    };
  }

  // TODO: change to componentDidUpdate(prevProps) (obsolete lifecycle function)
  componentWillReceiveProps(props) {
    if (
      props.loginStatus === reqStates.LOADING &&
      this.props.loginStatus !== reqStates.LOADING
    ) {
      this.setState({ ...this.state, show_loader: true });
    }
    if (
      props.loginStatus !== reqStates.LOADING &&
      this.props.loginStatus === reqStates.LOADING
    ) {
      this.setState({ ...this.state, show_loader: false });
    }
    if (props.resetPasswordLoading && !this.props.resetPasswordLoading) {
      this.setState({ ...this.state, show_loader: true });
    }
    if (!props.resetPasswordLoading && this.props.resetPasswordLoading) {
      this.setState({
        ...this.state,
        show_loader: false,
        forgot_password: false,
        after_link_request: true,
      });
    }
  }

  _isFormValid() {
    const { username, password } = this.state;
    return username.inputValue.length > 0 && password.inputValue.length > 0;
  }

  handleChange = (key, value) => {
    this.setState({
      ...this.state,
      [key]: { ...this.state[key], inputValue: value },
    });
  };

  handleLogin = (e) => {
    e.preventDefault();
    if (this._isFormValid()) {
      // ! FRONT QUICK FIX !
      if (!this.props.tooManyLoginAttempts)
        this.props.login(
          this.state.username.inputValue,
          this.state.password.inputValue,
        );
    } else {
      this.setState({
        ...this.state,
        isFormValid: false,
      });
    }
  };

  render() {
    return (
      <div className="external-login">
        <OpenIDConnectCard {...this.props} />
      </div>
    );
  }

  renderError() {
    const { loginStatus, userservicelogin } = this.props;
    if (userservicelogin) return null;
    if (loginStatus === reqStates.NONE) {
      if (!this.state.isFormValid)
        return (
          <p className="error-message text-warning">
            {I18n.t('login.errors.invalidForm')}
          </p>
        );
      return null;
    } else if (loginStatus === reqStates.ERROR) {
      const { status, data } = this.props.response;
      // ! FRONT QUICK FIX !
      if (this.props.tooManyLoginAttempts)
        return (
          <p className="error-message text-warning">
            {I18n.t('login.errors.tooManyAttempts')}
          </p>
        );
      if (status === 400)
        if (data.non_field_errors)
          return (
            <p className="error-message text-warning">
              {I18n.t('login.errors.wrongAuth')}
            </p>
          );
        else
          return (
            <p className="error-message text-warning">
              {I18n.t('login.errors.userDisabled')}
            </p>
          );
      else
        return (
          <p className="error-message text-warning">
            {'Error al ingresar (' + status + ')'}
          </p>
        );
    } else {
      return null;
    }
  }
}

export default ExternalLoginScreen;
