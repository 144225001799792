import {
  GET_CLIENTS,
  GET_CLIENTS_RESPONSE,
  GET_CLIENTS_ERROR,
  GET_ALL_CLIENTS,
  GET_ALL_CLIENTS_RESPONSE,
  GET_ALL_CLIENTS_ERROR,
  CREATE_CLIENT,
  CREATE_CLIENT_RESPONSE,
  CREATE_CLIENT_ERROR,
  UPDATE_CLIENT,
  UPDATE_CLIENT_ERROR,
  UPDATE_CLIENT_RESPONSE,
  DELETE_CLIENT,
  DELETE_CLIENT_RESPONSE,
  DELETE_CLIENT_ERROR,
  UPDATE_CREDITS,
  UPDATE_CREDITS_RESPONSE,
  UPDATE_CREDITS_ERROR,
  SELECT_CLIENT,
  UPDATE_PAGE,
  UPDATE_PAGE_SIZE,
  UPDATE_CLIENTS_FILTER_VALUES,
  CLEAR_UI_VALUE,
  LOGOUT,
  GET_CLIENT_GROUPS,
  GET_CLIENT_GROUPS_RESPONSE,
  GET_CLIENT_GROUPS_ERROR,
  UPLOAD_ATTACHMENT_RESPONSE,
  UPLOAD_ATTACHMENT_REQUEST,
  UPLOAD_ATTACHMENT_ERROR,
  GET_CLIENT_TYPES,
  GET_CLIENT_TYPES_RESPONSE,
  GET_CLIENT_TYPES_ERROR,
  GET_BRANCHS,
  GET_BRANCHS_RESPONSE,
  GET_LOGO_URL,
  GET_LOGO_URL_RESPONSE,
  GET_LOGO_URL_ERROR,
  UPDATE_VIEW_BRANCH,
  GET_CLIENTS_SCREEN,
  BACK_FROM_DETAIL,
  CLEAN_TABLE,
} from './clients.actions';
import { REHYDRATE } from 'redux-persist/lib/index';

const initialState = {
  clients: [],
  branchs: [],
  allClients: [],
  client_groups: [],
  selectedClient: {},
  clientTypes: [],
  clients_screen: '',
  typeSelection: '',

  // table
  count: 1,
  current: 1,
  total_pages: 1,
  page_size: 10,
  desc: false,
  filterValues: { ordering: '' },
  view_branch: false,
  search: '',

  /**
   * Statuses
   * --------
   * @template (object): { loading: false, error: false, success: false }
   * @template (variable): <what><Who>Status
   * example: getClientStatus -> status of the request
   * that gets all client.
   */
  logo_id: null,
  logo_url: null,
  getLogoStatus: { loading: false, error: false, success: false },
  getClientsStatus: { loading: false, error: false, success: false },
  getAllClientsStatus: { loading: false, error: false, success: false },
  updateCreditsStatus: {
    loading: false,
    error: false,
    success: false,
    message: '',
  },
  createClientStatus: { loading: false, error: false, success: false },
  updateClientStatus: { loading: false, error: false, success: false },
  deleteClientStatus: { loading: false, error: false, success: false },
  getClientGroupsStatus: { loading: false, error: false, success: false },
  uploadAttachmentStatus: { loading: false, error: false, success: false },
  clientTypesStatus: { loading: false, error: false, success: false },

  customFilter: { ordering: '-game_date', search: '', page: 1 },

  credits_user_status: {},
  credits_account_status: {},

  back_from_detail: false,
};

const clients = (state = initialState, action) => {
  switch (action.type) {
    /** Get clients */
    case GET_CLIENTS:
      return {
        ...state,
        view_branch: action.view_branch,
        search: action.search,
        getClientsStatus: { loading: true, error: false, success: false },
        typeSelection: action.client_type ? action.client_type : '',
      };

    case GET_CLIENTS_RESPONSE:
      return {
        ...state,
        getClientsStatus: { loading: false, error: false, success: true },
        ...action.response,
        clients: action.response.results,
        credits_user_status: action.response.credits_user_status,
        credits_account_status: action.response.credits_account_status,
      };
    case GET_CLIENTS_SCREEN:
      return {
        ...state,
        clients_screen: action.screen,
      };

    case GET_BRANCHS:
      return {
        ...state,
        getClientsStatus: { loading: true, error: false, success: false },
      };
    case GET_BRANCHS_RESPONSE:
      return {
        ...state,
        getClientsStatus: { loading: false, error: false, success: true },
        branchs: action.response.results,
      };
    case GET_CLIENTS_ERROR:
      return {
        ...state,
        getClientsStatus: { loading: false, error: true, success: false },
      };

    /** Create client */
    case CREATE_CLIENT:
      return {
        ...state,
        createClientStatus: { loading: true, error: false, success: false },
      };
    case CREATE_CLIENT_RESPONSE:
      return {
        ...state,
        createClientStatus: { loading: false, error: false, success: true },
      };
    case CREATE_CLIENT_ERROR:
      return {
        ...state,
        createClientStatus: { loading: false, error: true, success: false },
      };

    /** Update client */
    case UPDATE_CLIENT:
      return {
        ...state,
        updateClientStatus: { loading: true, error: false, success: false },
      };
    case UPDATE_CLIENT_RESPONSE:
      if (action.response.nwm_accountId) {
        return {
          ...state,
          view_branch: true,
          updateClientStatus: { loading: false, success: true, error: false },
        };
      }
      return {
        ...state,
        view_branch: false,
        updateClientStatus: { loading: false, success: true, error: false },
      };
    case UPDATE_CLIENT_ERROR:
      return {
        ...state,
        updateClientStatus: { loading: false, success: false, error: true },
      };

    /** Delete client */
    case DELETE_CLIENT:
      return {
        ...state,
        deleteClientStatus: { loading: true, error: false, success: false },
      };
    case DELETE_CLIENT_RESPONSE:
      return {
        ...state,
        deleteClientStatus: { loading: false, success: true, error: false },
      };
    case DELETE_CLIENT_ERROR:
      return {
        ...state,
        deleteClientStatus: { loading: false, success: false, error: true },
      };

    /** Update credits */
    case UPDATE_CREDITS:
      return {
        ...state,
        updateCreditsStatus: {
          loading: true,
          error: false,
          success: false,
          message: '',
        },
      };
    case UPDATE_CREDITS_RESPONSE:
      return {
        ...state,
        updateCreditsStatus: {
          loading: false,
          error: false,
          success: true,
          message: '',
        },
      };
    case UPDATE_CREDITS_ERROR:
      return {
        ...state,
        updateCreditsStatus: {
          loading: false,
          error: true,
          success: false,
          message: action.error.data.error,
        },
      };

    /** */
    case SELECT_CLIENT:
      return {
        ...state,
        selectedClient: state.clients.filter((t) => t.id === action.id)[0],
      };

    /** Branch view detail*/
    case BACK_FROM_DETAIL:
      return {
        ...state,
        back_from_detail: action.to,
      };
    case UPDATE_VIEW_BRANCH:
      return {
        ...state,
        view_branch: action.bool,
      };

    /** Table page*/
    case UPDATE_PAGE:
      return { ...state, current: action.pageNumber };
    case UPDATE_PAGE_SIZE:
      return { ...state, page_size: action.pageSize, current: 1 };
    case CLEAN_TABLE:
      return {
        ...state,
        clients: [],
      };

    /** */
    case UPDATE_CLIENTS_FILTER_VALUES:
      return {
        ...state,
        current: action.filterValues.search?.length > 0 ? 1 : state.current,
        filterValues: { ...state.filterValues, ...action.filterValues },
        desc: !state.desc,
      };

    /** Get all clients (used in dashboard) (!! only ADMIN access this) */
    case GET_ALL_CLIENTS:
      return {
        ...state,
        getAllClientsStatus: { loading: true, error: false, success: false },
      };
    case GET_ALL_CLIENTS_RESPONSE:
      return {
        ...state,
        getAllClientsStatus: { loading: false, error: false, success: true },
        allClients: action.response.results,
      };
    case GET_ALL_CLIENTS_ERROR:
      return {
        ...state,
        getAllClientsStatus: { loading: false, error: true, success: false },
      };

    /** Get client groups (used in dashboard) */
    case GET_CLIENT_GROUPS:
      return {
        ...state,
        getClientGroupsStatus: { loading: true, error: false, success: false },
      };
    case GET_CLIENT_GROUPS_RESPONSE:
      return {
        ...state,
        client_groups: action.response,
        getClientGroupsStatus: { loading: false, error: false, success: true },
      };
    case GET_CLIENT_GROUPS_ERROR:
      return {
        ...state,
        getClientGroupsStatus: { loading: false, error: true, success: false },
      };

    /** Get client groups (used in dashboard) */
    case UPLOAD_ATTACHMENT_REQUEST:
      return {
        ...state,
        uploadAttachmentStatus: { loading: true, error: false, success: false },
      };
    case UPLOAD_ATTACHMENT_RESPONSE:
      return {
        ...state,
        logo_id: action.response.attachment_id,
        uploadAttachmentStatus: { loading: false, error: false, success: true },
      };
    case UPLOAD_ATTACHMENT_ERROR:
      return {
        ...state,
        uploadAttachmentStatus: { loading: false, error: true, success: false },
      };

    case GET_CLIENT_TYPES:
      return {
        ...state,
        clientTypesStatus: { loading: true, error: false, success: false },
      };
    case GET_CLIENT_TYPES_RESPONSE:
      return {
        ...state,
        clientTypes: action.response,
        clientTypesStatus: { loading: false, error: false, success: true },
      };
    case GET_CLIENT_TYPES_ERROR:
      return {
        ...state,
        clientTypesStatus: { loading: false, error: true, success: false },
      };
    case GET_LOGO_URL:
      return {
        ...state,
        getLogoStatus: { loading: true, error: false, success: false },
      };
    case GET_LOGO_URL_RESPONSE:
      return {
        ...state,
        logo_url: action.response,
        getLogoStatus: { loading: false, error: false, success: true },
      };
    case GET_LOGO_URL_ERROR:
      return {
        ...state,
        getLogoStatus: { loading: false, error: true, success: false },
      };
    /** */
    case CLEAR_UI_VALUE:
      return {
        ...state,
        [action.op_type]: {
          ...action.op_type,
          [action.condition]: false,
        },
        logo_id: null,
      };

    /** */
    case LOGOUT:
      return { ...initialState };

    /** redux-persist */
    case REHYDRATE:
      let incoming = action.payload;
      if (incoming) {
        return {
          ...state,
          ...incoming.clients,
          getClientsStatus: { loading: false, error: false, success: false },
          getAllClientsStatus: { loading: false, error: false, success: false },
          updateCreditsStatus: { loading: false, error: false, success: false },
          createClientStatus: { loading: false, error: false, success: false },
          updateClientStatus: { loading: false, error: false, success: false },
          deleteClientStatus: { loading: false, error: false, success: false },
          getClientGroupsStatus: {
            loading: false,
            error: false,
            success: false,
          },
          uploadAttachmentStatus: {
            loading: false,
            error: false,
            success: false,
          },
        };
      }
      return state;

    default:
      return state;
  }
};

export default clients;
