const scopes = ['openid', 'profile'];

export const getUserHref = () => {
  const url = process.env.REACT_APP_IDAPTIVE_COMFAMA_USER_ENDPOINT;
  return `${url}`;
};

export const getAuthorizeHref = () => {
  const url = process.env.REACT_APP_IDAPTIVE_COMFAMA_AUTH_ENDPOINT;
  const clientId = process.env.REACT_APP_CLIENT_ID_IDAPTIVE_COMFAMA;
  const redirectUri = process.env.REACT_APP_REDIRECT_URI;
  return `${url}?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scopes.join(
    '%20',
  )}&response_type=code`;
};

// export const getPartnerId = () => {
//     return process.env.REACT_APP_IDAPTIVE_COMFAMA_PARTNER_ID
// }

export const getLogoutHref = () => {
  return `${process.env.REACT_APP_IDAPTIVE_COMFAMA_END_SESSION_ENDPOINT}?post_logout_redirect_uri=${process.env.REACT_APP_REDIRECT_URI}`;
};
