import React, { Component } from 'react';
import './RecoverPassword.sass';
import Input from '../../../components/common/Input';
import Button from '../../../components/common/Button';
import Loader from '../../../components/common/Loader/Loader';
import { I18n } from 'react-redux-i18n';
import settings from '../../../settings';
import { notify } from 'react-notify-toast';
import {
  errorMessage,
  successMessage,
  SnackBar,
} from '../../../utils/snackbar';
import regex from '../../../utils/regex';

class RecoverPasswordScreen extends Component {
  state = {
    email: {
      inputValue: '',
      required: true,
      validator: (email) => regex.emailRegex.test(email),
    },
    connection: true,
    success: null,
    emailSent: false,
    errorLogin: true,
  };

  componentWillMount() {
    this.props.recoverPasswordCleanError();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.emailStatus.error !== this.props.emailStatus.error) {
      this.setState({
        ...this.state,
        success: false,
      });
    }
    if (prevState.connection !== this.state.connection) {
      if (this.state.connection === false) {
        this.setState({
          ...this.state,
          errorLogin: false,
        });
      }
    }
  }

  haveStateConnection() {
    if (window.navigator.onLine) {
      this.setState({
        ...this.state,
        connection: true,
      });
      return true;
    } else {
      this.setState({
        ...this.state,
        connection: false,
        errorLogin: true,
      });
      return false;
    }
  }

  haveConnection() {
    if (window.navigator.onLine) {
      return true;
    } else {
      return false;
    }
  }

  handleChange = (key, value) => {
    this.setState({
      ...this.state,
      [key]: { ...this.state[key], inputValue: value },
      success: null,
    });
  };

  handleRecover = (e) => {
    e.preventDefault();
    if (this.haveStateConnection()) {
      this.setState({ ...this.state, connection: true, emailSent: true });
      this.props.RecoverPassword(this.state.email.inputValue);
    }
  };

  renderError = (error) => {
    if (this.haveConnection() && this.state.success === false) {
      notify.show(
        <SnackBar
          icon={'priority'}
          title={I18n.t('common.attention')}
          body={error}
          type="error"
          iconFilled
        />,
        'custom',
        5000,
        errorMessage,
      );
    }
  };

  _isFormValid() {
    const email = this.state.email.inputValue;
    return email.length > 0 && regex.emailRegex.test(email);
  }

  render() {
    if (this.props.emailStatus.error) {
      if (this.props.emailStatus.status === 400) {
        this.renderError(I18n.t('login.errors.invalidEmail'));
      } else {
        this.renderError(I18n.t('login.errors.tooManyRecoverAttempts'));
      }
    }

    return (
      <div className="recover-screen">
        {/* <div className="col-md-4 col-md-offset-4"> */}
        {/* {this.props.emailStatus.success && this.state.emailSent ? (
          this.renderEmailSent()
        ) : ( */}
        <div className="card">
          <div
            className="wrapper-title"
            onClick={(e) => {
              e.preventDefault();
              this.props.history.push('/');
            }}
          >
            <span className="material-symbols-rounded">chevron_left</span>
            <h2 className="recover-password-title">
              {I18n.t('login.recoverpassword')}
            </h2>
          </div>
          <form className="recover-form" onSubmit={this.handleRecover}>
            <div className="form-body">
              <Input
                label={I18n.t('login.email')}
                name={'email'}
                type={'email'}
                change={this.handleChange}
                value={this.state.email}
                placeholder={I18n.t('recover_password.email')}
                autoFocus
                disabled={
                  this.props.emailStatus.success && this.state.emailSent
                }
                errorLogin={this.state.errorLogin}
              />
              {!this.haveConnection() && (
                <p className="error-message text-warning">
                  {I18n.t('common.connection')}
                </p>
              )}
            </div>
            {/* <div className="text-warning">
                {this.props.emailStatus.error &&
                  (this.props.emailStatus.status === 400
                    ? I18n.t('login.errors.invalidEmail')
                    : I18n.t('login.errors.tooManyRecoverAttempts'))}
              </div> */}
            <div className="form-footer text-center">
              {this.props.emailStatus.loading ? (
                <Loader />
              ) : (
                <Button
                  className={'primary'}
                  translate={true}
                  iconButton={true}
                  iconClass={'check'}
                  text={I18n.t('buttons.confirm')}
                  type={'submit'}
                  disabled={
                    !this._isFormValid() ||
                    (this.props.emailStatus.success && this.state.emailSent)
                  }
                />
              )}
              <p className="version">{settings.version}</p>
            </div>
          </form>
        </div>
        {/* )} */}
      </div>
    );
  }

  // renderEmailSent() {
  //   return (
  //     <div className="p-3">
  //       <div className="card">
  //         <div className="back-button">
  //           <Button
  //             className={'secondary p-0'}
  //             iconButton={true}
  //             iconClass={'fa fa-arrow-left fa-lg'}
  //             onClick={(e) => {
  //               e.preventDefault();
  //               this.props.history.push('/');
  //             }}
  //           />
  //           <div>{I18n.t('login.backLogin')}</div>
  //         </div>
  //         <div>
  //           <h1 className="message-title">{I18n.t('login.emailSent')}</h1>
  //         </div>
  //         <div className="card-body p-2">
  //           <h3 className="message-body">{I18n.t('login.emailMessage')}</h3>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }
}

export default RecoverPasswordScreen;
