import { connect } from 'react-redux';
import actions from '../../actions/index';
import ReportsScreen from '../components/ReportsScreen/ReportsScreen';
import { withRouter } from 'react-router';

const mapDispatchToProps = (dispatch) => ({
  getInfoReports: ({ account, branch }) => {
    dispatch(actions.reports.getInfoReports({ account, branch }));
  },
  restoreReports: ({ report }) => {
    dispatch(actions.reports.restoreReports({ report }));
  },
});

const mapStateToProps = (state, ownProps) => ({
  user: state.session.user,
  getInfoReportsResponse: state.reports.getInfoReportsResponse,
  getInfoReportsError: state.reports.getInfoReportsError,
  getInfoReportsStatus: state.reports.getInfoReportsStatus,
  restoreReportStatus: state.reports.restoreReportStatus,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ReportsScreen));
