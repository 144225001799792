import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './ModalAlert.css';
import { Modal } from 'react-bootstrap';
import exit from '../../../assets/icons/cross-DARK-32x32.png';
import { I18n } from 'react-redux-i18n';
import Button from '../Button';

class DeleteModal extends Component {
  static defaultProps;

  render() {
    const { name, actionConfirm, actionCancel, textButtonConfirm } = this.props;
    return (
      <Modal
        show={this.props.show}
        dialogClassName="modal-alert delete"
        onHide={this.props.onHide}
        width={{}}
      >
        <Modal.Header className={'modal-header'}>
          <h4 className="modal-title">
            {
              <span class="material-symbols-rounded material-symbols-filled">
                report
              </span>
            }{' '}
            {this.props.title || I18n.t(name + '.modal.title')}
          </h4>
          {/* <img src={exit} height={'30px'} onClick={this.props.onHide} alt="" /> */}
        </Modal.Header>
        <Modal.Body>
          <p>{this.props.message || I18n.t(name + '.modal.message')}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="text-link-button"
            text={I18n.t('buttons.cancel')}
            onClick={() => {
              actionCancel();
              this.props.onHide();
            }}
          />
          <Button
            className="primary"
            text={
              textButtonConfirm
                ? textButtonConfirm
                : I18n.t('buttons.deleteJob')
            }
            onClick={async () => {
              await actionConfirm();
              this.props.onHide();
            }}
          />
        </Modal.Footer>
      </Modal>
    );
  }
}

DeleteModal.defaultProps = {};
DeleteModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
};

export default DeleteModal;
