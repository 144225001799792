import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import reducer from './reducers';
import sessionMiddleware from './session/session.middleware';
import personsMiddleware from './persons/persons.middleware';
import baproMiddleware from './bapro/bapro.middleware';
import campaignsMiddleware from './campaigns/campaigns.middleware';
import campaignsMigrationsMiddleware from './campaignsMigrations/campaignsMigrations.middleware';
import dashboardMiddleware from './dashboard/dashboard.middleware';
import usersMiddleware from './users/users.middleware';
import jobsMiddleware from './jobs/jobs.middleware';
import clientsMiddleware from './clients/clients.middleware';
import profileMiddleware from './profile/profile.middleware';
import commonMiddleware from './components/common.middleware';
import reportsMiddleware from './reports/reports.middleware';
// middleware imports

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducer);

const history = createBrowserHistory();

const middleware = routerMiddleware(history);

const store =
  process.env.NODE_ENV === 'production'
    ? createStore(
        connectRouter(history)(persistedReducer),
        undefined,
        compose(
          applyMiddleware(
            thunk,
            middleware,
            sessionMiddleware,
            personsMiddleware,
            campaignsMiddleware,
            campaignsMigrationsMiddleware,
            dashboardMiddleware,
            usersMiddleware,
            jobsMiddleware,
            clientsMiddleware,
            profileMiddleware,
            baproMiddleware,
            reportsMiddleware,
            commonMiddleware,
          ),
        ),
      )
    : createStore(
        connectRouter(history)(persistedReducer),
        undefined,
        compose(
          applyMiddleware(
            thunk,
            logger,
            middleware,
            sessionMiddleware,
            personsMiddleware,
            campaignsMiddleware,
            campaignsMigrationsMiddleware,
            dashboardMiddleware,
            usersMiddleware,
            jobsMiddleware,
            clientsMiddleware,
            profileMiddleware,
            baproMiddleware,
            reportsMiddleware,
            commonMiddleware,
          ),
        ),
      );

const persistor = persistStore(store);

export default { store, persistor, history };
