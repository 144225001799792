import {
  GET_TAGS,
  GET_TAGS_RESPONSE,
  GET_TAGS_ERROR,

  // UPDATE_CAMPAIGN,
  // UPDATE_CAMPAIGN_ERROR,
  // UPDATE_CAMPAIGN_RESPONSE,
  GET_PROFILES,
  GET_PROFILES_RESPONSE,
  GET_PROFILES_ERROR,
  /*  GET_ALL_PROFILES,
  GET_ALL_PROFILES_ERROR,
  GET_ALL_PROFILES_RESPONSE, */
  GET_PROFILE_SKILLS,
  GET_PROFILE_SKILLS_RESPONSE,
  GET_PROFILE_SKILLS_ERROR,
  GET_PROFILE_SKILLS_RANGE,
  GET_PROFILE_SKILLS_RANGE_RESPONSE,
  GET_PROFILE_SKILLS_RANGE_ERROR,
  ADD_SKILL,
  REMOVE_SKILL,
  GET_ALL_PROFILE_SKILLS_RANGE,
  GET_ALL_PROFILE_SKILLS_RANGE_RESPONSE,
  GET_ALL_PROFILE_SKILLS_RANGE_ERROR,
  LOGOUT,
} from './profile.actions';
import { REHYDRATE } from 'redux-persist/lib/index';

const initialState = {
  desc: false,
  filterValues: {
    ordering: '',
  },
  tags: [],
  profiles: [],
  all_profiles: [],
  skills: [],
  profile_skills: [],
  profile_skills_ranges: [],
  all_profile_skills_ranges: [],

  getProfileSkillsRangesStatus: {
    loading: false,
    error: false,
    success: false,
  },
  getProfileSkillsStatus: { loading: false, error: false, success: false },
  getProfilesStatus: { loading: false, error: false, success: false },
  tagsStatus: { loading: false, error: false, success: false },
  getAllProfileSkillsRangesStatus: {
    loading: false,
    error: false,
    success: false,
  },

  skillSelected: 0,
};

const dashboard = (state = initialState, action) => {
  switch (action.type) {
    /** Get profiles */
    case GET_PROFILES:
      return {
        ...state,
        getProfilesStatus: { loading: true, success: false, error: false },
      };
    case GET_PROFILES_RESPONSE:
      return {
        ...state,
        getProfilesStatus: { loading: false, success: true, error: false },
        profiles: action.response,
      };
    case GET_PROFILES_ERROR:
      return {
        ...state,
        getProfilesStatus: { loading: false, success: false, error: true },
      };

    /** Get all profiles */
    /* case GET_ALL_PROFILES:
      return {
        ...state,
        getProfilesStatus: { loading: true, success: false, error: false },
      };
    case GET_ALL_PROFILES_RESPONSE:
      return {
        ...state,
        getProfilesStatus: { loading: false, success: true, error: false },
        profiles: action.response.results,
      };
    case GET_ALL_PROFILES_ERROR:
      return {
        ...state,
        getProfilesStatus: { loading: false, success: false, error: true },
      }; */

    /** Get profile skills */
    case GET_PROFILE_SKILLS:
      return {
        ...state,
        getProfileSkillsStatus: { loading: true, success: false, error: false },
      };
    case GET_PROFILE_SKILLS_RESPONSE:
      return {
        ...state,
        getProfileSkillsStatus: { loading: false, success: true, error: false },
        profile_skills: action.response.results,
      };
    case GET_PROFILE_SKILLS_ERROR:
      return {
        ...state,
        getProfileSkillsStatus: { loading: false, success: false, error: true },
      };

    /** Get profile skills range */
    case GET_PROFILE_SKILLS_RANGE:
      return {
        ...state,
        getProfileSkillsRangesStatus: {
          loading: true,
          success: false,
          error: false,
        },
      };
    case GET_PROFILE_SKILLS_RANGE_RESPONSE:
      return {
        ...state,
        getProfileSkillsRangesStatus: {
          loading: false,
          success: true,
          error: false,
        },
        profile_skills_ranges: action.response,
      };
    case GET_PROFILE_SKILLS_RANGE_ERROR:
      return {
        ...state,
        getProfileSkillsRangesStatus: {
          loading: false,
          success: false,
          error: true,
        },
      };

    /** Get all profile skills range */
    case GET_ALL_PROFILE_SKILLS_RANGE:
      return {
        ...state,
        getAllProfileSkillsRangesStatus: {
          loading: true,
          success: false,
          error: false,
        },
      };
    case GET_ALL_PROFILE_SKILLS_RANGE_RESPONSE:
      return {
        ...state,
        getAllProfileSkillsRangesStatus: {
          loading: false,
          success: true,
          error: false,
        },
        all_profile_skills_ranges: action.response,
      };
    case GET_ALL_PROFILE_SKILLS_RANGE_ERROR:
      return {
        ...state,
        getAllProfileSkillsRangesStatus: {
          loading: false,
          success: false,
          error: true,
        },
      };

    /**  */
    case GET_TAGS:
      return {
        ...state,
        tagsStatus: { loading: true, error: false, success: false },
      };
    case GET_TAGS_RESPONSE:
      return {
        ...state,
        tagsStatus: { loading: false, error: false, success: true },
        tags: action.response.results.sort((a, b) =>
          a.name < b.name ? -1 : 1,
        ),
      };
    case GET_TAGS_ERROR:
      return {
        ...state,
        tagsStatus: { loading: false, error: true, success: false },
        tags: action.response.results.sort((a, b) =>
          a.name < b.name ? -1 : 1,
        ),
      };

    case ADD_SKILL:
      return {
        ...state,
        skillSelected: state.skillSelected + 1,
      };
    case REMOVE_SKILL:
      return { ...state, skillSelected: state.skillSelected - 1 };
    /**  */
    case LOGOUT:
      return { ...state, ...initialState };

    /**  */
    case REHYDRATE:
      let incoming = action.payload;
      if (incoming) {
        return {
          ...state,
          ...incoming.dashboard,
        };
      }
      return state;

    /**  */
    default:
      return state;
  }
};

export default dashboard;
