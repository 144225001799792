import React from 'react';
import EmotionalIntelligenceProfile from '../EmotionalIntelligenceProfile/EmotionalIntelligenceProfile';
import IeBody from '../IeBody/IeBody';
import './IeTemplate.css';
const IeTemplate = React.forwardRef((props) => {
  const { result, playerName, personDetails, lang } = props;
  return (
    <div style={{ fontSize: '20px' }}>
      <IeBody
        name={
          personDetails.user.first_name + ' ' + personDetails.user.last_name
        }
        email={personDetails.user.email}
      />
      <br />
      <div>
        {result?.map((val, idx) => {
          return (
            <div className={lang !== 'es' ? 'wrapper' : ''}>
              <EmotionalIntelligenceProfile
                value={val.value}
                name={playerName}
                axis={val.axis}
                id={idx}
                key={idx}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
});

export default IeTemplate;
