import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import { Tooltip as TooltipBS } from 'react-bootstrap';
import Button from '../Button';
import './Tooltip.css';

class Tooltip extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showTooltip: false,
      specificTooltip: `tooltip_${Math.random()}`,
    };
  }

  handleClick = (e) => {
    this.setState({ showTooltip: !this.state.showTooltip });
  };

  componentDidMount() {
    document.addEventListener('click', (e) => {
      const tooltip = document.getElementById(this.state.specificTooltip);
      if (!tooltip?.contains(e.target) && this.state.showTooltip === true) {
        this.setState({ showTooltip: false });
      }
    });
  }

  componentWillUnmount() {
    document.removeEventListener('click', (e) => {
      const tooltip = document.getElementById(this.state.specificTooltip);
      if (!tooltip?.contains(e.target) && this.state.showTooltip === true) {
        this.setState({ showTooltip: false });
      }
    });
  }

  render() {
    const { className, message, materialIcon, translate, right } = this.props;
    return (
      <div className="Tooltip">
        {this.state.showTooltip && (
          <TooltipBS className="in" placement={right ? 'right' : 'left'}>
            <span className="icon material-symbols-rounded">privacy_tip</span>
            {translate ? I18n.t(message) : message}
          </TooltipBS>
        )}
        <button
          id={this.state.specificTooltip}
          onClick={() => this.handleClick()}
          type="button"
          className="material-symbols-rounded"
          onBlur={() => this.setState({ showTooltip: false })}
        >
          {materialIcon}
        </button>
      </div>
    );
  }
}

Tooltip.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  translate: PropTypes.bool,
};

Tooltip.defaultProps = {
  translate: true,
};

const mapStateToProps = (state) => ({
  showTooltip: this.state.showTooltip,
});

export default Tooltip;
