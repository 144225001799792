import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import Routes from './routes';
import store from './store';
import { ConnectedRouter } from 'connected-react-router';
import actions from './actions';
import './styles/main.css';
import './styles/styles.css';
import {
  loadTranslations,
  setLocale,
  syncTranslationWithStore,
} from 'react-redux-i18n';
import { translations } from './i18n/index';
import { LANGUAGE } from './utils/constants';
import { GoogleOAuthProvider } from '@react-oauth/google';

function startApp() {
  ReactDOM.render(
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID_DASHBOARD}
    >
      <PersistGate
        loading={<div>loading</div>}
        persistor={store.persistor}
        onBeforeLift={() => {
          syncTranslationWithStore(store.store);
          store.store.dispatch(loadTranslations(translations));
          // Language logic for localStorage
          localStorage.setItem('language', LANGUAGE);
          store.store.dispatch(setLocale(LANGUAGE));
          /*  const state = store.store.getState();
        const tokenTS = localStorage['token-ts'];

        if (Date.now() - tokenTS > 1800000 && state.session.isLoggedIn)
        store.store.dispatch(actions.session.logout()); */
        }}
      >
        <Provider store={store.store}>
          <ConnectedRouter history={store.history}>
            <Routes />
          </ConnectedRouter>
        </Provider>
      </PersistGate>
    </GoogleOAuthProvider>,
    document.getElementById('root'),
  );
}

startApp();
