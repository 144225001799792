export const GET_DASHBOARD_REPORT_AGE = 'GET DASHBOARD REPORT AGE';
export const GET_DASHBOARD_REPORT_AGE_RESPONSE =
  'GET DASHBOARD REPORT AGE RESPONSE';
export const GET_DASHBOARD_REPORT_AGE_ERROR = 'GET DASHBOARD REPORT AGE ERROR';

export const GET_DASHBOARD_REPORT_PROFILE = 'GET DASHBOARD REPORT PROFILE';
export const GET_DASHBOARD_REPORT_PROFILE_RESPONSE =
  'GET DASHBOARD REPORT PROFILE RESPONSE';
export const GET_DASHBOARD_REPORT_PROFILE_ERROR =
  'GET DASHBOARD REPORT PROFILE ERROR';

export const GET_DASHBOARD_REPORT_PROFILE_GENDER =
  'GET DASHBOARD REPORT PROFILE GENDER';
export const GET_DASHBOARD_REPORT_PROFILE_GENDER_RESPONSE =
  'GET DASHBOARD REPORT RESPONSE GENDER PROFILE';
export const GET_DASHBOARD_REPORT_PROFILE_GENDER_ERROR =
  'GET DASHBOARD REPORT ERROR GENDER PROFILE';

export const GET_DASHBOARD_REPORT_CITY = 'GET DASHBOARD REPORT CITY';
export const GET_DASHBOARD_REPORT_CITY_RESPONSE =
  'GET DASHBOARD REPORT CITY RESPONSE';
export const GET_DASHBOARD_REPORT_CITY_ERROR =
  'GET DASHBOARD REPORT CITY ERROR';

export const GET_LAST_MONTH = 'GET LAST MONTH';
export const GET_LAST_MONTH_RESPONSE = 'GET LAST MONTH RESPONSE';
export const GET_LAST_MONTH_ERROR = 'GET LAST MONTH ERROR';
export const SHOW_ONBOARDING = 'SHOW ONBOARDING';
export const ADDING_COUNT_ON_BOARDING = 'ADDING COUNT ON BOARDING';
// export const CLEAR_UI_VALUE = 'CLEAR UI VALUE';
export const LOGOUT = 'LOGOUT';

export const UPDATE_CREDITS_WIDGETS = 'UPDATE CREDITS WIDGETS';

export const dashboard = {
  getDashboardReportAge: (filter) => ({
    type: GET_DASHBOARD_REPORT_AGE,
    filter,
  }),
  getDashboardReportAgeResponse: (response) => ({
    type: GET_DASHBOARD_REPORT_AGE_RESPONSE,
    response: response,
  }),
  getDashboardReportAgeError: (error) => ({
    type: GET_DASHBOARD_REPORT_AGE_ERROR,
    response: error,
  }),

  getDashboardReportProfile: (filter) => ({
    type: GET_DASHBOARD_REPORT_PROFILE,
    filter,
  }),
  getDashboardReportProfileResponse: (response) => ({
    type: GET_DASHBOARD_REPORT_PROFILE_RESPONSE,
    response: response,
  }),
  getDashboardReportProfileError: (error) => ({
    type: GET_DASHBOARD_REPORT_PROFILE_ERROR,
    response: error,
  }),

  getDashboardReportProfileGender: (time, filter) => ({
    type: GET_DASHBOARD_REPORT_PROFILE_GENDER,
    time: time,
    filter,
  }),
  getDashboardReportProfileGenderResponse: (response) => ({
    type: GET_DASHBOARD_REPORT_PROFILE_GENDER_RESPONSE,
    response: response,
  }),
  getDashboardReportProfileGenderError: (error) => ({
    type: GET_DASHBOARD_REPORT_PROFILE_GENDER_ERROR,
    response: error,
  }),

  getDashboardReportCity: (filter) => ({
    type: GET_DASHBOARD_REPORT_CITY,
    filter,
  }),
  getDashboardReportCityResponse: (response) => ({
    type: GET_DASHBOARD_REPORT_CITY_RESPONSE,
    response: response,
  }),
  getDashboardReportCityError: (error) => ({
    type: GET_DASHBOARD_REPORT_CITY_ERROR,
    response: error,
  }),

  getLastMonthData: () => ({ type: GET_LAST_MONTH }),
  getLastMonthDataResponse: (response) => ({
    type: GET_LAST_MONTH_RESPONSE,
    response,
  }),
  getLastMonthDataError: () => ({ type: GET_LAST_MONTH_ERROR }),

  // clearUiValue: (type, condition) => ({type: CLEAR_UI_VALUE, op_type: type, condition: condition }),
  logout: () => ({ type: LOGOUT }),
  showOnBoarding: (boolean) => ({ type: SHOW_ONBOARDING, boolean }),
  updateCreditsWidgets: () => ({ type: UPDATE_CREDITS_WIDGETS }),
};
