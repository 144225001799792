import actions from '../actions/index';
import { services } from './campaignsMigrations.services';
import {
  UPDATE_PAGE,
  UPDATE_PAGE_SIZE,
  //GET_ALL_PROFILES,
  GET_ALL_PARTNERS,
  GET_JOB_COMPATIBILITY,
  GET_CAPABILITIES,
  //GET_PROFILE,
  FILTER_PERSONS_MIGRATION,
  UPDATE_PERSONS_FILTER_VALUES,
  MIGRATE_RESULTS,
  MIGRATE_RESULTS_RESPONSE,
  MIGRATE_RESULTS_ERROR,
  SELECT_ALL,
  LOAD_ALL,
} from './campaignsMigrations.actions';
import { parseQueryObject } from '../utils/parser';
import { push } from 'connected-react-router';
import { notify } from 'react-notify-toast';
import { success, error } from '../utils/modals';
import { I18n } from 'react-redux-i18n';

const campaignsMigrationsMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    const language = localStorage.getItem('language');
    const { selection, allSelected, allResultsSelected, page_size } =
      getState().campaignsMigrations;
    switch (action.type) {
      case FILTER_PERSONS_MIGRATION:
        const customFilter = { ...getState().campaignsMigrations.customFilter };
        // const data = getState();
        // console.log(data);
        // debugger;

        // NOTE: delete items that go to the endpoint and not the body
        // endpointFilter.page will check if there is other filter other than ordering, if so
        // the page needs to be reset to 1.
        delete customFilter.search;
        delete customFilter.current;
        delete customFilter.page;
        // delete customFilter.ordering;
        // Request URL: http://34.206.199.137/api/person/filter/?search=jose&page=1&page_size=10&lang=es
        const endpointFilters = {
          search: getState().campaignsMigrations.customFilter.search,
          //ordering: getState().campaignsMigrations.customFilter.ordering,
          page: action.resetPage ? 1 : getState().campaignsMigrations.current,
          page_size: getState().campaignsMigrations.page_size,
          lang: language,
        };
        endpointFilters.page = endpointFilters.page ? endpointFilters.page : 1;
        if (endpointFilters.search?.length) delete action.newFilter.search;
        services
          .filterPersons(customFilter, parseQueryObject(endpointFilters))
          .then((data) =>
            dispatch(actions.campaignsMigrations.filterPersonsResponse(data)),
          )
          .catch((error) =>
            dispatch(actions.campaignsMigrations.filterPersonsError(error)),
          );
        break;

      case UPDATE_PAGE:
        dispatch(
          actions.campaignsMigrations.filterPersons({ isPageUpdate: true }),
        );
        break;

      case UPDATE_PAGE_SIZE:
        dispatch(
          actions.campaignsMigrations.filterPersons({ isPageUpdate: true }),
        );
        break;

      /* case GET_ALL_PROFILES:
        services
          .getAllProfiles('?page_size=50')
          .then((data) =>
            dispatch(actions.campaignsMigrations.getAllProfilesResponse(data)),
          )
          .catch((error) =>
            dispatch(actions.campaignsMigrations.getAllProfilesError(error)),
          );
        break; */

      case GET_ALL_PARTNERS:
        services
          .getAllPartners('?page_size=50')
          .then((data) =>
            dispatch(actions.campaignsMigrations.getAllPartnersResponse(data)),
          )
          .catch((error) =>
            dispatch(actions.campaignsMigrations.getAllPartnersError(error)),
          );
        break;

      case GET_CAPABILITIES:
        services
          .getCapabilities(
            action.job,
            ...getState().campaignsMigrations.selection,
          )
          .then((data) =>
            dispatch(actions.campaignsMigrations.getCapabilitiesResponse(data)),
          )
          .catch((error) =>
            dispatch(actions.campaignsMigrations.getCapabilitiesError(error)),
          );
        break;

      /* case GET_PROFILE:
        services
          .getProfile(action.id)
          .then((data) =>
            dispatch(actions.campaignsMigrations.getProfileResponse(data)),
          )
          .catch((error) =>
            dispatch(actions.campaignsMigrations.getProfileError(error)),
          );
        break; */

      case MIGRATE_RESULTS:
        services
          .migrateResults(action.body)
          .then((response) =>
            dispatch(
              actions.campaignsMigrations.migrateResultsResponse(response),
            ),
          )
          .catch((error) =>
            dispatch(actions.campaignsMigrations.migrateResultsError(error)),
          );
        break;

      case UPDATE_PERSONS_FILTER_VALUES:
        dispatch(
          actions.campaignsMigrations.filterPersons({
            ...action.filter,
            ...getState().campaignsMigrations.customFilter,
          }),
        );
        break;

      // case MIGRATE_RESULTS_ERROR:
      //   notify.show(`${action.error.data}`, 'custom', 5000, error);
      //   break;

      // case MIGRATE_RESULTS_RESPONSE:
      //   const message_success = {
      //     es: 'Migración Completada',
      //     en: 'Migration Completed',
      //     pt: 'Migração Concluída',
      //   };
      //   notify.show(
      //     `${message_success[localStorage.getItem('language')]}`,
      //     'custom',
      //     5000,
      //     success,
      //   );
      //   // dispatch(actions.campaignsMigrations.filterPersons());
      //   break;

      case SELECT_ALL:
        const flag = action.flag;
        if (flag) {
          dispatch(actions.campaignsMigrations.loadAll(1));
        } else {
          dispatch(actions.campaignsMigrations.clearSelection());
        }
        break;
      //OPTIONS

      case LOAD_ALL:
        const pageFilter = {
          //page: action.page,
          lang: localStorage.getItem('language'),
          //page_size,
        };
        services
          .filterPersons(
            getState().campaignsMigrations.customFilter,
            parseQueryObject(pageFilter),
          )
          .then((data) => {
            const currentResults = data.results.map((item) => ({
              id_result: item.result.id,
              id_invitation: item.id,
            }));

            dispatch(actions.campaignsMigrations.saveAll(currentResults));
            dispatch(actions.campaignsMigrations.allLoaded());
          })
          .catch((error) =>
            dispatch(actions.campaignsMigrations.filterPersonsError(error)),
          );
        break;

      default:
        break;
    }
  };

export default campaignsMigrationsMiddleware;
