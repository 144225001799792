export const GET_CLIENTS = 'GET CLIENTS';
export const GET_CLIENTS_RESPONSE = 'GET CLIENTS RESPONSE';
export const GET_CLIENTS_ERROR = 'GET CLIENTS ERROR';

/* !! Only usable if the user is ADMIN */
export const GET_ALL_CLIENTS = 'GET_ALL_CLIENTS';
export const GET_BRANCHS = 'GET_BRANCHS';
export const GET_BRANCHS_RESPONSE = 'GET_BRANCHS_RESPONSE';
export const GET_ALL_CLIENTS_RESPONSE = 'GET_ALL_CLIENTS_RESPONSE';
export const GET_ALL_CLIENTS_ERROR = 'GET_ALL_CLIENTS_ERROR';

export const GET_CLIENT_GROUPS = 'GET CLIENT GROUPS';
export const GET_CLIENT_GROUPS_RESPONSE = 'GET CLIENT GROUPS RESPONSE';
export const GET_CLIENT_GROUPS_ERROR = 'GET CLIENT GROUPS ERROR';

export const CREATE_CLIENT = 'CREATE CLIENT';
export const CREATE_CLIENT_RESPONSE = 'CREATE CLIENT RESPONSE';
export const CREATE_CLIENT_ERROR = 'CREATE CLIENT ERROR';

export const UPDATE_CLIENT = 'UPDATE CLIENT';
export const UPDATE_CLIENT_RESPONSE = 'UPDATE CLIENT RESPONSE';
export const UPDATE_CLIENT_ERROR = 'UPDATE CLIENT ERROR';
export const UPDATE_VIEW_BRANCH = 'UPDATE VIEW BRANCH';

export const DELETE_CLIENT = 'DELETE CLIENT';
export const DELETE_CLIENT_RESPONSE = 'DELETE CLIENT RESPONSE';
export const DELETE_CLIENT_ERROR = 'DELETE CLIENT ERROR';

export const UPDATE_CREDITS = 'UPDATE_CREDITS';
export const UPDATE_CREDITS_RESPONSE = 'UPDATE_CREDITS_RESPONSE';
export const UPDATE_CREDITS_ERROR = 'UPDATE_CREDITS_ERROR';

export const SELECT_CLIENT = 'SELECT CLIENT';
export const UPDATE_PAGE = 'UPDATE PAGE CLIENTS';
export const UPDATE_PAGE_SIZE = 'UPDATE PAGE CLIENTS SIZE';
export const UPDATE_CLIENTS_FILTER_VALUES = 'UPDATE CLIENTS FILTER VALUES';

export const CLEAR_UI_VALUE = 'CLEAR UI VALUE';
export const LOGOUT = 'LOGOUT';

export const UPLOAD_ATTACHMENT_REQUEST = 'UPLOAD_ATTACHMENT_REQUEST';
export const UPLOAD_ATTACHMENT_RESPONSE = 'UPLOAD_ATTACHMENT_RESPONSE';
export const UPLOAD_ATTACHMENT_ERROR = 'UPLOAD_ATTACHMENT_ERROR';

export const GET_CLIENT_TYPES = 'GET CLIENT TYPES';
export const GET_CLIENT_TYPES_RESPONSE = 'GET CLIENT TYPES RESPONSE';
export const GET_CLIENT_TYPES_ERROR = 'GET CLIENT TYPES ERROR';

export const GET_LOGO_URL = 'GET LOGO URL';
export const GET_LOGO_URL_RESPONSE = 'GET LOGO URL RESPONSE';
export const GET_LOGO_URL_ERROR = 'GET LOGO URL ERROR';

export const GET_CLIENTS_SCREEN = 'GET_CLIENTS_SCREEN';

export const BACK_FROM_DETAIL = 'BACK_FROM_DETAIL';

export const CLEAN_TABLE = 'CLEAN_TABLE';

export const clients = {
  createClient: (body) => ({ type: CREATE_CLIENT, body: body }),
  createClientResponse: (response) => ({
    type: CREATE_CLIENT_RESPONSE,
    response,
  }),
  createClientError: (error) => ({
    type: CREATE_CLIENT_ERROR,
    response: error,
  }),

  getClients: (view_branch, search, filterDates, current, client_type) => ({
    type: GET_CLIENTS,
    view_branch,
    search,
    filterDates,
    current,
    client_type,
  }),
  getClientsResponse: (response) => ({ type: GET_CLIENTS_RESPONSE, response }),
  getClientsError: (error) => ({ type: GET_CLIENTS_ERROR, response: error }),

  getClientsScreen: (screen) => ({ type: GET_CLIENTS_SCREEN, screen }),

  getAllClients: (accountDropdown, view_branch, view_account, search) => ({
    type: GET_ALL_CLIENTS,
    accountDropdown,
    view_branch,
    view_account,
    search,
  }),
  getBranchs: (account_id, search) => ({
    type: GET_BRANCHS,
    account_id,
    search,
  }),
  getBranchsResponse: (response) => ({ type: GET_BRANCHS_RESPONSE, response }),
  getAllClientsResponse: (response) => ({
    type: GET_ALL_CLIENTS_RESPONSE,
    response,
  }),
  getAllClientsError: (error) => ({
    type: GET_ALL_CLIENTS_ERROR,
    response: error,
  }),

  getClientGroups: (password) => ({
    type: GET_CLIENT_GROUPS,
    password: password,
  }),
  getClientGroupsResponse: (response) => ({
    type: GET_CLIENT_GROUPS_RESPONSE,
    response: response,
  }),
  getClientGroupsError: (error) => ({
    type: GET_CLIENT_GROUPS_ERROR,
    error: error,
  }),

  updateClient: (logo, client) => ({
    type: UPDATE_CLIENT,
    client: client,
    logo,
  }),
  updateClientResponse: (response) => ({
    type: UPDATE_CLIENT_RESPONSE,
    response: response,
  }),
  updateClientError: (error) => ({ type: UPDATE_CLIENT_ERROR, error: error }),

  deleteClient: (filter) => ({ type: DELETE_CLIENT, filter }),
  deleteClientResponse: (response) => ({
    type: DELETE_CLIENT_RESPONSE,
    response,
  }),
  deleteClientError: (error) => ({ type: DELETE_CLIENT_ERROR, error }),

  updateCredits: (partnerId, amount) => ({
    type: UPDATE_CREDITS,
    partnerId,
    amount,
  }),
  updateCreditsResponse: (response) => ({
    type: UPDATE_CREDITS_RESPONSE,
    response,
  }),
  updateCreditsError: (error) => ({ type: UPDATE_CREDITS_ERROR, error }),

  selectClient: (id) => ({ type: SELECT_CLIENT, id }),
  updateFilterValues: (filterValues) => ({
    type: UPDATE_CLIENTS_FILTER_VALUES,
    filterValues,
  }),
  updatePage: (pageNumber) => {
    return {
      type: UPDATE_PAGE,
      pageNumber,
    };
  },
  updatePageSize: (pageSize) => ({
    type: UPDATE_PAGE_SIZE,
    pageSize: pageSize,
  }),

  clearUiValue: (type, condition) => ({
    type: CLEAR_UI_VALUE,
    op_type: type,
    condition,
  }),

  logout: () => ({ type: LOGOUT }),

  uploadAttachment: (file) => ({ type: UPLOAD_ATTACHMENT_REQUEST, file }),
  uploadAttachmentResponse: (response, uri) => ({
    type: UPLOAD_ATTACHMENT_RESPONSE,
    response,
    uri,
  }),
  uploadAttachmentError: (error) => ({ type: UPLOAD_ATTACHMENT_ERROR, error }),

  getClientTypes: () => ({ type: GET_CLIENT_TYPES }),
  getClientTypesResponse: (response) => ({
    type: GET_CLIENT_TYPES_RESPONSE,
    response,
  }),
  getClientTypesError: (error) => ({ type: GET_CLIENT_TYPES_ERROR, error }),

  getLogoUrl: (fileName) => ({ type: GET_LOGO_URL, fileName }),
  getLogoUrlResponse: (response) => ({ type: GET_LOGO_URL_RESPONSE, response }),
  getLogoUrlError: (error) => ({ type: GET_LOGO_URL_ERROR, error }),

  backFromDetail: (to) => ({ type: BACK_FROM_DETAIL, to }),

  updateViewBranch: (bool) => ({ type: UPDATE_VIEW_BRANCH, bool }),

  cleanTable: () => ({ type: CLEAN_TABLE }),
};
