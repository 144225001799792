import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Credits from '../components/Credits/Credits';
import actions from '../../actions';

const mapStateToProps = (state, ownProps) => ({
  user:
    ownProps.user && ownProps.user.partner ? ownProps.user : state.session.user,
  user_: state.session.user,
});

const mapDispatchToProps = (dispatch) => ({
  getPartner: (id) => dispatch(actions.session.getPartner(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Credits));
