import React, { useEffect, useState } from 'react';
import Navbar from '../../../containers/Navbar';
import 'react-tooltip/dist/react-tooltip.css';

const DashboardLayout = ({ children }) => {
  const [changedPage, setChangedPage] = useState(false);
  const [showFontIcon, setShowFontIcon] = useState(false);

  useEffect(() => {
    setChangedPage(!changedPage);
  }, [children]);

  useEffect(() => {
    const interval = setInterval(() => {
      document.fonts
        .load('12px Material Symbols Rounded', 'help')
        .then((value) => {
          setShowFontIcon(true);
          clearInterval(interval);
        })
        .catch((error) => {});
    }, 300);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {!showFontIcon && (
        <style>{`.material-symbols-filled, .material-symbols-rounded { display: none !important; }`}</style>
      )}
      <div
        className={`dashboard-layout ${window.location.pathname
          .replaceAll('/', '-')
          .slice(1, window.location.pathname.length)}`}
      >
        <Navbar key={changedPage} />
        {children}
      </div>
    </>
  );
};
export default DashboardLayout;
