import React, { Component } from 'react';
import './EmotionalIntelligenceResults.css';
import { I18n } from 'react-redux-i18n';

const LEVELS = [5, 9, 13, 18, 22];

class EmotionalIntelligenceResults extends Component {
  static defaultProps;
  state = {
    isOpen: false,
  };

  componentDidMount() {
    document.addEventListener('click', (e) => {
      if (e.target.id === this.props.id.toString()) {
        this.setState({ isOpen: !this.state.isOpen });
      } else {
        this.setState({ isOpen: false });
      }
    });
  }

  componentWillUnmount() {
    document.removeEventListener('click', (e) => {
      if (e.target.id === this.props.id.toString()) {
        this.setState({ isOpen: true });
      } else {
        this.setState({ isOpen: false });
      }
    });
  }

  obtainResults(value, level) {
    if (value < level) return 'none';
    if (value >= LEVELS[4]) return 'very-high';
    if (value >= LEVELS[3]) return 'high';
    if (value >= LEVELS[2]) return 'half';
    if (value >= LEVELS[1]) return 'low';
    if (value >= LEVELS[0]) return 'very-low';
    return 'none';
  }

  obtainOportunities(axis) {
    if (axis === 'self-knowledge')
      return [
        { position: 1, name: 'emotional-newspaper', iconName: 'auto_stories' },
        {
          position: 2,
          name: 'personality-tests',
          iconName: 'settings_accessibility',
        },
        { position: 3, name: 'meditation', iconName: 'self_improvement' },
        { position: 4, name: 'self-observation', iconName: 'visibility' },
      ];
    if (axis === 'self-regulation')
      return [
        { position: 1, name: 'deep-breathing', iconName: 'pulmonology' },
        {
          position: 2,
          name: 'an-exercise-routine',
          iconName: 'sports_martial_arts',
        },
        { position: 3, name: 'mindfulness', iconName: 'neurology' },
        { position: 4, name: 'yoga-or-tai-chi', iconName: 'sports_gymnastics' },
      ];
    if (axis === 'motivation')
      return [
        { position: 1, name: 'smart-goals', iconName: 'crisis_alert' },
        {
          position: 2,
          name: 'the-pomodoro-technique',
          iconName: 'avg_pace',
        },
        { position: 3, name: 'his-why', iconName: 'psychology_alt' },
        {
          position: 4,
          name: 'a-list-of-achievements',
          iconName: 'emoji_events',
        },
      ];
    if (axis === 'empathy')
      return [
        { position: 1, name: 'active-listening', iconName: 'hearing' },
        {
          position: 2,
          name: 'diverse-reading',
          iconName: 'local_library',
        },
        { position: 3, name: 'role-exercises', iconName: 'diversity_3' },
        {
          position: 4,
          name: 'observation-and-imagination',
          iconName: 'mystery',
        },
      ];
    if (axis === 'social-skills')
      return [
        { position: 1, name: 'group-activities', iconName: 'diversity_2' },
        {
          position: 2,
          name: 'a-communication-workshop',
          iconName: 'communication',
        },
        {
          position: 3,
          name: 'assertiveness-practices',
          iconName: 'record_voice_over',
        },
        { position: 4, name: 'body-language', iconName: 'sign_language' },
      ];
    return [
      { position: 1, name: 'emotional-newspaper', iconName: 'auto_stories' },
      {
        position: 2,
        name: 'personality-tests',
        iconName: 'settings_accessibility',
      },
      { position: 3, name: 'meditation', iconName: 'self_improvement' },
      { position: 4, name: 'self-observation', iconName: 'visibility' },
    ];
  }

  render() {
    const { isOpen } = this.state;
    const { id, value, axis, name } = this.props;

    const obtainedResult = this.obtainResults(value);
    const obteinedOportunities = this.obtainOportunities(axis);
    return (
      <div className="container-ie-results">
        <div className={`header`} id={id}>
          <div className="header-left">
            <h3>{I18n.t(`ie.axes.${axis}`)}</h3>
            <div className={'ads-result ' + obtainedResult}>
              {I18n.t(`ie.levels.${obtainedResult}`)}
            </div>
          </div>
          <div className="collapse-details">
            <p id={id}>
              {isOpen ? I18n.t('ie.see-less') : I18n.t('ie.see-more')}
            </p>
            <span className="material-symbols-rounded expand" id={id}>
              {!isOpen ? 'expand_more' : 'expand_less'}
            </span>
          </div>
        </div>
        <div className="bar">
          {LEVELS.map((level, i) => (
            <div
              key={i}
              className={'result ' + this.obtainResults(value, level)}
            ></div>
          ))}
        </div>
        {isOpen && (
          <div className="details">
            <div className="first-row">
              <div className="card strengths">
                <div className="title">
                  {' '}
                  <span className="material-symbols-filled icon">
                    verified
                  </span>{' '}
                  <p>{I18n.t(`ie.strengths.title`)}</p>
                </div>
                <div className="strengths-detail">
                  <ul>
                    <li>
                      <p>
                        {I18n.t(
                          `ie.strengths.returns.${axis}.${obtainedResult}`,
                          {
                            name: name,
                          },
                        )}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="card strengths">
                <div className="title">
                  <span className="material-symbols-filled icon">whatshot</span>
                  <p>{' ' + I18n.t(`ie.aspects-to-improve.title`)}</p>
                </div>
                <div className="strengths-detail">
                  <ul>
                    <li>
                      <p>
                        {I18n.t(
                          `ie.aspects-to-improve.returns.${axis}.${obtainedResult}`,
                          {
                            name: name,
                          },
                        )}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="improvement-opportunities">
              <p>
                {I18n.t(`ie.improvement-opportunities`, {
                  name: name,
                  eje: I18n.t(`ie.axes.${axis}`).toLowerCase(),
                })} {' '}
                {I18n.t(`ie.recommended.course.${axis}.${obtainedResult}`)}
              </p>
              <div className="opportunities">
                {obteinedOportunities.map((item, i) => (
                  <div className="opportunity" key={i}>
                    <span className="material-symbols-filled icon">
                      {item.iconName}
                    </span>
                    {I18n.t(`ie.oportunities.${item.name}`)}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

EmotionalIntelligenceResults.defaultProps = {};

export default EmotionalIntelligenceResults;
