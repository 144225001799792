import React, { Component } from 'react';
import Loader from '../Loader';
import './TableLoader.scss';

export class TableLoader extends Component {
  render() {
    return this.props.loading ? (
      <div className="table-loader">
        <Loader />
      </div>
    ) : null;
  }
}

export default TableLoader;
