export const GET_JOBS = 'GET JOBS';
export const GET_JOBS_RESPONSE = 'GET JOBS RESPONSE';
export const GET_JOBS_ERROR = 'GET JOBS ERROR';

export const CREATE_JOB = 'CREATE JOB';
export const CREATE_JOB_RESPONSE = 'CREATE JOB RESPONSE';
export const CREATE_JOB_ERROR = 'CREATE JOB ERROR';

export const UPDATE_JOB = 'UPDATE JOB';
export const UPDATE_JOB_RESPONSE = 'UPDATE JOB RESPONSE';
export const UPDATE_JOB_ERROR = 'UPDATE JOB ERROR';

export const DELETE_JOB = 'DELETE JOB';
export const DELETE_JOB_RESPONSE = 'DELETE JOB RESPONSE';
export const DELETE_JOB_ERROR = 'DELETE JOB ERROR';

export const CLEAN_JOB_ERROR = 'CLEAN_JOB_ERROR';

export const SELECT_JOB = 'SELECT JOB';

export const UPDATE_PAGE = 'UPDATE PAGE JOBS';
export const UPDATE_PAGE_SIZE = 'UPDATE PAGE JOBS SIZE';
export const UPDATE_JOBS_FILTER_VALUES = 'UPDATE JOBS FILTER VALUES';
export const CLEAR_UI_VALUE_JOBS = 'CLEAR_UI_VALUE_JOBS';

export const LOGOUT = 'LOGOUT';

export const jobs = {
  createJob: (body, typeCreate) => ({
    type: CREATE_JOB,
    body: body,
    typeCreate,
  }),
  createJobResponse: (response, typeCreate) => ({
    type: CREATE_JOB_RESPONSE,
    response,
    typeCreate,
  }),
  createJobError: (error) => ({ type: CREATE_JOB_ERROR, response: error }),

  getJobs: (positionDropdown) => ({ type: GET_JOBS, positionDropdown }),
  getJobsResponse: (response) => ({ type: GET_JOBS_RESPONSE, response }),
  getJobsError: (error) => ({ type: GET_JOBS_ERROR, response: error }),

  updateJob: (id, job, typeUpdate) => ({
    type: UPDATE_JOB,
    id,
    job,
    typeUpdate,
  }),
  updateJobResponse: (response, typeUpdate) => ({
    type: UPDATE_JOB_RESPONSE,
    response,
    typeUpdate,
  }),
  updateJobError: (error) => ({ type: UPDATE_JOB_ERROR, error }),

  deleteJob: (id) => ({ type: DELETE_JOB, id: id }),
  deleteJobResponse: (response) => ({ type: DELETE_JOB_RESPONSE, response }),
  deleteJobError: (error) => ({ type: DELETE_JOB_ERROR, error }),

  cleanJobError: () => ({ type: CLEAN_JOB_ERROR }),

  selectJob: (id) => ({ type: SELECT_JOB, id: id }),

  updateFilterValues: (filterValues) => ({
    type: UPDATE_JOBS_FILTER_VALUES,
    filterValues,
  }),
  updatePage: (pageNumber) => ({ type: UPDATE_PAGE, pageNumber }),
  updatePageSize: (pageSize) => ({ type: UPDATE_PAGE_SIZE, pageSize }),

  clearUiValue: (type, condition) => ({
    type: CLEAR_UI_VALUE_JOBS,
    op_type: type,
    condition,
  }),

  logout: () => ({ type: LOGOUT }),
};
