import actions from '../actions/index';
import { services } from './clients.services';

import {
  GET_CLIENTS,
  CREATE_CLIENT,
  UPDATE_CLIENT,
  DELETE_CLIENT,
  UPDATE_PAGE,
  UPDATE_PAGE_SIZE,
  UPDATE_CREDITS,
  GET_ALL_CLIENTS,
  GET_CLIENT_GROUPS,
  UPLOAD_ATTACHMENT_REQUEST,
  GET_CLIENT_TYPES,
  GET_BRANCHS,
  GET_LOGO_URL,
} from './clients.actions';
import { parseQueryObject, parseSize } from '../utils/parser';
import { push } from 'connected-react-router';

const clientsMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    const user = getState().session.user;
    const search = getState().clients.search;
    const view_branch = getState().clients.view_branch;
    const typeSelection = getState().clients.typeSelection;
    const current = getState().clients.current;

    switch (action.type) {
      case GET_CLIENTS:
        const actionFilter = { ...getState().clients.filterValues };
        const filter = {
          ...actionFilter,
          page: action.current ? action.current : getState().clients.current,
          page_size: getState().clients.page_size,
          lang: localStorage.getItem('language'),
        };

        services
          .getClients(
            parseQueryObject(filter),
            action.view_branch,
            action.search,
            action.filterDates,
            action.client_type,
          )
          .then((data) => dispatch(actions.clients.getClientsResponse(data)))
          .catch((error) => dispatch(actions.clients.getClientsError(error)));
        break;

      case GET_ALL_CLIENTS:
        services
          .getAllClients({
            accountDropdown: action.accountDropdown,
            view_branch: action.view_branch,
            view_account: action.view_account,
            search: action.search,
          })
          .then((data) => dispatch(actions.clients.getAllClientsResponse(data)))
          .catch((error) =>
            dispatch(actions.clients.getAllClientsError(error)),
          );
        break;

      case GET_BRANCHS:
        services
          .getBranchs(
            action.account_id,
            action.search === undefined ? '' : action.search,
          )
          .then((data) => {
            dispatch(actions.clients.getBranchsResponse(data));
          })
          .catch((error) => {
            dispatch(actions.clients.getAllClientsError(error));
          });
        break;

      case UPDATE_PAGE:
        if (typeSelection !== '') {
          return dispatch(
            actions.clients.getClients(
              view_branch,
              search,
              null,
              current,
              typeSelection,
            ),
          );
        }
        dispatch(actions.clients.getClients(view_branch, search));
        break;

      case UPDATE_PAGE_SIZE:
        dispatch(
          actions.clients.getClients(
            view_branch,
            search,
            null,
            current,
            typeSelection,
          ),
        );
        break;

      case UPDATE_CLIENT:
        /* const logo = getState().clients.logo_id;
        let { client_type, isSuperDashboard, ...update_body } = action.client;
        if (logo !== null) {
          update_body = { ...update_body, logo_id: logo };
        }
        if (client_type) {
          update_body = { ...update_body, client_type_id: client_type };
        }
        update_body = { ...update_body, is_super_dashboard: isSuperDashboard }; */

        services
          .updateClient(action.logo, action.client)
          .then((data) => {
            dispatch(actions.clients.updateClientResponse(data));
            dispatch(
              actions.clients.getClients(data.nwm_accountId ? true : false),
            );
            if (!user.user_admin)
              dispatch(actions.session.getPartner(user.account.id));

            dispatch(push('/main/clients'));
          })
          .catch((error) => dispatch(actions.clients.updateClientError(error)));
        break;

      case DELETE_CLIENT:
        services
          .deleteClient(action.filter)
          .then((data) => {
            dispatch(actions.clients.deleteClientResponse(data));
            if (data.nwm_user_company_x_branch) {
              dispatch(actions.clients.getClients(true, ''));
            } else {
              dispatch(actions.clients.getClients(false, ''));
            }
            if (!user.user_admin)
              dispatch(actions.session.getPartner(user.account.id));
            dispatch(push('/main/clients'));
          })
          .catch((error) => dispatch(actions.clients.deleteClientError(error)));
        break;

      case CREATE_CLIENT:
        const {
          client_type: type,
          isSuperDashboard: is_super_dashboard,
          ...restBody
        } = action.body;
        /* const create_body = {
          ...restBody,
          logo_id: getState().clients.logo_id,
          client_type_id: type,
          is_super_dashboard,
        }; */
        services
          .createClient(action.body)
          .then((response) => {
            dispatch(actions.clients.createClientResponse(response));
            if (!user.user_admin)
              dispatch(actions.session.getPartner(user.account.id));
            dispatch(push('/main/clients'));
          })
          .catch((error) => dispatch(actions.clients.createClientError(error)));
        break;

      case UPDATE_CREDITS:
        let partner_key = 'branch_id';
        if (user.user_admin) {
          partner_key = 'account_id';
        }
        services
          .updateCredits({
            [partner_key]: action.partnerId,
            credits: action.amount,
          })
          .then((response) => {
            dispatch(actions.clients.updateCreditsResponse(response));
            dispatch(
              actions.session.getPartner(getState().session.user.partner?.id),
            );
            dispatch(actions.clients.getClients());
            if (!user.user_admin)
              dispatch(actions.session.getPartner(user.account.id));
          })
          .catch((error) =>
            dispatch(actions.clients.updateCreditsError(error)),
          );
        break;

      case GET_CLIENT_GROUPS:
        services
          .getClientGroups()
          .then((data) =>
            dispatch(actions.clients.getClientGroupsResponse(data)),
          )
          .catch((error) =>
            dispatch(actions.clients.getClientGroupsError(error)),
          );
        break;

      case GET_CLIENT_TYPES:
        services
          .getAllClientTypes()
          .then((data) =>
            dispatch(actions.clients.getClientTypesResponse(data)),
          )
          .catch((error) =>
            dispatch(actions.clients.getClientTypesError(error)),
          );
        break;

      case UPLOAD_ATTACHMENT_REQUEST:
        services
          .getAttachmentURL(action.file.name, action.file.type)
          .then((data) => {
            services
              .uploadAttachment(data.url, action.file)
              .then((awsData) => {
                services
                  .confirmAttachmentUpload(
                    data.attachment_id,
                    parseSize(action.file.size),
                  )
                  .then((confirmData) => {
                    dispatch(
                      actions.clients.uploadAttachmentResponse(
                        data,
                        awsData.url,
                      ),
                    );
                  })
                  .catch((error) =>
                    dispatch(actions.clients.uploadAttachmentError(error)),
                  );
              })
              .catch((error) =>
                dispatch(actions.clients.uploadAttachmentError(error)),
              );
          })
          .catch((error) =>
            dispatch(actions.clients.uploadAttachmentError(error)),
          );
        break;

      case GET_LOGO_URL:
        services
          .getLogoUrl(action.fileName)
          .then((data) => {
            dispatch(actions.clients.getLogoUrlResponse(data));
          })
          .catch((error) => {
            dispatch(actions.clients.getLogoUrlError(error));
          });
        break;
      default:
        break;
    }
  };

export default clientsMiddleware;
