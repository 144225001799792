import { connect } from 'react-redux';
import actions from '../../actions/index';
import NewCampaign from '../components/NewCampaign/NewCampaign';
import { withRouter } from 'react-router';

const mapStateToProps = (state) => ({
  createStatus: state.campaigns.createStatus,
  getCampaignsStatus: state.campaigns.getCampaignsStatus,
  updateStatus: state.campaigns.updateStatus,
  campaignDeleteStatus: state.campaigns.campaignDeleteStatus,
  selectedCampaign: state.campaigns.selectedCampaign,
  positions: state.campaigns.positions,
  fields: state.campaigns.fields,
  generateCodeStatus: state.campaigns.generateCodeStatus,
  partner: state.session.user.partner,
  errorCode: state.campaigns.errorCode,
  user_: state.session.user,
  credits_user_status: state.campaigns.credits_user_status,
});

const mapDispatchToProps = (dispatch) => ({
  getCampaigns: () => dispatch(actions.campaigns.getCampaigns()),
  updateCampaign: (id, campaign) =>
    dispatch(actions.campaigns.updateCampaign(id, campaign)),
  createCampaign: (body) => dispatch(actions.campaigns.createCampaign(body)),
  clearUiValue: (type, condition) =>
    dispatch(actions.campaigns.clearUiValue(type, condition)),
  generateCode: (id, email) =>
    dispatch(actions.campaigns.generateCode(id, email)),
  updateCode: (id, code) => dispatch(actions.campaigns.updateCode(id, code)),
  deleteCode: (campaign_id, id) =>
    dispatch(actions.campaigns.deleteCode(campaign_id, id)),
  campaignCleanErrors: () => dispatch(actions.campaigns.campaignCleanErrors()),
  getPositions: ({ positionDropdown, search = '' }) =>
    dispatch(actions.campaigns.getPositions(positionDropdown, search)),
  getFields: () => dispatch(actions.campaigns.getFields()),
  selectCampaign: (id) => dispatch(actions.campaigns.selectCampaign(id)),
  unselectCampaign: () => dispatch(actions.campaigns.unselectCampaign()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(NewCampaign));
