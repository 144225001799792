export const GET_CREDITS_HISTORY = 'GET_CREDITS_HISTORY';
export const GET_CREDITS_HISTORY_RESPONSE = 'GET_CREDITS_HISTORY_RESPONSE';
export const GET_CREDITS_HISTORY_ERROR = 'GET_CREDITS_HISTORY_ERROR';

export const UPDATE_PAGE_CREDITS_HISTORY = 'UPDATE_PAGE_CREDITS_HISTORY';
export const UPDATE_PAGE_SIZE_CREDITS_HISTORY =
  'UPDATE_PAGE_SIZE_CREDITS_HISTORY';

export const UPDATE_FILTER_VALUES_CREDITS_HISTORY =
  'UPDATE_FILTER_VALUES_CREDITS_HISTORY';

export const LOGOUT = 'LOGOUT';

export const GET_FILTER_DATES = 'GET_FILTER_DATES';

export const CREDIT_FILTER_CLEAR = 'CREDIT_FILTER_CLEAR';
export const SET_CREDIT_OPTION = 'SET_CREDIT_OPTION';

export const GET_ACTUAL_LOCATION = 'GET_ACTUAL_LOCATION';

export const common = {
  setCreditOption: (state) => ({
    type: SET_CREDIT_OPTION,
    state,
  }),
  setCreditFilterClear: (value) => ({
    type: CREDIT_FILTER_CLEAR,
    value,
  }),
  getFilterDates: (filterDates) => ({
    type: GET_FILTER_DATES,
    filterDates,
  }),
  getCreditsHistory: (resetPage = false) => ({
    type: GET_CREDITS_HISTORY,
    resetPage,
  }),
  getCreditsHistoryResponse: (response) => ({
    type: GET_CREDITS_HISTORY_RESPONSE,
    response,
  }),
  getCreditsHistoryError: (error) => ({
    type: GET_CREDITS_HISTORY_ERROR,
    error,
  }),
  getActualLocation: (location) => ({
    type: GET_ACTUAL_LOCATION,
    location,
  }),

  updateFilterValues: (filter) => ({
    type: UPDATE_FILTER_VALUES_CREDITS_HISTORY,
    filter,
  }),
  updatePageCreditsHistory: (pageNumber) => ({
    type: UPDATE_PAGE_CREDITS_HISTORY,
    pageNumber,
  }),
  updatePageSizeCreditsHistory: (pageSize) => ({
    type: UPDATE_PAGE_SIZE_CREDITS_HISTORY,
    pageSize,
  }),
  logout: () => ({ type: LOGOUT }),
};
