// getHashParams:  obtiene el acces token de la URL despues de hacer login con el servicio de autorización.
export const getHashParams = () => {
  return window.location.search
    .substring(1)
    .split('&')
    .reduce(function (initial, item) {
      if (item) {
        var parts = item.split('=');
        initial[parts[0]] = decodeURIComponent(parts[1]);
      }
      return initial;
    }, {});
};

// removeHashParamsFromUrl limpiamos la URL.
export const removeHashParamsFromUrl = () => {
  window.history.pushState(
    '',
    document.title,
    window.location.pathname + window.location.search,
  );
};
