import React, { Component } from 'react';
import './ProgressBar.css';

/**
 * Progress Bar component documentation
 *
 * @props
 *  - name {string} id (TODO: change to id).
 *  - label {string} @optional text before the bar.
 *  - value {int} value 0 to 1 (percentage).
 */
export class ProgressBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hovered: false,
    };
  }
  render() {
    if (this.props.dashboard) {
      const { name, label, value, long, secondaryClass } = this.props;
      // console.log(name, label, value);
      return (
        <div className="dashboard-progress-bar-container" id={name}>
          <div
            className={`text-header${secondaryClass} ${
              this.state.hovered ? 'hovered' : ''
            }`}
          >
            <span>{label}</span>
            <span hidden={!this.state.hovered}>{value}</span>
          </div>
          <div
            className="progress-bar"
            onMouseEnter={() => this.setState({ ...this.state, hovered: true })}
            onMouseLeave={() =>
              this.setState({ ...this.state, hovered: false })
            }
          >
            <div
              className={`bar${secondaryClass}`}
              role="progressbar"
              style={{ width: `${long}%` }}
              aria-valuenow={value}
              aria-valuemin="0"
              aria-valuemax="100"
            />
          </div>
        </div>
      );
    } else if (this.props.persons) {
      const { name, value } = this.props;
      return (
        <div className="persons-progress-bar-container" id={name}>
          <div className="progress-bar">
            <div
              className="bar"
              role="progressbar"
              aria-valuenow={value}
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: `${value}%` }}
            />
          </div>
          <span className="number">{Math.round(value * 100) / 100 + '%'}</span>
        </div>
      );
    } else if (this.props.compatibilities) {
      const { name, value } = this.props;
      return (
        <div className="compatibilities-progress-bar-container" id={name}>
          <div className="progress-bar">
            <div
              className="bar"
              role="progressbar"
              style={{ width: `${value}%` }}
              aria-valuenow={value}
              aria-valuemin="0"
              aria-valuemax="100"
            >
              {value > 21 && (
                <div className={'number'}>
                  {Math.round(value * 100) / 100 + '%'}
                </div>
              )}
            </div>
            {value <= 21 && (
              <div className="number low-percent">
                {Math.round(value * 100) / 100 + '%'}
              </div>
            )}
          </div>
        </div>
      );
    }
  }
}

export default ProgressBar;
