import React, { Component } from 'react';
import './ClientsScreen.css';
import 'react-table/react-table.css';
import { notify } from 'react-notify-toast';
import { Tabs, Tab } from 'react-bootstrap';
import { I18n } from 'react-redux-i18n';
import { error, success } from '../../../utils/modals';
import Button from '../../../components/common/Button';
import ReactTable from 'react-table';
import DateRangePickerFilter from '../../../components/common/DateRangePickerFilter/DateRangePickerFilter';
import { parseDate } from '../../../utils/parser';
import DeleteClientModal from '../../../components/common/DeleteClientModal/DeleteClientModal';
import {
  errorMessage,
  successMessage,
  SnackBar,
} from '../../../utils/snackbar';
import Loader from '../../../components/common/Loader';
import TablePaginationDrawer from '../../../components/common/TablePaginationDrawer/TablePaginationDrawer';
import TableLoader from '../../../components/common/TableLoader/TableLoader';
import { NumberLabel } from '../../../components/common/NumberLabel/NumberLabel';
import CreditsHistory from '../../../components/common/CreditsHistory/CreditsHistory';
import Credits from '../../../campaigns/containers/Credits';
import Input from '../../../components/common/Input';
import Dropdown from '../../../components/common/Dropdown/Dropdown';
import FormDropdown from '../../../components/common/Dropdown/FormDropdown';
import CreditsWidgets from '../../../components/common/CreditsWidgets/CreditsWidgets';
import DeleteModal from '../../../components/common/ModalAlert/ModalAlert';

import dayjs from 'dayjs';
import TableMenuItem from '../../../components/common/TableMenuItem/TableMenuItem';
class ClientsScreen extends Component {
  state = {
    showModal: false,
    clientId: undefined,
    isSuperDashboard: this.props.user.partner?.is_super_dashboard,
    view_branch: this.props.view_branch_global
      ? this.props.view_branch_global
      : this.props.user.user_admin
      ? false
      : true,
    view_credits: false,
    values: {
      search: { inputValue: '' },
    },
    newOrdering: '',
    orderingFilterObj: {
      id: 'name',
      desc: false,
    },
    range: {
      start: this.props?.customFilter?.start_range || this.props.start_date,
      end: this.props?.customFilter?.end_range || this.props.end_date,
    },
    isReset: true,
    cleanFilter: false,
    isClicked: false,
    workingCell: '',
    selectedClient: {},
    currentTab: '',
    heightCard: 0,
    clearInputValue: false,
  };

  componentDidMount() {
    // let orderingValue = 'created';
    // if (this.props.history.location.state) {
    //   const stateValue = this.props.history.location.state.replace(
    //     '?ordering=',
    //     '',
    //   );
    //   this.setState({ newOrdering: stateValue });
    //   orderingValue = stateValue;
    // }
    // if(props.history.algo === branch, this.toggleViewBranch)
    if (this.props.user.user_admin) {
      this.props.getClientTypes();
    }
    this.props.cleanTable();
    this.props.getClients(this.state.view_branch, '', null, 1);
    this.onFilterReset();
    this.setState({
      ...this.state,
      view_branch: this.props.view_branch_global,
    });
    if (this.props.back_from_detail === false && this.props.user.user_admin) {
      this.setState({
        ...this.state,
        currentTab: I18n.t('clients.accountButton'),
      });
    }
    if (this.props.back_from_detail === false && !this.props.user.user_admin) {
      this.setState({
        ...this.state,
        currentTab: I18n.t('clients.filiar'),
      });
    }
    if (this.props.back_from_detail === I18n.t('clients.filiar')) {
      this.setState({
        ...this.state,
        currentTab: I18n.t('clients.filiar'),
      });
      this.props.backFromDetail(false);
    }
    if (this.props.back_from_detail === I18n.t('clients.accountButton')) {
      this.setState({
        ...this.state,
        currentTab: I18n.t('clients.accountButton'),
      });
      this.props.backFromDetail(false);
    }
    if (this.props.back_from_detail === I18n.t('clients.creditsHistory')) {
      this.setState({
        ...this.state,
        currentTab: I18n.t('clients.creditsHistory'),
      });
      this.props.backFromDetail(false);
    }
    //si el usuario es admin, y el hash es credits-history, la pestaña por default, sera de creditos
    if (
      this.props.user.user_admin &&
      this.props.history.location.hash === '#credits-history'
    )
      this.handleSelect(I18n.t('clients.creditsHistory'));

    // this.props.updateFilterValues({
    //   ordering: this.props.filterValues.ordering || 'created',
    //   search: '',
    //   // start_date,
    //   // end_date,
    // });
    // this.props.getClients(this.props.view_branch_global, '', null, 1);
    // this.props.updatePage(1);
    // if (this.props.user.user_admin) {
    //   this.props.getClients(false, '');
    //   this.setState({
    //     ...this.state,
    //     currentTab: I18n.t('clients.accountButton'),
    //   });
    // } else {
    //   this.props.getClients(this.props.view_branch_global, '');
    //   this.setState({
    //     ...this.state,
    //     currentTab: I18n.t('clients.filiar'),
    //   });
    // }

    //this.props.getClientTypes();
    this.props.getCampaigns();
    this.props.unselectCampaign();
    // this.props.getCreditsHistory();
    if (this.props.user.user_company) {
      this.props.updateViewBranch(true);
    }

    if (this.props.user.account) {
      this.props.updateViewBranch(true);
      // window.addEventListener('load', () => {
      //   this.handleHeights();
      // });
      // window.addEventListener('resize', () => {
      //   this.handleHeights();
      // });
    }
    this.props.updateCreditsWidgets();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.filterClientValues !== this.props.filterClientValues ||
      prevState.view_branch !== this.state.view_branch
    ) {
      //Si cambio de pestaña cuenta a filial, o viceversa, se resetea la pagina
      //de lo contrario, se hace fetch normal a getClients
      if (prevState.view_branch !== this.state.view_branch) {
        this.props.getClients(
          this.state.view_branch,
          this.state.values.search.inputValue,
          null,
          1,
        );
      } else
        this.props.getClients(
          this.state.view_branch,
          this.state.values.search.inputValue,
        );
    }
    if (prevState.clearInputValue === true) {
      this.setState({
        ...this.state,
        clearInputValue: false,
      });
    }
    if (prevState.clearInputValue !== this.state.clearInputValue) {
      this.setState({
        ...this.state,
        values: {
          search: { inputValue: '' },
        },
      });
    }
  }

  handleShowHide = (show, id) => {
    this.setState({ ...this.state, showModal: show, clientId: id });
  };

  toggleViewBranch = async (bool) => {
    this.setState({ ...this.state, view_branch: bool });
    //await this.props.updatePage(1);
    //this.props.getClients(bool, this.state.values.search.inputValue);
  };

  handleSearch = (key, value) => {
    this.setState({
      ...this.state,
      values: {
        ...this.state.values,
        [key]: { inputValue: value },
      },
    });

    if (this.searchTimeout) clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(
      function () {
        this.props.updateFilterValues({ search: value });
        this.props.getClients(this.props.view_branch_global, value);
        this.props.getCreditsHistory();
        if (this.state.view_credits) {
          this.props.getCreditsByName({ search: value });
        }
      }.bind(this),
      300,
    );
  };

  handleSelect = (e) => {
    // debugger;
    if (e === I18n.t('clients.accountButton')) {
      //this.toggleViewBranch(false);
      // this.props.backFromDetail(false);
      this.setState(
        {
          ...this.state,
          currentTab: I18n.t('clients.accountButton'),
          view_branch: false,
        },
        () => this.onFilterReset(),
      );
    }
    if (e === I18n.t('clients.filiar')) {
      //this.toggleViewBranch(true);
      // this.props.backFromDetail(false);
      this.props.updateViewBranch(true);
      this.setState(
        {
          ...this.state,
          currentTab: I18n.t('clients.filiar'),
          view_branch: true,
        },
        () => this.onFilterReset(),
      );
    }
    if (e === I18n.t('clients.creditsHistory')) {
      this.props.backFromDetail(false);
      //this.toggleViewBranch(false);
      // this.setState(
      //   {
      //     ...this.state,
      //     currentTab: I18n.t('clients.creditsHistory'),
      //     view_branch: false,
      //   },
      //   () => this.onFilterReset(),
      // );
      this.props.history.push('/main/credits-history');
      // if (
      //   currentTab === I18n.t('clients.creditsHistory') &&
      //   this.props.user.account
      // ) {
      // }
    }
  };

  filterByDates = (filter) => {
    let filterbyDates = {};
    filterbyDates.start_value = dayjs(filter.start_range).format(
      'YYYY-MM-DDT00:00:00Z',
    );
    filterbyDates.end_value = dayjs(filter.end_range).format(
      'YYYY-MM-DDT23:59:59Z',
    );
    this.setState({
      ...this.state,
      isReset: false,
      range: {
        start: filterbyDates.start_value,
        end: filterbyDates.end_value,
      },
    });
    this.props.getClients(this.state.view_branch, null, filterbyDates);
  };

  toggleCleanFilter = (bool) => {
    this.setState({ ...this.state, cleanFilter: bool });
  };

  onFilterReset = () => {
    //this.props.getClients(this.state.view_branch, '', null, 1);
    let filter = {};

    filter = {
      search: [],
      start_date: '',
      end_date: '',
      id_account: [],
      branchs: [],
    };
    this.props.getFilterDates({
      start_value: '',
      end_value: '',
    });
    let state = { search: [], branchs: [] };

    this.setState(
      {
        ...this.state,
        isReset: true,
        filterValues: {
          ...this.state.filterValues,
          search: { inputValue: [] },
          branchs: { inputValue: [] },
        },
        values: { search: { inputValue: '' } },
        cleanFilter: false,
        clearInputValue: true,
        range: { start: undefined, end: undefined },
      },
      () => this.toggleCleanFilter(true),
    );
    this.props.setCreditOptionValues(state);
    this.props.setCreditFilterClear(true);
    this.props.updateFilterValues(filter);
    this.props.updateCommonFilterValues(filter);
    // this.props.getBranchs(null);
    this.props.getCreditsHistory();
    // this.props.getClients(
    //   this.state.view_branch,
    //   '',
    //   {
    //     start_date: this.state.start_date,
    //     end_date: this.state.end_date,
    //   },
    //   1,
    // );
    // if (this.props.user.user_admin === 1) {
    //   this.props.getClients(this.state.view_branch, '', null, 1);
    // } else this.props.getClients(this.state.view_branch, '', null, 1);
    this.props.updatePage(1);
  };
  availableCredits = () => {
    if (this.props.user.partner?.credits >= 0) {
      return (
        this.props.user.partner?.credits -
        this.props.user.partner?.credits_assigned
      );
    }
    if (this.props.user.branch?.credits >= 0) {
      return (
        this.props.user.branch.credits - this.props.user.branch.credits_assigned
      );
    }
    if (this.props.user.account?.credits >= 0) {
      return (
        this.props.user.account?.credits -
        this.props.user.account?.credits_assigned
      );
    }
  };

  calculateCreditsActive = () => {
    const total =
      this.props.user.partner?.credits ??
      this.props.user.branch?.credits ??
      this.props.user.account?.credits;

    const assigned =
      this.props.user.partner.credits_assigned ??
      this.props.user.branch?.credits_assigned ??
      this.props.user.account?.credits_assigned;

    const assignedNotConsumed =
      this.props.credits_account_status?._sum?.credits -
      this.props.credits_account_status?._sum?.credits_assigned;

    return total - (assignedNotConsumed - assigned);
  };

  handleTypeChange = (key, value) => {
    this.setState({
      ...this.state,
      values: {
        ...this.state.values,
        [key]: {
          ...this.state.values[key],
          inputValue: value,
        },
      },
    });

    this.props.getClients(
      false,
      '',
      this.state.range.start
        ? {
            start_value: dayjs(this.state.range.start).toISOString(),
            end_value: dayjs(this.state.range.end).toISOString(),
          }
        : null,
      1,
      value.code,
    );
  };

  render() {
    this.checkNotificationRenders();
    return (
      <div className="row ClientScreenWrapper">
        <DeleteModal
          show={this.state.showModal}
          onHide={() => this.handleShowHide(false)}
          actionCancel={() => this.handleShowHide(false)}
          actionConfirm={() => {
            if (this.props.view_branch_global) {
              this.props.deleteClient({
                id_branch: this.props.selectedClient.id,
              });
              this.props.updateViewBranch(true);
            } else
              this.props.deleteClient({
                id_account: this.props.selectedClient.id,
              });

            if (this.props.clients.length === 1 && this.props.currentPage > 1) {
              this.props.updatePage(this.props.currentPage - 1);
            }
          }}
          message={
            this.state.view_branch
              ? I18n.t('clients.deleteBranch', {
                  branchName: this.props.selectedClient.name,
                })
              : I18n.t('clients.deleteAccount', {
                  accountName: this.props.selectedClient.name,
                })
          }
          title={I18n.t('common.attention')}
          textButtonConfirm={I18n.t('common.confirmDelete')}
        />

        <div
          className={
            this.state.isSuperDashboard ? 'col-md-9 col-lg-10' : 'col-md-12'
          }
        >
          <div className="row table-tabs">
            <div className={'header-right'}>
              {!this.state.isSuperDashboard ? (
                <Button
                  className="primary add-client pull-right"
                  iconButton
                  iconClass={'add'}
                  text={I18n.t('clients.new')}
                  onClick={() => {
                    this.toggleViewBranch(false);
                    this.props.user.user_admin &&
                      this.props.updateViewBranch(false);
                    this.props.getClientsScreen('new');
                    this.props.history.push('/main/new-client');
                  }}
                />
              ) : (
                ''
              )}
              <div>
                <Button
                  className="filter-button-client"
                  iconButton
                  iconClass={'close'}
                  text={I18n.t('buttons.clearFilter')}
                  onClick={() => {
                    this.onFilterReset();
                  }}
                  disabled={
                    this.state.values.search.inputValue.length > 1 ||
                    this.props.commonFilterValues.branchs?.inputValue ||
                    this.props.commonFilterValues.accounts?.inputValue ||
                    !this.props.creditFilterClear ||
                    (this.props.dateFromCreditsHistory.start_value !== '' &&
                      this.props.dateFromCreditsHistory.end_value !== '') ||
                    this.state.values.client_type?.inputValue ||
                    !this.state.isReset
                      ? false
                      : true
                  }
                />
              </div>
            </div>
            <Tabs
              className="wrapper-tabs-content"
              onSelect={(currentTab) => {
                this.handleSelect(currentTab);
              }}
              defaultActiveKey={
                /*para usuario de tipo admin, si la ruta tiene el hash credits-history,
                se selecciona por default la pestaña de creditos*/
                this.props.user.user_admin &&
                this.props.history.location.hash === '#credits-history'
                  ? I18n.t('clients.creditsHistory')
                  : this.props.back_from_detail
                  ? this.props.back_from_detail
                  : this.props.view_branch_global
                  ? I18n.t('clients.filiar')
                  : this.props.user.user_admin
                  ? I18n.t('clients.accountButton')
                  : I18n.t('clients.filiar')
              }
            >
              <div className={'header-left'}>
                <div className={'search-bar'}>
                  <span class="material-symbols-rounded search-icon">
                    search
                  </span>
                  <Input
                    name="search"
                    value={this.state.values.search}
                    placeholder={I18n.t('persons.search')}
                    clearInput={
                      this.props.clearInput || this.state.clearInputValue
                    }
                    change={this.handleSearch}
                    type="filter"
                  />
                </div>
                {this.state.currentTab !== I18n.t('clients.creditsHistory') && (
                  <div
                    className="date-range-selector"
                    hidden={this.state.view_credits}
                  >
                    <DateRangePickerFilter
                      start_date={this.props.start_range}
                      end_date={this.props.end_range}
                      classNameButton="date-person"
                      {...this.props}
                      filter={this.filterByDates}
                      resetFilter={this.state.isReset}
                      hiddenPopup={true}
                    />
                  </div>
                )}
                {this.state.currentTab === I18n.t('clients.accountButton') &&
                  !this.props.view_branch_global && (
                    <div className="client-type-selector">
                      <FormDropdown
                        className="client-type"
                        // disabled={true}
                        name="client_type"
                        value={this.state.values.client_type}
                        change={this.handleTypeChange}
                        options={this.props.clientTypes.map((item) => ({
                          code: item.id,
                          description: item[`name_${this.props.language}`],
                        }))}
                        placeholder={I18n.t('clients.type')}
                      />
                    </div>
                  )}
              </div>
              {this.props.user.user_admin && (
                <Tab
                  eventKey={I18n.t('clients.accountButton')}
                  title={I18n.t('clients.accountButton')}
                >
                  {this.renderTable()}
                </Tab>
              )}
              {(this.props.user.user_admin || this.props.user?.account?.id) && (
                <Tab
                  eventKey={I18n.t('clients.filiar')}
                  title={I18n.t('clients.filiar')}
                  className={`clients-tab ${
                    this.props.user?.account?.id ? 'DMUser' : 'Admin'
                  }`}
                >
                  {this.renderTable()}
                </Tab>
              )}

              <Tab
                eventKey={I18n.t('clients.creditsHistory')}
                title={I18n.t('clients.creditsHistory')}
              >
                <div className="clients-credits-history">
                  <CreditsHistory
                    startRange={this.props.start_range}
                    endRange={this.props.end_range}
                    view_credits={this.state.view_credits}
                    resetInCredits={this.props.creditFilterClear}
                    toggleCleanFilter={this.toggleCleanFilter}
                    cleanFilter={this.state.cleanFilter}
                    onCredits={
                      this.state.currentTab === I18n.t('clients.creditsHistory')
                    }
                    {...this.props}
                  />
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
        {this.state.isSuperDashboard && (
          <div id="clients-wrapper" className="col-md-3 col-lg-2 widgets">
            {this.state.isSuperDashboard ? (
              <Button
                className="primary add-client"
                iconButton
                iconClass={'add'}
                text={I18n.t('clients.new_filial')}
                onClick={() => {
                  this.props.getClientsScreen('new');
                  this.props.history.push('/main/new-client');
                }}
              />
            ) : (
              ''
            )}
            <div className="credits-card">
              {/* <div className="col total-credits">
                <CreditsWidgets
                  material_symbol={'database'}
                  title={I18n.t('credits.totalCredits')}
                  label={I18n.t('credits.totalCreditsCredits')}
                  value={
                    this.props.user.partner?.credits ||
                    this.props.user.branch?.credits ||
                    this.props.user.account?.credits
                  }
                  description={I18n.t('credits.totalCreditsDescription')}
                  type={'row-view'}
                  renderStyle="total"
                />
              </div> */}
              <div className="col total-credits">
                <div className="database">
                  <span className="material-symbols-rounded material-symbols-filled">
                    database
                  </span>
                  {I18n.t('credits.totalCredits')}
                </div>
                <div className="totalcredits-text">
                  <span className="text">
                    {I18n.t('credits.totalCreditsCredits')}
                  </span>
                  <span className="number">
                    {this.props.user.branch?.credits ||
                      this.props.user.partner?.credits ||
                      this.props.user.account?.credits}
                  </span>
                  <span className="text">
                    {I18n.t('credits.totalCreditsDescription')}
                  </span>
                </div>

                {/* <CreditsWidgets
                  material_symbol={'database'}
                  title={I18n.t('credits.totalCredits')}
                  label={I18n.t('credits.totalCreditsCredits')}
                  value={
                    this.props.user_?.branch.credits ||
                    this.props.user_?.partner.credits
                  }
                  description={I18n.t('credits.totalCreditsDescription')}
                  type={'row-view'}
                  renderStyle=""
                /> */}
              </div>
              <div className="col">
                <CreditsWidgets
                  material_symbol={'priority'}
                  title={I18n.t('credits.available')}
                  label={I18n.t('credits.availableCredits')}
                  value={this.availableCredits()}
                  description={
                    this.props.user.account !== null
                      ? I18n.t('credits.availableCreditsDescription')
                      : I18n.t('credits.availableCreditsDescriptionFilial')
                  }
                  type={'column-view'}
                  renderStyle="available"
                />
              </div>
              <div className="col">
                <CreditsWidgets
                  material_symbol={
                    this.props.user.account !== null
                      ? 'account_tree'
                      : 'campaign'
                  }
                  title={I18n.t('credits.associated')}
                  label={I18n.t('credits.associatedCreditsDetail')}
                  value={
                    this.props.user.partner?.credits_assigned ??
                    this.props.user.branch?.credits_assigned ??
                    this.props.user.account?.credits_assigned
                  }
                  description={
                    this.props.user.account !== null
                      ? I18n.t('credits.associatedCreditsDescription')
                      : I18n.t('credits.associatedCreditsDescriptionFilial')
                  }
                  type={'column-view'}
                  renderStyle="associated"
                />
              </div>
              {this.props.user.user_admin && (
                <div className="col">
                  <CreditsWidgets
                    material_symbol={'groups'}
                    title={I18n.t('credits.assigned')}
                    label={I18n.t('credits.assignedCredits')}
                    value={
                      this.props.user?.partner.credits_assigned -
                      this.props.credits_user_status?._sum?.credits_consumed
                    }
                    description={I18n.t('credits.assignedCreditsDescription')}
                    type={'column-view'}
                    renderStyle={'actives'}
                  />
                </div>
              )}
              {this.props.user.account && (
                <div className="col">
                  <CreditsWidgets
                    material_symbol={'groups'}
                    title={I18n.t('credits.creditsActive.title')}
                    label={I18n.t('credits.creditsActive.label')}
                    value={this.calculateCreditsActive()}
                    description={I18n.t('credits.creditsActive.description')}
                    type={'column-view'}
                    renderStyle={'actives'}
                  />
                </div>
              )}
              <div className="col">
                <CreditsWidgets
                  material_symbol={'sports_esports'}
                  title={I18n.t('credits.consumed')}
                  label={I18n.t('credits.creditsConsumed')}
                  value={
                    this.props.credits_user_status?._sum?.credits_consumed || 0
                  }
                  description={I18n.t('credits.creditsConsumedDescription')}
                  type={'column-view'}
                  renderStyle={'consumed'}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  renderTable() {
    const { loading, success, error } = this.props.getClientsStatus;
    if (error)
      return <div className="text-warning">{I18n.t('clients.error.list')}</div>;
    let columns_ = [
      {
        Header: (
          <div className="client-table-headers">
            <span className="client-name-header">
              {this.props.user.user_admin
                ? I18n.t('clients.name')
                : I18n.t('users.filiar')}
              <span
                className={`material-symbols-rounded ${
                  this.state.isClicked &&
                  this.state.workingCell === 'name' &&
                  'turn-icon'
                }`}
              >
                arrow_drop_down
              </span>
            </span>
          </div>
        ),
        headerClassName: 'wordwrap name-header',
        accessor: 'name',
        Cell: (props) => (
          <span class="number nombre-cliente">{props.value}</span>
        ),
      },
      // {
      // 	Header: I18n.t("clients.link"),
      //     headerClassName: 'wordwrap',
      // 	accessor: 'link',
      // 	Cell: props => <span className='number'>{props.value}</span>
      // },
      // {
      //   Header: (
      //     <div class="client-table-headers">
      //       {I18n.t('clients.language')}
      //       <span
      //         class={`material-symbols-rounded ${
      //           this.state.isClicked &&
      //           this.state.workingCell === 'language' &&
      //           'turn-icon'
      //         }`}
      //       >
      //         arrow_drop_down
      //       </span>
      //     </div>
      //   ),
      //   headerClassName: 'wordwrap',
      //   accessor: 'language',
      //   Cell: (props) => {
      //     // TODO: traduction not needed as should not reach this case anyway
      //     return (
      //       <span className="number">
      //         {props.value && I18n.t('languages.' + props.value)}
      //       </span>
      //     );
      //   },
      // },
      {
        Header: (
          <div
            class={`client-table-headers ${
              this.props.user?.account?.id ? 'DMUser-side' : ''
            }`}
          >
            <span class="credits-header">
              {I18n.t('clients.credits_acquired')}
              <span
                class={`material-symbols-rounded ${
                  this.state.isClicked &&
                  this.state.workingCell === 'credits' &&
                  'turn-icon'
                }`}
              >
                arrow_drop_down
              </span>
            </span>
          </div>
        ),
        headerClassName: 'wordwrap credits-assigned',
        accessor: 'credits',
        Cell: (props) => (
          <div class="creditos-asignados">
            <NumberLabel value={props.value} />
          </div>
        ),
      },
      {
        Header: (
          <div class="client-table-headers">
            <span class=" credits-header">
              {I18n.t('clients.credits_active')}
              <span
                class={`material-symbols-rounded ${
                  this.state.isClicked &&
                  this.state.workingCell === 'active_credits' &&
                  'turn-icon'
                }`}
              >
                arrow_drop_down
              </span>
            </span>
          </div>
        ),
        id: 'active_credits',
        headerClassName: 'wordwrap credits-active',
        accessor: 'credits_active',
        Cell: (props) => (
          <div class="creditos-activos">
            <NumberLabel value={props.value} />
          </div>
        ),
      },
      {
        Header: (
          <div class="client-table-headers ">
            <span class="credits-header">
              {I18n.t('clients.credits_used')}
              <span
                class={`material-symbols-rounded ${
                  this.state.isClicked &&
                  this.state.workingCell === 'credits_consumed' &&
                  'turn-icon'
                }`}
              >
                arrow_drop_down
              </span>
            </span>
          </div>
        ),
        headerClassName: 'wordwrap credits-consumed',
        accessor: 'credits_consumed',
        Cell: (props) => (
          <div class="creditos-consumidos">
            <NumberLabel value={props.value ? props.value : '0'} />
          </div>
        ),
      },
      {
        Header: (
          <div class="client-table-headers ">
            <span class="credits-header date-header">
              {I18n.t('dropdown.date')}
              <span
                class={`material-symbols-rounded ${
                  this.state.isClicked &&
                  this.state.workingCell === 'created' &&
                  'turn-icon'
                }`}
              >
                arrow_drop_down
              </span>
            </span>
          </div>
        ),
        headerClassName: 'wordwrap date-created',
        id: 'created',
        accessor: 'created',
        Cell: (props) => (
          <div className="number centered-values fecha-creacion">
            {parseDate(new Date(props.value))}
          </div>
        ),
      },
      {
        Header: (
          <div class="client-table-headers">
            <span class="client-user-header">
              {I18n.t('users.userOnTable')}
              <span
                class={`material-symbols-rounded ${
                  this.state.isClicked &&
                  this.state.workingCell === 'full_name' &&
                  'turn-icon'
                }`}
              >
                arrow_drop_down
              </span>
            </span>
          </div>
        ),
        headerClassName: 'wordwrap user-header',
        className: 'user-td',
        id: 'full_name',
        accessor: `${
          this.props.clients[0]?.nwm_user_company_x_account
            ? 'nwm_user_company_x_account[0].user_company.user.full_name'
            : 'nwm_user_company_x_branch[0].user_company.user.full_name'
        }`,
        Cell: (props) => (
          <span class="number">{props.value ? props.value : '-'}</span>
        ),
      },
      // {
      // 	Header: I18n.t("clients.expiry_date"),
      //     headerClassName: 'wordwrap',
      // 	accessor: 'expiry_date',
      // 	Cell: props => <span className='number'>{parseDate(new Date(props.value))}</span>
      // },
      {
        Header: '',
        headerClassName: 'wordwrap dropdown-options',
        id: 'delete',
        sortable: false,
        className: 'dropdown-options',
        width: 140,
        accessor: (d) => d.id,
        Cell: (props) => {
          return (
            <TableMenuItem>
              <Dropdown
                value={{
                  inputValue: {
                    code: '1',
                    label: (
                      <span class="material-symbols-rounded">more_vert</span>
                    ),
                  },
                }}
                className="users-dropdown"
                noBorder
                // change={() => this.handleActions(props.value)}
                options={[
                  {
                    code: 'edit',
                    label: (
                      <Button
                        text="common.edit"
                        translate
                        iconButton={true}
                        iconClass="edit"
                        iconFilled={true}
                        className="text-link-button normal-button"
                        onClick={(e, handleOriginal) => {
                          if (handleOriginal) {
                            handleOriginal();
                          }
                          this.props.selectClient(props.original.id);
                          if (
                            this.state.currentTab ===
                            I18n.t('clients.accountButton')
                          ) {
                            this.props.getClientsScreen('edit-client');
                          }
                          if (
                            this.state.currentTab === I18n.t('clients.filiar')
                          ) {
                            this.props.getClientsScreen('edit-branch');
                          }
                          this.props.history.push(
                            '/main/client' +
                              (this.state.newOrdering !== ''
                                ? `?ordering=${this.state.newOrdering}`
                                : ''),
                            +(this.state.newOrdering !== ''
                              ? `?ordering=${this.state.newOrdering}`
                              : ''),
                          );
                        }}
                      />
                    ),
                    disabled: false,
                  },
                  {
                    code: 'delete',
                    label: (
                      <Button
                        text="common.delete"
                        translate
                        iconButton={true}
                        iconClass="delete"
                        iconFilled={true}
                        className="text-link-button delete-button user-screen"
                        onClick={async (e, handleOriginal) => {
                          if (handleOriginal) handleOriginal();

                          this.props.selectClient(props.original.id);
                          this.setState({
                            ...this.state,
                            userId: props.original.id,
                            showModal: true,
                          });
                        }}
                      />
                    ),
                    disabled: false,
                  },
                ]}
              />
            </TableMenuItem>
          );

          // return (
          //   <Dropdown
          //     value={{
          //       inputValue: {
          //         code: '1',
          //         label: (
          //           <span class="material-symbols-rounded">more_vert</span>
          //         ),
          //       },
          //     }}
          //     className="users-dropdown"
          //     // change={() => this.handleActions(props.value)}
          //     options={[
          //       {
          //         code: 'edit',
          //         label: (
          //           <Button
          //             text="editar"
          //             iconButton={true}
          //             iconClass="edit"
          //             className="text-link-button normal-button"
          //             onClick={(e, handleOriginal) => {
          //               if (handleOriginal) {
          //                 handleOriginal();
          //               }
          //               // this.props.selectClient(rowInfo.original.id);
          //               this.props.history.push(
          //                 '/main/client' +
          //                   (this.state.newOrdering !== ''
          //                     ? `?ordering=${this.state.newOrdering}`
          //                     : ''),
          //                 +(this.state.newOrdering !== ''
          //                   ? `?ordering=${this.state.newOrdering}`
          //                   : ''),
          //               );
          //             }}
          //           />
          //         ),
          //       },
          //       {
          //         code: 'delete',
          //         label: (
          //           <Button
          //             text="Eliminar"
          //             iconButton={true}
          //             iconClass="delete"
          //             className="text-link-button delete-button user-screen"
          //             onClick={async (e, handleOriginal) => {
          //               if (handleOriginal) handleOriginal();
          //               this.setState({
          //                 ...this.state,
          //                 userId: props.original.id,
          //                 showModal: true,
          //               });
          //             }}
          //           />
          //         ),
          //       },
          //     ]}
          //   />
          // );
        },
      },
    ];
    if (this.props.user.user_admin) {
      if (this.state.view_branch) {
        columns_.unshift({
          Header: (
            <div class="client-table-headers ">
              <span class="client-account-header">
                {I18n.t('clients.accountTable')}
                <span class="material-symbols-rounded">arrow_drop_down</span>
              </span>
            </div>
          ),
          headerClassName: 'wordwrap account-header',
          accessor: 'account.name',
          Cell: (props) => <span className="number">{props.value}</span>,
        });
      }
      columns_.unshift({
        Header: (
          <div class="client-table-headers">
            <span class="client-id-header">
              ID
              <span
                class={`material-symbols-rounded ${
                  this.state.isClicked &&
                  this.state.workingCell === 'id' &&
                  'turn-icon'
                }`}
              >
                arrow_drop_down
              </span>
            </span>
          </div>
        ),
        headerClassName: 'wordwrap client-id',
        className: 'client-id',
        accessor: 'id',
        Cell: (props) => (
          <span class="number id-value-cell">{props.value}</span>
        ),
      });
      if (!this.state.view_branch) {
        columns_.splice(5, 0, {
          Header: (
            <div class="client-table-headers">
              <span class="client-type">
                {I18n.t('clients.user_type')}
                <span
                  class={`material-symbols-rounded ${
                    this.state.isClicked &&
                    this.state.workingCell === 'id' &&
                    'turn-icon'
                  }`}
                >
                  arrow_drop_down
                </span>
              </span>
            </div>
          ),
          id: 'client-type',
          headerClassName: 'wordwrap client-type',
          className: 'client-type',
          accessor: `nwm_account_type.name_${this.props.language}`,
          Cell: (props) => (
            <span class="number id-value-cell">{props.value}</span>
          ),
        });
      }
    }

    return (
      <div className="table">
        <ReactTable
          columns={columns_}
          manual
          data={this.props.clients}
          minRows={0}
          resizable={false}
          noDataText={I18n.t('clients.table.noRows')}
          defaultSorted={[
            {
              id: 'name',
              desc: false,
            },
          ]}
          sorted={[
            {
              id: this.state.orderingFilterObj.id,
              desc: this.state.orderingFilterObj.desc,
            },
          ]}
          onSortedChange={async (newSorted, column) => {
            let filter = column.id;
            if (!this.state.isClicked) {
              this.setState({
                ...this.state,
                isClicked: !this.state.isClicked,
                workingCell: column.id,
                newOrdering: (newSorted[0].desc ? '-' : '') + filter,
                orderingFilterObj: {
                  id: filter,
                  desc: newSorted[0].desc,
                },
              });
            } else
              this.setState({
                ...this.state,
                newOrdering: (newSorted[0].desc ? '-' : '') + filter,
                orderingFilterObj: {
                  id: filter,
                  desc: newSorted[0].desc,
                },
              });

            this.props.updateFilterValues({
              ordering: (newSorted[0].desc ? '-' : '') + column.id,
            });
          }}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              onClick: (e, handleOriginal) => {
                e.preventDefault();
                if (handleOriginal) {
                  handleOriginal();
                }
                if (column.id !== 'delete') {
                  /* if (this.state.currentTab === I18n.t('clients.filiar')) {
                    //this.toggleViewBranch(true);
                  } */
                  this.props.getClientsScreen('detail');
                  this.props.selectClient(rowInfo.original.id);
                  this.props.history.push('/main/client');
                }
              },
            };
          }}
          manualPagination // for server-side pagination render
          pageSize={this.props.pageSize}
          PaginationComponent={() => {
            const {
              currentPage,
              updatePage,
              updatePageSize,
              totalPages,
              pageSize,
              totalCount,
            } = this.props;
            const isOnFirstPage = currentPage === 1;
            const isOnLastPage = currentPage === totalPages;
            return (
              <TablePaginationDrawer
                data={{
                  totalCount,
                  currentPage,
                  pageSize,
                  isOnFirstPage,
                  isOnLastPage,
                  updatePage,
                  updatePageSize,
                  totalPages,
                }}
                table={
                  this.state.isSuperDashboard ? 'clientsFilial' : 'clients'
                }
              />
            );
          }}
          loading={loading}
          LoadingComponent={TableLoader}
        />
      </div>
    );
  }

  checkNotificationRenders() {
    if (this.props.createClientStatus.success) {
      notify.show(
        <SnackBar
          icon={'priority'}
          title={I18n.t('common.excellent')}
          iconFilled
          body={
            !this.props.user.user_admin
              ? I18n.t('clients.success.createFilial')
              : I18n.t('clients.success.create')
          }
        />,
        'custom',
        5000,
        successMessage,
      );
      this.props.clearUiValue('createClientStatus', 'success');
    }
    if (this.props.updateClientStatus.success) {
      notify.show(
        <SnackBar
          icon={'priority'}
          title={I18n.t('common.excellent')}
          iconFilled
          body={
            this.state.isSuperDashboard
              ? I18n.t('clients.success.updateFilial')
              : I18n.t('clients.success.update')
          }
        />,
        'custom',
        5000,
        successMessage,
      );
      this.props.clearUiValue('updateClientStatus', 'success');
    }
    if (this.props.deleteClientStatus.success) {
      notify.show(
        <SnackBar
          icon={'priority'}
          title={I18n.t('common.excellent')}
          iconFilled
          body={
            this.props.view_branch_global
              ? I18n.t('clients.success.deleteFilial')
              : I18n.t('clients.success.delete')
          }
        />,
        'custom',
        5000,
        successMessage,
      );
      this.props.clearUiValue('deleteClientStatus', 'success');
    }
    if (this.props.deleteClientStatus.error) {
      notify.show(
        <SnackBar
          icon={'report'}
          type="error"
          title={I18n.t('common.attention')}
          iconFilled
          body={I18n.t('clients.error.delete')}
        />,
        'custom',
        5000,
        errorMessage,
      );
      this.props.clearUiValue('deleteClientStatus', 'error');
    }
  }

  //componentWillUnmount() {
  //se vuelve a la primer pagina del paginado antes de cambiar de Tab
  //this.props.getClients(this.props.view_branch_global, '', null, 1);
  //}
}

export default ClientsScreen;
