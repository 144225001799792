import React, { Component } from 'react';
import './DashboardScreen.css';
import { I18n } from 'react-redux-i18n';
import DonutChart from 'react-donut-chart';
import Button from '../../../components/common/Button';
import ReactApexChart from 'react-apexcharts';
import 'leaflet.markercluster';
import Loader from '../../../components/common/Loader';
import { Map, TileLayer, CircleMarker } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import ProgressBar from '../../../components/common/ProgressBar/ProgressBar';
import { latLngBounds, latLng } from 'leaflet';
import Dropdown from '../../../components/common/Dropdown';
import { NumberLabel } from '../../../components/common/NumberLabel/NumberLabel';
import DateRangePickerFilter from '../../../components/common/DateRangePickerFilter/DateRangePickerFilter';
import MainModal from '../../../components/common/MainModal';
import Onboarding from '../../../components/common/Onboarding';
import CreditsWidgets from '../../../components/common/CreditsWidgets/CreditsWidgets';
import Tooltip from '../../../components/common/Tooltip';
import SquareButton from '../../../components/common/SquareButton';
import FormDropdown from '../../../components/common/Dropdown/FormDropdown';

const WINDOW_WIDTH_SMALL_THRESHOLD = 900;
class DashboardScreen extends Component {
  constructor(props) {
    super(props);

    this.isAdmin = this.props.user.user_admin ? true : false;
    this.isSuperDashboard =
      this.props.user.partner && this.props.user.partner.is_super_dashboard;
    this.state = {
      graph: 'ALL',
      executedFilters: false,
      values: {
        male: { inputValue: true },
        female: { inputValue: true },
      },
      filterValues: {
        partner_ids: { inputValue: [] },
        campaigns_ids: { inputValue: [] },
        consistent_profiles: { inputValue: false },
      },
      bounds: null,
      windowWidthIsSmall: window.innerWidth < WINDOW_WIDTH_SMALL_THRESHOLD,
      range: { start: undefined, end: undefined },
      hoveredBarId: null,
      hoveredBar2Id: null,
      clearInput: false,
    };
  }
  handleResize = () => {
    const { windowWidthIsSmall } = this.state;
    const windowWidthIsSmallNow =
      window.innerWidth < WINDOW_WIDTH_SMALL_THRESHOLD;
    if (windowWidthIsSmall && !windowWidthIsSmallNow) {
      this.setState({ ...this.state, windowWidthIsSmall: false });
    } else if (!windowWidthIsSmall && windowWidthIsSmallNow) {
      this.setState({ ...this.state, windowWidthIsSmall: true });
    }
  };

  componentDidMount() {
    this.props.getLastMonth();
    let filter = {
      start_range: this.state.range.start
        ? this.state.range.start
        : this.props.start_date,
      end_range: this.state.range.end
        ? this.state.range.end
        : this.props.end_date,
    };
    this.props.updateCreditsWidgets();
    this.props.getDashboardReportAge(filter);
    this.props.getDashboardReportProfile(filter);
    // this.props.getDashboardReportProfileGender(this.state.graph, filter);

    this.props.getDashboardReportCity(filter);

    !(this.isAdmin && this.isSuperDashboard) &&
      this.props.getAllCampaigns({ partners: null, campaign_dropdown: true });
    (this.isAdmin || this.isSuperDashboard || this.props.user.account?.id) &&
      this.props.getAllClients({
        accountDropdown: true,
        view_branch: true,
        view_account: false,
      });

    // resize listener for apex chart graph behaviour
    window.addEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps, prevState, nextProps, nextState) {
    if (
      (this.state.range.start !== this.props.start_date ||
        this.state.range.end !== this.props.end_date) &&
      !prevState.executedFilters
    ) {
      this.setState({
        ...this.state,
        range: { start: this.props.start_date, end: this.props.end_date },
        executedFilters: true,
      });

      this.applyfilter({
        start_range: this.props.start_date,
        end_range: this.props.end_date,
      });
    }
    if (prevProps.profile !== this.props.profile) {
      this.renderTotalProfileInDonutChart({
        total: this.props.profile.amount[0].total,
      });
    }
    if (prevState.clearInput === true) {
      this.setState({
        ...this.state,
        clearInput: false,
      });
    }
  }
  handleTypeChange(type) {
    this.setState({ ...this.state, graph: type });
    // this.props.getDashboardReportProfileGender(type);
  }

  handleChange = (name, value) => {
    this.setState({
      ...this.state,
      values: {
        ...this.state.values,
        [name]: {
          ...this.state.values[name],
          inputValue: value,
        },
      },
    });
  };

  applyfilter = (
    date_filter = {},
    onlyConsistents = this.state.filterValues.consistent_profiles.inputValue,
  ) => {
    let filter = {};

    // Custom Filter
    let partner_ids = [];
    let campaign_ids = [];

    partner_ids = this.state.filterValues.partner_ids.inputValue.map(
      (client) => client.code,
    );

    campaign_ids = this.state.filterValues.campaigns_ids.inputValue.map(
      (campaign) => campaign.code,
    );
    if (campaign_ids.length === 0) {
      filter = {
        branchs: partner_ids,
      };
    } else {
      filter = {
        branchs: partner_ids,
        campaign_ids: campaign_ids,
      };
    }

    filter['consistent_profiles'] = onlyConsistents;

    // Date filters
    if (date_filter.start_range && date_filter.end_range) {
      this.setState({
        ...this.state,
        range: { start: date_filter.start_range, end: date_filter.end_range },
        executedFilters: true,
      });
      filter['start_range'] = date_filter.start_range;
      filter['end_range'] = date_filter.end_range;
    } else {
      filter['start_range'] = this.state.range.start;
      filter['end_range'] = this.state.range.end;
    }

    this.props.getDashboardReportAge(filter);
    this.props.getDashboardReportProfile(filter);
    // this.props.getDashboardReportProfileGender(this.state.graph, filter);
    this.props.getDashboardReportCity(filter);
  };
  onFilterReset = () => {
    this.props.getLastMonth();
    let filter = {
      start_range: this.props.start_date,
      end_range: this.props.end_date,
      partner_ids: [],
      campaign_ids: [],
    };
    this.setState({
      ...this.state,
      filterValues: {
        ...this.state.filterValues,
        partner_ids: { inputValue: [] },
        campaigns_ids: { inputValue: [] },
        consistent_profiles: { inputValue: false },
      },
      range: { start: filter.start_range, end: filter.end_range },
      clearInput: true,
    });
    this.props.getAllCampaigns({ partners: null, campaign_dropdown: true });
    this.props.getDashboardReportAge(filter);
    this.props.getDashboardReportProfile(filter);
    // this.props.getDashboardReportProfileGender(this.state.graph, filter);
    this.props.getDashboardReportCity(filter);
  };

  filterByDates = (filter) => {
    this.applyfilter(filter);
  };

  clearInputs() {
    this.setState({
      ...this.state,
      clearInput: false,
    });
  }

  availableCredits(user) {
    if (user.account !== null) {
      let accCredits =
        (user.partner?.credits || user.account?.credits) -
        (user.partner?.credits_assigned || user.account?.credits_assigned);
      return accCredits;
    } else {
      let brcCredits =
        (user.partner?.credits || user.branch.credits) -
        (user.partner?.credits_assigned || user.branch?.credits_assigned);
      return brcCredits;
    }
  }

  asociatedCredits(user) {
    if (user.account !== null) {
      let accCredits =
        user.partner?.credits_assigned || user.account?.credits_assigned;
      return accCredits;
    } else {
      let brcCredits =
        user.partner?.credits_assigned || user.branch?.credits_assigned;
      return brcCredits;
    }
  }

  consumedCredits() {
    const consumed = this.props.allCampaigns?.map(
      (consumed) => consumed?.credits_consumed,
    );
    return consumed.length && consumed.reduce((a, b) => a + b);
  }

  assignedCredits() {
    const assigned = this.props.allCampaigns?.map(
      (asign) => asign?.credits_assigned,
    );
    return assigned.length && assigned.reduce((a, b) => a + b);
  }
  getTotalProfiles = () => {
    const profileGender = this.props.profile?.amount || [];
    let totalValue = 0;
    profileGender.map((item) => {
      totalValue += item.total;
      return item.total;
    });
    return totalValue;
  };

  getTotalPersons = () => {
    const ageDistribution = this.props.age?.response || [];

    let totalValue = 0;
    ageDistribution.map((item) => {
      totalValue += item.total;
      return item.total;
    });
    return totalValue;
  };

  render() {
    let totalItems = this.getTotalProfiles();
    let totalPersons = this.getTotalPersons();
    return (
      <div className="container-fluid dashboard-screen">
        <div className="row wrapper-first-row">
          <div
            className={
              this.isAdmin
                ? 'credits-sumary'
                : 'col-md-12 col-lg-5 credits-sumary'
            }
          >
            {this.isAdmin ? null : this.renderCreditsSumary()}
          </div>

          <div
            className={
              this.isAdmin
                ? 'col-lg-12'
                : 'col-md-12 col-lg-7 profile-distribution'
            }
          >
            <div className="wrapper-profile-distribution">
              {this.isAdmin || this.isSuperDashboard
                ? this.renderAdminFilters()
                : this.renderPartnerFilters()}
              <div className="card pb-0 no-scroll-y profiles-chart">
                <div className="card-header justify-content-between">
                  <div className="chart-header">
                    <p className="no-wrap">
                      {I18n.t('dashboard.profileDistribution')}
                    </p>
                  </div>
                  <div className="chart-header right">
                    <p className="no-wrap">
                      {totalItems > 1
                        ? totalItems + ` ${I18n.t('dashboard.profiles')}`
                        : totalItems +
                          ` ${I18n.t('jobs.profile').toLowerCase()}`}
                    </p>
                  </div>
                </div>
                {this.renderApexChart()}
              </div>
            </div>
          </div>
        </div>
        <div className="row wrapper-second-row">
          <div className="col-lg-6 demographic-dist">
            <div className="card">
              <div className="card-header">
                <div className="chart-header">
                  <p>{I18n.t('dashboard.demographicDistribution')}</p>
                </div>
                <div className="chart-header right">
                  <p className="no-wrap">
                    {totalItems > 1
                      ? totalItems + ` ${I18n.t('dashboard.profiles')}`
                      : totalItems + ` ${I18n.t('jobs.profile').toLowerCase()}`}
                  </p>
                </div>
              </div>
              {this.renderDemographicDistMap()}
            </div>
          </div>
          <div className="col-lg-3 profiles-categories">
            <div className="card">
              <div className="card-header">
                <div className="chart-header">
                  <p>{I18n.t('dashboard.profileCategories')}</p>
                </div>
                <div className="chart-header right">
                  <p className="no-wrap">
                    {totalItems > 1
                      ? totalItems + ` ${I18n.t('dashboard.profiles')}`
                      : totalItems + ` ${I18n.t('jobs.profile').toLowerCase()}`}
                  </p>
                </div>
              </div>
              {this.renderProfileCategoriesDonutChart()}
            </div>
          </div>
          <div className="col-lg-3 age-distribution">
            <div className="card">
              <div className="card-header">
                <div className="chart-header">
                  <p>{I18n.t('dashboard.ageDistribution')}</p>
                </div>
                <div className="chart-header right">
                  <p className="no-wrap">
                    {totalItems > 1
                      ? totalItems + ` ${I18n.t('dashboard.persons')}`
                      : totalItems + ` ${I18n.t('dashboard.person')}`}
                  </p>
                </div>
              </div>
              {this.renderAgeDistributionBars()}
            </div>
          </div>
        </div>
      </div>
    );
  }

  handleFiltersChange = async (type, name, option) => {
    let newArray = [...this.state.filterValues[name].inputValue];
    let campaignsValues = this.state.filterValues.campaigns_ids.inputValue;

    if (option.code === -1) return;
    else if (type === 'add') {
      newArray = newArray.concat([option]);
    } else {
      // logic for when deleting a partner, delete corresponding selected campaigns
      // if (name === 'partner_ids') {
      //     newArray = newArray.filter((opt) => opt.code !== option.code);
      //     campaignsValues = campaignsValues.filter(campaign => campaign.partner.id !== option.code)
      // }
      newArray = newArray.filter((opt) => opt.code !== option.code);
    }
    if (name === 'partner_ids') {
      let partners_ids_filter = newArray.map(({ code }) => code);

      await this.props.getAllCampaigns({
        partners: partners_ids_filter,
        campaign_dropdown: true,
      });

      newArray.sort((profile1, profile2) => {
        return profile2.code - profile1.code;
      });
    }
    this.setState(
      {
        ...this.state,
        filterValues: {
          ...this.state.filterValues,
          campaigns_ids: { inputValue: campaignsValues },
          [name]: {
            ...this.state.filterValues[name],
            inputValue: [...newArray],
          },
        },
      },
      () => this.applyfilter(),
    );
  };

  handleInputChange = async (key, value) => {
    this.setState({
      ...this.state,
      filterValues: {
        ...this.state.filterValues,
        [key]: {
          ...this.state.filterValues[key],
          inputValue: value,
        },
      },
    });

    if (key === 'partner_ids') {
      let newArray = [...this.state.filterValues.partner_ids.inputValue];

      newArray.sort((profile1, profile2) => {
        return profile2.code - profile1.code;
      });
      this.setState(
        {
          ...this.state,
          filterValues: {
            ...this.state.filterValues,
            [key]: {
              ...this.state.filterValues[key],
              inputValue: { ...value },
            },
          },
        },
        () => {
          let filter = {
            start_range: this.state.range.start
              ? this.state.range.start
              : this.props.start_date,
            end_range: this.state.range.end
              ? this.state.range.end
              : this.props.end_date,
            partner_ids: [this.state.filterValues.partner_ids.inputValue.code],
            campaign_ids: [
              this.state.filterValues.campaigns_ids.inputValue.code,
            ],
          };
          this.props.getDashboardReportAge(filter);
          this.props.getDashboardReportProfile(filter);
          this.props.getDashboardReportCity(filter);
        },
      );
    }

    if (key === 'campaigns_ids') {
      let newArray = [...this.state.filterValues[key].inputValue];

      newArray.sort((profile1, profile2) => {
        return profile2.code - profile1.code;
      });
      this.setState(
        {
          ...this.state,
          filterValues: {
            ...this.state.filterValues,
            [key]: {
              ...this.state.filterValues[key],
              inputValue: { ...value },
            },
          },
        },
        () => {
          let filter = {
            start_range: this.state.range.start
              ? this.state.range.start
              : this.props.start_date,
            end_range: this.state.range.end
              ? this.state.range.end
              : this.props.end_date,
            partner_ids: [this.state.filterValues.partner_ids.inputValue.code],
            campaign_ids: [
              this.state.filterValues.campaigns_ids.inputValue.code,
            ],
          };

          this.props.getDashboardReportAge(filter, {
            campaign_id: this.state.filterValues.campaigns_ids.code,
          });
          this.props.getDashboardReportProfile(filter, {
            campaign_id: this.state.filterValues.campaigns_ids.code,
          });
          this.props.getDashboardReportCity(filter, {
            campaign_id: this.state.filterValues.campaigns_ids.code,
          });
        },
      );
    }
  };

  toggleProfileStatus = () => {
    let value = this.state.filterValues.consistent_profiles.inputValue;
    this.setState({
      ...this.state,
      filterValues: {
        ...this.state.filterValues,
        consistent_profiles: { inputValue: !value },
      },
    });
    this.applyfilter({}, !value);
  };

  renderCreditsSumary() {
    return (
      <div className="credits-summary-wrapper">
        {(this.props.user?.account !== null ||
          this.props.user?.branch !== null) && (
          <div className="card credits-widget-container">
            <div className="title-dashboard-container">
              <h3 className="credits-title">{I18n.t('credits.resume')}</h3>
              <Tooltip
                message={I18n.t('credits.creditsTooltip')}
                translate={false}
                materialIcon="contact_support"
              />
            </div>
            <div className="credits-body">
              <div className="credits-card">
                <div className="row">
                  <div className="col-md-6">
                    <CreditsWidgets
                      material_symbol={'priority'}
                      title={I18n.t('credits.available')}
                      label={I18n.t('credits.availableCredits')}
                      value={this.availableCredits(this.props.user)}
                      description={
                        this.props.user.account !== null
                          ? I18n.t('credits.availableCreditsDescription')
                          : I18n.t('credits.availableCreditsDescriptionFilial')
                      }
                      type={'column-view'}
                      renderStyle="available"
                    />
                  </div>
                  <div className="col-md-6">
                    <CreditsWidgets
                      material_symbol={
                        this.props.user.account !== null
                          ? 'account_tree'
                          : 'campaign'
                      }
                      title={I18n.t('credits.associated')}
                      label={I18n.t('credits.associatedCreditsDetail')}
                      value={this.asociatedCredits(this.props.user)}
                      description={
                        this.props.user.account !== null
                          ? I18n.t('credits.associatedCreditsDescription')
                          : I18n.t('credits.associatedCreditsDescriptionFilial')
                      }
                      type={'column-view'}
                      renderStyle="associated"
                    />
                  </div>
                </div>
              </div>
              <div className="credits-row">
                <div className="row-cols">
                  <div className="row">
                    {this.props.user.branch ? (
                      <div className="col-xs-12">
                        <CreditsWidgets
                          material_symbol={'groups'}
                          title={I18n.t('credits.assigned')}
                          label={I18n.t('credits.assignedCredits')}
                          value={this.assignedCredits()}
                          description={I18n.t(
                            'credits.assignedCreditsDescription',
                          )}
                          type={'row-view'}
                          renderStyle={'actives'}
                        />
                      </div>
                    ) : null}
                    <div className="col-xs-12">
                      <CreditsWidgets
                        material_symbol={'sports_esports'}
                        title={I18n.t('credits.consumed')}
                        label={I18n.t('credits.creditsConsumed')}
                        value={this.consumedCredits()}
                        description={I18n.t(
                          'credits.creditsConsumedDescription',
                        )}
                        type={'row-view'}
                        renderStyle={'consumed'}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="buttons-container">
                <div className="col-md-12 col-lg-6">
                  <Button
                    className={'text-link-button outlined'}
                    iconButton={true}
                    iconClass={'format_list_bulleted'}
                    text={I18n.t('clients.creditsHistoryButton')}
                    onClick={() =>
                      this.props.history.push('/main/credits-history')
                    }
                  />
                </div>
                <div className="col-md-12 col-lg-6">
                  <Button
                    className={'primary'}
                    iconButton={true}
                    iconClass={'add'}
                    text={
                      this.props.user.account
                        ? I18n.t('clients.add_filial')
                        : I18n.t('campaigns.new')
                    }
                    onClick={() => {
                      if (this.props.user.account) {
                        this.props.updateViewBranch();
                        this.props.history.push('/main/new-client');
                      } else {
                        this.props.removeSelectedCampaigns();
                        this.props.history.push('/main/new-campaign');
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  renderAdminFilters() {
    const showClearFiltersButton =
      this.state.filterValues.partner_ids.inputValue.length === 0 &&
      this.state.filterValues.campaigns_ids.inputValue.length === 0 &&
      this.state.range.start?.length &&
      this.state.range.end?.length;
    const { loading: loadingDates } = this.props.dateStatus;

    return (
      <div className="filters">
        <div className="filter-right">
          {loadingDates ? (
            <></>
          ) : (
            <div className="date-filter-dashboard">
              <DateRangePickerFilter
                {...this.props}
                rangeDefault={
                  this.state.range.start
                    ? this.state.range
                    : {
                        start: this.props.start_date,
                        end: this.props.end_date,
                      }
                }
                filter={this.filterByDates}
              />
            </div>
          )}
          <div className="dropdown-filter">
            <FormDropdown
              name={'partner_ids'}
              placeholder={
                this.isSuperDashboard
                  ? I18n.t('clients.filiar')
                  : I18n.t('clients.identifier')
              }
              clearInput={this.state.clearInput}
              multiSelect={true}
              //change={this.handleInputChange}
              search={this.props.getAllClients}
              addOption={this.handleFiltersChange}
              value={this.state.filterValues.partner_ids}
              options={this.props.allClients.map((client) => ({
                code: client.id,
                description: client.name,
              }))}
              loading={this.props.getAllClientsStatus.loading}
              showEmptyOption
              getAllClientsPrompts={{
                accountDropdown: true,
                view_branch: true,
                view_account: false,
              }}
            />
          </div>
          <div className="dropdown-filter">
            <FormDropdown
              name={'campaigns_ids'}
              placeholder={I18n.t('campaigns.identifier')}
              multiSelect={true}
              //change={this.handleInputChange}
              clearInput={this.state.clearInput}
              search={this.props.getAllCampaigns}
              addOption={this.handleFiltersChange}
              value={this.state.filterValues.campaigns_ids}
              options={this.filterPartnerCampaignsOptions()}
              loading={this.props.getAllCampaignsStatus.loading}
              showEmptyOption
              getAllClientsPrompts={{
                partners:
                  this.state.filterValues.partner_ids.inputValue.flatMap(
                    (item) => item.code,
                  ),
                campaign_dropdown: true,
              }}
            />
          </div>

          {this.renderConsistencyFilter()}
          {!showClearFiltersButton ? (
            <div className="filter-container">
              <Button
                className="text-link-button delete-button"
                text={I18n.t('buttons.clearFilter')}
                onClick={() => this.onFilterReset()}
                iconButton={true}
                iconClass={'close'}
              />
            </div>
          ) : (
            <div className="filter-container-not"></div>
          )}
        </div>
      </div>
    );
  }

  filterAdminCampaignsOptions() {
    let partnerSelected = [];
    let options = [];
    let notNull = [];
    partnerSelected = this.state.filterValues.partner_ids.inputValue.map(
      (x) => x.code,
    );

    Object.keys(partnerSelected).forEach((value) => {
      options = options.concat(
        this.props.allCampaigns.filter(
          (res) => res.nwm_branchId === partnerSelected[value],
        ),
      );
    });

    if (options.length === 0) {
      return [{ code: -1, description: I18n.t('campaigns.null') }];
    } else {
      notNull = options.filter((n) => n.name != null);
      return notNull.map((campaign) => ({
        code: campaign.id,
        description: campaign.branch.name + ': ' + campaign.name,
        partner: campaign.branch,
      }));
    }
  }

  filterPartnerCampaignsOptions() {
    let options = this.props.allCampaigns.map((campaign) => ({
      code: campaign.id,
      description: campaign.name,
    }));

    return options.filter((n) => n.description != null);
  }

  renderPartnerFilters() {
    const showClearFiltersButton =
      this.state.filterValues.campaigns_ids.inputValue.length === 0;
    const { loading: loadingDates } = this.props.dateStatus;
    return (
      <div className="filters">
        <div className="filter-right">
          {loadingDates ? (
            <></>
          ) : (
            <div className="date-filter-dashboard">
              <DateRangePickerFilter
                {...this.props}
                filter={this.filterByDates}
                rangeDefault={
                  this.state.range.start
                    ? this.state.range
                    : {
                        start: this.props.start_date,
                        end: this.props.end_date,
                      }
                }
              />
            </div>
          )}

          <div className="dropdown-filter">
            <FormDropdown
              name={'campaigns_ids'}
              placeholder={I18n.t('campaigns.identifier')}
              multiSelect={true}
              //change={this.handleInputChange}
              clearInput={this.state.clearInput}
              search={this.props.getAllCampaigns}
              addOption={this.handleFiltersChange}
              value={this.state.filterValues.campaigns_ids}
              options={this.filterPartnerCampaignsOptions()}
              loading={this.props.getAllCampaignsStatus.loading}
              showEmptyOption
              getAllClientsPrompts={{
                partners:
                  this.state.filterValues.partner_ids.inputValue.flatMap(
                    (item) => item.code,
                  ),
                campaign_dropdown: true,
              }}
            />
          </div>

          {this.renderConsistencyFilter()}
          {!showClearFiltersButton ? (
            <div className="filter-container">
              <Button
                className="text-link-button delete-button"
                text={I18n.t('buttons.clearFilter')}
                onClick={() => this.onFilterReset()}
                iconButton={true}
                iconClass={'close'}
              />
            </div>
          ) : (
            <div className="filter-container"></div>
          )}
        </div>
      </div>
    );
  }

  renderConsistencyFilter() {
    return (
      <div className="filter-container">
        <SquareButton
          label={I18n.t('dashboard.profileFilterPlaceholder')}
          name={'consitentProfiles'}
          value={this.state.filterValues.consistent_profiles.inputValue}
          change={this.toggleProfileStatus}
          setValue={!this.state.filterValues.consistent_profiles?.inputValue}
        />
      </div>
    );
  }

  /**
   * Distribucion por perfil
   */
  renderApexChart() {
    // concat 0 values instead of empty array to
    // enable animation properly.
    const { loading, success, error } = this.props.profileStatus;
    const profileGender = this.props.profile?.amount || [];

    let hoveredBarId = this.state.hoveredBarId;

    let maxValue = 0;
    let apexSeries = [
      {
        name: I18n.t('dashboard.total'),
        data: profileGender.map((item) => {
          if (item.total > maxValue && item.total > 0) maxValue = item.total;
          return item.total;
        }),
      },
    ];
    apexSeries[0].data.unshift(0);
    apexSeries[0].data.push(0);

    if (maxValue) maxValue = maxValue * 1.05;

    let apexOptions = {
      colors: ['#344563'],
      chart: {
        height: 350,
        type: 'bar',
        toolbar: {
          show: false,
          tools: {
            download: false,
          },
        },
        animations: {
          enabled: true,
          easing: 'easeinout',
          speed: 1500,
          dynamicAnimation: { enabled: true, speed: 80 },
        },
        events: {
          dataPointMouseEnter: (e, chartContext, config) => {
            if (
              this.state.hoveredBarId !== config.dataPointIndex &&
              this.state.hoveredBarId === null
            ) {
              this.setState({
                ...this.state,
                hoveredBarId: config.dataPointIndex,
              });
            }
          },
          mouseMove: (e, chartContext, config) => {
            if (
              !e.srcElement.classList.contains('apexcharts-bar-area') &&
              this.state.hoveredBarId !== null
            ) {
              this.setState({
                ...this.state,
                hoveredBarId: null,
              });
            }
          },
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 6,
          horizontal: this.state.windowWidthIsSmall,
          columnWidth: '80%',
          endingShape: 'rounded',
          distributed: true,
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val > 1
            ? val + ` ${I18n.t('dashboard.profiles')}`
            : val + ` ${I18n.t('jobs.profile').toLowerCase()}`;
        },

        offsetY: -30,
        style: {
          fontSize: '18px',
          fontweight: '800',
          transform: 'translateY(-50px)',
          colors: [
            function (opts) {
              if (
                hoveredBarId === opts.dataPointIndex &&
                hoveredBarId !== null
              ) {
                return '#50b5d0';
              } else {
                return 'transparent';
              }
            },
          ],
        },
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        categories: [' ']
          .concat(profileGender.map((profile) => profile.name))
          .concat([' ']),
        labels: {
          hideOverlappingLabels: false,
        },
      },
      yaxis: {
        forceNiceScale: true,
        min: 0,
        max: maxValue || 1,
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        enabled: false,
        y: {
          formatter: function (val) {
            return val;
          },
        },
        hover: { enabled: false },
      },
      legend: {
        show: false,
      },
      states: {
        hover: {
          filter: {
            type: 'none',
          },
          dataLabels: { enabled: true },
        },
      },
    };

    if (loading) return <Loader />;
    else if (error)
      return (
        <div className="text-warning">
          {I18n.t('dashboard.error.getProfileGender')}
        </div>
      );
    else if (success) {
      return (
        <div id="chart" className="apex-charts-container">
          <ReactApexChart
            style={{ visibility: success ? 'visible' : 'hidden' }}
            options={apexOptions}
            series={apexSeries}
            type="bar"
            height={'359'}
            width="100%"
          />
        </div>
      );
    } else return <Loader />;
  }

  /**
   * Age
   */
  renderAgeDistributionBars() {
    const { loading, success, error } = this.props.ageStatus;
    const age = this.props.age.response;

    if (loading) return <Loader />;
    else if (error)
      return (
        <div className="text-warning">{I18n.t('dashboard.error.getAge')}</div>
      );
    else if (success) {
      let maxValue = 0;
      let apexSeries = [
        {
          name: I18n.t('dashboard.total'),
          data: age.map((item) => {
            if (item.total > maxValue) maxValue = item.total;
            return item.total;
          }),
        },
      ];

      let colorlist = [];
      age.map((ageRange) =>
        colorlist.push(ageRange.total === maxValue ? '#50b5d0' : '#344563'),
      );

      let hoveredBar2Id = this.state.hoveredBar2Id;

      let apexOptions = {
        colors: ['#344563'],
        chart: {
          height: 250,
          type: 'bar',
          toolbar: {
            show: false,
            tools: {
              download: false,
            },
          },
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 1500,
            dynamicAnimation: { enabled: true, speed: 80 },
          },
          events: {
            dataPointMouseEnter: (e, chartContext, config) => {
              if (
                hoveredBar2Id !== config.dataPointIndex &&
                hoveredBar2Id === null
              ) {
                this.setState({
                  ...this.state,
                  hoveredBar2Id: config.dataPointIndex,
                });
              }
            },
            mouseMove: (e, chartContext, config) => {
              if (
                !e.srcElement.classList.contains('apexcharts-bar-area') &&
                this.state.hoveredBar2Id !== null
              ) {
                this.setState({
                  ...this.state,
                  hoveredBar2Id: null,
                });
              }
            },
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 6,
            horizontal: this.state.windowWidthIsSmall,
            columnWidth: '80%',
            endingShape: 'rounded',
            distributed: true,
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val;
          },
          offsetY: -30,
          style: {
            fontSize: '18px',
            fontweight: '800',
            colors: [
              function (opts) {
                if (
                  hoveredBar2Id === opts.dataPointIndex &&
                  hoveredBar2Id !== null
                ) {
                  return '#50b5d0';
                } else {
                  return 'transparent';
                }
              },
            ],
          },
        },

        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
        },
        xaxis: {
          categories: age.map((ageRange) => ageRange.name),
          labels: {
            hideOverlappingLabels: false,
          },
        },
        yaxis: {
          forceNiceScale: true,
          min: 0,
          max: maxValue + 1 || 1,
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          enabled: false,
          y: {
            formatter: function (val) {
              return val;
            },
          },
          hover: { enabled: false },
        },
        legend: {
          show: false,
        },
        states: {
          hover: {
            filter: {
              type: 'none',
              value: 0.0,
            },
            dataLabels: { enabled: true },
          },
        },
      };

      return (
        <div id="chart" className="apex-charts-container">
          <ReactApexChart
            style={{ visibility: success ? 'visible' : 'hidden' }}
            options={apexOptions}
            series={apexSeries}
            type="bar"
            height={'359'}
            width="100%"
          />
        </div>
      );
    } else return <Loader />;
  }

  /**
   * Cities
   */
  renderDemographicDistMap() {
    let count = 0;
    this.props.cities.forEach((city) => (count += city.total));
    let maxValue = this.props.cities.sort((city1, city2) => {
      return city2.total - city1.total;
    })[0]?.total;

    const { loading, success, error } = this.props.cityStatus;
    if (loading) return <Loader />;
    else if (error)
      return (
        <div className="text-warning">{I18n.t('dashboard.error.getCity')}</div>
      );
    else if (success)
      return (
        <div className={'row distribution-content'}>
          <div className={'col-lg-5'}>
            <Map
              className="markercluster-map"
              center={[30, 0]}
              minZoom={0.8}
              zoom={0.8}
              maxZoom={18}
              worldCopyJump
              maxBounds={latLngBounds(latLng(-120, -600), latLng(120, 600))}
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>'
              />
              <MarkerClusterGroup>
                {this.props.cities.map((city, id) => {
                  return (
                    <CircleMarker
                      key={'city-marker-' + id}
                      center={[city.latitude, city.longitude]}
                      position={[city.latitude, city.longitude]}
                      radius={city.total * 3}
                      attribution=""
                    />
                  );
                })}
              </MarkerClusterGroup>
            </Map>
          </div>
          <div className={'col-lg-7'}>
            {this.props.cities
              .sort((city1, city2) => {
                return city2.total - city1.total;
              })
              .slice(0, 5)
              .map((city, id) => (
                <ProgressBar
                  label={city.name}
                  name="demographic-dist"
                  value={
                    city.total !== 0
                      ? city.total > 1
                        ? `${city.total} ${I18n.t('dashboard.profiles')}`
                        : `${city.total} ${I18n.t('jobs.profile')
                            .toLowerCase()
                            .toLowerCase()}`
                      : ''
                  }
                  long={(city.total * 100) / count}
                  secondaryClass={city.total === maxValue ? ' maxValue' : ''}
                  dashboard
                />
              ))}
          </div>
        </div>
      );
    else return <Loader />;
  }

  renderTotalProfileInDonutChart(a) {
    document
      .querySelector('.donutchart-innertext .donutchart-innertext-total')
      ?.remove();

    const data = document
      .querySelector('.donutchart-innertext .donutchart-innertext-label')
      .cloneNode(true);
    data.setAttribute('class', 'donutchart-innertext-total');
    data.innerHTML =
      a.total > 1
        ? a.total + ' ' + I18n.t('dashboard.profiles')
        : a.total + ' ' + I18n.t('jobs.profile').toLowerCase();
    document.querySelector('.donutchart-innertext').appendChild(data);
  }

  /**
   * Profile
   */
  renderProfileCategoriesDonutChart() {
    let otherValue = 0;
    let otherValueTotal = 0;
    let profile = this.props.profile.percentage || [];
    let donutData = [];
    profile
      ?.slice(5, profile.length)
      .map((a) => (otherValue += parseFloat(a.percentage)));
    this.props.profile.amount
      ?.slice(5, profile.length)
      .map((a) => (otherValueTotal += parseFloat(a.total)));
    donutData = profile
      .slice(0, 5)
      .map((profile) => ({
        id: profile.id,
        label: profile.name,
        value: parseFloat(profile.percentage),
        total: this.props.profile.amount.find((a) => a.id === profile.id).total,
      }))
      .concat({
        label: I18n.t('dashboard.others'),
        value: otherValue,
        isEmpty: true,
        total: otherValueTotal,
      });
    const { loading, success, error } = this.props.profileStatus;
    if (loading) return <Loader />;
    else if (error)
      return (
        <div className="text-warning">
          {I18n.t('dashboard.error.getProfile')}
        </div>
      );
    else if (success)
      return (
        !!donutData.length && (
          <div className="doughnut-chart">
            <DonutChart
              colors={['#508ff4', '#50f48f', '#f4f450', '#f48f50', '#f4508f']}
              emptyColor={'#dcebf9'}
              strokeColor={'rgba(0, 0, 0, 0)'}
              height={350}
              width={350}
              legend={false}
              innerRadius={0.65}
              outerRadius={0.8}
              startAngle={270}
              clickToggle={false}
              data={donutData}
              onMouseEnter={(a) => this.renderTotalProfileInDonutChart(a)}
            />
          </div>
        )
      );
    else return <Loader />;
  }
}

export default DashboardScreen;
