import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router';
import actions from '../actions';

const LoginRequiredRoute = ({ component, ...rest }) => {
  if (!rest.loggedIn) {
    rest.saveRedirectRoute(rest.location.pathname);
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        rest.loggedIn ? (
          component(props)
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )
      }
    />
  );
};

export default connect(
  (state) => ({
    loggedIn: state.session.isLoggedIn,
  }),
  (dispatch) => ({
    saveRedirectRoute: (location) =>
      dispatch(actions.session.saveRedirectRoute(location)),
  }),
)(LoginRequiredRoute);
