import React from 'react';
import './Switch.sass';
const Switch = ({ name, onChange, value, disabled = false }) => {
  return (
    <div className="switch-container">
      <label class="switch">
        <input
          type="checkbox"
          name={name}
          checked={value}
          disabled={disabled}
          onChange={(e) => onChange(e.target.name, !value)}
        />
        <span class="slider"></span>
      </label>
    </div>
  );
};

export default Switch;
