import React, { Component } from 'react';
import './LoginScreen.css';
import Input from '../../../components/common/Input';
import Button from '../../../components/common/Button';
import { I18n } from 'react-redux-i18n';
import Loader from '../../../components/common/Loader';
import settings from '../../../settings';
import reqStates from '../../../utils/reqStates';
import regex from '../../../utils/regex';
import {
  SnackBar,
  successMessage,
  errorMessage,
} from '../../../utils/snackbar';

class LoginScreen extends Component {
  static defaultProps;

  constructor(props) {
    super(props);
    const urlMail = props.match.params.mail || '';
    this.state = {
      forgot_password: false,
      after_link_request: false,
      show_loader: false,
      disableInput: !!urlMail,
      username: {
        inputValue: urlMail,
        required: false,
        validator: (email) =>
          (regex.emailRegex.test(email) || email.length === 0) &&
          this.state.errorLogin,
      },
      password: {
        inputValue: '',
        required: false,
        forgotPassword: true,
        validator: () => true && this.state.errorLogin,
      },
      errorLogin: true,
      sentActivationLink: false,
      isFormValid: true,
      loginEnabled: false,
      connection: true,
    };
  }

  // TODO: change to componentDidUpdate(prevProps) (obsolete lifecycle function)
  componentWillReceiveProps(props) {
    if (
      props.loginStatus === reqStates.LOADING &&
      this.props.loginStatus !== reqStates.LOADING
    ) {
      this.setState({ ...this.state, show_loader: true });
    }
    if (
      props.loginStatus !== reqStates.LOADING &&
      this.props.loginStatus === reqStates.LOADING
    ) {
      this.setState({ ...this.state, show_loader: false });
    }
    if (props.resetPasswordLoading && !this.props.resetPasswordLoading) {
      this.setState({ ...this.state, show_loader: true });
    }
    if (!props.resetPasswordLoading && this.props.resetPasswordLoading) {
      this.setState({
        ...this.state,
        show_loader: false,
        forgot_password: false,
        after_link_request: true,
      });
    }
  }

  haveStateConnection() {
    if (window.navigator.onLine) {
      this.setState({
        ...this.state,
        connection: true,
      });
      return true;
    } else {
      this.setState({
        ...this.state,
        connection: false,
      });
      return false;
    }
  }

  haveConnection() {
    if (window.navigator.onLine) {
      return true;
    } else {
      return false;
    }
  }

  _isFormValid() {
    const { username, password } = this.state;
    return (
      password.inputValue.length > 0 &&
      regex.emailRegex.test(username.inputValue)
    );
  }

  handleChange = (key, value) => {
    this.setState(
      {
        ...this.state,
        [key]: { ...this.state[key], inputValue: value },
      },
      () => {
        if (!this._isFormValid()) {
          this.setState({
            ...this.state,
            loginEnabled: false,
          });
        }
      },
    );
  };

  handleLogin = (e) => {
    e.preventDefault();
    if (this.haveStateConnection()) {
      if (this._isFormValid()) {
        // ! FRONT QUICK FIX !
        if (!this.props.tooManyLoginAttempts)
          window.location.pathname === '/bapro'
            ? this.props.baproLogin(
                this.state.username.inputValue,
                this.state.password.inputValue,
              )
            : this.props.login(
                this.state.username.inputValue,
                this.state.password.inputValue,
              );
      } else {
        this.setState({
          ...this.state,
          isFormValid: false,
        });
      }
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.connection !== this.state.connection) {
      if (this.state.connection === false) {
        this.setState({
          ...this.state,
          errorLogin: false,
        });
      }
    }
    if (this._isFormValid() && !this.state.loginEnabled) {
      this.setState({
        ...this.state,
        loginEnabled: true,
      });
    }
    if (prevProps.loginStatus !== this.props.loginStatus) {
      if (this.props.loginStatus === 'ERROR') {
        this.setState({
          ...this.state,
          errorLogin: false,
        });
      }
    }
  }

  render() {
    return (
      <div className="login-screen">
        <div className="card">
          <div className="wrapper-title">
            <span className="material-symbols-rounded material-symbols-filled">
              person
            </span>
            <h2>{I18n.t('login.title')}</h2>
          </div>

          <form id="login-form" onSubmit={this.handleLogin}>
            <div className="form-body">
              <Input
                label={I18n.t('login.email')}
                name={'username'}
                change={this.handleChange}
                placeholder={I18n.t('login.placeholders.email')}
                value={this.state.username}
                disabled={this.props.loginLoading || this.state.disableInput}
                autoFocus
                errorLogin={this.state.errorLogin}
              />
              <Input
                label={I18n.t('login.password')}
                type={'password'}
                name={'password'}
                placeholder={I18n.t('login.placeholders.password')}
                change={this.handleChange}
                value={this.state.password}
                disabled={this.props.show_loader}
                ignoreDirty
                errorLogin={this.state.errorLogin}
              />
              {<span className="credentials-error">{this.renderError()}</span>}

              {window.location.pathname !== '/bapro' && (
                <div className="forgot-password forgot-wrap">
                  <button
                    className="text-link-button"
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.loginFormReset();
                      this.props.history.push('recover-password');
                    }}
                  >
                    {I18n.t('login.forgotPassword')}
                  </button>
                </div>
              )}
            </div>

            <div className="form-footer">
              {this.state.show_loader ? (
                <Loader />
              ) : (
                <Button
                  className={'primary'}
                  iconButton={true}
                  disabled={
                    !this.state.loginEnabled || this.props.tooManyLoginAttempts
                  }
                  iconClass="login"
                  translate={true}
                  text={'login.title'}
                  type={'submit'}
                />
              )}
              <p className="version">{settings.version}</p>
            </div>
          </form>
        </div>
      </div>
    );
  }

  renderError() {
    const { loginStatus, userservicelogin } = this.props;

    if (!this.haveConnection()) {
      return (
        <p className="error-message text-warning">
          {I18n.t('common.connection')}
        </p>
      );
    }
    if (userservicelogin) return null;
    if (loginStatus === reqStates.NONE) {
      if (!this.state.isFormValid) {
        return (
          <p className="error-message text-warning">
            {I18n.t('login.errors.invalidForm')}
          </p>
        );
      }
      return null;
    } else if (loginStatus === reqStates.ERROR) {
      const { status, data } = this.props.response;
      // ! FRONT QUICK FIX !
      if (this.props.tooManyLoginAttempts)
        return (
          <p className="error-message text-warning">
            {I18n.t('login.errors.tooManyAttempts')}
          </p>
        );
      if (status === 400)
        if (data.non_field_errors)
          return (
            <p className="error-message text-warning">
              {I18n.t('login.errors.wrongAuth')}
            </p>
          );
        else
          return (
            <p className="error-message text-warning">
              {I18n.t('login.errors.userDisabled')}
            </p>
          );
      else if (status === 401 && data === 'user_player') {
        return (
          <p className="error-message text-warning">
            {I18n.t('login.errors.userPlayer')}
          </p>
        );
      } else if (status === 401 && data === 'bapro_perms') {
        return (
          <p className="error-message text-warning">
            No tienes permisos de Bapro
          </p>
        );
      } else if (status === 404)
        return (
          <p className="error-message text-warning">
            {I18n.t('login.errors.wrongAuth')}
          </p>
        );
      else if (status === 412)
        return (
          <p className="error-message text-warning">
            Para continuar deberá completar el juego conductual
          </p>
        );
      else
        return (
          <p className="error-message text-warning">
            {'Error al ingresar (' + status + ')'}
          </p>
        );
    } else {
      return null;
    }
  }
}

export default LoginScreen;
