import React, { Component } from 'react';
import './ProfileScreen.css';
import 'react-table/react-table.css';
import { I18n } from 'react-redux-i18n';
import Dropdown from '../../../components/common/Dropdown';
import Loader from '../../../components/common/Loader';
import Tooltip from '../../../components/common/Tooltip';
import SquareButton from '../../../components/common/SquareButton';
import PositionCard from '../../../components/common/PositionCard/PositionCard';
import SearchInput from '../../../components/common/SearchInput/SearchInput';

class ProfileScreen extends Component {
  state = {
    selectedTags: [],
    searchTags: '',
    searchResults: '',
    allTagsEmpty: true,
  };

  handleTagSelect = async (id, name) => {
    let newTags = [...this.state.selectedTags];
    if (newTags.some((tag) => tag.id === id)) {
      newTags = newTags.filter((tag) => tag.id !== id);
    } else newTags.push({ id, name });
    await this.props.getProfiles(newTags.map((t) => t.id));
    this.setState({ ...this.state, selectedTags: newTags });
  };

  handleSearch = (e) => {
    this.setState({ ...this.state, [e.target.name]: e.target.value });
  };

  /* handleTagSelect = async (index, value) => {
    let newTags = [...this.state.tags];
    if (value.code === -1) newTags[index].inputValue = '';
    else newTags[index].inputValue = value;

    if (newTags.some((tag) => tag.inputValue !== '')) {
      this.setState({ ...this.state, tags: newTags, allTagsEmpty: false });
      let prueba = newTags.map((tag) => {
        return tag.inputValue.code;
      });
      console.log('prueba', prueba);
      await this.props.getProfiles(
        newTags.map((tag) => {
          return tag.inputValue.code;
        }),
      );
    } else {
      this.setState({ ...this.state, allTagsEmpty: true });
    }
  };
 */
  async componentDidMount() {
    await this.props.getTags();
    let tag_ids = '';
    this.props.getProfiles(tag_ids);
  }

  /* noRepeatedGroup(tag) {
    let selected = this.state.tags.map((tag) => tag.inputValue.group);
    if (tag.group === null) {
      let selectedCode = this.state.tags.map((tag) => tag.inputValue.code);
      return selectedCode.indexOf(tag.code) < 0;
    }
    return selected.indexOf(tag.group) < 0;
  } */

  render() {
    return (
      <div className=" profile-container">
        <div className="row tag-container ">
          <div className="col-md-4 col-lg-3 tags">
            <div className="tags-header">
              <div className="profile-header">
                <p>{I18n.t('profiles.tag-title')}</p>
                <Tooltip
                  translate
                  message="profiles.tooltip"
                  materialIcon="contact_support"
                />
              </div>
              <SearchInput
                name="searchTags"
                placeholder={I18n.t('profiles.search-label')}
                onChange={this.handleSearch}
              />
            </div>
            {this.renderDropdowns()}
            <span className="tags-paginate">
              {I18n.t('profiles.paginate.tags', {
                selected: this.state.selectedTags.length,
                total: this.props.tags.length,
              })}
            </span>
          </div>
          <div className="col-md-8 col-lg-9 results">
            {this.renderResults()}
          </div>
        </div>
      </div>
    );
  }

  renderDropdowns() {
    const { loading, success, error } = this.props.tagsStatus;
    if (error)
      return (
        <div className="profile-dropdowns">
          <div className="text-warning">
            {I18n.t('profiles.error.dropdown')}
          </div>
        </div>
      );

    if (loading)
      return (
        <div className="loader">
          <Loader />
        </div>
      );
    if (!loading && !error)
      return (
        <div className="tag-list">
          {this.props.tags.map((tag) => {
            if (!this.state.selectedTags.some((t) => t.id === tag.id)) {
              if (
                !tag.name
                  .toLowerCase()
                  .includes(this.state.searchTags.toLowerCase())
              )
                return null;
            }
            return (
              <SquareButton
                key={tag.id}
                description={<>{tag.description}</>}
                name={`tags-${tag.name}`}
                change={() => this.handleTagSelect(tag.id, tag.name)}
                labelBody={<div>{tag.name}</div>}
                value={{
                  inputValue: this.state.selectedTags.some(
                    (t) => t.id === tag.id,
                  ),
                }}
                disabled={
                  !this.props?.profiles?.availableTags?.some(
                    (t) => t === tag.id,
                  )
                }
              />
            );
          })}
        </div>
      );
    /* else
      return [0, 1, 2, 3, 4].map((number, id) => (
        <div className="profile-tag-dropdown">
          <Dropdown
            loading={loading || !success}
            showEmptyOption
            key={'tag-dropdown' + id}
            name={number}
            value={this.state.tags[number]}
            placeholder={I18n.t('profiles.tag') + ' ' + (number + 1)}
            change={this.handleTagSelect}
            options={[].concat(
              this.props.tags
                .map((tag) => ({
                  code: tag.id,
                  description: tag.name,
                  group: tag.group,
                }))
                .filter((tag) => this.noRepeatedGroup(tag)),
            )}
          />
        </div>
      )); */
  }

  renderResults() {
    const { loading, success, error } = this.props.profilesStatus;
    if (loading)
      return (
        <div className="loader">
          <Loader />
        </div>
      );
    if (error)
      return (
        <div className="error">
          <span>{I18n.t('profiles.error.getProfiles')}</span>
        </div>
      );
    /* if (this.state.selectedTags.length === 0)
      return <div className="primary">{I18n.t('profiles.noSelected')}</div>; */
    let profiles =
      this.props.profiles?.results?.length > 0
        ? this.props.profiles?.results
        : this.props.profiles;
    let profilesFilterCount = profiles.filter((p) =>
      p.name.toLowerCase().includes(this.state.searchResults.toLowerCase()),
    ).length;
    return [
      <div className="header">
        <div>
          {this.state.selectedTags.length === 0 ? (
            <>
              <span class="material-symbols-rounded privacy">privacy_tip</span>
              <span>{I18n.t('profiles.information')}</span>
            </>
          ) : (
            <div className="selected-tags">
              {this.state.selectedTags.map((tag) => (
                <button
                  onClick={() => this.handleTagSelect(tag.id, tag.name)}
                  key={tag.id}
                >
                  {tag.name}
                  <span>x</span>
                </button>
              ))}
            </div>
          )}
        </div>
        <SearchInput
          displayValue={this.state.searchResults}
          placeholder={I18n.t('toolbox.profileSearch')}
          name="searchResults"
          onChange={this.handleSearch}
        />
      </div>,
      <div className="profile-result-container">
        {profiles?.length > 0 &&
          profiles?.slice(0, 26).map((item, index) => {
            if (
              !item.name
                .toLowerCase()
                .includes(this.state.searchResults.toLowerCase())
            )
              return null;
            return (
              <div className="profile-result" key={'profiles-' + item.name}>
                <PositionCard
                  name={item.name}
                  behave={item.description}
                  traits={item.nwm_profile_x_trait_value}
                  tags={item.nwm_profile_x_tag}
                  isCollapsable={true}
                  id={item.id}
                  language={this.props.language}
                />
              </div>
            );
          })}
      </div>,
      <span className="total-profiles">
        {I18n.t('profiles.paginate.results', {
          selected: profilesFilterCount > 26 ? 26 : profilesFilterCount,
          total: 26,
        })}
      </span>,
      this.props.profiles?.results?.length === 0 && (
        <div className="text-warning">{I18n.t('profiles.noResults')}</div>
      ),
    ];
  }
}

export default ProfileScreen;
