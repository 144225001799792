import actions from '../actions/index';
import { services } from './common.services';

import {
  GET_CREDITS_HISTORY,
  UPDATE_FILTER_VALUES_CREDITS_HISTORY,
  UPDATE_PAGE_CREDITS_HISTORY,
  UPDATE_PAGE_SIZE_CREDITS_HISTORY,
} from './common.actions';
import { parseBackDate } from '../utils/parser';

const customParseQueryObject = (object) => {
  return (
    '?' +
    Object.keys(object)
      .filter((key) => {
        return object[key];
      })
      .map((key) => {
        if (key === 'search') {
          if (object[key].length === 0) return null;
          let clients = [];
          object[key].forEach((clientId) => clients.push(`client=${clientId}`));
          return clients.join('&');
        } else return `${key}=${object[key]}`;
      })
      .join('&')
  );
};

const commonMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    switch (action.type) {
      case GET_CREDITS_HISTORY:
        const actionFilter = { ...getState().common.filterValues };
        const filter = {
          ...actionFilter,
          search:
            actionFilter.search && actionFilter.search?.length > 0
              ? actionFilter.search
              : getState().clients.filterValues.search,
          page: action.resetPage ? 1 : getState().common.currentPage,
          page_size: getState().common.pageSize,

          start_date: getState().common.filterDates.start_value,
          end_date: getState().common.filterDates.end_value,
        };
        delete filter.accounts;
        delete filter.branchs;
        if (actionFilter?.branchs?.inputValue?.length) {
          filter.branchs = actionFilter.branchs.inputValue.map(
            (branch) => branch.code,
          );
        }
        if (actionFilter?.accounts?.inputValue?.length) {
          filter.accounts = actionFilter.accounts.inputValue.map(
            (account) => account.code,
          );
        }
        // add 1 day to the 'to' date. Necessary for from-to same date case.
        if (filter.to) {
          const date = new Date(filter.to);
          filter.to = parseBackDate(new Date(date.setDate(date.getDate() + 1)));
        }
        // delete filter.search;
        // delete filter.branch;

        services
          .getCreditsHistory(filter)
          .then((data) =>
            dispatch(actions.common.getCreditsHistoryResponse(data)),
          )
          .catch((error) =>
            dispatch(actions.common.getCreditsHistoryError(error)),
          );
        break;

      case UPDATE_PAGE_CREDITS_HISTORY:
        dispatch(actions.common.getCreditsHistory());
        break;

      case UPDATE_PAGE_SIZE_CREDITS_HISTORY:
        dispatch(actions.common.getCreditsHistory());
        break;

      case UPDATE_FILTER_VALUES_CREDITS_HISTORY:
        dispatch(actions.common.getCreditsHistory(true));
        break;

      default:
        break;
    }
  };

export default commonMiddleware;
