import React, { Component } from 'react';
import './UsersScreen.sass';
import 'react-table/react-table.css';
import ReactTable from 'react-table';
import { I18n } from 'react-redux-i18n';
import Button from '../../../components/common/Button';
import Input from '../../../components/common/Input';
import { notify } from 'react-notify-toast';
import {
  errorMessage,
  successMessage,
  SnackBar,
} from '../../../utils/snackbar';
import { success, error } from '../../../utils/modals';
import DeleteUserModal from '../../../components/common/DeleteUserModal/DeleteUserModal';
import DateRangePickerFilter from '../../../components/common/DateRangePickerFilter/DateRangePickerFilter';
import Loader from '../../../components/common/Loader';
import TablePaginationDrawer from '../../../components/common/TablePaginationDrawer/TablePaginationDrawer';
import TableLoader from '../../../components/common/TableLoader/TableLoader';
import dayjs from 'dayjs';
import { Tabs, Tab } from 'react-bootstrap';
import Dropdown from '../../../components/common/Dropdown';
import DeleteModal from '../../../components/common/ModalAlert/ModalAlert';
import LargeTextTooltip from '../../../components/common/LargeTextTooltip';
import TableMenuItem from '../../../components/common/TableMenuItem/TableMenuItem';

// import DateRangePickerFilter from '../../../components/common/DateRangePickerFilter/DateRangePickerFilter';

class UsersScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      userId: undefined,
      users: [],
      userName: '',
      values: {
        search: { inputValue: '' },
      },
      largeText: false,
      allItems: [{ key: 'branch', title: I18n.t('users.identifier') }],
      // range: {
      //   start: this.props.start_range || this.props.start_date,
      //   end: this.props.end_range || this.props.end_date,
      // },
      filteringSearch: false,
      filteringDate: false,
      largeText: false,
      isReset: true,
      clearInputValue: false,
    };
  }

  componentDidMount() {
    if (this.props.userInfo.user_admin) {
      this.setState({
        ...this.state,
        allItems: [
          { key: 'admin', title: I18n.t('users.localUsers') },
          { key: 'account', title: I18n.t('users.usersAccount') },
          { key: 'branch', title: I18n.t('users.usersBranch') },
        ],
        selectedTab: this.props.history.location.state || 'admin',
      });
      this.props.updateFilterValues({
        type: this.props.history.location.state || 'admin',
        search: '',
        current: 1,
        page_size: 10,
        // start_date: this.props.start_date,
        // end_date: this.props.end_date,
      });
    }

    if (this.props.userInfo.account) {
      this.setState({
        ...this.state,
        allItems: [
          { key: 'account', title: I18n.t('users.localUsers') },
          { key: 'branch', title: I18n.t('users.usersBranch') },
        ],
        selectedTab: this.props.history.location.state || 'account',
      });
      this.props.updateFilterValues({
        type: this.props.history.location.state || 'account',
        search: '',
        current: 1,
        page_size: 10,
        // start_date: this.props.start_date,
        // end_date: this.props.end_date,
      });
    }
    if (this.props.userInfo.branch) {
      this.setState({
        ...this.state,
        selectedTab: this.props.history.location.state || 'branch',
      });
      this.props.updateFilterValues({
        type: this.props.history.location.state || 'branch',
        search: '',
        current: 1,
        page_size: 10,
        // start_date: this.props.start_date,
        // end_date: this.props.end_date,
      });
    }
    // this.setState({
    //   ...this.state,
    //   range: {
    //     start:
    //       this.props.customFilter?.start_range ||
    //       this.state.start_date ||
    //       this.props.start_date,
    //     end:
    //       this.props.customFilter?.end_range ||
    //       this.state.end_date ||
    //       this.props.end_date,
    //   },
    // });
    setTimeout(
      () => this.setState({ ...this.state, largeText: !this.state.largeText }),
      1000,
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.users !== this.props.users) {
      // assignation of user role so that ReactTable is able to use accessor as user.role
      let usersCopyTemp = JSON.parse(JSON.stringify(this.props.users));
      Object.values(usersCopyTemp).forEach((user) => {
        const role =
          user.nwm_user_x_role.length === 0
            ? null
            : user.nwm_user_x_role[0].name;
        if (role === 'PartnerUser') user.role = I18n.t('users.user');
        else if (role === 'PartnerAdmin') user.role = I18n.t('users.admin');
      });
      this.setState({
        ...this.state,
        users: usersCopyTemp,
      });
    }
    if (
      this.state.values.search.inputValue.length < 1 &&
      this.state.filteringSearch
    ) {
      this.setState({
        ...this.state,
        filteringSearch: false,
      });
      this.removeFilters(this.state.selectedTab);
    }
    if (prevState.clearInputValue === true) {
      this.setState({
        ...this.state,
        clearInputValue: false,
      });
    }
    if (prevState.clearInputValue !== this.state.clearInputValue) {
      this.setState({
        ...this.state,
        values: {
          search: { inputValue: '' },
        },
      });
    }
  }

  handleSelect = (key) => {
    this.setState(
      {
        ...this.state,
        selectedTab: key,
      },
      () => this.removeFilters(key),
    );
  };

  removeFilters(key) {
    this.setState({
      ...this.state,
      isReset: true,
      values: { search: { inputValue: '' } },
      range: { start: '', end: '' },
      selectedTab: key,
      filteringDate: false,
      filteringSearch: false,
      isReset: true,
      clearInputValue: true,
    });
    this.props.updateFilterValues({
      type: key,
      search: '',
      current: 1,
      page_size: 10,
      start_date: undefined,
      end_date: undefined,
    });
    this.props.getFilterDates({
      start_value: dayjs(this.props.start_date).toISOString(),
      end_value: dayjs(this.props.end_date).toISOString(),
    });
    this.props.getUsers();
  }

  filterByDates = (filter) => {
    this.handleShowHide();
    this.setState(
      {
        ...this.state,
        range: { start: filter.start_range, end: filter.end_range },
        filteringDate: true,
        isReset: false,
      },
      () => {
        this.props.updateFilterValues({
          start_date: dayjs(this.state.range.start).toISOString(),
          end_date: dayjs(this.state.range.end).toISOString(),
        });
      },
    );
  };

  handleInputChange = (key, value) => {
    this.setState({
      values: {
        ...this.state.values,
        [key]: { ...this.state.values[key], inputValue: value },
      },
    });
  };
  handleSearch = (key, value) => {
    this.setState({
      ...this.state,
      values: {
        ...this.state.values,
        [key]: { ...this.state.values[key], inputValue: value },
      },
    });
    if (this.searchTimeout) clearTimeout(this.searchTimeout);

    this.searchTimeout = setTimeout(
      function () {
        this.setState({
          ...this.state,
          filteringSearch: true,
        });
        this.props.updateFilterValues({
          search: value,
          page_size: this.props.pageSize,
        });
      }.bind(this),
      300,
    );
  };

  handleShowHide = (show, id) => {
    this.setState({ ...this.state, showModal: show, userId: id });
  };

  render() {
    const { success, loading } = this.props.retrieveStatus;
    this.checkNotificationRenders();
    return (
      <div className={'container-fluid UsersScreen'}>
        <div className="row">
          <DeleteModal
            show={this.state.showModal}
            actionConfirm={() => {
              this.props.deleteUser(this.state.userId);
            }}
            onHide={() => this.handleShowHide(false)}
            actionCancel={() => this.handleShowHide(false)}
            title={I18n.t('common.attention')}
            message={I18n.t('users.unbindModalBodyAlternative', {
              user: this.state.userName,
            })}
            textButtonConfirm={I18n.t('buttons.delete')}
          />
          <DeleteUserModal />
          <div className="col-md-12">
            <div className="table">
              <Tabs
                defaultActiveKey={
                  this.props.history.location.state || this.state.selectedTab
                }
                id="uncontrolled-tab-example"
                onSelect={this.handleSelect}
                value={this.state.selectedTab}
              >
                <div className={'card-header-right'}>
                  <Button
                    className="primary"
                    text={I18n.t('users.new')}
                    onClick={() => this.props.history.push('/main/new-user')}
                    iconButton={true}
                    iconClass={'add'}
                  />
                </div>
                <div className={'search-bar'}>
                  <div className="left-side-search">
                    <div className="bar-filter-container">
                      <Input
                        name="search"
                        value={this.state.values.search}
                        placeholder={I18n.t('persons.search')}
                        change={this.handleSearch}
                        clearInput={
                          this.props.clearInput || this.state.clearInputValue
                        }
                        iconClass={'search'}
                        iconInput={true}
                        className={'input-search'}
                        type="filter"
                      />
                    </div>
                    <div className="date-range-selector">
                      <DateRangePickerFilter
                        start_date={
                          dayjs(this.props.start_date).format(
                            'YYYY-MM-DDT00:00:00',
                          ) + '.000Z'
                        }
                        end_date={
                          dayjs(this.props.end_date).format(
                            'YYYY-MM-DDT23:59:59',
                          ) + '.000Z'
                        }
                        classNameButton="date-person"
                        {...this.props}
                        filter={this.filterByDates}
                        resetFilter={this.state.isReset}
                        hiddenPopup={true}
                        filteringDate={this.state.filteringDate}
                      />
                    </div>
                  </div>
                  <div className="delete-button-container">
                    <Button
                      className="text-link-button delete-button"
                      text={I18n.t('buttons.clearFilter')}
                      onClick={() => this.removeFilters(this.state.selectedTab)}
                      iconButton={true}
                      iconClass={'close'}
                      disabled={
                        !this.state.filteringSearch && !this.state.filteringDate
                      }
                    />
                  </div>
                </div>

                {this.state.allItems.map((el) => (
                  <Tab eventKey={el.key} title={el.title}>
                    {this.state.selectedTab === el.key && (
                      <div className="table">{this.renderTable(el.key)}</div>
                    )}
                  </Tab>
                ))}
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderTable(type) {
    const { loading, success, error } = this.props.retrieveStatus;
    let columns = [
      {
        Header: () => {
          return this.props.ordering === '-full_name' ? (
            <div className="header-container">
              <span>{I18n.t('users.first_name')}</span>
              <span class="material-symbols-rounded">arrow_drop_up</span>
            </div>
          ) : (
            <div className="header-container">
              <span>{I18n.t('users.first_name')}</span>
              <span class="material-symbols-rounded">arrow_drop_down</span>
            </div>
          );
        },
        headerClassName: 'wordwrap',
        className: 'full-name-cell',
        id: 'full_name',
        accessor: (d) => d,
        Cell: (props) => {
          return (
            <>
              <span
                className="number"
                data-tooltip-content={props.value.full_name}
                data-tooltip-id={'full-name-person-tooltip-' + props.value.id}
                id={'full-name-person-' + props.value.id}
              >
                {props.value.full_name}
              </span>
              <LargeTextTooltip
                id={'full-name-person-tooltip-' + props.value.id}
                idToOverflow={'full-name-person-' + props.value.id}
                key={this.state.largeText}
              />
              <span
                className={`admin-sub ${
                  !props.value.nwm_user_admin.length >= 1 && 'hidden'
                }`}
              >
                {I18n.t('users.admin')}
              </span>
            </>
          );
        },
      },
      {
        Header: () => {
          return this.props.ordering === '-email' ? (
            <div className="header-container">
              <span>{I18n.t('users.email')}</span>
              <span class="material-symbols-rounded">arrow_drop_up</span>
            </div>
          ) : (
            <div className="header-container">
              <span>{I18n.t('users.email')}</span>
              <span class="material-symbols-rounded">arrow_drop_down</span>
            </div>
          );
        },
        headerClassName: 'wordwrap',
        className: 'user-email',
        accessor: 'email',
        Cell: (props) => {
          return (
            <>
              <LargeTextTooltip
                id={'email-person-tooltip-' + props.original.id}
                idToOverflow={'email-person-' + props.original.id}
                key={this.state.largeText}
              />
              <span
                className="number"
                id={'email-person-' + props.original.id}
                data-tooltip-id={'email-person-tooltip-' + props.original.id}
                data-tooltip-content={props.value}
              >
                {props.value}
              </span>
            </>
          );
        },
      },
      {
        Header: () => {
          return this.props.ordering === '-created' ? (
            <div className="header-container">
              <span>{I18n.t('users.created_at')}</span>
              <span class="material-symbols-rounded">arrow_drop_up</span>
            </div>
          ) : (
            <div className="header-container">
              <span>{I18n.t('users.created_at')}</span>
              <span class="material-symbols-rounded">arrow_drop_down</span>
            </div>
          );
        },
        headerClassName: 'wordwrap centered-head-container',
        className: 'centered-cell-container',
        accessor: 'created',
        id: 'created',
        Cell: (props) => (
          <span className="number created-date">
            {dayjs(props.value).format('DD/MM/YYYY')}
          </span>
        ),
      },
      {
        Header: () => {
          return this.props.ordering === '-is_active' ? (
            <div className="header-container">
              <span>{I18n.t('clients.enabled')}</span>
              <span class="material-symbols-rounded">arrow_drop_up</span>
            </div>
          ) : (
            <div className="header-container">
              <span>{I18n.t('clients.enabled')}</span>
              <span class="material-symbols-rounded">arrow_drop_down</span>
            </div>
          );
        },
        headerClassName: 'wordwrap enabled centered-head-container',
        accessor: 'is_active',
        className: 'enabled centered-cell-container',
        Cell: (props) => (
          <span className="number">
            {props.value ? I18n.t('common.yes') : I18n.t('common.no')}
          </span>
        ),
      },
      {
        Header: () => {
          return this.props.ordering === '-nwm_user_x_role' ? (
            <div className="header-container">
              <span>{I18n.t('users.permissions')}</span>
              <span class="material-symbols-rounded">arrow_drop_up</span>
            </div>
          ) : (
            <div className="header-container">
              <span>{I18n.t('users.permissions')}</span>
              <span class="material-symbols-rounded">arrow_drop_down</span>
            </div>
          );
        },
        headerClassName: 'wordwrap roles',
        className: `roles roles-${this.state.selectedTab}`,
        accessor: 'nwm_user_x_role',
        Cell: (props) => {
          let roles = [];
          props.value
            .filter((val) => val.role.id !== 4)
            .map((val) =>
              roles.push(val.role[`name_${this.props.language.toLowerCase()}`]),
            );

          const rolesEval = () => {
            if (props.original.nwm_user_admin?.length) {
              return 4;
            }
            if (
              props.original.nwm_user_company[0]?.nwm_user_company_x_account
                .length
            ) {
              return 4;
            }
            if (
              props.original.nwm_user_company[0]?.nwm_user_company_x_branch
                .length
            ) {
              return 6;
            }
          };
          return (
            <>
              <LargeTextTooltip
                id={'permissions-person-tooltip-' + props.original.id}
                idToOverflow={'permissions-person-' + props.original.id}
                key={this.state.largeText}
              />
              <span
                className="number"
                id={'permissions-person-' + props.original.id}
                data-tooltip-id={
                  'permissions-person-tooltip-' + props.original.id
                }
                data-tooltip-content={
                  roles.length > rolesEval()
                    ? I18n.t('common.sidebar.allPermissions')
                    : roles.join(' - ')
                }
              >
                {roles.length >= rolesEval()
                  ? I18n.t('common.sidebar.allPermissions')
                  : roles.join(' - ')}
              </span>
            </>
          );
        },
      },
      {
        Header: () => {
          return this.props.ordering === '-nwm_user_company' ? (
            <div className="header-container">
              <span>{I18n.t('clients.accountTable')}</span>
              <span class="material-symbols-rounded">arrow_drop_up</span>
            </div>
          ) : (
            <div className="header-container">
              <span>{I18n.t('clients.accountTable')}</span>
              <span class="material-symbols-rounded">arrow_drop_down</span>
            </div>
          );
        },
        headerClassName: 'wordwrap',
        accessor: 'nwm_user_company',
        Cell: (props) => {
          return (
            <span className="number">
              {props.value[0]?.nwm_user_company_x_account[0]?.account?.name}
            </span>
          );
        },
      },
      {
        Header: () => {
          return this.props.ordering === '-nwm_user_company' ? (
            <div className="header-container">
              <span>{I18n.t('clients.filialInput')}</span>
              <span class="material-symbols-rounded">arrow_drop_up</span>
            </div>
          ) : (
            <div className="header-container">
              <span>{I18n.t('clients.filialInput')}</span>
              <span class="material-symbols-rounded">arrow_drop_down</span>
            </div>
          );
        },
        headerClassName: 'wordwrap',
        accessor: 'nwm_user_company',
        Cell: (props) => {
          return (
            <>
              <LargeTextTooltip
                id={'branch-person-tooltip' + props.original.id}
                idToOverflow={'branch-person-' + props.original.id}
                key={this.state.largeText}
              />
              <span
                className="number"
                id={'branch-person-' + props.original.id}
                data-tooltip-id={'branch-person-tooltip' + props.original.id}
                data-tooltip-content={
                  props.value[0]?.nwm_user_company_x_branch[0]?.branch?.name
                }
              >
                {props.value[0]?.nwm_user_company_x_branch[0]?.branch?.name}
              </span>
            </>
          );
        },
      },
      {
        Header: '',
        headerClassName: 'wordwrap dropdown-options',
        id: 'delete',
        sortable: false,
        className: 'dropdown-options',
        width: 97,
        accessor: (d) => d.id,
        Cell: (props) => {
          return (
            <TableMenuItem>
              <Dropdown
                value={{
                  inputValue: {
                    code: '1',
                    label: (
                      <span class="material-symbols-rounded">more_vert</span>
                    ),
                  },
                }}
                className="users-dropdown"
                noBorder
                change={() => this.handleActions(props.value)}
                options={[
                  {
                    code: 'edit',
                    label: (
                      <Button
                        text={I18n.t('common.edit')}
                        iconButton={true}
                        iconClass="edit"
                        iconFilled
                        className="text-link-button normal-button"
                        onClick={(e, handleOriginal) => {
                          if (handleOriginal) handleOriginal();
                          this.props.selectUser(props.original.id);
                          this.props.history.push('/main/user');
                        }}
                      />
                    ),
                    disabled: false,
                  },
                  {
                    code: 'delete',
                    label: (
                      <Button
                        text={I18n.t('common.delete')}
                        iconButton={true}
                        iconClass="delete"
                        iconFilled
                        className="text-link-button delete-button user-screen"
                        onClick={async (e, handleOriginal) => {
                          if (handleOriginal) handleOriginal();
                          this.setState({
                            ...this.state,
                            userId: props.original.id,
                            userName: props.original.full_name,
                            showModal: true,
                          });
                        }}
                      />
                    ),
                    disabled: false,
                  },
                ]}
              />
            </TableMenuItem>
          );
        },
      },
    ];
    if (type === 'admin') {
      columns.splice(5, 2);
    }
    if (type === 'account') {
      columns.splice(6, 1);
    }
    if (type === 'branch') {
      columns.splice(5, 1);
    }

    if (error)
      return <div className="text-warning">{I18n.t('users.error.list')}</div>;

    if (success || loading)
      return (
        <>
          <ReactTable
            columns={columns}
            data={this.state.users}
            minRows={0}
            minWith={5}
            resizable={false}
            noDataText={I18n.t('users.table.noRows')}
            defaultSorted={[
              {
                id: 'created',
                desc: false,
              },
            ]}
            // TODO: ReactTable sorting
            onSortedChange={(newSorted, column, shiftKey) => {
              let filter = column.id;
              this.props.updateFilterValues({
                ordering: (newSorted[0].desc ? '-' : '') + filter,
                page_size: this.props.pageSize,
              });
              this.props.getUsers(this.state.values.search.inputValue);
            }}
            manual
            getTrProps={(state, rowInfo, column, instance) => {
              return {
                class: `${
                  rowInfo.original.nwm_user_admin.length &&
                  rowInfo.row.is_active === false
                    ? 'rt-tr user-list disabled'
                    : rowInfo.row.is_active === false
                    ? 'rt-tr user-list disabled'
                    : rowInfo.original.nwm_user_admin.length
                    ? 'rt-tr user-admin'
                    : 'rt-tr user-list'
                }`,
                style: {
                  cursor: 'default',
                },
              };
            }}
            manualPagination
            pageSize={this.props.pageSize}
            PaginationComponent={() => {
              const {
                currentPage,
                updatePage,
                totalPages,
                pageSize,
                totalCount,
                updatePageSize,
              } = this.props;
              const isOnFirstPage = currentPage === 1;
              const isOnLastPage = currentPage === totalPages;
              return (
                <TablePaginationDrawer
                  data={{
                    totalCount,
                    currentPage,
                    pageSize,
                    isOnFirstPage,
                    isOnLastPage,
                    updatePage,
                    totalPages,
                    updatePageSize,
                  }}
                  table={'users'}
                />
              );
            }}
            loading={loading}
            LoadingComponent={TableLoader}
          />
        </>
      );
    return <Loader />;
  }

  checkNotificationRenders() {
    if (this.props.createStatus.success) {
      notify.show(
        <SnackBar
          icon={'priority'}
          title={I18n.t('common.excellent')}
          body={I18n.t('users.success.create')}
          type="success"
          iconFilled
        />,
        'custom',
        5000,
        successMessage,
      );
      this.props.clearUiValue('create', 'success');
    }
    if (this.props.updateStatus.success) {
      notify.show(
        <SnackBar
          icon={'priority'}
          title={I18n.t('common.excellent')}
          body={I18n.t('users.success.update')}
          type="success"
          iconFilled
        />,
        'custom',
        5000,
        successMessage,
      );
      this.props.clearUiValue('update', 'success');
    }
    if (this.props.deleteStatus.success) {
      notify.show(
        <SnackBar
          icon={'report'}
          title={I18n.t('users.success.delete')}
          body={I18n.t('users.success.success', {
            user: this.state.userName,
          })}
          type="error"
          iconFilled
        />,
        'custom',
        5000,
        errorMessage,
      );
      this.props.clearUiValue('delete', 'success');
    }
    if (this.props.deleteStatus.error) {
      if (this.props.deleteStatus.status === 403) {
        notify.show(
          <SnackBar
            icon={'report'}
            title={I18n.t('common.attention')}
            body={I18n.t('users.error.canNotDeleteDMUser')}
            type="error"
            iconFilled
          />,
          'custom',
          5000,
          errorMessage,
        );
      } else {
        notify.show(
          <SnackBar
            icon={'report'}
            title={I18n.t('common.attention')}
            body={I18n.t('users.error.delete')}
            type="error"
            iconFilled
          />,
          'custom',
          5000,
          errorMessage,
        );
      }
      this.props.clearUiValue('delete', 'error');
    }
  }

  componentWillUnmount() {
    const nextLocation = this.props.history.location.pathname;
    if (nextLocation !== '/main/new-user' && nextLocation !== '/main/user') {
      this.props.updatePage(0);
    }
  }
}

export default UsersScreen;
