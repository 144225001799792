import React, { Component } from 'react';
import './ExternalLoginScreen.css';
// import Input from "../../../components/common/Input";
import Button from '../../../components/common/Button';
import { I18n } from 'react-redux-i18n';
import Loader from '../../../components/common/Loader';
import reqStates from '../../../utils/reqStates';
import settings from '../../../settings';
import { getAuthorizeHref } from '../../../oauthConfig';
import {
  getHashParams,
  removeHashParamsFromUrl,
} from '../../../utils/hashUtils';

// Obtener parámetros enviados por el authorization service:
// ACCESS_TOKEN + EXPIRATON TIME
// Limpiamos la url
let hashParams = getHashParams();
removeHashParamsFromUrl();

class OpenIDConnectCard extends Component {
  static defaultProps;

  constructor(props) {
    super(props);
    this.state = {
      isFormValid: true,
    };
  }

  componentDidMount() {
    if (hashParams && hashParams.code) {
      this.props.openIdConnect(hashParams.code, hashParams.expires_in);
    }
  }

  componentWillUnmount() {
    hashParams = null;
  }

  _isFormValid() {
    return true;
  }

  handleLogin = (e) => {
    e.preventDefault();
    window.open(getAuthorizeHref(), '_self');
  };

  render() {
    return (
      <div className="card">
        <form id="login-form" onSubmit={this.handleLogin}>
          <div className="form-body">{this.renderError()}</div>
          <div className="wrapper-title">
            <span className="material-symbols-rounded material-symbols-filled">
              person
            </span>
            <h2 className="external-login-title">{I18n.t('login.comfama')}</h2>
          </div>
          <div className="body-form">
            <img
              src="/logo_comfama.png"
              alt="logoimg"
              className="partner-logo"
            />
            {this.state.show_loader ? (
              <Loader />
            ) : (
              <Button
                className={'primary'}
                translate={true}
                iconButton={true}
                iconClass={'contacts'}
                text={I18n.t('login.validate')}
                type={'submit'}
                iconFilled
              />
            )}
            <button
              className="user-login"
              onClick={(e) => {
                e.preventDefault();
                this.props.history.push('/');
              }}
            >
              {I18n.t('login.login-with-user')}
            </button>
            <p className="version">{settings.version}</p>
          </div>
        </form>
      </div>
    );
  }

  renderError() {
    const { loginStatus, userservicelogin } = this.props;
    if (userservicelogin && loginStatus === reqStates.ERROR) {
      return (
        <p className="error-message text-warning">
          {I18n.t('login.errors.comfama')}
        </p>
      );
    }

    return null;
  }
}

export default OpenIDConnectCard;
