import actions from '../actions/index';
import {
  LOGIN,
  BAPRO_LOGIN,
  LOGOUT,
  GET_PARTNER,
  ACTIVATE_ACCOUNT,
  RECOVER_PASSWORD,
  RESET_PASSWORD,
  VALIDATE_TOKEN_CHANGE_PASSWORD,
  OPEN_ID_AUTH_RESPONSE,
  GOOGLE_LOGIN,
} from './session.actions';
import { services } from './session.services';
import { push } from 'connected-react-router';
import { _saveToken } from '../utils/http';
import { getLogoutHref } from '../oauthConfig';
import { I18n } from 'react-redux-i18n';
import { notify } from 'react-notify-toast';
import { SnackBar, successMessage, errorMessage } from '../utils/snackbar';

const sessionMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    switch (action.type) {
      case LOGIN:
        services
          .login({ username: action.email, password: action.password })
          .then((data) => {
            const redirectPath =
              getState().session.redirectRoute || '/main/home';
            dispatch(actions.session.loginResponse(data));
            dispatch(push(redirectPath, { from: 'login' }));
          })
          .catch((error) => dispatch(actions.session.loginError(error)));
        break;
      case BAPRO_LOGIN:
        services
          .baproLogin({ username: action.email, password: action.password })
          .then((data) => {
            const redirectPath =
              getState().session.redirectRoute || '/main/bapro';
            dispatch(actions.session.baproLoginResponse(data));
            dispatch(push(redirectPath, { from: 'login' }));
          })
          .catch((error) => dispatch(actions.session.baproLoginError(error)));
        break;
      case GOOGLE_LOGIN:
        services
          .googleLogin({ code: action.code, token: true })
          .then((data) => {
            const redirectPath =
              getState().session.redirectRoute || '/main/bapro';
            dispatch(actions.session.googleLoginResponse(data));
            dispatch(push(redirectPath, { from: 'login' }));
          })
          .catch((error) => dispatch(actions.session.baproLoginError(error)));
        break;
      case GET_PARTNER:
        services
          .getPartner(action.id)
          .then((data) => dispatch(actions.session.getPartnerResponse(data)))
          .catch((error) => dispatch(actions.session.getPartnerError(error)));
        break;

      case LOGOUT:
        if (
          window.location.pathname === '/main/bapro' &&
          getState().session.user.roles.includes('bapro')
        ) {
          dispatch(push('/bapro'));
        } else {
          dispatch(push(''));
        }
        services.logout();
        if (getState().session.userservicelogin) {
          dispatch(actions.session.logoutResponse());
          window.open(getLogoutHref(), '_self');
        }
        dispatch(actions.session.logoutResponse());
        break;

      case ACTIVATE_ACCOUNT:
        services
          .activateToken(action.token)
          .then((response) =>
            dispatch(actions.session.activateResponse(response)),
          )
          .catch((error) => dispatch(actions.session.activateError(error)));
        break;

      case RECOVER_PASSWORD:
        // var lang = '';
        // if (localStorage.getItem('language') !== 'es')
        //   lang = localStorage.getItem('language') + '/';
        services
          .recoverPassword(action.email)
          .then((response) => {
            dispatch(actions.session.recoverPasswordResponse(response));
            const redirectPath = getState().session.redirectRoute || '/';
            // dispatch(push(redirectPath));
            notify.show(
              <SnackBar
                icon={'priority'}
                title={I18n.t('common.excellent')}
                body={I18n.t('login.emailMessage')}
                type="success"
              />,
              'custom',
              5000,
              errorMessage,
            );
          })
          .catch((error) =>
            dispatch(actions.session.recoverPasswordError(error)),
          );
        break;

      case RESET_PASSWORD:
        services
          .resetPassword(action.data)
          .then((response) =>
            dispatch(actions.session.resetPasswordResponse(response)),
          )
          .catch((error) =>
            dispatch(actions.session.resetPasswordError(error)),
          );
        break;

      case VALIDATE_TOKEN_CHANGE_PASSWORD:
        services
          .validateTokenChangePassword(action.data)
          .then((response) => {
            if (!response || !response.response) {
              const redirectPath = getState().session.redirectRoute || '/login';
              dispatch(push(redirectPath));
            }
          })
          .catch((error) => {
            const redirectPath = getState().session.redirectRoute || '/login';
            dispatch(push(redirectPath));
          });
        break;

      case OPEN_ID_AUTH_RESPONSE:
        // tengo el acces token y el expires in, necesito el user
        services
          .open_id_user(action.access_token, action.expires_in)
          .then((data) => {
            _saveToken(data.token);
            const redirectPath =
              getState().session.redirectRoute || '/main/home';
            dispatch(actions.session.loginResponse(data));
            dispatch(push(redirectPath));
          })
          .catch((error) => dispatch(actions.session.loginError(error)));
        break;

      default:
        break;
    }
  };

export default sessionMiddleware;
