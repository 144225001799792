import React, { Component } from 'react';

import './LargeTextTooltip.css';
import { Tooltip } from 'react-tooltip';

class LargeTextTooltip extends Component {
  render() {
    const { id, idToOverflow } = this.props;
    const width = window.innerWidth > 1367 ? 268 : 160

    console.log(width)
    const renderTooltip = idToOverflow.includes('email-not-consumed')
      ? document.querySelector('#' + idToOverflow)?.offsetWidth >=
        width
      : document.querySelector('#' + idToOverflow)?.offsetWidth <
        width

    if (renderTooltip || !idToOverflow)
      return <Tooltip id={id} place="top" effect="solid" />;
    else return <></>;
  }
}

export default LargeTextTooltip;
