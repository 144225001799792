import { connect } from 'react-redux';
import ProfileScreen from '../components/ProfileScreen/ProfileScreen';
import actions from '../../actions';
import { withRouter } from 'react-router';

const mapStateToProps = (state, ownProps) => ({
  tags: state.profile.tags,
  tagsStatus: state.profile.tagsStatus,
  profiles: state.profile.profiles,
  profilesStatus: state.profile.getProfilesStatus,
  language: state.i18n.locale,
});

const mapDispatchToProps = (dispatch) => ({
  getTags: () => dispatch(actions.profile.getTags()),
  getProfiles: (tags) => dispatch(actions.profile.getProfiles(tags)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ProfileScreen));
