if (process.env.NODE_ENV === 'production') {
  module.exports = {
    testing: true,
    version: process.env.REACT_APP_VERSION,
    webApi: process.env.REACT_APP_API,
  };
} else {
  module.exports = {
    testing: true,
    version: process.env.REACT_APP_VERSION,
    webApi: process.env.REACT_APP_API,
  };
}
