import React, { useState, useEffect } from 'react';

export default function TextViewer({ file }) {
  const [content, setContent] = useState('');

  useEffect(() => {
    const decodeBase64 = (base64) => {
      const binaryString = atob(base64);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      return new Blob([bytes], { type: 'text/plain' });
    };

    const reader = new FileReader();
    reader.onload = (e) => {
      const text = e.target.result;
      setContent(text);
    };

    if (file && file.path && file.path.startsWith('data:text/plain;base64,')) {
      const base64 = file.path.split(',')[1];
      const blob = decodeBase64(base64);
      reader.readAsText(blob);
    }
  }, [file]);

  const containerStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100vh',
    width: '100vw',
    backgroundColor: 'rgba(0,0,0,0.8)',
    display: 'flex',
    flexDirection: 'row',
  };

  const contentStyle = {
    fontFamily: 'monospace',
    width: '50%',
    height: '70%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    backgroundColor: 'white',
    textAlign: 'left',
    margin: 'auto',
    padding: '20px',
    zIndex: 1000,
    overflowY: 'scroll',
    whiteSpace: 'pre-line',
  };

  return (
    <div style={containerStyle}>
      <div style={contentStyle}>{content}</div>
    </div>
  );
}
