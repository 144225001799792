import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-redux-i18n';
import './Label.css';

class Label extends Component {
  static defaultProps;

  state = {
    borderError: false,
    showError: false,
    dirty: false,
    showPassword: false,
  };

  render() {
    const { label, noTranslation } = this.props;
    return (
      <div className="label-container">
        {label && (
          <label className="custom-label">
            {noTranslation ? <span>{label}</span> : <Translate value={label} />}
          </label>
        )}
      </div>
    );
  }
}

Label.defaultProps = {
  label: '',
};
Label.propTypes = {
  label: PropTypes.string,
  noTranslation: PropTypes.bool,
};

export default Label;
