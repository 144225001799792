import { get, post, webApi } from '../utils/http';
import queryString from 'query-string';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
dayjs.extend(timezone);

export const services = {
  uploadFile: ({ id_user_player, curriculum, partner }) => {
    const formData = new FormData();
    formData.append('id_user_player', id_user_player);
    formData.append('partner', partner);
    if (curriculum && curriculum instanceof File) {
      formData.append('file', curriculum);
      formData.append('directory', curriculum.type);
    }

    return post(webApi + 'api/files/bapro', formData, {
      headers: {
        'Content-Type': `multipart/form-data`,
      },
    });
  },

  getBaproFiles: ({ id_user_player, partner }) => {
    return get(
      webApi +
        `api/files/bapro?id_user_player=${id_user_player}&partner=${partner}`,
    );
  },
};
