export const GET_CAMPAIGNS = 'GET CAMPAIGNS';
export const GET_CAMPAIGNS_RESPONSE = 'GET CAMPAIGNS RESPONSE';
export const GET_CAMPAIGNS_ERROR = 'GET CAMPAIGNS ERROR';

export const GET_CAMPAIGN = 'GET_CAMPAIGN';
export const GET_CAMPAIGN_RESPONSE = 'GET CAMPAIGN_RESPONSE';
export const GET_CAMPAIGN_ERROR = 'GET CAMPAIGN_ERROR';
export const GET_ALL_CAMPAIGNS = 'GET_ALL_CAMPAIGNS';
export const GET_ALL_CAMPAIGNS_RESPONSE = 'GET_ALL_CAMPAIGNS RESPONSE';
export const GET_ALL_CAMPAIGNS_ERROR = 'GET_ALL_CAMPAIGNS ERROR';

export const CREATE_CAMPAIGN = 'CREATE CAMPAIGN';
export const CREATE_CAMPAIGN_RESPONSE = 'CREATE CAMPAIGN RESPONSE';
export const CREATE_CAMPAIGN_ERROR = 'CREATE CAMPAIGN ERROR';

export const UPDATE_CAMPAIGN = 'UPDATE CAMPAIGN';
export const UPDATE_CAMPAIGN_RESPONSE = 'UPDATE CAMPAIGN RESPONSE';
export const UPDATE_CAMPAIGN_ERROR = 'UPDATE CAMPAIGN ERROR';

export const DELETE_CAMPAIGN = 'DELETE CAMPAIGN';
export const DELETE_CAMPAIGN_RESPONSE = 'DELETE CAMPAIGN RESPONSE';
export const DELETE_CAMPAIGN_ERROR = 'DELETE CAMPAIGN ERROR';

export const CAMPAIGN_CLEAN_ERRORS = 'CAMPAIGN_CLEAN_ERRORS';

export const SELECT_CAMPAIGN = 'SELECT CAMPAIGN';
export const UNSELECT_CAMPAIGN = 'UNSELECT CAMPAIGN';
export const UPDATE_PAGE = 'UPDATE PAGE CAMPAIGNS';
export const UPDATE_PAGE_SIZE = 'UPDATE PAGE CAMPAIGNS SIZE';
export const CLEAN_SEARCH_VALUE = 'CLEAN_SEARCH_VALUE';
export const UPDATE_CAMPAIGNS_FILTER_VALUES = 'UPDATE CAMPAIGNS FILTER VALUES';

export const GENERATE_CODE = 'GENERATE CODE';
export const GENERATE_CODE_RESPONSE = 'GENERATE CODE RESPONSE';
export const GENERATE_CODE_ERROR = 'GENERATE CODE ERROR';

export const UPDATE_CODE = 'UPDATE CODE';
export const UPDATE_CODE_RESPONSE = 'DELETE CODE RESPONSE';
export const UPDATE_CODE_ERROR = 'UPDATE CODE ERROR';

export const DELETE_CODE = 'DELETE CODE';
export const DELETE_CODE_RESPONSE = 'DELETE CODE RESPONSE';
export const DELETE_CODE_ERROR = 'DELETE CODE ERROR';

export const DELETE_CODES = 'DELETE CODES';
export const DELETE_CODES_RESPONSE = 'DELETE CODES RESPONSE';
export const DELETE_CODES_ERROR = 'DELETE CODES ERROR';

export const RELOAD_SELECTED_CAMPAIGN = 'RELOAD_SELECTED_CAMPAIGN';
export const RELOAD_SELECTED_CAMPAIGN_RESPONSE =
  'RELOAD_SELECTED_CAMPAIGN_RESPONSE';
export const RELOAD_SELECTED_CAMPAIGN_ERROR = 'RELOAD_SELECTED_CAMPAIGN_ERROR';

export const BUY_CREDITS = 'BUY CREDITS';
export const BUY_CREDITS_RESPONSE = 'BUY CREDITS RESPONSE';
export const BUY_CREDITS_ERROR = 'BUY CREDITS ERROR';

export const SHARE_CAMPAIGN = 'SHARE CAMPAIGN';
export const SHARE_CAMPAIGN_RESPONSE = 'SHARE CAMPAIGN RESPONSE';
export const SHARE_CAMPAIGN_ERROR = 'SHARE CAMPAIGN ERROR';

export const GET_POSITIONS = 'GET POSITIONS';
export const GET_POSITIONS_RESPONSE = 'GET POSITIONS RESPONSE';
export const GET_POSITIONS_ERROR = 'GET POSITIONS ERROR';

export const GET_FIELDS = 'GET FIELDS';
export const GET_FIELDS_RESPONSE = 'GET FIELDS RESPONSE';
export const GET_FIELDS_ERROR = 'GET FIELDS ERROR';

export const CLEAR_UI_VALUE = 'CLEAR UI VALUE';
export const LOGOUT = 'LOGOUT';

export const DOWNLOAD_TEMPLATE = 'DOWNLOAD TEMPLATE';

export const CLEAN_PLAYING_CODE_ERROR = 'CLEAN_PLAYING_CODE_ERROR';

export const campaigns = {
  //CRUD

  createCampaign: (body) => ({ type: CREATE_CAMPAIGN, body }),
  createCampaignResponse: (response) => ({
    type: CREATE_CAMPAIGN_RESPONSE,
    response,
  }),
  createCampaignError: (error) => ({
    type: CREATE_CAMPAIGN_ERROR,
    response: error,
  }),
  getCampaign: (campaign_id) => ({ type: GET_CAMPAIGN, campaign_id }),
  getCampaignResponse: (response) => ({
    type: GET_CAMPAIGN_RESPONSE,
    response,
  }),
  getCampaignError: (error) => ({
    type: GET_CAMPAIGN_ERROR,
    response: error,
  }),
  getCampaigns: (campaign_id) => ({ type: GET_CAMPAIGNS, campaign_id }),
  getCampaignsResponse: (response) => ({
    type: GET_CAMPAIGNS_RESPONSE,
    response,
  }),
  getCampaignsError: (error) => ({
    type: GET_CAMPAIGNS_ERROR,
    response: error,
  }),

  getAllCampaigns: (partners, campaign_dropdown, search = '', campaign_id) => ({
    type: GET_ALL_CAMPAIGNS,
    partners,
    campaign_dropdown,
    search,
    campaign_id,
  }),
  getAllCampaignsResponse: (response) => ({
    type: GET_ALL_CAMPAIGNS_RESPONSE,
    response,
  }),
  getAllCampaignsError: (error) => ({
    type: GET_ALL_CAMPAIGNS_ERROR,
    response: error,
  }),

  updateCampaign: (id, campaign) => ({
    type: UPDATE_CAMPAIGN,
    id: id,
    campaign,
  }),
  updateCampaignResponse: (response) => ({
    type: UPDATE_CAMPAIGN_RESPONSE,
    response,
  }),
  updateCampaignError: (error) => ({
    type: UPDATE_CAMPAIGN_ERROR,
    response: error,
  }),

  deleteCampaign: (id) => ({ type: DELETE_CAMPAIGN, id: id }),
  deleteCampaignResponse: (response) => ({
    type: DELETE_CAMPAIGN_RESPONSE,
    response,
  }),
  deleteCampaignError: (error) => ({ type: DELETE_CAMPAIGN_ERROR, error }),

  campaignCleanErrors: () => ({ type: CAMPAIGN_CLEAN_ERRORS }),

  selectCampaign: (id, all = false) => ({ type: SELECT_CAMPAIGN, id, all }),
  unselectCampaign: () => ({ type: UNSELECT_CAMPAIGN }),
  cleanSearchValue: (boolean) => ({
    type: CLEAN_SEARCH_VALUE,
    boolean,
  }),
  updateFilterValues: (filterValues) => ({
    type: UPDATE_CAMPAIGNS_FILTER_VALUES,
    filterValues,
  }),
  updatePage: (pageNumber) => ({ type: UPDATE_PAGE, pageNumber }),
  updatePageSize: (pageSize) => ({ type: UPDATE_PAGE_SIZE, pageSize }),

  //OPTIONS

  //UI

  clearUiValue: (type, condition) => ({
    type: CLEAR_UI_VALUE,
    op_type: type,
    condition,
  }),

  //OTHER

  generateCode: (id, email) => ({ type: GENERATE_CODE, id, email }),
  generateCodeResponse: (response) => ({
    type: GENERATE_CODE_RESPONSE,
    response,
  }),
  generateCodeError: (error) => ({ type: GENERATE_CODE_ERROR, error }),

  updateCode: (campaign, code_id, body) => ({
    type: UPDATE_CODE,
    campaign_id: campaign,
    code_id,
    body,
  }),
  updateCodeResponse: (response) => ({
    type: UPDATE_CODE_RESPONSE,
    response: response,
  }),
  updateCodeError: (error) => ({ type: UPDATE_CODE_ERROR, error: error }),

  deleteCode: (campaign, code_id) => ({
    type: DELETE_CODE,
    campaign_id: campaign,
    code_id,
  }),
  deleteCodeResponse: (response) => ({ type: DELETE_CODE_RESPONSE, response }),
  deleteCodeError: (error) => ({ type: DELETE_CODE_ERROR, error }),
  cleanPlayingCodeError: () => ({ type: CLEAN_PLAYING_CODE_ERROR }),

  deleteCodes: (ids) => ({
    type: DELETE_CODES,
    ids,
  }),
  deleteCodesResponse: (response) => ({
    type: DELETE_CODES_RESPONSE,
    response,
  }),
  deleteCodesError: (error) => ({ type: DELETE_CODES_ERROR, error }),

  reloadSelectedCampaign: (id) => ({ type: RELOAD_SELECTED_CAMPAIGN, id }),
  reloadSelectedCampaignResponse: (response) => ({
    type: RELOAD_SELECTED_CAMPAIGN_RESPONSE,
    response,
  }),
  reloadSelectedCampaignError: (error) => ({
    type: RELOAD_SELECTED_CAMPAIGN_ERROR,
    error,
  }),

  buyCredits: (quantity) => ({ type: BUY_CREDITS, quantity }),
  buyCreditsResponse: (response) => ({ type: BUY_CREDITS_RESPONSE, response }),
  buyCreditsError: (error) => ({ type: BUY_CREDITS_ERROR, error }),

  shareCampaign: (id) => ({ type: SHARE_CAMPAIGN, id }),
  shareCampaignResponse: (response) => ({
    type: SHARE_CAMPAIGN_RESPONSE,
    response,
  }),
  shareCampaignError: (error) => ({ type: SHARE_CAMPAIGN_ERROR, error }),

  getPositions: (positionDropdown, search) => ({
    type: GET_POSITIONS,
    positionDropdown,
    search,
  }),
  getPositionsResponse: (response) => ({
    type: GET_POSITIONS_RESPONSE,
    response,
  }),
  getPositionsError: (error) => ({ type: GET_POSITIONS_ERROR, error }),

  getFields: () => ({ type: GET_FIELDS }),
  getFieldsResponse: (response) => ({
    type: GET_FIELDS_RESPONSE,
    response,
  }),
  getFieldsError: (error) => ({ type: GET_FIELDS_ERROR, error }),

  logout: () => ({ type: LOGOUT }),

  downloadTemplate: () => ({ type: DOWNLOAD_TEMPLATE }),
};
