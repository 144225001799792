import React from 'react';
import { I18n } from 'react-redux-i18n';
import LargeTextTooltip from '../../../../components/common/LargeTextTooltip';
import './PersonalData.sass';

const Item = ({
  children,
  value,
  label,
  icon,
  valueId,
  tooltipId,
  isCustom = false,
}) => {
  return (
    <div className="item">
      <div
        className={`item-label ${
          (!value || value?.toLowerCase() === 'empty') && !isCustom
            ? 'disabled-item'
            : ''
        } ${isCustom ? 'custom-field' : ''}`}
      >
        <span className="material-symbols-filled">{icon}</span>
        <p>{label}</p>
      </div>

      <div
        className={`item-value ${
          (!value || value?.toLowerCase() === 'empty') && !isCustom
            ? 'disabled-item'
            : ''
        } ${isCustom ? 'custom-field' : ''}`}
      >
        <p
          id={valueId || 'value'}
          data-tooltip-id={tooltipId}
          data-tooltip-content={
            value && value?.toLowerCase() !== 'empty'
              ? value
              : I18n.t('persons.notRequested')
          }
        >
          {value && value?.toLowerCase() !== 'empty'
            ? value
            : I18n.t('persons.notRequested')}
        </p>
        {children}
      </div>
    </div>
  );
};
const PersonalData = ({ person, user, locale, handleCopy }) => {
  return (
    <div className="PersonalData">
      <div className="personal-data-div">
        <Item
          value={person.user_player.email || person.user_player.user.email}
          icon={'mail'}
          label={I18n.t('persons.personal-data.email')}
        ></Item>

        <Item
          value={person.user_player.phone_number}
          icon={'phone_iphone'}
          label={I18n.t('persons.personal-data.phone')}
        />

        <Item
          value={person.user_player.doc_value}
          icon={'fingerprint'}
          label={I18n.t('persons.personal-data.dni')}
        />
        <Item
          value={
            person.user_player.city?.country?.id
              ? person.user_player.city?.country[`name_${locale}`]
              : person.user_player?.country
              ? person.user_player?.country[`name_${locale}`]
              : null
          }
          icon={'public'}
          label={I18n.t('persons.personal-data.country')}
        />
        <Item
          value={
            person.user_player?.nwm_cityId
              ? person.user_player?.city[`name_${locale}`]
              : null
          }
          icon={'map'}
          label={I18n.t('persons.personal-data.city')}
          valueId={'city-text-overflow'}
          tooltipId={'city-text-overflow-tooltip'}
        >
          <LargeTextTooltip
            id="city-text-overflow-tooltip"
            idToOverflow="city-text-overflow"
          />
        </Item>

        {person.user_player.info !== null &&
          ((user.account && user.account?.campaign_field) ||
            (user.branch && user.branch?.account?.campaign_field) ||
            user.user_admin) && (
            <>
              <Item
                value={person.user_player.info.date}
                icon="today"
                label={I18n.t('persons.EAE.date')}
                valueId={'date-text-overflow'}
                tooltipId={'date-text-overflow-tooltip'}
                isCustom
              >
                <LargeTextTooltip
                  id="date-text-overflow-tooltip"
                  idToOverflow="date-text-overflow"
                />
              </Item>

              <Item
                value={person.user_player.info?.modality}
                icon="nest_clock_farsight_analog"
                label={I18n.t('persons.EAE.modality')}
                valueId={'modality-text-overflow'}
                tooltipId={'modality-text-overflow-tooltip'}
                isCustom
              >
                <LargeTextTooltip
                  id="modality-text-overflow-tooltip"
                  idToOverflow="modality-text-overflow"
                />
              </Item>

              <Item
                value={person.user_player.info.program}
                icon="local_library"
                label={I18n.t('persons.EAE.program')}
                valueId={'program-text-overflow'}
                tooltipId={'program-text-overflow-tooltip'}
                isCustom
              >
                <LargeTextTooltip
                  id="program-text-overflow-tooltip"
                  idToOverflow="program-text-overflow"
                />
              </Item>
              <Item
                value={
                  person.user_player.info.language?.toLowerCase() === 'español'
                    ? 'ES'
                    : person.user_player.info.language?.toLowerCase() ===
                      'ingles'
                    ? 'EN'
                    : null
                }
                icon="translate"
                label={I18n.t('persons.EAE.language')}
                isCustom
              />
            </>
          )}
      </div>
    </div>
  );
};

export default PersonalData;
