import React from 'react';
import { Component } from 'react';
import './JobDetailScreen.css';
import { I18n } from 'react-redux-i18n';
import Button from '../../../components/common/Button';
import Input from '../../../components/common/Input';
// import Dropdown from '../../../components/common/Dropdown';
import Loader from '../../../components/common/Loader';
import { Tab, Tabs } from 'react-bootstrap';
import FormDropdown from '../../../components/common/Dropdown/FormDropdown';
import PositionCard from '../../../components/common/PositionCard/PositionCard';
import SquareButton from '../../../components/common/SquareButton';
import Tooltip from '../../../components/common/Tooltip';
import NewIndicator from '../../../components/common/NewIndicator/NewIndicator';

class JobDetailScreen extends Component {
  state = {
    values: {
      company_name: { inputValue: '' },
      leader: { inputValue: false },
      name: { inputValue: '' },
      reference_profile: { inputValue: [] },
      job_behaviors: { inputValue: '' },
    },
    isFormValid: true,
  };

  componentWillMount() {
    this.props.cleanJobError();
  }

  loadValues() {
    let newValues = {};
    let name_lang = `name_${this.props.language}`;
    let description_lang = `description_${this.props.language}`;
    Object.keys(this.props.selectedJob).forEach((key) => {
      if (key === 'profile') {
        newValues = {
          ...newValues,
          reference_profile: {
            inputValue: {
              code: this.props.selectedJob[key].id,
              description: this.props.selectedJob[key][name_lang],
              traits: this.props.selectedJob[key]
                ? this.props.selectedJob[key].nwm_profile_x_trait_value
                : [],
            },
          },
        };
      } else {
        newValues = {
          ...newValues,
          [key]: { inputValue: this.props.selectedJob[key] },
        };
      }
      newValues = {
        ...newValues,
        [key]: { inputValue: this.props.selectedJob[key] },
      };
    });
    newValues['job_behaviors'] = {
      inputValue: this.props.selectedJob.profile
        ? this.props.selectedJob.profile[description_lang]
        : '',
    };
    this.setState({
      ...this.state,
      values: { ...this.state.values, ...newValues },
    });
  }

  async componentDidMount() {
    await this.props.getAllProfiles();
    this.props.createStatus.errorCode = {};
    this.props.updateStatus.error = false;
    if (this.props.update) this.loadValues();
    this.props.unselectCampaign();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.createCampaing !== this.props.createCampaing) {
      if (this.props.createCampaing.ok === true) {
        this.props.clearUiValue('createCampaing', 'ok');
      }
    }
    if (prevProps.createStatus.success !== this.props.createStatus.success) {
      if (this.props.createStatus.success) {
        this.props.history.push('/main/jobs');
      }
    }
    if (
      prevProps.retrieveStatus !== this.props.retrieveStatus &&
      this.props.profiles?.length
    ) {
      const urlParams = new URLSearchParams(this.props.history.location.search);
      if (
        urlParams.has('idPosition') &&
        parseInt(urlParams.get('idPosition')) < 27
      ) {
        const profile = this.props.profiles.find(
          (p) => p.id === parseInt(urlParams.get('idPosition')),
        );
        this.setState({
          ...this.state,
          values: {
            ...this.state.values,
            job_behaviors: {
              ...this.state.values.job_behaviors,
              inputValue: profile.description,
            },
            reference_profile: {
              ...this.state.values.reference_profile,
              inputValue: {
                ...{
                  code: profile.id,
                  description: profile.name,
                  desc: profile.description,
                  traits: profile.nwm_profile_x_trait_value,
                  tags: profile.nwm_profile_x_tag,
                },
              },
            },
          },
        });
        /* newValues = {
      ...this.state.values,
      job_behaviors: {
        ...this.state.values.job_behaviors,
        inputValue: value.desc,
      },
      reference_profile: {
        ...this.state.values.reference_profile,
        inputValue: {
          ...{
            code: value.code,
            description: value.description,
            traits: value.traits,
            tags: value.tags,
          },
        },
      },
    }; */
      }
    }
  }

  isFormValid() {
    const { company_name, name, reference_profile, job_behaviors } =
      this.state.values;
    return (
      company_name.inputValue.length !== 0 &&
      name.inputValue.length !== 0 &&
      reference_profile.inputValue.length !== 0 &&
      job_behaviors.inputValue.length !== 0
    );
  }

  handleInputChange = (key, value) => {
    let newValues;

    if (key === 'reference_profile') {
      newValues = {
        ...this.state.values,
        job_behaviors: {
          ...this.state.values.job_behaviors,
          inputValue: value.desc,
        },
        reference_profile: {
          ...this.state.values.reference_profile,
          inputValue: {
            ...{
              code: value.code,
              description: value.description,
              traits: value.traits,
              tags: value.tags,
            },
          },
        },
      };
    } else {
      newValues = {
        ...this.state.values,
        [key]: {
          ...this.state.values[key],
          inputValue: value,
        },
      };
    }
    this.setState({ ...this.state, values: { ...newValues } });
  };

  handleSubmit = (e, type = 'create') => {
    e.preventDefault();
    if (!this.isFormValid())
      this.setState({ ...this.state, isFormValid: false });
    /* else { */
    let httpObject = {};
    if (this.props.selectedJob.id) {
      httpObject['id'] = this.props.selectedJob.id;
    }
    httpObject['name'] = this.state.values.name.inputValue;
    httpObject['company_name'] = this.state.values.company_name.inputValue;
    httpObject['profile_id'] =
      this.state.values.reference_profile.inputValue.code;
    // Object.keys(this.state.values).forEach((key) => {
    //   if (this.state.values[key].inputValue.code) {
    //     httpObject[key] = this.state.values[key].inputValue.code;
    //   } else httpObject[key] = this.state.values[key].inputValue;
    // });

    if (this.props.update) {
      this.props.updateJob(this.props.selectedJob.id, httpObject, type);
    } else {
      this.props.createJob(httpObject, type);
    }
  };

  handleSelect = (e) => {
    if (e === 1) {
      this.props.history.push('/main/tags');
    }
  };

  render() {
    return (
      <div className="jobdetail-container ">
        <Tabs
          defaultActiveKey={2}
          id="uncontrolled-tab-example"
          onSelect={this.handleSelect}
        >
          <Tab eventKey={1} title={I18n.t('profiles.title')}></Tab>
          <Tab eventKey={2} title={I18n.t('jobs.jobs')}>
            <div className="row">
              <div className="col-md-12">
                <div className={'card-header-left'}>
                  <div className="title">
                    <button
                      className="text-primary p-0  arrow-icon"
                      iconButton={true}
                      iconClass={'arrow_back'}
                      onClick={() => this.props.history.goBack()}
                    >
                      <span class="material-symbols-rounded">
                        arrow_back_ios
                      </span>
                    </button>
                    <p>
                      {this.props.history.location.pathname === '/main/job'
                        ? I18n.t('jobs.updatePosition')
                        : I18n.t('jobs.create_new_position')}
                    </p>
                  </div>
                </div>
                {/* <div className="card-header-right">
              {errorCode ? (
                errorCode.company_name ||
                errorCode.name ||
                errorCode.reference_profile ? (
                  <div className="error ml-0">{I18n.t('jobs.error.empty')}</div>
                ) : (
                  false
                )
              ) : (
                false
              )}
              {this.props.update && this.props.updateStatus.error && (
                <div className="error">{I18n.t('jobs.error.update')}</div>
              )}
            </div> */}
              </div>
            </div>
            <div className="">
              <div className="col-md-4 col-lg-3">{this.renderForm()}</div>

              {this.state.values.job_behaviors.inputValue ? (
                <div className="col-md-8 col-lg-9">
                  <PositionCard
                    name={
                      this.state.values.reference_profile.inputValue.description
                    }
                    behave={this.state.values.job_behaviors.inputValue}
                    traits={
                      this.state.values.reference_profile.inputValue.traits
                    }
                    tags={this.state.values.reference_profile.inputValue.tags}
                    id={this.state.values.reference_profile.inputValue.code}
                    language={this.props.language}
                  />
                </div>
              ) : null}
            </div>

            <div className="form-footer">
              {this.props.update ? (
                <div className="buttons-container">
                  <Button
                    className="secondary"
                    onClick={this.handleSubmit}
                    text={
                      <span>
                        <span class="material-symbols-rounded">download</span>
                        {I18n.t('jobs.saveJob')}
                      </span>
                    }
                  />
                  <Button
                    className="primary"
                    onClick={(e) => {
                      this.handleSubmit(e, 'campaign');
                    }}
                    text={
                      <span>
                        <span class="material-symbols-filled">campaign</span>
                        {I18n.t('campaigns.createCampaign')}
                      </span>
                    }
                  />
                </div>
              ) : (
                <div className="buttons-container">
                  <Button
                    className="secondary"
                    onClick={this.handleSubmit}
                    text={
                      <span>
                        <span class="material-symbols-rounded">download</span>
                        {I18n.t('jobs.saveJob')}
                      </span>
                    }
                  />
                  <Button
                    className="primary"
                    onClick={(e) => {
                      this.handleSubmit(e, 'campaign');
                    }}
                    text={
                      <span>
                        <span class="material-symbols-filled">campaign</span>
                        {I18n.t('campaigns.createCampaign')}
                      </span>
                    }
                  />
                </div>
              )}
              <div>{this.renderLoader()}</div>
            </div>
          </Tab>
        </Tabs>
      </div>
    );
  }

  renderForm() {
    /* values:
                company: {inputValue: "Administración x"}
                name: {inputValue: "Puesto"}
                reference_profile: {inputValue: {…}}
                job_behaviors: {inputValue: "Se siente a gusto en posiciones de autoridad y pos…o que escucha y ser percibida como “superficial”."}
                id: {inputValue: 2}
                partner: {inputValue: 1}
                profile: {inputValue: {…}}
                __proto__: Object
                isFormValid: true
         */
    const errorCode = this.props.createStatus.errorCode;
    const { loading, success, error } = this.props.retrieveStatus;
    if (loading) return <Loader />;
    if (error)
      return <div className="text-warning">{I18n.t('jobs.error.list')}</div>;
    if (success)
      return (
        <div className="form-body">
          <div className="form-group flex-dcolumn" style={{ flexGrow: 2 }}>
            <div>
              <Input
                type="text"
                autocomplete="off"
                name="company_name"
                label={I18n.t('jobs.company_or_area')}
                value={this.state.values.company_name}
                change={this.handleInputChange}
                maxLength={50}
                placeholder={I18n.t('jobs.company_or_area_placeholder')}
                bottomText={{
                  appears: errorCode?.company_name ? true : false,
                  message: I18n.t('jobs.error.required'),
                }}
              />
            </div>
            <div className="form-group flex-dcolumn input">
              <Input
                type="text"
                autocomplete="off"
                name="name"
                label={I18n.t('jobs.job_name')}
                value={this.state.values.name}
                change={this.handleInputChange}
                maxLength={100}
                placeholder={I18n.t('jobs.job_name_placeholder')}
                bottomText={{
                  appears: errorCode?.name ? true : false,
                  message: I18n.t('jobs.error.required'),
                }}
              />

              {/* <div className={'input-label'}>{I18n.t('jobs.job_conducts')}</div>
              <p align="justify">
                {this.state.values.job_behaviors.inputValue}
              </p> */}
            </div>
            <hr />
            <div className="dropdown">
              <FormDropdown
                name={'reference_profile'}
                value={this.state.values.reference_profile}
                label={I18n.t('jobs.reference_profile')}
                placeholder={I18n.t('jobs.selectProfile')}
                change={this.handleInputChange}
                disabled={this.state.values.leader.inputValue}
                options={
                  this.props.profiles.length > 0
                    ? this.props.profiles
                        ?.map((profile) => ({
                          code: profile.id,
                          description: profile.name,
                          traits: profile.nwm_profile_x_trait_value,
                          tags: profile.nwm_profile_x_tag,
                          desc: profile.description,
                        }))
                        .filter((p) => p.code !== 27 && p.code !== 28)
                    : []
                }
                bottomText={{
                  appears: errorCode?.reference_profile ? true : false,
                  message: I18n.t('jobs.error.required'),
                }}
              />
            </div>
            {/* <hr />

            <div className="leader-container">
              <SquareButton
                name="leader"
                setValue={!this.state.values.leader.inputValue}
                change={this.handleInputChange}
                label={I18n.t('jobs.leader')}
                value={this.state.values.leader}
              />

              <Tooltip
                message={'jobs.leader_tooltip'}
                materialIcon="contact_support"
              />

              <NewIndicator />
            </div> */}

            {/* <div className="dropdown-leader">
              <FormDropdown
                name={'reference_profile'}
                value={this.state.values.reference_profile}
                placeholder={I18n.t('jobs.leader_placeholder')}
                change={this.handleInputChange}
                disabled={!this.state.values.leader.inputValue}
                options={
                  this.props.profiles.length > 0
                    ? this.props.profiles
                        ?.map((profile) => ({
                          code: profile.id,
                          description: profile.name,
                          traits: profile.nwm_profile_x_trait_value,
                          tags: profile.nwm_profile_x_tag,
                          desc: profile.description,
                        }))
                        .filter((p) => p.code !== 27 && p.code !== 28)
                    : []
                }
                bottomText={{
                  appears: errorCode?.reference_profile ? true : false,
                  message: I18n.t('jobs.error.required'),
                }}
              />
            </div> */}
          </div>
        </div>
      );
  }

  // if submitted for create/update, render a loader until
  // request succeeds
  renderLoader() {
    const { createStatus, updateStatus } = this.props;
    if (updateStatus.loading || createStatus.loading) return <Loader />;
    return null;
  }
}

export default JobDetailScreen;
