import { get, webApi } from '../utils/http';
import queryString from 'query-string';

export const services = {
  //Request URL: http://34.206.199.137/api/credits_update/?ordering=-created_at&search=1&page=1&page_size=10
  getCreditsHistory: (filter) => {
    return get(
      webApi +
        `api/companies/credits?${queryString.stringify(
          filter,
        )}&lang=${localStorage.getItem('language')}`,
    );
  },
};
