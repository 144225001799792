import React, { Component } from 'react';
import './CampaignsMigrationsScreen.css';
import 'react-table/react-table.css';
import ReactTable from 'react-table';
import { parseDate } from '../../../utils/parser';
import { I18n } from 'react-redux-i18n';
import Button from '../../../components/common/Button';
import Input from '../../../components/common/Input';
import FilterModal from '../FilterModal';
import SquareButton from '../../../components/common/SquareButton';
import { notify } from 'react-notify-toast';
import Dropdown from '../../../components/common/Dropdown/Dropdown';
import Loader from '../../../components/common/Loader';
import TablePaginationDrawer from '../../../components/common/TablePaginationDrawer/TablePaginationDrawer';
import { success as successUI, error as errorUI } from '../../../utils/modals';
import {
  SnackBar,
  successMessage,
  errorMessage,
} from '../../../utils/snackbar';
import TableLoader from '../../../components/common/TableLoader/TableLoader';
import ReactFileReader from 'react-file-reader';
import DateRangePickerFilter from '../../../components/common/DateRangePickerFilter/DateRangePickerFilter';
import { times, xor } from 'lodash';

/**
 * Note 1: componentDidUpdate state.fetch logic changed
 * 		because now the input can only be accessed while
 * 		request status is 'success'.
 */

const initialValues = {
  profiles: { inputValue: [] },
  genders: { inputValue: [] },
  partners: { inputValue: [] },
  min_age: { inputValue: '' },
  max_age: { inputValue: '' },
  campaigns: { inputValue: [] },
  code: { inputValue: '' },
  partnerToMigrate: { inputValue: [], required: true, validation: false },
  campaignToMigrate: { inputValue: [], required: true, validation: false },
  creditsConsumed: { inputValue: true, required: true, validation: false },
};

class CampaignsMigrationsScreen extends Component {
  state = {
    showFilter: false,
    showMigration: false,
    values: {
      search: this.props.savedSearchInput || { inputValue: '' },
      //savedCustomFIlter: this.props.customFilter,
      ...initialValues,
    },
    personsToMigrate: { inputValue: [] },
    personsToMigrateSort: { id: 'user__name', desc: false },
    showEmailReportConfirm: false,
    pendingProfile: { inputValue: false },
    inconsistentProfile: { inputValue: false },
    lastConsistentResults: false,
    range: {
      start: this.props.customFilter?.start_range || this.props.start_date,
      end: this.props.customFilter?.end_range || this.props.end_date,
    },
    filterApplied: false,
  };

  componentDidMount() {
    this.props.clearSelection();
    this.props.clearPersonCustomFilters();

    // this.props.clearFilter();
    //this.props.filterPersons(); // this.props.getPersons();
    this.handleSubmit(false, false);

    this.props.getAllProfiles();
    // this.props.getAllPartners();

    if (!this.props.user.branch?.id)
      this.props.getAllClients(true, true, false);

    this.filterByDates({
      start_range: this.state.start_range || this.props.start_date,
      end_range: this.state.end_range || this.props.end_date,
    });

    //this.props.getCities(); //-> {"country_id":["Select a valid choice. That choice is not one of the available choices."]}
    // this.props.saveSearchInput({ inputValue: '' });  // clearing prop after using it.
    this.setState({
      ...this.state,
      filterApplied: false,
      range: {
        start:
          this.props.customFilter?.start_range ||
          this.state.start_date ||
          this.props.start_date,
        end:
          this.props.customFilter?.end_range ||
          this.state.end_date ||
          this.props.end_date,
      },
    });
    this.props.getAllCampaigns();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.createStatus.success !== this.props.createStatus.success)
      if (this.props.createStatus.success) {
        this.resetSelection();
      }
  }

  addOption = (type, name, option) => {
    let newArray = [...this.state.values[name].inputValue];
    let pendValue = this.state.pendingProfile.inputValue;
    let incValue = this.state.inconsistentProfile.inputValue;
    if (type === 'add' || type === true) {
      newArray = newArray.concat([option]);
    } else {
      newArray = newArray.filter((opt) => opt.code !== option.code);
    }
    if (option.description === 'Pendiente') {
      pendValue = type;
    }
    if (option.description === 'Inconsistente') {
      incValue = type;
    }
    if (name === 'partners') {
      this.props.getAllCampaigns(newArray.map(({ code }) => code));
    }
    newArray.sort((profile1, profile2) => {
      return profile2.code - profile1.code;
    });
    this.setState({
      ...this.state,
      pendingProfile: { inputValue: pendValue },
      inconsistentProfile: { inputValue: incValue },
      values: {
        ...this.state.values,
        [name]: { ...this.state.values[name], inputValue: [...newArray] },
      },
    });
  };

  cleanDropdown = (name) => {
    this.setState(
      {
        ...this.state,
        values: {
          ...this.state.values,
          [name]: { inputValue: [] },
        },
      },
      () => {
        this.addOption(null, 'profiles', '');
      },
    );
  };

  onHide = () => {
    this.setState({
      ...this.state,
      showFilter: false,
      showMigration: false,
      showEmailReportConfirm: false,
    });

    if (!this.state.filterApplied) {
      this.setState({
        ...this.state,
        showFilter: false,
        showMigration: false,
        showEmailReportConfirm: false,
        values: { ...this.state.values, ...initialValues },
      });
    }
  };
  handleMigrate = async () => {
    const selectionIds = this.state.personsToMigrate.inputValue.map(
      (user) => user.id,
    );
    const migrationBody = {};
    migrationBody.invitations_ids = selectionIds;
    migrationBody.id_campaign =
      this.state.values.campaignToMigrate.inputValue.code;
    migrationBody.consume_credits = true; //this.state.values.creditsConsumed.inputValue;
    await this.props.migrateResults(migrationBody);
  };

  _validateForm() {
    let option =
      this.state.values.campaignToMigrate.inputValue.length !== 0 &&
      this.state.values.campaignToMigrate.inputValue?.code !== -1 &&
      this.state.personsToMigrate.inputValue?.length !== 0;

    return option;
  }

  resetSelection = () => {
    this.setState({
      ...this.state,
      values: { ...this.state.values, ...initialValues },
      personsToMigrate: { inputValue: [] },
    });
    this.props.clearSelection();
  };

  // downloadReport = (name, option) => {
  //   const { allSelected, selection, allResultsSelected } = this.props;
  //   this.props.downloadProfileReport(
  //     allSelected ? allResultsSelected : selection,
  //     option.code,
  //   );
  // };

  handleInputChange = (key, value) => {
    if (key === 'partnerToMigrate') {
      this.props.getAllCampaigns([value.code]);
      this.setState({
        values: {
          ...this.state.values,
          [key]: { ...this.state.values[key], inputValue: value },
          campaignToMigrate: { inputValue: [] },
        },
      });
    }
    if (key === 'campaignToMigrate') {
      this.setState({
        values: {
          ...this.state.values,
          [key]: { ...this.state.values[key], inputValue: value },
        },
      });
    }
  };

  handleFilterRemove() {
    this.props.clearFilter();
    this.props.filterPersons(
      {
        start_range: this.state.range.start,
        end_range: this.state.range.end,
        ordering: this.props.customFilter.ordering,
        search: this.props.customFilter.search,
      },
      true,
    );
    this.setState({
      ...this.state,
      pendingProfile: { inputValue: false },
      inconsistentProfile: { inputValue: false },
      filterApplied: false,
      values: { ...this.state.values, ...initialValues },
    });
  }
  // handleMigrate = () => {
  //   let;
  // };
  handleSubmit = (
    lastConsistentResults = this.state.lastConsistentResults,
    resetPage = true,
  ) => {
    let submitForm = {
      ordering: this.props.customFilter.ordering,
      search: this.props.customFilter.search,
    };
    Object.keys(this.state.values).forEach((value) => {
      if (
        this.state.values[value].inputValue !== '' &&
        this.state.values[value].inputValue.length !== 0
      ) {
        submitForm = {
          ...submitForm,
          [value]: Array.isArray(this.state.values[value].inputValue)
            ? this.state.values[value].inputValue.map((res) => res.code)
            : this.state.values[value].inputValue.code
            ? this.state.values[value].inputValue.code
            : this.state.values[value].inputValue,
        };
      }
    });
    this.setState({
      ...this.state,
      filterApplied: Object.keys(submitForm).length > 2,
      showFilter: false,
      lastConsistentResults,
    });
    submitForm.branchs = submitForm.partners;
    this.props.filterPersons(
      {
        ...submitForm,
        start_range: this.state.range.start,
        end_range: this.state.range.end,
        lastConsistentResults,
      },
      resetPage,
    );
  };

  clearPersonsToMigrate = (id_invitation) => {
    if (!id_invitation) {
      this.setState({
        ...this.state,
        personsToMigrate: { inputValue: [] },
      });
    } else {
      this.setState({
        ...this.state,
        personsToMigrate: {
          inputValue: this.state.personsToMigrate.inputValue.filter(
            ({ id }) => id !== id_invitation,
          ),
        },
      });
    }
  };

  handleSearch = (key, value) => {
    let current = this.state.values.search.inputValue;
    this.setState(
      {
        ...this.state,
        values: {
          ...this.state.values,
          [key]: { ...this.state.values[key], inputValue: value },
        },
      },
      () => {
        if (
          this.state.values.search.inputValue.length >= 3 ||
          current.length > this.state.values.search.inputValue.length
        ) {
          if (this.searchTimeout) clearTimeout(this.searchTimeout);

          this.searchTimeout = setTimeout(
            function () {
              this.props.updateFilterValues({
                ...this.state,
                search: this.state.values.search.inputValue,
              });
            }.bind(this),
            300,
          );
        }
      },
    );
  };

  // handleFiles = (files) => {
  //   this.props.migrateCampaign(files[0]);
  // };

  // selectAll = (flag) => {
  //   this.props.selectAll(flag);
  //   const allselectedInPage =
  //     this.props.filters.filter(
  //       (person) =>
  //         (person.result &&
  //           this.props.selection.find(
  //             (sel) => sel.id_result === person.result.id,
  //           )) ||
  //         !person.result,
  //     ).length === this.props.filters.length;
  //   if (flag && !allselectedInPage) {
  //     this.props.toggleSelectionAllMigrations();
  //   }
  // };

  filterByDates = (filter) => {
    let submitForm = {
      ordering: this.props.customFilter.ordering,
      search: this.props.customFilter.search,
    };

    Object.keys(this.state.values).forEach((value) => {
      if (
        this.state.values[value].inputValue !== '' &&
        this.state.values[value].inputValue.length !== 0
      ) {
        submitForm = {
          ...submitForm,
          [value]: Array.isArray(this.state.values[value].inputValue)
            ? this.state.values[value].inputValue.map((res) => res.code)
            : this.state.values[value].inputValue.code
            ? this.state.values[value].inputValue.code
            : this.state.values[value].inputValue,
        };
      }
    });
    this.onHide();
    this.setState({
      ...this.state,
      range: { start: filter.start_range, end: filter.end_range },
    });
    this.props.filterPersons(
      {
        ...submitForm,
        ...filter,
        lastConsistentResults: this.state.lastConsistentResults,
      },
      true,
    );
  };

  // handleActions = (name, option) => {
  //   if (option.code === 'tends') {
  //     this.props.history.push('/main/persons/group-trends');
  //   }

  //   if (option.code === 'individual' || option.code === 'empresa') {
  //     this.downloadReport(name, option);
  //   }
  // };

  toggleLastResultStatus = () => {
    let value = this.state.lastConsistentResults;
    this.handleSubmit(!value);
  };
  allSelectioninPage = () => {
    let flag = true;
    this.props.filters.map((person) => {
      const result = this.props.selection.find(
        (s) => s.id_result === person.result.id,
      );
      if (!result) {
        flag = false;
      }
    });
    return flag;
  };
  render() {
    this.checkNotificationRenders();
    const { loading, success, error } = this.props.retrieveStatus;
    const { loading: loadingReports } = this.props.reportsStatus;
    const { allSelected, allLoaded } = this.props;
    const { loading: loadingDates } = this.props.dateStatus;
    let allprofiles = [
      {
        id: -1,
        name: I18n.t('dashboard.profileFilterPlaceholder'),
        description: '',
        traits: Array(0),
      },
    ].concat(this.props.profiles);
    return loadingReports ? (
      <Loader />
    ) : (
      <div className="row persons-custom-container">
        <FilterModal
          show={this.state.showFilter}
          change={this.handleInputChange}
          addOption={this.addOption}
          onHide={this.onHide}
          values={this.state.values}
          extendedProfiles={allprofiles}
          pendingProfile={this.state.pendingProfile}
          inconsistentProfile={this.state.inconsistentProfile}
          partners={this.props.partners}
          countries={this.props.countries}
          campaigns={this.props.allCampaigns}
          action={this.handleSubmit}
          loading={loading || (allSelected && !allLoaded)}
          error={error}
          success={success}
          isAdmin={this.props.user.user_admin}
          isSuperPartner={
            this.props.user.partner &&
            this.props.user.partner.is_super_dashboard
          }
          cleanDropdown={this.cleanDropdown}
          {...this.props}
        />
        <div className=" col-md-12 list-person">
          <div className="card">
            <div className="card-header">
              <div className="card-header-custom">
                <p>{I18n.t('campaigns.migration.identifier')}</p>
                <div className="header-container">
                  <div className="header-left">
                    <div
                      className={`search-bar ${
                        this.state.values.search.inputValue.length > 0 &&
                        'active'
                      }`}
                    >
                      <i className={'fa fa-search'} />
                      <Input
                        name="search"
                        value={this.state.values.search}
                        placeholder={I18n.t('persons.search')}
                        change={this.handleSearch}
                        type="filter"
                      />
                    </div>
                    {this.props.filterApplied ? (
                      <div className="filter-container">
                        <button
                          type="button"
                          className="material-symbols-rounded material-symbols-filled filter-button"
                          onClick={() =>
                            this.setState({ ...this.state, showFilter: true })
                          }
                        >
                          filter_alt
                        </button>

                        <Button
                          className="primary"
                          text={I18n.t('persons.filterClose')}
                          onClick={() => this.handleFilterRemove()}
                        />
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="material-symbols-rounded material-symbols-filled filter-button"
                        onClick={() =>
                          this.setState({ ...this.state, showFilter: true })
                        }
                      >
                        filter_alt
                      </button>
                    )}
                    {this.renderLastConsistentFilter()}
                  </div>
                  <div className="header-right">
                    {!!this.props.start_date && !!this.props.end_date && (
                      <div className="date-picker">
                        <DateRangePickerFilter
                          popupUp={true}
                          rangeDefault={{
                            start:
                              this.props.customFilter.start_range ||
                              this.props.start_date,
                            end:
                              this.props.customFilter.end_range ||
                              this.props.end_date,
                          }}
                          previewRange={{
                            // this.state.range.start
                            //   ? this.state.range
                            //   : {
                            start: this.state.range.start,
                            end: this.state.range.end,
                          }}
                          start_date={this.state.range.start}
                          end_date={this.state.range.end}
                          classNameButton="date-person"
                          {...this.props}
                          filter={this.filterByDates}
                        />
                      </div>
                    )}
                    <div className="other-actions">
                      {this.props.user.groups.indexOf(1) > -1 && (
                        <ReactFileReader
                          handleFiles={this.handleFiles}
                          fileTypes={'.csv'}
                        >
                          <Button
                            className="primary"
                            text={I18n.t('persons.migrateCampaign')}
                            disabled={loading || (allSelected && !allLoaded)}
                          />
                        </ReactFileReader>
                      )}
                      {/* {this.props.selection.length > 0 && (
                        <Button
                          className={'p-0 bg-warning text-white no-border'}
                          iconButton={true}
                          iconClass={'fa fa-times'}
                          onClick={() => {
                            this.props.clearSelection();
                            this.setState({
                              ...this.state,
                              personsToMigrate: { inputValue: [] },
                            });
                          }}
                        />
                      )} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {allSelected && !allLoaded ? <Loader /> : this.renderTable()}
          </div>
        </div>
        <div className="col-md-12 list-selected-person">
          <div className="card">
            <div className="card-header">
              <div className="card-header-custom">
                <p>{I18n.t('campaigns.migration.selected')}</p>
              </div>
            </div>
            {allSelected && !allLoaded ? (
              <Loader />
            ) : (
              this.renderSelectedTable()
            )}
          </div>
          <div className="card">
            <div className="card-header">
              <div className="card-header-custom">
                <p>{I18n.t('campaigns.destination')}</p>
              </div>
            </div>
            <div className="card-body">
              <div className="row filter-destination">
                <div className="col-12 col-md-3">
                  <Dropdown
                    label={I18n.t('common.sidebar.filiales')}
                    placeholder={I18n.t('dropdown.default')}
                    name="partnerToMigrate"
                    value={this.state.values.partnerToMigrate}
                    multiSelect={false}
                    change={this.handleInputChange}
                    options={this.props.partners.map((client) => ({
                      code: client.id,
                      description: `${client.name}`,
                      isAccount: client.nwm_accountId ? false : true,
                    }))}
                    loading={this.props.getAllClientsStatus.loading}
                    showEmptyOption
                  />
                </div>
                <div className="col-12 col-md-3">
                  <Dropdown
                    label={I18n.t('campaigns.identifier')}
                    placeholder={I18n.t('dropdown.default')}
                    name="campaignToMigrate"
                    value={this.state.values.campaignToMigrate}
                    multiSelect={false}
                    change={this.handleInputChange}
                    options={this.props.allCampaigns.map((campaign) => ({
                      code: campaign.id,
                      description: `${campaign.name}`,
                      isAccount: campaign.nwm_accountId ? false : true,
                    }))}
                    loading={this.props.allCampaignsStatus.loading}
                    showEmptyOption
                  />
                </div>
                {/* <div className="col-12 col-md-4">
                  <SquareButton
                    label={I18n.t('campaigns.credits.creditsConsumed')}
                    className="consumed-credits-check"
                    name={'creditsConsumed'}
                    change={() =>
                      this.setState({
                        ...this.state,
                        values: {
                          ...this.state.values,
                          creditsConsumed: {
                            ...this.state.values.creditsConsumed,
                            inputValue:
                              !this.state.values.creditsConsumed.inputValue,
                          },
                        },
                      })
                    }
                    value={{
                      inputValue: this.state.values.creditsConsumed.inputValue,
                    }}
                  />
                </div> */}
              </div>
              <div className="row filter-submit">
                <div className="col-12 col-md-3">
                  {/* <Button
                    className="primary"
                    text={I18n.t('campaigns.migrate')}
                    onClick={this.handleMigrate}
                  /> */}
                  <Button
                    className="secondary"
                    text={I18n.t('campaigns.migrate')}
                    disabled={!this._validateForm()}
                    onClick={this.handleMigrate}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderTable() {
    const { loading, success, error } = this.props.retrieveStatus;
    const { loading: loadingCampaigns } = this.props.allCampaignsStatus;
    const { loading: loadingFilter } = this.props.retrieveFilter;
    const { customFilter } = this.props;
    const orderingFilterObj = {
      desc: customFilter.ordering[0] === '-',
      id:
        customFilter.ordering[0] === '-'
          ? customFilter.ordering.slice(1, customFilter.ordering.length)
          : customFilter.ordering,
    };
    if (error)
      return <div className="text-warning">{I18n.t('persons.error.list')}</div>;
    if (success || loading)
      return (
        // <div className="table">
        <ReactTable
          className="migration"
          columns={[
            {
              id: 'result',
              accessor: (d) => ({
                id_result: d.result.id,
                id_invitation: d.id,
              }),
              sortable: false,
              Cell: (props) => (
                <div className="centered-cell">
                  <SquareButton
                    value={{
                      inputValue: this.props.selection.find(
                        (sel) =>
                          props.value.id_invitation &&
                          sel.id_invitation === props.value.id_invitation,
                      ),
                    }}
                    disabled={!props.value}
                    change={() => {
                      if (
                        !this.state.personsToMigrate.inputValue.some(
                          (item) => item.id === props.value.id_invitation,
                        )
                      )
                        this.setState({
                          ...this.state,
                          personsToMigrate: {
                            inputValue: [
                              ...this.state.personsToMigrate.inputValue,
                              {
                                ...this.props.filters.find(
                                  (item) =>
                                    item.id === props.value.id_invitation,
                                ),
                              },
                            ],
                          },
                        });
                      else
                        this.setState({
                          ...this.state,
                          personsToMigrate: {
                            inputValue: [
                              ...this.state.personsToMigrate.inputValue.filter(
                                (item) => item.id !== props.value.id_invitation,
                              ),
                            ],
                          },
                        });
                      this.props.addRemovePersonToSelection(props.value);
                    }}
                  />
                </div>
              ),
              getProps: () => ({
                onClick: (e) => e.stopPropagation(),
                style: { cursor: 'default' },
              }),
            },
            {
              Header: () => (
                <span className="table-head">
                  {I18n.t('persons.first_name')}
                </span>
              ),
              headerClassName: 'wordwrap',
              id: 'user__first_name',
              accessor: 'user_player.user',
              Cell: (props) => (
                <span className="number">{props?.value?.first_name}</span>
              ),
            },
            {
              Header: (
                <span className="table-head">
                  {I18n.t('persons.last_name')}
                </span>
              ),
              headerClassName: 'wordwrap',
              id: 'user__last_name',
              accessor: 'user_player.user',
              Cell: (props) => (
                <span className="number">{props?.value?.last_name}</span>
              ),
            },
            {
              Header: (
                <span className="table-head">{I18n.t('persons.profile')}</span>
              ),
              headerClassName: 'wordwrap',
              id: 'profile_name',
              accessor: 'result.nwm_result_distance_match.profile',
              Cell: (props) => (
                <span className="number">
                  {props.value
                    ? props.value[`name_${this.props.language}`]
                    : '-'}
                </span>
              ),
            },
            {
              Header: (
                <span className="table-head">{I18n.t('persons.email')}</span>
              ),
              headerClassName: 'wordwrap',
              id: 'user__email',
              accessor: (d) =>
                d?.user_player?.email || d?.user_player?.user.email || '',
              Cell: (props) => <span className="number">{props.value}</span>,
            },
            {
              Header: (
                <span className="table-head">
                  {I18n.t('campaigns.identifier')}
                </span>
              ),
              headerClassName: 'wordwrap',
              id: 'campaign_name',
              accessor: 'campaign.name',
              Cell: (props) => (
                <span className="number">
                  {props.value ? props.value : '-'}
                </span>
              ),
            },
            {
              Header: (
                <span className="table-head">
                  {I18n.t('campaigns.campaignCodes')}
                </span>
              ),
              headerClassName: 'wordwrap',
              id: 'campaign_code',
              accessor: 'campaign.code',
              Cell: (props) => (
                <span className="number">
                  {props.value ? props.value : '-'}
                </span>
              ),
            },
            {
              Header: (
                <span className="table-head">
                  {I18n.t('persons.invitationCreated')}
                </span>
              ),
              headerClassName: 'wordwrap',
              id: 'created',
              accessor: 'created',
              Cell: (props) => (
                <span className="number">
                  {props.value ? parseDate(new Date(props.value)) : ''}
                </span>
              ),
            },
            {
              Header: (
                <span className="table-head">
                  {I18n.t('persons.game_date')}
                </span>
              ),
              headerClassName: 'wordwrap',
              id: 'game_date',
              accessor: 'conversion_date',
              Cell: (props) => (
                <span className="number game-date">
                  {props.value ? parseDate(new Date(props.value)) : ''}
                </span>
              ),
            },
          ]}
          data={this.props.filters}
          minRows={0}
          minWith={5}
          resizable={false}
          noDataText={I18n.t('persons.table.noRows')}
          // TODO: ReactTable sorting
          defaultSorted={[
            {
              id: 'game_date',
              desc: false,
            },
          ]}
          sorted={[
            {
              id: orderingFilterObj.id,
              desc: orderingFilterObj.desc,
            },
          ]}
          onSortedChange={(newSorted, column, shiftKey) => {
            let columnId = column.id;
            this.props.updateFilterValues({
              ordering: (newSorted[0].desc ? '-' : '') + columnId,
              start_range: this.state.range.start,
              end_range: this.state.range.end,
            });
          }}
          getTrProps={(state, rowInfo, column, instance) => {
            return {
              onClick: (e, handleOriginal) => {
                if (handleOriginal) {
                  handleOriginal();
                }
                this.props.selectPerson(rowInfo.original.result);
              },
              style: {
                cursor: 'pointer',
              },
            };
          }}
          manual // prop that tells ReactTable to dont client-side sort or paginate
          pageSize={this.props.pageSize}
          PaginationComponent={() => {
            const {
              currentPage,
              updatePage,
              totalPages,
              pageSize,
              totalCount,
              updatePageSize,
            } = this.props;
            const isOnFirstPage = currentPage === 1;
            const isOnLastPage = currentPage === totalPages;
            return (
              <TablePaginationDrawer
                data={{
                  totalCount,
                  currentPage,
                  pageSize,
                  isOnFirstPage,
                  isOnLastPage,
                  updatePage,
                  updatePageSize,
                  totalPages,
                }}
                table={'persons'}
              />
            );
          }}
          loading={loadingFilter || loadingCampaigns}
          LoadingComponent={TableLoader}
        />
        // </div>
      );
    return <Loader />;
  }

  renderSelectedTable() {
    const { loading, success, error } = this.props.retrieveStatus;
    const { loading: loadingCampaigns } = this.props.allCampaignsStatus;
    const { loading: loadingFilter } = this.props.retrieveFilter;
    const { customFilter } = this.props;

    if (error)
      return <div className="text-warning">{I18n.t('persons.error.list')}</div>;
    if (success || loading)
      return (
        <div className="table">
          <ReactTable
            columns={[
              {
                Header: (
                  <span className="header-title">
                    {I18n.t('persons.first_name')}
                  </span>
                ),
                headerClassName: 'wordwrap',
                id: 'user__first_name',
                accessor: 'user_player.user',
                Cell: (props) => (
                  <span className="number cell-content selection-name">
                    {props.value.first_name}
                  </span>
                ),
              },
              {
                Header: (
                  <span className="header-title">
                    {I18n.t('persons.last_name')}
                  </span>
                ),
                headerClassName: 'wordwrap',
                id: 'user__last_name',
                accessor: 'user_player.user',
                Cell: (props) => (
                  <span className="number cell-content">
                    {props.value.last_name}
                  </span>
                ),
              },
              {
                Header: (
                  <span className="header-title">
                    {I18n.t('persons.profile')}
                  </span>
                ),
                headerClassName: 'wordwrap',
                id: 'profile_name',
                accessor: 'result.nwm_result_distance_match.profile',
                Cell: (props) => (
                  <span className="number cell-content">
                    {props.value
                      ? props.value[`name_${this.props.language}`]
                      : '-'}
                  </span>
                ),
              },
              {
                Header: (
                  <span className="header-title">
                    {I18n.t('persons.email')}
                  </span>
                ),
                headerClassName: 'wordwrap',
                id: 'user__email',
                accessor: (d) =>
                  d?.user_player?.email || d?.user_player?.user.email || '',
                Cell: (props) => (
                  <span className="email-address">
                    <div>{props.value}</div>
                  </span>
                ),
              },
              {
                Header: (
                  <span className="header-title">
                    {I18n.t('campaigns.identifier')}
                  </span>
                ),
                headerClassName: 'wordwrap',
                id: 'campaign_name',
                accessor: 'campaign.name',
                Cell: (props) => (
                  <span className="number cell-campaign">
                    {props.value ? props.value : '-'}
                  </span>
                ),
              },
              {
                Header: '',
                accessor: (d) => ({
                  id_invitation: d.id,
                  id_result: d.result.id,
                }),
                sortable: false,
                id: 'delete',
                Cell: (props) => {
                  return (
                    <Button
                      className={
                        'p-0 bg-warning text-white no-border remove-user'
                      }
                      iconButton={true}
                      iconClass={'delete'}
                      onClick={(e) => {
                        this.clearPersonsToMigrate(props.value.id_invitation);
                        this.props.addRemovePersonToSelection(props.value);
                      }}
                    />
                  );
                },
              },
            ]}
            data={this.state.personsToMigrate.inputValue}
            minRows={0}
            minWith={5}
            resizable={false}
            noDataText={I18n.t('persons.table.noRows')}
            // TODO: ReactTable sorting
            defaultSorted={[
              {
                id: 'game_date',
                desc: false,
              },
            ]}
            sorted={[
              {
                id: this.state.personsToMigrateSort.id,
                desc: this.state.personsToMigrateSort.desc,
              },
            ]}
            onSortedChange={(newSorted, column, shiftKey) => {
              let personsToMigrate = this.state.personsToMigrate.inputValue;
              const sortBy = newSorted[0]?.id?.replace('user__', '');
              let d = this.state.personsToMigrateSort.desc;
              if (['first_name', 'last_name', 'email'].includes(sortBy)) {
                personsToMigrate.sort((a, b) => {
                  let x = d ? a : b;
                  let y = d ? b : a;
                  return x.user_player.user[sortBy]
                    .toLowerCase()
                    .localeCompare(y.user_player.user[sortBy].toLowerCase());
                });
              }
              if (sortBy === 'profile_name') {
                personsToMigrate.sort((a, b) => {
                  let x = d ? a : b;
                  let y = d ? b : a;
                  return x.result.nwm_result_distance_match.profile[
                    `name_${this.props.language}`
                  ].localeCompare(
                    y.result.nwm_result_distance_match.profile[
                      `name_${this.props.language}`
                    ],
                  );
                });
              }
              if (sortBy === 'campaign_name') {
                personsToMigrate.sort((a, b) => {
                  let x = d ? a : b;
                  let y = d ? b : a;
                  return x.campaign.name
                    .toLowerCase()
                    .localeCompare(y.campaign.name.toLowerCase());
                });
              }
              this.setState({
                ...this.state,
                personsToMigrate: {
                  ...this.state.personsToMigrate,
                  inputValue: personsToMigrate,
                },
                personsToMigrateSort: {
                  id: newSorted[0]?.id,
                  desc:
                    this.state.personsToMigrateSort.id === newSorted[0]?.id
                      ? !this.state.personsToMigrateSort.desc
                      : newSorted[0]?.desc,
                },
              });
            }}
            getTrProps={(state, rowInfo, column, instance) => {
              return {
                onClick: (e, handleOriginal) => {
                  if (handleOriginal) {
                    handleOriginal();
                  }
                  this.props.selectPerson(rowInfo.original.result);
                  this.props.history.push('/main/campaignsMigrations');
                },
                style: {
                  cursor: 'pointer',
                },
              };
            }}
            manual // prop that tells ReactTable to dont client-side sort or paginate
            showPagination={false}
            loading={loadingFilter || loadingCampaigns}
            LoadingComponent={TableLoader}
          />
        </div>
      );
    return <Loader />;
  }

  handleShowHide = (show, id) => {
    //this.setState({ ...this.state, showModal: show, clientId: id });
  };

  renderLastConsistentFilter() {
    return (
      <div className="filter-container">
        <Button
          className={
            this.state.lastConsistentResults ? 'primary applied' : 'primary'
          }
          text={I18n.t('persons.profileFilterButton')}
          onClick={this.toggleLastResultStatus}
        />
      </div>
    );
  }

  checkNotificationRenders() {
    if (
      this.props.updateStatus.success ||
      this.props.updateUserStatus.success ||
      this.props.createStatus.success
    ) {
      notify.show(
        <SnackBar
          icon={'priority'}
          title={I18n.t('common.excellent')}
          body={I18n.t('persons.success.update')}
          type="success"
          iconFilled
        />,
        'custom',
        5000,
        successMessage,
      );
      this.props.clearUiValue('update', 'success');
      this.props.clearUiValue('userUpdate', 'success');
      this.props.clearUiValue('create', 'success');
    }
    if (this.props.updateStatus.error) {
      notify.show(
        <SnackBar
          icon={'report'}
          title={I18n.t('common.attention')}
          body={I18n.t('persons.error.update')}
          type="error"
        />,
        'custom',
        5000,
        errorMessage,
      );
      this.props.clearUiValue('update', 'error');
    }
    if (this.props.createStatus.error) {
      notify.show(
        <SnackBar
          icon={'report'}
          title={I18n.t('common.attention')}
          body={I18n.t('persons.error.create')}
          type="error"
        />,
        'custom',
        5000,
        errorMessage,
      );
      this.props.clearUiValue('create', 'error');
    }
    if (this.props.deleteStatus.success) {
      notify.show(
        <SnackBar
          icon={'priority'}
          title={I18n.t('common.excellent')}
          body={I18n.t('persons.success.delete')}
          type="success"
          iconFilled
        />,
        'custom',
        5000,
        successMessage,
      );
      this.props.clearUiValue('delete', 'success');
    }
    if (this.props.deleteStatus.error) {
      notify.show(
        <SnackBar
          icon={'report'}
          title={I18n.t('common.error')}
          body={I18n.t('persons.error.delete')}
          type="error"
        />,
        'custom',
        5000,
        errorMessage,
      );
      this.props.clearUiValue('delete', 'error');
    }
  }

  componentWillUnmount() {
    const nextLocation = this.props.history.location.pathname;
    if (nextLocation !== '/main/campaignsMigrations' && nextLocation !== '/') {
      this.props.clearSelection();
    }
    if (nextLocation === '/main/campaignsMigrations') {
      this.props.saveSearchInput(this.state.values.search);
    }
  }
}

export default CampaignsMigrationsScreen;
