export const GET_INFO_REPORTS = 'GET INFO REPORTS';
export const GET_INFO_REPORTS_RESPONSE = 'GET INFO REPORTS RESPONSE';
export const GET_INFO_REPORTS_LOADING = 'GET INFO REPORTS LOADING';
export const GET_INFO_REPORTS_ERROR = 'GET INFO REPORTS ERROR';
export const RESTORE_REPORT = 'RESTORE REPORT';
export const RESTORE_REPORT_RESPONSE = 'RESTORE REPORT RESPONSE';
export const RESTORE_REPORT_ERROR = 'RESTORE REPORT ERROR';
export const UPDATE_REPORTS = 'UPDATE REPORTS';
export const UPDATE_REPORTS_RESPONSE = 'UPDATE REPORTS RESPONSE';
export const UPDATE_REPORTS_ERROR = 'UPDATE REPORTS ERROR';
export const DOWNLOAD_REPORT = 'DOWNLOAD REPORT';
export const DOWNLOAD_REPORT_RESPONSE = 'DOWNLOAD REPORT RESPONSE';
export const DOWNLOAD_REPORT_ERROR = 'DOWNLOAD REPORT ERROR';
export const reports = {
  getInfoReports: ({ account, branch }) => ({
    type: GET_INFO_REPORTS,
    account,
    branch,
  }),
  getInfoReportsResponse: (response) => ({
    type: GET_INFO_REPORTS_RESPONSE,
    response,
  }),
  getInfoReportsError: (response) => ({
    type: GET_INFO_REPORTS_ERROR,
    response,
  }),
  restoreReports: ({ report }) => ({
    type: RESTORE_REPORT,
    report,
  }),
  restoreReportsResponse: (response) => ({
    type: RESTORE_REPORT_RESPONSE,
    response,
  }),
  restoreReportsError: (response) => ({
    type: RESTORE_REPORT_ERROR,
    response,
  }),
  updateReports: ({ updateReport, reportType }) => ({
    type: UPDATE_REPORTS,
    updateReport,
    reportType,
  }),
  updateReportsResponse: (response) => ({
    type: UPDATE_REPORTS_RESPONSE,
    response,
  }),
  updateReportsError: (response) => ({
    type: UPDATE_REPORTS_ERROR,
    response,
  }),
  downloadTestReport: ({ reportType, test, folder }) => ({
    type: DOWNLOAD_REPORT,
    reportType,
    test,
    folder,
  }),
  downloadTestReportResponse: (response) => ({
    type: DOWNLOAD_REPORT_RESPONSE,
    response,
  }),
  downloadTestReportError: (response) => ({
    type: DOWNLOAD_REPORT_ERROR,
    response,
  }),
};
