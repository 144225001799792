import React, { Component, cloneElement } from 'react';
import './MainModal.sass';
import { Modal } from 'react-bootstrap';
import Button from '../Button';
import { I18n } from 'react-redux-i18n';
class MainModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      handleModal: this.props.onBoardingState,
      index: 0,
      direction: null,
      carouselItemCount: parseInt(this.props.slides),
    };
  }

  toggleCarousel = (direction) => {
    let index = this.state.index;
    const [min, max] = [0, this.state.carouselItemCount - 1];

    if (direction === 'next') {
      index++;
    } else if (direction === 'prev') {
      index--;
    }

    if (index > max) {
      index = 0;
    }

    if (index < min) {
      index = max;
    }

    this.setState({
      direction,
      index,
    });
  };

  render() {
    return (
      <>
        {this.props.onBoarding === false && (
          <Button
            className="primary"
            iconButton={false}
            text={'modal'}
            onClick={() => this.switchModal()}
          />
        )}

        <Modal
          className="MainModal"
          show={this.state.handleModal}
          onHide={() => this.switchModal()}
          backdrop="static"
          keyboard={false}
          animation={false}
        >
          {cloneElement(this.props.children, {
            index: this.state.index,
            direction: this.state.direction,
            carouselItemCount: this.state.carouselItemCount,
          })}
          <Modal.Footer>
            <div
              className={`footer-display ${
                process.env.REACT_APP_FAQ_URL && 'onboarding-footer'
              }`}
            >
              {this.state.index === this.state.carouselItemCount - 1 &&
                process.env.REACT_APP_FAQ_URL && (
                  <a
                    href={process.env.REACT_APP_FAQ_URL}
                    className="redirect-link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="material-symbols-rounded material-symbols-filled icon-left">
                      help_outline
                    </span>
                    {I18n.t('dropdown.help.button').toUpperCase()}
                  </a>
                )}

              <div className="footer-display-bottom-right">
                {this.state.index > 0 && (
                  <Button
                    className="text-link-button"
                    onClick={() => this.toggleCarousel('prev')}
                    text={'common.back'}
                    translate={true}
                    iconButton={true}
                    iconClass={'chevron_left'}
                  />
                )}
                {this.state.index < this.state.carouselItemCount - 1 && (
                  <Button
                    className=" primary"
                    onClick={() => this.toggleCarousel('next')}
                    text={'common.next'}
                    translate={true}
                    iconButton={true}
                    iconClass={'chevron_right'}
                    iconRight={true}
                  />
                )}
                {this.state.index === this.state.carouselItemCount - 1 && (
                  <Button
                    text={'common.start'}
                    className={'primary done'}
                    iconButton={true}
                    translate={true}
                    iconClass="done"
                    onClick={() => this.props.showOnBoarding(false)}
                    iconRight={true}
                  />
                )}
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default MainModal;
