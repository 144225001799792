import React, { Component } from 'react';
import './CreditsWidgets.css';

export class CreditsWidgets extends Component {
  render() {
    const {
      material_symbol,
      label,
      title,
      value,
      description,
      type,
      renderStyle,
    } = this.props;
    return (
      <div className={`credit-widget-container ${type}`}>
        <div className={`title-wrapper ${type} ${renderStyle}`}>
          <div className={`title-container ${type}`}>
            <span
              className={`material-symbols-rounded material-symbols-filled ${type}`}
            >
              {material_symbol}
            </span>
            <h3 className={`title ${type}`}>{title}</h3>
          </div>
        </div>
        <div className={`credit-widget-body-container ${type}`}>
          <span className={`label-container ${type}`}>{label}</span>
          <span className={`value-container ${type} ${renderStyle}`}>
            {value ? value : 0}
          </span>
          <span className={`description-container ${type}`}>{description}</span>
        </div>
      </div>
    );
  }
}

export default CreditsWidgets;
