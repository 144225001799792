import { Component } from 'react';
import { Carousel } from 'react-bootstrap';
import { I18n } from 'react-redux-i18n';
import './Onboarding.sass';
class Onboarding extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let index = this.props.index;
    let direction = this.props.direction;
    return (
      <Carousel
        indicators={false}
        controls={false}
        activeIndex={index}
        direction={direction}
        className="onBoarding"
      >
        {[...Array(this.props.carouselItemCount)].map((x, i) => (
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={`/OnBoarding${i + 1}_${this.props.language}.png`}
              alt={`${i + 1} Slide`}
            />
            <div className="shadow"></div>
            <Carousel.Caption>
              <h3> {I18n.t(`onBoarding.slide${i + 1}.title`)}</h3>
              <p>{I18n.t(`onBoarding.slide${i + 1}.description`)}</p>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
    );
  }
}

export default Onboarding;
