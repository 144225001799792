import { connect } from 'react-redux';
import actions from '../../actions/index';
import JobsScreen from '../components/JobsScreen/JobsScreen';
import { withRouter } from 'react-router';

const mapDispatchToProps = (dispatch) => ({
  getJob: (id) => dispatch(actions.jobs.getJob(id)),
  getJobs: () => dispatch(actions.jobs.getJobs()),
  selectJob: (id) => dispatch(actions.jobs.selectJob(id)),
  deleteJob: (id) => dispatch(actions.jobs.deleteJob(id)),
  updatePage: (page) => dispatch(actions.jobs.updatePage(page)),
  updatePageSize: (pageSize) => dispatch(actions.jobs.updatePageSize(pageSize)),
  updateFilterValues: (filter) =>
    dispatch(actions.jobs.updateFilterValues(filter)),
  filterJobs: (filter) => dispatch(actions.jobs.getJobs(filter)),
  clearUiValue: (type, condition) =>
    dispatch(actions.jobs.clearUiValue(type, condition)),
  getAllCampaigns: ({ partners, campaign_dropdown, search }) =>
    dispatch(
      actions.campaigns.getAllCampaigns(partners, campaign_dropdown, search),
    ),
  getAllProfiles: ({ search }) =>
    dispatch(actions.persons.getAllProfiles(search)),
  getLastMonth: () => dispatch(actions.dashboard.getLastMonthData()),
  // selectCampaign: (id) => dispatch(actions.campaigns.selectCampaign(id)),
  getCampaigns: (id) => dispatch(actions.campaigns.reloadSelectedCampaign(id)),
  unselectCampaign: () => dispatch(actions.campaigns.unselectCampaign()),
  getAllClients: ({ account_dropdown, view_branch, view_account }) =>
    dispatch(
      actions.clients.getAllClients(
        account_dropdown,
        view_branch,
        view_account,
      ),
    ),
});

const mapStateToProps = (state, ownProps) => ({
  totalCount: state.jobs.count,
  currentPage: state.jobs.current,
  totalPages: state.jobs.total_pages,
  pageSize: state.jobs.page_size,

  jobs: state.jobs.results,
  newJob: state.jobs.newJob,
  desc: state.jobs.desc,

  createStatus: state.jobs.create,
  updateStatus: state.jobs.update,
  deleteStatus: state.jobs.delete,
  jobsStatus: state.jobs.jobsStatus,
  selectedCampaign: state.campaigns.selectedCampaign,
  filterValues: state.jobs.filterValues,
  dateStatus: state.dashboard.dateStatus,
  ordering: state.jobs.filterValues.ordering,
  allCampaigns: state.campaigns.allCampaigns,
  getAllCampaignsStatus: state.clients.getAllClientsStatus,
  getAllProfiles: state.persons.getAllProfiles,
  profiles: state.persons.profiles,
  selectedJob: state.jobs.selectedJob,
  user: state.session.user,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(JobsScreen));
