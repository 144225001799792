import {
  LOGIN,
  LOGIN_RESPONSE,
  LOGIN_ERROR,
  LOGIN_FORM_RESET,
  LOGOUT_RESPONSE,
  SAVE_REDIRECT_ROUTE,
  GET_PARTNER_RESPONSE,
  ACTIVATE_ACCOUNT,
  ACTIVATE_ACCOUNT_RESPONSE,
  ACTIVATE_ACCOUNT_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_RESPONSE,
  RESET_PASSWORD_ERROR,
  VALIDATE_TOKEN_CHANGE_PASSWORD,
  VALIDATE_TOKEN_CHANGE_PASSWORD_RESPONSE,
  VALIDATE_TOKEN_CHANGE_PASSWORD_ERROR,
  RECOVER_PASSWORD,
  RECOVER_PASSWORD_RESPONSE,
  RECOVER_PASSWORD_ERROR,
  RECOVER_PASSWORD_CLEAN_ERROR,
  OPEN_ID_AUTH_RESPONSE,
  UPDATE_LOGGEDIN_USER,
  BAPRO_LOGIN,
  BAPRO_LOGIN_RESPONSE,
  BAPRO_LOGIN_ERROR,
  GOOGLE_LOGIN_RESPONSE,
  GOOGLE_LOGIN_ERROR,
  GOOGLE_LOGIN,
} from './session.actions';
import { REHYDRATE } from 'redux-persist';
import reqStates from '../utils/reqStates';

const initialState = {
  user: undefined,
  response: null,
  isLoggedIn: false,
  redirectRoute: undefined,
  loginStatus: reqStates.NONE,

  // ! FRONT QUICK FIX !
  loginAttempts: 0,
  tooManyLoginAttempts: false,
  loginBlockDate: null,

  activationState: { loading: false, success: false, error: false },
  activationResponse: null,

  resetPasswordStatus: { loading: false, success: false, error: false },
  emailStatus: { loading: false, success: false, error: false },

  userservicelogin: false,
};
// ! FRONT QUICK FIX !
const LOGIN_BLOCK_TIME_LIMIT = 900000; // 15'

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      // ! FRONT QUICK FIX !
      if (
        state.loginBlockDate &&
        Date.now() - state.loginBlockDate > LOGIN_BLOCK_TIME_LIMIT
      ) {
        return {
          ...state,
          user: undefined,
          isLoggedIn: false,
          loginStatus: reqStates.LOADING,

          loginAttempts: 0,
          tooManyLoginAttempts: false,
          loginBlockDate: null,
        };
      }
      return {
        ...state,
        user: undefined,
        isLoggedIn: false,
        loginStatus: reqStates.LOADING,
      };

    case LOGIN_RESPONSE:
      return {
        ...state,
        response: null,
        user: action.response.user,
        isLoggedIn: true,
        redirectRoute: undefined,
        loginStatus: reqStates.SUCCESS,
      };

    case LOGIN_ERROR:
      // ! FRONT QUICK FIX !
      if (state.loginAttempts === 2) {
        return {
          ...state,
          loginStatus: reqStates.ERROR,
          tooManyLoginAttempts: true,
          loginBlockDate: Date.now(),
        };
      }
      return {
        ...state,
        response: action.response,
        loginStatus: reqStates.ERROR,

        // ! FRONT QUICK FIX !
        tooManyLoginAttempts: false,
        loginAttempts: state.loginAttempts + 1,
      };
    case BAPRO_LOGIN:
      // ! FRONT QUICK FIX !
      if (
        state.loginBlockDate &&
        Date.now() - state.loginBlockDate > LOGIN_BLOCK_TIME_LIMIT
      ) {
        return {
          ...state,
          user: undefined,
          isLoggedIn: false,
          loginStatus: reqStates.LOADING,

          loginAttempts: 0,
          tooManyLoginAttempts: false,
          loginBlockDate: null,
        };
      }
      return {
        ...state,
        user: undefined,
        isLoggedIn: false,
        loginStatus: reqStates.LOADING,
      };

    case BAPRO_LOGIN_RESPONSE:
      return {
        ...state,
        response: null,
        user: action.response.user,
        isLoggedIn: true,
        redirectRoute: undefined,
        loginStatus: reqStates.SUCCESS,
      };

    case BAPRO_LOGIN_ERROR:
      // ! FRONT QUICK FIX !
      if (state.loginAttempts === 2) {
        return {
          ...state,
          loginStatus: reqStates.ERROR,
          tooManyLoginAttempts: true,
          loginBlockDate: Date.now(),
        };
      }
      return {
        ...state,
        response: action.response,
        loginStatus: reqStates.ERROR,

        // ! FRONT QUICK FIX !
        tooManyLoginAttempts: false,
        loginAttempts: state.loginAttempts + 1,
      };
    case GOOGLE_LOGIN:
      if (
        state.loginBlockDate &&
        Date.now() - state.loginBlockDate > LOGIN_BLOCK_TIME_LIMIT
      ) {
        return {
          ...state,
          user: undefined,
          isLoggedIn: false,
          loginStatus: reqStates.LOADING,

          loginAttempts: 0,
          tooManyLoginAttempts: false,
          loginBlockDate: null,
        };
      }
      return {
        ...state,
        user: undefined,
        isLoggedIn: false,
        loginStatus: reqStates.LOADING,
      };

    case GOOGLE_LOGIN_RESPONSE:
      return {
        ...state,
        response: null,
        user: action.response.user,
        isLoggedIn: true,
        redirectRoute: undefined,
        loginStatus: reqStates.SUCCESS,
      };

    case GOOGLE_LOGIN_ERROR:
      if (state.loginAttempts === 2) {
        return {
          ...state,
          loginStatus: reqStates.ERROR,
          tooManyLoginAttempts: true,
          loginBlockDate: Date.now(),
        };
      }
      return {
        ...state,
        response: action.response,
        loginStatus: reqStates.ERROR,
        tooManyLoginAttempts: false,
        loginAttempts: state.loginAttempts + 1,
      };
    case LOGIN_FORM_RESET:
      return {
        ...state,
        loginStatus: reqStates.NONE,
      };

    case SAVE_REDIRECT_ROUTE:
      return { ...state, redirectRoute: action.path };

    case GET_PARTNER_RESPONSE:
      return { ...state, user: { ...state.user, partner: action.response } };

    /** User activation */
    case ACTIVATE_ACCOUNT:
      return {
        ...state,
        activationState: { loading: true, success: false, error: false },
      };
    case ACTIVATE_ACCOUNT_RESPONSE:
      return {
        ...state,
        activationState: { loading: false, success: true, error: false },
      };
    case ACTIVATE_ACCOUNT_ERROR:
      return {
        ...state,
        activationState: { loading: false, success: false, error: true },
      };

    case RESET_PASSWORD:
      return {
        ...state,
        resetPasswordStatus: { loading: true, success: false, error: false },
      };
    case RESET_PASSWORD_RESPONSE:
      return {
        ...state,
        resetPasswordStatus: { loading: false, success: true, error: false },
      };
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        resetPasswordStatus: { loading: false, success: false, error: true },
      };

    case VALIDATE_TOKEN_CHANGE_PASSWORD:
      return {
        ...state,
        validateTokenChangePasswordStatus: {
          loading: true,
          success: false,
          error: false,
        },
      };
    case VALIDATE_TOKEN_CHANGE_PASSWORD_RESPONSE:
      return {
        ...state,
        validateTokenChangePasswordStatus: {
          loading: false,
          success: true,
          error: false,
        },
      };
    case VALIDATE_TOKEN_CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        validateTokenChangePasswordStatus: {
          loading: false,
          success: false,
          error: true,
        },
      };

    case RECOVER_PASSWORD:
      return {
        ...state,
        emailStatus: { loading: true, success: false, error: false },
      };
    case RECOVER_PASSWORD_RESPONSE:
      return {
        ...state,
        emailStatus: { loading: false, success: true, error: false },
        resetPasswordStatus: { loading: false, success: false, error: false },
      };
    case RECOVER_PASSWORD_ERROR:
      const { status } = action.error;
      return {
        ...state,
        emailStatus: { loading: false, success: false, error: true, status },
      };
    case RECOVER_PASSWORD_CLEAN_ERROR:
      return {
        ...state,
        emailStatus: {
          loading: false,
          success: true,
          error: false,
          status: null,
        },
      };

    case LOGOUT_RESPONSE:
      return { ...state, ...initialState };

    case REHYDRATE:
      let incoming = action.payload;
      if (incoming && incoming.session) {
        return {
          ...state,
          ...incoming.session,
          redirectRoute: undefined,
          user: localStorage['token'] ? incoming.session.user : undefined,
          isLoggedIn: localStorage['token']
            ? incoming.session.isLoggedIn
            : false,
          loginStatus: reqStates.NONE,
          loginAttempts: 0,
          tooManyLoginAttempts: false,
          activationState: { loading: false, success: false, error: false },
          activationResponse: null,
        };
      }
      return state;

    case OPEN_ID_AUTH_RESPONSE:
      return {
        ...state,
        response: null,
        user: undefined,
        isLoggedIn: false,
        redirectRoute: undefined,
        loginStatus: reqStates.LOADING,
        // userservicelogin: true,
      };

    case UPDATE_LOGGEDIN_USER:
      return {
        ...state,
        user: action.user,
      };

    default:
      return state;
  }
};

export default sessionReducer;
