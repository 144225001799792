export const reportDictionary = (type, value) => {
  const dictionaries = {
    individual: {
      hurlingham: 'introspection',
      natura: 'professional',
      integrado: 'integrator',
      default: 'default',
      // },
      // company: {
      Comfama: 'extended',
      // natura: 'professional',
      // default: 'default',
      vocacional: 'orientation',
    },
  };

  const dictionary = dictionaries[type] || dictionaries['default'];
  return dictionary[value] || dictionary['default'];
};
