import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import './Menu.css';
import Button from '../Button';
import { push } from 'connected-react-router';
import UserChangePasswordModal from '../UserChangePasswordModal/UserChangePasswordModal';
import LanguageSelector from '../../common/LanguageSelector/LanguageSelector';
import { notify } from 'react-notify-toast';
import {
  errorMessage,
  successMessage,
  SnackBar,
} from '../../../utils/snackbar';
import regex from '../../../utils/regex';

const emptyState = {
  values: {
    currentPassword: { inputValue: '', required: true, validation: false },
    password: {
      inputValue: '',
      required: true,
      validator: (pass) => regex.passwordRegex.test(pass),
    },
  },
  showPasswordModal: false,
  show: false,
  navClicked: false,
};

class Menu extends Component {
  static defaultProps;
  constructor(props) {
    super(props);
    this.state = props.update
      ? {
          ...emptyState,
          show: false,
          showPasswordModal: false,
          values: {
            currentPassword: { inputValue: '' },
            password: {
              inputValue: '',
              required: true,
              validator: (pass) => regex.passwordRegex.test(pass),
            },
            repeatPassword: {
              inputValue: '',
              required: true,
              validator: (pass) =>
                pass === this.state.values.password.inputValue,
            },
          },
          showMenu: false,
        }
      : {
          ...emptyState,
          showMenu: false,
          values: {
            ...emptyState.values,
            repeatPassword: {
              inputValue: '',
              required: true,
              validator: (pass) =>
                pass === this.state.values.password.inputValue,
            },
          },
        };
  }

  showToast(title, message, type) {
    notify.show(
      <SnackBar
        icon={'report'}
        title={title}
        body={message}
        type={type === errorMessage ? 'error' : 'success'}
      />,
      'custom',
      5000,
      type,
    );
    return;
  }

  handleHideOptionsState = (ev) => {
    if (
      ev.path.filter((element) => element.id === this.props.name).length === 0
    ) {
      this.setState({ ...this.state, showOptions: false });
    }
  };

  handleLogoutMenu = () => {
    this.props.logout();
  };

  handleInputChange = async (key, value) => {
    this.setState({
      ...this.state,
      values: {
        ...this.state.values,
        [key]: {
          ...this.state.values[key],
          inputValue: value,
        },
      },
    });

    return;
  };

  componentDidMount = () => {
    document.addEventListener('click', (e) => {
      const menu = document.getElementById('MenuButton');

      if (menu && menu?.id !== e.target?.id && this.state.showMenu) {
        this.setState({ ...this.state, showMenu: false });
      }
    });
  };
  componentWillUnmount() {
    document.removeEventListener('click', (e) => {
      const menu = document.getElementById('MenuButton');

      if (menu && menu?.id !== e.target.id) {
        this.setState({ ...this.state, showMenu: false });
      }
    });
  }
  componentDidUpdate(prevState) {
    if (
      this.props.changePasswordStatus.error &&
      !this.props.changePasswordStatus.loading &&
      !this.props.changePasswordStatus.success
    ) {
      this.showToast(
        I18n.t('common.attention'),
        I18n.t('users.error.changePassword'),
        errorMessage,
      );
      this.props.changePasswordResponse({
        loading: false,
        error: false,
        success: false,
      });
    }
    if (
      this.props.changePasswordStatus.success &&
      !this.props.changePasswordStatus.loading &&
      !this.props.changePasswordStatus.error
    ) {
      this.showToast(
        I18n.t('common.excellent'),
        I18n.t('users.success.changePassword'),
        successMessage,
      );
      this.props.changePasswordResponse({
        loading: false,
        error: false,
        success: false,
      });
    }
  }

  render() {
    const { company_name, user_name } = this.props;
    if (!this.props.user) {
      return <div className="menu"></div>;
    }

    const regExPassword =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    return (
      <div className="DropdownUserMenu menu">
        <UserChangePasswordModal
          show={this.state.show}
          currentPassword={this.state.values.currentPassword}
          password={this.state.values.password}
          repeatPassword={this.state.values.repeatPassword}
          change={this.handleInputChange}
          onHide={() => {
            this.setState({
              ...this.state,
              showPasswordModal: false,
              show: false,
              showMenu: false,
              values: {
                currentPassword: { inputValue: '' },
                password: { inputValue: '' },
                repeatPassword: { inputValue: '' },
              },
            });
          }}
          action={(password, currentPassword) => {
            this.props.changePassword(password, currentPassword);
          }}
          user={this.props.user}
          checkingPassword={
            this.state.values.password.inputValue.length > 0
              ? regExPassword.test(this.state.values.password.inputValue)
              : undefined
          }
        />

        <div className="dropdown">
          <button
            type="button"
            id="MenuButton"
            className="material-symbols-rounded"
            onClick={() => {
              this.setState({ ...this.state, showMenu: !this.state.showMenu });
            }}
          >
            expand_more
          </button>

          {this.state.showMenu && (
            <div
              className={`dropdown-menu ${this.state.showMenu ? 'show' : ''}`}
              key={this.state.showMenu}
            >
              <div className="menu-user">
                <p className="menu-user-name">{user_name}</p>
                <p className="menu-company-name">{company_name}</p>
              </div>

              <ul className="ul-menu" key="menu-list">
                {/* <li className="li-menu">
                <div className="no-link">
                  <span className="material-symbols-rounded">{'Public'}</span>
                  <span className="menu-link-no-button">
                    {I18n.t('users.idiom')}
                  </span>
                </div>
              </li> */}
                <li className="li-menu">
                  {window.location.pathname !== '/main/bapro' && (
                    <LanguageSelector showTitle={true} />
                  )}
                </li>
                <li className="li-menu">
                  {window.location.pathname !== '/main/bapro' && (
                    <Button
                      className="menu-link-button"
                      text={I18n.t('menu.changePassword')}
                      onClick={() =>
                        this.setState({
                          ...this.state,
                          showPasswordModal: true,
                          show: true,
                        })
                      }
                      iconButton={true}
                      iconClass={'lock'}
                      iconFilled={true}
                    />
                  )}
                </li>
                {this.props.showUserMenu && (
                  <li className="li-menu">
                    <Button
                      className="menu-link-button"
                      text={I18n.t('menu.users')}
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.getActualLocation('/main/users');
                        this.props.history.push('/main/users');
                      }}
                      iconButton={true}
                      iconClass={'supervisor_account'}
                      iconFilled={true}
                    />
                  </li>
                )}
                <li className="li-menu">
                  <Button
                    className="menu-link-button red"
                    text={I18n.t('common.logout')}
                    onClick={this.handleLogoutMenu}
                    iconButton={true}
                    iconClass={'power_settings_new'}
                  />
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    );
  }
}

Menu.defaultProps = {};
Menu.propTypes = {
  logout: PropTypes.func,
  showHelp: PropTypes.func,
};

export default Menu;
