import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { I18n } from 'react-redux-i18n';
import Button from '../Button';
import './ExportExcelModal.sass';
class ExportExcelModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allOrSelected: 'selected',
    };
  }
  changeState(state) {
    if (this.props.jobOrSkillSelected) {
      this.setState({ allOrSelected: state });
    }
    return true;
  }
  componentDidMount() {
    if (!this.props.jobOrSkillSelected) {
      this.setState({
        ...this.state,
        allOrSelected: 'all',
      });
    }
  }
  render() {
    return (
      <div>
        <Modal
          show={this.props.show}
          onHide={this.props.onHide}
          className="export-excel-modal"
        >
          <Modal.Header className={'modal-header'}>
            <div className="header">
              <div class="material-symbols-rounded">download</div>
              <p className="title">
                {I18n.t(`toolbox.exportExcel.title${this.props.selectedTab}`)}
              </p>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div
              className="options"
              onClick={() => this.changeState('selected')}
            >
              <label
                className={!this.props.jobOrSkillSelected ? 'disabled' : ''}
              >
                <input
                  type="radio"
                  name="selected"
                  // onClick={() => this.changeState('selected')}
                  disabled={!this.props.jobOrSkillSelected}
                  checked={
                    this.state.allOrSelected === 'selected' &&
                    this.props.jobOrSkillSelected
                      ? true
                      : false
                  }
                />
                {I18n.t(
                  `toolbox.exportExcel.downloadSelected${this.props.selectedTab}`,
                )}
              </label>
            </div>
            <div className="options" onClick={() => this.changeState('all')}>
              <label>
                <input
                  type="radio"
                  name="all"
                  onClick={() => this.changeState('all')}
                  checked={
                    this.state.allOrSelected === 'all' ||
                    !this.props.jobOrSkillSelected
                      ? true
                      : false
                  }
                />
                {I18n.t(
                  `toolbox.exportExcel.downloadAll${this.props.selectedTab}`,
                )}
              </label>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="text-link-button"
              text={I18n.t('buttons.cancel')}
              onClick={this.props.onHide}
            />
            <Button
              className="primary"
              text={I18n.t('buttons.download')}
              onClick={() => {
                this.props.action(this.state.allOrSelected);
                this.props.onHide();
              }}
            />
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default ExportExcelModal;
