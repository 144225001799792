import React, { Component } from 'react';
import './SideBarScreen.css';
import { Navbar, Nav, NavItem, NavDropdown, Fade } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { I18n, Translate } from 'react-redux-i18n';
import NewIndicator from '../../common/NewIndicator/NewIndicator';

import logoFull from '../../../assets/images/nawaiam-logo.png';

class SideBarScreen extends Component {
  constructor(props) {
    super(props);
    this.isAdmin = this.props.user.user_admin ? true : false;
    this.is_super_dashboard = this.props.user.partner.is_super_dashboard;
    this.state = {
      active: 4,
      hamburgerExpanded: false,
      actualLink: '',
      showDropdown: false,
      allItems: [
        {
          key: 4,
          text: 'home',
          link: '/main/home',
          order: 1,
          iconType: 'home',
        },
        {
          key: 5,
          text: 'campaigns',
          link: '/main/campaigns',
          links: [
            '/main/campaigns',
            '/main/credits-history',
            '/main/new-campaign',
            '/main/campaign',
          ],
          order: 2,
          iconType: 'campaign',
        },
        {
          key: 6,
          text: 'persons',
          link: '/main/persons',
          order: 4,
          iconType: 'person',
        },
        {
          key: 12,
          text: 'toolbox',
          links: ['/main/persons/group-trends', '/main/tags', '/main/jobs'],
          order: 9,
          iconType: 'build',
          dropdown: true,
          items: [],
        },
        {
          key: 15,
          text: 'Bapro',
          links: ['/main/bapro'],
          order: 9,
          iconType: 'person',
          items: [],
        },
        {
          key: 16,
          text: 'reports',
          link: '/main/reports',
          order: 11,
          iconType: 'assignment',
          items: [],
        },
        /* {
          key: 7,
          text: 'jobs',
          link: '/main/jobs',
          order: 4,
          iconType: 'work',
        }, */
        /* {
          key: 8,
          text: 'profiles',
          link: '/main/tags',
          order: 5,
          iconType: 'contact_page',
        }, */
        // {
        //   key: 9,
        //   text: 'users',
        //   link: '/main/users',
        //   order: 7,
        //   iconType: 'group',
        // },
      ],
    };
  }
  navigationonItems() {
    let currentId = 0;
    if (this.state.actualLink !== this.props.history.location.pathname) {
      this.state.allItems.forEach((item, index) => {
        if (item.link) {
          if (item.link === this.props.history.location.pathname)
            currentId = item.key;
        }
        if (
          item.links?.length &&
          item.links.some((l) => l === this.props.history.location.pathname)
        )
          currentId = item.key;

        /* if (currentId === 12 || item?.secondTab) {
          if (
            ['/main/tags', '/main/jobs'].includes(
              this.props.history.location.pathname,
            )
          ) {
            currentId = 8;
          } else currentId = 7;
        } */
      });

      this.setState({
        ...this.state,
        active: currentId,
        actualLink: this.props.history.location.pathname,
      });
    }
  }
  componentWillUnmount() {
    document.removeEventListener('click', (e) => {
      const dropdown = document.getElementById('nav-dropdown');

      if (!e.target.id.startsWith('nav-dropdown') && this.state.showDropdown) {
        this.setState({ ...this.state, showDropdown: false });
      }
    });
  }
  componentDidMount() {
    document.addEventListener('click', (e) => {
      const dropdown = document.getElementById('nav-dropdown');

      if (!e.target.id.startsWith('nav-dropdown') && this.state.showDropdown) {
        this.setState({ ...this.state, showDropdown: false });
      }
    });

    this.navigationonItems();
    let allItems = this.state.allItems;
    let dropdown = allItems.find((i) => i.key === 12);

    if (this.props.user.groups.includes(6)) {
      dropdown.items.push({
        key: 13, //13 no es el id real del rol, se usa 11, porque sino se superpone con el rol de personas
        secondKey: 6,
        text: 'compatibility',
        link: '/main/persons/group-trends',
        iconType: 'sync_alt',
      });
    }
    if (
      this.props.user.groups.includes(7) ||
      this.props.user.groups.includes(8)
    ) {
      dropdown.items.push({
        key: 8,
        text: 'profilesAndJobs',
        link: '/main/tags',
        secondTab: '/main/jobs',
        iconType: 'person_pin',
      });
    }
    allItems.map((l) => {
      if (l.key === 12) {
        l = { ...dropdown };
      }
      return l;
    });

    if (this.props.user.user_admin) {
      if (this.props.user.groups.includes(10)) {
        allItems.push({
          key: 10,
          text: 'clients',
          link: '/main/clients',
          links: ['/main/clients', '/main/credits-history'],
          order: 3,
          iconType: 'diversity_3',
        });
      }

      allItems.push({
        key: 11,
        text: 'migrations',
        link: '/main/campaignsMigrations',
        order: 8,
        iconType: 'drive_file_move',
      });

      this.setState({
        ...this.state,
        allItems: allItems,
      });
    } else if (this.props.user.account) {
      if (this.props.user.groups.includes(10)) {
        allItems.push({
          key: 10,
          text: 'filiales',
          link: '/main/clients',
          links: ['/main/clients', '/main/credits-history'],
          order: 3,
          iconType: 'account_tree',
        });
      }
      this.setState({
        ...this.state,
        allItems: allItems,
      });
    }

    if (
      this.props.user.groups.includes(15) &&
      this.props.location.pathname === '/main/bapro'
    ) {
      const baproBar = allItems.filter((i) => i.key === 15);
      this.setState({
        ...this.state,
        allItems: baproBar,
      });
    } else {
      const commonBar = allItems.filter((i) => i.key !== 15);
      this.setState({
        ...this.state,
        allItems: commonBar,
      });
    }
  }
  componentDidUpdate() {
    this.navigationonItems();

    // this.setState({ ...this.state, active: item.key });
  }

  render() {
    let items = [];
    /* window.onpopstate = () => {
      this.navigationonItems();
    }; */
    const { loading: loadingReports } = this.props.reportsStatus;
    items = this.state.allItems
      .filter((item) => {
        if (item.key === 5 && this.props.user.user_admin) return false;
        let hasDropdown = item.dropdown;
        if (this.props.user.groups.includes(item.key) || hasDropdown) {
          if (this.props.user.groups.includes(item.key)) return true;

          if (!hasDropdown && !this.props.user.groups.includes(item.key))
            return false;

          let itemDropdown = item.items?.find((d) => {
            if (
              this.props.user.groups.includes(d.key) ||
              this.props.user.groups.includes(d.secondKey)
            )
              return true;
          });
          if (hasDropdown && itemDropdown) return true;
        }
        if (this.props.user.user_admin) {
          if (item.key === 11) return true;
        }
        return false;
      })
      .sort((a, b) => a.order - b.order);
    const listItems = items.map((item, id) => {
      if (!item?.dropdown) {
        return (
          <NavItem
            key={item.key + id + item.link}
            href={item.link}
            className={`${
              item.key === this.state.active ||
              (this.state.active === 0 &&
                item.text === 'persons' &&
                this.state.actualLink === '/main/person')
                ? 'active-navitem navitem'
                : 'navitem'
            }`}
            onClick={() =>
              item.link !== '/main/clients' && this.props.user.user_admin
                ? this.props.updateViewBranch(false)
                : null
            }
          >
            <span className="material-symbols-rounded material-symbols-filled">
              {item.iconType}
            </span>
            {item.key === 16 && <NewIndicator />}
            {I18n.t('common.sidebar.' + item.text)}
          </NavItem>
        );
      } else {
        return (
          <NavDropdown
            key={item.key + id + item.link}
            className={`${
              this.state.active === item.key
                ? 'active-navitem navitem'
                : 'navitem'
            }`}
            noCaret
            title={
              <>
                <span
                  id="nav-dropdown-span-icon"
                  className="material-symbols-rounded material-symbols-filled"
                >
                  {item.iconType}
                </span>
                {I18n.t('common.sidebar.' + item.text)}
                <span
                  id="nav-dropdown-span-expand"
                  className="material-symbols-rounded expand"
                >
                  expand_more
                </span>
              </>
            }
            onClick={() => {
              this.setState({
                ...this.state,
                showDropdown: !this.state.showDropdown,
              });
            }}
            open={this.state.showDropdown}
            id="nav-dropdown"
          >
            <>
              {item.items.map((items, id) => {
                return (
                  <NavItem
                    key={items.key + id + items.link}
                    href={items.link}
                    className={`${
                      items.link === this.props.history.location.pathname ||
                      items.secondTab === this.props.history.location.pathname
                        ? 'dropdown-active-navitem dropdown-navitem'
                        : 'dropdown-navitem'
                    }`}
                    onClick={() => {
                      if (item.link !== '/main/clients') {
                        if (this.props.user.user_admin)
                          this.props.updateViewBranch(false);
                      }
                    }}
                  >
                    <span className="material-symbols-rounded material-symbols-filled">
                      {items.iconType}
                    </span>
                    {I18n.t('common.sidebar.' + items.text)}
                  </NavItem>
                );
              })}
            </>
          </NavDropdown>
        );
      }
    });
    return (
      <Navbar className="sidebar navbar-transparent" fluid>
        <Navbar.Header>
          <Navbar.Brand>
            <img className="logo-text" src={logoFull} alt={'logoText'} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Nav className="hidden-lg mobile-user" pullRight>
            {this.props.userMenu}
          </Nav>
        </Navbar.Header>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="sidebar-list">{listItems}</Nav>
          <Nav className="visible-lg" pullRight>
            {this.props.userMenu}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

SideBarScreen.defaultProps = {};
SideBarScreen.propTypes = {};

export default SideBarScreen;
