import React from 'react';
import './Beta.sass';

const Beta = () => {
  return (
    <div className="beta-container">
      <span>BETA</span>
    </div>
  );
};

export default Beta;
