export const errorMessage = { background: '#ffc4c4', text: '#cb3333' };
export const successMessage = { background: '#c4ffd8', text: '#1eb050' };
export const SnackBar = ({ icon, title, body, type, iconFilled = true }) => {
  return (
    <div className={'snackbar ' + type}>
      <div className="title">
        <span
          className={`material-symbols-rounded ${
            iconFilled && 'material-symbols-filled'
          }`}
        >
          {icon}
        </span>
        <h1>{title}</h1>
      </div>
      <p>{body}</p>
    </div>
  );
};
