import {
  GET_DASHBOARD_REPORT_AGE,
  GET_DASHBOARD_REPORT_AGE_RESPONSE,
  GET_DASHBOARD_REPORT_AGE_ERROR,
  GET_DASHBOARD_REPORT_PROFILE,
  GET_DASHBOARD_REPORT_PROFILE_RESPONSE,
  GET_DASHBOARD_REPORT_PROFILE_ERROR,
  GET_DASHBOARD_REPORT_PROFILE_GENDER,
  GET_DASHBOARD_REPORT_PROFILE_GENDER_RESPONSE,
  GET_DASHBOARD_REPORT_PROFILE_GENDER_ERROR,
  GET_DASHBOARD_REPORT_CITY,
  GET_DASHBOARD_REPORT_CITY_RESPONSE,
  GET_DASHBOARD_REPORT_CITY_ERROR,
  GET_LAST_MONTH,
  GET_LAST_MONTH_RESPONSE,
  GET_LAST_MONTH_ERROR,
  SHOW_ONBOARDING,
  // CLEAR_UI_VALUE
} from './dashboard.actions';
import { REHYDRATE } from 'redux-persist/lib/index';
import { LOGOUT } from '../campaigns/campaigns.actions';

const initialState = {
  results: [],
  desc: false,
  filterValues: { page: '', ordering: '' },
  dashboard: {},

  age: [],
  profile: [],
  profileGender: [],
  city: [],
  onBoardingState: false,
  ageStatus: { loading: false, success: false, error: false },
  profileStatus: { loading: false, success: false, error: false },
  profileGenderStatus: { loading: false, success: false, error: false },
  cityStatus: { loading: false, success: false, error: false },
  dateStatus: { loading: false, success: false, error: false },
  start_date: undefined,
  end_date: undefined,
};

const dashboard = (state = initialState, action) => {
  switch (action.type) {
    /** Age */
    case GET_DASHBOARD_REPORT_AGE:
      return {
        ...state,
        ageStatus: { loading: true, success: false, error: false },
      };
    case GET_DASHBOARD_REPORT_AGE_RESPONSE:
      return {
        ...state,
        ageStatus: { loading: false, success: true, error: false },
        age: action.response,
      };
    case GET_DASHBOARD_REPORT_AGE_ERROR:
      return {
        ...state,
        ageStatus: {
          loading: false,
          success: false,
          error: true,
          age: action.response,
        },
      };
    /** OnBoarding */
    case SHOW_ONBOARDING:
      return {
        ...state,
        onBoardingState: action.boolean,
      };
    /** Profile */
    case GET_DASHBOARD_REPORT_PROFILE:
      return {
        ...state,
        profileStatus: { loading: true, success: false, error: false },
      };
    case GET_DASHBOARD_REPORT_PROFILE_RESPONSE:
      return {
        ...state,
        profileStatus: { loading: false, success: true, error: false },
        profile: action.response,
      };
    case GET_DASHBOARD_REPORT_PROFILE_ERROR:
      return {
        ...state,
        profileStatus: {
          loading: false,
          success: false,
          error: true,
          profile: action.response,
        },
      };

    /** Profile */
    case GET_DASHBOARD_REPORT_PROFILE_GENDER:
      return {
        ...state,
        profileGenderStatus: { loading: true, success: false, error: false },
      };
    case GET_DASHBOARD_REPORT_PROFILE_GENDER_RESPONSE:
      return {
        ...state,
        profileGenderStatus: { loading: false, success: true, error: false },
        profileGender: action.response,
      };
    case GET_DASHBOARD_REPORT_PROFILE_GENDER_ERROR:
      return {
        ...state,
        profileGenderStatus: {
          loading: false,
          success: false,
          error: true,
          profileGender: action.response,
        },
      };

    /** City */
    case GET_DASHBOARD_REPORT_CITY:
      return {
        ...state,
        cityStatus: { loading: true, success: false, error: false },
      };
    case GET_DASHBOARD_REPORT_CITY_RESPONSE:
      return {
        ...state,
        cityStatus: { loading: false, success: true, error: false },
        city: action.response,
      };
    case GET_DASHBOARD_REPORT_CITY_ERROR:
      return {
        ...state,
        cityStatus: {
          loading: false,
          success: false,
          error: true,
          city: action.response,
        },
      };

    // /**  */
    // case CLEAR_UI_VALUE:
    //     return state

    /**  */
    case LOGOUT:
      return { ...initialState };

    case GET_LAST_MONTH:
      return {
        ...state,
        dateStatus: { loading: true, success: false, error: false },
      };
    case GET_LAST_MONTH_RESPONSE: {
      const { start_date, end_date } = action.response;
      console.log('response', action.response);
      return {
        ...state,
        dateStatus: { loading: false, success: true, error: false },
        start_date,
        end_date,
      };
    }
    case GET_LAST_MONTH_ERROR:
      return {
        ...state,
        dateStatus: { loading: false, success: false, error: true },
      };

    /**  */
    case REHYDRATE:
      let incoming = action.payload;
      if (incoming) {
        return {
          ...state,
          ...incoming.dashboard,
          ageStatus: { loading: false, success: false, error: false },
          genderStatus: { loading: false, success: false, error: false },
          profileStatus: { loading: false, success: false, error: false },
          profileGenderStatus: { loading: false, success: false, error: false },
          cityStatus: { loading: false, success: false, error: false },
        };
      }
      return state;

    default:
      return state;
  }
};

export default dashboard;
