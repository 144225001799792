import {
  deleteRequest,
  get,
  patch,
  post,
  put,
  putS3,
  webApi,
} from '../utils/http';
import queryString from 'query-string';

export const services = {
  getClients: (filter, view_branch, search, filterDates, client_type) => {
    return get(
      webApi +
        `api/companies${filter.toString()}&view_branch=${view_branch}&start_date=${
          filterDates?.start_value
        }&end_date=${filterDates?.end_value}&client_type=${client_type}`,
    );
  },
  getAllClients: ({
    accountDropdown = false,
    view_branch = false,
    view_account = false,
    search = '',
  }) => {
    return get(
      webApi +
        `api/companies?account_dropdown=${accountDropdown}&view_branch=${view_branch}&view_account=${view_account}&search=${search}`,
    );
  },
  getBranchs: (account_id, search) =>
    get(
      webApi +
        `api/companies?account_dropdown=${true}&view_branch=${true}&view_account=${false}&search=${search}&${queryString.stringify(
          account_id,
        )}`,
    ),
  getClientGroups: () =>
    get(webApi + `api/groups/?lang=${localStorage.getItem('language')}`),
  /* createUser: (body) => post(webApi + 'api/partner_user/', body), */
  updateCredits: (body) => post(webApi + 'api/companies/credits', body),
  createClient: (body) => {
    return post(webApi + 'api/companies', body, {
      headers: {
        'Content-Type': `multipart/form-data`,
      },
    });
  },
  updateClient: (logo, body) => {
    return patch(webApi + 'api/companies', body, {
      headers: {
        'Content-Type': `multipart/form-data`,
      },
    });
  },
  deleteClient: (body) => {
    const formData = new FormData();
    Object.entries(body).forEach(([key, value]) => {
      formData.append(key, value);
    });
    return deleteRequest(webApi + 'api/companies', formData, {
      headers: {
        'Content-Type': `multipart/form-data`,
      },
    });
  },
  getLogoUrl: (fileName) => {
    return get(webApi + `api/companies/logo?fileName=${fileName}`);
  },
  // getAttachmentURL: (file_name, type) =>
  //   put(webApi + 'api/attachment/', { file_name, type }),
  // uploadAttachment: (url, attachment, type) =>
  //   putS3(url, attachment, { 'Content-Type': type }),
  // confirmAttachmentUpload: (attachmentId, size) =>
  //   put(webApi + `api/attachment/${attachmentId}/confirm/`, { size }),
  getAllClientTypes: () =>
    get(
      webApi +
        `api/companies/account-types?lang=${localStorage.getItem('language')}`,
    ),
};
