import actions from '../actions/index';
import { services } from './jobs.services';
import {
  CREATE_JOB,
  GET_JOBS,
  UPDATE_PAGE,
  UPDATE_PAGE_SIZE,
  UPDATE_JOB,
  DELETE_JOB,
  UPDATE_JOBS_FILTER_VALUES,
} from './jobs.actions';
import { parseQueryObject } from '../utils/parser';
import { push } from 'connected-react-router';

const jobsMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    const actionFilter = { ...getState().jobs.filterValues };
    const filter = {
      ...actionFilter,
      page: getState().jobs.current,
      page_size: getState().jobs.page_size,
      positionDropdown: action.positionDropdown,
    };
    switch (action.type) {
      case GET_JOBS:
        services
          .getJobs(parseQueryObject(filter), action.positionDropdown)
          .then((data) => {
            // dispatch(
            //   actions.persons.getAllJobCompatibility(
            //     data.results.map((job) => job.id),
            //   ),
            // );
            dispatch(actions.jobs.getJobsResponse(data));
          })
          .catch((error) => dispatch(actions.jobs.getJobsError(error)));
        break;

      case UPDATE_PAGE:
        dispatch(actions.jobs.getJobs());
        break;

      case UPDATE_PAGE_SIZE:
        dispatch(actions.jobs.getJobs());
        break;

      case UPDATE_JOB:
        services
          .updateJob(action.id, action.job)
          .then((data) => {
            dispatch(actions.jobs.updateJobResponse(data, action.typeUpdate));
            action.typeUpdate === 'campaign'
              ? dispatch(push(`/main/new-campaign?id_position=${action.id}`))
              : dispatch(push('/main/jobs'));
          })
          .catch((error) => dispatch(actions.jobs.updateJobError(error)));
        break;

      case DELETE_JOB:
        services
          .deleteJob(action.id)
          .then((data) => {
            dispatch(actions.jobs.deleteJobResponse(data));
            dispatch(actions.jobs.getJobs());
            dispatch(push('/main/jobs'));
          })
          .catch((error) => dispatch(actions.jobs.deleteJobError(error)));
        break;

      case CREATE_JOB:
        services
          .createJob(action.body)
          .then((data) => {
            action.typeCreate === 'campaign'
              ? dispatch(push(`/main/new-campaign?id_position=${data.id}`))
              : dispatch(push('/main/jobs'));
            dispatch(actions.jobs.createJobResponse(data, action.typeCreate));
          })
          .catch((error) => dispatch(actions.jobs.createJobError(error)));
        break;
      //OPTIONS
      case UPDATE_JOBS_FILTER_VALUES:
        services
          .getJobs(parseQueryObject({ ...filter, ...action.filterValues }))
          .then((data) => dispatch(actions.jobs.getJobsResponse(data)))
          .catch((error) => dispatch(actions.jobs.getJobsError(error)));
        break;

      default:
        break;
    }
  };

export default jobsMiddleware;
