import React from 'react';
import { I18n } from 'react-redux-i18n';
import logoFull from '../../../assets/images/nawaiam-logo.png';
import './IeBody.css';

const IeBody = ({ name, email }) => {
  return (
    <div className="ie-body-container">
      <header>
        <img className="logo-text" src={logoFull} alt={'logoText'} />
      </header>
      <section className="personal-data">
        <h4>{I18n.t('persons.personalDetails')}</h4>
        <span>
          {I18n.t('clients.name')}: {name}
        </span>
        <span>
          {I18n.t('clients.email')}: {email}
        </span>
      </section>
      <section>
        <h4>{I18n.t('ieprofile.title1')}</h4>
        <p>{I18n.t('ieprofile.paragraph1')}</p>
        <p>{I18n.t('ieprofile.paragraph2')}</p>
      </section>
      <section className="traits">
        <h4>{I18n.t('ieprofile.title2')}</h4>
        <div className="selfknowledge">
          <p className="trait-paragraph">
            <span className="trait-key">
              {I18n.t('ieprofile.selfknowledge.key')}
            </span>
            {I18n.t('ieprofile.selfknowledge.desc')}
          </p>
        </div>
        <div className="selfregulation">
          <p className="trait-paragraph">
            <span className="trait-key">
              {I18n.t('ieprofile.selfregulation.key')}
            </span>
            {I18n.t('ieprofile.selfregulation.desc')}
          </p>
        </div>
        <div className="motivation">
          <p className="trait-paragraph">
            <span className="trait-key">
              {I18n.t('ieprofile.motivation.key')}
            </span>
            {I18n.t('ieprofile.motivation.desc')}
          </p>
        </div>
        <div className="empathy">
          <p className="trait-paragraph">
            <span className="trait-key">{I18n.t('ieprofile.empathy.key')}</span>
            {I18n.t('ieprofile.empathy.desc')}
          </p>
        </div>
        <div className="socialskills">
          <p className="trait-paragraph">
            <span className="trait-key">
              {I18n.t('ieprofile.socialskills.key')}
            </span>
            {I18n.t('ieprofile.socialskills.desc')}
          </p>
        </div>
      </section>
      <section>
        <h4>{I18n.t('ieprofile.title3')}</h4>
        <p>{I18n.t('ieprofile.paragraph3')}</p>
        <p>{I18n.t('ieprofile.paragraph4')}</p>
      </section>
      <section>
        <h4>{I18n.t('ieprofile.title4')}</h4>
        <p>{I18n.t('ieprofile.paragraph5')}</p>
      </section>
      <br />
    </div>
  );
};

export default IeBody;
