import actions from '../actions/index';
import { services } from './users.services';
import {
  CREATE_USER,
  GET_USERS,
  UPDATE_PAGE,
  UPDATE_PAGE_SIZE,
  UPDATE_USER,
  UPDATE_USER_EMAIL,
  DELETE_USER,
  CHANGE_PASSWORD,
  VALIDATE_USER_VIA_EMAIL,
  GET_USER_GROUPS,
  CREATE_USER_FROM_PARTNER,
  UPDATE_USERS_FILTER_VALUES,
  VALIDATE_USER_VIA_EMAIL_ERROR,
  VALIDATE_USER_VIA_EMAIL_RESPONSE,
} from './users.actions';
import { notify } from 'react-notify-toast';
import { success, error } from '../utils/modals';
import { parseQueryObject } from '../utils/parser';
import { push } from 'connected-react-router';
import { I18n } from 'react-redux-i18n';
import dayjs from 'dayjs';
import { Bubble } from 'react-chartjs-2';
import { errorMessage, SnackBar } from '../utils/snackbar';
const usersMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    const actionFilter = { ...getState().users.filterValues };
    const filter = {
      ...actionFilter,
      type: getState().users.filterValues.type,
      page: getState().users.current,
      page_size: getState().users.page_size,
      start_date:
        getState().dashboard.start_date ===
          getState().users.filterValues.start_date ||
        !getState().users.filterValues.start_date
          ? ''
          : dayjs(getState().users.filterValues.start_date).toISOString(),
      end_date:
        getState().dashboard.end_date === getState().users.filterValues.end_date
          ? ''
          : dayjs(getState().users.filterValues.end_date).toISOString(),
    };
    switch (action.type) {
      case GET_USERS:
        services
          .getUsers(parseQueryObject(filter), action.search)
          .then((data) => dispatch(actions.users.getUsersResponse(data)))
          .catch((error) => dispatch(actions.users.getUsersError(error)));
        break;

      case UPDATE_PAGE:
        dispatch(actions.users.getUsers());
        break;

      case UPDATE_PAGE_SIZE:
        dispatch(actions.users.getUsers());
        break;

      case UPDATE_USER_EMAIL:
        services
          .updateUser(action.id, action.user)
          .catch((error) => dispatch(actions.users.updateUserError(error)));
        break;

      case UPDATE_USER:
        services
          .updateUser(action.id, action.user)
          .then((data) => {
            const loggedUser = getState().session.user;
            const selectedUser = getState().users.user;
            const userTab = action.user.branch_id
              ? 'branch'
              : action.user.account_id
              ? 'account'
              : 'admin';
            dispatch(actions.users.updateUserResponse(data));
            if (loggedUser.id === selectedUser.id) {
              const { first_name, last_name } = data.user_result;
              dispatch(
                actions.session.updateLoggedinUser({
                  ...loggedUser,
                  first_name,
                  last_name,
                }),
              );
            }
            if (
              !(
                loggedUser.id === selectedUser.id &&
                data.user_result.email !== selectedUser.email
              )
            )
              dispatch(push('/main/users', userTab));
          })
          .catch((error) => dispatch(actions.users.updateUserError(error)));
        break;

      case DELETE_USER:
        services
          .deleteUser(action.id)
          .then((data) => {
            dispatch(actions.users.deleteUserResponse(data));
            dispatch(actions.users.updatePage(0));
            dispatch(actions.users.getUsers());
            dispatch(push('/main/users'));
          })
          .catch((error) => dispatch(actions.users.deleteUserError(error)));
        break;

      case CHANGE_PASSWORD:
        services
          .changePassword(
            getState().users.user.id
              ? getState().users.user.id
              : getState().session.user.id,
            action.password,
            action.currentPassword,
          )
          .then((data) => {
            dispatch(
              actions.users.changePasswordResponse({
                loading: false,
                error: false,
                success: true,
              }),
            );
          })
          .catch((error) => dispatch(actions.users.changePasswordError(error)));
        break;

      case VALIDATE_USER_VIA_EMAIL:
        services
          .validateUserViaEmail(action.email)
          .then((data) =>
            dispatch(actions.users.validateUserViaEmailResponse(data)),
          )
          .catch((error) =>
            dispatch(actions.users.validateUserViaEmailError(error)),
          );
        break;
      case VALIDATE_USER_VIA_EMAIL_RESPONSE:
        break;
      case VALIDATE_USER_VIA_EMAIL_ERROR:
        notify.show(
          <SnackBar
            icon={'report'}
            title={I18n.t('users.error.notAvailable')}
            body={I18n.t('users.error.email')}
            type="error"
            iconFilled
          />,
          'custom',
          5000,
          errorMessage,
        );
        break;

      case GET_USER_GROUPS:
        services
          .getUserGroups()
          .then((data) => dispatch(actions.users.getUserGroupsResponse(data)))
          .catch((error) => dispatch(actions.users.getUserGroupsError(error)));
        break;

      case CREATE_USER:
        services
          .createUser(action.body)
          .then((data) => {
            dispatch(actions.users.createUserResponse(data));
            dispatch(push('/main/users'));
          })
          .catch((error) => dispatch(actions.users.createUserError(error)));
        break;

      case CREATE_USER_FROM_PARTNER:
        services
          .createUser(action.body)
          .then((data) => {
            dispatch(actions.users.createUserFromPartnerResponse(data));
            dispatch(push('/main/clients'));
          })
          .catch((error) =>
            dispatch(actions.users.createUserFromPartnerError(error)),
          );
        break;

      case UPDATE_USERS_FILTER_VALUES:
        services
          .getUsers(parseQueryObject({ ...filter, ...action.filterValues }))
          .then((data) => dispatch(actions.users.getUsersResponse(data)))
          .catch((error) => dispatch(actions.users.getUsersError(error)));
        break;

      default:
        break;
    }
  };

export default usersMiddleware;
