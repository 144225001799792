import React, { Component } from 'react';
import './UploadFiles.css';
import { I18n } from 'react-redux-i18n';
import dayjs from 'dayjs';
import Button from '../../../components/common/Button';
import PropTypes from 'prop-types';
import { errorMessage, SnackBar } from '../../../utils/snackbar';
import { notify } from 'react-notify-toast';
import _ from 'lodash';
import TextViewer from '../TextViewer/TextViewer';

class UploadFiles extends Component {
  constructor(props) {
    super(props);

    const { file, extensions } = props;
    this.fileInputRef = React.createRef();
    const files = file
      ? file.map((attached) => ({
          file: attached,
          base64ImagePath: attached.path,
        }))
      : [];

    const stringExtensions = this.getStringExtensions(extensions);

    this.state = {
      files,
      stringExtensions,
      error: false,
      selectedFileIndex: null,
      newFiles: [],
    };

    this.handleChange = this.handleChange.bind(this);
  }

  getStringExtensions(extensions) {
    if (!extensions) return '';

    const extensionsArray = extensions.split(',');
    const lastOne = extensionsArray.pop();
    return `${extensionsArray.join(', ')} ${I18n.t(
      'persons.CV.or',
    )} ${lastOne}`;
  }

  componentDidMount() {
    const files = this.props.file
      ? this.props.file.map((attached) => ({
          file: attached,
          base64ImagePath: attached.path,
        }))
      : [];
    this.setState({ files });

    const extensions =
      this.props.extensions && this.props.extensions.split(',');

    const displayExtensions = extensions.join(', ') || '';
    this.setState({ stringExtensions: displayExtensions });
  }
  componentWillUnmount() {
    this.setState({
      files: [],
      stringExtensions: '',
      error: false,
      selectedFileIndex: null,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.file, this.props.file)) {
      if (this.props.file && this.props.file.length) {
        const files = this.props.file.map((attached) => ({
          file: attached,
          base64ImagePath: attached.path,
        }));
        this.setState({ files });
      } else {
        this.setState({ files: [] });
      }
    }

    if (!_.isEqual(prevProps.extensions, this.props.extensions)) {
      if (this.props.extensions) {
        const extensions = this.props.extensions.split(',');
        const lastOne = extensions.pop();
        const stringExtensions = `${extensions.join(', ')} ${I18n.t(
          'persons.CV.or',
        )} ${lastOne}`;
        this.setState({ stringExtensions });
      } else {
        this.setState({ stringExtensions: '' });
      }
    }
  }

  maxSizeRenderFormat = () => {
    return this.props.maxSize / 1000000;
  };

  handleFileClick = (index) => {
    this.setState({
      selectedFileIndex: index,
      showFile: true,
    });
  };

  handleChange(e) {
    const { extensions, maxSize } = this.props;
    const { files: selectedFiles } = e.target;

    // Comprobar si los archivos seleccionados son los mismos que los nuevos archivos
    if (
      !selectedFiles.length ||
      (selectedFiles.length === this.state.newFiles.length &&
        selectedFiles.every(
          (file, index) => file === this.state.newFiles[index],
        ))
    ) {
      return; // Si los archivos son los mismos o no se seleccionan archivos, salimos del método
    }

    const newFiles = [...selectedFiles];
    this.setState({ newFiles });

    const allowedExtensions = new RegExp(extensions.split(',').join('|'));

    const invalidFiles = newFiles.filter(
      (file) => file.size > maxSize || !allowedExtensions.test(file.name),
    );

    if (invalidFiles.length) {
      this.showErrorNotification(
        I18n.t('persons.CV.formats', {
          extensions: this.state.stringExtensions,
          maxSize: this.maxSizeRenderFormat(),
        }),
      );
      this.setState({ newFiles: [] });
      return;
    }

    const hasDuplicate = this.checkForDuplicates(newFiles);
    if (hasDuplicate) {
      this.showErrorNotification(I18n.t('persons.CV.duplicated'));
      this.setState({ newFiles: [] });
      this.fileInputRef.current.value = null;
      return;
    }

    this.processNewFiles(newFiles);
    this.fileInputRef.current.value = null;
  }

  checkForDuplicates(newFiles) {
    const { files } = this.state;
    const fileStep1 = newFiles[0].name.toLowerCase();
    let fileStep2 = fileStep1.split(' ').join('');
    fileStep2 = fileStep2
      .replace(/[\u2010\u2011\u2012\u2013\u2014\u2015\u0020\u002D]/g, '-')
      .replace(/[\u00C0-\u00C5\u00E0-\u00E5]/g, 'a') // Á, À, Â, Ä, á, à, â, ä
      .replace(/[\u00C8-\u00CB\u00E8-\u00EB]/g, 'e') // É, È, Ê, Ë, é, è, ê, ë
      .replace(/[\u00CC-\u00CF\u00EC-\u00EF]/g, 'i') // Í, Ì, Î, Ï, í, ì, î, ï
      .replace(/[\u00D2-\u00D6\u00F2-\u00F6]/g, 'o') // Ó, Ò, Ô, Ö, ó, ò, ô, ö
      .replace(/[\u00D9-\u00DC\u00F9-\u00FC]/g, 'u') // Ú, Ù, Û, Ü, ú, ù, û, ü
      .replace(/[\u00C7\u00E7]/g, 'c') // Ç, ç
      .replace(/[\u0152\u0153]/g, 'oe') // Œ, œ
      .replace(/[\u00DF]/g, 'ss') // ß (Eszett)
      .replace(/[\u00F1]/g, 'n'); // Ñ, ñ

    return files.some((existingFile) => existingFile.file.name === fileStep2);
  }

  processNewFiles(newFiles) {
    const files = [...this.state.files];
    newFiles.forEach((newFile) => {
      const reader = new FileReader();
      reader.onload = () => {
        files.push({ file: newFile, base64ImagePath: reader.result });
        this.setState({ files });
      };
      reader.readAsDataURL(newFile);
    });

    this.props.onChange && this.props.onChange(newFiles);
    this.setState({ newFiles: [] });
  }

  showErrorNotification(body) {
    notify.show(
      <SnackBar
        icon={'report'}
        title={I18n.t('common.error', { errorMessage: '' })}
        body={body}
        type="error"
      />,
      'custom',
      5000,
      errorMessage,
    );
  }

  truncateDecimals(n) {
    const t = n.toString();
    const regex = /(\d*.\d{0,2})/;
    return t.match(regex)[0];
  }
  clearInput(indexToDelete) {
    const updatedFiles = [...this.state.files];
    const deletedFile = updatedFiles.splice(indexToDelete, 1)[0];

    this.props.deleteAttached && this.props.deleteAttached(deletedFile);
  }

  render() {
    return (
      <div className="upload-file-container">
        <div>
          {this.props.title && (
            <div className="title-file">
              <h3>{this.props.title}</h3>
              {this.renderInputFile()}
            </div>
          )}
          {!this.state.files.length ? (
            <div className="message-file">
              <span className="material-symbols-filled">report</span>
              <div>
                <p className="description">
                  {this.props.messageNoFile ||
                    'Aún no has adjuntado ningún archivo'}
                </p>
                <p className="helper-formats">
                  {I18n.t('persons.CV.formats', {
                    extensions: this.state.stringExtensions,
                    maxSize: this.maxSizeRenderFormat(),
                  })}
                </p>
              </div>
            </div>
          ) : (
            this.state.files.map((fileObj, index) => (
              <div className="file-charged" key={index}>
                <span
                  className="material-symbols-rounded attach_file"
                  style={{
                    transform: this.props.rotateIcon
                      ? `rotate(${this.props.rotateIcon}deg)`
                      : '',
                  }}
                >
                  {this.props.icon || 'attach_file'}
                </span>
                {this.props.viewImage &&
                  fileObj.file.type.includes('image') &&
                  fileObj.base64ImagePath && (
                    <img
                      src={fileObj.base64ImagePath}
                      alt={fileObj.file.name}
                      className="image-preview"
                    />
                  )}
                <p>
                  <span>{fileObj.file.name}</span>
                  <span className="date-file">
                    {dayjs(fileObj.file.created).format('DD/MM/YYYY')} -
                    {this.truncateDecimals(fileObj.file.size / 1000000)}mb
                  </span>
                </p>
                <button
                  onClick={() => {
                    !this.props.disabled && this.clearInput(index);
                  }}
                  className={
                    this.props.disabled
                      ? 'disabled button-delete'
                      : ' button-delete'
                  }
                >
                  <span className="material-symbols-filled">delete</span>
                </button>
                <Button
                  className="text-link-button"
                  text={this.props.textButtonWithFile}
                  iconClass="contact_page"
                  iconButton
                  onClick={() => this.handleFileClick(index)}
                  iconFilled={true}
                />
              </div>
            ))
          )}
        </div>

        {this.state.showFile && this.state.selectedFileIndex !== null && (
          <div
            className="iframe-container"
            onClick={() =>
              this.setState({ showFile: false, selectedFileIndex: null })
            }
          >
            {this.state.files[this.state.selectedFileIndex]?.file.type.includes(
              'video',
            ) ? (
              <video className="video-viewer" controls>
                <source
                  src={
                    this.state.files[this.state.selectedFileIndex]
                      ?.base64ImagePath
                  }
                  type="video/mp4"
                  key={this.state.files[this.state.selectedFileIndex]}
                />
              </video>
            ) : this.state.files[
                this.state.selectedFileIndex
              ]?.file.type.includes('text') ? (
              <TextViewer
                file={this.state.files[this.state.selectedFileIndex]?.file}
              />
            ) : (
              <iframe
                src={
                  this.state.files[this.state.selectedFileIndex]
                    ?.base64ImagePath
                }
                title={
                  this.state.files[this.state.selectedFileIndex]?.file.name
                }
              />
            )}
          </div>
        )}
      </div>
    );
  }

  renderInputFile() {
    return (
      <div className="input-file">
        <label
          htmlFor="input-file"
          className={
            this.props.file?.length >= 10 ||
            this.props.disabled ||
            this.props.loading ||
            this.props.loadingfiles
              ? 'disabled'
              : ''
          }
        >
          <span
            className="material-symbols-rounded attach_file"
            style={{
              transform: this.props.rotateIcon
                ? `rotate(${this.props.rotateIcon}deg)`
                : '',
            }}
          >
            {this.props.icon || 'attach_file'}
          </span>
          {this.props.textButton}
        </label>
        <input
          id="input-file"
          disabled={
            this.props.file?.length >= 10 ||
            this.props.disabled ||
            this.props.loading ||
            this.props.loadingfiles
          }
          type="file"
          name="file"
          accept={this.props.extensions}
          onChange={(e) => this.handleChange(e)}
          ref={this.fileInputRef}
        />
      </div>
    );
  }
}

UploadFiles.defaultProps = {
  maxSize: 5000000,
};

UploadFiles.propTypes = {
  buttonIcon: PropTypes.string,
  withPreview: PropTypes.bool,
  extensions: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.string,
  rotateIcon: PropTypes.number,
  maxSize: PropTypes.number,
  disabled: PropTypes.bool,
  file: PropTypes.array,
  textButton: PropTypes.string,
  textButtonWithFile: PropTypes.string,
  onChange: PropTypes.func,
  messageNoFile: PropTypes.string,
};
export default UploadFiles;
