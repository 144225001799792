import {
  // GET_PERSONS, GET_PERSONS_RESPONSE, GET_PERSONS_ERROR,
  GET_ALL_PROFILES,
  GET_ALL_PROFILES_RESPONSE,
  GET_ALL_PROFILES_ERROR,
  GET_ALL_PARTNERS,
  GET_ALL_PARTNERS_RESPONSE,
  GET_ALL_PARTNERS_ERROR,
  GET_COUNTRIES,
  GET_COUNTRIES_RESPONSE,
  GET_COUNTRIES_ERROR,
  GET_PROFILE,
  GET_PROFILE_RESPONSE,
  GET_PROFILE_ERROR,
  GET_CITIES,
  GET_CITIES_RESPONSE,
  GET_CITIES_ERROR,
  CREATE_PERSON,
  CREATE_PERSON_RESPONSE,
  CREATE_PERSON_ERROR,
  UPDATE_PERSON,
  UPDATE_PERSON_RESPONSE,
  UPDATE_PERSON_ERROR,
  DELETE_PERSON,
  DELETE_PERSON_RESPONSE,
  DELETE_PERSON_ERROR,
  SELECT_PERSON,
  DEMOGRAPHIC_FILTER,
  DEMOGRAPHIC_FILTER_RESPONSE,
  DEMOGRAPHIC_FILTER_ERROR,
  GET_JOB_COMPATIBILITY_RESPONSE,
  GET_CAPABILITIES,
  PATCH_USER,
  PATCH_USER_RESPONSE,
  PATCH_USER_ERROR,
  SEND_PERSON_REPORT_EMAIL,
  SEND_PERSON_REPORT_EMAIL_RESPONSE,
  SEND_PERSON_REPORT_EMAIL_ERROR,

  // table and filters
  UPDATE_PERSONS_FILTER_VALUES,
  ADD_REMOVE_PERSON_TO_SELECTION,
  UPDATE_TOOLBOX_FILTER,
  TOGGLE_SELECTION_ALL,
  CLEAR_SELECTION,
  CLEAR_PERSON_CUSTOM_FILTER,
  SAVE_SEARCH_INPUT,
  UPDATE_PAGE,
  UPDATE_PAGE_SIZE,
  FILTER_PERSONS,
  FILTER_PERSONS_RESPONSE,
  FILTER_PERSONS_ERROR,
  CLEAR_FILTER,
  CLEAR_UI_VALUE,
  LOGOUT,
  GET_JOB_COMPATIBILITY_ERROR,
  GET_JOB_COMPATIBILITY,
  DOWNLOAD_PERSON_REPORT,
  DOWNLOAD_PERSON_REPORT_RESPONSE,
  DOWNLOAD_PERSON_REPORT_ERROR,
  MIGRATE_CAMPAIGN,
  MIGRATE_CAMPAIGN_RESPONSE,
  MIGRATE_CAMPAIGN_ERROR,
  TREND_EXPORT,
  TREND_EXPORT_RESPONSE,
  TREND_EXPORT_ERROR,
  SELECT_ALL,
  LOAD_ALL,
  SAVE_ALL,
  ALL_LOADED,
  GET_ALL_JOB_COMPATIBILITY,
  GET_ALL_JOB_COMPATIBILITY_RESPONSE,
  GET_ALL_JOB_COMPATIBILITY_ERROR,
  GET_USER_PLAYER_INFORMATION,
  GET_USER_PLAYER_INFORMATION_RESPONSE,
  ADD_NOTE,
  ADD_NOTE_RESPONSE,
  ADD_NOTE_ERROR,
  GET_NOTES,
  GET_NOTES_RESPONSE,
  GET_NOTES_ERROR,
  UPDATE_NOTE,
  UPDATE_NOTE_RESPONSE,
  UPDATE_NOTE_ERROR,
  DELETE_NOTE,
  DELETE_NOTE_RESPONSE,
  DELETE_NOTE_ERROR,
  ADD_CV,
  ADD_CV_RESPONSE,
  ADD_CV_ERROR,
  GET_CV,
  GET_CV_RESPONSE,
  GET_CV_ERROR,
  UPDATE_CV,
  UPDATE_CV_RESPONSE,
  UPDATE_CV_ERROR,
  SHARE_FILE,
  SHARE_FILE_RESPONSE,
  SHARE_FILE_ERROR,
  CLEAN_TOOLBOX_FILTER,
  CLEAR_CV,
  ADD_IE_INVITATION_RESPONSE,
  ADD_IE_INVITATION_ERROR,
  SELECT_PERSON_RESPONSE,
  MASSIVE_IE_INVITATION_RESPONSE,
  MASSIVE_IE_INVITATION_ERROR,
  DELETE_ATTACHED_RESPONSE,
} from './persons.actions';
import { REHYDRATE } from 'redux-persist/lib/index';

const initialState = {
  count: 0, // table rows
  current: 1, // page number
  total_pages: 1,
  page_size: 10, // rows to fetch and show
  results: [],
  desc: true,
  customFilter: { ordering: '-game_date', search: '', page: 1 },
  filterApplied: false,
  demo_filter_results: [],
  person: {},
  selection: [],
  toolboxFilterPersons: {
    title: '',
    subtitle: '',
    caption: '',
  },
  toolboxFilterJobs: {
    title: '',
    subtitle: '',
  },
  toolboxFilterSkills: {
    title: '',
  },
  selectionData: [],
  profiles: [],
  partners: [],
  job_compatibility: [],
  all_job_compatibility: [],
  capabilities: [],
  countries: [],
  cities: [],
  persons: [], // TODO: not used?
  allPersons: [],
  filters: [], // !! this is persons
  create: { loading: false, success: false, error: false },
  update: { loading: false, success: false, error: false },
  userPlayerInformationStatus: { loading: false, success: false, error: false },
  userPlayerInformation: [],
  userUpdate: { loading: false, success: false, error: false },
  delete: { loading: false, success: false, error: false },
  demoFilterStatus: { loading: false, success: false, error: false },
  reportsStatus: { loading: false, success: false, error: false },

  retrieve: { loading: false, success: false, error: false },
  sendPersonReportEmailStatus: { loading: false, success: false, error: false },
  jobsCompatibilityStatus: { loading: false, success: false, error: false },
  allJobsCompatibilityStatus: { loading: false, success: false, error: false },
  savedSearchInput: { inputValue: '' },
  currentProfile: {},
  allSelected: false,
  allResultsSelected: [],
  allLoaded: false,
  retrieveFilter: { loading: false, success: false, error: false },
  serverDateFilterShouldChange: false,
  historicalStartDate: null,

  addNoteStatus: { loading: false, success: false, error: false },
  updateNoteStatus: { loading: false, success: false, error: false },
  deleteNoteStatus: { loading: false, success: false, error: false },
  getNoteStatus: { loading: false, success: false, error: false },
  notes: [],

  addCVStatus: { loading: false, success: false, error: false },
  getCVStatus: { loading: false, success: false, error: false },
  updateCVStatus: { loading: false, success: false, error: false },
  attached_files: null,
  attachedFilesStatus: { loading: false, success: false, error: false },
  shareFileStatus: { loading: false, success: false, error: false },
  IEInvitationStatus: { loading: false, success: false, error: false },
  massiveInvitationStatus: { loading: false, success: true, error: false },
};

const persons = (state = initialState, action) => {
  switch (action.type) {
    case FILTER_PERSONS:
      const serverDateFilterShouldChange =
        JSON.stringify(state.customFilter?.campaigns) !==
          JSON.stringify(action.filter?.campaigns) ||
        JSON.stringify(state.customFilter?.partners) !==
          JSON.stringify(action.filter?.partners);
      let customFilter = action.filter?.isPageUpdate
        ? state.customFilter
        : action.filter || state.customFilter;
      // if (serverDateFilterShouldChange && !action.filter?.isPageUpdate) {
      // customFilter.start_range = undefined;
      // customFilter.end_range = undefined;
      // }
      const filterConst = [
        'campaigns',
        'code',
        'max_age',
        'min_age',
        'profiles',
      ];
      if (!customFilter.ordering) customFilter.ordering = '-game_date';
      return {
        ...state,
        customFilter,
        filterApplied: Object.keys(action.filter || {}).some((k) =>
          filterConst.includes(k),
        ),
        serverDateFilterShouldChange,
        retrieveFilter: { loading: true, success: false, error: false },
      };
    case FILTER_PERSONS_RESPONSE:
      /* const start_date =
        !state.start_date || state.serverDateFilterShouldChange
          ? action.response.game_date_range.start_date
          : state.start_date;
      const end_date =
        !state.start_date || state.serverDateFilterShouldChange
          ? action.response.game_date_range.end_date
          : state.end_date; 
      const historicalStartDate = action.response.historical_start_date; */
      const tempState = {
        ...state,
        retrieveFilter: { loading: false, success: true, error: false },
        filters: action.response.results,
        /* start_date,
        end_date,
        historicalStartDate, */
        ...action.response,
      };
      if (state.allSelected) {
        return {
          ...tempState,
          //allSelected: false,
          //selection: [],
          selectionData: [],
        };
      }
      return tempState;
    case FILTER_PERSONS_ERROR:
      return {
        ...state,
        retrieveFilter: { loading: false, success: false, error: true },
      };
    /** Profiles */
    case GET_ALL_PROFILES:
      return {
        ...state,
        retrieve: { loading: true, success: false, error: false },
        ...action.response,
      };
    case GET_ALL_PROFILES_RESPONSE:
      return {
        ...state,
        retrieve: { loading: false, success: true, error: false },
        profiles: action.response.results,
      };
    case GET_ALL_PROFILES_ERROR:
      return {
        ...state,
        retrieve: { loading: false, success: false, error: true },
      };

    /** Partners */
    case GET_ALL_PARTNERS:
      return {
        ...state,
        retrieve: { loading: true, success: false, error: false },
      };
    case GET_ALL_PARTNERS_RESPONSE:
      return {
        ...state,
        retrieve: { loading: false, success: true, error: false },
        partners: action.response.results,
      };
    case GET_ALL_PARTNERS_ERROR:
      return {
        ...state,
        retrieve: { loading: false, success: false, error: true },
      };

    /** Countries */
    case GET_COUNTRIES:
      return {
        ...state,
        retrieve: { loading: true, success: false, error: false },
      };
    case GET_COUNTRIES_RESPONSE:
      return {
        ...state,
        retrieve: { loading: false, success: true, error: false },
        countries: action.response.results,
      };
    case GET_COUNTRIES_ERROR:
      return {
        ...state,
        retrieve: { loading: false, success: false, error: true },
      };

    /** Profile */
    case GET_PROFILE:
      return {
        ...state,
        retrieve: { loading: true, success: false, error: false },
      };
    case GET_PROFILE_RESPONSE:
      return {
        ...state,
        retrieve: { loading: false, success: true, error: false },
        currentProfile: action.response.results[0],
      };
    case GET_PROFILE_ERROR:
      return {
        ...state,
        retrieve: { loading: false, success: false, error: true },
      };

    /** Cities */
    case GET_CITIES:
      return {
        ...state,
        retrieve: { loading: true, success: false, error: false },
      };
    case GET_CITIES_RESPONSE:
      return {
        ...state,
        retrieve: { loading: false, success: true, error: false },
        cities: action.response.results,
      };
    case GET_CITIES_ERROR:
      return {
        ...state,
        retrieve: { loading: false, success: false, error: true },
      };

    case DOWNLOAD_PERSON_REPORT:
      return {
        ...state,
        reportsStatus: { loading: true, success: false, error: false },
      };
    case DOWNLOAD_PERSON_REPORT_RESPONSE:
      return {
        ...state,
        reportsStatus: { loading: false, success: true, error: false },
        selection: [],
        selectionData: [],
      };
    case DOWNLOAD_PERSON_REPORT_ERROR:
      return {
        ...state,
        reportsStatus: { loading: false, success: false, error: true },
      };

    /**  */
    case GET_JOB_COMPATIBILITY:
      return {
        ...state,
        jobsCompatibilityStatus: {
          loading: true,
          success: false,
          error: false,
        },
      };
    case GET_JOB_COMPATIBILITY_RESPONSE:
      return {
        ...state,
        jobsCompatibilityStatus: {
          loading: false,
          success: true,
          error: false,
        },
        job_compatibility: action.response,
      };
    case GET_JOB_COMPATIBILITY_ERROR:
      return {
        ...state,
        jobsCompatibilityStatus: {
          loading: false,
          success: false,
          error: true,
        },
      };

    case GET_ALL_JOB_COMPATIBILITY:
      return {
        ...state,
        allJobsCompatibilityStatus: {
          loading: true,
          success: false,
          error: false,
        },
      };
    case GET_ALL_JOB_COMPATIBILITY_RESPONSE:
      return {
        ...state,
        allJobsCompatibilityStatus: {
          loading: false,
          success: true,
          error: false,
        },
        all_job_compatibility: action.response,
      };
    case GET_ALL_JOB_COMPATIBILITY_ERROR:
      return {
        ...state,
        allJobsCompatibilityStatus: {
          loading: false,
          success: false,
          error: true,
        },
      };
    /** */
    case GET_CAPABILITIES:
      return { ...state, capabilities: action.response };

    /** Demographic filter */
    case DEMOGRAPHIC_FILTER:
      return {
        ...state,
        demoFilterStatus: { loading: true, success: false, error: false },
      };
    case DEMOGRAPHIC_FILTER_RESPONSE:
      return {
        ...state,
        demoFilterStatus: { loading: false, success: true, error: false },
        demo_filter_results: action.response,
      };
    case DEMOGRAPHIC_FILTER_ERROR:
      return {
        ...state,
        demoFilterStatus: { loading: false, success: false, error: true },
      };

    case SELECT_PERSON_RESPONSE:
      return {
        ...state,
        person: {
          ...action.person.results[0],
          user: action.person.results[0].user_player.user,
        },
      };

    /** */
    case UPDATE_PERSONS_FILTER_VALUES:
      return {
        ...state,
        current: action.filter.search ? 1 : state.customFilter.current,
        customFilter: {
          ...state.customFilter,
          ...action.filter,
        },
        desc: !state.desc,
      };
    case CLEAR_FILTER:
      return {
        ...state,
        customFilter: {
          ordering: state.customFilter.ordering,
          search: state.customFilter.search,
        },
      };

    /**  */
    case UPDATE_PAGE:
      return { ...state, current: action.pageNumber };
    case UPDATE_PAGE_SIZE:
      return {
        ...state,
        page_size: action.pageSize,
        current: 1,
        selection: [],
        selectionData: [],
        allSelected: false,
        allResultsSelected: [],
      };

    /** Delete person */
    case DELETE_PERSON:
      return {
        ...state,
        delete: { loading: true, success: false, error: false },
      };
    case DELETE_PERSON_RESPONSE:
      return {
        ...state,
        delete: { loading: false, success: true, error: false },
      };
    case DELETE_PERSON_ERROR:
      return {
        ...state,
        delete: { loading: false, success: false, error: true },
      };

    /** Update person */
    case UPDATE_PERSON:
      return {
        ...state,
        update: { loading: true, success: false, error: false },
      };
    case UPDATE_PERSON_RESPONSE:
      return {
        ...state,
        update: { loading: false, success: true, error: false },
      };
    case UPDATE_PERSON_ERROR:
      return {
        ...state,
        update: { loading: false, success: false, error: true },
      };

    /** Patch user */
    case PATCH_USER:
      return {
        ...state,
        userUpdate: { loading: true, success: false, error: false },
      };
    case PATCH_USER_RESPONSE:
      return {
        ...state,
        userUpdate: { loading: false, success: true, error: false },
      };
    case PATCH_USER_ERROR:
      return {
        ...state,
        userUpdate: { loading: false, success: false, error: true },
      };

    /** Create person */
    case CREATE_PERSON:
      return {
        ...state,
        create: { loading: true, success: false, error: false },
      };
    case CREATE_PERSON_RESPONSE:
      return {
        ...state,
        create: { loading: false, success: true, error: false },
      };
    case CREATE_PERSON_ERROR:
      return {
        ...state,
        create: { loading: false, success: false, error: true },
      };

    case MIGRATE_CAMPAIGN:
      return {
        ...state,
        create: { loading: true, success: false, error: false },
      };
    case MIGRATE_CAMPAIGN_RESPONSE:
      return {
        ...state,
        create: { loading: false, success: true, error: false },
      };
    case MIGRATE_CAMPAIGN_ERROR:
      return {
        ...state,
        create: { loading: false, success: false, error: true },
      };

    case TREND_EXPORT:
      return {
        ...state,
        create: { loading: true, success: false, error: false },
      };
    case TREND_EXPORT_RESPONSE:
      return {
        ...state,
        create: { loading: false, success: true, error: false },
      };
    case TREND_EXPORT_ERROR:
      return {
        ...state,
        create: { loading: false, success: false, error: true },
      };

    /**  */
    case ADD_REMOVE_PERSON_TO_SELECTION:
      let add =
        state.selection.filter(
          (selected) => selected.id_invitation === action.result.id_invitation,
        ).length === 0;
      let newSelection = add
        ? state.selection.concat([action.result])
        : state.selection.filter(
            (sel) => sel.id_invitation !== action.result.id_invitation,
          );
      let extraSelData = add
        ? state.selectionData.concat(
            state.results.filter(
              (res) => res.id === action.result.id_invitation,
            )[0],
          )
        : state.selectionData.filter(
            (sel) => sel !== action.result.id_invitation,
          );

      return {
        ...state,
        selection: [...new Set(newSelection)],
        selectionData: extraSelData,
        allSelected: state.allSelected && add,
      };
    case UPDATE_TOOLBOX_FILTER:
      return {
        ...state,
        [action.key]: {
          ...action.filter,
        },
      };
    case CLEAN_TOOLBOX_FILTER:
      return {
        ...state,
        toolboxFilterPersons: {
          title: '',
          subtitle: { description: '' },
          caption: { description: '' },
        },
        toolboxFilterJobs: {
          title: '',
          subtitle: { description: '' },
        },
        toolboxFilterSkills: {
          title: '',
        },
      };
    case TOGGLE_SELECTION_ALL:
      let result = state.selection;
      // find if persons in filters are ALL selected
      const filtersFound = state.selection.filter((result) => {
        const found = state.filters.find(
          (person) => person.conductual.id === result.id_invitation,
        );
        return found;
      });
      if (filtersFound.length === state.filters.length) {
        // enters here if all persons in filters are in my selection
        result = state.selection.filter(
          (result) =>
            !state.filters.find(
              (person) => person.conductual.id === result.id_invitation,
            ),
        );
      } else {
        let resultFiltered = result.filter(
          (resultPrev) =>
            !state.filters.find(
              (person) => person.conductual.id === resultPrev.id_invitation,
            ),
        );
        result = [
          ...new Set([
            ...resultFiltered,
            ...state.filters.flatMap((person) => ({
              id_result: person.conductual.result.id,
              id_invitation: person.conductual.id,
              campaign: person.campaign,
              profile: person.profile,
              full_name: person.user.full_name,
              email: person.conductual.email,
              user_id: person.user.id,
            })),
          ]),
        ];
      }
      return { ...state, selection: result };

    /**  */
    case CLEAR_SELECTION:
      return {
        ...state,
        selection: [],
        selectionData: [],
        allSelected: false,
        allResultsSelected: [],
        allLoaded: false,
      };

    case CLEAR_PERSON_CUSTOM_FILTER:
      return {
        ...state,
        customFilter: { ordering: '-game_date', search: '', page: 1 },
      }; // ver si ahora también está volviendo a la página 1

    /**  */
    case CLEAR_UI_VALUE:
      return {
        ...state,
        [action.op_type]: {
          ...state[action.op_type],
          [action.condition]: false,
        },
      };

    /** Send a person a report in email format */
    case SEND_PERSON_REPORT_EMAIL:
      return {
        ...state,
        sendPersonReportEmailStatus: {
          loading: true,
          success: false,
          error: false,
        },
      };
    case SEND_PERSON_REPORT_EMAIL_RESPONSE:
      return {
        ...state,
        sendPersonReportEmailStatus: {
          loading: false,
          success: true,
          error: false,
        },
      };
    case SEND_PERSON_REPORT_EMAIL_ERROR:
      return {
        ...state,
        sendPersonReportEmailStatus: {
          loading: false,
          success: false,
          error: true,
        },
      };

    /**  */
    case SAVE_SEARCH_INPUT:
      return { ...state, savedSearchInput: action.value };

    /**  */
    case LOGOUT:
      return { ...initialState };

    case SELECT_ALL:
      return { ...state, allSelected: action.flag };

    //OPTIONS

    case REHYDRATE:
      let incoming = action.payload;
      delete incoming?.persons?.filters;
      if (incoming) {
        return {
          ...state,
          ...incoming.persons,
          create: { loading: false, success: false, error: false },
          update: { loading: false, success: false, error: false },
          userUpdate: { loading: false, success: false, error: false },
          delete: { loading: false, success: false, error: false },
          demoFilterStatus: { loading: false, success: false, error: false },
          retrieve: { loading: false, success: false, error: false },
          sendPersonReportEmailStatus: {
            loading: false,
            success: false,
            error: false,
          },
          customFilter: initialState.customFilter,
        };
      }
      return state;

    // Filters

    case LOAD_ALL:
      return { ...state };

    case ALL_LOADED:
      return { ...state, allLoaded: true };

    case SAVE_ALL:
      return {
        ...state,
        //results: action.results,
        allResultsSelected: [...action.results],
        selection: [...action.results],
      };

    case GET_USER_PLAYER_INFORMATION:
      return {
        ...state,
        userPlayerInformationStatus: {
          loading: true,
          success: false,
          error: false,
        },
      };

    case GET_USER_PLAYER_INFORMATION_RESPONSE:
      return {
        ...state,
        userPlayerInformation: action.response,
      };

    case ADD_NOTE:
      return {
        ...state,
        addNoteStatus: { loading: true, error: false, success: false },
      };

    case ADD_NOTE_RESPONSE:
      return {
        ...state,
        addNoteStatus: { loading: false, error: false, success: true },
      };

    case ADD_NOTE_ERROR:
      return {
        ...state,
        addNoteStatus: { loading: false, error: true, success: false },
      };

    case UPDATE_NOTE:
      return {
        ...state,
        updateNoteStatus: { loading: true, error: false, success: false },
      };

    case UPDATE_NOTE_RESPONSE:
      return {
        ...state,
        updateNoteStatus: { loading: false, error: false, success: true },
      };

    case UPDATE_NOTE_ERROR:
      return {
        ...state,
        updateNoteStatus: { loading: false, error: true, success: false },
      };

    case DELETE_NOTE:
      return {
        ...state,
        deleteNoteStatus: { loading: true, error: false, success: false },
      };

    case DELETE_NOTE_RESPONSE:
      return {
        ...state,
        deleteNoteStatus: { loading: false, error: false, success: true },
      };

    case DELETE_NOTE_ERROR:
      return {
        ...state,
        deleteNoteStatus: { loading: false, error: true, success: false },
      };

    case GET_NOTES:
      return {
        ...state,
        getNoteStatus: { loading: true, error: false, success: false },
      };

    case GET_NOTES_RESPONSE:
      return {
        ...state,
        getNoteStatus: { loading: false, error: false, success: true },
        notes: action.response,
      };

    case GET_NOTES_ERROR:
      return {
        ...state,
        getNoteStatus: { loading: false, error: true, success: false },
      };

    case ADD_CV:
      return {
        ...state,
        addCVStatus: { loading: true, error: false, success: false },
      };

    case ADD_CV_RESPONSE:
      return {
        ...state,
        addCVStatus: { loading: false, error: false, success: true },
      };

    case ADD_CV_ERROR:
      return {
        ...state,
        addCVStatus: { loading: false, error: true, success: false },
      };

    case GET_CV:
      return {
        ...state,
        getCVStatus: { loading: true, error: false, success: false },
      };

    case GET_CV_RESPONSE:
      return {
        ...state,
        getCVStatus: { loading: false, error: false, success: true },
        attached_files: action.response,
      };
    case DELETE_ATTACHED_RESPONSE:
      return {
        ...state,
        attachedFilesStatus: {
          loading: false,
          success: true,
          error: false,
        },
      };
    case GET_CV_ERROR:
      return {
        ...state,
        getCVStatus: { loading: false, error: true, success: false },
      };

    case CLEAR_CV:
      return {
        ...state,
        attached_files: null,
      };
    case UPDATE_CV:
      return {
        ...state,
        updateCVStatus: { loading: true, error: false, success: false },
      };

    case UPDATE_CV_RESPONSE:
      return {
        ...state,
        updateCVStatus: { loading: false, error: false, success: true },
      };

    case UPDATE_CV_ERROR:
      return {
        ...state,
        updateCVStatus: { loading: false, error: true, success: false },
      };

    case SHARE_FILE:
      return {
        ...state,
        shareFileStatus: { loading: true, error: false, success: false },
      };

    case SHARE_FILE_RESPONSE:
      return {
        ...state,
        shareFileStatus: { loading: false, error: false, success: true },
      };

    case SHARE_FILE_ERROR:
      return {
        ...state,
        shareFileStatus: { loading: false, error: true, success: false },
      };
    case ADD_IE_INVITATION_RESPONSE:
      return {
        ...state,
        IEInvitationStatus: {
          loading: false,
          error: false,
          success: true,
        },
      };
    case ADD_IE_INVITATION_ERROR:
      return {
        ...state,
        IEInvitationStatus: {
          loading: false,
          error: true,
          success: false,
        },
      };
    case MASSIVE_IE_INVITATION_RESPONSE:
      return {
        ...state,
        massiveInvitationStatus: {
          loading: false,
          error: false,
          success: true,
        },
      };
    case MASSIVE_IE_INVITATION_ERROR:
      return {
        ...state,
        massiveInvitationStatus: {
          loading: false,
          error: true,
          success: false,
        },
      };
    default:
      return state;
  }
};

export default persons;
