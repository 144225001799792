import React, { Component } from 'react';
import './GroupTrends.sass';
import 'react-table/react-table.css';
import Button from '../../../components/common/Button';
import { I18n, i18nReducer } from 'react-redux-i18n';
import Dropdown from '../../../components/common/Dropdown';
import ReactTable from 'react-table';
import Loader from '../../../components/common/Loader';
import ProgressBar from '../../../components/common/ProgressBar/ProgressBar';
import TableLoader from '../../../components/common/TableLoader/TableLoader';
import { GeneratePdfHtml } from './GeneratePdfHtml/GeneratePdfHtml';
import { Tabs, Tab, Thumbnail } from 'react-bootstrap';
import ToolboxColumn from '../../../components/common/ToolboxColumn/ToolboxColumn';
import dayjs from 'dayjs';
import { result } from 'lodash';
import NewIndicator from '../../../components/common/NewIndicator/NewIndicator';
import ExportExcelModal from '../../../components/common/ExportExcelModal/ExportExcelModal';
import MainModal from '../../../components/common/MainModal';
import Tooltip from '../../../components/common/Tooltip';
import LargeTextTooltip from '../../../components/common/LargeTextTooltip';
// const html2PDF = require('jspdf-html2canvas');

class GroupTrends extends Component {
  constructor(props) {
    super(props);

    this.state = {
      screen: 'capabilities',
      changeTab: false,
      values: {
        capabilities: { inputValue: [] },
        jobs: { inputValue: [] },
      },
      fetch: false,
      generatePdf: false, // state boolean that triggers GeneratePdfHtml component render
      sorted: {
        id: -1,
        desc: false,
      },
      allItems: [
        { key: 'Competencies', title: I18n.t('toolbox.competencies') },
        { key: 'Jobs', title: I18n.t('toolbox.jobs') },
      ],
      selectedTab: 'Competencies',
      selectedPersons: [],
      selectedJobs: [],
      selectedSkills: [],
      exportExcelModalShow: false,
      sorteditem: 0,
      order: 'desc',
      orderDesc: true,
      scroll: false,
      compatibilities: false,
    };
    this.orderCompatibilitiesAscDesc =
      this.orderCompatibilitiesAscDesc.bind(this);
  }

  componentDidMount() {
    this.props.cleanToolboxFilter();
    this.props.filterPersons({
      start_range: dayjs().subtract(5, 'years').format('YYYY-MM-DDT00:00:00Z'),
      end_range: dayjs().format('YYYY-MM-DDT00:00:00Z'),
      page_size: 1000,
      statusCompleted: true,
    });
    this.props.updateJobsSearchFilter();
    this.props.getJobs(true);
    this.props.getAllProfiles({});

    this.props.getAllCampaigns({
      //partner_id: this.props.user.branch.id,
      campaign_dropdown: true,
    });

    if (this.props.user.user_admin) {
      this.props.getAllClients(true, false, true);
      this.props.getBranchs(null);
    }
    if (this.props.user.account?.id) {
      this.props.getAllClients(true, false, true);
      this.props.getBranchs({
        account_id: this.props.user.account.id,
      });
    }

    this.props.getProfileSkills();
    const urlSearchParams = new URLSearchParams(
      this.props.history.location.search,
    );
    if (!urlSearchParams.has('selected-items')) {
      this.props.clearSelection();
    } else {
      let newArray = [];

      this.props.selection?.map((item) => {
        if (item.profile.id !== 27) {
          newArray.push({
            id: item.user_id,
            title: item.full_name,
            subtitle: {
              description: item.profile?.[`name_${this.props.language}`],
              code: item.profile?.id,
            },
            caption: {
              description: item?.campaign?.name,
              code: item?.campaign?.id,
            },
            id_results: item?.id_result,
            id_invitation: item?.id_invitation,
          });
        }
      });

      this.setState({ ...this.state, selectedPersons: newArray });
    }
  }

  componentDidUpdate() {
    if (this.state.fetch) {
      this.props.selectSkill();
      if (this.state.selectedTab === 'Competencies') {
        this.props.getProfileSkillsRanges(
          this.state.selectedSkills.map((skill) => skill.id),
          false,
        );
      }
      if (this.state.selectedTab === 'Jobs') {
        this.props.getJobCompatibility(
          this.state.selectedJobs.map((job) => job.id),
        );
      }
      this.setState({ ...this.state, fetch: false });
    }

    if (this.state.generatePdf) {
      setTimeout(() => {
        const element = document.getElementById('generatePdf-elementToPrint');
        const fileName =
          I18n.t('persons.emailReport.file.report') +
          (this.state.screen === 'capabilities'
            ? I18n.t('persons.emailReport.file.competencies')
            : I18n.t('persons.emailReport.file.jobs')) +
          '.pdf';
        // html2pdf(element, {
        //     filename: I18n.t('persons.emailReport.file.report') + ((this.state.screen === 'capabilities') ?
        //         I18n.t('persons.emailReport.file.competencies')
        //         :
        //         I18n.t('persons.emailReport.file.jobs')) + '.pdf',
        //     image: { type: 'jpeg', quality: 0.98 },
        //     html2canvas: { scale: 2 },
        //     jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' }
        // });
        // html2PDF(element, {
        //   jsPDF: {
        //     format: 'a4',
        //   },
        //   imageType: 'image/png',
        //   output: fileName,
        // });
        this.setState({ ...this.state, generatePdf: false });
      }, 500);
    }
  }

  componentWillUnmount() {
    const nextLocation = this.props.history.location.pathname;
    if (nextLocation !== '/main/persons' && nextLocation !== '/')
      this.props.clearSelection();
  }

  addOption = (type, name, option) => {
    if (type === 'add' && this.state.values[name].inputValue.length >= 4)
      return;
    let newArray;
    if (type === 'add')
      newArray = [
        ...[{ ...option }].concat(this.state.values[name].inputValue),
      ];
    else if (type === 'remove')
      newArray = [
        ...this.state.values[name].inputValue.filter(
          (selectedOption) => selectedOption.code !== option.code,
        ),
      ];
    else newArray = [...this.state.values[name].inputValue];

    newArray.sort((elem1, elem2) => {
      return elem1.code - elem2.code;
    });

    this.setState({
      ...this.state,
      values: {
        ...this.state.values,
        [name]: { ...this.state.values[name], inputValue: newArray },
      },
    });

    if (this.fetchTiemout) clearTimeout(this.fetchTiemout);
    this.fetchTiemout = setTimeout(
      function () {
        this.setState({ ...this.state, fetch: true });
      }.bind(this),
      500,
    );
  };
  processData = (data, limitatedColumns) => {
    const { screen, sorted } = this.state;
    const defaultSorted = sorted;
    const sortedData = data.sort((valueA, valueB) => {
      const getValue = (item) => {
        const sortedId = defaultSorted.id === -1 ? 0 : defaultSorted.id;
        if (screen === 'capabilities') {
          if (typeof defaultSorted.id === 'string') {
            if (defaultSorted.id === 'name')
              return item.first_name + ' ' + item.last_name;
            if (defaultSorted.id === 'profile') return item.result_profile;
            return item.first_name + ' ' + item.last_name;
          }
          if (item.skills_range[sortedId] === undefined) return null;
          else return item.skills_range[sortedId].min;
        } else {
          if (typeof defaultSorted.id === 'string') {
            if (defaultSorted.id === 'name')
              return item.first_name + ' ' + item.last_name;
            if (defaultSorted.id === 'profile') {
              const match = item.profile_match.filter(
                (match) => match.profile_one.id === item.profile_id,
              )[0];
              return match ? match.profile_one.name : null;
            }
            return item.first_name + ' ' + item.last_name;
          }
          const columnSelected =
            defaultSorted.id === -1
              ? limitatedColumns[0]
              : limitatedColumns.find((col) => col.code === defaultSorted.id);
          const match = item.profile_match.filter(
            (match) =>
              match &&
              columnSelected &&
              match.profile_one.id === item.profile_id &&
              match.profile_two.id === columnSelected.profile,
          )[0];
          if (!match) return null;
          return match.value;
        }
      };
      const a = getValue(valueA);
      const b = getValue(valueB);
      if (typeof defaultSorted.id === 'string') {
        if (defaultSorted.desc) {
          if (a !== null && b !== null) return b.localeCompare(a);
          else if (a !== null) return 1;
          else return -1;
        } else {
          if (a !== null && b !== null) return a.localeCompare(b);
          else if (a !== null) return -1;
          else return 1;
        }
      } else {
        if (defaultSorted.desc) {
          if (a !== null && b !== null) return a - b;
          else if (a !== null) return 1;
          else return -1;
        } else {
          if (a !== null && b !== null) return b - a;
          else if (a !== null) return -1;
          else return 1;
        }
      }
    });
    return sortedData;
  };

  handleGenerateExcel = async (name, option) => {
    // const { screen } = this.state;
    // const all =
    //   this.state.screen === 'capabilities'
    //     ? this.props.profileSkills.map((skill) => ({
    //         code: skill.id,
    //         description: skill.name,
    //       }))
    //     : this.props.jobs.map((job) => ({
    //         code: job.id,
    //         description: job[`name_${this.props.language}`],
    //         profile: job.profile.id,
    //       }));
    // const selected = this.state.values[this.state.screen].inputValue;
    // const values = option.code === 'all' ? all : selected;
    // let columns = [
    //   { description: I18n.t('persons.first_name') },
    //   { description: I18n.t('persons.profile') },
    //   ...values,
    // ];
    // const tableData =
    //   columns.length > 0
    //     ? this.state.screen === 'capabilities'
    //       ? option.code === 'all'
    //         ? this.props.allProfileSkillsRanges
    //         : this.props.profileSkillsRanges
    //       : option.code === 'all'
    //       ? this.props.allJobCompatibility
    //       : this.props.jobCompatibility
    //     : [];
    // const processData = this.processData(tableData, values);
    ////////////////////////////////////////////////////////////////
    // let ids = [];
    // let capabilities = false;
    // if (this.state.screen === 'capabilities') {
    //   if (option.code === 'all') {
    //     ids = this.props.profileSkills.map((i) => i.id);
    //   } else {
    //     ids = this.state.values.capabilities.inputValue.map(
    //       (skill) => skill.code,
    //     );
    //   }
    //   capabilities = true;
    // } else {
    //   if (option.code === 'all') {
    //     ids = this.props.jobs.map((i) => i.id);
    //   } else {
    //     ids = this.state.values.jobs.inputValue.map((job) => job.code);
    //   }
    // }
    let ids = [];
    let capabilities = this.state.selectedTab === 'Competencies' ? true : false;
    if (name === 'selected') {
      if (
        this.state[
          `selected${this.state.selectedTab === 'Jobs' ? 'Jobs' : 'Skills'}`
        ].length > 0
      ) {
        ids = this.state[
          `selected${this.state.selectedTab === 'Jobs' ? 'Jobs' : 'Skills'}`
        ].map((i) => i.id);
      }
    }
    if (name === 'all') {
      ids = this.props[
        `${this.state.selectedTab === 'Jobs' ? 'jobs' : 'profileSkills'}`
      ].map((i) => i.id);
    }
    this.props.exportTrend(ids, capabilities);
  };
  handleBackendFilter = async (filter) => {
    let branchList = filter?.branchs?.code;

    if (
      filter?.partner_ids &&
      (!filter?.branchs || filter?.branchs?.code === -1)
    ) {
      let branchs_x_partner = this.props.branchs.filter(
        (b) => b.nwm_accountId === filter.partner_ids.code,
      );
      branchList = branchs_x_partner.map((branch) => branch.id);
    }

    if (this.props.user.user_admin) {
      let partner_id_ = null;

      if (!filter?.partner_ids) {
        this.props.getBranchs(null);
      } else {
        partner_id_ = filter.partner_ids.code;
        this.props.getBranchs({
          account_id: filter?.partner_ids.code,
        });
      }
      this.props.getAllCampaigns({
        queryPartners: branchList,
        campaign_dropdown: true,
        partner_id: partner_id_,
      });
    }
    if (this.props.user.account?.id) {
      if (!filter?.branchs || filter?.branchs?.code === -1) {
        let branchs_x_partner = this.props.branchs.filter(
          (b) => b.nwm_accountId === this.props.user.account?.id,
        );
        branchList = branchs_x_partner.map((branch) => branch.id);
      }
      this.props.getBranchs({
        account_id: this.props.user.account.id,
      });

      this.props.getAllCampaigns({
        queryPartners: branchList,
        campaign_dropdown: true,
        partner_id: this.props.user.account?.id,
      });
    }

    await this.props.filterPersons({
      start_range: dayjs().subtract(5, 'years').format('YYYY-MM-DDT00:00:00Z'),
      end_range: dayjs().format('YYYY-MM-DDT00:00:00Z'),
      campaigns: filter?.caption?.code,
      profiles: filter?.subtitle?.code,
      partner_ids: filter?.partner_ids?.code,
      branchs: branchList,
      search: filter?.title,
      page_size: filter?.page_size || 1000,
      statusCompleted: true,
    });
  };
  handleSelect = async (key, value, items) => {
    //defino una variable para chequear si esta o no seleccionado
    let valuesToRemove;
    let itemExist;
    let allItems;
    if (key === 'selectedPersons') {
      allItems = 'persons';
    }
    if (key === 'selectedJobs') allItems = 'jobs';
    // chequeo si existe en el estado
    itemExist = this.state[key]?.find((item) => {
      return item.id_results
        ? item?.id_results === value?.id_results
        : item.id === value.id;
    });
    // si no esta: la agrego si esta: lo saco
    if (value === 'remove-all') {
      if (this.state[key][0].id_results) {
        valuesToRemove = this.state[key];
      }
      this.setState(
        {
          ...this.state,
          [key]: [],
        },
        () => {
          if (valuesToRemove && valuesToRemove[0]?.id_results) {
            valuesToRemove?.map((val) => {
              this.props.addRemovePersonToSelection({
                id_result: val.id_results,
                id_invitation: val.id_invitation,
              });
            });
          }
        },
      );
    }
    if (value === 'add-all') {
      this.setState(
        {
          ...this.state,
          [key]: items,
          fetch: true,
        },
        () => {
          if (key === 'selectedPersons') {
            //borro los actualmente seleccionados para agregar a todos luego
            this.props.selection.map((itemToRemove) => {
              this.props.addRemovePersonToSelection({
                id_result: itemToRemove.id_results,
                id_invitation: itemToRemove.id_invitation,
              });
            });
            this.state[key].map((item) => {
              this.props.addRemovePersonToSelection({
                id_result: item.id_results,
                id_invitation: item.id_invitation,
              });
            });
          }

          this.setState({
            ...this.state,
            fetch: true,
          });
        },
      );
    }
    if (itemExist === undefined && typeof value !== 'string') {
      if (value.id_results) {
        this.props.addRemovePersonToSelection({
          id_result: value.id_results,
          id_invitation: value.id_invitation,
        });
      }
      this.setState(
        {
          ...this.state,
          [key]: [...this.state[key], value],
          fetch: value.id_result ? false : true,
        },
        // () => {
        //   if (value.id_results) {
        //     this.props.addRemovePersonToSelection({
        //       id_result: value.id_results,
        //       id_invitation: value.id_invitation,
        //     });

        //     this.setState({ ...this.state, fetch: true }, () => {});
        //   }
        // },
      );
    }
    if (itemExist !== undefined && typeof value !== 'string') {
      if (value.id_results) {
        this.props.addRemovePersonToSelection({
          id_result: itemExist.id_results,
          id_invitation: itemExist.id_invitation,
        });
      }
      this.setState(
        {
          ...this.state,
          fetch: true,
          [key]: this.state[key].filter((item) =>
            item.id_results
              ? item.id_results !== value.id_results
              : item.id !== value.id,
          ),
        },
        // () => {
        //   this.setState({
        //     ...this.state,
        //     fetch: true,
        //   });
        // },
      );
    }
  };
  modalShow = () => {
    if (this.state.exportExcelModalShow === false) {
      this.setState({
        ...this.state,
        exportExcelModalShow: true,
      });
    } else {
      this.setState({
        ...this.state,
        exportExcelModalShow: false,
      });
    }
  };
  sortCompatibilities = (id) => {
    this.setState({
      ...this.state,
      sorteditem: id,
      orderDesc: !this.state.orderDesc,
    });
  };
  updatePageSize = async (pageSize) => {
    return await this.props.updatePageSize(pageSize);
  };
  tooltipMessage = (selectedTab) => {
    return (
      <>
        {I18n.t(`toolbox.tooltip${selectedTab}`)}
        {selectedTab === 'Competencies' && (
          <>
            <b className="font-bold">{I18n.t('toolbox.boldedWords')}</b>{' '}
            {I18n.t('toolbox.tooltipCompetenciesNextPart')}
          </>
        )}
      </>
    );
  };
  orderCompatibilitiesAscDesc = (items, orderDesc) => {
    if (orderDesc) {
      return items.sort(
        (a, b) =>
          b.values?.find(
            (v) =>
              v[
                `${
                  this.state.selectedTab === 'Jobs' ? 'position_id' : 'skill_id'
                }`
              ] == this.state.sorteditem,
          )?.value -
          a.values?.find(
            (v) =>
              v[
                `${
                  this.state.selectedTab === 'Jobs' ? 'position_id' : 'skill_id'
                }`
              ] == this.state.sorteditem,
          )?.value,
      );
    } else {
      return items.sort(
        (a, b) =>
          a.values?.find(
            (v) =>
              v[
                `${
                  this.state.selectedTab === 'Jobs' ? 'position_id' : 'skill_id'
                }`
              ] == this.state.sorteditem,
          )?.value -
          b.values?.find(
            (v) =>
              v[
                `${
                  this.state.selectedTab === 'Jobs' ? 'position_id' : 'skill_id'
                }`
              ] == this.state.sorteditem,
          )?.value,
      );
    }
  };

  dropDownClean = () => {
    this.props.cleanToolboxFilter();
    this.props.filterPersons({
      start_range: dayjs().subtract(5, 'years').format('YYYY-MM-DDT00:00:00Z'),
      end_range: dayjs().format('YYYY-MM-DDT00:00:00Z'),
      page_size: 1000,
      statusCompleted: true,
    });
    this.props.getJobs(true);
    this.props.getAllProfiles({});
    this.props.getAllCampaigns({
      partners: null,
      campaign_dropdown: true,
    });

    if (this.props.user.user_admin) {
      this.props.getAllClients(true, false, true);
      this.props.getBranchs(null);
    }
    if (this.props.user.account?.id) {
      this.props.getAllClients(true, false, true);
      this.props.getBranchs({
        account_id: this.props.user.account.id,
      });
    }
  };

  render() {
    const { screen, selectedTab } = this.state;
    const { capabilities, jobs } = this.state.values;
    const { loading } = this.props.createStatus;
    let compatibilities = false;
    if (
      this.state.selectedPersons.length &&
      this.state.selectedTab === 'Jobs' &&
      this.state.selectedJobs.length
    ) {
      compatibilities = this.state.selectedJobs;
    }
    if (
      this.state.selectedPersons.length &&
      this.state.selectedTab === 'Competencies' &&
      this.state.selectedSkills.length
    ) {
      compatibilities = this.state.selectedSkills;
    }
    return (
      <>
        {this.state.exportExcelModalShow && (
          <ExportExcelModal
            show={this.state.exportExcelModalShow}
            action={this.handleGenerateExcel}
            onHide={this.modalShow}
            selectedTab={this.state.selectedTab}
            jobOrSkillSelected={
              this.state.selectedTab === 'Jobs'
                ? this.state.selectedJobs.length > 0
                  ? true
                  : false
                : this.state.selectedSkills.length > 0
                ? true
                : false
            }
          />
        )}

        <Tabs
          defaultActiveKey={this.state.selectedTab}
          id="uncontrolled-tab-example"
          value={this.state.selectedTab}
          className="compatibilities-and-jobs-container"
          onSelect={(option) => {
            this.setState({
              ...this.state,
              selectedTab: option,
            });
          }}
        >
          <div key={this.state.changeTab}>
            {this.renderHeader(this.state.changeTab)}
          </div>
          <Tab
            eventKey={this.state.allItems[0].key}
            title={this.state.allItems[0].title}
            className="tab-variables-container"
          >
            {this.state.selectedTab === this.state.allItems[0].key && (
              <>
                {this.renderPersons()}
                {this.renderSkills()}
                {this.renderResults(compatibilities, loading)}
              </>
            )}
          </Tab>
          <Tab
            eventKey={this.state.allItems[1].key}
            title={this.state.allItems[1].title}
            className="tab-variables-container"
          >
            {this.state.selectedTab === this.state.allItems[1].key && (
              <>
                {this.renderPersons()}
                {this.renderPositions()}
                {this.renderResults(compatibilities, loading)}
              </>
            )}
          </Tab>
        </Tabs>
      </>
    );
  }

  renderProfileSkillsDropdown() {
    const { loading, error } = this.props.getProfileSkillsRangesStatus;
    if (loading) return <Loader />;
    if (error)
      return (
        <div className="text-warning">
          {I18n.t('persons.groupTrends.error.getJobs')}
        </div>
      );
    return (
      <Dropdown
        multiSelect
        name="jobs"
        key={'jobs'}
        value={this.state.values.jobs}
        addOption={this.addOption}
        placeholder={I18n.t('persons.selectJobs')}
        options={this.props.jobs.map((job) => ({
          code: job.id,
          description: job.name,
          profile: job.profile.id,
        }))}
      />
    );
  }

  renderJobCompatibilityDropdown() {
    const { loading, error } = this.props.jobsStatus;
    if (loading) return <Loader />;
    if (error)
      return (
        <div className="text-warning">
          {I18n.t('persons.groupTrends.error.getJobs')}
        </div>
      );
    return (
      <Dropdown
        multiSelect
        value={this.state.values.capabilities}
        addOption={this.addOption}
        name="capabilities"
        key={'capabilities'}
        placeholder={I18n.t('persons.selectProfiles')}
        options={this.props.profileSkills.map((skill) => ({
          code: skill.id,
          description: skill[`name_${this.props.language}`],
        }))}
      />
    );
  }

  renderPersons = () => {
    if (this.props.persons) {
      return (
        <ToolboxColumn
          className="persons"
          title={
            <span className="column-title">{I18n.t('persons.identifier')}</span>
          }
          customFilter={this.props.customFilter}
          selected={this.state.selectedPersons}
          handleSelect={this.handleSelect}
          getAllClients={({ account_dropdown, view_branch, view_account }) => {
            this.props.getAllClients(
              account_dropdown,
              view_branch,
              view_account,
            );
          }}
          getBranchs={this.props.getBranchs}
          allClients={this.props.allClients}
          branchs={this.props.branchs}
          totalCount={this.props.totalCount}
          loading={this.props.retrieveFilter.loading || false}
          items={this.props.persons?.map((person) => ({
            id: person?.id,
            title: `${person.user_player?.user?.first_name} ${person?.user_player?.user?.last_name}`,
            subtitle: {
              description:
                person?.result?.nwm_result_distance_match?.profile[
                  `name_${this.props.language}`
                ],
              code: person?.result?.nwm_result_distance_match?.profile.id,
            },
            caption: {
              description: person?.campaign?.name,
              code: person?.campaign?.id,
            },
            id_results: person?.result?.id,
            id_invitation: person.id,
          }))}
          campaigns={this.props.allCampaigns.map((campaign) => ({
            caption: {
              code: campaign.id,
              description: campaign.name,
            },
          }))}
          profiles={this.props.allProfiles.map((profile) => {
            if (profile.id !== 27) {
              return {
                subtitle: {
                  code: profile.id,
                  description: profile.name,
                },
              };
            }
          })}
          name={'selectedPersons'}
          fetch={this.handleBackendFilter}
          backendFetch
          updateToolboxFilter={this.props.updateToolboxFilter}
          toolboxFilter={this.props.toolboxFilterPersons}
          isAdmin={this.props.user?.user_admin ? true : false}
          isUserAccount={this.props.user?.account?.id ? true : false}
          dropDownClean={this.dropDownClean}
        />
      );
    } else {
      return <Loader />;
    }
  };

  renderSkills = () => {
    return (
      <ToolboxColumn
        className="skills"
        infiniteScroll={false}
        title={this.state.allItems[0].title}
        headerIcon={
          <Tooltip
            message={this.tooltipMessage(this.state.selectedTab)}
            translate={false}
            materialIcon="contact_support"
            className={`tooltip-on-${this.state.selectedTab}`}
          />
        }
        items={this.props.profileSkills.map((job) => ({
          id: job.id,
          title: job[`name_${this.props.language}`],
          description: job[`description_${this.props.language}`],
        }))}
        selected={this.state.selectedSkills}
        handleSelect={this.handleSelect}
        name={'selectedSkills'}
        updateToolboxFilter={this.props.updateToolboxFilter}
        toolboxFilter={this.props.toolboxFilterSkills}
        profiles={this.props.allProfiles.map((profile) => {
          if (profile.id !== 27) {
            return {
              subtitle: {
                code: profile.id,
                description: profile.name,
              },
            };
          }
        })}
        dropDownClean={this.dropDownClean}
      />
    );
  };

  renderResults = (results, loading) => {
    this.changeKeyToPagination();
    return (
      <div
        className={`results results-${this.state.selectedTab}`}
        id="container"
        key={this.state.selectedTab}
      >
        {loading && <Loader />}
        {!loading && results.length ? (
          results.map((result) => {
            return (
              <div className="result-column">
                <div
                  className={`result-title ${
                    result.id === this.state.sorteditem ? 'active' : ''
                  } `}
                  key={'result-' + result.id}
                >
                  <div
                    onClick={() => this.sortCompatibilities(result.id)}
                    className="first-item"
                  >
                    <div
                      className="text"
                      id={`title-result-${result.id}`}
                      data-tooltip-id={`title-result-tooltip-${result.id}`}
                      data-tooltip-content={result.title}
                    >
                      {result.title}
                    </div>
                    <LargeTextTooltip
                      id={`title-result-tooltip-${result.id}`}
                      idToOverflow={`title-result-${result.id}`}
                    />
                    <div class="material-symbols-rounded">
                      arrow_drop_
                      {this.state.orderDesc ? 'down' : 'up'}
                    </div>
                  </div>
                  <div
                    class="material-symbols-rounded"
                    onClick={() =>
                      this.handleSelect(
                        this.state.selectedTab === 'Jobs'
                          ? 'selectedJobs'
                          : 'selectedSkills',
                        result,
                      )
                    }
                  >
                    close
                  </div>
                </div>
                <div className="job-body">
                  {this?.orderCompatibilitiesAscDesc(
                    this.props[
                      `${
                        this.state.selectedTab === 'Jobs'
                          ? 'jobCompatibility'
                          : 'profileSkillsRanges'
                      }`
                    ],
                    this.state.orderDesc,
                  )?.map((results, key) => {
                    return (
                      <div className="person-card">
                        <div className="person-card-first-element">
                          <div>
                            <div className="person-name">
                              {results.full_name}{' '}
                            </div>
                            <div className="person-profile">
                              {results.profile_name}
                            </div>
                          </div>
                          <div
                            class="material-symbols-rounded"
                            onClick={() =>
                              this.handleSelect('selectedPersons', {
                                ...results,
                                id: results.id_invitation,
                                title: results.full_name,
                                subtitle: {
                                  code: key,
                                  description: results.profile_name,
                                },
                              })
                            }
                          >
                            close
                          </div>
                        </div>
                        <ProgressBar
                          value={
                            results?.values?.find(
                              (r) =>
                                r[
                                  `${
                                    this.state.selectedTab === 'Jobs'
                                      ? 'position_id'
                                      : 'skill_id'
                                  }`
                                ] === result.id,
                            )?.value
                          }
                          long={10}
                          // label={'s'}
                          // name={'s'}
                          compatibilities
                        />
                      </div>
                    );
                  })}
                  {this.props.retrieveFilter &&
                    (this.props.retrieveFilter === 'true' ? <Loader /> : '')}
                </div>
              </div>
            );
          })
        ) : (
          <div className="not-selected-view">
            <div class="material-symbols-rounded">gpp_maybe</div>

            <p>
              {this.state.selectedPersons.length >= 1
                ? I18n.t('toolbox.personSelected')
                : this.state[
                    `selected${
                      this.state.selectedTab === 'Jobs' ? 'Jobs' : 'Skills'
                    }`
                  ].length >= 1
                ? I18n.t('toolbox.skillsOrJobsSelected')
                : I18n.t('toolbox.notSelected')}
              <span className="bold">
                {this.state.selectedPersons.length >= 1
                  ? I18n.t(
                      `toolbox.personsSelected${this.state.selectedTab}NotSelected`,
                    )
                  : this.state[
                      `selected${
                        this.state.selectedTab === 'Jobs' ? 'Jobs' : 'Skills'
                      }`
                    ].length >= 1
                  ? I18n.t('toolbox.personsNotSelected')
                  : I18n.t(`toolbox.itemsNotSelected${this.state.selectedTab}`)}
              </span>
            </p>
          </div>
        )}
      </div>
    );
  };

  changeKeyToPagination = () => {
    if (!document.querySelector(`.active .results-${this.state.selectedTab}`)) {
      const interval = setInterval(() => {
        const results = document.querySelector(
          `.active .results-${this.state.selectedTab}`,
        );
        if (results) {
          this.setState({ ...this.state, changeTab: !this.state.changeTab });
          clearInterval(interval);
        }
      }, 500);
    }
  };

  renderHeader = (key) => {
    const results = document.querySelector(
      `.active .results-${this.state.selectedTab}`,
    );
    let resultViewWidth;
    let resultRealWidth;
    let resultItemWidth;
    let activeNavigationClass = false;

    if (results) {
      resultViewWidth = results.offsetWidth;
      resultRealWidth = results.scrollWidth;
      if (resultViewWidth < resultRealWidth) activeNavigationClass = true;
      resultItemWidth = document.querySelector(
        '.active .results .result-column',
      )?.offsetWidth;
    }
    const margin = resultItemWidth === 200 ? 20 : 30;

    return (
      <div className="header-right" key={key}>
        <div>
          <Button
            onClick={() => this.modalShow()}
            disabled={this.state.selectedPersons.length > 0 ? false : true}
            className={'secondary text-white p-0'}
            iconButton={true}
            iconClass={'download'}
            text={I18n.t('buttons.download')}
          />
        </div>
        <div
          className={`navigation-container ${
            activeNavigationClass ? 'active' : 'disable'
          }`}
        >
          <div
            onClick={() => {
              results.scrollLeft = 0;
            }}
            class="material-symbols-rounded"
          >
            first_page
          </div>
          <div
            class="material-symbols-rounded"
            onClick={() => {
              results.scrollLeft =
                results.scrollLeft - (resultItemWidth + margin);
            }}
          >
            navigate_before
          </div>
          <div
            class="material-symbols-rounded"
            onClick={() => {
              results.scrollLeft =
                results.scrollLeft + resultItemWidth + margin;
            }}
          >
            navigate_next
          </div>
          <div
            onClick={() => (results.scrollLeft = resultRealWidth)}
            class="material-symbols-rounded"
          >
            last_page
          </div>
        </div>
      </div>
    );
  };

  renderPositions = () => {
    return (
      <ToolboxColumn
        className="positions"
        infiniteScroll={false}
        title={this.state.allItems[1].title}
        headerIcon={
          <Tooltip
            message={this.tooltipMessage(this.state.selectedTab)}
            translate={false}
            materialIcon="contact_support"
            className={`tooltip-on-${this.state.selectedTab}`}
          />
        }
        items={this.props.jobs.map((job) => ({
          id: job.id,
          title: job.name,
          subtitle: job?.profile[`name_${this.props.language}`],
        }))}
        selected={this.state.selectedJobs}
        handleSelect={this.handleSelect}
        name={'selectedJobs'}
        updateToolboxFilter={this.props.updateToolboxFilter}
        toolboxFilter={this.props.toolboxFilterJobs}
        profiles={this.props.allProfiles.map((profile) => {
          if (profile.id !== 27) {
            return {
              subtitle: {
                code: profile.id,
                description: profile.name,
              },
            };
          }
        })}
        dropDownClean={this.dropDownClean}
      />
    );
  };

  /**
   * Renders GeneratePdfHtml with correct props.
   * Renders an overlay in front of GeneratePdfHtml.
   *
   *  - render table.
   *  - click 'generate pdf'.
   *  - loader in front of table.
   *  - pdf file downloads.
   *  - table appears again.
   */
  renderGeneratePdf() {
    let columns = this.state.values[this.state.screen].inputValue;
    const tableData =
      columns.length > 0
        ? this.state.screen === 'capabilities'
          ? this.props.profileSkillsRanges
          : this.props.jobCompatibility
        : [];
    return (
      <div className="generatePdf-container">
        <div className="generatePdf-overlay">
          <Loader />
        </div>
        <GeneratePdfHtml
          id="generatePdf-elementToPrint"
          data={tableData}
          columns={columns}
          screen={this.state.screen} // capabilities or jobs
          profileSkillsRangesStatus={this.props.profileSkillsRangesStatus}
          defaultSorted={this.state.sorted}
        />
      </div>
    );
  }
}

GroupTrends.defaultProps = {};

export default GroupTrends;
