import React, { Component } from 'react';
import './ResetPassword.css';
import Input from '../../../components/common/Input';
import Button from '../../../components/common/Button';
import { I18n } from 'react-redux-i18n';
import _ from 'lodash';
import settings from '../../../settings';
import regex from '../../../utils/regex';
import { notify } from 'react-notify-toast';
import {
  errorMessage,
  successMessage,
  SnackBar,
} from '../../../utils/snackbar';
import queryString from 'query-string';

/*
Update on inputs validation: 
- show error if in state boolean / password is valid = 8 chars && has 1 number && has 1 special char
- when submit, if error in validation -> reset error in send
- when submit, if no error in validation -> componentDidUpdate checsk if send error in prop was 
  recieved -> update send error in stat and reset error in vcalidation
*/
class ResetPasswordScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: {
        new_password: {
          inputValue: '',
          required: true,
          validator: (pass) => regex.passwordRegex.test(pass),
        },
        re_new_password: {
          inputValue: '',
          required: true,
          validator: (pass) =>
            pass === this.state.values.new_password.inputValue,
        },
        uid: { inputValue: '' },
        token: { inputValue: '' },
        lang: { inputValue: this.props.lang },
      },
      success: false,
      passwordCheckError: false,
      passwordSendError: false,
      connection: true,
      errorLogin: true,
    };
  }

  componentDidMount() {
    const result = queryString.parse(this.props.location.search);

    let data = {};
    Object.keys(this.state.values).forEach((key) => {
      data[key] = this.state.values[key].inputValue;
    });
    data = { ...data, token: result.token };
    this.props.validateTokenChangePassword(data);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !_.isEqual(prevProps.resetPasswordStatus, this.props.resetPasswordStatus)
    ) {
      if (this.props.resetPasswordStatus.error) {
        this.setState({
          ...this.state,
          passwordCheckError: false,
          passwordSendError: true,
        });
      } else {
        this.setState({ ...this.state, passwordCheckError: false });
      }
      if (this.props.resetPasswordStatus.success) {
        this.setState({
          ...this.state,
          success: true,
        });
      }
    }
    if (prevState.connection !== this.state.connection) {
      if (this.state.connection === false) {
        this.setState({
          ...this.state,
          errorLogin: false,
        });
      }
    }
  }

  haveConnection() {
    if (window.navigator.onLine) {
      this.setState({
        ...this.state,
        connection: true,
      });
      return true;
    } else {
      this.setState({
        ...this.state,
        connection: false,
        errorLogin: false,
      });
      return false;
    }
  }

  passwordIsValid() {
    return (
      this.state.values.new_password.inputValue ===
        this.state.values.re_new_password.inputValue &&
      regex.passwordRegex.test(this.state.values.new_password.inputValue)
    );
  }

  handleChange = (key, value) => {
    this.setState({
      ...this.state,
      values: {
        ...this.state.values,
        [key]: {
          ...this.state.values[key],
          inputValue: value,
        },
        uid: { inputValue: this.props.url.split('/')[4] },
        token: {
          inputValue: this.props.location.search.replace('?token=', ''),
        },
      },
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let data = {};
    if (this.haveConnection()) {
      Object.keys(this.state.values).forEach((key) => {
        data[key] = this.state.values[key].inputValue;
      });
      if (data.new_password.length > 0) {
        this.props.resetPassword(data);
      } else {
        this.setState({
          ...this.state,
          passwordCheckError: true,
          passwordSendError: false,
        });
      }
    }
  };

  renderSuccess = () => {
    notify.show(
      <SnackBar
        icon={'priority'}
        title={I18n.t('session.resetPassword.successTitle')}
        body={I18n.t('session.resetPassword.success')}
        type="success"
        iconFilled
      />,
      'custom',
      5000,
      successMessage,
    );
    this.setState({
      ...this.state,
      success: false,
    });
  };

  render() {
    const { resetPasswordStatus } = this.props;
    if (this.props.resetPasswordStatus.success && this.state.success) {
      this.renderSuccess();
    }

    return (
      <div className="confirm-screen">
        <div className="card">
          <div
            className="wrapper-title"
            onClick={(e) => {
              e.preventDefault();
              this.props.history.push('/');
            }}
          >
            <span className="material-symbols-rounded">chevron_left</span>
            <h2 className="recover-password-title">
              {I18n.t('login.recoverpassword')}
            </h2>
          </div>
          <form id="confirm-form" onSubmit={this.handleSubmit}>
            <div className="form-body">
              <Input
                label={I18n.t('login.newpassword')}
                type={'password'}
                name={'new_password'}
                change={this.handleChange}
                value={this.state.values.new_password}
                ignoreDirty
                disabled={this.props.resetPasswordStatus.success}
                errorLogin={true}
              />
              <Input
                label={I18n.t('login.repeatPassword')}
                type={'password'}
                name={'re_new_password'}
                change={this.handleChange}
                value={this.state.values.re_new_password}
                ignoreDirty
                disabled={this.props.resetPasswordStatus.success}
                errorLogin={false}
              />
              {this.state.connection === false && (
                <p className="error-message text-warning">
                  {I18n.t('common.connection')}
                </p>
              )}
            </div>
            <div className="form-footer text-center">
              <Button
                className={'primary'}
                translate={true}
                text={I18n.t('buttons.confirm')}
                type={'submit'}
                disabled={
                  !this.passwordIsValid() ||
                  this.props.resetPasswordStatus.success
                }
                loading={resetPasswordStatus.loading}
              />
              <p className="version">{settings.version}</p>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default ResetPasswordScreen;
