import React from 'react';
import Beta from '../Beta/Beta';
import Switch from '../Switch/Switch';
import { I18n } from 'react-redux-i18n';
import './IeCard.sass';
const IeCard = ({ value, onChange, disabled }) => {
  return (
    <div className="ie-container">
      <div className="ie-header">
        <span class="material-symbols-filled">diagnosis</span>
        <h3>{I18n.t('campaigns.ie.title')}</h3>
        <Beta />
        <Switch
          name="ie_game_active"
          value={value}
          onChange={onChange}
          disabled={disabled}
        />
      </div>
      <p
        dangerouslySetInnerHTML={{ __html: I18n.t('campaigns.ie.description') }}
      />
    </div>
  );
};

export default IeCard;
