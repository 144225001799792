import ClientDetailScreen from '../components/ClientDetailScreen/ClientDetailScreen';
import actions from '../../actions/index';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

const mapDispatchToProps = (dispatch) => ({
  getClients: () => dispatch(actions.clients.getClients()),
  getClientGroups: () => dispatch(actions.clients.getClientGroups()),
  getClientTypes: () => dispatch(actions.clients.getClientTypes()),
  updateClient: (logo, client) =>
    dispatch(actions.clients.updateClient(logo, client)),
  createClient: (body) => dispatch(actions.clients.createClient(body)),
  clearUiValue: (type, condition) =>
    dispatch(actions.clients.clearUiValue(type, condition)),
  // uploadLogo: (file) => dispatch(actions.clients.uploadAttachment(file)),
  getLogoUrl: (file) => dispatch(actions.clients.getLogoUrl(file)),
  getClientsScreen: (screen) =>
    dispatch(actions.clients.getClientsScreen(screen)),
  deleteClient: (id) => dispatch(actions.clients.deleteClient(id)),
  updateCredits: (partnerId, amount) =>
    dispatch(actions.clients.updateCredits(partnerId, amount)),
  backFromDetail: (to) => dispatch(actions.clients.backFromDetail(to)),
  updateViewBranch: (bool) => dispatch(actions.clients.updateViewBranch(bool)),
  updateViewBranchInMount: (bool) => {
    dispatch(actions.clients.getClientsScreen('new'));
    dispatch(actions.clients.updateViewBranch(bool));
  },
  cleanTable: () => dispatch(actions.clients.cleanTable()),
});

const calculateAvailableCredits = (state) => {
  if (!state.session.user.account)
    if (
      state.clients.selectedClient.nwm_branch?.length > 1 ||
      state.clients.selectedClient.nwm_branch?.length === 0
    ) {
      const selectedClient = state.clients.selectedClient;
      return selectedClient.credits - selectedClient.credits_assigned;
    } else {
      const selectedAccount = state.clients.selectedClient;
      const selectedClient = state.clients.selectedClient.nwm_branch?.length
        ? state.clients.selectedClient?.nwm_branch[0]
        : state.clients.selectedClient;
      if (state.clients.selectedClient.nwm_branch?.length)
        return (
          selectedClient.credits -
          selectedClient.credits_assigned +
          (selectedAccount.credits - selectedAccount.credits_assigned)
        );
      else return selectedClient.credits - selectedClient.credits_assigned;
    }
  else {
    const selectedClient = state.session.user.partner.credits
      ? state.session.user.partner
      : state.session.user.account;
    return selectedClient.credits - selectedClient.credits_assigned;
  }
};

const affiliateCreditsAvailable = (state) => {
  if (state.session.user.account) {
    const selectedClient = state.clients.selectedClient;
    return selectedClient.credits - selectedClient.credits_assigned;
  }
};

const mapStateToProps = (state) => ({
  users: state.clients.users,
  createStatus: state.clients.createClientStatus,
  retrieveStatus: state.clients.getClientsStatus,
  updateStatus: state.clients.updateClientStatus,
  deleteStatus: state.clients.deleteClientStatus,
  client: state.clients.selectedClient,
  createUserStatus: state.users.createFromPartner,
  client_groups: state.clients.client_groups.results,
  uploadAttachmentStatus: state.clients.uploadAttachmentStatus,
  clientTypes: state.clients.clientTypes,
  clientTypesStatus: state.clients.clientTypesStatus,
  user: state.session.user,
  logo_url: state.clients.logo_url,
  getLogoStatus: state.clients.getLogoStatus,
  clients_screen: state.clients.clients_screen,
  clientId: state.clients.selectedClient.id,
  creditsAvailable: calculateAvailableCredits(state),
  updateCreditsStatus: state.clients.updateCreditsStatus,
  clients: state.clients.clients,
  creditsAcquired: state.clients.selectedClient.credits_acquired,
  credits_user_status: state.clients.credits_user_status,
  isAdmin: state.session.user.user_admin ? true : false,
  isSuperDashboard:
    state.session.user.partner && state.session.user.partner.is_super_dashboard,
  affiliateCreditsAvaible: affiliateCreditsAvailable(state),
  language: state.i18n.locale,
  view_branch_global: state.clients.view_branch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ClientDetailScreen));
