import React, { useEffect, useState } from 'react';
import LanguageSelector from '../../common/LanguageSelector/LanguageSelector';
import './PublicLayout.sass';

const PublicLayout = ({ children }) => {
  const [showFontIcon, setShowFontIcon] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      document.fonts
        .load('12px Material Symbols Rounded', 'help')
        .then((value) => {
          setShowFontIcon(true);
          clearInterval(interval);
        })
        .catch((error) => {});
    }, 300);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="public-layout">
      {!showFontIcon && (
        <style>{`.material-symbols-filled, .material-symbols-rounded { display: none !important; }`}</style>
      )}
      <div className="container-fluid">
        <div className="row">
          {window.location.pathname === '/bapro' ? (
            <div className="bapro-container wrapper-logo">
              <div className="bapro-header">
                <img src="/logo_withoutsessionortext.png" alt="nwmlogo" />
                <img
                  src="/bapro-logo.png"
                  alt="baprologo"
                  className="baprologo"
                />
              </div>
              <h1>Bienvenido a Nawaiam</h1>
            </div>
          ) : (
            <div className="col-md-6 wrapper-logo">
              <img src="/logo_withoutsessionortext.png" alt="nwmlogo" />
            </div>
          )}
          {window.location.pathname !== '/bapro' && (
            <div className="col-md-6 wrapper-language">
              <LanguageSelector />
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-md-12 wrapper-content">
            <div className="children-content">{children}</div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 public-footer">
            <span className="public-footer-span">let's game the change</span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PublicLayout;
