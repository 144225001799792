import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './FilterModal.scss';
import { Modal, Form } from 'react-bootstrap';
import { I18n } from 'react-redux-i18n';
import Button from '../../../components/common/Button';
import Dropdown from '../../../components/common/Dropdown';
import Loader from '../../../components/common/Loader';
import Input from '../../../components/common/Input';
import SquareButton from '../../../components/common/SquareButton';

class FilterModal extends Component {
  static defaultProps;

  constructor(props) {
    super(props);
    this.initializeProps();
  }

  initializeProps() {
    // TODO unificar props.values con props.customFilter.
    Object.keys(this.props.customFilter).forEach((customFilter) => {
      if (Object.keys(this.props.values).indexOf(customFilter) > -1) {
        switch (customFilter) {
          case 'profiles':
          case 'campaigns':
            this.props.values[customFilter] = {
              inputValue: this.props[customFilter]
                .filter((fil) => {
                  return (
                    this.props.customFilter[customFilter].indexOf(fil.id) > -1
                  );
                })
                .map((val) => ({
                  code: val.id,
                  description: val.name,
                })),
            };
            break;
          case 'partners':
            this.props.values[customFilter] = {
              inputValue: this.props[customFilter]
                .filter((fil) => {
                  return (
                    this.props.customFilter[customFilter].indexOf(fil.id) > -1
                  );
                })
                .map((val) => ({
                  code: val.id,
                  description: val.company,
                })),
            };
            break;
          case 'min_age':
          case 'max_age':
            this.props.values[customFilter] = {
              inputValue: {
                code: this.props.customFilter[customFilter],
                description: this.props.customFilter[customFilter],
              },
            };
            break;
          default:
            // "search", "code"
            this.props.values[customFilter] = {
              inputValue: this.props.customFilter[customFilter],
            };
            break;
        }
      }
    });
  }

  handleAge(isMin) {
    const options = [];
    const minValue = this.props.values.min_age.inputValue;
    const maxValue = this.props.values.max_age.inputValue;
    if (isMin && maxValue) {
      for (let index = 16; index < maxValue.code; index++) {
        options.push({ code: index, description: index });
      }
    } else if (!isMin && minValue) {
      for (let index = minValue.code; index < 101; index++) {
        options.push({ code: index, description: index });
      }
    } else {
      for (let index = 16; index < 101; index++) {
        options[index - 16] = { code: index, description: index };
      }
    }

    return options;
  }

  render() {
    const { loading, success, error } = this.props;
    if (loading) {
      return (
        <Modal
          show={this.props.show}
          dialogClassName="filter"
          onHide={this.props.onHide}
        >
          <Modal.Header className={'modal-header'}>
            <h4 className="modal-title">{I18n.t('persons.filterTitle')}</h4>
          </Modal.Header>
          <Modal.Body>
            <Loader />
          </Modal.Body>
        </Modal>
      );
    } else if (error) {
      return (
        <Modal
          show={this.props.show}
          dialogClassName="filter"
          onHide={this.props.onHide}
        >
          <Modal.Header className={'modal-header'}>
            <h4 className="modal-title">{I18n.t('persons.filterTitle')}</h4>
          </Modal.Header>
          <Modal.Body>
            <div className="text-warning">
              {I18n.t('persons.error.getPersons')}
            </div>
          </Modal.Body>
        </Modal>
      );
    } else if (success) {
      return (
        <Modal
          show={this.props.show}
          dialogClassName="filter"
          onHide={this.props.onHide}
        >
          <Modal.Header className={'modal-header'}>
            <h4 className="modal-title">{I18n.t('persons.filterTitle')}</h4>
            <Button
              className={'bg-white no-border text-light p-0'}
              onClick={this.props.onHide}
              iconButton={true}
              iconClass={'fa fa-times'}
            />
          </Modal.Header>
          <Modal.Body className="filter-modal-body">
            {this.renderForm()}
          </Modal.Body>
          <Modal.Footer>
            <Button
              className={'primary'}
              text={I18n.t('buttons.filter')}
              onClick={() => {
                this.props.action();
              }}
            />
          </Modal.Footer>
        </Modal>
      );
    } else
      return (
        <Modal
          show={this.props.show}
          dialogClassName="filter"
          onHide={this.props.onHide}
        >
          <Modal.Header className={'modal-header'}>
            <h4 className="modal-title">{I18n.t('persons.filterTitle')}</h4>
          </Modal.Header>
          <Modal.Body>
            <Loader />
          </Modal.Body>
        </Modal>
      );
  }

  renderForm() {
    const { isAdmin, isSuperPartner } = this.props;
    let profileValues = {
      inputValue: this.props.values.profiles.inputValue.filter(
        (f) => f.code !== 28 && f.code !== 27,
      ),
    };

    return (
      <div>
        <Form onSubmit={this.props.action}>
          <div className="form-column">
            <Dropdown
              label={I18n.t('persons.profile')}
              name={'profiles'}
              value={profileValues}
              multiSelect
              change={this.props.change}
              addOption={this.props.addOption}
              options={this.props.extendedProfiles
                .filter((fil) => fil.id !== 27 && fil.id !== 28)
                .map((profile) => ({
                  code: profile.id,
                  description: profile.name,
                }))}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 10,
              }}
            >
              <SquareButton
                label={I18n.t('persons.inconsistent')}
                className="inconsistent-profile-check"
                name={'profiles'}
                value={this.props.inconsistentProfile}
                setValue={!this.props.inconsistentProfile.inputValue}
                change={(name, setValue, data) => {
                  this.props.addOption(setValue, name, data);
                }}
                data={{ code: 27, description: 'Inconsistente' }}
              />
            </div>
            {(this.props.user.user_admin || isSuperPartner) && (
              <Dropdown
                label={
                  isAdmin
                    ? I18n.t('persons.partnerSlashClient')
                    : I18n.t('persons.filiar')
                }
                name={'partners'}
                value={this.props.values.partners}
                multiSelect
                change={this.props.change}
                addOption={this.props.addOption}
                options={this.props.partners.map((partner) => ({
                  code: partner.id,
                  description: partner.name,
                }))}
              />
            )}
            <Dropdown
              label={I18n.t('campaigns.identifier')}
              name={'campaigns'}
              multiSelect
              addOption={this.props.addOption}
              value={this.props.values.campaigns}
              options={this.props.campaigns.map((campaign) => ({
                code: campaign.id,
                description: campaign.name,
              }))}
            />
          </div>
          <div className="form-column">
            <div className="age-title">
              <label className="input-label">{I18n.t('persons.age')} </label>
            </div>
            <div className="age-row">
              <Dropdown
                value={this.props.values.min_age}
                name={'min_age'}
                options={this.handleAge(true)}
                placeholder={I18n.t('persons.from')}
                change={this.props.change}
              />
              <Dropdown
                value={this.props.values.max_age}
                name={'max_age'}
                options={this.handleAge(false)}
                placeholder={I18n.t('persons.to')}
                change={this.props.change}
              />
            </div>
            <Input
              label={I18n.t('persons.code')}
              name={'code'}
              type={'search'}
              value={this.props.values.code}
              change={this.props.change}
            />
          </div>
        </Form>
      </div>
    );
  }
}

FilterModal.defaultProps = {};
FilterModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
};

export default FilterModal;
