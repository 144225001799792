import { connect } from 'react-redux';
import actions from '../../actions/index';
import CampaignDetailScreen from '../components/CampaignDetailScreen/CampaignDetailScreen';
import { withRouter } from 'react-router';

const mapStateToProps = (state) => ({
  selectedCampaign: state.campaigns.selectedCampaign,
  generateCodeStatus: state.campaigns.generateCodeStatus,
  updateCodeStatus: state.campaigns.updateCodeStatus,
  deleteCodeStatus: state.campaigns.deleteCodeStatus,
  deleteCodesStatus: state.campaigns.deleteCodesStatus,
  reloadSelectedCampaignStatus: state.campaigns.reloadSelectedCampaignStatus,
  downloadTemplate: state.campaigns.downloadTemplate,
  campaignDeleteStatus: state.campaigns.campaignDeleteStatus,
  searchValueClean: state.campaigns.searchValueClean,
  playingCode: state.campaigns.playingCode,
  user_: state.session.user,
});

const mapDispatchToProps = (dispatch) => ({
  generateCode: (id, email) =>
    dispatch(actions.campaigns.generateCode(id, email)),
  updateCode: (campaign, id, code) =>
    dispatch(actions.campaigns.updateCode(campaign, id, code)),
  deleteCode: (campaign_id, code_id) =>
    dispatch(actions.campaigns.deleteCode(campaign_id, code_id)),
  deleteCodes: (ids) => dispatch(actions.campaigns.deleteCodes(ids)),
  reloadSelectedCampaign: () =>
    dispatch(actions.campaigns.reloadSelectedCampaign()),
  clearUiValue: (type, condition) =>
    dispatch(actions.campaigns.clearUiValue(type, condition)),
  downloadTemplate: () => dispatch(actions.campaigns.downloadTemplate()),
  deleteCampaign: (id) => dispatch(actions.campaigns.deleteCampaign(id)),
  getCampaigns: () => dispatch(actions.campaigns.getCampaigns()),
  unselectCampaign: () => dispatch(actions.campaigns.unselectCampaign()),
  cleanSearchValue: (boolean) =>
    dispatch(actions.campaigns.cleanSearchValue(boolean)),
  cleanPlayingCodeError: () =>
    dispatch(actions.campaigns.cleanPlayingCodeError()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(CampaignDetailScreen));
