import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import './Button.css';

class Button extends Component {
  static defaultProps;
  constructor(props) {
    super(props);
    this.state = {
      showPop: false,
    };
  }
  showPopup = () => {
    if (this.props.hiddenPopup) {
      this.props.handleHidden();
      if (this.state.showPop) return;
    }
    this.setState({ showPop: !this.state.showPop });
  };

  render() {
    const { showPop } = this.state;
    const {
      title,
      type,
      hasPopup,
      className,
      disabled,
      text,
      id,
      onClick,
      hiddenPopup,
      popupMessage,
      iconSize,
      iconButton,
      iconClass,
      imgAlt,
      img,
      imgHeight,
      popupUp,
      translate,
      iconRight = false,
      iconFilled = false,
    } = this.props;
    return (
      <button
        title={title}
        type={type}
        className={
          'button ' +
          (hasPopup ? 'popup ' : '') +
          className +
          (disabled ? ' disabled' : '') +
          (text ? '' : '')
        }
        id={id}
        disabled={disabled}
        onMouseEnter={hasPopup && this.showPopup}
        onMouseLeave={hasPopup && this.showPopup}
        onClick={onClick}
      >
        {hasPopup && (
          <div
            className={`${popupUp ? 'popuptext-up' : 'popuptext'} ${
              showPop && !hiddenPopup ? 'show' : ''
            }`}
          >
            <span>{popupMessage}</span>
          </div>
        )}
        {iconButton && !iconRight && (
          <span
            className={`material-symbols-rounded icon-left ${
              iconFilled && 'material-symbols-filled'
            }`}
          >
            {iconClass}
          </span>
        )}
        {img && <img alt={imgAlt} src={img} height={imgHeight} />}
        {translate ? <span>{I18n.t(text)}</span> : <span>{text}</span>}

        {iconButton && iconRight && (
          <span
            className={`material-symbols-rounded icon-right ${
              iconFilled && 'material-symbols-filled'
            }`}
          >
            {iconClass}
          </span>
        )}
      </button>
    );
  }
}

Button.propTypes = {
  text: PropTypes.string,
  style: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  translate: PropTypes.bool,
  type: PropTypes.string,
  imgAlt: PropTypes.string,
  img: PropTypes.string,
  imgHeight: PropTypes.number,
  iconButton: PropTypes.bool,
  iconClass: PropTypes.string,
  hasPopup: PropTypes.bool,
  popupMessage: PropTypes.string,
};

export default Button;
