import React, { useEffect } from 'react';
import { I18n } from 'react-redux-i18n';
import './TablePaginationDrawer.sass';
import Dropdown from '../Dropdown';

const pageSizes = [10, 20, 50];

/**
 *
 *
 * @param {int} totalCount
 * @param {int} currentPage
 * @param {int} pageSize
 * @param {boolean} isOnFirstPage
 * @param {boolean} isOnLastPage
 * @param {int} totalPages
 * @param {f} updatePage
 * @param {f} updatePageSize
 */
const TablePaginationDrawer = (props) => {
  const {
    data: {
      totalCount,
      currentPage,
      pageSize,
      isOnFirstPage,
      isOnLastPage,
      totalPages,
      updatePage,
      updatePageSize,
    },
    table,
    extraComponent,
  } = props;
  // creates an array of numbers
  // const pageNumbers = () => {
  //     let result = [];
  //     for (var i = 1; i < totalPages; i++) result.push(i);
  //     return result;
  // }
  // creates an array of {code, description} with given array of values.
  const generateOptions = (values) => {
    let result = [];
    for (var i = 0; i < values.length; i++) {
      result.push({ code: values[i], description: values[i] });
    }
    return result;
  };

  useEffect(() => {
    document.querySelector('.rt-tbody').scrollTop = 0;
  }, [currentPage]);

  // const handleUpdatePage = (option) => {
  //     if (props.data.currentPage !== option.code)
  //         updatePage(option.code)
  // }

  const handleUpdatePageSize = async (option) => {
    if (props.data.pageSize !== option.code) {
      await updatePageSize(option.code);
      // await updatePage(1);
    }
  };
  return (
    totalCount > 0 && (
      <div className="paged-drawer table-pagination-container">
        {extraComponent && extraComponent}
        <div className="table-pagination-text">
          <span>
            {totalCount > 0
              ? I18n.t(`${table}.table.pagedMessage`, {
                  pageStart: (currentPage - 1) * pageSize + 1 || 0,
                  pageEnd:
                    (currentPage * pageSize > totalCount
                      ? totalCount
                      : currentPage * pageSize) || 0,
                  total: totalCount,
                })
              : I18n.t('campaigns.table.pagedMessageEmpty')}
          </span>
        </div>
        <div className="table-pagination-buttons-container">
          <div className="table-paginations-buttons-alt">
            {/* Removed by client */}
            {/*<div>*/}
            {/*<p>{I18n.t(`${table}.table.goTo`)}</p>*/}
            {/*/!* TODO: style of same page as selected or not show in options *!/*/}
            {/*<Dropdown*/}
            {/*value={{inputValue: {code: currentPage, description: currentPage}}}*/}
            {/*options={generateOptions(pageNumbers(totalPages))}*/}
            {/*change={(name, option) => handleUpdatePage(option)}*/}
            {/*gray*/}
            {/*/>*/}
            {/*</div>*/}
            <div>
              {/* <p>{I18n.t(`${table}.table.perPage`)}</p> */}
              {/* TODO: style of same page as selected or not show in options */}
              <Dropdown
                value={{
                  inputValue: {
                    code: pageSize,
                    description: (
                      <div>
                        <span className="page-size">{pageSize}</span>
                        <span class="material-symbols-rounded">
                          expand_more
                        </span>
                      </div>
                    ),
                  },
                }}
                options={generateOptions(pageSizes)}
                change={(name, option) => handleUpdatePageSize(option)}
                gray
              />
            </div>
          </div>
          <div className="table-paginations-buttons-navigation">
            <button onClick={() => (!isOnFirstPage ? updatePage(1) : null)}>
              <span class="material-symbols-rounded">first_page</span>
            </button>
            <button
              onClick={() =>
                !isOnFirstPage ? updatePage(currentPage - 1) : null
              }
            >
              <span class="material-symbols-rounded">chevron_left</span>
            </button>
            <div className="pages-container">
              {totalPages === 1 && (
                <>
                  <button
                    className="current-page"
                    onClick={() => console.log(currentPage)}
                  >
                    <span className="active-page">{currentPage}</span>
                  </button>
                </>
              )}
              {totalPages === 2 && (
                <>
                  {isOnFirstPage ? (
                    <button
                      className="current-page"
                      onClick={() =>
                        !isOnLastPage ? updatePage(currentPage) : null
                      }
                    >
                      <span className="active-page">{currentPage}</span>
                    </button>
                  ) : (
                    <button
                      className="current-page"
                      onClick={() => updatePage(currentPage - 1)}
                    >
                      <span className="">{currentPage - 1}</span>
                    </button>
                  )}
                  {isOnLastPage ? (
                    <button
                      className="current-page"
                      onClick={() =>
                        !isOnLastPage ? updatePage(currentPage) : null
                      }
                    >
                      <span className="active-page">{currentPage}</span>
                    </button>
                  ) : (
                    <button
                      className="current-page"
                      onClick={() =>
                        !isOnLastPage ? updatePage(currentPage + 1) : null
                      }
                    >
                      <span className="">{currentPage + 1}</span>
                    </button>
                  )}
                </>
              )}
              {totalPages >= 3 && currentPage <= totalPages && (
                <>
                  {isOnFirstPage ? (
                    <button
                      className="current-page"
                      onClick={() =>
                        !isOnLastPage ? updatePage(currentPage) : null
                      }
                    >
                      <span className="active-page">{currentPage}</span>
                    </button>
                  ) : isOnLastPage ? (
                    <button
                      className="current-page"
                      onClick={() => updatePage(currentPage - 2)}
                    >
                      <span>{currentPage - 2}</span>
                    </button>
                  ) : (
                    <button
                      className="current-page"
                      onClick={() =>
                        updatePage(!isOnFirstPage && currentPage - 1)
                      }
                    >
                      <span>{!isOnFirstPage && currentPage - 1}</span>
                    </button>
                  )}
                  {isOnFirstPage ? (
                    <button
                      className="current-page"
                      onClick={() =>
                        !isOnLastPage ? updatePage(currentPage + 1) : null
                      }
                    >
                      <span className="">{currentPage + 1}</span>
                    </button>
                  ) : isOnLastPage ? (
                    <button
                      className="current-page"
                      onClick={() => updatePage(currentPage - 1)}
                    >
                      <span className="">{currentPage - 1}</span>
                    </button>
                  ) : (
                    <button
                      className="current-page"
                      onClick={() =>
                        !isOnLastPage ? updatePage(currentPage) : null
                      }
                    >
                      <span className="active-page">{currentPage}</span>
                    </button>
                  )}
                  {isOnFirstPage ? (
                    <button
                      className="current-page"
                      onClick={() =>
                        !isOnLastPage ? updatePage(currentPage + 2) : null
                      }
                    >
                      <span>{currentPage + 2}</span>
                    </button>
                  ) : isOnLastPage ? (
                    <button
                      className="current-page"
                      onClick={() =>
                        !isOnLastPage ? updatePage(currentPage) : null
                      }
                    >
                      <span className="active-page">{currentPage}</span>
                    </button>
                  ) : (
                    <button
                      className="current-page"
                      onClick={() =>
                        !isOnLastPage ? updatePage(currentPage + 1) : null
                      }
                    >
                      <span>{currentPage + 1}</span>
                    </button>
                  )}
                </>
              )}
            </div>
            <button
              onClick={() =>
                !isOnLastPage ? updatePage(currentPage + 1) : null
              }
            >
              <span class="material-symbols-rounded">chevron_right</span>
            </button>
            <button
              onClick={() => (!isOnLastPage ? updatePage(totalPages) : null)}
            >
              <span class="material-symbols-rounded">last_page</span>
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default TablePaginationDrawer;
