import { post, get, webApi } from '../utils/http';
import queryString from 'query-string';

export const services = {
  getDashboardReportAge: (filter) =>
    get(
      webApi +
        `api/results/age-distribution?lang=${localStorage.getItem(
          'language',
        )}&${queryString.stringify(filter)}`,
    ),
  getDashboardReportProfile: (filter) =>
    get(
      webApi +
        `api/results/profiles-distribution?lang=${localStorage.getItem(
          'language',
        )}&${queryString.stringify(filter)}`,
    ),
  getDashboardReportProfileGender: (time, filter) =>
    post(
      webApi +
        `api/stats/profile_gender/${time.toUpperCase()}/?lang=${localStorage.getItem(
          'language',
        )}`,
      // { ...filter },
    ),
  getDashboardReportCity: (filter) =>
    get(
      webApi +
        `api/results/demographic-distribution?lang=${localStorage.getItem(
          'language',
        )}&${queryString.stringify(filter)}`,
    ),
  getLastMonth: () => get(webApi + 'api/results/last-month'),
};
