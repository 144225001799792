import React from 'react';
import Loader from '../../../components/common/Loader/Loader';
import { I18n } from 'react-redux-i18n';

class ActivateScreen extends React.Component {
  componentDidMount = async () => {
    await this.props.activationUser(
      this.props.location.pathname.replace('/activation/', ''),
    );
  };

  render() {
    const { loading, error, success } = this.props.activationState;
    if (loading) return <Loader />;
    else if (error)
      return (
        <div className="text-warning">
          {I18n.t('session.userActivationError')}
        </div>
      );
    else if (success) {
      return (
        <div className="activate-screen">
          <div className="container">
            <div className="row">
              <div className="col-md-4 col-md-offset-4">
                <div className="card">
                  <div class="card-header">
                    <h1 className="message-title">
                      {I18n.t('session.welcome')}
                    </h1>
                  </div>
                  <div class="card-body">
                    <h3 className="message-body">
                      {I18n.t('session.userActivationSuccess')}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default ActivateScreen;
