import actions from '../actions/index';
import {
  services,
  cancelToken,
  setToken,
  cleanToken,
} from './campaigns.services';
import {
  CREATE_CAMPAIGN,
  GET_CAMPAIGNS,
  UPDATE_PAGE,
  UPDATE_PAGE_SIZE,
  DELETE_CODE,
  DELETE_CODES,
  UPDATE_CAMPAIGN,
  DELETE_CAMPAIGN,
  GENERATE_CODE,
  BUY_CREDITS,
  UPDATE_CODE,
  SHARE_CAMPAIGN,
  GET_POSITIONS,
  GET_FIELDS,
  RELOAD_SELECTED_CAMPAIGN,
  GET_ALL_CAMPAIGNS,
  DOWNLOAD_TEMPLATE,
} from './campaigns.actions';
import { parseQueryObject } from '../utils/parser';
import { push } from 'connected-react-router';

const path = require('./../assets/docs/Campaña_directa.csv');

const campaignsMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    switch (action.type) {
      case GET_CAMPAIGNS:
        const actionFilter = { ...getState().campaigns.filterValues };
        const filter = {
          ...actionFilter,
          page: getState().campaigns.currentPage,
          page_size: getState().campaigns.page_size,
        };
        if (action.campaign_id) filter.campaign_id = action.campaign_id;

        services
          .getCampaigns(parseQueryObject(filter))
          .then((data) => {
            dispatch(actions.campaigns.getCampaignsResponse(data));
            dispatch(
              actions.session.getPartner(getState().session.user.branch.id),
            );
          })
          .catch((error) =>
            dispatch(actions.campaigns.getCampaignsError(error)),
          );
        break;

      case GET_ALL_CAMPAIGNS:
        if (typeof cancelToken != typeof undefined) {
          cancelToken.cancel();
        }
        setToken();
        services
          .getAllCampaigns(
            action.partners,
            action.campaign_dropdown,
            action.search,
            action.campaign_id,
          )
          .then((data) => {
            cleanToken();
            dispatch(actions.campaigns.getAllCampaignsResponse(data));
          })
          .catch((error) =>
            dispatch(actions.campaigns.getAllCampaignsError(error)),
          );
        break;

      case UPDATE_PAGE:
        dispatch(actions.campaigns.getCampaigns());
        break;

      case UPDATE_PAGE_SIZE:
        dispatch(actions.campaigns.getCampaigns());
        break;

      case CREATE_CAMPAIGN:
        services
          .createCampaign(action.body)
          .then((data) => {
            dispatch(actions.campaigns.createCampaignResponse(data));
            dispatch(
              actions.session.getPartner(getState().session.user.branch.id),
            );
            dispatch(push('/main/campaigns'));
          })
          .catch((error) =>
            dispatch(actions.campaigns.createCampaignError(error)),
          );
        break;

      case UPDATE_CAMPAIGN:
        services
          .updateCampaign(action.id, action.campaign)
          .then((data) => {
            dispatch(actions.campaigns.updateCampaignResponse(data));
            dispatch(
              actions.session.getPartner(getState().session.user.branch.id),
            );
            dispatch(push('/main/campaigns'));
          })
          .catch((error) =>
            dispatch(actions.campaigns.updateCampaignError(error)),
          );
        break;

      case DELETE_CAMPAIGN:
        services
          .deleteCampaign(action.id)
          .then((data) => {
            dispatch(actions.campaigns.deleteCampaignResponse(data));
            dispatch(actions.campaigns.updatePage(0));
            dispatch(actions.campaigns.unselectCampaign());
            // !! getPartners called in CampaignsTable calls getCampaigns which calls getPartner
            // dispatch(actions.session.getPartner(getState().session.user.partner.id));
          })
          .catch((error) =>
            dispatch(actions.campaigns.deleteCampaignError(error)),
          );
        break;

      /**
       * Codes
       */
      case GENERATE_CODE:
        services
          .generateCode(action.id, action.email)
          .then((data) => {
            dispatch(actions.campaigns.generateCodeResponse(data));
            dispatch(actions.campaigns.reloadSelectedCampaign(action.id));
          })
          .catch((error) => {
            if (
              error.data['non_field_errors'] &&
              error.data['non_field_errors'][0] === 'email_campaign_not_unique'
            )
              return;
            dispatch(actions.campaigns.generateCodeError(error));
          });
        break;

      case UPDATE_CODE:
        services
          .updateCode(action.code_id, action.body)
          .then((data) => {
            dispatch(actions.campaigns.updateCodeResponse(data));
            dispatch(actions.campaigns.reloadSelectedCampaign());
          })
          .catch((error) => dispatch(actions.campaigns.updateCodeError(error)));
        break;

      case DELETE_CODE:
        services
          .deleteCode(action.code_id)
          .then((data) => {
            dispatch(actions.campaigns.deleteCodeResponse(data));
            dispatch(actions.campaigns.reloadSelectedCampaign());
          })
          .catch((error) => dispatch(actions.campaigns.deleteCodeError(error)));
        break;

      case DELETE_CODES:
        services
          .deleteCodes(action.ids)
          .then((data) => {
            dispatch(actions.campaigns.deleteCodesResponse(data));
            dispatch(actions.campaigns.reloadSelectedCampaign());
          })
          .catch((error) => dispatch(actions.campaigns.deleteCodeError(error)));
        break;

      case RELOAD_SELECTED_CAMPAIGN:
        services
          .reloadSelectedCampaign(
            action.id || getState().campaigns.selectedCampaign.id,
          )
          .then((data) =>
            dispatch(actions.campaigns.reloadSelectedCampaignResponse(data)),
          )
          .catch((error) =>
            dispatch(actions.campaigns.reloadSelectedCampaignError(error)),
          );
        break;

      case BUY_CREDITS:
        services
          .buyCredits(action.quantity)
          .then((data) => dispatch(actions.campaigns.buyCreditsResponse(data)))
          .catch((error) => dispatch(actions.campaigns.buyCreditsError(error)));
        break;

      case SHARE_CAMPAIGN:
        services
          .shareCampaign(action.id)
          .then((data) =>
            dispatch(actions.campaigns.shareCampaignResponse(data)),
          )
          .catch((error) =>
            dispatch(actions.campaigns.shareCampaignError(error)),
          );
        break;

      case GET_POSITIONS:
        services
          .getPositions(action.positionDropdown, action.search)
          .then((data) =>
            dispatch(actions.campaigns.getPositionsResponse(data)),
          )
          .catch((error) =>
            dispatch(actions.campaigns.getPositionsError(error)),
          );
        break;

      case GET_FIELDS:
        services
          .getFields()
          .then((data) => dispatch(actions.campaigns.getFieldsResponse(data)))
          .catch((error) => dispatch(actions.campaigns.getFieldsError(error)));
        break;

      case DOWNLOAD_TEMPLATE:
        const downloadName = {
          es: 'Plantilla CSV',
          en: 'CSV Template',
          pt: 'Modelo CSV',
        };
        let link = document.createElement('a');
        link.setAttribute('type', 'hidden');
        link.href = path;
        link.download = `${downloadName[localStorage.getItem('language')]}.csv`;
        document.body.appendChild(link);
        link.click();
        link.remove();
        break;

      //OPTIONS

      default:
        break;
    }
  };

export default campaignsMiddleware;
