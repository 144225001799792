import { deleteRequest, get, patch, post, put, webApi } from '../utils/http';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import queryString from 'query-string';

dayjs.extend(utc);
dayjs.extend(timezone);

export const services = {
  getInfoReports: ({ account, branch }) => {
    return get(
      webApi + `api/reports?${queryString.stringify({ account, branch })}`,
    );
  },
  restoreReports: ({ report }) => {
    return post(webApi + `api/reports?${queryString.stringify({ report })}`);
  },
  updateReport: ({ updateReport, reportType }) => {
    return post(
      webApi +
        `api/reports/update?${queryString.stringify({
          updateReport,
          reportType,
        })}`,
    );
  },
  downloadTestReport: (reportType, test, folder) => {
    let queryObject = {
      folder: folder,
      test: test,
      type: reportType,
    };
    return get(
      webApi +
        `api/results/reports/download?lang=${localStorage.getItem(
          'language',
        )}&${queryString.stringify(queryObject)}`,
      { extra: { responseType: 'blob' } },
    );
  },
};
