import { languagesCodes } from './../i18n/index';

export const BROWSER_LANGUAGE =
  (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  navigator.userLanguage;
export const LANGUAGE =
  localStorage.getItem('language') ??
  languagesCodes.find((lang) => lang === BROWSER_LANGUAGE.substring(0, 2)) ??
  'es';
export const FORM_TYPES = {
  gameRegistration: 'game-registration',
};
