import { connect } from 'react-redux';
import actions from '../../actions/index';
import ReportsDetailScreen from '../components/ReportsDetailScreen/ReportsDetailScreen';
import { withRouter } from 'react-router';

const mapDispatchToProps = (dispatch) => ({
  getInfoReports: ({ account, branch }) => {
    dispatch(actions.reports.getInfoReports({ account, branch }));
  },
  updateReports: ({ updateReport, reportType }) => {
    dispatch(actions.reports.updateReports({ updateReport, reportType }));
  },
  downloadTestReport: (testReport) => {
    console.log('testReport en container', testReport);
    dispatch(actions.reports.downloadTestReport(testReport));
  },
});

const mapStateToProps = (state, ownProps) => ({
  user: state.session.user,
  getInfoReportsResponse: state.reports.getInfoReportsResponse,
  updateReportsStatus: state.reports.updateReportsStatus,
  downloadReportStatus: state.reports.downloadReportStatus
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ReportsDetailScreen));
