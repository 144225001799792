import React, { Component } from 'react';
import './DateRangePickerFilter.css';
import 'react-table/react-table.css';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import actions from '../../../actions';
import Button from '../Button';
import Tooltip from '../Tooltip';
import DropdownMenu from '../Dropdown/DropdownMenu';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import {
  lastWeek,
  lastMonth,
  formatDate,
  equalsDate,
} from '../../../utils/dates';
import localeEs from 'date-fns/locale/es';
import localePt from 'date-fns/locale/es';
import defaultLocale from 'date-fns/locale/en-US';
import dayjs from 'dayjs';

class DateRangePickerFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterValues: {
        dates: [
          {
            startDate: '',
            endDate: '',
            key: 'selection',
          },
        ],
      },
      closeAfter: false,
      hiddenPopup: false,
      query: false,
      openCalendar: false,
      filtered: false,
    };
    this.filterByDates = this.filterByDates.bind(this);
    this.changeCollapse = this.changeCollapse.bind(this);
  }

  solveLanguageCalendar = () => {
    const mapping = {
      es: localeEs,
      pt: localePt,
      en: defaultLocale,
    };
    const lang = localStorage.getItem('language');
    return mapping[lang];
  };

  selectRangeDate = (item) => {
    this.props.getFilterDates({
      start_value: item.selection.startDate,
      end_value: item.selection.endDate,
    });

    this.setState({
      ...this.state,
      filterValues: { ...this.state.filterValues, dates: [item.selection] },
    });
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.resetFilter !== this.props.resetFilter &&
      this.props.resetFilter
    ) {
      this.selectRangeDate({
        selection: {
          startDate: '',
          endDate: '',
          key: 'selection',
        },
      });
    }
  }

  componentDidMount() {
    if (this.props.rangeDefault) {
      this.selectRangeDate({
        selection: {
          startDate: new Date(this.props.rangeDefault.start),
          endDate: new Date(this.props.rangeDefault.end),
          key: 'selection',
        },
      });
    }
    if (typeof this.props.customFilter?.end_range === 'string') {
      this.setState({ ...this.state, query: false });
    }
  }

  componentDidUpdate() {
    if (this.props.resetFilter && this.state.filtered) {
      this.setState({
        filtered: false,
        query: false,
        filterValues: {
          dates: [
            {
              startDate: '',
              endDate: '',
              key: 'selection',
            },
          ],
        },
      });
    }
  }

  restartDefaultDates = () => {
    if (this.props.previewRange) {
      this.setState({
        ...this.state,
        filterValues: {
          dates: [
            {
              startDate: new Date(this.props.previewRange.start),
              endDate: new Date(this.props.previewRange.end),
              key: 'selection',
            },
          ],
        },
        query: false,
      });
    }
  };

  changeCollapse = () => {
    this.setState({ ...this.state, closeAfter: !this.state.closeAfter });
  };

  displayRangeFilter = (date1, date2) => {
    return `${I18n.t('dashboard.from')} ${formatDate(
      date1,
      localStorage.getItem('language'),
    )} ${I18n.t('dashboard.to')} ${formatDate(
      date2,
      localStorage.getItem('language'),
    )}`;
  };

  getPopupMessage = (date1, date2) => {
    if (!date1) return I18n.t('dashboard.lastMonthMessage');
    if (this.isLastWeek(date1, date2)) {
      return I18n.t('dashboard.lastWeekMessage');
    }
    return I18n.t('dashboard.lastMonthMessage');
  };

  isLastMonth = (date1, date2) => {
    const endDate = new Date(this.props.end_date);
    const prevMonth = new Date(this.props.start_date);
    let newdate1 = new Date(date1);
    let newdate2 = new Date(date2);
    return equalsDate(newdate2, endDate) && equalsDate(newdate1, prevMonth);
  };

  isLastWeek = (date1, date2) => {
    const endDate = new Date(this.props.end_date);
    const prevWeek = lastWeek(endDate);
    return equalsDate(date2, endDate) && equalsDate(date1, prevWeek);
  };

  filterByDates = () => {
    this.props.setCreditFilterClear(false);
    const { startDate, endDate: filterEndDate } =
      this.state.filterValues.dates[0];
    const endDate = new Date(this.props.end_date);
    const prevMonth = new Date(this.props.start_date);
    const filterStart = startDate || prevMonth;
    const filterEnd = filterEndDate || endDate;
    const filter = { start_range: filterStart, end_range: filterEnd };
    this.setState({
      filterValues: {
        dates: [
          {
            startDate: dayjs(filter.start_range).toISOString(),
            endDate: dayjs(filter.end_range).toISOString(),
            key: 'selection',
          },
        ],
      },
    });
    this.props.filter(filter);

    this.changeCollapse();
  };
  handleShow = (e) => {
    if (
      e.path.filter((element) => element.id === 'popup-button').length === 0
    ) {
      this.setState({ ...this.state, hiddenPopup: true });
    }
  };
  handleHidden = () => {
    this.setState({ ...this.state, hiddenPopup: false });
  };

  getHistoricalStartDate = () => {
    return new Date(this.props.start_date);
  };

  // handleCalendarOpen = () => {
  //   this.setState({
  //     ...this.state,
  //     isCalendarOpen: !this.state.isCalendarOpen,
  //   });
  // };

  render() {
    const endDate = new Date(
      this.props.credits_end_date ? this.props.credits_end_date : dayjs(),
    );
    const startDate = new Date(
      this.props.start_date
        ? this.props.start_date
        : this.props.credits_start_date,
    );
    const { startDate: startFilter, endDate: endFilter } =
      this.state.filterValues.dates[0];
    const initDate = startFilter ? new Date(startFilter) : startDate;
    const lastDate = endFilter ? new Date(endFilter) : endDate;
    let monthsNumber = 2;
    if (window.innerWidth < 1200) {
      monthsNumber = 1;
    }

    return (
      <div className={`filter-date ${this.props.class}`}>
        <DropdownMenu
          name="filter-home"
          className={`${
            (this.state.filterValues.dates[0].startDate !== '' &&
              this.state.filtered) ||
            this.state.filtered ||
            this.props.didFilter
              ? 'active'
              : ''
          } ${this.state.openCalendar ? 'open-calendar' : ''} date-button`}
          closeAfter={this.state.closeAfter}
          closeAfterAction={this.changeCollapse}
          dropCalendar={{ startDate, initDate, endDate, lastDate }}
          query={this.state.query}
          loading={this.props.dateStatus?.loading}
          label={this.displayRangeFilter(initDate, lastDate)}
          restartState={this.restartDefaultDates}
          resetFilter={this.props.resetFilter}
          resetInCredits={this.props.resetInCredits}
          inCredits={this.props.inCredits}
          filtering={this.props.filtering}
          historyFiltering={this.props.historyFiltering}
          filteringDate={this.props.filteringDate}
          jobDateFiltering={this.props.jobDateFiltering}
          handleCalendarOpen={this.handleCalendarOpen}
          onDashboard={this.props.cities ? true : false}
          onCredits={this.props.onCredits}
          location={this.props.location}
          onClick={() => {
            this.setState({
              ...this.state,
              openCalendar: !this.state.openCalendar,
            });
          }}
          user={this.props.user}
          nestedComponents={[
            <div
              id="wrapper-date-picker-range"
              className="wrapper-date-picker-range"
            >
              <DateRangePicker
                locale={this.solveLanguageCalendar()}
                maxDate={new Date()}
                showSelectionPreview={true}
                onChange={this.selectRangeDate}
                moveRangeOnFirstSelection={false}
                months={monthsNumber}
                inputRanges={[]}
                staticRanges={[
                  {
                    label: I18n.t('dashboard.lastWeek'),
                    range: () => ({
                      startDate: lastWeek(endDate),
                      endDate: endDate,
                    }),
                    isSelected() {
                      return true;
                    },
                  },
                  {
                    label: I18n.t('dashboard.lastMonth'),
                    range: () => ({
                      startDate: lastMonth(endDate),
                      endDate: endDate,
                    }),
                    isSelected() {
                      return true;
                    },
                  },
                  {
                    label: I18n.t('dashboard.all'),
                    range: () => ({
                      startDate: this.getHistoricalStartDate(),
                      endDate: endDate,
                    }),
                    isSelected() {
                      return true;
                    },
                  },
                ]}
                ranges={
                  startFilter
                    ? this.state.filterValues.dates
                    : [
                        {
                          ...this.state.filterValues.dates[0],
                          startDate,
                          endDate,
                        },
                      ]
                }
                direction="horizontal"
                editableDateInputs={false}
                showDateDisplay={false}
              />
              <Button
                className="apply-date-button"
                text={I18n.t('dashboard.filter')}
                onClick={() => {
                  this.filterByDates();
                  this.setState({
                    openCalendar: false,
                    filtered: true,
                    query: true,
                  });
                }}
              />
            </div>,
          ]}
        />
        {!this.props.hiddenPopup ? (
          <Tooltip
            message={this.getPopupMessage(startFilter, endFilter)}
            translate={false}
            materialIcon="contact_support"
            className="dateRangeFilter"
            right
          />
        ) : (
          <div className="space" />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  filterDates: state.common.filterDates,
  user: state.session.user,
});

const mapDispatchToProps = (dispatch) => ({
  getFilterDates: (filterDates) =>
    dispatch(actions.common.getFilterDates(filterDates)),
  setCreditFilterClear: (value) =>
    dispatch(actions.common.setCreditFilterClear(value)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(DateRangePickerFilter));
