import { get, post, put, patch, webApi, _saveToken } from '../utils/http';
import { getUserHref } from '../oauthConfig';
import queryString from 'query-string';

export const services = {
  refreshToken: (token) => post(webApi + `auth/refresh/`, { token }),
  login: (body) =>
    post(webApi + 'api/auth/login', body).then((data) => {
      _saveToken(data.token);
      return data;
    }),
  baproLogin: (body) =>
    post(webApi + 'api/auth/bapro', body).then((data) => {
      _saveToken(data.token);
      return data;
    }),
  googleLogin: (body) =>
    post(webApi + 'api/auth/google/bapro', body).then((data) => {
      _saveToken(data.token);
      return data;
    }),
  getPartner: (id) => get(webApi + `api/companies/company`),
  logout: () => {
    delete localStorage['token'];
    delete localStorage['token-ts'];
  },
  getUsers: () =>
    get(webApi + `api/users/?lang=${localStorage.getItem('language')}`),
  activateToken: (token) => put(webApi + `api/users/activation/${token}/`),
  recoverPassword: (email) =>
    post(webApi + `api/auth/recovery`, {
      email,
      lang: localStorage.getItem('language'),
    }),
  resetPassword: (body) => patch(webApi + `api/auth/recovery`, body),
  validateTokenChangePassword: (data) =>
    get(
      webApi +
        `api/auth/recovery?${queryString.stringify({ token: data.token })}`,
    ),

  open_id_user: (access_token, expires_in = false) => {
    // SAVES COMFAMA TOKEN
    // _saveToken(access_token);
    // var uri = getUserHref();
    // return get(uri, {}, access_token).then((data) => {
    //   data['access_token'] = access_token;
    //   // data['partner_id'] = getPartnerId()
    // });
    // Nuevo login con clientes
    return post(webApi + `api/auth/comfama/validate`, {
      code: access_token,
    });
  },
};
