import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './ChangePasswordModal.css';
import { Modal } from 'react-bootstrap';
import exit from '../../../assets/icons/cross-DARK-32x32.png';
import { I18n } from 'react-redux-i18n';
import Button from '../Button';
import Input from '../Input';
import Tooltip from '../../../components/common/Tooltip';
// import Dropdown from "../Dropdown";

class ChangePasswordModal extends Component {
  static defaultProps;

  render() {
    return (
      <Modal
        show={this.props.show}
        dialogClassName="change-password-modal"
        onHide={this.props.onHide}
        width={{}}
      >
        <Modal.Header className={'modal-header'}>
          <h4 className="modal-title">{I18n.t('users.changePassword')}</h4>
          <img src={exit} height={'30px'} onClick={this.props.onHide} alt="" />
        </Modal.Header>
        <Modal.Body>
          <div style={{ width: '100%' }}>
            <Input
              label={I18n.t('users.password')}
              name="password"
              value={this.props.password}
              change={this.props.change}
              type={'password'}
              tooltip={<div>{this.showTooltip()}</div>}
            />
            <Input
              label={I18n.t('users.repeatPassword')}
              name="repeatPassword"
              value={this.props.repeatPassword}
              change={this.props.change}
              type={'password'}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div style={{ padding: '0 10px' }}>
              <Button
                className="alternative-gray-modal"
                text={I18n.t('buttons.cancel')}
                onClick={this.props.onHide}
              />
            </div>
            <div style={{ padding: '0 10px' }}>
              <Button
                className="primary"
                text={I18n.t('buttons.confirm')}
                onClick={() => {
                  this.props.action(this.props.password.inputValue);
                  this.props.onHide();
                }}
                disabled={
                  !this.props.password.inputValue ||
                  this.props.password.inputValue !==
                    this.props.repeatPassword.inputValue
                }
              />
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }

  showTooltip = () => {
    return (
      <Tooltip translate={false} message={I18n.t('users.tooltipPassword')} />
    );
  };
}

ChangePasswordModal.defaultProps = {};
ChangePasswordModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
};

export default ChangePasswordModal;
