import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './ModalShare.css';
import { Modal } from 'react-bootstrap';
import { I18n } from 'react-redux-i18n';
import Button from '../../../components/common/Button';
import Tooltip from '../../../components/common/Tooltip';
import SquareButton from '../../../components/common/SquareButton';
import EmailInput from './EmailInput';

class ModalShare extends Component {
  static defaultProps;

  constructor(props) {
    super(props);
    this.state = {
      emails: [],
      shareNotes: { inputValue: false },
      shareCV: { inputValue: false },
      confirmDisabled: true,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.isDisabled = this.isDisabled.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show) {
      this.setState({
        emails: [],
        shareNotes: { inputValue: false },
        shareCV: { inputValue: false },
        confirmDisabled: true,
      });
    }
  }
  handleInputChange(name) {
    this.setState(() => ({
      ...this.state,
      [name]: { inputValue: !this.state[name].inputValue },
    }));
  }

  isDisabled(state) {
    this.setState({
      ...this.state,
      emails: state,
      confirmDisabled: state.length > 0 ? false : true,
    });
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        dialogClassName="modal-share"
        onHide={this.props.onHide}
        // backdrop={'static'}
      >
        <Modal.Header className={'modal-header'}>
          <div className="header-container">
            <h4 className="modal-title">
              <span class="material-symbols-filled">share</span>{' '}
              {I18n.t('persons.shareFile')}
            </h4>
            <Tooltip
              className="tooltip-share"
              message={'persons.modal-share.tooltip'}
              translate
              materialIcon={
                <span class="material-symbols-rounded">contact_support</span>
              }
            />
          </div>
          {/* <img src={exit} height={'30px'} onClick={this.props.onHide} alt="" /> */}
        </Modal.Header>
        <Modal.Body>
          <h5>{I18n.t('persons.modal-share.label')}</h5>
          <div className="email-input-container">
            <EmailInput
              setEmails={(emails) =>
                this.setState({ ...this.state, emails: emails })
              }
              isDisabled={this.isDisabled}
            />
          </div>
          <div className="checkboxs">
            <SquareButton
              setValue={!this.state.shareNotes.inputValue}
              change={this.handleInputChange}
              label={I18n.t('persons.modal-share.shareNotes')}
              name={'shareNotes'}
              value={this.state.shareNotes}
            />
            {/* <SquareButton
              setValue={!this.state.shareCV.inputValue}
              change={this.handleInputChange}
              label={I18n.t('persons.modal-share.shareCV')}
              name={'shareCV'}
              value={this.state.shareCV}
            /> */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="text-link-button"
            text={I18n.t('buttons.cancel')}
            onClick={this.props.onHide}
          />
          <Button
            className="primary"
            text={I18n.t('persons.modal-share.share')}
            disabled={this.state.confirmDisabled}
            onClick={async () => {
              await this.props.action({
                sendCv: this.state.shareCV.inputValue,
                sendNotes: this.state.shareNotes.inputValue,
                emails: this.state.emails,
              });
              this.props.onHide();
            }}
          />
        </Modal.Footer>
      </Modal>
    );
  }
}

ModalShare.defaultProps = {};
ModalShare.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
};

export default ModalShare;
