import React, { Component } from 'react';
import './IeIndicator.css';
import { I18n } from 'react-redux-i18n';
class IeIndicator extends Component {
  render() {
    const { ie_active } = this.props;
    return (
      <div className={`ie-indicator ${ie_active}`}>
        <div className="header-ie-indicator">
          <span className="material-symbols-filled icon">diagnosis</span>
          <span>{I18n.t('campaigns.ie.title')}</span>
        </div>
        <div className="body-ie-indicator">
          <span>
            {ie_active
              ? I18n.t('persons.requested')
              : I18n.t('persons.notRequested')}
          </span>
        </div>
      </div>
    );
  }
}

export default IeIndicator;
