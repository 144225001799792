export const SELECT_PERSON = 'SELECT PERSON';
export const UPDATE_PAGE = 'UPDATE PAGE PERSONS MIGRATION';
export const UPDATE_PAGE_SIZE = 'UPDATE PAGE PERSONS SIZE MIGRATION';
export const UPDATE_PERSONS_FILTER_VALUES = 'UPDATE PERSONS FILTER VALUES';

/* export const GET_ALL_PROFILES = 'GET ALL PROFILES';
export const GET_ALL_PROFILES_RESPONSE = 'GET ALL PROFILES RESPONSE';
export const GET_ALL_PROFILES_ERROR = 'GET ALL PROFILES ERROR'; */

export const GET_ALL_PARTNERS = 'GET ALL PARTNERS';
export const GET_ALL_PARTNERS_RESPONSE = 'GET ALL PARTNERS RESPONSE';
export const GET_ALL_PARTNERS_ERROR = 'GET ALL PARTNERS ERROR';

export const ADD_REMOVE_PERSON_TO_SELECTION = 'ADD REMOVE PERSON TO SELECTION';

export const GET_CAPABILITIES = 'GET CAPABILITIES';
export const GET_CAPABILITIES_RESPONSE = 'GET CAPABILITIES RESPONSE';
export const GET_CAPABILITIES_ERROR = 'GET CAPABILITIES ERROR';

/* export const GET_PROFILE = 'GET PROFILE';
export const GET_PROFILE_RESPONSE = 'GET PROFILE RESPONSE'; */
/* export const GET_PROFILE_ERROR = 'GET PROFILE ERROR'; */

export const TOGGLE_SELECTION_ALL_MIGRATIONS =
  'TOGGLE SELECTION ALL MIGRATIONS';
export const CLEAR_SELECTION = 'CLEAR SELECTION';
export const CLEAR_PERSON_CUSTOM_FILTER = 'CLEAR PERSON CUSTOM FILTER';

export const CLEAR_UI_VALUE = 'CLEAR UI VALUE';

export const CLEAR_FILTER = 'CLEAR_FILTER';
export const FILTER_PERSONS_MIGRATION = 'FILTER PERSONS MIGRATION';
export const FILTER_PERSONS_RESPONSE_MIGRATION =
  'FILTER PERSONS RESPONSE MIGRATIONS';
export const FILTER_PERSONS_ERROR = 'FILTER PERSONS ERROR MIGRATION';

export const MIGRATE_RESULTS = 'MIGRATE RESULTS';
export const MIGRATE_RESULTS_RESPONSE = 'MIGRATE RESULTS RESPONSE';
export const MIGRATE_RESULTS_ERROR = 'MIGRATE RESULTS ERROR';
export const SAVE_SEARCH_INPUT = 'SAVE_SEARCH_INPUT';

export const LOGOUT = 'LOGOUT';

export const SELECT_ALL = 'SELECT ALL';

export const LOAD_ALL = 'LOAD ALL';

export const SAVE_ALL = 'SAVE ALL';

export const ALL_LOADED = 'ALL LOADED';

export const campaignsMigrations = {
  selectPerson: (id) => ({ type: SELECT_PERSON, id }),
  updateFilterValues: (filter) => ({
    type: UPDATE_PERSONS_FILTER_VALUES,
    filter,
  }),
  updatePage: (pageNumber) => ({ type: UPDATE_PAGE, pageNumber }),
  updatePageSize: (pageSize) => ({ type: UPDATE_PAGE_SIZE, pageSize }),

  /*   getAllProfiles: () => ({ type: GET_ALL_PROFILES }),
  getAllProfilesResponse: (response) => ({
    type: GET_ALL_PROFILES_RESPONSE,
    response,
  }),
  getAllProfilesError: (error) => ({ type: GET_ALL_PROFILES_ERROR, error }), */

  getAllPartners: () => ({ type: GET_ALL_PARTNERS }),
  getAllPartnersResponse: (response) => ({
    type: GET_ALL_PARTNERS_RESPONSE,
    response,
  }),
  getAllPartnersError: (error) => ({ type: GET_ALL_PARTNERS_ERROR, error }),

  clearUiValue: (type, condition) => ({
    type: CLEAR_UI_VALUE,
    op_type: type,
    condition,
  }),

  clearFilter: () => ({ type: CLEAR_FILTER }),
  filterPersons: (filter, resetPage) => {
    let newFilter = {
      ...filter,
    };

    return {
      type: FILTER_PERSONS_MIGRATION,
      filter,
      newFilter,
      resetPage,
    };
  },
  filterPersonsResponse: (response) => ({
    type: FILTER_PERSONS_RESPONSE_MIGRATION,
    response,
  }),
  filterPersonsError: (error) => ({ type: FILTER_PERSONS_ERROR, error }),

  getCapabilities: (capabilities) => ({ type: GET_CAPABILITIES, capabilities }),
  getCapabilitiesResponse: (response) => ({
    type: GET_CAPABILITIES_RESPONSE,
    response,
  }),
  getCapabilitiesError: (error) => ({ type: GET_CAPABILITIES_ERROR, error }),

  /* getProfile: (id) => ({ type: GET_PROFILE, id }), */
  /*   getProfileResponse: (response) => ({ type: GET_PROFILE_RESPONSE, response }),
  getProfileError: (error) => ({ type: GET_PROFILE_ERROR, error }), */

  addRemovePersonToSelection: (result) => {
    return {
      type: ADD_REMOVE_PERSON_TO_SELECTION,
      result,
    };
  },
  toggleSelectionAllMigrations: () => ({
    type: TOGGLE_SELECTION_ALL_MIGRATIONS,
  }),
  clearSelection: () => ({ type: CLEAR_SELECTION }),
  clearPersonCustomFilters: () => ({ type: CLEAR_PERSON_CUSTOM_FILTER }),

  migrateResults: (body) => ({ type: MIGRATE_RESULTS, body }),
  migrateResultsResponse: (response) => ({
    type: MIGRATE_RESULTS_RESPONSE,
    response,
  }),
  migrateResultsError: (error) => ({ type: MIGRATE_RESULTS_ERROR, error }),

  saveSearchInput: (value) => ({ type: SAVE_SEARCH_INPUT, value }),

  logout: () => ({ type: LOGOUT }),

  selectAll: (flag) => ({ type: SELECT_ALL, flag }),

  loadAll: (page) => ({ type: LOAD_ALL, page }),

  saveAll: (results) => ({ type: SAVE_ALL, results }),

  allLoaded: () => ({ type: ALL_LOADED }),
};
