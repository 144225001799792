import { connect } from 'react-redux';
import Menu from '../components/common/Menu/Menu';
import actions from '../actions';
import { withRouter } from 'react-router';

const mapDispatchToProps = (dispatch) => ({
  getActualLocation: (location) => {
    dispatch(actions.common.getActualLocation(location));
  },
  logout: () => {
    dispatch(actions.session.logout());
  },
  showHelp: () => dispatch(actions.session.toggleHelp(true)),
  changePassword: (password, currentPassword) =>
    dispatch(actions.users.changePassword(password, currentPassword)),
  changePasswordResponse: (response) => {
    dispatch(actions.users.changePasswordResponse(response));
  },
});

const mapStateToProps = (state, ownProps) => ({
  user: state.session.user,
  changePasswordStatus: state.users.changePassword,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Menu));
