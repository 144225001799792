import React from 'react';
import { Component } from 'react';
import './Credits.css';
import { withRouter } from 'react-router';
import DonutChart from 'react-donut-chart';
import { I18n } from 'react-redux-i18n';
import Button from '../../../components/common/Button';
import { NumberLabel } from '../../../components/common/NumberLabel/NumberLabel';

class Credits extends Component {
  getConsumedPercentage() {
    let partner = this.props.session.partner;
    return (partner.credits_used / partner.credits_acquired) * 100;
  }

  componentDidMount() {
    if (this.props.user_.account)
      this.props.getPartner(this.props.user_.account.id);
  }
  /**
   * Credits data calculation to show
   */
  calculateCredits() {
    /** Stages
     * 1 - has credits_acquired (credits_used < credits_acquired)
     * 2 - has no credits_acquired but yes uncovered (credits_acquired + uncovered > credits_used > credits_acquired)
     * 3 - has no credits_acquired nor uncovered (credits_used >= credits_acquired + uncovered)
     *
     * @return {Object}:
     *      {boolean}   isUsingAcquired,
     *      {int}       acquiredRemaining
     *      {boolean}   isUsingUncovered,
     *      {boolean}   noMoreCredits,
     *      {int}       credits_acquired,
     *      {int}       credits_used,
     *      {int}       uncovered,
     */
    const {
      credits: acquired,
      credits_assigned: used,
      uncovered,
    } = this.props.user.partner ||
    this.props.user.account ||
    this.props.user.branch;
    return {
      isUsingAcquired: used < acquired,
      acquiredRemaining: used < acquired ? acquired - used : 0,
      isUsingUncovered: used >= acquired,
      uncoveredRemaining:
        used >= acquired ? uncovered - (used - acquired) : uncovered,
      noMoreCredits: used === acquired + uncovered,
    };
  }

  render() {
    const {
      credits: credits_acquired,
      credits_assigned: credits_used,
      uncovered,
    } = this.props.user.partner ||
    this.props.user.account ||
    this.props.user.branch;
    const credits = this.calculateCredits();
    let data = [
      { label: I18n.t('campaigns.credits.used'), value: credits_used },
    ];
    if (!credits.isUsingUncovered)
      data.push({
        label: I18n.t('campaigns.credits.available'),
        value: credits.acquiredRemaining,
        isEmpty: true,
      });
    return (
      <div className="credits">
        <div className="card-header">
          <p>{I18n.t('campaigns.credits.credits')}</p>
        </div>
        <div className="card-graph">
          <DonutChart
            colors={credits.isUsingUncovered ? ['#FF0000'] : ['#5621E7']}
            emptyColor={credits.isUsingUncovered ? '#FF8888' : '#C4C2F9'}
            strokeColor={'rgba(0, 0, 0, 0)'}
            height={300}
            width={300}
            legend={false}
            innerRadius={0.65}
            outerRadius={0.75}
            startAngle={270}
            clickToggle={false}
            data={data}
            formatValues={(value, total) => {
              return credits.isUsingUncovered
                ? value === credits_used
                  ? `${((value / total) * 100).toFixed(2)}%`
                  : `0 %`
                : `${((value / total) * 100).toFixed(2)}%`;
            }}
          />
          <div className="texts-container">
            <div>
              <p>
                <NumberLabel
                  className={'strong'}
                  value={credits.acquiredRemaining}
                />
                <span> {I18n.t('common.of')} </span>
                <NumberLabel className={'strong'} value={credits_acquired} />
              </p>
              <p>
                <span className={''}>
                  {I18n.t('campaigns.credits.creditsAvailable')}
                </span>
              </p>
            </div>
          </div>
          <div>
            <Button
              className="primary"
              text={I18n.t('clients.creditsHistoryButton')}
              onClick={() => this.props.history.push('/main/credits-history')}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Credits);
