import { deleteRequest, get, patch, post, webApi } from '../utils/http';

export const services = {
  getUsers: (filter, search) => {
    return get(
      webApi +
        `api/users${filter.toString()}&lang=${localStorage.getItem(
          'language',
        )}`,
    );
  },
  getUserGroups: () =>
    get(webApi + `api/roles?lang=${localStorage.getItem('language')}`),
  createUser: (body) => post(webApi + 'api/users', body),
  updateUser: (id, user) => patch(webApi + `api/users/${id}`, user),
  deleteUser: (id) => deleteRequest(webApi + `api/users/${id}`),
  changePassword: (id, password, currentPassword) =>
    patch(webApi + `api/users/${id}`, {
      password: password,
      currentPassword: currentPassword,
    }),
  validateUserViaEmail: (email) => post(webApi + 'api/users/exist', email),
};
