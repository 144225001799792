import actions from '../actions/index';
import { services } from './dashboard.services';
import {} from './dashboard.actions';
import {
  GET_DASHBOARD_REPORT_AGE,
  GET_DASHBOARD_REPORT_PROFILE,
  GET_DASHBOARD_REPORT_PROFILE_GENDER,
  GET_DASHBOARD_REPORT_CITY,
  GET_LAST_MONTH,
  UPDATE_CREDITS_WIDGETS,
} from './dashboard.actions';

const dashboardMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    switch (action.type) {
      case GET_DASHBOARD_REPORT_AGE:
        services
          .getDashboardReportAge(action.filter)
          .then((data) =>
            dispatch(actions.dashboard.getDashboardReportAgeResponse(data)),
          )
          .catch((error) =>
            dispatch(actions.dashboard.getDashboardReportAgeError(error)),
          );
        break;

      case GET_DASHBOARD_REPORT_PROFILE:
        services
          .getDashboardReportProfile(action.filter)
          .then((data) =>
            dispatch(actions.dashboard.getDashboardReportProfileResponse(data)),
          )
          .catch((error) =>
            dispatch(actions.dashboard.getDashboardReportProfileError(error)),
          );
        break;

      case GET_DASHBOARD_REPORT_PROFILE_GENDER:
        services
          .getDashboardReportProfileGender(action.time, action.filter)
          .then((data) =>
            dispatch(
              actions.dashboard.getDashboardReportProfileGenderResponse(data),
            ),
          )
          .catch((error) =>
            dispatch(
              actions.dashboard.getDashboardReportProfileGenderError(error),
            ),
          );
        break;

      case GET_DASHBOARD_REPORT_CITY:
        services
          .getDashboardReportCity(action.filter)
          .then((data) =>
            dispatch(actions.dashboard.getDashboardReportCityResponse(data)),
          )
          .catch((error) =>
            dispatch(actions.dashboard.getDashboardReportCityError(error)),
          );
        break;

      case GET_LAST_MONTH:
        services
          .getLastMonth()
          .then((data) =>
            dispatch(actions.dashboard.getLastMonthDataResponse(data)),
          )
          .then((error) =>
            dispatch(actions.dashboard.getLastMonthDataError(error)),
          );
        break;

      case UPDATE_CREDITS_WIDGETS:
        if (getState().session.user?.account?.id) {
          dispatch(
            actions.session.getPartner(getState().session.user?.account?.id),
          );
        }
        if (getState().session.user?.branch?.id) {
          dispatch(
            actions.session.getPartner(getState().session.user?.branch?.id),
          );
        }
        break;

      default:
    }
  };

export default dashboardMiddleware;
