import { I18n } from 'react-redux-i18n';
import React from 'react';

class EmailInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emails: [],
      inputValue: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    this.setState({
      ...this.state,
      emails: [],
    });
    this.props.isDisabled([]);
  }

  handleChange = (event, keyDownEvent = false) => {
    const { value } = event.target;
    this.setState({ inputValue: value });

    // Autocompletar el email
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let repeatEmail = this.state.emails.find((e) => e === value.trimEnd());

    if (repeatEmail && (value[value.length - 1] === ' ' || keyDownEvent)) {
      this.setState(
        (prevState) => {
          this.props.setEmails([...prevState.emails, value.trimEnd()]);

          return {
            inputValue: '',
          };
        },
        () => this.props.isDisabled(this.state.emails),
      );
    } else {
      if (
        emailRegex.test(value.trimEnd()) &&
        (value[value.length - 1] === ' ' || keyDownEvent)
      ) {
        this.setState(
          (prevState) => {
            this.props.setEmails([...prevState.emails, value.trimEnd()]);

            return {
              emails: [...prevState.emails, value.trimEnd()],
              inputValue: '',
            };
          },
          () => this.props.isDisabled(this.state.emails),
        );
      }
    }
  };

  handleKeyDown = (event) => {
    if (event.key === 'Backspace' && !this.state.inputValue) {
      this.setState(
        (prevState) => ({
          emails: prevState.emails.slice(0, -1),
        }),
        () => this.props.isDisabled(this.state.emails),
      );
    }
    if (event.key === 'Enter' || event.key === 'Tab') {
      this.handleChange(event, true);
    }
  };

  async deleteEmail(email) {
    const updated = await this.state.emails.filter((e) => e !== email);
    this.setState(
      {
        emails: updated,
      },
      () => this.props.isDisabled(this.state.emails),
    );
  }

  render() {
    return (
      <div className="email-input">
        {this.state.emails.map((email) => (
          <span key={email} className="email-tag">
            {email}
            <button
              type="button"
              onClick={() => this.deleteEmail(email)}
              // this.setState((prevState) => ({
              //   emails: prevState.emails.filter((e) => e !== email),
              // }))
            >
              x
            </button>
          </span>
        ))}
        <label htmlFor="email-input"></label>
        <input
          type="text"
          id="email-input"
          value={this.state.inputValue}
          placeholder={I18n.t('persons.modal-share.placeholder')}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
        />
      </div>
    );
  }
}

export default EmailInput;
