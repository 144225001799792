import actions from '../actions/index';
import { services } from './reports.services';
import {
  DOWNLOAD_REPORT,
  GET_INFO_REPORTS,
  RESTORE_REPORT,
  UPDATE_REPORTS,
} from './reports.actions';

const reportsMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    const language = localStorage.getItem('language');

    // getState().reports;
    switch (action.type) {
      case GET_INFO_REPORTS:
        const { account, branch } = action;
        services
          .getInfoReports({ account, branch })
          .then((data) => {
            dispatch(actions.reports.getInfoReportsResponse(data));
          })
          .catch((error) =>
            dispatch(actions.reports.getInfoReportsError(error)),
          );
        break;
      case RESTORE_REPORT:
        const { report } = action;
        services
          .restoreReports({ report })
          .then((data) =>
            dispatch(actions.reports.restoreReportsResponse(data)),
          )
          .catch((error) =>
            dispatch(actions.reports.restoreReportsError(error)),
          );
        break;
      case UPDATE_REPORTS:
        const { updateReport, reportType } = action;
        services
          .updateReport({ updateReport, reportType })
          .then((data) => dispatch(actions.reports.updateReportsResponse(data)))
          .catch((error) =>
            dispatch(actions.reports.updateReportsError(error)),
          );
        break;
      case DOWNLOAD_REPORT:
        const lang = language;

        services
          .downloadTestReport(action.reportType, action.test, action.folder)
          .then((data) => {
            const blob = new Blob([data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `test-report${action.reportType}${[lang]}.pdf`;
            link.click();
            window.URL.revokeObjectURL(url);

            dispatch(action.reports.downloadTestReportResponse(data));
          })
          .catch((error) => {
            dispatch(action.reports.downloadTestReportError(error));
          });

        break;
      default:
        break;
    }
  };

export default reportsMiddleware;
