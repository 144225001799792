import { connect } from 'react-redux';
import actions from '../../actions/index';
import DashboardScreen from '../components/DashboardScreen/DashboardScreen';
import { withRouter } from 'react-router';

const mapDispatchToProps = (dispatch) => ({
  getDashboardReportAge: (filter) =>
    dispatch(actions.dashboard.getDashboardReportAge(filter)),
  getDashboardReportProfile: (filter) =>
    dispatch(actions.dashboard.getDashboardReportProfile(filter)),
  getDashboardReportProfileGender: (time, filter) =>
    dispatch(actions.dashboard.getDashboardReportProfileGender(time, filter)),
  getDashboardReportCity: (filter) =>
    dispatch(actions.dashboard.getDashboardReportCity(filter)),
  getLastMonth: () => dispatch(actions.dashboard.getLastMonthData()),
  getAllCampaigns: ({ partners, campaign_dropdown, search }) =>
    dispatch(
      actions.campaigns.getAllCampaigns(partners, campaign_dropdown, search),
    ),
  getAllClients: ({ accountDropdown, view_branch, view_account, search }) =>
    dispatch(
      actions.clients.getAllClients(
        accountDropdown,
        view_branch,
        view_account,
        search,
      ),
    ),
  showOnBoarding: (boolean) =>
    dispatch(actions.dashboard.showOnBoarding(boolean)),
  removeSelectedCampaigns: () => dispatch(actions.campaigns.unselectCampaign()),
  updateViewBranch: () => {
    dispatch(actions.clients.getClientsScreen('new'));
    dispatch(actions.clients.updateViewBranch(true));
  },
  updateCreditsWidgets: () => {
    dispatch(actions.dashboard.updateCreditsWidgets());
  },
});

const mapStateToProps = (state, ownProps) => ({
  age: state.dashboard.age,
  profile: state.dashboard.profile,
  profileGender: state.dashboard.profileGender,
  cities: state.dashboard.city,
  start_date: state.dashboard.start_date,
  end_date: state.dashboard.end_date,
  i18n: state.i18n.locale,
  ageStatus: state.dashboard.ageStatus,
  profileStatus: state.dashboard.profileStatus,
  profileGenderStatus: state.dashboard.profileGenderStatus,
  cityStatus: state.dashboard.cityStatus,
  dateStatus: state.dashboard.dateStatus,
  onBoardingState: state.dashboard.onBoardingState,
  allCampaigns: state.campaigns.allCampaigns,
  getAllCampaignsStatus: state.campaigns.getAllCampaignsStatus,
  allClients: state.clients.allClients,
  getAllClientsStatus: state.clients.getAllClientsStatus,

  user: state.session.user,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(DashboardScreen));
