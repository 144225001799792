import { deleteRequest, get, patch, post, put, webApi } from '../utils/http';
import queryString from 'query-string';

export const services = {
  getJobs: (filter, positionDropdown = false) => {
    return get(
      webApi +
        `api/positions${filter.toString()}&lang=${localStorage.getItem(
          'language',
        )}&${queryString.stringify(positionDropdown)}`,
    );
  },
  createJob: (body) => post(webApi + 'api/positions', body),
  updateJob: (id, body) => patch(webApi + 'api/positions/' + id, body),
  deleteJob: (id) => deleteRequest(webApi + 'api/positions/' + id),
};
