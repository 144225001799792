import { connect } from 'react-redux';
import actions from '../../actions/index';
import JobDetailScreen from '../components/JobDetailScreen/JobDetailScreen';
import { withRouter } from 'react-router';

const mapDispatchToProps = (dispatch) => ({
  getJobs: () => dispatch(actions.jobs.getJobs()),
  updateJob: (id, job, type) =>
    dispatch(actions.jobs.updateJob(id, { ...job }, type)),
  createJob: (body, type) => dispatch(actions.jobs.createJob(body, type)),
  deleteJob: (id) => dispatch(actions.jobs.deleteJob(id)),
  cleanJobError: () => dispatch(actions.jobs.cleanJobError()),
  clearUiValue: (type, condition) =>
    dispatch(actions.jobs.clearUiValue(type, condition)),
  getAllProfiles: () => dispatch(actions.persons.getAllProfiles()),
  unselectCampaign: () => dispatch(actions.campaigns.unselectCampaign()),
});

const mapStateToProps = (state, ownProps) => ({
  users: state.jobs.users,
  newJob: state.jobs.newJob,
  createStatus: state.jobs.create,
  updateStatus: state.jobs.update,
  deleteStatus: state.jobs.delete,
  retrieveStatus: state.persons.retrieve,
  selectedJob: state.jobs.selectedJob,
  profiles: state.persons.profiles,
  createCampaing: state.jobs.createCampaing,
  language: state.i18n.locale,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(JobDetailScreen));
