import React, { Component } from 'react';
import './BaproLoginScreen.css';
import Button from '../../../components/common/Button';
import Login from '../../containers/Login';
import { Modal } from 'react-bootstrap';
import { GoogleLogin } from '@react-oauth/google';

class BaproLoginScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEmailComponent: false,
      showModalError: false,
      messageError: '',
      modalStyle: {
        position: 'absolute',
        left: window.innerWidth >= 1919 ? '670px' : '490px',
        width: window.innerWidth >= 1919 ? '586px' : '420px',
      },
    };
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
    document.addEventListener('click', this.handleClickOutside);
    window.addEventListener('resize', this.updateModalStyle);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
    document.removeEventListener('click', this.handleClickOutside);
    window.removeEventListener('resize', this.updateModalStyle);
  }

  componentDidUpdate(prevProps, prevState) {
    const { loginStatus, response } = this.props;

    if (loginStatus === 'ERROR' && !prevState.showModalError) {
      this.setState({ showModalError: true });
    }

    if (prevProps.response !== response) {
      this.handleResponseChange(response);
    }
  }

  handleResponseChange(response) {
    if (!response) return;

    let messageError = '';

    switch (response.data) {
      case 'User not player':
        messageError = 'Debes ser candidato para ingresar';
        break;
      case 'bapro_perms':
        messageError = 'No tienes permisos de Bapro';
        break;
      case 'You must have result to login into the platform':
        messageError = 'Para continuar deberá completar el juego conductual';
        break;
      default:
        if (response.status === 404) {
          messageError =
            'Alguno de los datos que has ingresado es incorrecto. Por favor verifícalos y vuelve a intentarlo.';
        }
    }

    if (messageError) {
      this.setState({ messageError });
    } else {
      this.setState({ messageError: '' });
    }
  }

  handleKeyDown = (event) => {
    if (event.key === 'Escape' && this.state.showEmailComponent) {
      this.setState({ showEmailComponent: false });
    }
  };

  handleClickOutside = (event) => {
    if (
      this.modalRef &&
      !this.modalRef.contains(event.target) &&
      this.state.showEmailComponent
    ) {
      this.setState({ showEmailComponent: false });
    }
  };

  updateModalStyle = () => {
    const newStyle = {
      position: 'absolute',
      left: window.innerWidth >= 1919 ? '670px' : '490px',
      width: window.innerWidth >= 1919 ? '586px' : '420px',
    };

    this.setState({ modalStyle: newStyle });
  };

  render() {
    const { showEmailComponent, showModalError, messageError, modalStyle } =
      this.state;

    return (
      <div className="card bapro-login">
        <h4>Seleccione un modo de ingreso</h4>
        <div className="options-container">
          <GoogleLogin
            onSuccess={(credentialResponse) => {
              this.props.googleLogin(credentialResponse.credential, true);
            }}
            width={window.innerWidth >= 1920 ? 240 : 170}
            height={window.innerWidth >= 1920 ? 40 : 27}
          />
          {showModalError && <p className="google-error">{messageError}</p>}
          <Button
            className={'bg-primary text-white p-0'}
            id="delete-btn"
            text={'Acceder con email'}
            onClick={() => {
              this.setState({
                showEmailComponent: !showEmailComponent,
                showModalError: false,
              });
            }}
          />
        </div>
        <Modal
          style={modalStyle}
          show={showEmailComponent}
          onHide={() => {
            this.setState({ showEmailComponent: false });
          }}
          ref={(node) => (this.modalRef = node)}
        >
          <Login />
        </Modal>
      </div>
    );
  }
}

export default BaproLoginScreen;
