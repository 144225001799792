import {
  SELECT_USER,
  GET_USERS_RESPONSE,
  UPDATE_USERS_FILTER_VALUES,
  UPDATE_PAGE,
  UPDATE_PAGE_SIZE,
  GET_USERS,
  GET_USERS_ERROR,
  DELETE_USER_ERROR,
  DELETE_USER,
  UPDATE_USER,
  UPDATE_USER_EMAIL,
  UPDATE_USER_ERROR,
  UPDATE_USER_RESPONSE,
  CREATE_USER,
  CREATE_USER_RESPONSE,
  CREATE_USER_ERROR,
  CREATE_USER_CLEAN_ERROR,
  DELETE_USER_RESPONSE,
  GET_USER_GROUPS,
  GET_USER_GROUPS_RESPONSE,
  GET_USER_GROUPS_ERROR,
  CLEAR_UI_VALUE_USERS,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_RESPONSE,
  CHANGE_PASSWORD_ERROR,
  VALIDATE_USER_VIA_EMAIL,
  VALIDATE_USER_VIA_EMAIL_RESPONSE,
  VALIDATE_USER_VIA_EMAIL_ERROR,
  CLEAN_USER_VALIDATION,
  CREATE_USER_FROM_PARTNER,
  CREATE_USER_FROM_PARTNER_ERROR,
  CREATE_USER_FROM_PARTNER_RESPONSE,
  LOGOUT,
} from './users.actions';
import { REHYDRATE } from 'redux-persist/lib/index';

const initialState = {
  count: 1,
  current: 1,
  total_pages: 1,
  page: 1,
  page_size: 10,
  results: [],
  user_groups: [],
  desc: false,
  filterValues: {
    ordering: '',
    type: '',
  },
  user: {},
  getUsersStatus: { loading: false, error: false, success: false },
  getUserGroupsStatus: { loading: false, error: false, success: false },
  create: { loading: false, error: false, success: false },
  createFromPartner: { loading: false, error: false, success: false },
  update: { loading: false, error: false, success: false },
  delete: { loading: false, error: false, success: false },
  changePassword: { loading: false, error: false, success: false },
  changeUserPassword: { loading: false, error: false, success: false },
  userValidated: '',
  validateUserViaEmail: { loading: false, error: false, success: false },
  errorCode: [],
};

const users = (state = initialState, action) => {
  switch (action.type) {
    /** Get users */
    case GET_USERS:
      return {
        ...state,
        getUsersStatus: { loading: true, error: false, success: false },
      };
    case GET_USERS_RESPONSE:
      return {
        ...state,
        ...action.response,
        getUsersStatus: { loading: false, error: false, success: true },
      };
    case GET_USERS_ERROR:
      return {
        ...state,
        getUsersStatus: { loading: false, error: true, success: false },
      };
    /**  */
    case SELECT_USER:
      return {
        ...state,
        user: state.results.filter((t) => t.id === action.id)[0],
      };

    /** TODO: do redux correctly */
    case GET_USER_GROUPS:
      return {
        ...state,
        getUserGroupsStatus: { loading: true, error: false, success: false },
        create: { loading: false, error: false, success: false },
      };
    case GET_USER_GROUPS_RESPONSE:
      return {
        ...state,
        user_groups: action.response.results,
        getUserGroupsStatus: { loading: false, error: false, success: true },
      };
    case GET_USER_GROUPS_ERROR:
      return {
        ...state,
        getUserGroupsStatus: { loading: false, error: true, success: false },
      };

    /** Create user */
    case CREATE_USER:
      return {
        ...state,
        create: { loading: true, error: false, success: false },
      };
    case CREATE_USER_RESPONSE:
      return {
        ...state,
        create: { loading: false, error: false, success: true },
      };
    case CREATE_USER_ERROR:
      return {
        ...state,
        create: { loading: false, error: true, success: false },
        errorCode: action.response,
      };
    case CREATE_USER_CLEAN_ERROR:
      return {
        ...state,
        create: { loading: false, error: false, success: false },
        errorCode: [],
      };

    /** Update user */
    case UPDATE_USER:
      return {
        ...state,
        update: { loading: true, error: false, success: false },
      };
    case UPDATE_USER_EMAIL:
      return {
        ...state,
        update: { loading: true, error: false, success: false },
      };
    case UPDATE_USER_RESPONSE:
      return {
        ...state,
        update: { loading: false, error: false, success: true },
      };
    case UPDATE_USER_ERROR:
      return {
        ...state,
        update: { loading: false, error: true, success: false },
      };

    /** Delete user */
    case DELETE_USER:
      return {
        ...state,
        delete: { loading: true, error: false, success: false },
      };
    case DELETE_USER_RESPONSE:
      return {
        ...state,
        delete: { loading: false, success: true, error: false },
      };
    case DELETE_USER_ERROR:
      return {
        ...state,
        delete: {
          loading: false,
          success: false,
          error: true,
          status: action.error.status,
        },
      };

    /** Create user from partner */
    case CREATE_USER_FROM_PARTNER:
      return {
        ...state,
        createFromPartner: { loading: true, error: false, success: false },
      };
    case CREATE_USER_FROM_PARTNER_RESPONSE:
      return {
        ...state,
        createFromPartner: { loading: false, error: false, success: true },
      };
    case CREATE_USER_FROM_PARTNER_ERROR:
      return {
        ...state,
        createFromPartner: { loading: false, error: true, success: false },
      };

    /**  */
    case UPDATE_USERS_FILTER_VALUES:
      return {
        ...state,
        page_size: action.filterValues.page_size || 10,
        getUsersStatus: { loading: true, error: false, success: false },
        current:
          action.filterValues.search?.length > 0
            ? 1
            : action.filterValues.current,
        filterValues: { ...state.filterValues, ...action.filterValues },
        desc: !state.desc,
        start_date: action.start_date,
        end_date: action.end_date,
      };

    /**  */
    case UPDATE_PAGE:
      return { ...state, current: action.pageNumber };
    case UPDATE_PAGE_SIZE:
      return { ...state, page_size: action.pageSize, current: 1 };

    /** Change password */
    case CHANGE_PASSWORD:
      return {
        ...state,
        changePassword: { loading: true, error: false, success: false },
      };
    case CHANGE_PASSWORD_RESPONSE:
      console.log(action);
      return {
        ...state,
        changePassword: {
          loading: action.response.loading,
          error: action.response.error,
          success: action.response.success,
        },
      };
    case CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        changePassword: { loading: false, error: true, success: false },
      };

    /** Validate user via email */
    case VALIDATE_USER_VIA_EMAIL:
      return {
        ...state,
        validateUserViaEmail: {
          loading: true,
          error: false,
          success: false,
        },
      };
    case VALIDATE_USER_VIA_EMAIL_RESPONSE:
      return {
        ...state,
        userValidated: action.response,
        validateUserViaEmail: {
          loading: false,
          error: false,
          success: true,
        },
      };
    case VALIDATE_USER_VIA_EMAIL_ERROR:
      return {
        ...state,
        validateUserViaEmail: {
          loading: false,
          error: true,
          success: false,
        },
      };
    case CLEAN_USER_VALIDATION:
      return {
        ...state,
        userValidated: '',
        validateUserViaEmail: {
          loading: false,
          error: false,
          success: false,
        },
      };

    /**  */
    case CLEAR_UI_VALUE_USERS:
      return {
        ...state,
        [action.op_type]: {
          ...action.op_type,
          [action.condition]: false,
        },
      };

    /**  */
    case LOGOUT:
      return initialState;

    //OPTIONS

    /** redux-persist */
    case REHYDRATE:
      let incoming = action.payload;
      if (incoming) {
        return {
          ...state,
          ...incoming.users,
          getUsersStatus: { loading: false, error: false, success: false },
          getUserGroupsStatus: { loading: false, error: false, success: false },
          create: { loading: false, error: false, success: false },
          createFromPartner: { loading: false, error: false, success: false },
          update: { loading: false, error: false, success: false },
          delete: { loading: false, error: false, success: false },
          changeUserPassword: { loading: false, error: false, success: false },
          changePassword: { loading: false, error: false, success: false },
        };
      }
      return state;

    default:
      return state;
  }
};

export default users;
