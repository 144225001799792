import { deleteRequest, get, patch, post, put, webApi } from '../utils/http';
import queryString from 'query-string';

export const services = {
  // getPersons: (filter) => get(webApi + `api/person/${filter.toString()}`),
  filterPersons: (filter, searchFilter) => {
    return get(
      webApi +
        `api/results${searchFilter.toString()}&${queryString.stringify(
          filter,
        )}`,
    );
  },
  createPerson: (body) => post(webApi + 'api/person/', body),
  updatePerson: (id, person) => put(webApi + 'api/person/' + id + '/', person),
  patchUser: (id, user) => patch(webApi + 'api/users/' + id + '/', user),
  deletePerson: (id) => deleteRequest(webApi + 'api/person/' + id + '/'),
  /* getAllProfiles: (filter) =>
    get(
      webApi +
        `api/profiles` +
        filter.toString() +
        `&lang=${localStorage.getItem('language')}`,
    ), */
  getAllPartners: (filter) =>
    get(
      webApi +
        `api/partner` +
        filter.toString() +
        `&lang=${localStorage.getItem('language')}`,
    ),
  demographicFilter: (latlng) =>
    get(webApi + `api/demographic_filter/${latlng.lat}/${latlng.lng}`),
  getJobCompatibility: (body) => {
    let id_results = body.results;
    let queryObject = {
      id_results,
      id_positions: body.positions,
    };
    return get(
      webApi +
        `api/results/reports/profile?lang=${localStorage.getItem(
          'language',
        )}&${queryString.stringify(queryObject)}`,
    );
  },
  getCapabilities: () => {},
  getCountries: () =>
    get(
      webApi +
        `api/countries?page_size=250&lang=${localStorage.getItem('language')}`,
    ),
  getCities: (country_id) =>
    get(
      webApi +
        `api/cities?country_id=${country_id}&lang=${localStorage.getItem(
          'language',
        )}`,
    ),
  downloadProfileReport: (ids, reportType) => {
    let id_result = ids.map((res) => res.id_result);
    let id_invitations = ids.map((res) => res.id_invitation);
    let queryObject = {
      id_result,
      id_invitations,
      type: reportType,
    };
    return get(
      webApi +
        `api/results/reports/download?lang=${localStorage.getItem(
          'language',
        )}&${queryString.stringify(queryObject)}`,
      { extra: { responseType: 'blob' } },
    );
  },
  sendPersonReportEmail: (value, id_invitation, campaign) => {
    const queryObject = {
      id_result: value,
      id_invitation,
      campaign,
      type: 'individual',
    };
    return get(
      webApi + `api/results/reports/send?${queryString.stringify(queryObject)}`,
    );
  },
  /* getProfile: (id) =>
    get(
      webApi +
        `api/get_profile_translation/${id}/?lang=${localStorage.getItem(
          'language',
        )}`,
    ), */
  migrateResults: (body) =>
    post(webApi + `api/companies/campaigns/migration`, body),
  exportTrend: (body) => {
    let id_results = body.results;
    let id_skills = false;
    let id_positions = false;
    body.capabilities
      ? (id_skills = body.data.map((id_sk) => id_sk))
      : (id_positions = body.data.map((id_pos) => id_pos));

    let queryObject = {
      id_results,
      id_skills,
      id_positions,
    };

    const response = body.capabilities
      ? get(
          webApi +
            `api/results/reports/skills/download?lang=${localStorage.getItem(
              'language',
            )}&${queryString.stringify(queryObject)}`,
          {
            extra: { responseType: 'blob' },
          },
        )
      : get(
          webApi +
            `api/results/reports/profile/download?lang=${localStorage.getItem(
              'language',
            )}&${queryString.stringify(queryObject)}`,
          {
            extra: { responseType: 'blob' },
          },
        );
    return response;
  },
};
