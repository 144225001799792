import React, { Component } from 'react';
import './Dropdown.css';
import dropdownIcon from '../../../assets/icons/down-arrow-LIGHT-24x24.png';
import dropUpIcon from '../../../assets/icons/up-arrow-LIGHT-24x24.png';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import Loader from '../Loader';
import { I18n } from 'react-redux-i18n';
/**
 * Documentation
 * ------------------------
 * @description: <button/> with <ul><li>OPTIONS</li></ul>
 * @type:
 * 		1. single option select (required, no empty option)
 * 		2. single option select (not required, empty option selectable)
 * 		3. multiSelect
 *
 * (1, 2): 	showEmptyOption prop adds to option array the placeholder
 * 			or generic option.
 * (3): 	prop renders other components, and needs an addOption
 * 			prop function call.
 */

export default class DropdownMenu extends Component {
  // static defaultProps;

  constructor(props) {
    super(props);
    this.state = {
      showOptions: false,
    };
  }

  // componentDidMount = () => {
  //   document.addEventListener('click', (e) => {
  //     const menu = document.getElementById(this.props.name);

  //     if (menu.id !== e.target.id && this.state.showOptions) {
  //       this.setState({ ...this.state, showOptions: false });
  //     }
  //   });
  // };
  // componentWillUnmount() {
  //   document.removeEventListener('click', (e) => {
  //     const menu = document.getElementById(this.props.name);

  //     if (menu.id !== e.target.id && this.state.showOptions) {
  //       this.setState({ ...this.state, showOptions: false });
  //     }
  //   });
  // }

  handleHideOptionsState = (ev) => {
    const { onClick } = this.props;
    const parentElement = document.getElementById(this.props.name);

    if (!parentElement.contains(ev.target) && this.state.showOptions) {
      this.setState({ showOptions: false }, () => onClick(false));
      this.props.restartState();
    }
  };

  renderButtonContent() {
    const {
      label,
      loading,
      dropCalendar,
      // style gray border
    } = this.props;

    let result = [];
    if (loading) return <Loader mini />;
    else {
      result.push(
        <label key="dropdown-single-select-label">
          {this.props.inCredits
            ? this.props.resetInCredits
              ? I18n.t('dropdown.date')
              : dropCalendar.startDate.toString() ===
                dropCalendar.initDate.toString()
              ? I18n.t('dropdown.date')
              : label
            : dropCalendar === undefined ||
              this.props.resetFilter ||
              this.props.resetInCredits ||
              this.props.filtering === false ||
              this.props.historyFiltering === false ||
              this.props.filteringDate === false ||
              this.props.jobDateFiltering === false
            ? I18n.t('dropdown.date')
            : dropCalendar.startDate.toString() ===
                dropCalendar.initDate.toString() && this.props.query === false
            ? I18n.t('dropdown.date')
            : label}
        </label>,
      );
    }
    this.state.showOptions
      ? result.push(
          <span className="material-symbols-rounded">expand_less</span>,
        )
      : result.push(
          <span className="material-symbols-rounded">expand_more</span>,
        );

    return result;
  }

  render() {
    const {
      name, // id
      className,
      nestedComponents,
      closeAfter,
      closeAfterAction,
      loading,
      handleCalendarOpen,
      onClick,
      // style gray border
    } = this.props;
    if (closeAfter) {
      this.setState({ showOptions: false });
      closeAfterAction();
    }

    return (
      <div
        className={`dropdown-container ${className}`}
        id={name}
        onClick={() =>
          document.addEventListener('mousedown', this.handleHideOptionsState)
        }
      >
        <div className="dropdown-menu-test">
          {[
            <button
              className={`${className} test ${loading ? 'loading-date' : ''}`}
              key="dropdown-button-values"
              type="button"
              onClick={() => {
                // this.props.handleCalendarOpen();
                this.setState({ showOptions: !this.state.showOptions }, () =>
                  onClick(this.state.showOptions),
                );
              }}
            >
              {this.renderButtonContent()}
            </button>,
            this.state.showOptions && (
              <ul
                className={
                  'custom-dropdown-menu-components ul-menu' +
                  (nestedComponents.length === 0 ? ' no-items' : '') +
                  (this.props.onDashboard && !this.props.user?.user_admin
                    ? ' on-dash'
                    : '') +
                  (this.props.onCredits && this.props.user?.user_admin
                    ? ' on-creds'
                    : '') +
                  ((this.props.user?.user_admin ||
                    this.props.user?.partner?.is_super_dashboard) &&
                  this.props.location.pathname === '/main/clients'
                    ? ' buttons-fix'
                    : '') +
                  (this.props.user?.user_admin ? ' admin-buttons-fix' : '')
                }
              >
                {nestedComponents.map((component, i) => (
                  <div key={i + 1}>{component}</div>
                ))}
              </ul>
            ),
          ]}
        </div>
      </div>
    );
  }
}
