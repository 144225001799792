import './TableMenuItem.sass';

const TableMenuItem = ({ children }) => {
  return (
    <div id="TableMenuItem" className="TableMenuItem">
      {children}
    </div>
  );
};

export default TableMenuItem;
