import 'whatwg-fetch';
import settings from '../settings';
import axios from 'axios';
import store from '../store';
import jwt_decode from 'jwt-decode';
import actions from '../actions';

const httpClient = axios.create();
httpClient.defaults.timeout = 300000;

export const webApi = settings.webApi;

export let token = localStorage['token'];
let tokenTimestamp =
  localStorage['token-ts'] && parseInt(localStorage['token-ts']);
let refreshingToken = false;

const _request = (url, method, data, config = {}, access_token = token) => {
  refreshToken();
  const headers = {
    ...config.headers,
    Authorization: 'Bearer ' + access_token,
  };
  if (config.noAuth || !access_token) delete headers.Authorization;
  return httpClient({ url, method, data, headers, ...config.extra })
    .then((res) => {
      if (res.status === 200 || res.status === 201 || res.status === 204)
        return res.data;
      else throw res.data;
    })
    .catch((errorResponse) => {
      if (
        errorResponse.response.status === 401 &&
        errorResponse.response.data &&
        errorResponse.response.data.detail === 'Signature has expired.'
      ) {
        // window.location.reload();
      }
      throw errorResponse.response || { status: 500 };
    });
};

export const get = (url, config = {}, access_token = token) =>
  _request(url, 'GET', undefined, config, access_token);

export const post = (url, body, config = {}) =>
  _request(url, 'POST', body, config);

export const put = (url, body, config = {}) =>
  _request(url, 'PUT', body, config);

export const patch = (url, body, config = {}) =>
  _request(url, 'PATCH', body, config);

export const deleteRequest = (url, body, config = {}) =>
  _request(url, 'DELETE', body, config);

export const putS3 = (url, body, headers) => {
  const resultHeaders = {
    ...headers,
  };

  const resultBody =
    resultHeaders['Content-Type'] === 'application/json'
      ? JSON.stringify(body)
      : body;

  return fetch(url, {
    method: 'PUT',
    headers: resultHeaders,
    body: resultBody,
  });
};

export const services = {
  refreshToken: (token) => post(webApi + 'auth/refresh/', { token }),
};

export const refreshToken = () => {
  /* if (
    token &&
    tokenTimestamp &&
    Date.now() - tokenTimestamp > 1200000 &&
    Date.now() - tokenTimestamp < 1800000 &&
    !refreshingToken
  ) {
    refreshingToken = true;
    services
      .refreshToken(token)
      .then((tokenRes) => {
        if (tokenRes.token) {
          _saveToken(tokenRes.token);
        }
        refreshingToken = false;
      })
      .catch((err) => (refreshingToken = false));
  } */
  const state = store.store.getState();
  const token_ = localStorage['token'];
  let decoded_token;
  if (token_) decoded_token = jwt_decode(token_);

  if (
    (Date.now() > new Date(decoded_token?.exp) || !token_) &&
    state.session.isLoggedIn
  )
    store.store.dispatch(actions.session.logout());
};
// refreshToken();

export const _saveToken = (respToken) => {
  localStorage['token'] = respToken;
  localStorage['token-ts'] = Date.now();
  token = respToken;
  tokenTimestamp = Date.now();
};
