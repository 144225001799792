import { connect } from 'react-redux';
import actions from '../../actions/index';
import CampaignsTable from '../components/CampaignsTable/CampaignsTable';
import { withRouter } from 'react-router';

const mapStateToProps = (state) => ({
  campaigns: state.campaigns.results,
  getCampaignsStatus: state.campaigns.getCampaignsStatus,
  selectedCampaign: state.campaigns.selectedCampaign,

  currentPage: state.campaigns.current >= 1 ? state.campaigns.current : 1,
  totalPages: state.campaigns.total_pages,
  totalCount: state.campaigns.count,
  pageSize: state.campaigns.page_size,
  desc: state.campaigns.desc,

  createStatus: state.campaigns.createStatus,
  updateStatus: state.campaigns.updateStatus,
  campaignDeleteStatus: state.campaigns.campaignDeleteStatus,
  shareStatus: state.campaigns.shareStatus,
  credits_user_status: state.campaigns.credits_user_status,
  user_: state.session.user,
  user: state.session.user,
  time_data: {
    start_date: state.dashboard.start_date,
    end_date: state.dashboard.end_date,
  },
  filterValues: state.campaigns.filterValues,
  searchValueClean: state.campaigns.searchValueClean,
});

const mapDispatchToProps = (dispatch) => ({
  getCampaigns: () => dispatch(actions.campaigns.getCampaigns()),
  selectCampaign: (id) => dispatch(actions.campaigns.selectCampaign(id)),
  unselectCampaign: () => dispatch(actions.campaigns.unselectCampaign()),

  updatePage: (page) => dispatch(actions.campaigns.updatePage(page)),
  updatePageSize: (pageSize) =>
    dispatch(actions.campaigns.updatePageSize(pageSize)),

  createCampaign: () => dispatch(actions.campaigns.createCampaign()),
  deleteCampaign: (id) => dispatch(actions.campaigns.deleteCampaign(id)),
  shareCampaign: (id) => dispatch(actions.campaigns.shareCampaign(id)),
  cleanSearchValue: (boolean) =>
    dispatch(actions.campaigns.cleanSearchValue(boolean)),
  updateFilterValues: (filter) =>
    dispatch(actions.campaigns.updateFilterValues(filter)),
  filterCampaigns: (filter) => dispatch(actions.campaigns.getCampaigns(filter)),
  clearUiValue: (type, condition) =>
    dispatch(actions.campaigns.clearUiValue(type, condition)),

  getCreditsHistory: () => dispatch(actions.common.getCreditsHistory()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(CampaignsTable));
