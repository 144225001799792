import React, { Component } from 'react';
import './SearchInput.css';

class SearchInput extends Component {
  static defaultProps;
  render() {
    return (
      <div className={`search-input-container ${this.props.class} `}>
        <span class="material-symbols-rounded search-icon">search</span>
        <input
          name={this.props.name}
          type="text"
          id={this.props.name}
          onChange={this.props.onChange}
          placeholder={this.props.placeholder}
          className={`${this.props.value ? 'active' : ''}`}
          value={this.props.displayValue}
          autoComplete={this.props.autoComplete}
        />
      </div>
    );
  }
}

export default SearchInput;
