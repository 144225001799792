import { connect } from 'react-redux';
import actions from '../../actions/index';
import UserDetailScreen from '../components/UserDetailScreen/UserDetailScreen';
import { withRouter } from 'react-router';

const mapDispatchToProps = (dispatch) => ({
  getUsers: () => dispatch(actions.users.getUsers()),
  getUserGroups: () => dispatch(actions.users.getUserGroups()),
  updateFilterValues: (filter) =>
    dispatch(actions.users.updateFilterValues(filter)),
  updateUser: (id, user) => dispatch(actions.users.updateUser(id, { ...user })),
  updateUserEmail: (id, user) =>
    dispatch(actions.users.updateUserEmail(id, { ...user })),
  createUser: (body) => dispatch(actions.users.createUser(body)),
  createUserCleanError: () => dispatch(actions.users.createUserCleanError()),
  deleteUser: (id) => dispatch(actions.users.deleteUser(id)),
  changePassword: (password, currentPassword) =>
    dispatch(actions.users.changePassword(password, currentPassword)),
  validateUserViaEmail: (email) =>
    dispatch(actions.users.validateUserViaEmail(email)),
  cleanUserValidation: () => dispatch(actions.users.cleanUserValidation()),
  clearUiValue: (type, condition) =>
    dispatch(actions.users.clearUiValue(type, condition)),
  getAllClients: ({ accountDropdown, view_branch, view_account, search }) =>
    dispatch(
      actions.clients.getAllClients(
        accountDropdown,
        view_branch,
        view_account,
        search,
      ),
    ),
  logout: () => {
    dispatch(actions.session.logout());
  },
});

const mapStateToProps = (state, ownProps) => ({
  errorCode: state.users.errorCode.data,
  my_groups: state.session.user.groups,
  users: state.users.users,
  user: state.session.user,
  createStatus: state.users.create,
  getUsersStatus: state.users.getUsersStatus,
  updateStatus: state.users.update,
  deleteStatus: state.users.delete,
  changePasswordStatus: state.users.changePassword,
  validateUserViaEmailStatus: state.users.validateUserViaEmail,
  userValidated: state.users.userValidated,
  selectedUser: state.users.user,
  partner: state.session.user.partner,
  lang: state.i18n.locale,
  user_groups: state.users.user_groups,
  getUserGroupsStatus: state.users.getUserGroupsStatus,

  allClients: state.clients.allClients,
  getAllClientsStatus: state.clients.getAllClientsStatus,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(UserDetailScreen));
