import React, { PureComponent } from 'react';
import './ReportsDetailScreen.css';
import { I18n } from 'react-redux-i18n';
import Button from '../../../components/common/Button';
import FormDropdown from '../../../components/common/Dropdown/FormDropdown';
import {
  SnackBar,
  successMessage,
  errorMessage,
} from '../../../utils/snackbar';
import { notify } from 'react-notify-toast';
import Tooltip from '../../../components/common/Tooltip';

class ReportsDetailScreen extends PureComponent {
  state = {
    individual: [
      { value: 'hurlingham', label: I18n.t('reports.introspection') },
      { value: 'natura', label: I18n.t('reports.professional') },
      { value: 'integrado', label: I18n.t('reports.integrator') },
      { value: 'vocacional', label: I18n.t('reports.orientation') },
      { value: 'default', label: I18n.t('reports.default') },
    ],
    company: [
      { value: 'Comfama', label: I18n.t('reports.extended') },
      { value: 'vocacional', label: I18n.t('reports.orientation') },
      { value: 'natura', label: I18n.t('reports.professional') },
      { value: 'default', label: I18n.t('reports.default') },
    ],
    selectedIndividual: {
      inputValue: '',
      type: 'individual',
    },
    selectedCompany: {
      inputValue: '',
      type: 'company',
    },
  };
  componentDidUpdate(prevProps) {
    if (prevProps.updateReportsStatus !== this.props.updateReportsStatus) {
      this.showNotifications();
    }
  }

  showNotifications() {
    if (this.props.updateReportsStatus.success) {
      notify.show(
        <SnackBar
          icon={'priority'}
          title={I18n.t('common.excellent')}
          body={I18n.t('reports.updatedReport')}
          type={'success'}
        />,
        'custom',
        5000,
        successMessage,
      );
    }
    if (this.props.updateReportsStatus.error) {
      notify.show(
        <SnackBar
          icon={'report'}
          title={'Error'}
          body={I18n.t('reports.error')}
          type={'error'}
        />,
        'custom',
        5000,
        errorMessage,
      );
    }
  }

  addOption = (name, value) => {
    if (name === 'individual') {
      this.setState({
        ...this.state,
        selectedIndividual: {
          ...this.state.selectedIndividual,
          inputValue: value,
        },
        selectedCompany: {
          inputValue: '',
          type: 'company',
        },
      });
    }
    if (name === 'company') {
      this.setState({
        selectedCompany: { ...this.state.selectedCompany, inputValue: value },
        selectedIndividual: {
          inputValue: '',
          type: 'individual',
        },
      });
    }
  };

  downloadTestReport = ({ type, test, folder }) => {
    const testReport = {
      reportType: type,
      test,
      folder,
    };
    this.props.downloadTestReport(testReport);
  };

  disabledButton = () => {
    const { selectedIndividual, selectedCompany } = this.state;

    const isIndividualValid =
      selectedIndividual?.inputValue?.description?.length > 0;

    const isCompanyValid = selectedCompany?.inputValue?.description?.length > 0;

    if (isIndividualValid && isCompanyValid) {
      return isIndividualValid && isCompanyValid;
    } else return !(isIndividualValid || isCompanyValid);
  };

  validateIndividual = () => {
    const { selectedIndividual } = this.state;
    const isIndividualValid =
      selectedIndividual?.inputValue.description?.length > 0;

    if (selectedIndividual) {
      return !isIndividualValid;
    }
  };

  validateCompany = () => {
    const { selectedCompany } = this.state;
    const isCompanyValid = selectedCompany?.inputValue.description?.length > 0;

    if (selectedCompany) {
      return !isCompanyValid;
    }
  };

  updateReports = () => {
    if (this.state.selectedCompany.inputValue.description?.length > 0) {
      const { code } = this.state.selectedCompany.inputValue;
      const { type } = this.state.selectedCompany;
      this.props.updateReports({ updateReport: code, reportType: type });
    }
    if (this.state.selectedIndividual.inputValue.description?.length > 0) {
      const { code } = this.state.selectedIndividual.inputValue;
      const { type } = this.state.selectedIndividual;
      this.props.updateReports({ updateReport: code, reportType: type });
    }
  };

  handleButtonClick = (type, download) => {
    if (type === 'updateReports') {
      this.updateReports();
    }
    if (type === 'downloadTestReport') {
      this.downloadTestReport(download);
    }
  };

  render() {
    return (
      <div className="card edit-report">
        <div className="header-container">
          <span
            className="material-symbols-rounded"
            onClick={() => this.props.history.push('/main/reports')}
          >
            arrow_back_ios
          </span>
          <span className="container-name">
            {this.props.user.branch?.name
              ? this.props.user.branch?.name
              : this.props.user.account?.name}
          </span>
        </div>
        <div className="edit-screen">
          {/* TODO: upload own reports 
           <div className="card upload-reports">
            <h3>Upload reports</h3>
          </div> */}
          <div className="card edit-container">
            <div className="edit-header">
              <span className="title">{I18n.t('reports.updateReport')}</span>
              <Tooltip
                className="associated-codes"
                message="reports.tooltip"
                materialIcon={
                  <span className="material-symbols-rounded">
                    contact_support
                  </span>
                }
              />
            </div>
            <div className="dropdown-container">
              <div className="button-container">
                <FormDropdown
                  value={this.state.selectedIndividual}
                  name={'individual'}
                  loading={false}
                  options={this.state.individual.map((individual) => ({
                    code: individual.value,
                    description: individual.label,
                  }))}
                  placeholder={I18n.t('reports.individual')}
                  change={this.addOption}
                  title={null}
                />
                <Button
                  text={I18n.t('reports.preview')}
                  disabled={this.validateIndividual()}
                  iconButton={true}
                  onClick={() =>
                    this.handleButtonClick('downloadTestReport', {
                      type: 'individual',
                      test: true,
                      folder: this.state.selectedIndividual.inputValue?.code,
                    })
                  }
                  className={`secondary buttons-csv`}
                  iconClass={'download'}
                  type={'button'}
                />
              </div>

              {this.state.selectedIndividual.inputValue.description?.length >
                0 &&
                (this.state.selectedIndividual.inputValue.code === 'default' ? (
                  <span className="report-detail">
                    {I18n.t('reports.reportMulti')}
                  </span>
                ) : (
                  <span className="report-detail">
                    {I18n.t('reports.reportSpanish')}
                  </span>
                ))}
            </div>
            <div className="dropdown-container">
              <div className="button-container">
                <FormDropdown
                  value={this.state.selectedCompany}
                  name={'company'}
                  loading={false}
                  options={this.state.company.map((company) => ({
                    code: company.value,
                    description: company.label,
                  }))}
                  placeholder={I18n.t('reports.company')}
                  change={this.addOption}
                  title={null}
                  cleanOption={false}
                  className="age"
                />
                <Button
                  text={I18n.t('reports.preview')}
                  disabled={this.validateCompany()}
                  iconButton={true}
                  onClick={
                    () =>
                      this.handleButtonClick('downloadTestReport', {
                        type: 'company',
                        test: true,
                        folder: this.state.selectedCompany.inputValue?.code,
                      })
                    // this.downloadTestReport({
                    //   type: 'company',
                    //   test: true,
                    //   folder: this.state.selectedCompany.inputValue?.code,
                    // })
                  }
                  className={`secondary buttons-csv`}
                  iconClass={'download'}
                  type={'button'}
                />
              </div>
              {this.state.selectedCompany.inputValue.description?.length > 0 &&
                (this.state.selectedCompany.inputValue.code === 'default' ? (
                  <span className="report-detail">
                    {I18n.t('reports.reportMulti')}
                  </span>
                ) : (
                  <span className="report-detail">
                    {I18n.t('reports.reportSpanish')}
                  </span>
                ))}
            </div>
            <Button
              text={I18n.t('reports.updateReport')}
              disabled={this.disabledButton()}
              iconButton={true}
              onClick={() => this.handleButtonClick('updateReports')}
              className={`secondary buttons-csv`}
              iconClass={'update'}
              type={'button'}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ReportsDetailScreen;
