import moment from 'moment';
const lastWeek = (date) => {
  const prevWeek = new Date(date);
  prevWeek.setDate(prevWeek.getDate() - 7);
  return prevWeek;
};

const lastMonth = (date) => {
  const prevMonth = new Date(date);
  prevMonth.setMonth(prevMonth.getMonth() - 1);
  prevMonth.setDate(prevMonth.getDate() - 1);
  return prevMonth;
};

const equalsDate = (date1, date2) => {
  try {
    if (!date1 || !date2) return false;
    let newDate1 = new Date(date1);
    let newDate2 = new Date(date2);
    return (
      newDate1 &&
      newDate2 &&
      newDate1?.getYear() === newDate2?.getYear() &&
      newDate1?.getMonth() === newDate2?.getMonth() &&
      newDate1?.getDate() === newDate2?.getDate()
    );
  } catch (e) {}
};

const formatDate = (d, lang) => {
  const dateMoment = moment(d);
  dateMoment.locale(lang);
  return dateMoment.format('l');
};

export { lastWeek, lastMonth, formatDate, equalsDate };
