import './DropdownHelp.sass';
import { Component } from 'react';
import Button from '../Button';
import { I18n } from 'react-redux-i18n';
class DropdownHelp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showHelp: false,
    };
  }
  componentDidMount = () => {
    document.addEventListener('click', (e) => {
      const menu = document.getElementById('MenuHelp');

      if (menu.id !== e.target.id && this.state.showHelp) {
        this.setState({ ...this.state, showHelp: false });
      }
    });
  };
  componentWillUnmount() {
    document.removeEventListener('click', (e) => {
      const menu = document.getElementById('MenuHelp');

      if (menu.id !== e.target.id && this.state.showHelp) {
        this.setState({ ...this.state, showHelp: false });
      }
    });
  }
  render() {
    return (
      <div className="dropdown DropdownHelp">
        <button
          type="button"
          id="MenuHelp"
          className="material-symbols-rounded material-symbols-filled"
          onClick={() =>
            this.setState({ ...this.state, showHelp: !this.state.showHelp })
          }
        >
          help
        </button>
        {this.state.showHelp && (
          <div className="dropdown-menu show">
            <div className="dropdown-title">
              {I18n.t('dropdown.help.title')}
            </div>
            <div className="dropdown-body" href="#">
              {I18n.t('dropdown.help.description')}
            </div>

            <div className="dropdown-footer">
              {process.env.REACT_APP_FAQ_URL && (
                <a
                  href={process.env.REACT_APP_FAQ_URL}
                  className="redirect-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="material-symbols-rounded icon-left material-symbols-filled">
                    help_outline
                  </span>
                  {I18n.t('dropdown.help.button').toUpperCase()}
                </a>
              )}
              <Button
                onClick={() => this.props.showOnBoarding(true)}
                className={'text-link-button'}
                iconClass={'replay'}
                iconButton={true}
                translate={true}
                text={'dropdown.help.footer'}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default DropdownHelp;
