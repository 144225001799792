import React from 'react';

export const NumberLabel = (props) => {
  const formatValue = (string) => {
    return string !== undefined || string !== null
      ? parseFloat(string?.toString().replace(/,|\./g, '')).toLocaleString(
          'es-AR',
          { minimumFractionDigits: 0 },
        )
      : '-';
  };
  return props.asP ? (
    <p className={props.className} style={props.style}>
      {(props.text || '') + ' ' + formatValue(props.value)}
    </p>
  ) : (
    <span className={props.className} style={props.style}>
      {(props.text || '') + ' ' + formatValue(props.value)}
    </span>
  );
};
