import { sessionActions } from '../session/session.actions';
import { persons } from '../persons/persons.actions';
import { campaigns } from '../campaigns/campaigns.actions';
import { campaignsMigrations } from '../campaignsMigrations/campaignsMigrations.actions';
import { dashboard } from '../dashboard/dashboard.actions';
import { users } from '../users/users.actions';
import { jobs } from '../jobs/jobs.actions';
import { clients } from '../clients/clients.actions';
import { profile } from '../profile/profile.actions';
import { common } from '../components/common.actions';
import { bapro } from '../bapro/bapro.actions';
import { reports } from '../reports/reports.actions';

export default {
  session: sessionActions,
  persons,
  campaigns,
  campaignsMigrations,
  dashboard,
  users,
  jobs,
  clients,
  profile,
  common,
  bapro,
  reports
};
