import React from 'react';
import { Component } from 'react';
import './NewCampaign.css';
import Input from '../../../components/common/Input';
import Label from '../../../components/common/Label';
import Tooltip from '../../../components/common/Tooltip';
import { I18n, Translate } from 'react-redux-i18n';
import Dropdown from '../../../components/common/Dropdown';
import NewIndicator from '../../../components/common/NewIndicator/NewIndicator';
import CreditsWidgets from '../../../components/common/CreditsWidgets/CreditsWidgets';
import Button from '../../../components/common/Button';
import Credits from '../../containers/Credits';
import {
  SnackBar,
  successMessage,
  errorMessage,
} from '../../../utils/snackbar';
import { notify } from 'react-notify-toast';
import { success } from '../../../utils/modals';
import ConfirmModal from '../../../components/common/ConfirmModal';
import SquareButton from '../../../components/common/SquareButton/SquareButton';
import Loader from '../../../components/common/Loader/Loader';
import { Tabs, Tab } from 'react-bootstrap';
import FormDropdown from '../../../components/common/Dropdown/FormDropdown';
import IeCard from '../../../components/common/IeCard/IeCard';

/**
 * Notes
 * - use all positions from this.props
 * - what you save into this.state are the selectedCampaing or just empty array
 * - when addOption is requested, it needs to change this.state positions
 *
 */

const emptyState = {
  // heightCard: 500,
  values: {
    campaign_type: { inputValue: '' },
    ie_game_active: { inputValue: false },
    autoDelivery: { inputValue: true },
    name: { inputValue: '' },
    positions: {
      inputValue: { code: 0, description: I18n.t('persons.selectJobs') },
    },
    codeEmail: { inputValue: '' },
    max_credits: {
      inputValue: '',
      validator: () => !this.props.errorCode,
    },
    fields: { inputValue: [] },
  },
  submit: false,
  submitUpdate: false,
  show: false,
  // showDelete: false,
  code: {},
  update: undefined,
};

const loadValues = (campaign) => {
  const {
    name,
    campaign_type,
    credits: max_credits,
    auto_report_delivery: auto_result_delivery,
    positions,
    fields: campaign_fields,
    ie_game_active,
  } = campaign;

  return {
    name: { inputValue: name || '' },
    campaign_type: {
      inputValue: {
        code: campaign_type || 'MASSIVE',
        description:
          campaign_type === 'DIRECT'
            ? I18n.t('campaigns.DIRECT')
            : I18n.t('campaigns.MASSIVE'),
      },
    },
    max_credits: {
      inputValue: max_credits || '',
      validator: () => !this.props.errorCode,
    },
    autoDelivery: { inputValue: auto_result_delivery },
    /* positions: {
      inputValue: positions.map((position) => ({
        code: position.id,
        description: position.name,
      })),
    }, */
    fields: {
      inputValue: campaign_fields?.map((field) => ({
        ...field,
        value: { inputValue: field.value },
      })),
    },
    ie_game_active: {
      inputValue: ie_game_active,
    },
  };
};

class NewCampaign extends Component {
  constructor(props) {
    super(props);

    this.state =
      props.selectedCampaign && props.selectedCampaign.id
        ? {
            ...emptyState,
            update: props.update,
            values: loadValues(props.selectedCampaign),
          }
        : // values: {}
          {
            ...emptyState,
            values: {
              ...emptyState.values,
              max_credits: {
                inputValue: '',
                validator: (value) => {
                  if (value <= this.availableCredits() && !this.props.errorCode)
                    return true;
                  return false;
                },
              },
            },
          };
  }

  handleHeights(unmount = false) {
    // if (!unmount) {
    //   //console.log(document.getElementById('uncontrolled-tab-example'));
    //   if (document.getElementById('tab-container')) {
    //     document.getElementById('tab-container').children[1].style.height = `${
    //       document.getElementById('credits-col')?.offsetHeight
    //     }px`;
    //     document.getElementById(
    //       'tab-container',
    //     ).children[1].style.maxHeight = `${
    //       document.getElementById('credits-col')?.offsetHeight
    //     }px`;
    //   }
    // }
  }

  componentWillMount() {
    this.props?.campaignCleanErrors();
    // window.addEventListener('load', () => this.handleHeights(true));
    // window.addEventListener('resize', () => this.handleHeights(true));
  }

  componentDidMount() {
    // window.addEventListener('load', () => this.handleHeights());
    // window.addEventListener('resize', () => this.handleHeights());

    if (!this.props.getCampaignsStatus?.success) {
      this.props.getCampaigns();
    }
    const positionDropdown = true;
    this.props.getPositions({ positionDropdown });
    if (!this.props.selectedCampaign?.created) {
      this.props?.getFields();
    }

    if (this.props?.selectedCampaign.position) {
      this.setState({
        ...this.state,
        values: {
          ...this.state.values,
          positions: {
            inputValue: {
              code: this.props.selectedCampaign.position.id,
              description: this.props.selectedCampaign.position.name,
            },
          },
        },
      });
    } else {
      this.setState({
        ...this.state,
        values: {
          ...this.state.values,
          positions: {
            inputValue: {
              code: 0,
              description: I18n.t('jobs.job'),
            },
          },
        },
      });
    }

    if (parseInt(this.props?.location?.search.split('=')[1])) {
      this.props.positions.map((pos) => {
        if (
          parseInt(pos.id) ===
          parseInt(this.props?.location?.search.split('=')[1])
        ) {
          this.setState({
            ...this.state,
            values: {
              ...this.state.values,
              positions: {
                inputValue: {
                  code: pos.id,
                  description: pos.name,
                },
              },
            },
          });
        }
      });
    }
    // this.handleHeights();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.fields !== this.props.fields) {
      if (!this.props.selectedCampaign?.created) {
        this.setState({
          ...this.state,
          values: {
            ...this.state.values,
            fields: {
              inputValue: this.props.fields.map((field) => ({
                ...field,
                value: { inputValue: false },
              })),
            },
          },
        });
      } else {
        this.setState({
          ...this.state,
          values: {
            ...this.state.values,
            fields: {
              inputValue: this.props.fields.map((field) => ({
                ...field,
                value: {
                  inputValue: this.props.selectedCampaign.fields.some(
                    ({ id }) => id === field.id,
                  ),
                },
              })),
            },
          },
        });
      }
    }
    if (prevProps.positions !== this.props.positions) {
      const url = new URLSearchParams(window.location.search);
      if (url.has('id_position')) {
        const position = this.props.positions.find(
          (p) => p.id === parseInt(url.get('id_position')),
        );
        this.setState({
          ...this.state,
          idPosition: parseInt(url.get('id_position')),
          values: {
            ...this.state.values,
            positions: {
              inputValue: {
                code: position.id || 0,
                description: position.name || I18n.t('jobs.job'),
              },
            },
          },
        });
      }
    }
  }

  availableCredits = (withMax = false) => {
    let total;
    if (this.props.user_.partner) {
      total =
        this.props.user_.partner.credits -
        this.props.user_.partner.credits_assigned;
    } else if (this.props.user_.branch) {
      total =
        this.props.user_.branch.credits -
        this.props.user_.branch.credits_assigned;
    }

    if (withMax) {
      let max_credits = this.state.values.max_credits.inputValue
        ? this.state.values.max_credits.inputValue
        : 0;
      if (this.props.selectedCampaign.id)
        max_credits = max_credits - this.props.selectedCampaign.credits;
      total = total - max_credits;
    }
    return total >= 0 ? total : 0;
  };

  formIsValid(strict) {
    if (this.state.values.name.inputValue) {
      if (strict) {
        return this.state.values.max_credits.inputValue;
      }
      return true;
    }
    return false;
  }

  handleInputChange = (key, value) => {
    if (
      this.state.values[key].inputValue?.code === value.code &&
      key === 'positions'
    ) {
      this.setState({
        ...this.state,
        values: {
          ...this.state.values,
          [key]: {
            ...this.state.values[key],
            inputValue: {
              code: 0,
              description: I18n.t('jobs.job'),
            },
          },
        },
      });
    } else
      this.setState({
        ...this.state,
        values: {
          ...this.state.values,
          [key]: {
            ...this.state.values[key],
            inputValue: value,
          },
        },
      });
  };

  handleFieldChange = (id) => {
    const oldValue = this.state.values.fields.inputValue.find(
      (field) => field.id === id,
    )?.value?.inputValue;
    this.setState({
      ...this.state,
      values: {
        ...this.state.values,
        fields: {
          inputValue: this.state.values.fields.inputValue.map((field) =>
            field.id === id
              ? { ...field, value: { inputValue: !oldValue } }
              : field,
          ),
        },
      },
    });
  };

  addOption = (type, name, option) => {
    let newArray;
    if (type === 'add')
      newArray = [
        ...[{ ...option }].concat(this.state.values[name].inputValue),
      ];
    else if (type === 'remove')
      newArray = [
        ...this.state.values[name].inputValue.filter(
          (selectedOption) => selectedOption.code !== option.code,
        ),
      ];
    else newArray = [...this.state.values[name].inputValue];

    newArray.sort((elem1, elem2) => {
      return elem1.code - elem2.code;
    });
    this.setState({
      ...this.state,
      values: {
        ...this.state.values,
        [name]: { ...this.state.values[name], inputValue: newArray },
      },
    });

    if (this.fetchTiemout) {
      clearTimeout(this.fetchTiemout);
    }
    this.fetchTiemout = setTimeout(
      function () {
        this.setState({ ...this.state, fetch: true });
      }.bind(this),
      500,
    );
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (!this.formIsValid(true)) return;
    this.setState({ ...this.state, submitUpdate: true });
    if (!this.props.selectedCampaign.created) {
      this.props.createCampaign({
        created: true,
        name: this.state.values.name.inputValue,
        campaign_type: this.state.values.campaign_type.inputValue.code,
        positions_id: this.state.values.positions.inputValue.code,
        campaign_fields_id: this.state.values.fields?.inputValue?.reduce(
          (values, { id, value }) => {
            if (value.inputValue) values.push(id);
            return values;
          },
          [],
        ),
        credits: this.state.values.max_credits.inputValue,
        auto_report_delivery: this.state.values.autoDelivery.inputValue,
        ie_game_active: this.state.values.ie_game_active.inputValue,
      });
    } else {
      this.props.updateCampaign(this.props.selectedCampaign.id, {
        //created: true,
        name: this.state.values.name.inputValue,
        campaign_type: this.state.values.campaign_type.inputValue.code,
        positions_id: this.state.values.positions.inputValue.code,
        campaign_fields_id: this.state.values.fields?.inputValue?.reduce(
          (values, { id, value }) => {
            if (value.inputValue) values.push(id);
            return values;
          },
          [],
        ),
        credits: this.state.values.max_credits.inputValue,
        auto_report_delivery: this.state.values.autoDelivery.inputValue,
      });
    }
  };

  discard() {
    this.props.history.push('/main/campaigns');
  }

  handleSelect = (e) => {
    if (e === I18n.t('campaigns.identifier')) {
      this.props.history.push('/main/campaigns');
    }
    if (e === I18n.t('clients.creditsHistory')) {
      this.props.history.push('/main/credits-history');
    }
  };

  dropDownClean = () => {
    this.props.getPositions({ positionDropdown: true });
  };

  render() {
    const {
      createStatus,
      updateStatus,
      generateCodeStatus,
      selectedCampaign,
      history,
    } = this.props;

    /** Notifications */
    if (createStatus?.success) {
      notify.show(
        <SnackBar
          icon={'priority'}
          title={I18n.t('common.excellent')}
          body={I18n.t('campaigns.success.create')}
          type="success"
          iconFilled
        />,
        'custom',
        5000,
        successMessage,
      );
      this.props.clearUiValue('createStatus', 'success');
    }
    if (createStatus?.error) {
      notify.show(
        <SnackBar
          icon={'priority'}
          title={I18n.t('campaigns.error.create')}
          body={I18n.t('errorCodes.credits.no_credits')}
          type="error"
          iconFilled
        />,
        'custom',
        5000,
        errorMessage,
      );
      this.props.clearUiValue('createStatus', 'success');
    }
    if (generateCodeStatus?.success) {
      notify.show(
        <SnackBar
          icon={'priority'}
          title={I18n.t('common.excellent')}
          body={I18n.t('campaigns.success.generate')}
          type="success"
          iconFilled
        />,
        'custom',
        5000,
        successMessage,
      );
      this.props.clearUiValue('generateCodeStatus', 'success');
    }
    if (updateStatus?.success) {
      notify.show(
        <SnackBar
          icon={'priority'}
          title={I18n.t('common.excellent')}
          body={I18n.t('campaigns.success.update')}
          type="success"
          iconFilled
        />,
        'custom',
        5000,
        successMessage,
      );
      this.props.clearUiValue('updateStatus', 'success');
    }

    return (
      <div className="container-fluid">
        <ConfirmModal
          show={this.state.show}
          action={() =>
            this.props.deleteCode(
              this.state.code.selectedCampaign,
              this.state.code.id,
            )
          }
          onHide={() => this.setState({ ...this.state, show: false, code: {} })}
        />

        <div className="row new-campaign-wrapper">
          <div className="col-md-10 form-container">
            <Tabs onSelect={this.handleSelect} id="tab-container">
              <Tab
                eventKey={I18n.t('campaigns.identifier')}
                title={I18n.t('campaigns.identifier')}
              >
                <div className="new-campaign-container">
                  <div className="card-header">
                    <div className="back card-header-left">
                      <div className="left-side">
                        <Button
                          className={'text-primary p-0'}
                          iconButton={true}
                          iconClass={'expand_more'}
                          onClick={() => this.props.history.goBack()}
                        />
                        {selectedCampaign?.created ? (
                          <p style={{ wordBreak: 'break-all' }}>
                            {I18n.t('campaigns.edit')}
                          </p>
                        ) : (
                          <p style={{ wordBreak: 'break-all' }}>
                            {I18n.t('campaigns.new')}
                          </p>
                        )}
                      </div>
                      <div className="right-side">
                        <p>{I18n.t('campaigns.requiredFields')}</p>
                      </div>
                    </div>
                    {/* <div className="card-header-right">
                      {createStatus?.error && (
                        <div className="text-warning">
                          {I18n.t('campaigns.error.create')}
                        </div>
                      )}
                      {updateStatus?.error && (
                        <div className="text-warning">
                          {I18n.t('campaigns.error.update')}
                        </div>
                      )}
                    </div> */}
                  </div>
                  {this.renderForm()}
                </div>
              </Tab>
              <Tab
                eventKey={I18n.t('clients.creditsHistory')}
                title={I18n.t('clients.creditsHistory')}
              ></Tab>
            </Tabs>
          </div>

          {!this.props.getCampaignsStatus.loading && (
            <div className="campaign-credits col-md-2">
              <div className="credits-body" id="credits-col">
                <div className="credits-card">
                  <div className="col total-credits">
                    <div className="database">
                      <span className="material-symbols-rounded material-symbols-filled">
                        database
                      </span>
                      {I18n.t('credits.totalCredits')}
                    </div>
                    <div className="totalcredits-text">
                      <span className="text">
                        {I18n.t('credits.totalCreditsCredits')}
                      </span>
                      <span className="number">
                        {this.props.user_?.branch.credits ||
                          this.props.user_?.partner.credits}
                      </span>
                      <span className="text">
                        {I18n.t('credits.totalCreditsDescription')}
                      </span>
                    </div>
                  </div>
                  <div className="col">
                    <CreditsWidgets
                      material_symbol={'priority'}
                      title={I18n.t('credits.available')}
                      label={I18n.t('credits.availableCredits')}
                      value={this.availableCredits(true)}
                      description={
                        this.props.user_.account !== null
                          ? I18n.t('credits.availableCreditsDescription')
                          : I18n.t('credits.availableCreditsCampaign')
                      }
                      type={'column-view'}
                      renderStyle="available"
                    />
                  </div>
                  <div className="col">
                    <CreditsWidgets
                      material_symbol={
                        this.props.user_.account !== null
                          ? 'account_tree'
                          : 'campaign'
                      }
                      title={I18n.t('credits.associated')}
                      label={I18n.t('credits.associatedCredits')}
                      value={
                        this.props.user_.partner.credits_assigned ||
                        this.props.user_.branch.credits_assigned
                      }
                      description={
                        this.props.user_.account !== null
                          ? I18n.t('credits.associatedCreditsDescription')
                          : I18n.t('credits.associatedCreditsDescriptionFilial')
                      }
                      type={'column-view'}
                      renderStyle="associated"
                    />
                  </div>
                  {this.props.user_.branch ? (
                    <div className="col">
                      <CreditsWidgets
                        material_symbol={'groups'}
                        title={I18n.t('credits.assigned')}
                        label={I18n.t('credits.assignedCredits')}
                        value={
                          this.props.credits_user_status?._sum?.credits_assigned
                        }
                        description={I18n.t(
                          'credits.assignedCreditsDescription',
                        )}
                        type={'column-view'}
                        renderStyle={'actives'}
                      />
                    </div>
                  ) : null}

                  <div className="col">
                    <CreditsWidgets
                      material_symbol={'sports_esports'}
                      title={I18n.t('credits.consumed')}
                      label={I18n.t('credits.creditsConsumed')}
                      value={
                        this.props.credits_user_status?._sum?.credits_consumed
                      }
                      description={I18n.t('credits.creditsConsumedDescription')}
                      type={'column-view'}
                      renderStyle={'consumed'}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  renderForm() {
    const { update } = this.state;

    if (this.props.getCampaignsStatus?.loading) return <Loader />;
    else {
      if (this.props.getCampaignsStatus?.error)
        return (
          <div className="text-warning">{I18n.t('campaigns.error.get')}</div>
        );
      else if (this.props.getCampaignsStatus?.success) {
        const confirmIsValid = this.formIsValid(true);
        const maxError =
          this.state.values.max_credits.inputValue >
          this.availableCredits() + this.props.selectedCampaign.credits;
        return (
          <form id={'new-campaign-form'} onSubmit={this.handleSubmit}>
            <div className={'form-body'}>
              <div className={'form-inputs'}>
                <Input
                  label={I18n.t('campaigns.campaignName')}
                  name="name"
                  value={this.state.values.name}
                  change={this.handleInputChange}
                  required
                  placeholder={I18n.t('campaigns.newCampaignNamePlaceholder')}
                />
                <div
                  className={`associated-codes-section ${
                    this.state.values.max_credits.inputValue >
                    this.availableCredits() +
                      this.props.selectedCampaign.credits
                      ? 'max-error'
                      : ''
                  }`}
                >
                  <Input
                    label={I18n.t('campaigns.associatedCodes')}
                    name="max_credits"
                    type={'number'}
                    value={{
                      ...this.state.values.max_credits,
                      validator: (value) => {
                        if (
                          this.props.update &&
                          value <=
                            this.availableCredits() +
                              this.props.selectedCampaign.credits
                        )
                          return true;
                        if (
                          this.props.user_.partner.credits -
                            this.props.user_.partner.credits_assigned >=
                          value
                        )
                          return true;
                        return false;
                      },
                    }}
                    change={this.handleInputChange}
                    ignoreDirty
                    min={1}
                    required
                    placeholder={I18n.t('campaigns.associatedCodesPlaceholder')}
                    errorLogin={
                      this.props.errorCode !== 0 &&
                      !this.props.updateStatus.error
                    }
                    tooltip={
                      <Tooltip
                        className="associated-codes"
                        message="campaigns.campaignCredits"
                        materialIcon={
                          <span class="material-symbols-rounded">
                            contact_support
                          </span>
                        }
                      />
                    }
                  />
                  {this.props.updateStatus.error &&
                    this.state.submitUpdate &&
                    this.props.errorCode && (
                      <div className="text-warning">
                        {this.props.errorCode ? (
                          <Translate
                            value="errorCodes.credits.errorCodeUpdate"
                            dangerousHTML={true}
                          />
                        ) : (
                          I18n.t('campaigns.error.update')
                        )}
                      </div>
                    )}
                </div>
                <div
                  className="middle-form-section"
                  style={{ display: 'flex', flexDirection: 'row' }}
                >
                  <div className="middle-left">
                    <div className="position-select">
                      <FormDropdown
                        cleanOption
                        textCleanOption={I18n.t('toolbox.clearSelection')}
                        expandMore={true}
                        name="positions"
                        search={this.props.getPositions}
                        showEmptyOption
                        emptyOption={{
                          code: 0,
                          description: I18n.t('jobs.job'),
                        }}
                        getAllClientsPrompts={{
                          positionDropdown: true,
                          search: '',
                        }}
                        value={{
                          inputValue: {
                            code: this.state?.values?.positions?.inputValue
                              ?.code,
                            label:
                              this.state?.values?.positions?.inputValue
                                ?.description,
                          },
                        }}
                        dropDownClean={this.dropDownClean}
                        change={this.handleInputChange}
                        options={this.props.positions.map((pos) => ({
                          code: pos.id,
                          description: pos.name,
                        }))}
                      />
                      {/*  <span class="material-symbols-rounded">expand_more</span> */}
                    </div>
                    <Tooltip
                      className="campaignPosition"
                      message="campaigns.campaignPosition"
                      materialIcon={
                        <span class="material-symbols-rounded">
                          contact_support
                        </span>
                      }
                    />
                  </div>
                  <div className="middle-right">
                    <SquareButton
                      setValue={!this.state.values.autoDelivery.inputValue}
                      change={this.handleInputChange}
                      label="campaigns.autoDeliveryAlternative"
                      name={'autoDelivery'}
                      value={this.state.values.autoDelivery}
                      disabled={!!this.props.selectedCampaign?.id}
                      className={`${
                        this.state.values.fields.inputValue && 'disabled'
                      }`}
                    />
                    <Tooltip
                      className="campaignAutoSend"
                      message="campaigns.campaignAutoSend"
                      materialIcon={
                        <span class="material-symbols-rounded">
                          contact_support
                        </span>
                      }
                    />
                  </div>
                </div>
                <div
                  className="bottom-header"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <div className="bottom-label">
                    <Label label="campaigns.registrationOptionalFields" />
                    <Tooltip
                      className="optionalFields"
                      message="campaigns.optionalFieldsTooltip"
                      materialIcon={
                        <span class="material-symbols-rounded">
                          contact_support
                        </span>
                      }
                    />
                  </div>
                  <div className="bottom-tooltip"></div>
                </div>
                <div className="optional-container">
                  <div
                    className={`optional-original ${
                      (this.state.values.fields.inputValue[4]?.field_name ===
                        'field_EAE' ||
                        this.state.values.fields.inputValue[4]?.name ===
                          'field_EAE') &&
                      'fields-right'
                    }`}
                  >
                    {this.state.values.fields.inputValue
                      ?.slice(0, 4)
                      .map(({ id, name, value, field_name }) => {
                        return (
                          <div
                            className={`options-check ${
                              this.props.selectedCampaign?.id && 'disabled'
                            }`}
                            style={{ display: 'flex', flexDirection: 'row' }}
                            key={id}
                          >
                            <SquareButton
                              setValue={value}
                              change={() => this.handleFieldChange(id)}
                              label={I18n.t(
                                `campaigns.fields.${
                                  this.props.update ? name : field_name
                                }`,
                              )}
                              name={name ? name : field_name}
                              value={value}
                              disabled={this.props.selectedCampaign?.id}
                            />
                          </div>
                        );
                      })}
                  </div>
                  <div className="optional-account-field">
                    {(this.state.values.fields.inputValue[4]?.field_name ===
                      'field_EAE' ||
                      this.state.values.fields.inputValue[4]?.name ===
                        'field_EAE') && (
                      <h3>{I18n.t('campaigns.EAE_title')}</h3>
                    )}
                    {this.state.values.fields.inputValue
                      ?.slice(4, 5)
                      .map(({ id, name, value, field_name }) => {
                        return (
                          <div
                            className={`options-check ${
                              this.props.selectedCampaign?.id && 'disabled'
                            }`}
                            style={{ display: 'flex', flexDirection: 'row' }}
                            key={id}
                          >
                            <SquareButton
                              setValue={value}
                              change={() => this.handleFieldChange(id)}
                              label={I18n.t(
                                `campaigns.fields.${
                                  this.props.update ? name : field_name
                                }`,
                              )}
                              name={name ? name : field_name}
                              value={value}
                              disabled={this.props.selectedCampaign?.id}
                            />
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
              {process.env.REACT_APP_ENABLED_IA_GAME === 'true' &&
                (this.props.user_.branch?.account?.ie_active === true ||
                  this.props.user_.account?.ie_active === true) && (
                  <IeCard
                    value={this.state.values.ie_game_active.inputValue}
                    onChange={this.handleInputChange}
                    disabled={this.props.selectedCampaign?.id ? true : false}
                  />
                )}
            </div>
            <div className={'form-footer'}>
              {this.props.selectedCampaign.created ? (
                [
                  <div>{this.renderLoaderSpinners()}</div>,
                  <div
                    style={{ margin: '5px', display: 'flex' }}
                    key="save-campaign-button"
                  >
                    <Button
                      text={I18n.t('campaigns.save')}
                      className="primary"
                      type="button"
                      onClick={(e) => this.handleSubmit(e, true)}
                      disabled={!confirmIsValid || maxError}
                      iconButton
                      iconFilled={true}
                      iconClass="save"
                    />
                  </div>,
                ]
              ) : (
                <div className="buttons">
                  <div className={'button-group'}>
                    <Button
                      text={I18n.t('campaigns.createCampaign')}
                      className="primary"
                      disabled={!confirmIsValid}
                      iconButton
                      iconClass={'add'}
                    />
                  </div>
                  <Button
                    text={I18n.t('campaigns.discard')}
                    className="warning"
                    type="button"
                    onClick={() => this.discard()}
                  />
                  <div>{this.renderLoaderSpinners()}</div>
                </div>
              )}
            </div>
            {/* {this.props.updateStatus.error &&
              this.state.submitUpdate &&
              this.props.errorCode && (
                <div
                  className="text-warning"
                  style={{ margin: '10px 15px 0 10px' }}
                >
                  {this.props.errorCode
                    ? I18n.t('errorCodes.credits.no_credits')
                    : I18n.t('campaigns.error.update')}
                </div>
              )} */}
            {this.props.createStatus.error &&
              this.state.submitUpdate &&
              this.props.errorCode && (
                <div className={'text-warning'}>
                  {this.props.errorCode
                    ? I18n.t('errorCodes.credits.no_credits')
                    : I18n.t('campaigns.error.update')}
                </div>
              )}
          </form>
        );
      } else {
        return <Loader />;
      }
    }
  }

  renderLoaderSpinners() {
    const { createStatus, updateStatus, campaignDeleteStatus } = this.props;
    if (
      updateStatus.loading ||
      createStatus.loading ||
      campaignDeleteStatus.loading
    )
      return <Loader />;
    return null;
  }
}

export default NewCampaign;
