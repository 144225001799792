import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Translate, I18n } from 'react-redux-i18n';
import './LanguageSelector.css';
import dropdownIcon from '../../../assets/icons/down-arrow-LIGHT-24x24.png';
import Loader from '../Loader';
import { languagesCodes } from '../../../i18n';

class LanguageSelector extends Component {
  static defaultProps;

  state = {
    showOptions: false,
    filterValue: '',
    showTitle: false,
  };

  handleHideOptionsState = (ev) => {
    if (
      ev.path.filter((element) => element.id === this.props.name).length === 0
    ) {
      this.setState({ ...this.state, showOptions: false });
    }
  };

  handleSelection(e, option) {
    if (this.props.multiSelect) {
      if (this.optionIsSelected(option)) {
        this.props.addOption('remove', this.props.name, option);
      } else {
        this.props.addOption('add', this.props.name, option);
        this.props.oneSelect &&
          this.setState({ ...this.state, showOptions: false });
      }
    } else {
      this.props.change(this.props.name, option);
    }
  }

  handleLanguageChange(option) {
    let newOption = option === 'por' ? 'pt' : option;
    localStorage.setItem('language', newOption);
    window.location.reload();
  }

  optionIsSelected = (option) => {
    return this.props.value.inputValue.find(
      (currentOption) => currentOption.code === option.code,
    );
  };

  getCurrentLanguage() {
    let current_language = localStorage.getItem('language');
    return current_language || 'es';
  }
  removeDuplicates(originalArray, objKey) {
    let trimmedArray = [];
    let values = [];
    let value;

    for (let i = 0; i < originalArray.length; i++) {
      value = originalArray[i][objKey];
      if (values.indexOf(value) === -1) {
        trimmedArray.push(originalArray[i]);
        values.push(value);
      }
    }
    return trimmedArray;
  }

  filterAndOrder(array) {
    const { removeDuplicates, filter, order } = this.props;
    let result = array;
    result = removeDuplicates
      ? this.removeDuplicates(result, 'description')
      : result;
    if (filter && this.state.filterValue) {
      result = array.filter(
        (option) =>
          (option.label || option.description) &&
          (option.label || option.description)
            .toLowerCase()
            .includes(this.state.filterValue.toLowerCase()),
      );
    }
    if (order) {
      result = result.sort(function (a, b) {
        return a.description || a.label
          ? (a.description || a.label).localeCompare(b.description || b.label)
          : 0;
      });
    }
    return result;
  }

  render() {
    const {
      name, // id
      multiSelect,
      translate, // apply I18n.t(...)
      className, // style gray border
      onSelect, // function who could be defined
      selected, // when selected language is managed outside the component
      label, // label for the forms where this selector is used
      disabled,
      showTitle,
    } = this.props;

    let options = languagesCodes.map((code) => ({
      code: code,
      description: I18n.t('languages.' + code),
    }));
    return (
      <div className="language-selector">
        <div
          className={`dropdown-container ${className ? className : ''}`}
          id={name}
          onClick={() =>
            multiSelect &&
            document.addEventListener('mousedown', this.handleHideOptionsState)
          }
        >
          {label && (
            <label
              className={
                'input-label ' + 'required' + (disabled ? ' disabled' : '')
              }
            >
              {label} <span className="required-icon">*</span>
            </label>
          )}
          <ul
            className={
              'custom-dropdown-menu' + (options.length === 0 ? ' no-items' : '')
            }
          >
            {showTitle && (
              <div className="title">
                <span className="material-symbols-rounded">{'Public'}</span>
                <span className="menu-link-no-button">
                  {I18n.t('users.idiom')}
                </span>
              </div>
            )}
            <div className="buttons">
              <ul
                className={
                  'custom-dropdown-menu' +
                  (options.length === 0 ? ' no-items' : '')
                }
              >
                {options.map((option, i) => {
                  return (
                    <li
                      // si recibe props de selection se utiliza ese valor para
                      // mostrar el idioma seleccionado, sino, utiliza el idioma
                      // de las props
                      className={`${option.subitem ? 'sub-item' : ''} ${
                        disabled || option.disabled ? 'disabled' : ''
                      }  
            
              ${
                selected
                  ? selected === option.code
                    ? 'selected'
                    : 'not-selected'
                  : this.getCurrentLanguage() === option.code
                  ? 'selected'
                  : 'not-selected'
              }`}
                      key={option.code + '-' + i}
                      onClick={() =>
                        !disabled
                          ? onSelect
                            ? onSelect('language', option.code)
                            : this.handleLanguageChange(option.code)
                          : ''
                      }
                    >
                      <span>
                        {option.code === 'pt'
                          ? 'por'
                          : translate
                          ? I18n.t(option.code || option.description)
                          : option.code || option.description}
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </ul>
        </div>
      </div>
    );
  }

  renderMultiSelectButtonContent() {
    const { loading, value, placeholder } = this.props;
    let result = [];
    if (loading) return <Loader mini />;
    else {
      if (value.inputValue.length > 0)
        result.push(
          <label>
            {value.inputValue.map((value) => value.description).join(', ')}
          </label>,
        );
      else if (placeholder) result.push(<label>{placeholder}</label>);
      else result.push(<label>{I18n.t('dropdown.default')}</label>);
    }
    result.push(<img src={dropdownIcon} alt="" />);
    return result;
  }

  renderSingleSelectButtonContent() {
    const { loading, value, translate, placeholder } = this.props;
    const { description, label } = this.props.value.inputValue;
    if (loading) return <Loader mini />;
    else
      return [
        <label key="dropdown-single-select-label">
          {value.inputValue
            ? (description || label) && translate
              ? I18n.t(description || label)
              : description || label || placeholder
            : placeholder || I18n.t('dropdown.default')}
        </label>,
        <img key="dropdown-single-select-img" src={dropdownIcon} alt="" />,
      ];
  }
}

LanguageSelector.defaultProps = {
  value: {},
};
LanguageSelector.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array,
  change: PropTypes.func,
  addOption: PropTypes.func,
  value: PropTypes.object,
  label: PropTypes.string,
  disabled: PropTypes.any,
  translate: PropTypes.bool,
  noBorder: PropTypes.bool,
  navbarFont: PropTypes.bool,
  multiSelect: PropTypes.bool,
  oneSelect: PropTypes.bool,
  removeDuplicates: PropTypes.bool,
  placeholder: PropTypes.string,
};

export default LanguageSelector;
