export const GET_LOGIN_ATTEMPTS = 'GET_LOGIN_ATTEMPTS';
export const LOGIN = 'LOGIN';
export const LOGIN_RESPONSE = 'LOGIN_RESPONSE';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const BAPRO_LOGIN = 'BAPRO_LOGIN';
export const BAPRO_LOGIN_RESPONSE = 'BAPRO_LOGIN_RESPONSE';
export const BAPRO_LOGIN_ERROR = 'BAPRO_LOGIN_ERROR';
export const GOOGLE_LOGIN = 'GOOGLE_LOGIN';
export const GOOGLE_LOGIN_RESPONSE = 'GOOGLE_LOGIN_RESPONSE';
export const GOOGLE_LOGIN_ERROR = 'GOOGLE_LOGIN_ERROR';
export const LOGIN_FORM_RESET = 'LOGIN_FORM_RESET';
export const GET_PARTNER = 'GET PARTNER';
export const GET_PARTNER_RESPONSE = 'GET PARTNER RESPONSE';
export const GET_PARTNER_ERROR = 'GET PARTNER ERROR';
export const SAVE_REDIRECT_ROUTE = 'SAVE REDIRECT ROUTE';
export const LOGOUT = 'LOGOUT';
export const LOGOUT_RESPONSE = 'LOGOUT RESPONSE';
export const TOGGLE_HELP = 'TOGGLE_HELP';
export const ACTIVATE_ACCOUNT = 'ACTIVATE ACCOUNT';
export const ACTIVATE_ACCOUNT_RESPONSE = 'ACTIVATE ACCOUNT RESPONSE';
export const ACTIVATE_ACCOUNT_ERROR = 'ACTIVATE ACCOUNT ERROR';
export const RECOVER_PASSWORD = 'RECOVER PASSWORD';
export const RECOVER_PASSWORD_RESPONSE = 'RECOVER PASSWORD RESPONSE';
export const RECOVER_PASSWORD_ERROR = 'RECOVER PASSWORD ERROR';
export const RECOVER_PASSWORD_CLEAN_ERROR = 'RECOVER PASSWORD CLEAN ERROR';
export const RESET_PASSWORD = 'RESET PASSWORD';
export const RESET_PASSWORD_RESPONSE = 'RESET PASSWORD RESPONSE';
export const RESET_PASSWORD_ERROR = 'RESET PASSWORD ERROR';
export const VALIDATE_TOKEN_CHANGE_PASSWORD = 'VALIDATE TOKEN CHANGE PASSWORD';
export const VALIDATE_TOKEN_CHANGE_PASSWORD_RESPONSE =
  'VALIDATE TOKEN CHANGE PASSWORD RESPONSE';
export const VALIDATE_TOKEN_CHANGE_PASSWORD_ERROR =
  'VALIDATE TOKEN CHANGE PASSWORD ERROR';
export const OPEN_ID_AUTH_RESPONSE = 'OPEN ID AUTH RESPONSE';
export const UPDATE_LOGGEDIN_USER = 'UPDATE LOGGEDIN USER';

export const sessionActions = {
  getLoginAttempts: () => ({ type: GET_LOGIN_ATTEMPTS }),

  login: (email, password) => ({ type: LOGIN, email, password }),
  loginResponse: (response) => ({ type: LOGIN_RESPONSE, response: response }),
  loginError: (response) => ({ type: LOGIN_ERROR, response: response }),
  baproLogin: (email, password) => ({ type: BAPRO_LOGIN, email, password }),
  baproLoginResponse: (response) => ({
    type: BAPRO_LOGIN_RESPONSE,
    response: response,
  }),
  baproLoginError: (error) => ({
    type: BAPRO_LOGIN_ERROR,
    response: error,
  }),
  googleLogin: (code, token) => ({
    type: GOOGLE_LOGIN,
    code,
    token,
  }),
  googleLoginResponse: (response) => ({
    type: GOOGLE_LOGIN_RESPONSE,
    response: response,
  }),
  googleLoginError: (error) => ({
    type: GOOGLE_LOGIN_ERROR,
    response: error,
  }),
  loginFormReset: () => ({ type: LOGIN_FORM_RESET }),

  getPartner: (id) => ({ type: GET_PARTNER, id: id }),
  getPartnerResponse: (response) => ({
    type: GET_PARTNER_RESPONSE,
    response: response,
  }),
  getPartnerError: (error) => ({ type: GET_PARTNER_ERROR, response: error }),

  logout: () => ({ type: LOGOUT }),
  logoutResponse: () => ({ type: LOGOUT_RESPONSE }),

  saveRedirectRoute: (path) => ({ type: SAVE_REDIRECT_ROUTE, path }),

  toggleHelp: (showHelp, step) => ({ type: TOGGLE_HELP, showHelp, step }),

  activateRequest: (token) => ({ type: ACTIVATE_ACCOUNT, token }),
  activateResponse: (response) => ({
    type: ACTIVATE_ACCOUNT_RESPONSE,
    response: response,
  }),
  activateError: (error) => ({ type: ACTIVATE_ACCOUNT_ERROR, response: error }),

  recoverPassword: (email) => ({ type: RECOVER_PASSWORD, email }),
  recoverPasswordResponse: (response) => ({
    type: RECOVER_PASSWORD_RESPONSE,
    response,
  }),
  recoverPasswordError: (error) => ({ type: RECOVER_PASSWORD_ERROR, error }),
  recoverPasswordCleanError: () => ({
    type: RECOVER_PASSWORD_CLEAN_ERROR,
  }),

  resetPassword: (data) => ({ type: RESET_PASSWORD, data }),
  resetPasswordResponse: (response) => ({
    type: RESET_PASSWORD_RESPONSE,
    response,
  }),
  resetPasswordError: (error) => ({ type: RESET_PASSWORD_ERROR, error }),

  validateTokenChangePassword: (data) => ({
    type: VALIDATE_TOKEN_CHANGE_PASSWORD,
    data,
  }),

  openIdAuthResponse: (access_token, expires_in) => ({
    type: OPEN_ID_AUTH_RESPONSE,
    access_token,
    expires_in,
  }),

  updateLoggedinUser: (user) => ({ type: UPDATE_LOGGEDIN_USER, user }),
};
