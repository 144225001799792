import React, { useRef, useState } from 'react';
import './IePersonCard.sass';
import Beta from '../../../components/common/Beta/Beta';
import { I18n } from 'react-redux-i18n';
import Button from '../../../components/common/Button';
import Loader from '../../../components/common/Loader';
import IeContainerResult from '../IeContainerResult/IeContainerResult';
import IeTemplate from '../IeTemplate/IeTemplate';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const IePersonCard = ({
  hasInvitation = false,
  result = undefined,
  playerName,
  onClick,
  disabled,
  personDetails,
  handleProfileLoading,
  lang,
}) => {
  const axes = [
    'self-knowledge',
    'self-regulation',
    'motivation',
    'empathy',
    'social-skills',
  ];

  const [loading, setLoading] = useState(false);

  const childRef = useRef(null);

  const handleDownload = async () => {
    if (childRef.current) {
      setLoading(true);
      handleProfileLoading(true);
      const canvas = await html2canvas(childRef.current, {
        scale: 2,
        setFontSize: 20,
        windowWidth: 1366,
      });
      const imgData = canvas.toDataURL('image/jpeg');
      const pdf = new jsPDF('p', 'mm', 'a4');

      const margin = 10;
      const pdfWidth = pdf.internal.pageSize.getWidth() - 2 * margin;
      const pdfHeight = pdf.internal.pageSize.getHeight() - 0.3 * margin;

      const imgProps = pdf.getImageProperties(imgData);
      const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;
      let heightLeft = imgHeight;
      let position = 0;

      pdf.setFontSize(14);

      pdf.addImage(
        imgData,
        'JPEG',
        margin,
        position + margin,
        pdfWidth,
        imgHeight,
      );
      heightLeft -= pdfHeight;

      while (heightLeft > 0) {
        pdf.addPage();
        position = heightLeft - imgHeight;
        pdf.addImage(
          imgData,
          'JPEG',
          margin,
          position + margin,
          pdfWidth,
          imgHeight,
        );
        heightLeft -= pdfHeight;
      }

      const fileName = `${personDetails.user.first_name}_${
        personDetails.user.last_name
      }_${I18n.t('common.ie')}.pdf`;

      pdf.save(fileName, { returnPromise: true }).then(() => {
        setLoading(false);
        handleProfileLoading(false);
      });
    }
  };

  return (
    <>
      <div className="ie-person-card-cointainer">
        <div className="ie-header">
          <span className="material-symbols-filled">diagnosis</span>
          <h3>{I18n.t('campaigns.ie.title')}</h3>
          <Beta />
          {loading ? (
            <Loader mini />
          ) : (
            <Button
              className="secondary"
              text={I18n.t('persons.downloadReport')}
              iconClass="download"
              iconButton
              disabled={!result ? true : false}
              onClick={handleDownload}
            />
          )}
        </div>

        {!hasInvitation && !result && (
          <div className="without-invitation invitation">
            <p
              dangerouslySetInnerHTML={{
                __html: I18n.t('persons.IE.text-without-invitation', {
                  name: playerName,
                }),
              }}
            />

            <Button
              className={'secondary'}
              iconButton={true}
              iconFilled={true}
              iconClass={'sports_esports'}
              text={I18n.t('persons.IE.button')}
              disabled={disabled}
              onClick={onClick}
            />
          </div>
        )}
        {hasInvitation && !result && (
          <div className="with-invitation invitation">
            <p
              dangerouslySetInnerHTML={{
                __html: I18n.t('persons.IE.text-with-invitation', {
                  name: playerName,
                }),
              }}
            />
          </div>
        )}
        {result && (
          <div>
            <div
              style={{ position: 'absolute', top: '-9999px' }}
              ref={childRef}
            >
              <IeTemplate
                result={result.answer.split(',').flatMap((total, i) => ({
                  value: parseInt(total),
                  axis: axes[i],
                }))}
                personDetails={personDetails}
                playerName={playerName}
                lang={lang}
              />
            </div>
            <IeContainerResult
              result={result.answer.split(',').flatMap((total, i) => ({
                value: parseInt(total),
                axis: axes[i],
              }))}
              playerName={playerName}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default IePersonCard;
