import { connect } from 'react-redux';
import actions from '../../actions/index';
import UsersScreen from '../components/UsersScreen/UsersScreen';
import { withRouter } from 'react-router';

const mapDispatchToProps = (dispatch) => ({
  getUser: (id) => dispatch(actions.users.getUser(id)),
  getUsers: (search) => dispatch(actions.users.getUsers(search)),
  selectUser: (id) => dispatch(actions.users.selectUser(id)),
  updatePage: (page) => dispatch(actions.users.updatePage(page)),
  updatePageSize: (pageSize) =>
    dispatch(actions.users.updatePageSize(pageSize)),
  updateFilterValues: (filter) =>
    dispatch(actions.users.updateFilterValues(filter)),
  filterUsers: (filter) => dispatch(actions.users.getUsers(filter)),
  clearUiValue: (type, condition) =>
    dispatch(actions.users.clearUiValue(type, condition)),
  deleteUser: (id) => dispatch(actions.users.deleteUser(id)),
  getFilterDates: (filterDates) =>
    dispatch(actions.common.getFilterDates(filterDates)),
});

const mapStateToProps = (state, ownProps) => ({
  userInfo: state.session.user,
  users: state.users.results,
  currentPage: state.users.current,
  totalPages: state.users.total_pages,
  totalCount: state.users.count,
  pageSize: state.users.page_size,
  desc: state.users.desc,
  start_date: state.dashboard.start_date,
  end_date: state.dashboard.end_date,
  dateStatus: state.dashboard.dateStatus,
  ordering: state.users.filterValues.ordering,
  createStatus: state.users.create,
  retrieveStatus: state.users.getUsersStatus,
  updateStatus: state.users.update,
  deleteStatus: state.users.delete,
  language: state.i18n.locale,
  filterValues: state.users.filterValues,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(UsersScreen));
