import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { I18n } from 'react-redux-i18n';
import Button from '../../../components/common/Button';
import './EmailReportConfirmModal.scss';

export class EmailReportConfirmModal extends Component {
  handleConfirm = (e) => {
    e.preventDefault();
    this.props.onConfirm();
    this.props.onHide();
  };

  render() {
    return (
      <Modal
        show={this.props.show}
        className="email-report-confirm-modal"
        onHide={this.props.onHide}
      >
        <Modal.Header className={'modal-header'}>
          <span class="material-symbols-filled">assignment</span>
          <h4 className="modal-title">{I18n.t('persons.emailReport.title')}</h4>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <p>
            {I18n.t('persons.emailReport.description', {
              name: this.props.name,
            })}
          </p>
        </Modal.Body>
        <Modal.Footer className="modal-footer">
          <Button
            className="alternative-gray-modal cancel-button"
            text={I18n.t('buttons.cancel')}
            onClick={this.props.onHide}
          />
          <Button
            className="primary confirm-button"
            text={I18n.t('persons.emailReport.resend')}
            onClick={this.handleConfirm}
          />
        </Modal.Footer>
      </Modal>
    );
  }
}

export default EmailReportConfirmModal;
