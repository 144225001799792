import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './UpdateUserModal.css';
import { Modal } from 'react-bootstrap';
import { I18n } from 'react-redux-i18n';
import Button from '../Button';

class UpdateUserModal extends Component {
  static defaultProps;

  render() {
    return (
      <Modal
        show={this.props.show}
        dialogClassName="delete-user"
        onHide={this.props.onHide}
        width={{}}
      >
        <Modal.Header className={'modal-header'}>
          <h4 className="modal-title">{I18n.t('users.updateModalTitle')}</h4>
        </Modal.Header>
        <Modal.Body>
          <p>{I18n.t('users.updateModalBody')}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="primary"
            text={I18n.t('buttons.continue')}
            onClick={(e) => {
              this.props.action(e);
              this.props.onHide();
            }}
          />
        </Modal.Footer>
      </Modal>
    );
  }
}

UpdateUserModal.defaultProps = {};
UpdateUserModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
};

export default UpdateUserModal;
