import React from 'react';
import logoFull from '../../../../assets/images/logo_full.png';
import { I18n } from 'react-redux-i18n';
import ReactTable from 'react-table';
import ProgressBar from '../../../../components/common/ProgressBar/ProgressBar';
import './GeneratePdfHtml.scss';

/**
 * - Target component for html2pdf.
 * - id matches GroupTrends 'getElementById' in componentDidUpdate
 * - This component is rendered behind some div with background,
 * so that is not seen by the user.
 * - This component has css style meant for printing a pdf, not to be
 * shown to the user:
 *      > removes sorting indicator on header of table (BUG, if activated indicator is not shown as intended)
 *
 * props:
 * @param { int } id component id used to target what to print
 * @param { string } screen compatibility/jobs
 * @param { array } columns profile skills selected, its capped to 6 columns
 * @param { array } data every 'row'
 * @param { int } pageSize number of rows to show
 * @param { object } profileSkillsRangesStatus when data changed, status is kept here
 */
export const GeneratePdfHtml = (props) => {
  const {
    id,
    screen,
    columns,
    data,
    profileSkillsRangesStatus,
    defaultSorted,
  } = props;
  const limitatedColumns = columns.slice(0, 4);

  /**
   * @description separates array into array of arrays based in two constants.
   * @param { array } data
   * @returns { array of arrays }
   *
   * @constant rowsInFirstPage first rendered table is in the first page of the
   * pdf, will be shorter than the rest of the tables because it has a header.
   * @constant rowsInRestOfPage the rest of the tables, will be rendered occupying
   * all the page in the pdf.
   *
   * +-----------+
   * |Header     |
   * |FirstTable | -> shorter than full page size
   * +-----------+
   * |SecondTable| -> full page size
   * +-----------+
   * |    ...    | -> full page size
   * +-----------+
   * |LastTable  | -> full page size
   * +-----------+
   */
  const separateData = (dataGiven) => {
    const result = [[]];
    const rowsInFirstPage = 13;
    const rowsInRestOfPage = 15;
    let pageCounter = 0;
    let counter = 0;
    dataGiven.forEach((item) => {
      if (result.length === 1) {
        if (counter === rowsInFirstPage) {
          counter = 0;
          pageCounter++;
          result.push([item]);
        } else {
          counter++;
          result[pageCounter].push(item);
        }
      } else {
        if (counter === rowsInRestOfPage) {
          counter = 0;
          pageCounter++;
          result.push([item]);
        } else {
          counter++;
          result[pageCounter].push(item);
        }
      }
    });
    return result;
  };

  /**
   * @description usa props.data, los sortea en funcion a props.defaultSorted.
   * id -> qué columna sortear. desc -> descendiente o ascendiente.
   *
   * una vez terminado, usa una funcion auxiliar (solo se usa en esta funcion, podría
   * estar todo junto) para separar el array a array de arrays.
   *
   * render() va a renderizar tablas dependiendo de la cantidad de arrays en el res-
   * ultado devuelto por este metodo.
   *
   * @returns { array of arrays }
   */
  const processData = () => {
    let separatedData = [[]];
    const sortedData = data.sort((valueA, valueB) => {
      const getValue = (item) => {
        const sortedId = defaultSorted.id === -1 ? 0 : defaultSorted.id;
        if (screen === 'capabilities') {
          if (typeof defaultSorted.id === 'string') {
            if (defaultSorted.id === 'name')
              return item.first_name + ' ' + item.last_name;
            if (defaultSorted.id === 'profile') return item.result_profile;
            return item.first_name + ' ' + item.last_name;
          }
          if (item.skills_range[sortedId] === undefined) return null;
          else return item.skills_range[sortedId].min;
        } else {
          if (typeof defaultSorted.id === 'string') {
            if (defaultSorted.id === 'name')
              return item.first_name + ' ' + item.last_name;
            if (defaultSorted.id === 'profile') {
              const match = item.profile_match.filter(
                (match) => match.profile_one.id === item.profile_id,
              )[0];
              return match ? match.profile_one.name : null;
            }
            return item.first_name + ' ' + item.last_name;
          }
          const columnSelected =
            defaultSorted.id === -1
              ? limitatedColumns[0]
              : limitatedColumns.find((col) => col.code === defaultSorted.id);
          const match = item.profile_match.filter(
            (match) =>
              match &&
              columnSelected &&
              match.profile_one.id === item.profile_id &&
              match.profile_two.id === columnSelected.profile,
          )[0];
          if (!match) return null;
          return match.value;
        }
      };
      const a = getValue(valueA);
      const b = getValue(valueB);
      if (typeof defaultSorted.id === 'string') {
        if (defaultSorted.desc) {
          if (a !== null && b !== null) return b.localeCompare(a);
          else if (a !== null) return 1;
          else return -1;
        } else {
          if (a !== null && b !== null) return a.localeCompare(b);
          else if (a !== null) return -1;
          else return 1;
        }
      } else {
        if (defaultSorted.desc) {
          if (a !== null && b !== null) return a - b;
          else if (a !== null) return 1;
          else return -1;
        } else {
          if (a !== null && b !== null) return b - a;
          else if (a !== null) return -1;
          else return 1;
        }
      }
    });
    separatedData = separateData(sortedData);
    return separatedData;
  };

  /**
   * @description renderiza componentes ReactTable con props.data dado.
   * Tiene tipos de renderizado que dependen de props.screen -> capabilities/jobs.
   * @returns { array of ReactTable }
   */
  const renderTables = () => {
    const separatedData = processData();
    return separatedData.map((dataItem, i) => (
      <ReactTable
        key={'pdf-tables-' + i}
        columns={
          props.screen === 'capabilities' // CAPACIDADES render de tabla
            ? [
                {
                  Header: I18n.t('persons.first_name'),
                  Cell: (cellProps) => (
                    <span className="number">{cellProps.value}</span>
                  ),
                  id: 'name',
                  accessor: (d) => d.first_name + ' ' + d.last_name,
                },
                {
                  Header: I18n.t('persons.profile'),
                  headerClassName: 'wordwrap',
                  id: 'profile',
                  accessor: 'result_profile',
                  Cell: (props) => {
                    return (
                      <span className="number">
                        {props.value ? props.value : '-----'}
                      </span>
                    );
                  },
                },
              ].concat(
                limitatedColumns.map((col, index) => ({
                  Header: col.description,
                  headerClassName: 'wordwrap',
                  accessor: (d) => {
                    if (d.skills_range[index] === undefined) return null;
                    else return d.skills_range[index].min;
                  },
                  id: col.code,
                  Cell: (dataProps) => {
                    const value = dataProps.value || 0;
                    const { error } = profileSkillsRangesStatus;
                    if (!dataProps.value) return <p>-----</p>;
                    if (error)
                      return (
                        <div className="text-warning">
                          {I18n.t('persons.groupTrends.error.field')}
                        </div>
                      );
                    return <ProgressBar value={value} persons />;
                  },
                })),
              )
            : // PUESTOS render de tabla
              [
                {
                  Header: I18n.t('persons.first_name'),
                  headerClassName: 'wordwrap',
                  Cell: (cellProps) => (
                    <span className="number">{cellProps.value}</span>
                  ),
                  id: 'name',
                  accessor: (d) => d.first_name + ' ' + d.last_name,
                },
                {
                  Header: I18n.t('persons.profile'),
                  headerClassName: 'wordwrap',
                  id: 'profile',
                  accessor: 'profile_match',
                  Cell: (props) => (
                    <span className="number">
                      {props.value.length > 0
                        ? props.value[0].profile_one.name
                        : '-----'}
                    </span>
                  ),
                },
              ].concat(
                limitatedColumns.map((col, index) => ({
                  Header: col.description,
                  headerClassName: 'wordwrap',
                  accessor: (d) => {
                    const match = d.profile_match.filter(
                      (match) =>
                        match.profile_one.id === d.profile_id &&
                        match.profile_two.id === col.profile,
                    )[0];
                    if (!match) return null;
                    return match.value;
                  },
                  id: col.code,
                  Cell: (dataProps) => {
                    const value = dataProps.value || 0;
                    const { error } = profileSkillsRangesStatus;
                    if (!dataProps.value) return <p>-----</p>;
                    if (error)
                      return (
                        <div className="text-warning">
                          {I18n.t('persons.groupTrends.error.userSkillRanges')}
                        </div>
                      );
                    return (
                      <div className="compatibility-progress">
                        <ProgressBar value={value} persons />
                      </div>
                    );
                  },
                })),
              )
        }
        data={dataItem}
        minRows={0}
        showPagination={false}
        loading={false}
        LoadingComponent={() => <span />}
      />
    ));
  };

  /**
   * Dependiendo de las columnas, devuelve un classname diferente.
   *
   * lo hice porque la posicion de las tablas en cada pagina
   * del pdf queda bien a partir de un margen que se le pone
   * a cada tabla...
   * @returns { string }
   */
  const getColumnsClassName = () => {
    switch (limitatedColumns.length) {
      case 1:
        return ' one-column';
      case 2:
        return ' two-columns';
      case 3:
        return ' three-columns';
      case 4:
        return ' four-columns';
      default:
        return '';
    }
  };

  return (
    <div className={'generatePdf-div' + getColumnsClassName()} id={id}>
      <div className="header-container">
        <img className={'logo-text'} src={logoFull} alt={'logoText'} />
        <h2>
          {screen === 'capabilities'
            ? I18n.t('persons.groupTrends.capabilitiesCompatibility')
            : I18n.t('persons.groupTrends.jobCompatibility')}
        </h2>
      </div>
      {/* si hay mas de una cantidad de items (filas) se separa la informacion y se 
            renderizan varias tablas como sea necesario */}
      {renderTables()}
    </div>
  );
};
