import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './ConfirmModal.css';
import { Modal } from 'react-bootstrap';
import exit from '../../../assets/icons/cross-DARK-32x32.png';
import { I18n } from 'react-redux-i18n';
import Button from '../Button';

class ConfirmModal extends Component {
  static defaultProps;

  render() {
    return (
      <Modal
        show={this.props.show}
        dialogClassName={`payment ${
          this.props.deleteAll && 'delete-all-modal'
        }`}
        onHide={this.props.onHide}
        width={{}}
      >
        <Modal.Header className={'modal-header'}>
          <span
            className={`material-symbols-rounded ${
              !this.props.deleteAll && !this.props.deleteCode && 'hidden'
            }`}
          >
            report
          </span>
          <h4 className="modal-title">
            {I18n.t(this.props.title || 'campaigns.deleteModalTitle')}
          </h4>
          <img src={exit} height={'30px'} onClick={this.props.onHide} alt="" />
        </Modal.Header>
        <Modal.Body>
          <p>{I18n.t(this.props.body || 'campaigns.deleteModalBody')}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="alternative-gray-modal"
            text={I18n.t('buttons.cancel')}
            onClick={this.props.onHide}
          />
          <Button
            className="primary"
            text={I18n.t('buttons.confirm')}
            onClick={() => {
              this.props.action();
              this.props.onHide();
            }}
          />
        </Modal.Footer>
      </Modal>
    );
  }
}

ConfirmModal.defaultProps = {};
ConfirmModal.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  show: PropTypes.bool,
  onHide: PropTypes.func,
};

export default ConfirmModal;
