import './MultiSelect.sass';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import Loader from '../Loader';
class MultiSelect extends Component {
  static defaultProps;

  state = {
    isBranch: null,
    clientsPermit: '',
  };

  componentDidUpdate(prevState) {
    if (this.props.options.length !== prevState.options.length) {
      this.setState({
        ...this.state,
        isBranch: this.props.isBranch?.inputValue
          ? this.props.isBranch?.inputValue
          : null,
        clientsPermit: this.props.value.inputValue.find(
          (opt) => opt.code === 10,
        ),
      });
    }
  }

  handleSelect(option) {
    // se verifica si el checkbox es el de todos los permisos y si es el caso
    // se selecciona o deseleccionan
    if (option === 'all') {
      let stateGroupids = [];
      if (this.props.value.inputValue.length === this.props.options.length) {
        this.props.options.map(async (option) => {
          await this.props.addOption('remove', this.props.name, option);
        });
      } else {
        this.props.options.map(async (option) => {
          //   await this.props.addOption('add', this.props.name, option);
          stateGroupids.push(option);
        });
      }
      this.props.setValues(stateGroupids);
    }
    // si no es el caso se seleccionan o deseleccionan los permisos
    // chequeados
    else {
      for (let i = 0; i <= this.props.value.inputValue.length; i++) {
        const element = this.props.value.inputValue[i];
        if (this.props.value.inputValue.length === i) {
          this.props.addOption('add', this.props.name, option);
          break;
        }
        if (element.code === option.code) {
          this.props.addOption('remove', this.props.name, option);
          break;
        }
      }
      if (this.props.value.inputValue.includes(option)) {
        this.props.addOption('remove', this.props.name, option);
      } else {
      }
    }
  }
  optionIsSelected = () => {
    console.log('props options', this.props.options);
    console.log('props value', this.props.value.inputValue);

    // Verificación de longitud: si options es mayor que inputValue, no puede haber coincidencia
    if (this.props.options.length > this.props.value.inputValue.length) {
      return false;
    }

    // Verificar si todos los elementos de options existen en inputValue
    const allOptionsExist = this.props.options.every((option) =>
      this.props.value.inputValue.some(
        (inputValue) =>
          inputValue.code === option.code &&
          inputValue.description === option.description,
      ),
    );

    return allOptionsExist;
  };

  render() {
    const { label, disabled, loading } = this.props;
    return (
      <>
        {label && (
          <label
            className={
              'permission-label input-label ' +
              'required' +
              (disabled ? ' disabled' : '')
            }
          >
            {label} <span className="required-icon">*</span>
          </label>
        )}
        <div className="multi-select-container">
          {this.props.options.map((option) => {
            return (
              <div className="square-button" key={option.code}>
                <input
                  type="checkbox"
                  checked={this.props.value.inputValue.some(
                    (value) => value.code === option.code,
                  )}
                  onClick={() => this.handleSelect(option)}
                  disabled={disabled}
                />
                <label
                  className={'' + (disabled ? 'input-label disabled' : '')}
                >
                  {option.description.includes('Client') && !this.props.isAdmin
                    ? I18n.t('common.sidebar.filiales')
                    : ''}
                  {option.description.includes('Client') && this.props.isAdmin
                    ? option.description
                    : ''}
                  {!option.description.includes('Client') && option.description}
                </label>
              </div>
            );
          })}
          <div className="square-button">
            <input
              type="checkbox"
              checked={this.optionIsSelected()}
              onClick={() => this.handleSelect('all')}
              disabled={disabled}
            />
            <label className={'' + (disabled ? 'input-label disabled' : '')}>
              {I18n.t('common.sidebar.allPermissions')}
            </label>
          </div>
        </div>
      </>
    );
  }
  //   renderCheckboxOptions() {
  //     const { loading, value, disabled } = this.props;
  //     let inputCheckboxOptions = [];
  //     if (loading) return <Loader mini />;
  //     else {
  //       if (value.inputValue.length > 0) {
  //         inputCheckboxOptions.push(
  //           options.map((option) => {
  //             <input type="checkbox" checked={this.optionIsSelected(option)}>
  //               <label className={'input-label ' + (disabled ? ' disabled' : '')}>
  //                 {option}
  //               </label>{' '}
  //             </input>;
  //           }),
  //         );
  //       }
  //     }
  //   }
}

export default MultiSelect;
