import {
  GET_CAMPAIGN,
  GET_CAMPAIGN_RESPONSE,
  GET_CAMPAIGN_ERROR,
  GET_CAMPAIGNS,
  GET_CAMPAIGNS_RESPONSE,
  GET_CAMPAIGNS_ERROR,
  CREATE_CAMPAIGN,
  CREATE_CAMPAIGN_RESPONSE,
  CREATE_CAMPAIGN_ERROR,
  UPDATE_CAMPAIGN,
  UPDATE_CAMPAIGN_RESPONSE,
  UPDATE_CAMPAIGN_ERROR,
  DELETE_CAMPAIGN,
  DELETE_CAMPAIGN_RESPONSE,
  DELETE_CAMPAIGN_ERROR,
  CAMPAIGN_CLEAN_ERRORS,
  SELECT_CAMPAIGN,
  UNSELECT_CAMPAIGN,
  CLEAN_SEARCH_VALUE,
  UPDATE_CAMPAIGNS_FILTER_VALUES,
  UPDATE_PAGE,
  UPDATE_PAGE_SIZE,
  CLEAR_UI_VALUE,
  GENERATE_CODE,
  GENERATE_CODE_ERROR,
  GENERATE_CODE_RESPONSE,
  UPDATE_CODE,
  UPDATE_CODE_RESPONSE,
  UPDATE_CODE_ERROR,
  DELETE_CODE,
  DELETE_CODE_RESPONSE,
  DELETE_CODE_ERROR,
  CLEAN_PLAYING_CODE_ERROR,
  DELETE_CODES,
  DELETE_CODES_RESPONSE,
  DELETE_CODES_ERROR,
  RELOAD_SELECTED_CAMPAIGN,
  RELOAD_SELECTED_CAMPAIGN_RESPONSE,
  RELOAD_SELECTED_CAMPAIGN_ERROR,
  SHARE_CAMPAIGN,
  SHARE_CAMPAIGN_RESPONSE,
  SHARE_CAMPAIGN_ERROR,
  GET_POSITIONS,
  GET_POSITIONS_RESPONSE,
  GET_POSITIONS_ERROR,
  GET_FIELDS,
  GET_FIELDS_RESPONSE,
  GET_FIELDS_ERROR,
  GET_ALL_CAMPAIGNS,
  GET_ALL_CAMPAIGNS_RESPONSE,
  GET_ALL_CAMPAIGNS_ERROR,
  DOWNLOAD_TEMPLATE,
  LOGOUT,
} from './campaigns.actions';
import { REHYDRATE } from 'redux-persist/lib/index';

const initialState = {
  count: 1,
  currentPage: 0,
  total_pages: 1,
  page_size: 10,
  results: [],
  desc: false,
  filterValues: {
    ordering: '-id',
  },
  searchValueClean: true,
  selectedCampaign: {},
  positions: [],
  allCampaigns: [],
  getCampaignsStatus: { loading: false, error: false, success: false },
  getAllCampaignsStatus: { loading: false, error: false, success: false },
  createStatus: { loading: false, error: false, success: false },
  updateStatus: { loading: false, error: false, success: false },
  campaignDeleteStatus: {
    loading: false,
    error: false,
    success: false,
    errorDetailsDeleting: '',
  },
  shareStatus: { loading: false, error: false, success: false },
  generateCodeStatus: { loading: false, error: false, success: false },
  updateCodeStatus: { loading: false, error: false, success: false },
  deleteCodeStatus: { loading: false, error: false, success: false },
  deleteCodesStatus: { loading: false, error: false, success: false },
  reloadSelectedCampaignStatus: {
    loading: false,
    error: false,
    success: false,
  },
  errorCode: [],
  credits_user_status: {},
  playingCode: false,
};

const campaigns = (state = initialState, action) => {
  switch (action.type) {
    /**
     * Campaigns
     */
    /** Get campaigns */
    case GET_CAMPAIGN:
      return {
        ...state,
        getCampaignStatus: { loading: true, error: false, success: false },
      };
    case GET_CAMPAIGN_RESPONSE:
      return {
        ...state,
        getCampaignStatus: { loading: false, error: false, success: true },
        ...action.response,
      };
    case GET_CAMPAIGN_ERROR:
      return {
        ...state,
        getCampaignStatus: { loading: false, error: true, success: false },
      };
    case GET_CAMPAIGNS:
      return {
        ...state,
        getCampaignsStatus: { loading: true, error: false, success: false },
      };
    case GET_CAMPAIGNS_RESPONSE:
      return {
        ...state,
        getCampaignsStatus: { loading: false, error: false, success: true },
        ...action.response,
      };
    case GET_CAMPAIGNS_ERROR:
      return {
        ...state,
        getCampaignsStatus: { loading: false, error: true, success: false },
      };

    /** Get campaign list */
    case GET_ALL_CAMPAIGNS:
      return {
        ...state,
        getAllCampaignsStatus: { loading: true, error: false, success: false },
      };
    case GET_ALL_CAMPAIGNS_RESPONSE:
      return {
        ...state,
        getAllCampaignsStatus: { loading: false, error: false, success: true },
        allCampaigns: action.response.results,
        results: action.response.results,
      };
    case GET_ALL_CAMPAIGNS_ERROR:
      return {
        ...state,
        getAllCampaignsStatus: { loading: false, error: true, success: false },
      };

    /** Create campaign */
    case CREATE_CAMPAIGN:
      return {
        ...state,
        createStatus: { loading: true, error: false, success: false },
      };
    case CREATE_CAMPAIGN_RESPONSE:
      return {
        ...state,
        selectedCampaign: action.response,
        createStatus: { loading: false, error: false, success: true },
      };
    case CREATE_CAMPAIGN_ERROR:
      return {
        ...state,
        createStatus: { loading: false, error: true, success: false },
        errorCode:
          Object.keys(action.response.data)[0] === 'non_field_errors'
            ? action.response.data['non_field_errors'][0]
            : Object.keys(action.response.data)[0],
      };

    /** Update campaign */
    case UPDATE_CAMPAIGN:
      return {
        ...state,
        updateStatus: { loading: true, error: false, success: false },
      };
    case UPDATE_CAMPAIGN_RESPONSE:
      return {
        ...state,
        updateStatus: { loading: false, error: false, success: true },
      };
    case UPDATE_CAMPAIGN_ERROR:
      return {
        ...state,
        updateStatus: { loading: false, error: true, success: false },
        errorCode:
          Object.keys(action.response.data)[0] === 'non_field_errors'
            ? action.response.data['non_field_errors'][0]
            : Object.keys(action.response.data)[0],
      };

    /** Delete campaign */
    case DELETE_CAMPAIGN:
      return {
        ...state,
        campaignDeleteStatus: {
          loading: true,
          error: false,
          success: false,
          errorDetailsDeleting: '',
        },
      };
    case DELETE_CAMPAIGN_RESPONSE:
      return {
        ...state,
        campaignDeleteStatus: {
          loading: false,
          error: false,
          success: true,
          errorDetailsDeleting: '',
        },
      };
    case DELETE_CAMPAIGN_ERROR:
      return {
        ...state,
        campaignDeleteStatus: {
          loading: false,
          error: true,
          success: false,
          errorDetailsDeleting: action.error.data,
        },
      };

    case CAMPAIGN_CLEAN_ERRORS:
      const noErrorStatus = { loading: false, error: false, success: false };
      const noErrorStatusDeleting = {
        loading: false,
        error: false,
        success: false,
        errorDetailsDeleting: '',
      };
      return {
        ...state,
        createStatus: noErrorStatus,
        updateStatus: noErrorStatus,
        campaignDeleteStatus: noErrorStatusDeleting,
      };

    /**  */
    case SELECT_CAMPAIGN:
      let selectedCampaign = action.all
        ? state.allCampaigns.find((t) => t.id === action.id)
        : state.results.find((t) => t.id === action.id);
      const played_people = selectedCampaign.nwm_invitation?.filter(
        (inv) => inv.status === 'COMPLETED' && inv.type === 'CONDUCT',
      );
      const played_people_ie = selectedCampaign.nwm_invitation?.filter(
        (inv) => inv.status === 'COMPLETED' && inv.type === 'IE',
      );
      const pending_people = selectedCampaign.nwm_invitation?.filter(
        (inv) => inv.status !== 'COMPLETED',
      );
      Object.assign(selectedCampaign, {
        pending_people,
        played_people,
        played_people_ie,
      });
      return {
        ...state,
        selectedCampaign,
      };

    /**  */
    case UNSELECT_CAMPAIGN:
      return { ...state, selectedCampaign: {} };

    /** Get positions */
    // TODO: retrieve to ?
    case GET_POSITIONS:
      return {
        ...state,
        retrieve: { loading: true, error: false, success: false },
      };
    case GET_POSITIONS_RESPONSE:
      return {
        ...state,
        retrieve: { loading: false, error: false, success: true },
        positions: action.response.results,
      };
    case GET_POSITIONS_ERROR:
      return {
        ...state,
        retrieve: { loading: false, error: true, success: false },
      };

    /** Get fields */
    case GET_FIELDS:
      return {
        ...state,
        fieldsStatus: { loading: true, error: false, success: false },
      };
    case GET_FIELDS_RESPONSE:
      return {
        ...state,
        fieldsStatus: { loading: false, error: false, success: true },
        fields: action.response.results,
      };
    case GET_FIELDS_ERROR:
      return {
        ...state,
        fieldsStatus: { loading: false, error: true, success: false },
      };

    /**  */

    case CLEAN_SEARCH_VALUE:
      return {
        ...state,
        searchValueClean: action.boolean,
      };
    case UPDATE_CAMPAIGNS_FILTER_VALUES:
      return {
        ...state,
        filterValues: { ...state.filterValues, ...action.filterValues },
        desc: !state.desc,
      };

    /**  */
    case UPDATE_PAGE:
      return { ...state, currentPage: action.pageNumber };
    case UPDATE_PAGE_SIZE:
      return { ...state, page_size: action.pageSize, current: 1 };

    /** Share campaign */
    case SHARE_CAMPAIGN:
      return {
        ...state,
        shareStatus: { loading: true, error: false, success: false },
      };
    case SHARE_CAMPAIGN_RESPONSE:
      return {
        ...state,
        shareStatus: { loading: false, error: false, success: true },
      };
    case SHARE_CAMPAIGN_ERROR:
      return {
        ...state,
        shareStatus: { loading: false, error: true, success: false },
      };

    /**
     * Campaign codes
     */
    /** Generate code */
    case GENERATE_CODE:
      return {
        ...state,
        generateCodeStatus: { loading: true, error: false, success: false },
      };
    case GENERATE_CODE_RESPONSE:
      return {
        ...state,
        generateCodeStatus: { loading: false, error: false, success: true },
      };
    case GENERATE_CODE_ERROR:
      return {
        ...state,
        generateCodeStatus: { loading: false, error: true, success: false },
      };

    /** Update code */
    case UPDATE_CODE:
      return {
        ...state,
        updateCodeStatus: { loading: true, error: false, success: false },
      };
    case UPDATE_CODE_RESPONSE:
      return {
        ...state,
        updateCodeStatus: { loading: false, error: false, success: true },
      };
    case UPDATE_CODE_ERROR:
      return {
        ...state,
        updateCodeStatus: { loading: false, error: true, success: false },
      };

    /** Delete code */
    case DELETE_CODE:
      return {
        ...state,
        deleteCodeStatus: { loading: true, error: false, success: false },
      };
    case DELETE_CODE_RESPONSE:
      return {
        ...state,
        deleteCodeStatus: { loading: false, error: false, success: true },
      };
    case DELETE_CODE_ERROR:
      return {
        ...state,
        deleteCodeStatus: { loading: false, error: true, success: false },
        playingCode:
          action.error.data === 'Playing invitations cannot be deleted'
            ? true
            : false,
      };
    case CLEAN_PLAYING_CODE_ERROR:
      return {
        ...state,
        playingCode: false,
      };

    /** Delete codes */
    case DELETE_CODES:
      return {
        ...state,
        deleteCodesStatus: { loading: true, error: false, success: false },
      };
    case DELETE_CODES_RESPONSE:
      return {
        ...state,
        deleteCodesStatus: { loading: false, error: false, success: true },
      };
    case DELETE_CODES_ERROR:
      return {
        ...state,
        deleteCodesStatus: { loading: false, error: true, success: false },
      };

    /** Reload the selected campaign */
    case RELOAD_SELECTED_CAMPAIGN:
      return {
        ...state,
        reloadSelectedCampaignStatus: {
          loading: true,
          error: false,
          success: false,
        },
      };
    case RELOAD_SELECTED_CAMPAIGN_RESPONSE:
      let selectedCampaignReloaded = action.response;
      const played_people_reloaded =
        selectedCampaignReloaded.nwm_invitation?.filter(
          (inv) => inv.status === 'COMPLETED' && inv.type === 'CONDUCT',
        );
      const played_people_ie_reloaded =
        selectedCampaignReloaded.nwm_invitation?.filter(
          (inv) => inv.status === 'COMPLETED' && inv.type === 'IE',
        );
      const pending_people_reloaded =
        selectedCampaignReloaded.nwm_invitation?.filter(
          (inv) => inv.status !== 'COMPLETED',
        );
      Object.assign(selectedCampaignReloaded, {
        pending_people: pending_people_reloaded,
        played_people: played_people_reloaded,
        played_people_ie: played_people_ie_reloaded,
      });
      return {
        ...state,
        reloadSelectedCampaignStatus: {
          loading: false,
          error: false,
          success: true,
        },
        selectedCampaign: selectedCampaignReloaded,
        generateCodeStatus: { loading: false, error: false, success: false },
      };
    case RELOAD_SELECTED_CAMPAIGN_ERROR:
      return {
        ...state,
        reloadSelectedCampaignStatus: {
          loading: false,
          error: true,
          success: false,
        },
      };

    /**  */
    case CLEAR_UI_VALUE:
      return {
        ...state,
        [action.op_type]: {
          ...action.op_type,
          [action.condition]: false,
        },
      };

    /**  */
    case LOGOUT:
      return initialState;

    case DOWNLOAD_TEMPLATE:
      return { ...state };

    /** redux-persist */
    case REHYDRATE:
      let incoming = action.payload;
      if (incoming) {
        return {
          ...state,
          ...incoming.campaigns,
          positions: [],
          generateCodeStatus: { loading: false, error: false, success: false },
          updateCodeStatus: { loading: false, error: false, success: false },
          deleteCodeStatus: { loading: false, error: false, success: false },
          reloadSelectedCampaignStatus: {
            loading: false,
            error: false,
            success: false,
          },
          createStatus: { loading: false, error: false, success: false },
          shareStatus: { loading: false, error: false, success: false },
          updateStatus: { loading: false, error: false, success: false },
          campaignDeleteStatus: {
            loading: false,
            error: false,
            success: false,
            errorDetailsDeleting: '',
          },
          retrieveStatus: { loading: false, error: false, success: false },
          getAllCampaignsStatus: {
            loading: false,
            error: false,
            success: false,
          },
        };
      }
      return state;

    default:
      return state;
  }
};

export default campaigns;
