import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './UserChangePasswordModal.css';
import { Modal } from 'react-bootstrap';
import exit from '../../../assets/icons/cross-DARK-32x32.png';
import { I18n } from 'react-redux-i18n';
import Button from '../Button';
import Input from '../Input';

class UserChangePasswordModal extends Component {
  static defaultProps;

  render() {
    return (
      <Modal
        show={this.props.show}
        dialogClassName="user-change-password-modal"
        onHide={this.props.onHide}
      >
        <Modal.Header>
          <span className="material-symbols-filled">{'lock'}</span>
          <p className="modal-title">{I18n.t('users.changePassword')}</p>
        </Modal.Header>
        <Modal.Body>
          <div style={{ width: '100%' }}>
            <Input
              label={I18n.t('users.actualPassword')}
              name="currentPassword"
              value={this.props.currentPassword}
              change={this.props.change}
              placeholder={I18n.t('users.currentPassword')}
              type={'password'}
            />
            <Input
              label={I18n.t('users.newPass')}
              name="password"
              value={this.props.password}
              change={this.props.change}
              placeholder={I18n.t('users.newPassword')}
              type={'password'}
              ignoreDirty
            />
            <span className="notify">{I18n.t('users.tooltipPassword')}</span>
            <Input
              label={I18n.t('users.rePassword')}
              name="repeatPassword"
              value={this.props.repeatPassword}
              change={this.props.change}
              placeholder={I18n.t('users.repeatPassword')}
              type={'password'}
              ignoreDirty
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="text-link-button"
            text={I18n.t('buttons.cancel')}
            onClick={this.props.onHide}
          />
          <Button
            className="primary"
            text={I18n.t('buttons.change')}
            onClick={() => {
              this.props.action(
                this.props.password.inputValue,
                this.props.currentPassword.inputValue,
              );
              this.props.onHide();
            }}
            disabled={
              !this.props.currentPassword.inputValue ||
              !this.props.password.inputValue ||
              this.props.password.inputValue !==
                this.props.repeatPassword.inputValue
            }
          />
        </Modal.Footer>
      </Modal>
    );
  }
}

UserChangePasswordModal.defaultProps = {};
UserChangePasswordModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
};

export default UserChangePasswordModal;
