import {
  DOWNLOAD_REPORT_ERROR,
  DOWNLOAD_REPORT_RESPONSE,
  GET_INFO_REPORTS,
  GET_INFO_REPORTS_ERROR,
  GET_INFO_REPORTS_RESPONSE,
  RESTORE_REPORT_ERROR,
  RESTORE_REPORT_RESPONSE,
  UPDATE_REPORTS_ERROR,
  UPDATE_REPORTS_RESPONSE,
} from './reports.actions';
import { REHYDRATE } from 'redux-persist/lib/constants';
const initialState = {
  getInfoReportsResponse: [],
  getInfoReportsStatus: { loading: false, error: false, success: false },
  restoreReportStatus: { loading: false, error: false, success: false },
  updateReportsStatus: { loading: false, error: false, success: false },
  downloadReportStatus: { loading: false, error: false, success: false },
};

const reports = (state = initialState, action) => {
  switch (action.type) {
    case GET_INFO_REPORTS:
      return {
        ...state,
        // infoReport: action.response,
        getInfoReportsStatus: {
          loading: true,
          error: false,
          success: false,
        },
      };
    case GET_INFO_REPORTS_RESPONSE:
      return {
        ...state,
        // infoReport: action.response,
        getInfoReportsResponse: action.response,
        getInfoReportsStatus: {
          loading: false,
          error: false,
          success: true,
        },
      };
    case GET_INFO_REPORTS_ERROR:
      return {
        ...state,
        getInfoReportsStatus: {
          loading: false,
          error: true,
          success: false,
        },
      };
    case RESTORE_REPORT_RESPONSE:
      return {
        ...state,
        restoreReportStatus: {
          loading: false,
          error: false,
          success: true,
        },
      };
    case RESTORE_REPORT_ERROR:
      return {
        ...state,
        restoreReportStatus: {
          loading: false,
          error: true,
          success: false,
        },
      };
    case UPDATE_REPORTS_RESPONSE:
      return {
        ...state,
        updateReportsStatus: { loading: false, error: false, success: true },
      };
    case UPDATE_REPORTS_ERROR:
      return {
        ...state,
        updateReportsStatus: { loading: false, error: true, success: false },
      };
    case DOWNLOAD_REPORT_RESPONSE:
      return {
        ...state,
        downloadReportStatus: { loading: false, error: false, success: true },
      };
    case DOWNLOAD_REPORT_ERROR:
      return {
        downloadReportStatus: { loading: false, error: true, success: false },
      };
    case REHYDRATE:
      let incoming = action.payload;
      if (incoming) {
        return {
          ...state,
          getInfoReportsStatus: {
            loading: false,
            error: false,
            success: false,
          },
          restoreReportStatus: { loading: false, error: false, success: false },
          updateReportsStatus: { loading: false, error: false, success: false },
        };
      }
    default:
      return state;
  }
};

export default reports;
