import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Translate, I18n } from 'react-redux-i18n';
import './Dropdown.css';
import dropdownIcon from '../../../assets/icons/down-arrow-LIGHT-24x24.png';
import Loader from '../Loader';

/**
 * Documentation
 * ------------------------
 * @description: <button/> with <ul><li>OPTIONS</li></ul>
 * @type:
 * 		1. single option select (required, no empty option)
 * 		2. single option select (not required, empty option selectable)
 * 		3. multiSelect
 *
 * (1, 2): 	showEmptyOption prop adds to option array the placeholder
 * 			or generic option.
 * (3): 	prop renders other components, and needs an addOption
 * 			prop function call.
 */
class Dropdown extends Component {
  static defaultProps;

  state = {
    showOptions: false,
    filterValue: '',
  };

  handleHideOptionsState = (ev) => {
    if (
      ev.path.filter((element) => element.id === this.props.name).length === 0
    ) {
      this.setState({ ...this.state, showOptions: false });
    }
  };

  handleSelection(e, option) {
    if (this.props.multiSelect) {
      if (this.optionIsSelected(option)) {
        this.props.addOption('remove', this.props.name, option);
      } else {
        this.props.addOption('add', this.props.name, option);
        this.props.oneSelect &&
          this.setState({ ...this.state, showOptions: false });
      }
    } else {
      this.props.change(this.props.name, option);
    }
  }

  optionIsSelected = (option) => {
    return this.props.value?.inputValue?.find(
      (currentOption) => currentOption.code === option.code,
    );
  };

  removeDuplicates(originalArray, objKey) {
    let trimmedArray = [];
    let values = [];
    let value;

    for (let i = 0; i < originalArray.length; i++) {
      value = originalArray[i][objKey];
      if (values.indexOf(value) === -1) {
        trimmedArray.push(originalArray[i]);
        values.push(value);
      }
    }
    return trimmedArray;
  }

  filterAndOrder(array) {
    const { removeDuplicates, filter, order } = this.props;
    let result = array;
    result = removeDuplicates
      ? this.removeDuplicates(result, 'description')
      : result;
    if (filter && this.state.filterValue) {
      result = array.filter(
        (option) =>
          (option.label || option.description) &&
          (option.label || option.description)
            .toLowerCase()
            .includes(this.state.filterValue.toLowerCase()),
      );
    }
    if (order) {
      result = result.sort(function (a, b) {
        return a.description || a.label
          ? (a.description || a.label).localeCompare(b.description || b.label)
          : 0;
      });
    }
    return result;
  }

  render() {
    const {
      name, // id
      value,
      label,
      multiSelect,
      navbarFont,
      disabled,
      filter,
      translate, // apply I18n.t(...)
      placeholder,
      showEmptyOption, // dropdown value can be null
      noBorder, // style
      loading,
      gray,
      className, // style gray border
    } = this.props;
    let options = [];
    if (showEmptyOption) {
      if (placeholder)
        options = options.concat({ code: -1, description: placeholder });
      else
        options = options.concat({
          code: -1,
          description: I18n.t('dropdown.default'),
        });
    }
    options = options.concat(this.props.options);
    return (
      <div
        className={`dropdown-container ${className}`}
        id={name}
        onClick={() =>
          multiSelect &&
          document.addEventListener('mousedown', this.handleHideOptionsState)
        }
      >
        {label && (
          <label
            className={
              'input-label ' +
              (disabled ? ' disabled' : '') +
              (value.required ? ' required' : '')
            }
          >
            <Translate value={label} />
          </label>
        )}
        <div className="dropdown">
          {multiSelect
            ? [
                <button
                  key="dropdown-button-values"
                  className={
                    'multi' +
                    (disabled ? ' disabled' : '') +
                    (navbarFont ? ' navbar-font' : '') +
                    (noBorder ? ' no-border' : '') +
                    (value?.inputValue?.length > 0 ? ' selected ' : '') +
                    (this.props.class || '') +
                    (loading ? ' loading' : '') +
                    (gray ? ' gray' : '')
                  }
                  type="button"
                  onClick={() => {
                    this.setState({ showOptions: !this.state.showOptions });
                  }}
                >
                  {this.renderMultiSelectButtonContent()}
                </button>,

                !disabled && this.state.showOptions && (
                  <ul
                    className={
                      'custom-dropdown-menu' +
                      (options.length === 0 ? ' no-items' : '')
                    }
                  >
                    {options.map((option, i) => (
                      <li
                        className={`${option.subitem ? 'sub-item' : ''} ${
                          option.disabled ? 'disabled' : ''
                        } ${
                          multiSelect && this.optionIsSelected(option)
                            ? 'selected'
                            : 'not-selected'
                        }`}
                        key={option.code + '-' + i}
                        onClick={(e) =>
                          !option.disabled && this.handleSelection(e, option)
                        }
                      >
                        {translate
                          ? I18n.t(option.description || option.label)
                          : option.description || option.label}
                        {option.menu && (
                          <span class="material-symbols-rounded expand-more">
                            expand_more
                          </span>
                        )}
                      </li>
                    ))}
                  </ul>
                ),
              ]
            : [
                <button
                  className={
                    (disabled ? ' disabled' : '') +
                    (navbarFont ? ' navbar-font' : '') +
                    (noBorder ? ' no-border' : '') +
                    (value.inputValue?.code ? ' selected ' : '') +
                    (this.props.class || '') +
                    (loading ? ' loading' : '') +
                    (gray ? ' gray' : '')
                  }
                  type="button"
                  data-toggle="dropdown"
                  onClick={() => {
                    this.setState({ showOptions: !this.state.showOptions });
                  }}
                  key="dropdown-multi-select"
                >
                  {this.renderSingleSelectButtonContent()}
                </button>,

                <ul className="dropdown-menu" key="dropdown-menu">
                  {filter && (
                    <input
                      className={'filter-input'}
                      onChange={(e) => {
                        this.setState({
                          ...this.state,
                          filterValue: e.target.value,
                        });
                      }}
                      placeholder={'Filter'}
                    />
                  )}
                  {this.filterAndOrder(options).map((option, i) => (
                    <li
                      className={`${option.subitem ? 'sub-item' : ''} ${
                        option.disabled ? 'disabled' : ''
                      } ${
                        multiSelect && this.optionIsSelected(option)
                          ? 'selected'
                          : ''
                      }`}
                      key={option.code + '-' + i}
                      onClick={(e) =>
                        !option.disabled && this.handleSelection(e, option)
                      }
                    >
                      {translate
                        ? I18n.t(option.description || option.label)
                        : option.description || option.label}
                      {option.menu && (
                        <span class="material-symbols-rounded expand-more">
                          expand_more
                        </span>
                      )}
                    </li>
                  ))}
                </ul>,
              ]}
        </div>
      </div>
    );
  }

  renderMultiSelectButtonContent() {
    const { loading, value, placeholder } = this.props;
    let result = [];
    if (loading) return <Loader mini />;
    else {
      if (value?.inputValue?.length > 0)
        result.push(
          <label>
            {value.inputValue.map((value) => value.description).join(', ')}
          </label>,
        );
      else if (placeholder) result.push(<label>{placeholder}</label>);
      else result.push(<label>{I18n.t('dropdown.default')}</label>);
    }
    result.push(
      <span class="material-symbols-rounded expand-more">expand_more</span>,
    );
    return result;
  }

  renderSingleSelectButtonContent() {
    const { loading, value, translate, placeholder } = this.props;
    const { description, label } = this.props.value.inputValue;
    if (loading) return <Loader mini />;
    else
      return [
        <label key="dropdown-single-select-label">
          {value.inputValue
            ? (description || label) && translate
              ? I18n.t(description || label)
              : description || label || placeholder
            : placeholder || I18n.t('dropdown.default')}
          {this.props.expandMore && (
            <span class="material-symbols-rounded expand-more">
              expand_more
            </span>
          )}
        </label>,
      ];
  }
}

Dropdown.defaultProps = {
  value: {},
};
Dropdown.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array,
  change: PropTypes.func,
  addOption: PropTypes.func,
  value: PropTypes.object,
  label: PropTypes.string,
  disabled: PropTypes.any,
  translate: PropTypes.bool,
  noBorder: PropTypes.bool,
  navbarFont: PropTypes.bool,
  multiSelect: PropTypes.bool,
  oneSelect: PropTypes.bool,
  removeDuplicates: PropTypes.bool,
  placeholder: PropTypes.string,
};

export default Dropdown;
