import { connect } from 'react-redux';
import actions from '../../actions/index';
import { withRouter } from 'react-router';
import GroupTrends from '../components/GroupTrends/GroupTrends';

const mapDispatchToProps = (dispatch) => ({
  getJobCompatibility: (jobs) =>
    dispatch(actions.persons.getJobCompatibility(jobs)),
  getJobs: (positionDropdown) =>
    dispatch(actions.jobs.getJobs(positionDropdown)),
  updateJobsSearchFilter: () =>
    dispatch(actions.jobs.updateFilterValues({ search: '' })),
  getCapabilities: (jobs) => dispatch(actions.persons.getCapabilities(jobs)),
  getProfileSkills: () => dispatch(actions.profile.getProfileSkills()),
  getProfileSkillsRanges: (skills, excel) =>
    dispatch(actions.profile.getProfileSkillsRange(skills, excel)),
  clearSelection: () => dispatch(actions.persons.clearSelection()),
  exportTrend: (data, capabilities) =>
    dispatch(actions.persons.trendExport(data, capabilities)),
  selectSkill: () => dispatch(actions.profile.addSelectedSkill()),
  removeSkill: () => dispatch(actions.profile.removeSelectedSkill()),
  filterPersons: (filter, resetPage) =>
    dispatch(actions.persons.filterPersons(filter, resetPage)),
  addRemovePersonToSelection: (result) =>
    dispatch(actions.persons.addRemovePersonToSelection(result)),
  updatePageSize: (pageSize) =>
    dispatch(actions.persons.updatePageSize(pageSize)),
  getAllClients: (account_dropdown, view_branch, view_account) =>
    dispatch(
      actions.clients.getAllClients(
        account_dropdown,
        view_branch,
        view_account,
      ),
    ),
  getBranchs: (account_id) => dispatch(actions.clients.getBranchs(account_id)),
  updateToolboxFilter: (key, filter) =>
    dispatch(actions.persons.updateToolboxFilter(key, filter)),
  cleanToolboxFilter: () => dispatch(actions.persons.cleanToolboxFilter()),
  getAllProfiles: (body) =>
    dispatch(actions.persons.getAllProfiles(body?.search)),
  getAllCampaigns: ({ queryPartners, campaign_dropdown, search, partner_id }) =>
    dispatch(
      actions.campaigns.getAllCampaigns(
        queryPartners,
        campaign_dropdown,
        search,
        partner_id,
      ),
    ),
});

const mapStateToProps = (state, ownProps) => ({
  allCampaigns: state.campaigns.allCampaigns,
  allProfiles: state.persons.profiles,
  totalCount: state.persons.count,
  jobCompatibility: state.persons.job_compatibility,
  allJobCompatibility: state.persons.all_job_compatibility,
  selection: state.persons.selection,
  persons: state.persons.results,
  profileSkills: state.profile.profile_skills,
  profileSkillsRanges: state.profile.profile_skills_ranges,
  allProfileSkillsRanges: state.profile.all_profile_skills_ranges,
  getProfileSkillsRangesStatus: state.profile.getProfileSkillsRangesStatus,
  getAllProfileSkillsRangesStatus:
    state.profile.getAllProfileSkillsRangesStatus,
  jobs: state.jobs.jobs,
  retrieveFilter: state.persons.retrieveFilter,
  profileSkillsStatus: state.profile.getProfileSkillsStatus,
  profileSkillsRangesStatus: state.profile.getProfileSkillsRangesStatus,
  jobsStatus: state.jobs.jobsStatus,
  jobsCompatibilityStatus: state.jobs.jobsCompatibilityStatus,
  createStatus: state.persons.create,
  skillSelected: state.profile.skillSelected,
  user: state.session.user,
  language: state.i18n.locale,
  customFilter: state.persons.customFilter,
  toolboxFilterPersons: state.persons.toolboxFilterPersons,
  toolboxFilterJobs: state.persons.toolboxFilterJobs,
  toolboxFilterSkills: state.persons.toolboxFilterSkills,
  allClients: state.clients.allClients,
  branchs: state.clients.branchs,
  isAdmin: state.session.user.user_admin ? true : false,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(GroupTrends));
