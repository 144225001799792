import {
  UPLOAD_FILE,
  UPLOAD_FILE_RESPONSE,
  UPLOAD_FILE_ERROR,
  GET_BAPRO_FILES,
  GET_BAPRO_FILES_RESPONSE,
  GET_BAPRO_FILES_ERROR,
  RESET_BAPRO_FILES,
} from './bapro.actions';
import { REHYDRATE } from 'redux-persist/lib/index';
const initialState = {
  uploadFilesStatus: { loading: false, error: false, success: false },
  getBaproFilesStatus: { loading: false, error: false, success: false },
  getBaproFile: { loading: false, error: false, success: false },
  baproFiles: [],
};

const bapro = (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_FILE:
      return {
        ...state,
        uploadFilesStatus: { loading: true, error: false, success: false },
      };
    case UPLOAD_FILE_RESPONSE:
      return {
        ...state,
        uploadFilesStatus: { loading: false, error: false, success: true },
      };
    case UPLOAD_FILE_ERROR:
      return {
        ...state,
        uploadFilesStatus: { loading: false, error: true, success: false },
      };
    case GET_BAPRO_FILES:
      return {
        ...state,
        getBaproFile: { loading: true, error: false, success: false },
      };
    case GET_BAPRO_FILES_RESPONSE:
      return {
        ...state,
        baproFiles: action.response,
        getBaproFile: { loading: false, error: false, success: true },
      };
    case GET_BAPRO_FILES_ERROR:
      return {
        ...state,
        getBaproFile: { loading: false, error: true, success: false },
      };
    case RESET_BAPRO_FILES:
      return {
        ...state,
        getBaproFile: { loading: false, error: false, success: false },
        uploadFilesStatus: { loading: false, error: false, success: false },
      };
    case REHYDRATE:
      let incoming = action.payload;
      if (incoming) {
        return {
          ...state,
          uploadFilesStatus: { loading: false, error: false, success: false },
        };
      }
    default:
      return state;
  }
};

export default bapro;
