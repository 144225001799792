import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { I18n } from 'react-redux-i18n';
import ReactTable from 'react-table';
import TablePaginationDrawer from '../TablePaginationDrawer/TablePaginationDrawer';
import Button from '../Button';
import actions from '../../../actions';
import { Tabs, Tab } from 'react-bootstrap';
import './CreditsHistory.sass';
import Loader from '../Loader';
import groups from '../../../utils/groups';
import { parseDateTime, parseBackDate } from '../../../utils/parser';
import { NumberLabel } from '../NumberLabel/NumberLabel';
import Dropdown from '../Dropdown';
import DateRangePickerFilter from '../../common/DateRangePickerFilter/DateRangePickerFilter';
import CreditsWidgets from '../CreditsWidgets/CreditsWidgets';
import TableLoader from '../../common/TableLoader/TableLoader';
import dayjs from 'dayjs';
import { es, pt } from 'date-fns/esm/locale';
import SearchInput from '../../common/SearchInput/SearchInput';
import FormDropdown from '../Dropdown/FormDropdown';
// registerLocale('es', es);
// registerLocale('pt', pt);

/**
 * Credits history
 * @description simple table with transactions made by user/s.
 *
 * This item is used by admin (all partners history) or
 * partner (self history).
 */
class CreditsHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is: props.userGroups.find((group) => group === groups.ADMIN),
      isPartner: props.userGroups.find((group) => group === groups.PARTNER),
      isSuperPartner: props.isSuperPartner,
      filterValues: {
        search: { inputValue: '' },
        branchs: { inputValue: [] },
        accounts: { inputValue: [] },
      },
      isReset: true,
      isClicked: false,
      language: props.language,
      workingCell: '',
      ordering: {
        desc: false,
        id: 'created',
      },
    };
  }

  handleHeights(unmount = false) {
    const tableBodyHeight = document.querySelector(
      '.credits-tabs-container .tab-pane .rt-tbody',
    )?.offsetHeight;
    const clientTabHeight = document.querySelector(
      '.credits-tabs-container',
    )?.offsetHeight;
    const widgetsHeight = document.getElementById('credits-card')?.offsetHeight;

    const element = document.querySelector(
      '.credits-tabs-container .tab-pane .rt-tbody',
    );

    if (element) {
      let sum = 0;
      if (window.innerWidth <= 1366) sum = 6;
      const total =
        widgetsHeight + sum - (clientTabHeight - tableBodyHeight) + 'px';
    }

    if (!unmount)
      this.setState({
        ...this.state,
        heightCard: widgetsHeight,
      });
  }
  componentDidMount() {
    if (
      this.props.user.user_admin &&
      this.props.history.location.pathname === '/main/credits-history'
    ) {
      // this.props.history.push('/main/clients#credits-history');
    }
    if (this.props.user?.branch) {
      this.props.getFilterDates({
        start_value: '',
        end_value: '',
      });
    }
    this.props.getCreditsHistory();
    if (this.props.user.user_admin) {
      this.props.getAllClients({
        account_dropdown: true,
        view_branch: false,
        view_account: true,
        search: '',
      });
      this.props.getBranchs({ account_id: null, search: '' });
    }
    if (this.props.user.account?.id) {
      this.props.getAllClients({
        account_dropdown: true,
        view_branch: true,
        view_account: false,
        search: '',
      });
      this.props.getBranchs({
        account_id: this.props.user.account.id,
        search: '',
      });
    }
    if (
      this.props.user?.user_company &&
      !this.props.user?.partner?.is_super_dashboard
    ) {
      this.setState({ ...this.state, historyFiltering: false });
    }
    if (!this.props.user.user_admin) {
      window.addEventListener('load', () => {
        this.handleHeights();
      });
      window.addEventListener('resize', () => {
        this.handleHeights();
      });

      if (document.getElementById('credits-card')?.offsetHeight) {
        setTimeout(() => {
          this.handleHeights();
        }, 500);
      }
    }
  }

  componentDidUpdate(prevProps) {
    // this.props.getCreditsHistory();

    if (this.props.cleanFilter) {
      this.setState(
        {
          ...this.state,
          filterValues: {
            search: { inputValue: '' },
            branchs: { inputValue: [] },
            accounts: { inputValue: [] },
          },
        },
        () => {
          this.props.toggleCleanFilter(false);
          this.props.getCreditsHistory();
        },
      );
    }
    if (this.state.historyFiltering) {
      this.setState({ ...this.state, historyFiltering: true });
    }

    if (
      this.props.creditsHistory !== prevProps.creditsHistory ||
      this.props.user !== prevProps.user
    ) {
      this.handleHeights();
    }
  }

  handleNavigationBack = (e) => {
    e.preventDefault();
    if (this.props.user.user_admin || this.state.isSuperPartner)
      this.props.history.push('/main/clients');
    else this.props.history.push('/main/campaigns');
  };

  availableCredits = () => {
    // if (this.props.user.branch?.credits) {
    //   return (
    //     this.props.user.branch.credits -
    //     this.props.user.branch.credits_assigned
    //   );
    // }
    if (this.props.user.partner?.credits) {
      return (
        this.props.user.partner?.credits_uses.credits -
        this.props.user.partner?.credits_uses.credits_assigned
      );
    }
    if (this.props.user.account?.credits) {
      return (
        this.props.user.account?.credits -
        this.props.user.account?.credits_assigned
      );
    }
  };

  calculateCreditsActive = () => {
    const total =
      this.props.user.branch?.credits ||
      this.props.user.partner?.credits ||
      this.props.user.account?.credits;

    const assigned =
      this.props.user.branch?.credits_assigned ||
      this.props.user.partner?.credits_assigned ||
      this.props.user.account?.credits_assigned;

    const assignedNotConsumed =
      this.props.credits_account_status?._sum?.credits -
      this.props.credits_account_status?._sum?.credits_assigned;

    return total - assigned + assignedNotConsumed;
  };

  onSubmit = () => {
    let filter = { accounts: [], branchs: [] };

    Object.keys(this.props.filterValues).forEach((key) => {
      const value = this.props.filterValues[key];
      if (value.inputValue !== '' || value.inputValue.length !== 0) {
        filter = {
          ...filter,
          [key]: Array.isArray(value.inputValue)
            ? value.inputValue.map((res) => res.code)
            : parseBackDate(value.inputValue), // is date
        };
      }
    });

    this.props.filterValues.search.inputValue.map((client) => {
      filter = {
        ...filter,
        accounts: [...filter.accounts, client.code],
      };
    });

    this.props.updateFilterValues(filter);
  };

  addOption = (type, name, option) => {
    if (option.isAccount) {
      let newArray = [...this.state.filterValues[name].inputValue];
      if (type === 'add') {
        newArray.push(option);
      } else {
        newArray = newArray.filter((item) => item.code !== option.code);
      }
      this.setState(
        {
          ...this.state,
          filterValues: {
            ...this.state.filterValues,
            [name]: {
              ...this.state.filterValues[name],
              inputValue: newArray,
            },
            [type === 'add' && name === 'accounts' ? 'branchs' : '']: [],
          },
        },
        () => {
          let state = {
            accounts: this.state.filterValues.accounts,
            branchs: this.state.filterValues.branchs,
            search: this.state.filterValues.search.inputValue,
          };
          this.props.setCreditOption(state);
          this.props.updatePage(1);
          this.props.getCreditsHistory();
        },
      );
      let state = {
        branchs: this.props.filterValues.branchs.inputValue,
        [name]: newArray,
      };

      this.props.setCreditOption(state);

      let account_id = newArray.flatMap((item) => item.code);
      if (newArray.length > 0)
        this.props.getBranchs({ account_id, search: '' });
      else this.props.getBranchs({ account_id: null, search: '' });
    } else {
      let newArray = this.state.filterValues.branchs.inputValue || [];
      if (type === 'add') {
        newArray.push(option);
      } else {
        newArray = newArray.filter((item) => item.code !== option.code);
      }
      this.setState(
        {
          ...this.state,
          filterValues: {
            ...this.state.filterValues,
            [name]: {
              ...this.state.filterValues[name],
              inputValue: newArray,
            },
          },
        },
        () => {
          let state = {
            search: this.props.filterValues.search.inputValue,
            branchs: newArray.map((branch) => branch?.code),
          };
          this.props.setCreditOption(this.state.filterValues);
          this.props.updatePage(1);
          this.props.getCreditsHistory();
        },
      );
    }
  };

  onFilterReset = () => {
    this.setState({
      ...this.state,
      filterValues: {
        ...this.state.filterValues,
        search: { inputValue: [] },
        branchs: { inputValue: [] },
      },
    });
    let filter = {};
    filter = {
      ordering: '-created_at',
      search: { inputValue: [] },
      branchs: { inputValue: [] },
      accounts: { inputValue: [] },
    };
    let state = { search: [], branchs: [] };
    this.props.setCreditOption(state);
    if (this.props.user.accounts) {
      this.props.updateCommonFilterValues(filter);
    }
    this.props.getBranchs({ account_id: null, search: '' });

    this.props.getFilterDates({
      start_value: '',
      end_value: '',
    });
  };

  onClearFilter = () => {
    this.props.getFilterDates({
      start_value: '',
      end_value: '',
    });
    this.setState(
      {
        ...this.state,
        historyFiltering: false,
        filterValues: {
          ...this.state.filterValues,
          search: { inputValue: '' },
          branchs: { inputValue: [] },
          accounts: { inputValue: [] },
        },
        isReset: true,
      },
      () => {
        let state = {
          accounts: this.state.filterValues.accounts,
          branchs: this.state.filterValues.branchs,
          search: this.state.filterValues.search.inputValue,
        };
        this.props.setCreditOption(state);
        this.props.getCreditsHistory();
      },
    );
  };

  filterByDates = () => {
    if (this.props.toggleCleanFilter !== undefined) {
      this.props.toggleCleanFilter(true);
    }
    if (
      this.props.filterDates.start_value === '' &&
      this.props.filterDates.end_value === ''
    ) {
      this.props.getFilterDates({
        start_value: this.props.start_date,
        end_value: this.props.end_date,
      });
    }

    this.props.setCreditFilterClear(false);
    this.props.getCreditsHistory();
    this.setState({ ...this.state, isReset: false });
  };

  handleSelect = (e) => {
    if (e === I18n.t('campaigns.identifier')) {
      this.props.history.push('campaigns');
    }
    if (e === I18n.t('clients.filiar')) this.props.history.push('clients');

    if (e === 'account_tab') {
      this.props.updateViewBranch(false);
      this.props.history.push('/main/clients');
    }

    if (e === 'branch_tab') {
      this.props.updateViewBranch(true);
      this.props.history.push('/main/clients');
    }
  };
  render() {
    return (
      <div
        className={`credits-history-container row ${
          (this.props.user.user_admin ||
            this.props.user.partner.is_super_dashboard) &&
          'clients-credits-history'
        } ${
          this.props.user.partner.is_super_dashboard && 'branch-credits-history'
        }`}
      >
        <div
          className={`${
            this.props.user?.branch || this.props.user.account
              ? 'credits-history-branch col-md-10'
              : 'credits-history-branch'
          } table-tabs`}
        >
          <Tabs
            className="credits-tabs-container"
            onSelect={this.handleSelect}
            defaultActiveKey={I18n.t('creditsHistory.identifier')}
          >
            {this.props.user.user_admin && (
              <Tab
                eventKey="account_tab"
                title={I18n.t('clients.accountButton')}
              ></Tab>
            )}

            {this.props.user.user_admin && (
              <Tab
                eventKey="branch_tab"
                title={I18n.t('clients.filiar')}
                className={`clients-tab`}
              ></Tab>
            )}

            {this.props.user.branch && (
              <Tab
                eventKey={I18n.t('campaigns.identifier')}
                title={I18n.t('campaigns.campaigns')}
              ></Tab>
            )}

            {this.props.user.account && (
              <Tab
                eventKey={I18n.t('clients.filiar')}
                title={I18n.t('clients.filiar')}
              ></Tab>
            )}

            <Tab
              eventKey={I18n.t('creditsHistory.identifier')}
              title={I18n.t('creditsHistory.identifier')}
            >
              <div
                className={` ${
                  this.props.user?.branch || this.props.user?.account
                    ? 'card-header-branch'
                    : 'card-header-branch'
                } `}
              >
                <div className={'card-header-right'}>
                  {this.renderFilters()}
                  <div className="filter-clear-button">
                    <Button
                      className="filter-button-client"
                      iconButton
                      iconClass={'close'}
                      text={I18n.t('buttons.clearFilter')}
                      onClick={() => {
                        this.onClearFilter();
                      }}
                      disabled={
                        this.props.filterDates.start_value === '' &&
                        this.props.filterDates.end_value === '' &&
                        this.state.filterValues.accounts?.inputValue?.length <
                          1 &&
                        this.state.filterValues.branchs?.inputValue?.length <
                          1 &&
                        this.state.filterValues.search?.inputValue?.length <= 1
                      }
                    />
                  </div>
                </div>
              </div>
              <div className={`table `}>{this.renderTable()}</div>
            </Tab>
          </Tabs>
        </div>
        {(this.props.user?.branch || this.props.user?.account) && (
          <div className="widget-side">
            <div className="credits-card col-md-2" id="credits-card">
              {this.props.user.branch && (
                <Button
                  text={I18n.t('campaigns.new')}
                  iconButton
                  iconClass="add"
                  className="primary"
                  onClick={() => {
                    this.props.unselectCampaign();
                    this.props.history.push('new-campaign');
                  }}
                />
              )}

              {this.props.user.account && (
                <Button
                  text={I18n.t('clients.add_filial')}
                  iconButton
                  iconClass="add"
                  className="primary"
                  onClick={() => {
                    this.props.updateViewBranch(true);
                    this.props.history.push('/main/new-client');
                  }}
                />
              )}
              <div className="col total-credits">
                <div className="database">
                  <span className="material-symbols-rounded material-symbols-filled">
                    database
                  </span>
                  {I18n.t('credits.totalCredits')}
                </div>
                <div className="totalcredits-text">
                  <span className="text">
                    {I18n.t('credits.totalCreditsCredits')}
                  </span>
                  <span className="number">
                    {this.props.user.branch?.credits ||
                      this.props.user.partner?.credits ||
                      this.props.user.account?.credits}
                  </span>
                  <span className="text">
                    {I18n.t('credits.totalCreditsDescription')}
                  </span>
                </div>
              </div>
              <div className="col">
                <CreditsWidgets
                  material_symbol={'priority'}
                  title={I18n.t('credits.available')}
                  label={I18n.t('credits.availableCredits')}
                  value={this.availableCredits()}
                  description={
                    this.props.user_?.account !== null
                      ? I18n.t('credits.availableCreditsDescription')
                      : I18n.t('credits.availableCreditsDescriptionFilial')
                  }
                  type={'column-view'}
                  renderStyle="available"
                />
              </div>
              <div className="col">
                <CreditsWidgets
                  material_symbol={
                    this.props.user_.account !== null
                      ? 'account_tree'
                      : 'campaign'
                  }
                  title={I18n.t('credits.associated')}
                  label={I18n.t('credits.associatedCreditsDetail')}
                  value={
                    // this.props.user.branch?.credits_assigned ||
                    this.props.user.partner?.credits_assigned ||
                    this.props.user.account?.credits_assigned
                  }
                  description={
                    this.props.user_.account !== null
                      ? I18n.t('credits.associatedCreditsDescription')
                      : I18n.t('credits.associatedCreditsDescriptionFilial')
                  }
                  type={'column-view'}
                  renderStyle="associated"
                />
              </div>
              {this.props.user_.branch ? (
                <div className="col">
                  <CreditsWidgets
                    material_symbol={'groups'}
                    title={I18n.t('credits.assigned')}
                    label={I18n.t('credits.assignedCredits')}
                    value={
                      this.props.credits_user_status?._sum?.credits_assigned
                    }
                    description={I18n.t('credits.assignedCreditsDescription')}
                    type={'column-view'}
                    renderStyle={'actives'}
                  />
                </div>
              ) : this.props.user.account ? (
                <div className="col">
                  <CreditsWidgets
                    material_symbol={'groups'}
                    title={I18n.t('credits.creditsActive.title')}
                    label={I18n.t('credits.creditsActive.label')}
                    value={this.calculateCreditsActive()}
                    description={I18n.t('credits.creditsActive.description')}
                    type={'column-view'}
                    renderStyle={'actives'}
                  />
                </div>
              ) : null}

              <div className="col">
                <CreditsWidgets
                  material_symbol={'sports_esports'}
                  title={I18n.t('credits.consumed')}
                  label={I18n.t('credits.creditsConsumed')}
                  value={
                    this.props.credits_user_status?._sum?.credits_consumed || 0
                  }
                  description={I18n.t('credits.creditsConsumedDescription')}
                  type={'column-view'}
                  renderStyle={'consumed'}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  renderFilters() {
    let isAdmin = this.props.user.user_admin ? true : false;
    let result = [];
    if (this.props.user.account?.id)
      result.push(
        <div className="dropdown-client">
          <div className="clients-filter">
            <FormDropdown
              name={'branchs'}
              placeholder={I18n.t('common.sidebar.filiales')}
              value={this.state.filterValues.branchs}
              search={this.props.getBranchs}
              multiSelect
              addOption={this.addOption}
              options={this.props.branchs.map((client) => {
                return {
                  code: client.id,
                  description: client.name,
                  isAccount: false,
                };
              })}
              getAllClientsPrompts={{
                account_id: this.props.user.account.id,
                search: '',
              }}
              hide={true}
            />
          </div>
        </div>,
      );
    if (isAdmin)
      result.push(
        <>
          <FormDropdown
            name={'accounts'}
            placeholder={I18n.t('common.sidebar.clients')}
            value={this.state?.filterValues?.accounts}
            search={this.props.getAllClients}
            multiSelect
            addOption={this.addOption}
            options={this.props.allClients.map((client) => {
              return {
                code: client.id,
                description: client.name,
                isAccount: true,
              };
            })}
            getAllClientsPrompts={
              this.props.user.user_admin
                ? {
                    account_dropdown: true,
                    view_branch: false,
                    view_account: true,
                    search: '',
                  }
                : {
                    account_dropdown: true,
                    view_branch: true,
                    view_account: false,
                    search: '',
                  }
            }
            hide={true}
          />

          <FormDropdown
            name={'branchs'}
            placeholder={I18n.t('common.sidebar.filiales')}
            value={this.state.filterValues.branchs}
            search={this.props.getBranchs}
            multiSelect
            addOption={this.addOption}
            options={this.props.branchs.map((client) => {
              return {
                code: client.id,
                description: client.name,
                isAccount: false,
              };
            })}
            getAllClientsPrompts={
              this.props.user.user_admin
                ? { account_id: null, search: '' }
                : {
                    account_id: this.props.user.account.id,
                    search: '',
                  }
            }
            hide={true}
          />
        </>,
      );
    result.unshift(
      <>
        <SearchInput
          name="search"
          placeholder={I18n.t('persons.search')}
          value={this.state.filterValues.search.inputValue}
          displayValue={this.state.filterValues.search.inputValue}
          autoComplete={'off'}
          onChange={(e) => {
            this.setState({
              ...this.state,
              filterValues: {
                ...this.state.filterValues,
                search: { inputValue: e.target.value },
              },
            });
            this.props.updateFilterValues({
              ...this.state.filterValues,
              search: e.target.value,
            });
          }}
        />
        <div className="date-range-picker">
          <DateRangePickerFilter
            start_date={this.props.start_date}
            end_date={this.props.end_date}
            classNameButton="date-person"
            {...this.props}
            filter={() => this.filterByDates()}
            resetFilter={this.state.isReset}
            hiddenPopup={true}
            inCredits={this.props.view_credits}
            resetInCredits={this.props.resetInCredits}
            onCredits={this.props.onCredits}
            historyFiltering={
              this.props.filterDates.start_value !== '' &&
              this.props.filterDates.end_value !== ''
            }
          />
        </div>
      </>,
    );

    return result;
  }

  renderTable() {
    const { creditsHistory } = this.props;
    const { loading, success, error } = this.props.getCreditsHistoryStatus;
    if (error)
      return (
        <div className="text-warning">
          {I18n.t('creditsHistory.table.error')}
        </div>
      );
    else if (!loading || success) {
      let columns = [];
      if (this.props.user.user_admin) {
        columns.push({
          Header: (
            <span class="credit-account">
              <span>
                {I18n.t('clients.accountTable')}
                <span
                  class={`material-symbols-rounded ${
                    this.state.isClicked &&
                    this.state.workingCell === 'credits' &&
                    'turn-icon'
                  }`}
                >
                  arrow_drop_down
                </span>
              </span>
            </span>
          ),
          headerClassName: 'wordwrap',
          className: 'account-name',
          accessor: 'account.name',
          id: 'account__name',
          Cell: (props) => (
            <span class="">{props.value ? props.value : '-'}</span>
          ),
        });
      }
      if (this.props.user.user_admin || this.props.user.account) {
        columns.push({
          Header: (
            <span class="credit-branch">
              <span>
                {I18n.t('clients.branchButton')}
                <span
                  class={`material-symbols-rounded ${
                    this.state.isClicked &&
                    this.state.workingCell === 'credits' &&
                    'turn-icon'
                  }`}
                >
                  arrow_drop_down
                </span>
              </span>
            </span>
          ),
          headerClassName: 'wordwrap credit-branch-header',
          className: 'credit-branch-cell',
          accessor: 'branch.name',
          id: 'branch__name',
          Cell: (props) => (
            <span class="">{props.value ? props.value : '-'}</span>
          ),
        });
      }

      if (this.props.user.branch) {
        columns.push({
          Header: (
            <span>
              {I18n.t('clients.campaign')}
              <span
                class={`material-symbols-rounded ${
                  this.state.isClicked &&
                  this.state.workingCell === 'credits' &&
                  'turn-icon'
                }`}
              >
                arrow_drop_down
              </span>
            </span>
          ),
          headerClassName: 'wordwrap',
          accessor: 'campaign.name',
          id: 'campaign__name',
          Cell: (props) => (
            <span class="">{props.value ? props.value : '-'}</span>
          ),
        });
      }

      if (
        this.props.user.user_admin ||
        this.props.user.partner.is_super_dashboard
      ) {
        columns.push({
          Header: (
            <span class="credit-transaction-head">
              <span>
                {I18n.t('creditsHistory.table.transaction')}
                <span
                  class={`material-symbols-rounded ${
                    this.state.isClicked &&
                    this.state.workingCell === 'credits' &&
                    'turn-icon'
                  }`}
                >
                  arrow_drop_down
                </span>
              </span>
            </span>
          ),
          headerClassName: 'wordwrap transaction',
          accessor: (row) => {
            return row;
          },
          id: 'transaction',
          Cell: (props) => {
            let transaction =
              props.value.credits >= 0
                ? I18n.t('creditsHistory.table.assigned')
                : I18n.t('creditsHistory.table.removed');
            if (props.value.campaign?.name)
              return (
                <span class="transaction-name">
                  {transaction} {this.state?.language === 'pt' ? 'para ' : ''}
                  {I18n.t('clients.campaign')}
                </span>
              );
            if (props.value.branch?.name)
              return (
                <span class="transaction-name">
                  {transaction} {this.state?.language === 'pt' ? 'para ' : ''}
                  {I18n.t('clients.filialInput')}
                </span>
              );
            if (props.value.account?.name)
              return (
                <span class="transaction-name">
                  {transaction} {this.state?.language === 'pt' ? 'por ' : ''}
                  {I18n.t('clients.accountTable')}
                </span>
              );
            return <span class="transaction-name">-</span>;
          },
        });
      }

      columns = [
        ...columns,

        {
          Header: (
            <span class="credit-amount-head">
              <span>
                {this.props.user.user_company &&
                !this.props.user.partner.is_super_dashboard
                  ? I18n.t('creditsHistory.identifier')
                  : I18n.t('credits.quantity')}
                <span
                  class={`material-symbols-rounded ${
                    this.state.isClicked &&
                    this.state.workingCell === 'credits' &&
                    'turn-icon'
                  }`}
                >
                  arrow_drop_down
                </span>
              </span>
            </span>
          ),
          headerClassName: 'wordwrap credit-amount',
          className: 'credit-amount-cell',
          accessor: 'credits',
          id: 'credits',
          Cell: (props) => (
            <span class="credit-name">
              <NumberLabel value={props.value} />
            </span>
          ),
        },
        {
          Header: (
            <span class="credit-date-head">
              <span>
                {I18n.t('creditsHistory.table.date')}
                <span
                  class={`material-symbols-rounded ${
                    this.state.isClicked &&
                    this.state.workingCell === 'credits' &&
                    'turn-icon'
                  }`}
                >
                  arrow_drop_down
                </span>
              </span>
            </span>
          ),
          headerClassName: 'wordwrap credit-date-created',
          className: 'create-date-cell',
          id: 'created',
          accessor: 'created',
          Cell: (props) => (
            <span class="credit-date">
              {dayjs(props.value).format('DD/MM/YYYY')}
            </span>
          ),
        },
        {
          Header: (
            <span class="credits-user">
              <span>
                {I18n.t('users.userOnTable')}
                <span
                  class={`material-symbols-rounded ${
                    this.state.isClicked &&
                    this.state.workingCell === 'credits' &&
                    'turn-icon'
                  }`}
                >
                  arrow_drop_down
                </span>
              </span>
            </span>
          ),
          headerClassName: 'wordwrap',
          className: 'credits-user-cell',
          accessor: 'user.full_name',
          id: 'full_name',
          Cell: (props) => (
            <span class="">{props.value ? props.value : '-'}</span>
          ),
        },
      ];
      return (
        <ReactTable
          columns={columns}
          manual
          data={creditsHistory}
          minRows={0}
          noDataText={I18n.t('creditsHistory.table.noRows')}
          resizable={false}
          manualPagination
          pageSize={this.props.pageSize}
          defaultSorted={[
            {
              id: 'created',
              desc: false,
            },
          ]}
          sorted={[
            {
              id: this.state.ordering.id,
              desc: this.state.ordering.desc,
            },
          ]}
          onSortedChange={(newSorted, column, shiftKey) => {
            let columnId = column.id;
            this.setState(
              {
                ...this.state,
                ordering: {
                  id: columnId,
                  desc: newSorted[0].desc,
                },
              },
              () =>
                this.props.updateFilterValues({
                  ordering: (newSorted[0].desc ? '-' : '') + columnId,
                }),
            );
          }}
          PaginationComponent={() => {
            const {
              currentPage,
              updatePage,
              updatePageSize,
              totalPages,
              pageSize,
              totalCount,
            } = this.props;
            const isOnFirstPage = currentPage === 1;
            const isOnLastPage = currentPage === totalPages;
            return (
              <TablePaginationDrawer
                data={{
                  totalCount,
                  currentPage,
                  pageSize,
                  isOnFirstPage,
                  isOnLastPage,
                  updatePage,
                  updatePageSize,
                  totalPages,
                }}
                table={'creditsHistory'}
              />
            );
          }}
          loading={loading}
          LoadingComponent={TableLoader}
        />
      );
    } else return <Loader />;
  }

  componentWillUnmount() {
    this.onFilterReset();
    window.removeEventListener('load', this.handleHeights(true));
    window.removeEventListener('resize', () => {
      this.handleHeights(true);
    });
  }
}

const mapStateToProps = (state) => ({
  userGroups: state.session.user.groups,
  getCreditsHistoryStatus: state.common.getCreditsHistoryStatus,
  creditsHistory: state.common.creditsHistory,
  creditsfirstusers: state.common.creditsfirstusers,
  isSuperPartner:
    state.session.user.partner && state.session.user.partner.is_super_dashboard,
  user: state.session.user,
  user_: state.session.user,
  totalCount: state.common.totalItems,
  currentPage: state.common.currentPage,
  totalPages: state.common.totalPages,
  pageSize: state.common.pageSize,

  allClients: state.clients.allClients,
  branchs: state.clients.branchs,

  filterDates: state.common.filterDates,
  start_date: state.dashboard.start_date,
  end_date: state.dashboard.end_date,
  creditOptionValues: state.common.filterValues,
  filterValues: state.common.filterValues,
  credits_user_status: state.campaigns.credits_user_status,
  credits_account_status: state.clients.credits_account_status,
  language: state.i18n.locale,
});

const mapDispatchToProps = (dispatch) => ({
  unselectCampaign: () => dispatch(actions.campaigns.unselectCampaign()),
  getCreditsHistory: () => dispatch(actions.common.getCreditsHistory()),
  getFilterDates: (filterDates) =>
    dispatch(actions.common.getFilterDates(filterDates)),
  updatePage: (page) => dispatch(actions.common.updatePageCreditsHistory(page)),
  updatePageSize: (size) =>
    dispatch(actions.common.updatePageSizeCreditsHistory(size)),
  updateFilterValues: (filter) =>
    dispatch(actions.common.updateFilterValues(filter)),
  getAllClients: ({ account_dropdown, view_branch, view_account, search }) =>
    dispatch(
      actions.clients.getAllClients(
        account_dropdown,
        view_branch,
        view_account,
        search,
      ),
    ),
  getBranchs: ({ account_id, search }) =>
    dispatch(actions.clients.getBranchs(account_id, search)),
  setCreditFilterClear: (value) =>
    dispatch(actions.common.setCreditFilterClear(value)),
  setCreditOption: (state) => dispatch(actions.common.setCreditOption(state)),
  updateViewBranch: (flag) => {
    dispatch(actions.clients.getClientsScreen('new'));
    dispatch(actions.clients.updateViewBranch(flag));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(CreditsHistory));
