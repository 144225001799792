export const GET_USERS = 'GET USERS';
export const GET_USERS_RESPONSE = 'GET USERS RESPONSE';
export const GET_USERS_ERROR = 'GET USERS ERROR';
export const CREATE_USER = 'CREATE USER';
export const CREATE_USER_RESPONSE = 'CREATE USER RESPONSE';
export const CREATE_USER_ERROR = 'CREATE USER ERROR';
export const CREATE_USER_CLEAN_ERROR = 'CREATE USER CLEAN ERROR';
export const CREATE_USER_FROM_PARTNER = 'CREATE USER FROM PARTNER';
export const CREATE_USER_FROM_PARTNER_RESPONSE =
  'CREATE USER FROM PARTNER RESPONSE';
export const CREATE_USER_FROM_PARTNER_ERROR = 'CREATE USER FROM PARTNER ERROR';
export const UPDATE_USER = 'UPDATE USER';
export const UPDATE_USER_EMAIL = 'UPDATE USER EMAIL';
export const UPDATE_USER_RESPONSE = 'UPDATE USER RESPONSE';
export const UPDATE_USER_ERROR = 'UPDATE USER ERROR';
export const DELETE_USER = 'DELETE USER';
export const DELETE_USER_RESPONSE = 'DELETE USER RESPONSE';
export const DELETE_USER_ERROR = 'DELETE USER ERROR';
export const SELECT_USER = 'SELECT USER';
export const UPDATE_PAGE = 'UPDATE PAGE USERS';
export const UPDATE_PAGE_SIZE = 'UPDATE PAGE USERS SIZE';
export const UPDATE_USERS_FILTER_VALUES = 'UPDATE USERS FILTER VALUES';
export const CHANGE_PASSWORD = 'CHANGE PASSWORD';
export const CHANGE_PASSWORD_RESPONSE = 'CHANGE PASSWORD RESPONSE';
export const CHANGE_PASSWORD_ERROR = 'CHANGE PASSWORD ERROR';
export const VALIDATE_USER_VIA_EMAIL = 'VALIDATE USER VIA EMAIL';
export const VALIDATE_USER_VIA_EMAIL_RESPONSE =
  'VALIDATE USER VIA EMAIL RESPONSE';
export const VALIDATE_USER_VIA_EMAIL_ERROR = 'VALIDATE USER VIA EMAIL ERROR';
export const CLEAN_USER_VALIDATION = 'CLEAN USER VALIDATION';
export const GET_USER_GROUPS = 'GET USER GROUPS';
export const GET_USER_GROUPS_RESPONSE = 'GET USER GROUPS RESPONSE';
export const GET_USER_GROUPS_ERROR = 'GET USER GROUPS ERROR';
export const CLEAR_UI_VALUE_USERS = 'CLEAR UI VALUE USERS';
export const LOGOUT = 'LOGOUT';

export const users = {
  //CRUD

  createUser: (body) => ({ type: CREATE_USER, body }),
  createUserResponse: (response) => ({ type: CREATE_USER_RESPONSE, response }),
  createUserError: (error) => ({ type: CREATE_USER_ERROR, response: error }),
  createUserCleanError: (error) => ({ type: CREATE_USER_CLEAN_ERROR }),

  createUserFromPartner: (body) => ({ type: CREATE_USER_FROM_PARTNER, body }),
  createUserFromPartnerResponse: (response) => ({
    type: CREATE_USER_FROM_PARTNER_RESPONSE,
    response,
  }),
  createUserFromPartnerError: (error) => ({
    type: CREATE_USER_FROM_PARTNER_ERROR,
    response: error,
  }),

  getUsers: (search) => ({ type: GET_USERS, search }),
  getUsersResponse: (response) => ({ type: GET_USERS_RESPONSE, response }),
  getUsersError: (error) => ({ type: GET_USERS_ERROR, response: error }),

  updateUser: (id, user) => ({ type: UPDATE_USER, id, user }),
  updateUserEmail: (id, user) => ({ type: UPDATE_USER_EMAIL, id, user }),
  updateUserResponse: (response) => ({
    type: UPDATE_USER_RESPONSE,
    response: response,
  }),
  updateUserError: (error) => ({ type: UPDATE_USER_ERROR, error: error }),

  deleteUser: (id) => ({ type: DELETE_USER, id }),
  deleteUserResponse: (response) => ({
    type: DELETE_USER_RESPONSE,
    response: response,
  }),
  deleteUserError: (error) => ({ type: DELETE_USER_ERROR, error: error }),

  changePassword: (password, currentPassword) => ({
    type: CHANGE_PASSWORD,
    password,
    currentPassword,
  }),
  changePasswordResponse: (response) => ({
    type: CHANGE_PASSWORD_RESPONSE,
    response,
  }),
  changePasswordError: (error) => ({ type: CHANGE_PASSWORD_ERROR, error }),

  // validate user via email

  validateUserViaEmail: (email) => ({ type: VALIDATE_USER_VIA_EMAIL, email }),
  validateUserViaEmailResponse: (response) => ({
    type: VALIDATE_USER_VIA_EMAIL_RESPONSE,
    response,
  }),
  validateUserViaEmailError: (error) => ({
    type: VALIDATE_USER_VIA_EMAIL_ERROR,
    error,
  }),
  cleanUserValidation: () => ({ type: CLEAN_USER_VALIDATION }),
  selectUser: (id) => ({ type: SELECT_USER, id: id }),
  updateFilterValues: (filterValues) => ({
    type: UPDATE_USERS_FILTER_VALUES,
    filterValues,
  }),
  updatePage: (pageNumber) => ({ type: UPDATE_PAGE, pageNumber }),
  updatePageSize: (pageSize) => ({ type: UPDATE_PAGE_SIZE, pageSize }),

  //OPTIONS

  getUserGroups: (password) => ({ type: GET_USER_GROUPS, password }),
  getUserGroupsResponse: (response) => ({
    type: GET_USER_GROUPS_RESPONSE,
    response,
  }),
  getUserGroupsError: (error) => ({ type: GET_USER_GROUPS_ERROR, error }),

  //UI

  clearUiValue: (type, condition) => ({
    type: CLEAR_UI_VALUE_USERS,
    op_type: type,
    condition,
  }),

  logout: () => ({ type: LOGOUT }),
};
