import React, { Component } from 'react';
import './ClientDetailScreen.css';
import { I18n } from 'react-redux-i18n';
import Button from '../../../components/common/Button';
import Input from '../../../components/common/Input';
import Dropdown from '../../../components/common/Dropdown';
import DeleteClientModal from '../../../components/common/DeleteClientModal/DeleteClientModal';
import CreditsWidgets from '../../../components/common/CreditsWidgets/CreditsWidgets';
import UpdateCredits from '../UpdateCredits/UpdateCredits';
import { notify } from 'react-notify-toast';
import { success } from '../../../utils/modals';
import Loader from '../../../components/common/Loader/Loader';
import { languagesCodes } from '../../../i18n/index';
import { NumberLabel } from '../../../components/common/NumberLabel/NumberLabel';
import SquareButton from '../../../components/common/SquareButton';
import { Tabs, Tab } from 'react-bootstrap';
import regex from '../../../utils/regex';
import ImageUploader from 'react-images-upload';
import ModalAlert from '../../../components/common/ModalAlert';
import Tooltip from '../../../components/common/Tooltip';
import { fileToBase64 } from '../../../utils/files';
import {
  errorMessage,
  successMessage,
  SnackBar,
} from '../../../utils/snackbar';
import dayjs from 'dayjs';
import FormDropdown from '../../../components/common/Dropdown/FormDropdown';
import TableMenuItem from '../../../components/common/TableMenuItem/TableMenuItem';
import DeleteModal from '../../../components/common/ModalAlert';
import LanguageSelector from '../../../components/common/LanguageSelector/LanguageSelector';
import { reportDictionary } from '../../../utils/reportDictionary';

const emptyState = {
  values: {
    company: { inputValue: '', required: true, validation: false },
    language: {
      inputValue: '',
      required: true,
      validation: false,
    },
    password: {
      inputValue: '',
      required: true,
      validation: false,
      validator: (password) => regex.passwordRegex.test(password),
    },
    email: {
      inputValue: '',
      required: true,
      validation: false,
      validator: (email) => regex.emailRegex.test(email),
    },
    uncovered: { inputValue: 0, required: true, validation: false },
    credits_acquired: {
      inputValue: '',
      required: true,
      validation: true,
      isValid: (v) => v > 0,
    },
    first_name: { inputValue: '', required: true, validation: false },
    last_name: { inputValue: '', required: true, validation: false },
    client_type: { inputValue: '', required: false, validation: false },
    isSuperDashboard: { inputValue: false },
    ie_active: { inputValue: false },
    /* group_ids: { inputValue: [] } */
  },
  logo: false,
  logo_url: false,
  propLogo: false,
  showModal: false,
  showDeleteClientModal: false,
  passwordError: false,
  invalidLogo: false,
  file_name: null,
  showPasswordModal: false,
  logo_base64: '',
  logo_width: '',
  logo_height: '',
};
class ClientDetailScreen extends Component {
  constructor(props) {
    super(props);
    /*
            id: 1
            company: "Partner QAx"
            link: "qa"
            language: "es"
            credits_acquired: 112001
            credits_used: 28
            uncovered: 1000
            expiry_date: "2019-10-15T09:40:13.357452Z"
            created_at: "2019-10-15T09:40:13.357452Z"
        */
    this.state = props.update
      ? {
          ...emptyState,
          values: {
            company: {
              inputValue: props.client.name,
              required: true,
              validation: false,
            },
            language: {
              inputValue: {
                code: props.client.language,
                description: I18n.t('languages.' + props.client.language),
              },
              required: true,
              validation: false,
            },
            // password
            // repeatPassword
            // email
            credits_acquired: {
              inputValue: props.client.credits,
              required: true,
              validation: true,
              isValid: (v) => v > 0,
            },
            credits_used: {
              inputValue: props.client.credits_assigned,
              required: true,
              validation: false,
            },
            uncovered: {
              inputValue: props.client.uncovered || 0,
              required: true,
              validation: false,
            },
            client_type: {
              inputValue: !props.client.nwm_accountId
                ? props.client.nwm_account_type
                  ? {
                      code: props.client.nwm_account_type?.id,
                      description: props.client.nwm_account_type?.name_es,
                    }
                  : ''
                : {
                    code: props.client.account.nwm_account_type?.id,
                    description: props.client.account.nwm_account_type?.name_es,
                  },
              validation: false,
            },
            isSuperDashboard: {
              inputValue: props.user.is_super_dashboard || false,
              validation: false,
            },
            ie_active: {
              inputValue: this.props.client.ie_active || false,
              validation: false,
            },
            repeatPassword: {
              inputValue: '',
              required: true,
              validator: (pass) =>
                pass === this.state.values.password.inputValue,
            },
            // first_name
            // last_name
            // expiry_date
          },
        }
      : {
          ...emptyState,
          values: {
            ...emptyState.values,
            repeatPassword: {
              inputValue: '',
              required: true,
              validator: (pass) =>
                pass === this.state.values.password.inputValue,
            },
            client_type:
              this.props.user && this.props.user.is_super_dashboard
                ? {
                    inputValue: (function (props) {
                      const { id: code, name_es } = props.clientTypes.find(
                        ({ name_es }) => name_es === 'Cliente',
                      );
                      return { code, description: name_es };
                    })(this.props),
                    required: false,
                    validation: false,
                  }
                : emptyState.values.client_type,
          },
        };
  }

  componentDidMount() {
    //this.props.updateViewBranch(false);
    if (this.props.user.user_admin) {
      this.props.getClientTypes();
      if (!this.props.clients_screen || this.props.clients_screen === '') {
        this.props.updateViewBranchInMount(false);
      }
    } else {
      if (!this.props.clients_screen || this.props.clients_screen === '') {
        this.props.updateViewBranchInMount(true);
      }
    }
    if (this.props.client.logo) {
      this.setState({ ...this.state, propLogo: this.props.client.logo });
      this.props.getLogoUrl(this.props.client.logo);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.logo_url !== this.props.logo_url) {
      this.setState({ ...this.state, logo_url: this.props.logo_url });
    }
    if (prevState.submit === true) {
      this.setState({
        ...this.state,
        submit: false,
      });
    }
  }

  inputIsValid(val) {
    let value = this.state.values[val];
    return (
      (!value.required || value.inputValue || value.inputValue === 0) &&
      !(value.validation && !value.isValid(value.inputValue))
    );
  }

  formIsValid() {
    let requiredValid =
      !this.props.uploadAttachmentStatus.loading &&
      !Object.keys(this.state.values).find((val) => !this.inputIsValid(val));
    if (this.props.update) {
      return requiredValid && this.emailIsValid();
    }
    if (this.props.user.user_admin) {
      return (
        this.passwordIsValid() &&
        requiredValid &&
        this.emailIsValid() &&
        this.state.values.client_type.inputValue
      );
    } else {
      return this.passwordIsValid() && requiredValid && this.emailIsValid();
    }
  }

  passwordIsValid() {
    return (
      this.state.values.password.inputValue ===
        this.state.values.repeatPassword.inputValue &&
      regex.passwordRegex.test(this.state.values.password.inputValue)
    );
  }

  emailIsValid() {
    return (
      this.state.values.email.inputValue.length > 0 &&
      regex.emailRegex.test(this.state.values.email.inputValue)
    );
  }

  handleInputChange = (key, value) => {
    this.setState({
      ...this.state,
      values: {
        ...this.state.values,
        [key]: {
          ...this.state.values[key],
          inputValue: value,
        },
      },
    });
  };

  handleNameChange = (e) => {};

  onDrop(pictureFiles, pictureDataURLs) {
    this.setState({
      pictures: pictureFiles,
    });
  }

  showError(title, message) {
    notify.show(
      <SnackBar
        icon={'report'}
        title={title}
        body={message}
        type="error"
        iconFilled
      />,
      'custom',
      5000,
      errorMessage,
    );
    return;
  }

  handleInputLogo = async (e) => {
    let logo = e;
    if (!logo) return;
    if (logo[0] === undefined)
      notify.show(
        <SnackBar
          icon={'report'}
          title={I18n.t('clients.validate_logo.title')}
          body={I18n.t('clients.validate_logo.generic_error')}
          type="error"
          iconFilled
        />,
        'custom',
        5000,
        errorMessage,
      );
    if (
      logo[0] !== undefined &&
      logo[0]?.type !== 'image/jpg' &&
      logo[0]?.type !== 'image/jpeg' &&
      logo[0]?.type !== 'image/png'
    ) {
      this.showError(
        I18n.t('clients.validate_logo.title'),
        I18n.t('clients.validate_logo.invalid_type_file'),
      );
      this.setState({ ...this.state, invalidLogo: true });
    } else if (logo[0]?.size > 2048000) {
      this.showError(
        I18n.t('clients.validate_logo.title'),
        I18n.t('clients.validate_logo.maximum_file_size_exceeded'),
      );
      this.setState({ ...this.state, invalidLogo: true });
    } else if (this.state.logo_height < 200 || this.state.logo_width < 200) {
      this.showError(
        I18n.t('clients.validate_logo.title'),
        I18n.t('clients.validate_logo.minimum_resolution_not_allowed'),
      );
      this.setState({ ...this.state, invalidLogo: true });
    } else {
      // this.props.uploadLogo(logo);
      const logo_base64 = await fileToBase64(logo[0]);
      this.setState({
        ...this.state,
        invalidLogo: false,
        file_name: logo[0]?.name,
        logo: logo[0],
        logo_base64,
      });
    }
  };

  handleCleanLogo = () => {
    this.setState({
      ...this.state,
      file_name: '',
      logo: false,
      propLogo: false,
      logo_base64: false,
      logo_url: false,
    });
  };

  handleCancelCleanLogo = () => {
    this.setState({
      ...this.state,
      showModal: false,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    let httpObject = {};
    Object.keys(this.state.values).forEach((key) => {
      httpObject[key] = Array.isArray(this.state.values[key].inputValue)
        ? this.state.values[key].inputValue.map((res) => res?.code)
        : this.state.values[key].inputValue?.code
        ? this.state.values[key].inputValue?.code
        : this.state.values[key].inputValue;
    });

    let error_;
    if (httpObject.password) {
      error_ = !regex.passwordRegex.test(httpObject.password);
      this.setState({ ...this.state, passwordError: error_ });
    }
    error_ = !this.props.update && !regex.emailRegex.test(httpObject.email);

    // logo true no actualizo el logo
    // logo falso elimino el logo de la empresa (actualizo)
    // logo objeto cargo el logo de la empresa
    let logo = false;

    // Tiene una imagne pre-cargada y no la cambio
    if (this.state.logo_url && !this.state.logo) {
      logo = true;
    }

    // Cargo una nueva imagen
    if (!this.state.logo_url && this.state.logo) {
      logo = this.state.logo;
    }

    const objectToCreate = {
      company_name: httpObject.company,
      credits: httpObject.credits_acquired,
      email: httpObject.email,
      language: httpObject.language.toUpperCase(),
      password: httpObject.password,
      first_name: httpObject.first_name,
      last_name: httpObject.last_name,
      logo,
      is_super_dashboard: this.state.values.isSuperDashboard.inputValue,
      account_type: httpObject.client_type,
      ie_active: this.state.values.ie_active.inputValue,
    };
    if (this.props.update) {
      if (this.props.client.nwm_accountId) {
        Object.assign(objectToCreate, {
          id_branch: this.props.client.id,
        });
      } else {
        Object.assign(objectToCreate, {
          id_account: this.props.client.id,
        });
      }
    }

    let formData = new FormData();
    Object.entries(objectToCreate).forEach(([key, value]) => {
      if (value) formData.append(key, value);
    });
    if (this.props.update && !error_) {
      this.props.updateClient(this.state.logo, formData);
    } else {
      !error_ && this.props.createClient(formData);
    }
    if (this.props.clients_screen === 'edit-client') {
      this.props.backFromDetail(I18n.t('clients.accountButton'));
    }
    if (this.props.clients_screen === 'edit-branch') {
      this.props.backFromDetail(I18n.t('clients.filiar'));
    }
    this.setState({ ...this.state, submit: true, passwordError: error_ });
  };

  handleShowHide = (value) => {
    this.setState({
      ...this.state,
      showModal: value,
    });
  };

  handleShowHideDeleteClient = (value) => {
    this.setState({
      ...this.state,
      showDeleteClientModal: value,
    });
  };

  availableCredits = () => {
    if (this.props.user?.branch) {
      return (
        this.props.user?.branch.credits -
        this.props.user?.branch.credits_assigned
      );
    }
    if (this.props.user?.partner) {
      return this.props.user?.credits - this.props.user?.credits_assigned;
    }
  };

  handleSelect = (e) => {
    if (this.props.user.user_admin) {
      if (e === 1) {
        this.props.backFromDetail(I18n.t('clients.accountButton'));
        this.props.updateViewBranch(false);
        this.props.cleanTable();
        this.props.getClientsScreen('');
        this.props.history.push('/main/clients');
      }
      if (e === 2) {
        this.props.updateViewBranch(true);
        this.props.backFromDetail(I18n.t('common.sidebar.filiales'));
        this.props.cleanTable();
        this.props.getClientsScreen('');
        this.props.history.push('/main/clients');
      }
      if (e === 3) {
        this.props.backFromDetail(I18n.t('campaigns.credits.credits'));
        this.props.getClientsScreen('');
        this.props.history.push({
          pathname: '/main/clients',
          hash: '#credits-history',
        });
      }
      return;
    }
    if (e === 2) {
      if (this.props.user.account)
        this.props.history.push('/main/credits-history');
      else {
        this.props.backFromDetail(I18n.t('campaigns.credits.credits'));
        this.props.history.push('/main/clients');
      }
    }
    if (e === I18n.t('clients.accountButton')) {
      // this.props.backFromDetail(true);
      this.props.history.push('/main/clients');
    }
  };

  render() {
    const props = this.props;
    if (this.props.createStatus.success) {
      notify.show(
        <SnackBar
          icon={'priority'}
          title={I18n.t('common.excellent')}
          body={
            !this.props.user.user_admin
              ? I18n.t('clients.success.createFilial')
              : I18n.t('clients.success.create')
          }
          type="success"
          iconFilled
        />,
        'custom',
        5000,
        successMessage,
      );
      this.props.clearUiValue('createStatus', 'success');
    }

    if (this.props.updateStatus.success) {
      notify.show(
        <SnackBar
          icon={'priority'}
          title={I18n.t('common.excellent')}
          body={
            this.props.view_branch_global
              ? I18n.t('clients.success.updateFilial')
              : I18n.t('clients.success.update')
          }
          type="success"
          iconFilled
        />,
        'custom',
        5000,
        successMessage,
      );
      this.props.clearUiValue('updateStatus', 'success');
    }
    if (this.props.deleteStatus.success) {
      notify.show(
        <SnackBar
          icon={'priority'}
          title={I18n.t('common.excellent')}
          body={
            this.props.view_branch_global
              ? I18n.t('clients.success.deleteFilial')
              : I18n.t('clients.success.delete')
          }
          type="success"
          iconFilled
        />,
        'custom',
        5000,
        successMessage,
      );
      this.props.clearUiValue('deleteStatus', 'success');
    }
    return (
      <div className={'container-fluid client-detail-screen'}>
        {/*<ChangePasswordModal*/}
        {/*    show={this.state.showPasswordModal}*/}
        {/*    password={this.state.values.password}*/}
        {/*    repeatPassword={this.state.values.repeatPassword}*/}
        {/*    change={this.handleInputChange}*/}
        {/*    onHide={() => {*/}
        {/*        this.setState({...this.state, showPasswordModal: false})*/}
        {/*    }}*/}
        {/*    action={(password) => this.props.changePassword(password)}*/}
        {/*/>*/}
        <DeleteModal
          show={this.state.showDeleteClientModal}
          onHide={() => this.handleShowHideDeleteClient(false)}
          actionCancel={() => this.handleShowHideDeleteClient(false)}
          actionConfirm={() => {
            if (this.props.clients_screen === 'edit-branch') {
              this.props.deleteClient({
                id_branch: this.props.client.id,
              });
            } else if (this.props.clients_screen === 'edit-client') {
              this.props.deleteClient({
                id_account: this.props.client.id,
              });
            }
            if (
              this.props.clients_screen === 'detail' &&
              this.props.view_branch_global
            ) {
              this.props.deleteClient({
                id_branch: this.props.client.id,
              });
            }
            if (
              this.props.clients_screen === 'detail' &&
              !this.props.view_branch_global
            ) {
              this.props.deleteClient({
                id_account: this.props.client.id,
              });
            }
          }}
          message={
            this.props.view_branch_global
              ? I18n.t('clients.deleteBranch', {
                  branchName: this.props.client.name,
                })
              : I18n.t('clients.deleteAccount', {
                  accountName: this.props.client.name,
                })
          }
          title={I18n.t('common.attention')}
          textButtonConfirm={I18n.t('common.confirmDelete')}
        />
        <ModalAlert
          show={this.state.showModal}
          onHide={() => this.handleShowHide(false)}
          name="cleanLogo"
          textButtonConfirm="Eliminar"
          actionConfirm={() => {
            let state = this.state;
            delete state.propLogo;
            this.handleCleanLogo();
          }}
          actionCancel={() => {
            this.handleCancelCleanLogo();
          }}
        />

        <Tabs
          defaultActiveKey={
            this.props.user.user_admin && this.props.view_branch_global ? 2 : 1

            /* this.props.user.user_admin
              ? this.props.clients_screen === 'edit-client'
                ? 1
                : this.props.clients_screen === 'edit-branch'
                ? 2
                : this.props.defaultTab
              : this.props.clients_screen === 'edit-branch'
              ? 1
              : this.props.defaultTab */
          }
          id={
            this.props.clients_screen === 'new'
              ? 'client-new-screen'
              : 'uncontrolled-tab-example'
          }
          onSelect={this.handleSelect}
          value={this.state.selectedTab}
        >
          <Tab
            eventKey={1}
            title={
              this.props.user.user_admin
                ? I18n.t('clients.accountButton')
                : I18n.t('common.sidebar.filiales')
            }
          >
            <div className="client-detail">{this.displayForms(props)}</div>
          </Tab>
          <Tab
            eventKey={2}
            title={
              this.props.user.user_admin
                ? I18n.t('common.sidebar.filiales')
                : I18n.t('campaigns.credits.credits')
            }
          >
            <div className="client-detail">{this.displayForms(props)}</div>
          </Tab>
          {this.props.user.user_admin ? (
            <Tab eventKey={3} title={I18n.t('campaigns.credits.credits')}></Tab>
          ) : null}
        </Tabs>
      </div>
    );
  }

  displayForms(props) {
    return (
      <form onSubmit={this.handleSubmit}>
        {(this.props.clients_screen === 'edit-client' ||
          this.props.clients_screen === 'edit-branch') &&
          this.renderUpdateClientForm()}
        {this.props.clients_screen === 'new' && this.renderNewClientForm()}
        {this.props.clients_screen === 'detail' &&
          this.renderDetailClientForm()}
        <div
          className={`container-fluid ${
            this.props.clients_screen === 'detail'
              ? 'detail-client-footer'
              : this.props.clients_screen === 'new'
              ? 'new-client-footer'
              : this.props.clients_screen === 'edit-client' ||
                this.props.clients_screen === 'edit-branch'
              ? 'edit-client-footer'
              : 'form-footer'
          } `}
        >
          <div className="row">
            <div className="col-sm-12">
              {this.props.clients_screen === 'new' && (
                <Button
                  className="secondary"
                  text={
                    !this.props.user.user_admin ? (
                      <>
                        <span class="material-symbols-rounded">add</span>
                        <span>{I18n.t('clients.create_filial')}</span>
                      </>
                    ) : (
                      <>
                        <span class="material-symbols-rounded">add</span>
                        <span>{I18n.t('clients.create')}</span>
                      </>
                    )
                  }
                  disabled={
                    !this.formIsValid() || props.uploadAttachmentStatus.loading
                  }
                />
              )}
            </div>
          </div>

          <div>{this.renderLoaderSpinners()}</div>
        </div>
      </form>
    );
  }

  buttonTextImageUploader() {
    return (
      <>
        <span class="material-symbols-filled icon-left">photo_library</span>
        <span className={'button-text-image-uploader'}>
          {I18n.t('clients.logo_button_attach')}
        </span>
      </>
    );
  }
  renderImageUploadContainer() {
    return (
      <ImageUploader
        className="upload-button"
        buttonClassName={`button primary${this.state.logo ? ' disabled' : ''}`}
        withIcon={false}
        label=""
        withIconButton={true}
        iconButton="photo_library"
        buttonText={this.buttonTextImageUploader()}
        onChange={async (e) => {
          await this.medirImagen(e);
          this.handleInputLogo(e);
        }}
        imgExtension={['.jpg', '.png', '.jpeg']}
        // maxFileSize={2048000}
        withPreview={false}
        singleImage={true}
        fileTypeError={I18n.t('clients.invalid_logo')}
        fileSizeError={I18n.t('clients.invalid_logo')}
      />
    );
  }

  async medirImagen(imagen) {
    const image = new Image();
    const promise = new Promise((resolve, reject) => {
      image.onload = () => {
        const width = image.naturalWidth;
        const height = image.naturalHeight;
        this.setState({ ...this.state, logo_height: height });
        this.setState({ ...this.state, logo_width: width });

        resolve({ width, height });
      };
      const rejectImage = () => {
        if (!imagen[0]) {
          this.showError(
            I18n.t('clients.validate_logo.title'),
            I18n.t('clients.validate_logo.maximum_file_size_exceeded'),
          );
          this.setState({ ...this.state, invalidLogo: true });
        }
        return reject;
      };
      image.onerror = rejectImage();
    });
    image.src = await fileToBase64(imagen[0]);

    return promise;
  }

  renderUploadWithoutLogo() {
    return (
      <>
        <div className="">
          <div className="title-logo">{I18n.t('clients.logo_title')}</div>
          <div className="description-without-logo">
            <div className="icon-without-logo">
              <span class="material-symbols-rounded material-symbols-filled">
                report
              </span>
            </div>
            <div className="text-without-logo">
              <div className="logo-without-file">
                {I18n.t('clients.logo_without_file')}
              </div>
              <div className="logo-format">{I18n.t('clients.logo_format')}</div>
            </div>
          </div>
        </div>
      </>
    );
  }

  renderUploadButtonWithoutLogo() {
    return (
      <>
        <div className="upload-container">
          {this.renderImageUploadContainer()}
        </div>
      </>
    );
  }

  renderUploadWithLogo() {
    return (
      <>
        <div className="row">
          <div className="col-12 col-md-12 title-logo">
            {I18n.t('clients.logo_title')}
          </div>
          <div className="col-12 col-md-12 description-with-logo">
            <div className="icon-with-logo">
              <span class="material-symbols-rounded material-symbols-filled">
                photo_library
              </span>
            </div>
            {this.state.logo_base64 || this.state.logo_url ? (
              <div
                className="logo-preview"
                style={{
                  background: `url(${
                    !this.props.update
                      ? this.state.logo_base64
                      : this.state.logo_url
                      ? this.state.logo_url
                      : this.state.logo_base64
                  })`,
                }}
              >
                <img
                  src={
                    !this.props.update
                      ? this.state.logo_base64
                      : this.state.logo_url
                      ? this.state.logo_url
                      : this.state.logo_base64
                  }
                  alt=""
                  className={'logo-img'}
                />
              </div>
            ) : (
              <div className="logo-preview">
                <img
                  src={
                    !this.props.update
                      ? this.state.logo_base64
                      : this.state.logo_url
                      ? this.state.logo_url
                      : this.state.logo_base64
                  }
                  alt=""
                  className={'logo-img'}
                />
              </div>
            )}
            <div className="text-with-logo">
              <div className="logo-filename">
                {!this.props.update
                  ? this.state.file_name
                  : this.state.propLogo
                  ? this.state.propLogo
                  : this.state.file_name}
              </div>
              <div className="logo-detail">
                {this.state.logo
                  ? dayjs().format('DD/MM/YYYY') +
                    ' ' +
                    Math.round(this.state.logo.size / 1000) +
                    ' kb'
                  : ''}
              </div>
            </div>
            <div className="delete-logo">
              <button
                type="button"
                className="material-symbols-rounded"
                onClick={() => this.handleShowHide(true)}
              >
                <span class="material-symbols-rounded material-symbols-filled">
                  delete
                </span>
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }

  renderUploadButtonWithLogo() {
    return (
      <>
        <div className="upload-container">
          <Button
            className="button primary icon-left"
            iconButton={true}
            iconClass="photo_library"
            onClick={() => {
              this.handleInputLogo();
            }}
            disabled={true}
            text={I18n.t('clients.logo_button_attach')}
            iconFilled={true}
          />
        </div>
      </>
    );
  }

  renderUploadLogo() {
    return (
      <div className="logo-container">
        <div className="wrapper-text">
          {(!this.props.update && !this.state.logo) ||
          (this.props.update && !this.state.logo_url && !this.state.logo)
            ? this.renderUploadWithoutLogo()
            : this.renderUploadWithLogo()}
        </div>
        <div className="wrapper-file-upload">
          {(!this.props.update && !this.state.logo) ||
          (this.props.update && !this.state.logo_url && !this.state.logo)
            ? this.renderUploadButtonWithoutLogo()
            : this.renderUploadButtonWithLogo()}
        </div>
      </div>
    );
  }

  renderUpdateClientForm() {
    return (
      <div className="row detail-client col-md-12">
        <div className="col-lg-6 client-detail-card">
          <div className="card-header">
            <div className={'card-header-left back-parent'}>
              <div className={'back'}>
                <Button
                  className={'button-back'}
                  iconButton={true}
                  iconClass={'chevron_left'}
                  onClick={() => {
                    if (this.props.location.search !== (undefined || '')) {
                      this.props.getClientsScreen('');
                      this.props.cleanTable();
                      return this.props.history.push(
                        '/main/clients',
                        this.props.location.search,
                      );
                    }
                    // if (this.props.clients_screen === 'edit-client') {
                    //   this.props.backFromDetail(
                    //     I18n.t('clients.accountButton'),
                    //   );
                    // }
                    // if (this.props.clients_screen === 'edit-branch') {
                    //   this.props.backFromDetail(I18n.t('clients.filiar'));
                    // }
                    if (this.props.clients_screen === 'edit-client') {
                      this.props.backFromDetail(
                        I18n.t('clients.accountButton'),
                      );
                    }
                    if (this.props.clients_screen === 'edit-branch') {
                      this.props.updateViewBranch(true);
                      this.props.backFromDetail(
                        I18n.t('common.sidebar.filiales'),
                      );
                    }
                    this.props.cleanTable();
                    this.props.getClientsScreen('');
                    this.props.history.push('/main/clients');
                  }}
                />
                {this.props.update ? (
                  <p>{this.props.client.name}</p>
                ) : (
                  <p>
                    {!this.props.user.user_admin
                      ? I18n.t('clients.new_filial')
                      : I18n.t('clients.new')}
                  </p>
                )}
                {!this.props.update &&
                  (this.props.createStatus.error ||
                    this.props.createUserStatus.error) &&
                  this.state.submit && (
                    <div className="error" style={{ padding: '3px 0 0 20px' }}>
                      {I18n.t('clients.error.create')}
                    </div>
                  )}
                {this.state.passwordError && (
                  <div className="error" style={{ padding: '3px 0 0 20px' }}>
                    {I18n.t('session.resetPassword.invalidPassword')}
                  </div>
                )}
                {this.props.update &&
                  this.props.updateStatus.error &&
                  this.state.submit && (
                    <div className="error" style={{ padding: '3px 0 0 20px' }}>
                      {I18n.t('clients.error.update')}
                    </div>
                  )}
                {this.state.invalidLogo && (
                  <div className="error" style={{ padding: '3px 0 0 20px' }}>
                    {I18n.t('clients.invalid_logo')}
                  </div>
                )}
              </div>
              <TableMenuItem>
                <div className="client-detail-dropdown">
                  <Dropdown
                    value={{
                      inputValue: {
                        code: '1',
                        label: (
                          <span class="material-symbols-rounded">
                            more_vert
                          </span>
                        ),
                      },
                    }}
                    className="users-dropdown"
                    noBorder
                    // change={() => this.handleActions(props.value)}
                    options={[
                      {
                        code: 'delete',
                        label: (
                          <Button
                            text="common.delete"
                            translate
                            iconFilled={true}
                            iconButton={true}
                            iconClass="delete"
                            className="text-link-button delete-button user-screen"
                            onClick={async (e, handleOriginal) => {
                              e.preventDefault();
                              this.setState({
                                ...this.state,
                                showDeleteClientModal: true,
                              });
                              if (handleOriginal) handleOriginal();
                            }}
                          />
                        ),
                        disabled: false,
                      },
                    ]}
                  />
                </div>
              </TableMenuItem>
              {/* <div className="required-text">
                <p>* {I18n.t('clients.required-text')}</p>
              </div> */}
            </div>
          </div>
          <div className="update-main-body">
            <Input
              label={
                this.props.clients_screen === 'edit-client'
                  ? I18n.t('clients.company')
                  : I18n.t('clientsFilial.name')
              }
              value={this.state.values.company}
              type="string"
              name="company"
              change={this.handleInputChange}
              key="inputValue"
              autocomplete="off"
            />
            {this.props.user.user_admin &&
              this.props.clients_screen === 'edit-client' && (
                <>
                  <label className="label-user-type">
                    {I18n.t('clients.user_type')}
                  </label>
                  {/* <span className="required-icon">*</span> */}
                  <FormDropdown
                    className="client-type"
                    // disabled={true}
                    name="client_type"
                    placeholder={I18n.t('clients.type')}
                    value={this.state.values.client_type}
                    change={this.handleInputChange}
                    options={this.props.clientTypes.map((item) => ({
                      code: item.id,
                      description: item[`name_${this.props.language}`],
                    }))}
                  />
                  <SquareButton
                    label={I18n.t('campaigns.ie.title')}
                    name={'ie_active'}
                    value={this.state.values?.ie_active.inputValue}
                    change={this.handleInputChange}
                    setValue={!this.state.values.ie_active.inputValue}
                    checked={this.state.values.ie_active.inputValue}
                  />
                </>
              )}
            {this.renderUploadLogo()}
            {(this.props.clients_screen === 'edit-client' ||
              this.props.clients_screen === 'edit-branch') && (
              <div className="footer-main-body">
                <Button
                  className="secondary"
                  text={
                    this.props.client?.nwm_accountId
                      ? I18n.t('clients.update_filial')
                      : I18n.t('clients.update')
                  }
                  disabled={this.props.uploadAttachmentStatus.loading}
                  iconButton
                  iconClass={'save'}
                  iconFilled={true}
                />
              </div>
            )}
          </div>
        </div>
        <div
          className={`client-credit-card ${
            this.props.user.user_admin ? 'col-md-6' : 'col-md-4'
          }`}
        >
          <div className="card-header-right">
            <UpdateCredits {...this.props} />
          </div>
        </div>
        <div
          className={`${
            this.props.user.user_admin
              ? 'hidden'
              : 'col-md-2 client-credit-widget'
          }`}
        >
          <div className="credits-card">
            <div className="col total-widget">
              <CreditsWidgets
                material_symbol={'database'}
                title={I18n.t('credits.totalCredits')}
                label={I18n.t('credits.totalCreditsCredits')}
                value={
                  this.props.user.user_admin
                    ? 0
                    : this.props.user?.branch?.credits ||
                      this.props.user?.partner?.credits ||
                      this.props.user?.account?.credits
                }
                description={I18n.t('credits.totalCreditsDescription')}
                type={'row-view'}
                renderStyle=""
              />
            </div>
            <div className="col available-widget">
              <CreditsWidgets
                material_symbol={'priority'}
                title={I18n.t('credits.available')}
                label={I18n.t('credits.availableCredits')}
                value={
                  this.props.user.user_admin ? 0 : this.props.creditsAvailable
                }
                description={
                  this.props.user?.account !== null
                    ? I18n.t('credits.availableCreditsDescription')
                    : I18n.t('credits.availableCreditsDescriptionFilial')
                }
                type={'column-view'}
                renderStyle="available"
              />
            </div>
            <div className="col associated-widget">
              <CreditsWidgets
                material_symbol={
                  this.props.user?.account !== null
                    ? 'account_tree'
                    : 'campaign'
                }
                title={I18n.t('credits.associated')}
                label={I18n.t('credits.associatedCreditsDetail')}
                value={
                  this.props.user.user_admin
                    ? 0
                    : this.props.user?.account.credits_assigned ||
                      this.props.user?.partner.credits_assigned
                }
                description={
                  this.props.user?.account !== null
                    ? I18n.t('credits.associatedCreditsDescription')
                    : I18n.t('credits.associatedCreditsDescriptionFilial')
                }
                type={'column-view'}
                renderStyle="associated"
              />
            </div>
            {this.props.user ? (
              <div className="col assigned-widget">
                <CreditsWidgets
                  material_symbol={'groups'}
                  title={I18n.t('credits.assigned')}
                  label={I18n.t('credits.assignedCredits')}
                  value={
                    this.props.user.user_admin
                      ? 0
                      : (this.props.user?.account.credits_assigned ||
                          this.props.user?.partner.credits_assigned) -
                        this.props.credits_user_status?._sum?.credits_consumed
                  }
                  description={I18n.t('credits.assignedCreditsDescription')}
                  type={'column-view'}
                  renderStyle={'actives'}
                />
              </div>
            ) : null}

            <div className="col cosnumed-widget">
              <CreditsWidgets
                material_symbol={'sports_esports'}
                title={I18n.t('credits.consumed')}
                label={I18n.t('credits.creditsConsumed')}
                value={
                  this.props.user.user_admin
                    ? 0
                    : this.props.credits_user_status?._sum?.credits_consumed
                }
                description={I18n.t('credits.creditsConsumedDescription')}
                type={'column-view'}
                renderStyle={'consumed'}
              />
            </div>
          </div>
        </div>
        {/* <div className="col-lg-6 ">
          {this.props.user.currentUseradmin && (
            <Input
              label={I18n.t('clients.uncovered')}
              name="uncovered"
              type="number"
              value={this.state.values.uncovered}
              change={this.handleInputChange}
            />
          )}
          
        </div> */}
      </div>
    );
  }

  renderDetailClientForm() {
    return (
      <div className="row detail-client col-md-12">
        <div className="col-md-6 client-detail-card">
          <div className="card-header">
            <div className={'card-header-left back-parent'}>
              <div className={'back'}>
                <Button
                  className={'button-back'}
                  iconButton={true}
                  iconClass={'chevron_left'}
                  onClick={() => {
                    if (this.props.location.search !== (undefined || '')) {
                      this.props.getClientsScreen('');
                      return this.props.history.push(
                        '/main/clients',
                        this.props.location.search,
                      );
                    }
                    this.props.getClientsScreen('');
                    this.props.history.push('/main/clients');
                  }}
                />
                {this.props.update ? (
                  <p>{this.props.client.name}</p>
                ) : (
                  <p>
                    {!this.props.user.user_admin
                      ? I18n.t('clients.new_filial')
                      : I18n.t('clients.new')}
                  </p>
                )}
                {!this.props.update &&
                  (this.props.createStatus.error ||
                    this.props.createUserStatus.error) &&
                  this.state.submit && (
                    <div className="error" style={{ padding: '3px 0 0 20px' }}>
                      {I18n.t('clients.error.create')}
                    </div>
                  )}
                {this.state.passwordError && (
                  <div className="error" style={{ padding: '3px 0 0 20px' }}>
                    {I18n.t('session.resetPassword.invalidPassword')}
                  </div>
                )}
                {this.props.update &&
                  this.props.updateStatus.error &&
                  this.state.submit && (
                    <div className="error" style={{ padding: '3px 0 0 20px' }}>
                      {I18n.t('clients.error.update')}
                    </div>
                  )}
                {this.state.invalidLogo && (
                  <div className="error" style={{ padding: '3px 0 0 20px' }}>
                    {I18n.t('clients.invalid_logo')}
                  </div>
                )}
              </div>
              <TableMenuItem>
                <div className="client-detail-dropdown">
                  <Dropdown
                    value={{
                      inputValue: {
                        code: '1',
                        label: (
                          <span class="material-symbols-rounded">
                            more_vert
                          </span>
                        ),
                      },
                    }}
                    className="users-dropdown"
                    noBorder
                    // change={() => this.handleActions(props.value)}
                    options={[
                      {
                        code: 'edit',
                        label: (
                          <Button
                            text="common.edit"
                            translate
                            iconButton={true}
                            iconFilled={true}
                            iconClass="edit"
                            className="text-link-button normal-button"
                            onClick={(e, handleOriginal) => {
                              if (handleOriginal) {
                                handleOriginal();
                              }
                              if (
                                this.props.user.user_admin &&
                                !this.props.view_branch_global
                              ) {
                                this.props.getClientsScreen('edit-client');
                              } else {
                                this.props.getClientsScreen('edit-branch');
                              }

                              this.props.history.push(
                                '/main/client' +
                                  (this.state.newOrdering !== ''
                                    ? `?ordering=${this.state.newOrdering}`
                                    : ''),
                                +(this.state.newOrdering !== ''
                                  ? `?ordering=${this.state.newOrdering}`
                                  : ''),
                              );
                            }}
                          />
                        ),
                        disabled: false,
                      },
                      {
                        code: 'delete',
                        label: (
                          <Button
                            text="common.delete"
                            translate
                            iconButton={true}
                            iconFilled={true}
                            iconClass="delete"
                            className="text-link-button delete-button user-screen"
                            onClick={async (e, handleOriginal) => {
                              e.preventDefault();
                              this.setState({
                                ...this.state,
                                showDeleteClientModal: true,
                              });
                              if (handleOriginal) handleOriginal();
                            }}
                          />
                        ),
                        disabled: false,
                      },
                    ]}
                  />
                </div>
              </TableMenuItem>
            </div>
          </div>
          <div
            className={`logo-card ${
              (!this.state.logo_url || this.props.getLogoStatus.loading) &&
              'hidden'
            }`}
          >
            <div className="card">
              <img src={this.state.logo_url} alt="" />
            </div>
          </div>
          <div className="branch-name-date">
            <div className="branch-name">
              <p>{this.props.client.name}</p>
            </div>
            <div className="branch-date">
              <p>{dayjs(this.props.client.created).format('DD/MM/YY')}</p>
            </div>
          </div>
          <div className="branch-credit-widget">
            <CreditsWidgets
              material_symbol={'account_tree'}
              title={I18n.t('credits.associated')}
              label={I18n.t('credits.associatedCreditsDetail')}
              value={this.props.client?.credits}
              description={I18n.t('credits.branchHasCredit')}
              type={'row-view'}
              renderStyle="associated"
            />
            <CreditsWidgets
              material_symbol={'groups'}
              title={I18n.t('clients.active')}
              label={I18n.t('credits.branchCreditOwn')}
              value={this.props.client?.credits_active}
              description={I18n.t('credits.branchCreditActive')}
              type={'row-view'}
              renderStyle="actives"
            />
            <CreditsWidgets
              material_symbol={'sports_esports'}
              title={I18n.t('credits.consumed')}
              label={I18n.t('credits.branchCreditConsumed')}
              value={
                this.props.client?.credits_consumed
                  ? this.props.client.credits_consumed
                  : 0
              }
              description={I18n.t('credits.lowCaseCredits')}
              type={'row-view'}
              renderStyle="consumed"
            />
          </div>
        </div>
        <div
          className={`client-credit-card ${
            this.props.user.user_admin ? 'col-md-6' : 'col-md-4'
          }`}
        >
          <div className="card-header-right">
            <UpdateCredits {...this.props} />
            <div className="report-detail-container">
              <div className="report-detail">
                <span>{I18n.t('reports.report')}</span>
              </div>
              <div className="report-detail-types">
                <span className="report-type">
                  {`${I18n.t('reports.individual')}: `}
                </span>
                <span className="report">
                  {I18n.t(
                    `reports.${reportDictionary(
                      'individual',
                      this.props.client.individual_report_folder,
                    )}`,
                  )}
                </span>
              </div>
              <div className="report-detail-types">
                <span className="report-type">
                  {`${I18n.t('reports.company')}: `}
                </span>
                <span className="report">
                  {I18n.t(
                    `reports.${reportDictionary(
                      'individual',
                      this.props.client.company_report_folder,
                    )}`,
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`${
            this.props.user.user_admin
              ? 'hidden'
              : 'col-md-2 client-credit-widget'
          }`}
        >
          <div className="credits-card">
            <div className="col total-widget">
              <CreditsWidgets
                material_symbol={'database'}
                title={I18n.t('credits.totalCredits')}
                label={I18n.t('credits.totalCreditsCredits')}
                value={
                  this.props.user.user_admin
                    ? 0
                    : this.props.user?.branch?.credits ||
                      this.props.user?.partner?.credits ||
                      this.props.user?.account?.credits
                }
                description={I18n.t('credits.totalCreditsDescription')}
                type={'row-view'}
                renderStyle=""
              />
            </div>
            <div className="col available-widget">
              <CreditsWidgets
                material_symbol={'priority'}
                title={I18n.t('credits.available')}
                label={I18n.t('credits.availableCredits')}
                value={
                  this.props.user.user_admin ? 0 : this.props.creditsAvailable
                }
                description={
                  this.props.user?.account !== null
                    ? I18n.t('credits.availableCreditsDescription')
                    : I18n.t('credits.availableCreditsDescriptionFilial')
                }
                type={'column-view'}
                renderStyle="available"
              />
            </div>
            <div className="col associated-widget">
              <CreditsWidgets
                material_symbol={
                  this.props.user?.account !== null
                    ? 'account_tree'
                    : 'campaign'
                }
                title={I18n.t('credits.associated')}
                label={I18n.t('credits.associatedCreditsDetail')}
                value={
                  this.props.user.user_admin
                    ? 0
                    : this.props.user?.account?.credits_assigned ||
                      this.props.user?.partner?.credits_assigned
                }
                description={
                  this.props.user?.account !== null
                    ? I18n.t('credits.associatedCreditsDescription')
                    : I18n.t('credits.associatedCreditsDescriptionFilial')
                }
                type={'column-view'}
                renderStyle="associated"
              />
            </div>
            {this.props.user ? (
              <div className="col assigned-widget">
                <CreditsWidgets
                  material_symbol={'groups'}
                  title={I18n.t('credits.assigned')}
                  label={I18n.t('credits.assignedCredits')}
                  value={
                    this.props.user.user_admin
                      ? 0
                      : (this.props.user?.account.credits_assigned ||
                          this.props.user?.partner.credits_assigned) -
                        this.props.credits_user_status?._sum?.credits_consumed
                  }
                  description={I18n.t('credits.assignedCreditsDescription')}
                  type={'column-view'}
                  renderStyle={'actives'}
                />
              </div>
            ) : null}

            <div className="col cosnumed-widget">
              <CreditsWidgets
                material_symbol={'sports_esports'}
                title={I18n.t('credits.consumed')}
                label={I18n.t('credits.creditsConsumed')}
                value={
                  this.props.user.user_admin
                    ? 0
                    : this.props.credits_user_status?._sum?.credits_consumed
                }
                description={I18n.t('credits.creditsConsumedDescription')}
                type={'column-view'}
                renderStyle={'consumed'}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderNewClientForm() {
    const regExPassword =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return (
      <div className="row new-client-row">
        <div className="card-header">
          <div className={'card-header-left back-parent'}>
            <div className={'back'}>
              <Button
                className={'button-back'}
                iconButton={true}
                iconClass={'chevron_left'}
                onClick={() => {
                  if (this.props.location.search !== (undefined || '')) {
                    this.props.getClientsScreen('');
                    return this.props.history.push(
                      '/main/clients',
                      this.props.location.search,
                    );
                  }
                  this.props.getClientsScreen('');
                  this.props.history.push('/main/clients');
                }}
              />
              {this.props.update ? (
                <p>{this.props.client.name}</p>
              ) : (
                <p>
                  {!this.props.user.user_admin
                    ? I18n.t('clients.new_filial')
                    : I18n.t('clients.new')}
                </p>
              )}
              {(this.props.createStatus.error ||
                this.props.createUserStatus.error) &&
                this.state.submit &&
                this.showError(
                  I18n.t('clients.error.create'),
                  I18n.t('users.error.email'),
                )}
              {this.state.passwordError && (
                <div className="error" style={{ padding: '3px 0 0 20px' }}>
                  {I18n.t('session.resetPassword.invalidPassword')}
                </div>
              )}
              {this.props.update &&
                this.props.updateStatus.error &&
                this.state.submit && (
                  <div className="error" style={{ padding: '3px 0 0 20px' }}>
                    {I18n.t('clients.error.update')}
                  </div>
                )}
              {this.state.invalidLogo && (
                <div className="error" style={{ padding: '3px 0 0 20px' }}>
                  {I18n.t('clients.invalid_logo')}
                </div>
              )}
            </div>
            <div className="required-text">
              <p>* {I18n.t('clients.required-text')}</p>
            </div>
          </div>
          <div className="card-header-right">
            {this.props.user.user_admin && this.props.client.nwm_accountId ? (
              <></>
            ) : (
              this.props.update &&
              (this.props.user.user_admin ||
                !this.props.client.is_super_dashboard) && (
                <Button
                  className="primary"
                  text={I18n.t('clients.addCredits')}
                  onClick={() =>
                    this.props.history.push('/main/client/update-credits')
                  }
                />
              )
            )}
          </div>
        </div>
        <div className="new-client">
          <div className="col-lg-6 left-container">
            <div className="row">
              <div className="col-sm-6">
                <Input
                  label={I18n.t('clients.company')}
                  name="company"
                  type={'string'}
                  value={this.state.values.company}
                  change={this.handleInputChange}
                  placeholder={I18n.t('clients.placeholder.company')}
                  required={true}
                  autocomplete="off"
                />
              </div>
            </div>
            <div className="row">
              <div className="button-container col-lg-3">
                {/* <Dropdown
                label={I18n.t('clients.language')}
                name="language"
                value={this.state.values.language}
                change={this.handleInputChange}
                options={languagesCodes.map((code) => ({
                  code: code,
                  description: I18n.t('languages.' + code),
                }))}
              /> */}
                {this.props.user.user_admin && (
                  <>
                    <label className="label-user-type">
                      {I18n.t('clients.user_type')}
                    </label>
                    <span className="required-icon">*</span>
                    <FormDropdown
                      className="client-type"
                      name="client_type"
                      value={this.state.values.client_type}
                      change={this.handleInputChange}
                      options={this.props.clientTypes.map((item) => ({
                        code: item.id,
                        description: item[`name_${this.props.language}`],
                      }))}
                      placeholder={I18n.t('clients.type')}
                      required={true}
                    />
                  </>
                )}
              </div>
            </div>
            {this.renderUploadLogo()}
            <div className="left-bottom row">
              <div className="credits-input col-sm-6">
                <Input
                  label={I18n.t('clients.credits_acquired')}
                  name="credits_acquired"
                  type="number"
                  value={this.state.values.credits_acquired}
                  change={this.handleInputChange}
                  placeholder={I18n.t('clients.credits_acquired_placeholder')}
                  required={true}
                />
              </div>
              {this.props.user.user_admin && (
                <div className="admin-user col-sm-6">
                  <SquareButton
                    label="clients.isSuperDashboard"
                    name={'isSuperDashboard'}
                    value={this.state.values.isSuperDashboard}
                    change={this.handleInputChange}
                    setValue={!this.state.values.isSuperDashboard.inputValue}
                  />
                  <SquareButton
                    label={I18n.t('campaigns.ie.title')}
                    name={'ie_active'}
                    value={this.state.values.ie_active}
                    change={this.handleInputChange}
                    setValue={!this.state.values.ie_active.inputValue}
                  />
                </div>
              )}
            </div>
            <LanguageSelector
              label={I18n.t('users.idiom')}
              onSelect={this.handleInputChange}
              selected={this.state.values.language.inputValue || 'none'}
              required={true}
            />
          </div>
          <div className="col-lg-6 right-container">
            <div className="client-form-subtitle">
              <label className="input-label">
                {I18n.t('clients.generateAdminUser')}
              </label>
            </div>

            <div className="row">
              <div className="col-sm-6">
                <Input
                  label={I18n.t('clients.first_name')}
                  name="first_name"
                  type={'string'}
                  value={this.state.values.first_name}
                  change={this.handleInputChange}
                  placeholder={I18n.t('clients.placeholder.firstName')}
                  required={true}
                />
              </div>
              <div className="col-sm-6">
                <Input
                  label={I18n.t('clients.last_name')}
                  name="last_name"
                  type={'string'}
                  value={this.state.values.last_name}
                  change={this.handleInputChange}
                  placeholder={I18n.t('clients.placeholder.lastName')}
                  required={true}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <Input
                  label={I18n.t('clients.email')}
                  name="email"
                  value={this.state.values.email}
                  change={this.handleInputChange}
                  /* emailChecker={
                    this.state.values.email.inputValue.length &&
                    regex.emailRegex.test(this.state.values.email.inputValue)
                  } */
                  placeholder={I18n.t('clients.placeholder.email')}
                  required={true}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <Input
                  label={I18n.t('clients.password')}
                  name="password"
                  value={this.state.values.password}
                  change={this.handleInputChange}
                  type={'password'}
                  placeholder={I18n.t('clients.placeholder.password')}
                  required={true}
                />
                {
                  <p
                    className={`text-on-password ${
                      !this.state.values.password.validator(
                        this.state.values.password.inputValue,
                      )
                        ? 'error-password'
                        : ''
                    }`}
                  >
                    {I18n.t('users.tooltipPassword')}
                  </p>
                }
              </div>
              <div className="col-sm-6">
                <Input
                  label={I18n.t('clients.repeatPassword')}
                  name="repeatPassword"
                  value={this.state.values.repeatPassword}
                  change={this.handleInputChange}
                  type={'password'}
                  placeholder={I18n.t('clients.placeholder.repeatPassword')}
                  ignoreDirty
                  className="input-repeat"
                  required={true}
                />
              </div>
            </div>
            {/* <Dropdown
                          label={I18n.t("clients.permissions")}
                          name='group_ids'
                          value={this.state.values.group_ids}
                          change={this.handleInputChange}
                          multiSelect={true}
                          addOption={this.addOption}
                          options={this.props.client_groups.map((group) => ({code: group.id, description: group.name}))}
                              /> */}
          </div>
        </div>
      </div>
    );
  }

  // showTooltip = () => {
  //   return (
  //     <Tooltip
  //       translate={false}
  //       message={I18n.t('users.tooltipPassword')}
  //       tooltipClass="help"
  //     />
  //   );
  // };

  renderLoaderSpinners() {
    const { createStatus, updateStatus, uploadAttachmentStatus } = this.props;
    if (
      updateStatus.loading ||
      createStatus.loading ||
      uploadAttachmentStatus.loading
    )
      return <Loader />;
    return null;
  }
}

export default ClientDetailScreen;
