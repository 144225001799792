import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router';
import ReverseAuthRoute from './containers/ReverseAuthRoute';
import Notifications from 'react-notify-toast';
import NavbarScreen from './containers/Navbar';
import SideBarScreen from './containers/SideBar';
import Login from './session/containers/Login';
import Activate from './session/containers/Activate';
import Persons from './persons/containers/Persons';
import PersonDetail from './persons/containers/PersonDetail';
import CampaignDetail from './campaigns/containers/CampaignDetail';
import Users from './users/containers/Users';
import UserDetail from './users/containers/UserDetail';
import Jobs from './jobs/containers/Jobs';
import JobDetail from './jobs/containers/JobDetail';
import Clients from './clients/containers/Clients';
import ClientDetail from './clients/containers/ClientDetail';
import UpdateCredits from './clients/containers/UpdateCredits';
import NewCampaign from './campaigns/containers/NewCampaign';
import CampaignsTable from './campaigns/containers/CampaignsTable';
import DemographicFilter from './persons/containers/DemogaphicFilter';
import GroupTrends from './persons/containers/GroupTrends';
import Profile from './profile/containers/Profile';
import Dashboard from './dashboard/containers/Dashboard';
import AuthRoute from './containers/AuthRoute';
import RecoverPassword from './session/containers/RecoverPassword';
import ResetPassword from './session/containers/ResetPassword';
import CreditsHistory from './components/common/CreditsHistory/CreditsHistory';
import ExternalLogin from './session/containers/ExternalLogin';
import CampaignsMigrations from './campaignsMigrations/containers/CampaignsMigrations';
import PublicLayout from './components/layouts/PublicLayout/PublicLayout';
import DashboardLayout from './components/layouts/DashboardLayout/DashboardLayout';
import BaproLogin from './session/containers/BaproLogin';
import BaproScreen from './bapro/containers/BaproScreen';
import ReportsScreen from './reports/containers/ReportsScreen';
import ReportsDetailScreen from './reports/containers/ReportsDetailScreen';
// module imports

class Routes extends Component {
  handlePermissions = () => {
    let state = {
      canSeeCampaigns: false,
      canSeePersons: false,
      canSeeUsers: false,
      canSeeJobs: false,
      canSeeTags: false,
      canSeeClients: false,
      canSeeBapro: false,
      canSeeReports: false,
      canSeeCampaignsMigrations: false,
    };

    this.props?.user &&
      this.props.user.groups.forEach((group) => {
        switch (group) {
          case 1:
            state = {
              ...state,
              canSeeCampaigns: true,
              canSeePersons: true,
              canSeeJobs: true,
              canSeeTags: true,
              canSeeUsers: true,
              canSeeClients: true,
              canSeeCampaignsMigrations: true,
            };
            break;

          case 5:
            state = { ...state, canSeeCampaigns: true };
            break;

          case 6:
            state = { ...state, canSeePersons: true };
            break;

          case 7:
            state = { ...state, canSeeJobs: true };
            break;

          case 8:
            state = { ...state, canSeeTags: true };
            break;

          case 9:
            state = { ...state, canSeeUsers: true };
            break;

          case 10:
            state = { ...state, canSeeClients: true };
            break;
          case 15:
            state = { ...state, canSeeBapro: true };
            break;
          case 16:
            state = { ...state, canSeeReports: true };
          default:
            break;
        }
      });

    if (this.props.user && this.props.user.user_admin)
      state = { ...state, canSeeCampaignsMigrations: true };

    return state;
  };

  render() {
    const {
      canSeeCampaigns,
      canSeePersons,
      canSeeUsers,
      canSeeJobs,
      canSeeTags,
      canSeeClients,
      canSeeCampaignsMigrations,
      canSeeBapro,
      canSeeReports,
    } = this.handlePermissions();
    return (
      <>
        <Notifications />
        {this.props.user ? (
          <DashboardLayout>
            <Switch>
              <AuthRoute
                path="/main"
                component={({ match: { url } }) => [
                  <div
                    style={{
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                    }}
                  >
                    <div className="main-container">
                      <Switch>
                        <Route
                          exact
                          path={`${url}/home`}
                          component={Dashboard}
                        />
                        {canSeePersons && (
                          <Route
                            exact
                            path={`${url}/persons`}
                            component={Persons}
                          />
                        )}
                        {canSeePersons && (
                          <Route
                            exact
                            path={`${url}/persons/demographic-filter`}
                            component={DemographicFilter}
                          />
                        )}
                        {canSeePersons && (
                          <Route
                            exact
                            path={`${url}/persons/group-trends`}
                            component={GroupTrends}
                          />
                        )}
                        {canSeePersons && (
                          <Route
                            path={`${url}/new-person`}
                            component={PersonDetail}
                          />
                        )}
                        {canSeePersons && (
                          <Route
                            exact
                            path={`${url}/person/:id`}
                            component={() => <PersonDetail update={true} />}
                          />
                        )}
                        {canSeeCampaigns && (
                          <Route
                            path={`${url}/edit-campaign`}
                            component={() => <NewCampaign update={true} />}
                          />
                        )}
                        {canSeeCampaigns && (
                          <Route
                            path={`${url}/new-campaign`}
                            component={() => <NewCampaign />}
                          />
                        )}
                        {canSeeCampaigns && (
                          <Route
                            path={`${url}/campaigns`}
                            component={CampaignsTable}
                          />
                        )}
                        {canSeeCampaigns && (
                          <Route
                            path={`${url}/campaign`}
                            component={() => <CampaignDetail />}
                          />
                        )}
                        {canSeeClients && (
                          <Route
                            path={`${url}/client/update-credits`}
                            component={() => <UpdateCredits />}
                          />
                        )}
                        {(canSeeClients || canSeeCampaigns) && (
                          <Route
                            path={`${url}/credits-history`}
                            component={() => <CreditsHistory />}
                          />
                        )}
                        {canSeeUsers && (
                          <Route path={`${url}/users`} component={Users} />
                        )}
                        {canSeeUsers && (
                          <Route
                            path={`${url}/new-user`}
                            component={UserDetail}
                          />
                        )}
                        {canSeeUsers && (
                          <Route
                            path={`${url}/user`}
                            component={() => <UserDetail update={true} />}
                          />
                        )}
                        {canSeeJobs && (
                          <Route
                            path={`${url}/jobs`}
                            component={() => <Jobs defaultTab={2} />}
                          />
                        )}
                        {canSeeJobs && (
                          <Route
                            path={`${url}/new-job`}
                            component={() => <JobDetail update={false} />}
                          />
                        )}
                        {canSeeJobs && (
                          <Route
                            path={`${url}/job`}
                            component={() => <JobDetail update={true} />}
                          />
                        )}
                        {canSeeClients && (
                          <Route path={`${url}/clients`} component={Clients} />
                        )}
                        {canSeeClients && (
                          <Route
                            path={`${url}/new-client`}
                            component={ClientDetail}
                          />
                        )}
                        {canSeeClients && (
                          <Route
                            path={`${url}/client`}
                            component={() => <ClientDetail update={true} />}
                          />
                        )}
                        {canSeeTags && (
                          <Route
                            path={`${url}/tags`}
                            component={() => <Jobs defaultTab={1} />}
                          />
                        )}
                        {canSeeCampaignsMigrations && (
                          <Route
                            path={`${url}/campaignsMigrations`}
                            component={CampaignsMigrations}
                          />
                        )}
                        {canSeeBapro && (
                          <Route
                            path={`${url}/bapro`}
                            component={BaproScreen}
                          />
                        )}
                        {canSeeReports && (
                          <Route
                            path={`${url}/reports`}
                            component={ReportsScreen}
                          />
                        )}
                        {canSeeReports && (
                          <Route
                            exact
                            path={`${url}/edit-report`}
                            component={ReportsDetailScreen}
                          />
                        )}
                        {/* Rutas que no matchean van a la home */}
                        <Redirect to={`/main/home`} />
                      </Switch>
                    </div>
                  </div>,
                ]}
              />
              <Redirect to={`/main/home`} />
            </Switch>
          </DashboardLayout>
        ) : (
          <PublicLayout>
            <Switch>
              <Route path={`/activation`} component={Activate} />
              <Route path={`/recover-password`} component={RecoverPassword} />
              <Route
                path={`/password/reset/confirm/`}
                component={ResetPassword}
              />
              <ReverseAuthRoute
                exact
                path="/comfama"
                component={ExternalLogin}
              />
              <Route path={`/bapro`} component={BaproLogin} />
              <Route path={`/login`} component={Login} />

              {/* Rutas que no matchean van al login */}
              <Redirect to={`/login`} />
            </Switch>
          </PublicLayout>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.session.user,
  router: state.router,
});

export default connect(mapStateToProps)(Routes);
