import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Translate, I18n } from 'react-redux-i18n';
import '../Input/Input.css';
import './InputAutocomplete.css';

class InputAutocomplete extends Component {
  constructor(props) {
    super(props);

    this.state = {
      borderError: false,
      showError: false,
      options: [],
      mounting: true,
      selected: false,
    };
  }
  static defaultProps;

  componentDidUpdate(prevProps) {
    if (
      this.props.options !== this.state.options &&
      !this.state.mounting &&
      !this.state.selected
    ) {
      this.setState({ ...this.state, options: this.props.options });
    }
  }

  handleBlur = () => {
    setTimeout(() => {
      if (!this.props.value.inputValue.code && this.props.value.required)
        this.setState({ options: [], borderError: true });
    }, 300);
  };

  handleChange = (event) => {
    if (this.state.borderError) this.setState({ borderError: false });
    this.setState({
      selected: false,
      mounting: false,
      showError:
        this.props.value.validator &&
        !this.props.value.validator(event.target.value),
    });
    this.props.change(
      this.props.name,
      { description: event.target.value, code: '' },
      event.target.value,
    );
  };

  handleSelectOption = (option) => {
    this.setState({ options: [], borderError: false, selected: true }, () => {
      this.props.change(
        this.props.name,
        {
          code: option[this.props.code],
          description: option[this.props.description],
        },
        true,
      );
    });
  };

  render() {
    return (
      <div className={'input-autocomplete'}>
        <div className="input-label-container">
          {this.props.label && (
            <label
              className={
                'input-label ' + (this.props.value.required ? 'required' : null)
              }
            >
              <Translate value={this.props.label} />
            </label>
          )}
          {!this.props.value.required && !this.props.hideOptional && (
            <label className="optional-text">
              <Translate value="common.optional" />
            </label>
          )}
        </div>
        {this.props.textarea === true ? (
          <textarea
            rows={'3'}
            disabled={this.props.disabled}
            placeholder={I18n.t(this.props.placeholder)}
            value={this.props.value.inputValue.description}
            onChange={(event) =>
              this.props.change(this.props.name, event.target.value)
            }
          />
        ) : (
          <input
            id={this.props.name}
            className={
              this.state.borderError || this.state.showError
                ? 'border-error'
                : null
            }
            onBlur={this.handleBlur}
            disabled={this.props.disabled}
            placeholder={I18n.t(this.props.placeholder)}
            type={this.props.type || 'text'}
            value={
              this.props.value.inputValue &&
              this.props.value.inputValue.description
            }
            onChange={this.handleChange}
            autocomplete="off"
          />
        )}
        {this.state.options &&
          this.state.options.length > 0 &&
          this.props.value.inputValue.description && (
            <ul className="auto-options">
              {this.state.options.map((option, i) => (
                <li key={i} onClick={() => this.handleSelectOption(option)}>
                  {option[this.props.description]}
                </li>
              ))}
            </ul>
          )}
        {this.state.showError && <div>Error!</div>}
      </div>
    );
  }
}

InputAutocomplete.defaultProps = {
  label: '',
  placeholder: '',
};
InputAutocomplete.propTypes = {
  value: PropTypes.object || PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  change: PropTypes.func,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  hideOptional: PropTypes.bool,
  disabled: PropTypes.bool,
  getOptions: PropTypes.func,
};

export default InputAutocomplete;
