import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import './SquareButton.sass';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
class SquareButton extends Component {
  static defaultProps;

  handleClick = (e) => {
    e.stopPropagation();
    !this.props.disabled &&
      this.props.change(this.props.name, this.props.setValue, this.props.data);
  };

  render() {
    return (
      <>
        <div
          id={`${this.props.id}`}
          className={`square-button ${this.props.class} ${
            this.props.disabled ? 'disabled-button' : ''
          }`}
        >
          <input
            type="checkbox"
            id={this.props.name}
            // checked={this.optionIsSelected('all')}
            onClick={this.handleClick}
            disabled={this.props.disabled}
            checked={
              this.props.checked
                ? this.props.checked
                : this.props.value?.inputValue
            }
          />
          {this.props.label ? (
            this.props.href ? (
              <a
                className={
                  (this.props.value.required && !this.props.hideRequired
                    ? 'required '
                    : '') +
                  (this.props.isDownloadLink ? 'is-download-link ' : '')
                }
                href={this.props.href}
                target="_blank"
                rel="noopener noreferrer"
              >
                {I18n.t(this.props.label)}
              </a>
            ) : (
              <label
                className={
                  (this.props.value?.required && !this.props.hideRequired
                    ? 'required '
                    : '') +
                  (this.props.isDownloadLink ? 'is-download-link ' : '')
                }
                htmlFor={this.props.name}
              >
                {I18n.t(this.props.label)}
              </label>
            )
          ) : null}
          {this.props.description ? (
            <OverlayTrigger
              key={'right'}
              placement={'right'}
              // delayHide={1000000000}
              show={true}
              overlay={
                <Tooltip id={`description-tooltip`}>
                  {this.props.description}
                </Tooltip>
              }
            >
              {this.props.labelBody}
            </OverlayTrigger>
          ) : (
            this.props.labelBody
          )}
        </div>
      </>
    );
  }
}

SquareButton.defaultProps = {};
SquareButton.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.bool,
  change: PropTypes.func,
  setValue: PropTypes.any,
  required: PropTypes.bool,
  isDownloadLink: PropTypes.bool,
  hideRequired: PropTypes.bool,
};

export default SquareButton;
