import React, { Component } from 'react';
import './PositionCard.css';
import { I18n } from 'react-redux-i18n';
import Button from '../Button';
import { Link } from 'react-router-dom';

export class PositionCard extends Component {
  state = {
    open: true,
  };

  componentDidMount() {
    if (this.props.isCollapsable) {
      this.setState({ ...this.state, open: false });
    }
  }
  render() {
    const { name, behave, traits, tags } = this.props;
    let traitName = (id) => {
      switch (id) {
        case 1:
          return I18n.t('jobs.traits.assertiveness');
        case 2:
          return I18n.t('jobs.traits.sociability');
        case 3:
          return I18n.t('jobs.traits.patience');
        case 4:
          return I18n.t('jobs.traits.ruleness');
        default:
          break;
      }
    };

    return (
      <div className="position-card-container">
        <div
          className={`PositionCard ${this.state.open ? 'open' : 'closed'} ${
            this.props.isCollapsable && 'cursor-pointer'
          }`}
          onClick={() => {
            if (this.props.isCollapsable) {
              this.setState({ ...this.state, open: !this.state.open });
            }
          }}
        >
          <div className="header-container">
            <p>{name}</p>
            {this.props.isCollapsable && (
              <Link
                className="primary button"
                iconClass="add"
                to={'/main/new-job?idPosition=' + this.props.id}
              >
                <span className="material-symbols-rounded icon-left">add</span>
                {I18n.t('profiles.create-position')}
              </Link>
            )}
          </div>
          <div className="section">
            <div className="behave">
              <div className="profile-description-container">
                {this.state.open
                  ? behave
                  : behave.slice(0, 250).trimEnd() + '...'}
              </div>
              {tags && (
                <ul
                  className={`tags-container list-inline ${
                    this.state.open ? 'collapse-open' : ''
                  }`}
                >
                  {tags.map((tag, index) => (
                    <li className={index < 5 ? 'primary-tag' : 'secondary-tag'}>
                      {tag.tag[`name_${this.props.language}`]}
                      <span>
                        {tag.tag[`description_${this.props.language}`]}
                      </span>
                    </li>
                  ))}
                  <li>+{tags.length - 5}</li>
                </ul>
              )}
            </div>

            {this.state.open && (
              <div className="traits">
                <ul className={'traits-list'}>
                  {traits.map((trait) => {
                    const { value } = trait.trait_value;
                    const { id } = trait.trait_value.trait;
                    return (
                      <li className="item-list" key={trait.id}>
                        <p className="trait-name">{traitName(id)}</p>
                        <div className="compatibility-progress">
                          <div className="progress">
                            <div
                              className="progress-bar"
                              role="progressbar"
                              style={{ width: value + '%' }}
                              aria-valuenow={value}
                              aria-valuemin="0"
                              aria-valuemax="100"
                            >
                              {value > 21 && (
                                <span className={' percentage-indicator'}>
                                  {Math.round(value * 100) / 100 + '%'}
                                </span>
                              )}
                            </div>
                            {value < 21 && (
                              <div className=" percentage-indicator low-percent">
                                {Math.round(value * 100) / 100 + '%'}
                              </div>
                            )}
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}

            {this.props.isCollapsable && (
              <span class="material-symbols-rounded expand">
                {this.props.isCollapsable ? 'expand_more' : 'expand_less'}
              </span>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default PositionCard;
