import { connect } from 'react-redux';
import NavbarScreen from '../components/screens/NavbarScreen/NavbarScreen';
import actions from '../actions';
import { withRouter } from 'react-router';

const mapDispatchToProps = (dispatch) => ({
  logout: () => {
    dispatch(actions.session.logout());
  },
  showHelp: () => dispatch(actions.session.toggleHelp(true)),
  showOnBoarding: (boolean) =>
    dispatch(actions.dashboard.showOnBoarding(boolean)),
});

const mapStateToProps = (state, ownProps) => ({
  user: state.session.user,
  onBoardingState: state.dashboard.onBoardingState,
  language: state.i18n.locale,
  from: state.router.location.state?.from,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(NavbarScreen));
