import { get, post, webApi } from '../utils/http';
import queryString from 'query-string';

export const services = {
  getTags: () =>
    get(webApi + `api/profiles/tags?lang=${localStorage.getItem('language')}`),
  getAllProfiles: () =>
    get(
      webApi +
        `api/profiles?page_size=200&lang=${localStorage.getItem('language')}`,
    ),
  getProfiles: (tags) =>
    get(
      webApi +
        `api/profiles?lang=${localStorage.getItem('language')}&tag_ids=${tags}`,
    ),
  getProfileSkills: () =>
    get(
      webApi +
        `api/skills?page_size=1000&lang=${localStorage.getItem('language')}`,
    ),
  getProfileSkillsRange: (body) => {
    let id_results = body.results.map((item) =>
      item.id_result ? item.id_result : item,
    );
    let id_skills = body.skills;
    let excel = body.excel;
    let queryObject = { id_results, id_skills, excel };
    return get(
      webApi +
        `api/results/reports/skills?lang=${localStorage.getItem(
          'language',
        )}&${queryString.stringify(queryObject)}`,
    );
  },
};
