import { connect } from 'react-redux';
import actions from '../../actions/index';
import { withRouter } from 'react-router';
import DemographicFilter from '../components/DemographicFilter/DemographicFilter';

const mapDispatchToProps = (dispatch) => ({
  demographicFilter: (latlng) =>
    dispatch(actions.persons.demographicFilter(latlng)),
});

const mapStateToProps = (state, ownProps) => ({
  selection: state.persons.selection,
  results: state.persons.demo_filter_results,
  demoFilterStatus: state.persons.demoFilterStatus,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(DemographicFilter));
