import { connect } from 'react-redux';
import actions from '../../actions/index';
import CampaignsMigrationsScreen from '../components/CampaignsMigrationsScreen/CampaignsMigrationsScreen';
import { withRouter } from 'react-router';

const mapDispatchToProps = (dispatch) => ({
  getPerson: (id) => dispatch(actions.campaignsMigrations.getPerson(id)),
  getPersons: () => dispatch(actions.campaignsMigrations.getPersons()),
  selectPerson: (id) => dispatch(actions.campaignsMigrations.selectPerson(id)),
  updatePage: (page) => dispatch(actions.campaignsMigrations.updatePage(page)),
  updatePageSize: (pageSize) =>
    dispatch(actions.campaignsMigrations.updatePageSize(pageSize)),
  updateFilterValues: (filter) =>
    dispatch(actions.campaignsMigrations.updateFilterValues(filter)),
  filterPersons: (filter, resetPage) =>
    dispatch(actions.campaignsMigrations.filterPersons(filter, resetPage)),
  clearUiValue: (type, condition) =>
    dispatch(actions.campaignsMigrations.clearUiValue(type, condition)),
  getAllProfiles: () => dispatch(actions.persons.getAllProfiles()),
  getAllPartners: () => dispatch(actions.campaignsMigrations.getAllPartners()),
  demographicFilter: (latlng) =>
    dispatch(actions.campaignsMigrations.demographicFilter(latlng)),
  addRemovePersonToSelection: (result) =>
    dispatch(actions.campaignsMigrations.addRemovePersonToSelection(result)),
  toggleSelectionAllMigrations: () =>
    dispatch(actions.campaignsMigrations.toggleSelectionAllMigrations()),
  clearSelection: () => dispatch(actions.campaignsMigrations.clearSelection()),
  clearPersonCustomFilters: () =>
    dispatch(actions.campaignsMigrations.clearPersonCustomFilters()),
  sendPersonReportEmail: (values) =>
    dispatch(actions.campaignsMigrations.sendPersonReportEmail(values)),
  saveSearchInput: (value) =>
    dispatch(actions.campaignsMigrations.saveSearchInput(value)),
  clearFilter: () => dispatch(actions.campaignsMigrations.clearFilter()),
  getAllCampaigns: (partners) =>
    dispatch(actions.campaigns.getAllCampaigns(partners)),
  migrateResults: (body) =>
    dispatch(actions.campaignsMigrations.migrateResults(body)),
  selectAll: (flag) => dispatch(actions.campaignsMigrations.selectAll(flag)),
  getAllClients: (account_dropdown, view_branch, view_account) =>
    dispatch(
      actions.clients.getAllClients(
        account_dropdown,
        view_branch,
        view_account,
      ),
    ),
});

const mapStateToProps = (state, ownProps) => ({
  totalCount: state.campaignsMigrations.count,
  currentPage: state.campaignsMigrations.current,
  totalPages: state.campaignsMigrations.total_pages,
  pageSize: state.campaignsMigrations.page_size,
  allSelected: state.campaignsMigrations.allSelected,
  allLoaded: state.campaignsMigrations.allLoaded,
  allResultsSelected: state.campaignsMigrations.allResultsSelected,
  results: state.campaignsMigrations.results,
  start_date: state.dashboard.start_date,
  end_date: state.dashboard.end_date,

  desc: state.campaignsMigrations.desc,
  createStatus: state.campaignsMigrations.create,
  updateStatus: state.campaignsMigrations.update,
  updateUserStatus: state.campaignsMigrations.userUpdate,
  deleteStatus: state.campaignsMigrations.delete,
  dateStatus: state.dashboard.dateStatus,
  reportsStatus: state.campaignsMigrations.reportsStatus,

  persons: state.campaignsMigrations.results,
  profiles: state.persons.profiles,
  partners: state.clients.allClients,
  countries: state.campaignsMigrations.countries,
  cities: state.campaignsMigrations.cities,
  retrieveStatus: state.campaignsMigrations.retrieve,
  sendPersonReportEmailStatus:
    state.campaignsMigrations.sendPersonReportEmailStatus,
  filters: state.campaignsMigrations.filters,
  customFilter: state.campaignsMigrations.customFilter,
  filterApplied: state.campaignsMigrations.filterApplied,
  retrieveFilter: state.campaignsMigrations.retrieveFilter,
  historicalStartDate: state.campaignsMigrations.historicalStartDate,

  selection: state.campaignsMigrations.selection,

  savedSearchInput: state.campaignsMigrations.savedSearchInput,
  getAllClientsStatus: state.clients.getAllClientsStatus,
  allCampaigns: state.campaigns.allCampaigns,
  allCampaignsStatus: state.campaigns.getAllCampaignsStatus,
  user: state.session.user,
  language: state.i18n.locale,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(CampaignsMigrationsScreen));
