import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import actions from '../actions';
import SideBarScreen from '../components/screens/SideBarScreen/SideBarScreen';

const mapStateToProps = (state) => ({
  user: state.session.user,
  reportsStatus: state.persons.reportsStatus,
  actualLocation: state.common.actualLocation,
});

const mapDispatchToProps = (dispatch) => ({
  getActualLocation: (location) => {
    dispatch(actions.common.getActualLocation(location));
  },
  updateViewBranch: (bool) => {
    dispatch(actions.clients.updateViewBranch(bool));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SideBarScreen),
);
