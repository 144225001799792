import React, { Component } from 'react';
import './IeContainerResult.css';
import EmotionalIntelligenceResults from '../EmotionalIntelligenceResults/EmotionalIntelligenceResults';

class IeContainerResult extends Component {
  static defaultProps;
  state = {
    result: undefined,
    playerName: '',
  };

  render() {
    const { result, playerName } = this.props;

    return (
      <div className="ie-results-container">
        {result?.map((val, idx) => {
          return (
            <EmotionalIntelligenceResults
              value={val.value}
              name={playerName}
              axis={val.axis}
              id={idx}
              key={idx}
            />
          );
        })}
      </div>
    );
  }
}
IeContainerResult.defaultProps = {};

export default IeContainerResult;
