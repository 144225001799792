import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './ShareModal.css';
import { Modal } from 'react-bootstrap';
import { I18n } from 'react-redux-i18n';
import exit from '../../../assets/icons/cross-DARK-32x32.png';
import Button from '../Button';
import QRCode from 'qrcode.react';

class ShareModal extends Component {
  static defaultProps;

  splitCode(code) {
    let splitCode = code.toUpperCase();

    return (splitCode =
      splitCode.length === 12
        ? `${splitCode.substring(0, 4)}-${splitCode.substring(
            4,
            8,
          )}-${splitCode.substring(8, 12)}`
        : splitCode.length === 9
        ? `${splitCode.substring(0, 3)}-${splitCode.substring(
            3,
            6,
          )}-${splitCode.substring(6, 9)}`
        : '-');
  }
  render() {
    return (
      <Modal
        show={this.props.show}
        dialogClassName="payment"
        onHide={this.props.onHide}
        width={{}}
      >
        <Modal.Header className={'modal-header'}>
          <h3 className="modal-title">
            {I18n.t('campaigns.directShareTitle')}
          </h3>
          <img src={exit} height={'30px'} onClick={this.props.onHide} alt="" />
        </Modal.Header>
        <Modal.Body>
          {this.props.campaign.campaign_type === 'DIRECT' ? (
            <p>{I18n.t('campaigns.directShareMessage')}</p>
          ) : (
            this.props.campaign.code && (
              <div className="campaign-code-info">
                <div className="campaign-name">{this.props.campaign.name}</div>
                <div className="campaign-code">
                  {this.splitCode(this.props.campaign.code)}
                </div>
                <div className="qr">
                  <QRCode value={this.props.campaign.code} />
                </div>
              </div>
            )
          )}
        </Modal.Body>
        {this.props.campaign.campaign_type === 'DIRECT' && (
          <Modal.Footer>
            <Button
              className="primary"
              text={I18n.t('buttons.confirm')}
              onClick={() => {
                this.props.action(this.props.campaign.id);
                this.props.onHide();
              }}
            />
          </Modal.Footer>
        )}
      </Modal>
    );
  }
}

ShareModal.defaultProps = {};
ShareModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
};

export default ShareModal;
