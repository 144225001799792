import React, { Component } from 'react';
import './PersonsScreen.css';
import 'react-table/react-table.css';
import ReactTable from 'react-table';
import { getAge, parseDate } from '../../../utils/parser';
import { I18n } from 'react-redux-i18n';
import Button from '../../../components/common/Button';
import Input from '../../../components/common/Input';
import FilterModal from '../FilterModal';
import SquareButton from '../../../components/common/SquareButton';
import { notify } from 'react-notify-toast';
import Dropdown from '../../../components/common/Dropdown/Dropdown';
import Loader from '../../../components/common/Loader';
import TablePaginationDrawer from '../../../components/common/TablePaginationDrawer/TablePaginationDrawer';
import EmailReportConfirmModal from '../EmailReportConfirmModal/EmailReportConfirmModal';
import { success as successUI, error as errorUI } from '../../../utils/modals';
import TableLoader from '../../../components/common/TableLoader/TableLoader';
import { Tabs, Tab } from 'react-bootstrap';
import {
  SnackBar,
  successMessage,
  errorMessage,
} from '../../../utils/snackbar';
import ReactFileReader from 'react-file-reader';
import DateRangePickerFilter from '../../../components/common/DateRangePickerFilter/DateRangePickerFilter';
import FormDropdown from '../../../components/common/Dropdown/FormDropdown';
import DownloadReportModal from '../../../components/common/DownloadReportModal/DownloadReportModal';
import SearchInput from '../../../components/common/SearchInput/SearchInput';

import dayjs from 'dayjs';
import ModalShare from '../ModalShare';
import TableMenuItem from '../../../components/common/TableMenuItem/TableMenuItem';
import LargeTextTooltip from '../../../components/common/LargeTextTooltip';

import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Tooltip } from 'react-tooltip';

dayjs.extend(customParseFormat);

/**
 * Note 1: componentDidUpdate state.fetch logic changed
 * 		because now the input can only be accessed while
 * 		request status is 'success'.
 */

const initialValues = {
  profiles: { inputValue: [] },
  genders: { inputValue: [] },
  partners: { inputValue: [] },
  age: { inputValue: '' },
  emotionalIntelligence: { inputValue: [] },
  campaigns: { inputValue: [] },
  code: { inputValue: '' },
  search: { inputValue: '' },
};

class PersonsScreen extends Component {
  state = {
    showFilter: false,
    values: {
      ...initialValues,
      search: this.props.savedSearchInput || { inputValue: '' },
      //savedCustomFIlter: this.props.customFilter,
    },
    showEmailReportConfirm: false,
    downloadReportModalShow: false,
    showShareModal: false,
    pendingProfile: { inputValue: false },
    inconsistentProfile: { inputValue: false },
    emotionalIntelligence: { inputValue: null },
    lastConsistentResults: false,
    invitation_selected: null,
    range: {
      start: this.props.customFilter?.start_range || this.props.start_date,
      end: this.props.customFilter?.end_range || this.props.end_date,
    },
    filterApplied: false,
    didFilter: false,
    clearInput: false,
  };

  componentDidMount() {
    if (this.props.savedSearchInput) {
      this.props.saveSearchInput(false);
    }
    this.props.clearSelection();
    this.props.clearPersonCustomFilters();

    this.handleSubmit(false, true);

    this.props.getAllProfiles({});
    this.props.getCountries();
    if (!this.props.user.branch?.id)
      this.props.getAllClients(true, true, false);
    this.props.getAllCampaigns({
      partners: null,
      campaign_dropdown: true,
    });

    this.setState({
      ...this.state,
      filterApplied: false,
      range: {
        start:
          this.props.customFilter?.start_range ||
          this.state.start_date ||
          this.props.start_date,
        end:
          this.props.customFilter?.end_range ||
          this.state.end_date ||
          this.props.end_date,
      },
      clearInput: false,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.start_date !== this.props.start_date) {
      this.filterByDates({
        start_range: this.props.start_date,
        end_range: this.props.end_date,
      });
    }

    if (prevState.clearInput === true) {
      this.setState({
        ...this.state,
        clearInput: false,
      });
    }

    if (prevProps.selection.length && this.props.selection.length === 0) {
      this.props.clearSelection();
    }
  }

  addOption = (type, name, option) => {
    let newArray = [...this.state.values[name].inputValue];
    let pendValue = this.state.pendingProfile.inputValue;
    let incValue = this.state.inconsistentProfile.inputValue;
    if (type === 'add' || type === true) {
      newArray = newArray.concat([option]);
    } else {
      newArray = newArray.filter((opt) => opt.code !== option.code);
    }
    if (option.description === 'Pendiente') {
      pendValue = type;
    }
    if (option.description === 'Inconsistente') {
      incValue = type;
    }
    if (name === 'partners') {
      this.props.getAllCampaigns(newArray.map(({ code }) => code));
    }
    newArray.sort((profile1, profile2) => {
      return profile2.code - profile1.code;
    });
    this.setState(
      {
        ...this.state,
        pendingProfile: { inputValue: pendValue },
        inconsistentProfile: { inputValue: incValue },
        values: {
          ...this.state.values,
          [name]: { ...this.state.values[name], inputValue: [...newArray] },
        },
        didFilter: true,
      },
      () => {
        this.handleSubmit();
      },
    );
  };

  addOptionIE = (type, name, option) => {
    let ieValue = type;
    let filtered = option?.length > 0 ? true : false;
    this.setState(
      {
        ...this.state,
        emotionalIntelligence: { inputValue: ieValue },
        values: {
          ...this.state.values,
          [name]: { ...this.state.values[name], inputValue: ieValue?.code },
        },
        filterApplied: filtered,
      },
      () => {
        this.handleSubmit();
      },
    );
  };

  onHide = () => {
    this.setState({
      ...this.state,
      showFilter: false,
      showEmailReportConfirm: false,
      showShareModal: false,
    });

    if (!this.state.filterApplied) {
      this.setState({
        ...this.state,
        showFilter: false,
        showEmailReportConfirm: false,
        showShareModal: false,
        //values: { ...this.state.values, ...initialValues },
      });
    }
  };

  modalShow = () => {
    if (this.state.downloadReportModalShow === false) {
      this.setState({
        ...this.state,
        showEmailReportConfirm: false,
        downloadReportModalShow: true,
      });
    } else {
      this.setState({
        ...this.state,
        downloadReportModalShow: false,
      });
    }
  };

  handleOnclick = (e) => {
    e.preventDefault();
    this.sendIeInvitation();
    this.ieResponse();
  };

  sendIeInvitation = () => {
    let persons = [];
    this.props.selection.length &&
      this.props.selection.map((person) => {
        persons.push({
          id_invitation: person.id_invitation,
          id_campaign: person.campaign.id,
          email: person.email,
        });
      });
    this.props.massiveIeInvitation(persons);
  };

  ieResponse = () => {
    const { error, success, loading } = this.props.massiveIeResponse;
    if (success) {
      notify.show(
        <SnackBar
          icon={'priority'}
          title={I18n.t('common.excellent')}
          body={I18n.t('campaigns.ie.massiveIe')}
          iconFilled
          type="success"
        />,
        'custom',
        5000,
        successMessage,
      );
    }
    this.props.clearSelection();
  };
  resetSelection = () => {
    this.setState({
      ...this.state,
      values: { ...this.state.values, ...initialValues },
      didFilter: false,
    });
  };

  downloadReport = (name, option) => {
    const { allSelected, selection, allResultsSelected } = this.props;
    let reportFolder;

    if (!this.props.user.user_admin) {
      const { branch, account } = this.props.user;
      reportFolder =
        account?.length > 0
          ? {
              individual_report_folder: account?.individual_report_folder,
              company_report_folder: account?.company_report_folder,
            }
          : {
              individual_report_folder: branch?.individual_report_folder,
              company_report_folder: branch?.company_report_folder,
            };
    } else {
      reportFolder = null;
    }

    this.props.downloadProfileReport(
      allSelected ? allResultsSelected : selection,
      option.code,
      reportFolder,
    );
  };

  handleInputChange = (key, value) => {
    this.setState(
      {
        values: {
          ...this.state.values,
          [key]: { ...this.state.values[key], inputValue: value },
        },
        didFilter: true,
      },
      () => {
        this.handleSubmit();
      },
    );
  };

  handleFilterRemove() {
    this.props.clearFilter();
    this.props.saveSearchInput('');

    this.props.filterPersons(
      {
        start_range: this.props.start_date,
        end_range: this.props.end_date,
        ordering: this.props.customFilter.ordering,
        search: '',
      },
      true,
    );
    this.setState({
      ...this.state,
      pendingProfile: { inputValue: false },
      inconsistentProfile: { inputValue: false },
      emotionalIntelligence: { inputValue: null },
      filterApplied: false,
      range: {
        start: this.props.start_date,
        end: this.props.end_date,
      },
      values: { ...this.state.values, ...initialValues },
      didFilter: false,
      clearInput: true,
    });
  }

  handleSubmit = (
    lastConsistentResults = this.state.lastConsistentResults,
    resetPage = true,
  ) => {
    let submitForm = {
      ordering: this.props.customFilter.ordering,
      search: this.props.customFilter.search,
    };

    Object.keys(this.state.values).forEach((value) => {
      if (
        this.state.values[value].inputValue !== '' &&
        this.state.values[value].inputValue?.length !== 0
      ) {
        submitForm = {
          ...submitForm,
          [value]: Array.isArray(this.state.values[value].inputValue)
            ? this.state.values[value].inputValue.map((res) => res.code)
            : this.state.values[value].inputValue?.code
            ? this.state.values[value].inputValue.code
            : this.state.values[value].inputValue,
        };
      }
    });
    this.setState({
      ...this.state,
      filterApplied: Object.keys(submitForm).length > 2,
      showFilter: false,
      lastConsistentResults,
    });
    submitForm.branchs = submitForm.partners;
    this.props.filterPersons(
      {
        ...submitForm,
        start_range: this.state.range.start,
        end_range: this.state.range.end,
        lastConsistentResults,
      },
      resetPage,
    );
  };

  handleSearch = (key, value) => {
    let current = this.state.values.search.inputValue;
    this.setState(
      {
        ...this.state,
        values: {
          ...this.state.values,
          [key]: { ...this.state.values[key], inputValue: value },

          didFilter: true,
        },
      },
      () => {
        if (
          this.state.values.search.inputValue.length >= 3 ||
          current.length > this.state.values.search.inputValue.length
        ) {
          if (this.searchTimeout) clearTimeout(this.searchTimeout);

          this.searchTimeout = setTimeout(
            function () {
              this.props.updateFilterValues({
                ...this.state,
                search: this.state.values.search.inputValue,
              });
            }.bind(this),
            300,
          );
        }
      },
    );
  };

  handleCellEmailReportModal = (
    e,
    value,
    id_invitation,
    campaign,
    person_name,
  ) => {
    e.stopPropagation();

    this.setState({
      ...this.state,
      showEmailReportConfirm: true,
      emailReportSelected: {
        value,
        id_invitation,
        campaign,
        person_name,
      },
    });
  };

  handleCellEmailReportConfirm = () => {
    this.props.sendPersonReportEmail(this.state.emailReportSelected);
    notify.show(
      <SnackBar
        icon={'priority'}
        title={I18n.t('common.excellent')}
        body={
          I18n.t('persons.reportResended') +
          ' ' +
          this.state.emailReportSelected.person_name
        }
        iconFilled
        type="success"
      />,
      'custom',
      5000,
      successMessage,
    );
  };

  handleFiles = (files) => {
    this.props.migrateCampaign(files[0]);
  };

  selectAll = (flag) => {
    this.props.selectAll(flag);
    const allselectedInPage =
      this.props.filters.filter(
        (person) =>
          (person.conductual.result &&
            this.props.selection.find(
              (sel) => sel.id_invitation === person.conductual.id,
            )) ||
          !person.conductual.result,
      ).length === this.props.filters.length;
    if (flag && !allselectedInPage) {
      this.props.toggleSelectionAll();
    }
  };

  filterByDates = (filter) => {
    let submitForm = {
      ordering: this.props.customFilter.ordering,
      search: this.props.customFilter.search,
    };

    Object.keys(this.state.values).forEach((value) => {
      if (
        this.state.values[value].inputValue !== '' &&
        this.state.values[value].inputValue?.length !== 0
      ) {
        submitForm = {
          ...submitForm,
          [value]: Array.isArray(this.state.values[value].inputValue)
            ? this.state.values[value].inputValue?.map((res) => res.code)
            : this.state.values[value].inputValue?.code
            ? this.state.values[value].inputValue?.code
            : this.state.values[value].inputValue,
        };
      }
    });
    this.onHide();

    this.setState({
      ...this.state,
      range: { start: filter.start_range, end: filter.end_range },
    });
    this.props.filterPersons(
      {
        ...submitForm,
        ...filter,
        lastConsistentResults: this.state.lastConsistentResults,
      },
      true,
    );
  };

  handleActions = (name, option) => {
    if (option.code === 'tends') {
      this.props.history.push('/main/persons/group-trends');
    }

    if (option.code === 'individual' || option.code === 'empresa') {
      this.downloadReport(name, option);
    }
  };

  toggleLastResultStatus = () => {
    let value = this.state.lastConsistentResults;
    this.handleSubmit(!value);
  };
  allSelectioninPage = () => {
    let flag = true;
    this.props.filters.map((person) => {
      const result = this.props.selection.find(
        (s) => s.id_invitation === person.conductual.id,
      );
      if (!result) {
        flag = false;
      }
    });
    return flag;
  };

  handleDisable() {
    const data = this.props.selection.find((a) => a?.profile?.id !== 27);
    if (data !== undefined) return false;
    return true;
  }
  render() {
    this.checkNotificationRenders();
    const { loading, success, error } = this.props.retrieveStatus;
    const { loading: loadingReports } = this.props.reportsStatus;
    const { allSelected, allLoaded } = this.props;

    const actions = [
      { code: 'reports', label: I18n.t('persons.reports'), menu: true },
      {
        code: 'individual',
        label: I18n.t('persons.individualReport'),
        subitem: true,
        disabled:
          this.props.selection.length < 1 ||
          loading ||
          (allSelected && !allLoaded),
      },
      {
        code: 'empresa',
        label: I18n.t('persons.partnerReport'),
        subitem: true,
        disabled:
          this.props.selection.length < 1 ||
          loading ||
          (allSelected && !allLoaded),
      },
      {
        code: 'tends',
        label: I18n.t('persons.groupTendencies'),
        disabled:
          this.props.selection.length < 1 ||
          loading ||
          (allSelected && !allLoaded),
      },
    ];
    const { loading: loadingDates } = this.props.dateStatus;
    let allprofiles = [
      {
        id: -1,
        name: I18n.t('dashboard.profileFilterPlaceholder'),
        description: '',
        traits: Array(0),
      },
    ].concat(this.props.profiles);
    if (this.props.shareFileStatus.success) {
      notify.show(
        <SnackBar
          icon={'priority'}
          title={I18n.t('persons.excellent')}
          body={I18n.t('persons.shareSuccess')}
          type="success"
          iconFilled
        />,
        'custom',
        5000,
        successMessage,
      );
      this.props.clearUiValue('shareFileStatus', 'success');
    }
    return loadingReports ? (
      <Loader />
    ) : (
      <>
        {this.state.downloadReportModalShow && (
          <DownloadReportModal
            show={this.state.downloadReportModalShow}
            action={this.handleActions}
            onHide={this.modalShow}
          />
        )}

        <ModalShare
          show={this.state.showShareModal}
          action={(body) =>
            this.props.shareFile({
              id_invitation: this.state.invitation_selected,
              ...body,
            })
          }
          onHide={this.onHide}
        />

        <div className="container-fluid persons-custom-container">
          <EmailReportConfirmModal
            show={this.state.showEmailReportConfirm}
            onHide={this.onHide}
            onConfirm={this.handleCellEmailReportConfirm}
            name={this.state.emailReportSelected?.person_name}
          />
          <Tabs
            defaultActiveKey={this.props.defaultTab || 1}
            id="uncontrolled-tab-example"
            onSelect={this.handleSelect}
            value={this.state.selectedTab}
          >
            <div className={'card-header-right'}>
              {(this.props.user.user_admin ||
                this.props.user.branch?.account.ie_active ||
                this.props.user.account?.ie_active) && (
                <Button
                  className="button-download-report"
                  text={I18n.t('campaigns.ie.inviteIe')}
                  disabled={this.handleDisable()}
                  iconButton={false}
                  onClick={this.handleOnclick}
                />
              )}
              <Button
                className="button-download-report"
                text={I18n.t('persons.downloadReport')}
                disabled={this.handleDisable()}
                iconClass="download"
                iconButton
                onClick={this.modalShow}
              />
              <Button
                className="primary"
                disabled={this.handleDisable()}
                text={I18n.t('common.sidebar.compatibility')}
                iconClass="swap_horiz"
                iconButton
                onClick={() => {
                  this.props.history.push(
                    '/main/persons/group-trends?selected-items=true',
                  );
                  window.location.reload();
                }}
              />
            </div>
            <Tab eventKey={1} title={I18n.t('common.sidebar.persons')}>
              {allSelected && !allLoaded ? (
                <Loader />
              ) : (
                <>
                  <div className="filters">{this.renderFilters()}</div>
                  {this.renderTable()}
                </>
              )}
            </Tab>
          </Tabs>
        </div>
      </>
    );
  }

  renderFilters() {
    let allprofiles = [
      {
        id: -1,
        name: I18n.t('dashboard.profileFilterPlaceholder'),
        description: '',
        traits: Array(0),
      },
    ].concat(this.props.profiles);
    let profileValues = {
      inputValue: this.state.values.profiles.inputValue.filter(
        (f) => f.code !== 28 && f.code !== 27,
      ),
    };
    return (
      <div className="filters-container">
        <SearchInput
          value={this.state.values.search.inputValue}
          name="search"
          placeholder={I18n.t('persons.search')}
          onChange={(e) => this.handleSearch('search', e.target.value)}
          displayValue={this.state.values.search.inputValue}
        />

        <DateRangePickerFilter
          hiddenPopup={true}
          popupUp={true}
          rangeDefault={{
            start: this.props.customFilter.start_range || this.props.start_date,
            end: this.props.customFilter.end_range || this.props.end_date,
          }}
          previewRange={{
            start: this.state.range.start,
            end: this.state.range.end,
          }}
          start_date={this.state.range.start}
          end_date={this.state.range.end}
          didFilter={this.state.didFilter}
          key={
            this.state.range.start +
            this.props.customFilter.start_range +
            this.props.customFilter.end_range +
            this.state.range.end +
            this.props.start_date +
            this.props.end_date
          }
          classNameButton="date-person"
          {...this.props}
          filter={(e) => {
            this.setState({ ...this.state, didFilter: true }, () => {
              this.filterByDates(e);
            });
          }}
        />

        <FormDropdown
          value={this.state.values.age}
          name={'age'}
          loading={false}
          options={[
            { code: '0-19', description: '<19' },
            { code: '20-29', description: '20 - 29' },
            { code: '30-39', description: '30 - 39' },
            { code: '40-49', description: '40 - 49' },
            { code: '50-59', description: '50 - 59' },
            { code: '60-100', description: '>60' },
          ]}
          placeholder={I18n.t('persons.age')}
          change={this.handleInputChange}
          title={I18n.t('persons.ageRange')}
          cleanOption={true}
          className="age"
          cleanSelected={() =>
            this.setState({
              ...this.state,
              age: { inputValue: [] },
            })
          }
        />

        <FormDropdown
          placeholder={I18n.t('persons.profile')}
          multiSelect
          search={this.props.getAllProfiles}
          loading={false}
          showEmptyOption
          getAllClientsPrompts={{ search: '' }}
          name={'profiles'}
          value={profileValues}
          addOption={this.addOption}
          clearInput={this.state.clearInput}
          options={allprofiles
            ?.filter((fil) => fil.id !== 27 && fil.id !== 28)
            .map((profile) => ({
              code: profile.id,
              description: profile.name,
            }))}
          cleanOption={true}
          cleanSelected={() =>
            this.setState(
              {
                ...this.state,
                values: { ...this.state.values, profiles: { inputValue: [] } },
              },
              () => this.addOption(null, 'profiles', ''),
            )
          }
        />
        <FormDropdown
          name={'campaigns'}
          multiSelect
          addOption={this.addOption}
          value={this.state.values.campaigns}
          options={this.props.allCampaigns.map((campaign) => ({
            code: campaign.id,
            description: campaign.name,
          }))}
          placeholder={I18n.t('campaigns.identifierAlternative')}
          search={this.props.getAllCampaigns}
          loading={false}
          showEmptyOption
          change={this.handleInputChange}
          getAllClientsPrompts={{
            partners: null,
            campaign_dropdown: true,
          }}
          cleanOption={true}
          clearInput={this.state.clearInput}
          cleanSelected={() =>
            this.setState(
              {
                ...this.state,
                values: { ...this.state.values, campaigns: { inputValue: [] } },
              },
              () => this.addOption(null, 'campaigns', ''),
            )
          }
        />

        {(this.props.user.branch?.account?.ie_active === true ||
          this.props.user.account?.ie_active === true ||
          this.props.user.user_admin) && (
          <>
            {/* <SquareButton
              label={I18n.t('persons.IE.filter')}
              className="emotional-intelligence-check"
              name={'emotionalIntelligence'}
              value={this.state.emotionalIntelligence}
              setValue={!this.state.emotionalIntelligence.inputValue}
              change={(name, setValue, data) => {
                this.addOptionIE(setValue, name, data);
              }}
              data={{ code: 0, description: 'emotionalIntelligence' }}
            /> */}
            <FormDropdown
              value={this.state.emotionalIntelligence}
              name={'emotionalIntelligence'}
              loading={false}
              options={[
                { code: true, description: I18n.t('common.yes') },
                { code: false, description: I18n.t('common.no') },
              ]}
              placeholder={I18n.t('persons.IE.filter')}
              change={(name, setValue, data) => {
                this.addOptionIE(setValue, name, data);
              }}
              title={null}
              cleanOption={true}
              cleanSelected={() =>
                this.setState({
                  ...this.state,
                  values: {
                    emotionalIntelligence: { inputValue: [] },
                  },
                  didFilter: false,
                })
              }
              className="age"
            />
          </>
        )}
        <SquareButton
          label={I18n.t('persons.inconsistent')}
          className="inconsistent-profile-check"
          name={'profiles'}
          value={this.state.inconsistentProfile}
          setValue={!this.state.inconsistentProfile.inputValue}
          change={(name, setValue, data) => {
            this.addOption(setValue, name, data);
          }}
          data={{ code: 27, description: 'Inconsistente' }}
        />

        {(this.state.filterApplied ||
          this.props.customFilter.start_range !== this.props.start_date ||
          this.props.customFilter.end_range !== this.props.end_date ||
          this.props.customFilter.search.length >= 3) && (
          <div className="delete-button-container">
            <Button
              className="text-link-button delete-button"
              text={I18n.t('buttons.clearFilter')}
              onClick={() => this.handleFilterRemove()}
              iconButton={true}
              iconClass={'close'}
            />
          </div>
        )}
      </div>
    );
  }
  renderTable() {
    const { loading, success, error } = this.props.retrieveStatus;
    const { loading: loadingCampaigns } = this.props.allCampaignsStatus;
    const { loading: loadingFilter } = this.props.retrieveFilter;
    const { customFilter, totalCount, allSelected, results } = this.props;
    const orderingFilterObj = {
      desc: customFilter.ordering[0] === '-',
      id:
        customFilter.ordering[0] === '-'
          ? customFilter.ordering.slice(1, customFilter.ordering.length)
          : customFilter.ordering,
    };
    const pageSelection = this.props.selection.length;
    let extraColumns = [];
    let emailColumn = [];
    let ieColumn = [];
    if (
      (this.props.user.account &&
        this.props.user.account?.campaign_field !== null) ||
      (this.props.user.branch &&
        this.props.user.branch?.account?.campaign_field !== null)
    ) {
      let fields =
        this.props.user.account?.campaign_field?.fieldsName ||
        this.props.user.branch.account?.campaign_field?.fieldsName;
      fields?.map((field) =>
        extraColumns.push({
          Header: (
            <p className="header-table">
              <span>{I18n.t('persons.EAE.' + field)}</span>
            </p>
          ),
          headerClassName: 'wordwrap campaign-table',
          className: 'campaign-table',
          id: field,
          accessor: `user_player.info.${field}`,
          Cell: (props) => {
            if (field === 'date') {
              let date;
              if (props.value && dayjs(props.value, 'DD/MM/YYYY').isValid()) {
                date = new Date(props.value.split('/').reverse().join('/'));
              } else {
                date = props.value;
              }

              return (
                <>
                  <span
                    className="large-text"
                    id={'optional-' + field + props.original.id}
                    data-tooltip-id={'tooltip-' + field + props.original.id}
                    data-tooltip-content={
                      props.value && dayjs(props.value, 'DD/MM/YYYY').isValid()
                        ? `${date
                            .toLocaleDateString(this.props.language, {
                              month: 'long',
                            })
                            .replace(/^\w/, (c) =>
                              c.toUpperCase(),
                            )} ${date.toLocaleDateString(this.props.language, {
                            year: 'numeric',
                          })}`
                        : props.value
                        ? date
                        : I18n.t('persons.notRequested')
                    }
                  >
                    {props.value && dayjs(props.value, 'DD/MM/YYYY').isValid()
                      ? `${date
                          .toLocaleDateString(this.props.language, {
                            month: 'long',
                          })
                          .replace(/^\w/, (c) =>
                            c.toUpperCase(),
                          )} ${date.toLocaleDateString(this.props.language, {
                          year: 'numeric',
                        })}`
                      : props.value
                      ? date
                      : I18n.t('persons.notRequested')}
                  </span>
                  <LargeTextTooltip
                    id={'tooltip-' + field + props.original.id}
                    idToOverflow={'optional-' + field + props.original.id}
                  />
                </>
              );
            } else {
              return (
                <>
                  <span
                    className="large-text"
                    id={'optional-' + field + props.original.id}
                    data-tooltip-id={'tooltip-' + field + props.original.id}
                    data-tooltip-content={
                      props.value
                        ? field === 'language'
                          ? I18n.t('persons.EAE.' + props.value.toLowerCase())
                          : props.value
                        : I18n.t('persons.notRequested')
                    }
                  >
                    {props.value
                      ? field === 'language'
                        ? I18n.t('persons.EAE.' + props.value.toLowerCase())
                        : props.value
                      : I18n.t('persons.notRequested')}
                  </span>
                  <LargeTextTooltip
                    id={'tooltip-' + field + props.original.id}
                    idToOverflow={'optional-' + field + props.original.id}
                  />
                </>
              );
            }
          },
        }),
      );
    } else {
      emailColumn.push({
        Header: (
          <p className="header-table">
            <span>{I18n.t('persons.emailAddress')}</span>
            {this.props.customFilter.ordering === 'user__email' ? (
              <span class="material-symbols-rounded">arrow_drop_down</span>
            ) : this.props.customFilter.ordering === '-user__email' ? (
              <span class="material-symbols-rounded">arrow_drop_up</span>
            ) : (
              <span class="material-symbols-rounded">arrow_drop_down</span>
            )}
          </p>
        ),
        headerClassName: 'wordwrap email-table',
        className: 'email-table',
        id: 'user__email',
        accessor: (d) => d?.conductual?.email || '',
        Cell: (props) => <span className="number">{props.value}</span>,
      });
      if (this.props.user.user_admin) {
        emailColumn.push({
          Header: (
            <p className="header-table">
              <span>{I18n.t('common.sidebar.filiales')}</span>
              {this.props.customFilter.ordering === 'branch' ? (
                <span class="material-symbols-rounded">arrow_drop_down</span>
              ) : this.props.customFilter.ordering === '-branch' ? (
                <span class="material-symbols-rounded">arrow_drop_up</span>
              ) : (
                <span class="material-symbols-rounded">arrow_drop_down</span>
              )}
            </p>
          ),

          id: 'branch',
          headerClassName: 'wordwrap campaign-table',
          className: 'campaign-table',
          accessor: (d) => d?.branch?.name,
          sortable: true,
          Cell: (props) => <span>{props.value}</span>,
        });
      }
    }

    if (
      this.props.user.branch?.account?.ie_active === true ||
      this.props.user.account?.ie_active === true ||
      this.props.user.user_admin
    ) {
      ieColumn.push({
        Header: (
          <p className="header-table">
            {/* <span>{I18n.t('persons.profile')}</span> */}
            <span
              data-tooltip-id="headerIETooltip"
              data-tooltip-content={I18n.t('persons.IE.filter')}
            >
              {I18n.t('common.ie')}
            </span>
            <Tooltip id="headerIETooltip" place="right" />
            {this.props.customFilter.ordering === 'emotional_intelligence' ? (
              <span class="material-symbols-rounded">arrow_drop_down</span>
            ) : this.props.customFilter.ordering ===
              '-emotional_intelligence' ? (
              <span class="material-symbols-rounded">arrow_drop_up</span>
            ) : (
              <span class="material-symbols-rounded">arrow_drop_down</span>
            )}
          </p>
        ),
        headerClassName: 'wordwrap',
        className: 'person-profile',
        id: 'emotional_intelligence',
        accessor: 'ie',
        Cell: (props) => {
          return (
            <span className="number">
              {props.value?.type === 'IE' && props.value?.status === 'COMPLETED'
                ? I18n.t('common.yes').toUpperCase()
                : I18n.t('common.no').toUpperCase()}
            </span>
          );
        },
      });
    }
    if (error)
      return <div className="text-warning">{I18n.t('persons.error.list')}</div>;
    if (success || loading)
      return (
        <div className="table">
          <ReactTable
            columns={[
              {
                Header: (
                  <SquareButton
                    value={{
                      inputValue: this.allSelectioninPage(),
                    }}
                    disabled={results.length === 0}
                    change={this.props.toggleSelectionAll}
                  />
                ),
                id: 'result',
                headerClassName: 'checkbox-header',
                className: 'checkbox-column',
                accessor: (d) => {
                  return {
                    id_result: d.conductual.result.id,
                    id_invitation: d.conductual.id,
                    campaign: d.campaign,
                    profile: d.profile,
                    email: d.conductual.email,
                    full_name: d.user.full_name,
                    user_id: d.user.id,
                  };
                },
                width: 50,
                sortable: false,
                Cell: (props) => {
                  return (
                    <SquareButton
                      value={{
                        inputValue: this.props.selection.some(
                          (sel) =>
                            props.value.id_invitation &&
                            sel.id_invitation === props.value.id_invitation,
                        ),
                      }}
                      disabled={!props.value}
                      change={() =>
                        this.props.addRemovePersonToSelection(props.value)
                      }
                    />
                  );
                },
                getProps: () => ({
                  onClick: (e) => e.stopPropagation(),
                  style: { cursor: 'default' },
                }),
              },
              {
                Header: (
                  <p className="header-table">
                    <span>{I18n.t('persons.first_name')}</span>
                    {this.props.customFilter.ordering === 'user__first_name' ? (
                      <span class="material-symbols-rounded">
                        arrow_drop_down
                      </span>
                    ) : this.props.customFilter.ordering ===
                      '-user__first_name' ? (
                      <span class="material-symbols-rounded">
                        arrow_drop_up
                      </span>
                    ) : (
                      <span class="material-symbols-rounded">
                        arrow_drop_down
                      </span>
                    )}
                  </p>
                ),
                headerClassName: 'wordwrap',
                className: 'resultt',
                id: 'user__first_name',
                accessor: (d) => ({
                  full_name: d.user.full_name,
                  code: d.conductual.code,
                }),
                Cell: (props) => (
                  <div className="full-name">
                    <span>{props.value.full_name}</span>
                    <span className="code">
                      {props.value.code.length === 12
                        ? `${props.value.code.substring(
                            0,
                            4,
                          )}-${props.value.code.substring(
                            4,
                            8,
                          )}-${props.value.code.substring(8, 12)}`
                        : props.value.code.length === 9
                        ? `${props.value.code.substring(
                            0,
                            3,
                          )}-${props.value.code.substring(
                            3,
                            6,
                          )}-${props.value.code.substring(6, 9)}`
                        : '-'}
                    </span>
                  </div>
                ),
              },
              {
                Header: (
                  <p className="header-table">
                    <span>{I18n.t('persons.profile')}</span>
                    {this.props.customFilter.ordering === 'profile_name' ? (
                      <span class="material-symbols-rounded">
                        arrow_drop_down
                      </span>
                    ) : this.props.customFilter.ordering === '-profile_name' ? (
                      <span class="material-symbols-rounded">
                        arrow_drop_up
                      </span>
                    ) : (
                      <span class="material-symbols-rounded">
                        arrow_drop_down
                      </span>
                    )}
                  </p>
                ),
                headerClassName: 'wordwrap',
                className: 'person-profile',
                id: 'profile_name',
                accessor: 'profile',
                Cell: (props) => {
                  return (
                    <span className="number">
                      {props.value[`name_${this.props.language}`]}
                    </span>
                  );
                },
              },
              ...ieColumn,
              {
                Header: (
                  <p className="header-table game_date">
                    <span>{I18n.t('persons.date')}</span>
                    {this.props.customFilter.ordering === 'game_date' ? (
                      <span class="material-symbols-rounded">
                        arrow_drop_down
                      </span>
                    ) : this.props.customFilter.ordering === '-game_date' ? (
                      <span class="material-symbols-rounded">
                        arrow_drop_up
                      </span>
                    ) : (
                      <span class="material-symbols-rounded">
                        arrow_drop_down
                      </span>
                    )}
                  </p>
                ),
                headerClassName: 'wordwrap game-date-table',
                className: 'game-date-table',
                id: 'game_date',
                accessor: 'conductual.conversion_date',
                Cell: (props) => (
                  <span className="number">
                    {props.value ? dayjs(props.value).format('DD/MM/YYYY') : ''}
                  </span>
                ),
              },
              ...emailColumn,
              {
                Header: (
                  <p className="header-table">
                    <span>{I18n.t('campaigns.identifier')}</span>
                    {this.props.customFilter.ordering === 'campaign_name' ? (
                      <span class="material-symbols-rounded">
                        arrow_drop_down
                      </span>
                    ) : this.props.customFilter.ordering ===
                      '-campaign_name' ? (
                      <span class="material-symbols-rounded">
                        arrow_drop_up
                      </span>
                    ) : (
                      <span class="material-symbols-rounded">
                        arrow_drop_down
                      </span>
                    )}
                  </p>
                ),
                headerClassName: 'wordwrap campaign-table',
                className: 'campaign-table',
                id: 'campaign_name',
                accessor: 'campaign.name',
                Cell: (props) => (
                  <span className="number">
                    {props.value ? props.value : '-'}
                  </span>
                ),
              },
              ...extraColumns,
              {
                //Header: I18n.t('persons.actions'),
                id: 'download-report',
                sortable: false,
                accessor: (d) => d.id,
                className: 'actions-table',
                width: 97,
                Cell: (props) => {
                  return (
                    <TableMenuItem>
                      <Dropdown
                        value={{
                          inputValue: {
                            code: '1',
                            label: (
                              <span class="material-symbols-rounded">
                                more_vert
                              </span>
                            ),
                          },
                        }}
                        className="users-dropdown"
                        noBorder
                        options={[
                          {
                            code: 'sendReport',
                            label: (
                              <p
                                onClick={(e) => {
                                  this.handleCellEmailReportModal(
                                    e,
                                    props.original.conductual.result.id,
                                    props.original.conductual.id,
                                    props.original.campaign.name,
                                    props.original.user.full_name,
                                  );
                                }}
                              >
                                <span class="material-symbols-filled">
                                  assignment
                                </span>
                                <span>{I18n.t('persons.resendReport')}</span>
                              </p>
                            ),
                            disabled: false,
                          },
                          {
                            code: 'delete',
                            label: (
                              <p
                                onClick={(e) =>
                                  this.setState({
                                    ...this.state,
                                    showShareModal: true,
                                    invitation_selected:
                                      props.original.conductual.id,
                                  })
                                }
                              >
                                <span class="material-symbols-filled">
                                  share
                                </span>
                                <span>{I18n.t('campaigns.share')}</span>
                              </p>
                            ),
                            disabled: false,
                          },
                        ]}
                      />
                    </TableMenuItem>
                  );
                },
                getProps: () => ({
                  onClick: (e) => e.stopPropagation(),
                  style: { cursor: 'default' },
                }),
              },
            ]}
            data={this.props.filters}
            minRows={0}
            minWith={5}
            resizable={false}
            noDataText={
              this.state.didFilter
                ? I18n.t('persons.table.noRows')
                : I18n.t('persons.table.noEntry')
            }
            // TODO: ReactTable sorting
            defaultSorted={[
              {
                id: 'game_date',
                desc: false,
              },
            ]}
            sorted={[
              {
                id: orderingFilterObj.id,
                desc: orderingFilterObj.desc,
              },
            ]}
            onSortedChange={(newSorted, column, shiftKey) => {
              let columnId = column.id;
              let fields =
                this.props.user.account?.campaign_field?.fieldsName ||
                this.props.user.branch?.account?.campaign_field?.fieldsName;
              if (!fields || !fields.includes(columnId))
                this.props.updateFilterValues({
                  ordering: (newSorted[0].desc ? '-' : '') + columnId,
                  start_range: this.state.range.start,
                  end_range: this.state.range.end,
                });
            }}
            getTrProps={(state, rowInfo, column, instance) => {
              let className = '';
              this.props.selection.some((sel) => {
                if (sel.id_invitation === rowInfo.original.conductual.id) {
                  className = 'selected-row';
                }
              });
              return {
                onClick: (e, handleOriginal) => {
                  if (handleOriginal) {
                    handleOriginal();
                  }
                  this.props.selectPerson(rowInfo.original.conductual.code);
                  // this.props.history.push('/main/person');
                },
                style: {
                  cursor: 'pointer',
                },
                className,
              };
            }}
            manual // prop that tells ReactTable to dont client-side sort or paginate
            pageSize={this.props.pageSize}
            PaginationComponent={() => {
              const {
                currentPage,
                updatePage,
                totalPages,
                pageSize,
                totalCount,
                updatePageSize,
              } = this.props;
              const isOnFirstPage = currentPage === 1;
              const isOnLastPage = currentPage === totalPages;
              return (
                <TablePaginationDrawer
                  data={{
                    totalCount,
                    currentPage,
                    pageSize,
                    isOnFirstPage,
                    isOnLastPage,
                    updatePage,
                    updatePageSize,
                    totalPages,
                  }}
                  extraComponent={
                    <div className="multi-select">
                      {allSelected && this.props.selection.length > 1 ? (
                        <a
                          onClick={() => this.selectAll(false)}
                          href="#/"
                          className="total-select"
                        >
                          {' '}
                          {I18n.t('persons.clearSelect', { total: totalCount })}
                        </a>
                      ) : (
                        pageSelection > 0 && (
                          <div>
                            <span className="selected">
                              {I18n.t('persons.multiSelect', {
                                selected: this.props.selection.length, //pageSelection,
                                total: totalCount,
                              })}
                            </span>
                            <a
                              onClick={() => this.selectAll(true)}
                              href="#/"
                              className="total-select"
                            >
                              {' '}
                              {I18n.t('persons.totalSelect')}
                            </a>
                          </div>
                        )
                      )}
                    </div>
                  }
                  table={'persons'}
                />
              );
            }}
            loading={loadingFilter || loadingCampaigns}
            LoadingComponent={TableLoader}
          />
        </div>
      );

    return <Loader />;
  }

  renderLastConsistentFilter() {
    return (
      <div className="filter-container">
        <Button
          className={
            this.state.lastConsistentResults ? 'primary applied' : 'primary'
          }
          text={I18n.t('persons.profileFilterButton')}
          onClick={this.toggleLastResultStatus}
        />
      </div>
    );
  }

  checkNotificationRenders() {
    if (
      this.props.updateStatus.success &&
      this.props.updateUserStatus.success
    ) {
      notify.show(I18n.t('persons.success.update'), 'custom', 5000, successUI);
      this.props.clearUiValue('update', 'success');
      this.props.clearUiValue('userUpdate', 'success');
    }
    if (this.props.deleteStatus.success) {
      notify.show(I18n.t('persons.success.delete'), 'custom', 5000, successUI);
      this.props.clearUiValue('delete', 'success');
    }
    if (this.props.deleteStatus.error) {
      notify.show(I18n.t('persons.error.delete'), 'custom', 5000, errorUI);
      this.props.clearUiValue('delete', 'error');
    }
    if (this.props.sendPersonReportEmailStatus.success) {
      notify.show(
        I18n.t('persons.success.sendReport'),
        'custom',
        5000,
        successUI,
      );
      this.props.clearUiValue('sendPersonReportEmailStatus', 'success');
    }
    if (this.props.sendPersonReportEmailStatus.error) {
      notify.show(I18n.t('persons.error.sendReport'), 'custom', 5000, errorUI);
      this.props.clearUiValue('sendPersonReportEmailStatus', 'error');
    }
  }

  componentWillUnmount() {
    const nextLocation = this.props.history.location.pathname;
    if (
      nextLocation !== '/main/person' &&
      nextLocation !== '/main/persons/group-trends' &&
      nextLocation !== '/'
    ) {
      // this.props.saveSearchInput({ inputValue: '' });
      // this.props.updateFilterValues({ search: '', page: 1, ordering: '-game_date'});
      this.props.clearSelection();
    }
    if (
      nextLocation === '/main/persons/group-trends' ||
      nextLocation === '/main/person'
    ) {
      this.props.saveSearchInput(this.state.values.search);
    }
  }
}

export default PersonsScreen;
