import actions from '../actions/index';
import { services } from './bapro.services';
import { GET_BAPRO_FILES, UPLOAD_FILE } from './bapro.actions';

const baproMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    // const language = localStorage.getItem('language');
    switch (action.type) {
      case UPLOAD_FILE:
        services
          .uploadFile(action.body)
          .then((data) => {
            dispatch(actions.bapro.uploadFileResponse(data));
            dispatch(actions.bapro.resetBaproFile());
            dispatch(
              actions.bapro.getBaproFiles({
                id_user_player: action.body.id_user_player,
                partner: action.body.partner,
              }),
            );
          })
          .catch((error) => dispatch(actions.bapro.uploadFileError(error)));
        break;
      case GET_BAPRO_FILES:
        services
          .getBaproFiles({
            id_user_player: action.id_user_player,
            partner: action.partner,
          })
          .then((data) => {
            dispatch(actions.bapro.getBaproFilesResponse(data));
          })
          .catch((error) => dispatch(actions.bapro.getBaproFilesError(error)));
        break;
      default:
        break;
    }
  };

export default baproMiddleware;
