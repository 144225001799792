import { connect } from 'react-redux';
import actions from '../../actions/index';
import { withRouter } from 'react-router';
import BaproScreen from '../components/BaproScren/BaproScreen';

const mapStateToProps = (state, ownProps) => ({
  user: state.session.user,
  // fileStatus: state.bapro.uploadFilesStatus,
  baproFiles: state.bapro.baproFiles,
  getBaproFile: state.bapro.getBaproFile,
  uploadFilesStatus: state.bapro.uploadFilesStatus,
});

const mapDispatchToProps = (dispatch) => ({
  uploadFile: ({ id_user_player, curriculum, partner }) =>
    dispatch(actions.bapro.uploadFile({ id_user_player, curriculum, partner })),
  getBaproFiles: ({ id_user_player, partner }) =>
    dispatch(actions.bapro.getBaproFiles({ id_user_player, partner })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(BaproScreen));
