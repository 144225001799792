import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import actions from '../../actions/index';
import UpdateCredits from '../components/UpdateCredits/UpdateCredits';

const mapStateToProps = (state) => ({
  clientId: state.clients.selectedClient.id,
  creditsAvailable: calculateAvailableCredits(state),
  updateCreditsStatus: state.clients.updateCreditsStatus,
  clients: state.clients.clients,
  creditsAcquired: state.clients.selectedClient.credits_acquired,
  isAdmin: state.session.user.user_admin ? true : false,
  isSuperDashboard:
    state.session.user.partner && state.session.user.partner.is_super_dashboard,
  affiliateCreditsAvaible: affiliateCreditsAvailable(state),
  currentUser: state.session.user,
});

/* const calculateAvaibleCredits = (state) => {
  // O acá No sé bien donde
  const selectedClient = state.clients.selectedClient;
  return selectedClient.credits - selectedClient.credits_assigned;
  
}; */

const calculateAvailableCredits = (state) => {
  if (!state.session.user.account)
    if (
      state.clients.selectedClient.nwm_branch?.length > 1 ||
      state.clients.selectedClient.nwm_branch?.length === 0
    ) {
      const selectedClient = state.clients.selectedClient;
      return selectedClient.credits - selectedClient.credits_assigned;
    } else {
      const selectedAccount = state.clients.selectedClient;
      const selectedClient = state.clients.selectedClient.nwm_branch?.length
        ? state.clients.selectedClient?.nwm_branch[0]
        : state.clients.selectedClient;
      if (state.clients.selectedClient.nwm_branch?.length)
        return (
          selectedClient.credits -
          selectedClient.credits_assigned +
          (selectedAccount.credits - selectedAccount.credits_assigned)
        );
      else return selectedClient.credits - selectedClient.credits_assigned;
    }
  else {
    const selectedClient =
      state.session.user.partner || state.session.user.account;
    return selectedClient.credits - selectedClient.credits_assigned;
  }
};

const affiliateCreditsAvailable = (state) => {
  if (state.session.user.account) {
    const selectedClient = state.clients.selectedClient;
    return selectedClient.credits - selectedClient.credits_assigned;
  }
};

const mapDispatchToProps = (dispatch) => ({
  updateCredits: (partnerId, amount) =>
    dispatch(actions.clients.updateCredits(partnerId, amount)),
  clearUiValue: (type, condition) =>
    dispatch(actions.clients.clearUiValue(type, condition)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(UpdateCredits));
