import { deleteRequest, get, post, put, patch, webApi } from '../utils/http';
import axios from 'axios';
import { FORM_TYPES, LANGUAGE } from '../utils/constants';
import queryString from 'query-string';

export let cancelToken;

export const setToken = () => {
  cancelToken = axios.CancelToken.source();
};
export const cleanToken = () => {
  cancelToken = undefined;
};

export const services = {
  getCampaigns: (filter) =>
    get(
      `${webApi}api/companies/campaigns${filter.toString()}&lang=${LANGUAGE}`,
    ),
  getAllCampaigns: (
    queryPartners,
    campaign_dropdown,
    search,
    partner_id,
    campaign_id,
  ) => {
    const queryObject = {
      branch_id: queryPartners,
      campaign_dropdown,
      partner_id,
      campaign_id,
    };
    if (search) queryObject.search = search;
    return get(
      webApi + `api/companies/campaigns?${queryString.stringify(queryObject)}`,
      {
        extra: { cancelToken: cancelToken.token },
      },
    );
  },
  reloadSelectedCampaign: (id) =>
    get(webApi + `api/companies/campaigns/${id}?origin=web`),
  createCampaign: (body) => post(webApi + 'api/companies/campaigns', body),
  updateCampaign: (id, campaign) => {
    return patch(webApi + 'api/companies/campaigns/' + id, campaign);
  },
  deleteCampaign: (id) =>
    deleteRequest(webApi + 'api/companies/campaigns/' + id),
  generateCode: (id, email) => {
    let newEmails;
    Array.isArray(email) ? (newEmails = email) : (newEmails = [email]);
    return post(webApi + 'api/invitations', {
      id_campaign: id,
      emails: newEmails,
    });
  },
  updateCode: (id, body) =>
    put(webApi + `api/campaign_inscription/${id}/`, { ...body }),

  deleteCode: (id) => {
    return deleteRequest(webApi + `api/invitations/${id}`);
  },
  deleteCodes: (id_campaign) =>
    deleteRequest(webApi + `api/invitations`, {
      id_campaign,
    }),
  shareCampaign: (id) => get(webApi + `api/share_campaign/${id}/`),
  getPositions: (positionDropdown, search) =>
    get(
      webApi +
        `api/positions?lang=${LANGUAGE}&positionDropdown=${positionDropdown}&search=${search}`,
    ),
  getFields: () =>
    get(
      `${webApi}api/companies/campaigns/fields?form=${FORM_TYPES.gameRegistration}`,
    ),
};
