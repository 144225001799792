// export const GET_PERSONS = 'GET PERSONS';
// export const GET_PERSONS_RESPONSE = 'GET PERSONS RESPONSE';
// export const GET_PERSONS_ERROR = 'GET PERSONS ERROR';

export const CREATE_PERSON = 'CREATE PERSON';
export const CREATE_PERSON_RESPONSE = 'CREATE PERSON RESPONSE';
export const CREATE_PERSON_ERROR = 'CREATE PERSON ERROR';

export const UPDATE_PERSON = 'UPDATE PERSON';
export const UPDATE_PERSON_RESPONSE = 'UPDATE PERSON RESPONSE';
export const UPDATE_PERSON_ERROR = 'UPDATE PERSON ERROR';

export const DELETE_PERSON = 'DELETE PERSON';
export const DELETE_PERSON_RESPONSE = 'DELETE PERSON RESPONSE';
export const DELETE_PERSON_ERROR = 'DELETE PERSON ERROR';

export const SELECT_PERSON = 'SELECT PERSON';
export const SELECT_PERSON_RESPONSE = 'SELECT PERSON RESPONSE';

export const UPDATE_PAGE = 'UPDATE PAGE PERSONS';
export const UPDATE_PAGE_SIZE = 'UPDATE PAGE PERSONS SIZE';
export const UPDATE_PERSONS_FILTER_VALUES = 'UPDATE PERSONS FILTER VALUES';

export const GET_ALL_PROFILES = 'GET ALL PROFILES';
export const GET_ALL_PROFILES_RESPONSE = 'GET ALL PROFILES RESPONSE';
export const GET_ALL_PROFILES_ERROR = 'GET ALL PROFILES ERROR';

export const GET_ALL_PARTNERS = 'GET ALL PARTNERS';
export const GET_ALL_PARTNERS_RESPONSE = 'GET ALL PARTNERS RESPONSE';
export const GET_ALL_PARTNERS_ERROR = 'GET ALL PARTNERS ERROR';

export const DEMOGRAPHIC_FILTER = 'DEMOGRAPHIC FILTER';
export const DEMOGRAPHIC_FILTER_RESPONSE = 'DEMOGRAPHIC FILTER RESPONSE';
export const DEMOGRAPHIC_FILTER_ERROR = 'DEMOGRAPHIC FILTER ERROR';

export const ADD_REMOVE_PERSON_TO_SELECTION = 'ADD REMOVE PERSON TO SELECTION';
export const UPDATE_TOOLBOX_FILTER = 'UPDATE TOOLBOX FILTER';
export const CLEAN_TOOLBOX_FILTER = 'CLEAN TOOLBOX FILTER';

export const GET_JOB_COMPATIBILITY = 'GET JOB COMPATIBILITY';
export const GET_JOB_COMPATIBILITY_RESPONSE = 'GET JOB COMPATIBILITY RESPONSE';
export const GET_JOB_COMPATIBILITY_ERROR = 'GET JOB COMPATIBILITY ERROR';

export const GET_ALL_JOB_COMPATIBILITY = 'GET ALL JOB COMPATIBILITY';
export const GET_ALL_JOB_COMPATIBILITY_RESPONSE =
  'GET ALL JOB COMPATIBILITY RESPONSE';
export const GET_ALL_JOB_COMPATIBILITY_ERROR =
  'GET ALL JOB COMPATIBILITY ERROR';

export const GET_CAPABILITIES = 'GET CAPABILITIES';
export const GET_CAPABILITIES_RESPONSE = 'GET CAPABILITIES RESPONSE';
export const GET_CAPABILITIES_ERROR = 'GET CAPABILITIES ERROR';

export const GET_CITIES = 'GET CITIES';
export const GET_CITIES_RESPONSE = 'GET CITIES RESPONSE';
export const GET_CITIES_ERROR = 'GET CITIES ERROR';

export const GET_COUNTRIES = 'GET COUNTRIES';
export const GET_COUNTRIES_RESPONSE = 'GET COUNTRIES RESPONSE';
export const GET_COUNTRIES_ERROR = 'GET COUNTRIES ERROR';

export const GET_PROFILE = 'GET PROFILE';
export const GET_PROFILE_RESPONSE = 'GET PROFILE RESPONSE';
export const GET_PROFILE_ERROR = 'GET PROFILE ERROR';

export const TOGGLE_SELECTION_ALL = 'TOGGLE_SELECTION_ALL';
export const CLEAR_SELECTION = 'CLEAR SELECTION';
export const CLEAR_PERSON_CUSTOM_FILTER = 'CLEAR PERSON CUSTOM FILTER';

export const CLEAR_UI_VALUE = 'CLEAR UI VALUE';

export const CLEAR_FILTER = 'CLEAR_FILTER';
export const FILTER_PERSONS = 'FILTER PERSONS';
export const FILTER_PERSONS_RESPONSE = 'FILTER PERSONS RESPONSE';
export const FILTER_PERSONS_ERROR = 'FILTER PERSONS ERROR';

export const PATCH_USER = 'PATCH USER';
export const PATCH_USER_RESPONSE = 'PATCH USER RESPONSE';
export const PATCH_USER_ERROR = 'PATCH USER ERROR';

export const DOWNLOAD_PERSON_REPORT = 'DOWNLOAD_PERSON_REPORT';
export const DOWNLOAD_PERSON_REPORT_RESPONSE =
  'DOWNLOAD_PERSON_REPORT_RESPONSE';
export const DOWNLOAD_PERSON_REPORT_ERROR = 'DOWNLOAD_PERSON_REPORT_ERROR';

export const SEND_PERSON_REPORT_EMAIL = 'SEND_PERSON_REPORT_EMAIL';
export const SEND_PERSON_REPORT_EMAIL_RESPONSE =
  'SEND_PERSON_REPORT_EMAIL_RESPONSE';
export const SEND_PERSON_REPORT_EMAIL_ERROR = 'SEND_PERSON_REPORT_EMAIL_ERROR';

export const MIGRATE_CAMPAIGN = 'MIGRAtE CAMPAIGN';
export const MIGRATE_CAMPAIGN_RESPONSE = 'MIGRATE CAMPAIGN RESPONSE';
export const MIGRATE_CAMPAIGN_ERROR = 'MIGRATE CAMPAIGN ERROR';

export const TREND_EXPORT = 'TREND EXPORT';
export const TREND_EXPORT_RESPONSE = 'TREND EXPORT RESPONSE';
export const TREND_EXPORT_ERROR = 'TREND EXPORT ERROR';

export const SAVE_SEARCH_INPUT = 'SAVE_SEARCH_INPUT';

export const LOGOUT = 'LOGOUT';

export const SELECT_ALL = 'SELECT ALL';

export const LOAD_ALL = 'LOAD ALL';

export const SAVE_ALL = 'SAVE ALL';

export const ALL_LOADED = 'ALL LOADED';

export const GET_USER_PLAYER_INFORMATION = 'GET USER PLAYER INFORMATION';
export const GET_USER_PLAYER_INFORMATION_RESPONSE =
  'GET USER PLAYER INFORMATION RESPONSE';

export const ADD_NOTE = 'ADD NOTE';
export const ADD_NOTE_RESPONSE = 'ADD NOTE RESPONSE';
export const ADD_NOTE_ERROR = 'ADD NOTE ERROR';

export const GET_NOTES = 'GET NOTES';
export const GET_NOTES_RESPONSE = 'GET NOTES RESPONSE';
export const GET_NOTES_ERROR = 'GET NOTES ERROR';

export const UPDATE_NOTE = 'UPDATE NOTE';
export const UPDATE_NOTE_RESPONSE = 'UPDATE NOTE RESPONSE';
export const UPDATE_NOTE_ERROR = 'UPDATE NOTE ERROR';

export const DELETE_NOTE = 'DELETE NOTE';
export const DELETE_NOTE_RESPONSE = 'DELETE NOTE RESPONSE';
export const DELETE_NOTE_ERROR = 'DELETE NOTE ERROR';

export const ADD_CV = 'ADD CV';
export const ADD_CV_RESPONSE = 'ADD CV RESPONSE';
export const ADD_CV_ERROR = 'ADD CV ERROR';

export const GET_CV = 'GET CV';
export const GET_CV_RESPONSE = 'GET CV RESPONSE';
export const GET_CV_ERROR = 'GET CV ERROR';

export const CLEAR_CV = 'CLEAR CV';

export const UPDATE_CV = 'UPDATE CV';
export const UPDATE_CV_RESPONSE = 'UPDATE CV RESPONSE';
export const UPDATE_CV_ERROR = 'UPDATE CV ERROR';

export const DELETE_ATTACHED = 'DELETE ATTACHED';
export const DELETE_ATTACHED_RESPONSE = 'DELETE ATTACHED RESPONSE';
export const DELETE_ATTACHED_ERROR = 'DELETE ATTACHED ERROR';

export const SHARE_FILE = 'SHARE FILE';
export const SHARE_FILE_RESPONSE = 'SHARE FILE_RESPONSE';
export const SHARE_FILE_ERROR = 'SHARE FILE ERROR';
export const ADD_IE_INVITATION = 'ADD IE INVITATION';
export const ADD_IE_INVITATION_RESPONSE = 'ADD IE INVITATION_RESPONSE';
export const ADD_IE_INVITATION_ERROR = 'ADD IE INVITATION ERROR';
export const MASSIVE_IE_INVITATION = 'MASSIVE IE INVITATION';
export const MASSIVE_IE_INVITATION_RESPONSE = 'MASSIVE IE INVITATION RESPONSE';
export const MASSIVE_IE_INVITATION_ERROR = 'MASSIVE IE INVITATION ERROR';

export const persons = {
  createPerson: (body) => ({ type: CREATE_PERSON, body }),
  createPersonResponse: (response) => ({
    type: CREATE_PERSON_RESPONSE,
    response,
  }),
  createPersonError: (error) => ({
    type: CREATE_PERSON_ERROR,
    response: error,
  }),

  getPersons: () => ({ type: FILTER_PERSONS }),
  getPersonsResponse: (response) => ({
    type: FILTER_PERSONS_RESPONSE,
    response,
  }),
  getPersonsError: (error) => ({ type: FILTER_PERSONS_ERROR, response: error }),

  updatePerson: (id, person, user_id, user) => ({
    type: UPDATE_PERSON,
    id,
    person,
    user_id,
    user,
  }),
  updatePersonResponse: (response) => ({
    type: UPDATE_PERSON_RESPONSE,
    response,
  }),
  updatePersonError: (error) => ({ type: UPDATE_PERSON_ERROR, error }),

  patchUser: (id, user) => ({ type: PATCH_USER, id, user }),
  patchUserResponse: (response) => ({ type: PATCH_USER_RESPONSE, response }),
  patchUserError: (error) => ({ type: PATCH_USER_ERROR, error }),

  deletePerson: (id) => ({ type: DELETE_PERSON, id }),
  deletePersonResponse: (response) => ({
    type: DELETE_PERSON_RESPONSE,
    response,
  }),
  deletePersonError: (error) => ({ type: DELETE_PERSON_ERROR, error }),

  selectPerson: (id) => ({ type: SELECT_PERSON, id }),
  selectPersonResponse: (person) => ({ type: SELECT_PERSON_RESPONSE, person }),
  updateFilterValues: (filter) => ({
    type: UPDATE_PERSONS_FILTER_VALUES,
    filter,
  }),
  updatePage: (pageNumber, getPersons = false) => ({
    type: UPDATE_PAGE,
    pageNumber,
    getPersons,
  }),
  updatePageSize: (pageSize, getPersons = false) => ({
    type: UPDATE_PAGE_SIZE,
    pageSize,
    getPersons,
  }),

  getAllProfiles: (search) => ({ type: GET_ALL_PROFILES, search }),
  getAllProfilesResponse: (response) => ({
    type: GET_ALL_PROFILES_RESPONSE,
    response,
  }),
  getAllProfilesError: (error) => ({ type: GET_ALL_PROFILES_ERROR, error }),

  getAllPartners: () => ({ type: GET_ALL_PARTNERS }),
  getAllPartnersResponse: (response) => ({
    type: GET_ALL_PARTNERS_RESPONSE,
    response,
  }),
  getAllPartnersError: (error) => ({ type: GET_ALL_PARTNERS_ERROR, error }),

  clearUiValue: (type, condition) => ({
    type: CLEAR_UI_VALUE,
    op_type: type,
    condition,
  }),

  clearFilter: () => ({ type: CLEAR_FILTER }),
  filterPersons: (filter, resetPage) => {
    let newFilter = {
      ...filter,
    };

    return {
      type: FILTER_PERSONS,
      filter,
      newFilter,
      resetPage,
    };
  },
  filterPersonsResponse: (response) => ({
    type: FILTER_PERSONS_RESPONSE,
    response,
  }),
  filterPersonsError: (error) => ({ type: FILTER_PERSONS_ERROR, error }),

  demographicFilter: (latlng) => ({ type: DEMOGRAPHIC_FILTER, latlng: latlng }),
  demographicFilterResponse: (response) => ({
    type: DEMOGRAPHIC_FILTER_RESPONSE,
    response,
  }),
  demographicFilterError: (error) => ({
    type: DEMOGRAPHIC_FILTER_ERROR,
    error,
  }),

  getJobCompatibility: (positions) => ({
    type: GET_JOB_COMPATIBILITY,
    positions,
  }),
  getJobCompatibilityResponse: (response) => ({
    type: GET_JOB_COMPATIBILITY_RESPONSE,
    response,
  }),
  getJobCompatibilityError: (error) => ({
    type: GET_JOB_COMPATIBILITY_ERROR,
    error,
  }),

  getAllJobCompatibility: (positions) => ({
    type: GET_ALL_JOB_COMPATIBILITY,
    positions,
  }),
  getAllJobCompatibilityResponse: (response) => ({
    type: GET_ALL_JOB_COMPATIBILITY_RESPONSE,
    response,
  }),
  getAllJobCompatibilityError: (error) => ({
    type: GET_ALL_JOB_COMPATIBILITY_ERROR,
    error,
  }),

  getCapabilities: (capabilities) => ({ type: GET_CAPABILITIES, capabilities }),
  getCapabilitiesResponse: (response) => ({
    type: GET_CAPABILITIES_RESPONSE,
    response,
  }),
  getCapabilitiesError: (error) => ({ type: GET_CAPABILITIES_ERROR, error }),

  getCountries: () => ({ type: GET_COUNTRIES }),
  getCountriesResponse: (response) => ({
    type: GET_COUNTRIES_RESPONSE,
    response,
  }),
  getCountriesError: (error) => ({ type: GET_COUNTRIES_ERROR, error }),

  getProfile: (id) => ({ type: GET_PROFILE, id }),
  getProfileResponse: (response) => ({ type: GET_PROFILE_RESPONSE, response }),
  getProfileError: (error) => ({ type: GET_PROFILE_ERROR, error }),

  getCities: (country_id) => ({ type: GET_CITIES, country_id }),
  getCitiesResponse: (response) => ({ type: GET_CITIES_RESPONSE, response }),
  getCitiesError: (error) => ({ type: GET_CITIES_ERROR, error }),

  downloadProfileReport: (ids, reportType, reportFolder, data = []) => ({
    type: DOWNLOAD_PERSON_REPORT,
    ids,
    reportType,
    reportFolder,
    data,
  }),
  downloadProfileReportResponse: (response) => ({
    type: DOWNLOAD_PERSON_REPORT_RESPONSE,
    response,
  }),
  downloadProfileReportError: (error) => ({
    type: DOWNLOAD_PERSON_REPORT_ERROR,
    error,
  }),

  sendPersonReportEmail: ({ value, id_invitation, campaign }) => ({
    type: SEND_PERSON_REPORT_EMAIL,
    value,
    id_invitation,
    campaign,
  }),
  sendPersonReportEmailResponse: (response) => ({
    type: SEND_PERSON_REPORT_EMAIL_RESPONSE,
    response,
  }),
  sendPersonReportEmailError: (error) => ({
    type: SEND_PERSON_REPORT_EMAIL_ERROR,
    error,
  }),

  addRemovePersonToSelection: (result) => ({
    type: ADD_REMOVE_PERSON_TO_SELECTION,
    result,
  }),
  updateToolboxFilter: (key, filter) => ({
    type: UPDATE_TOOLBOX_FILTER,
    key,
    filter,
  }),
  cleanToolboxFilter: () => ({ type: CLEAN_TOOLBOX_FILTER }),
  toggleSelectionAll: () => ({ type: TOGGLE_SELECTION_ALL }),
  clearSelection: () => ({ type: CLEAR_SELECTION }),
  clearPersonCustomFilters: () => ({ type: CLEAR_PERSON_CUSTOM_FILTER }),

  migrateCampaign: (file) => ({ type: MIGRATE_CAMPAIGN, file }),
  migrateCampaignResponse: (response) => ({
    type: MIGRATE_CAMPAIGN_RESPONSE,
    response,
  }),
  migrateCampaignError: (error) => ({ type: MIGRATE_CAMPAIGN_ERROR, error }),

  trendExport: (data, capabilities) => ({
    type: TREND_EXPORT,
    data,
    capabilities,
  }),
  trendExportResponse: (response) => ({
    type: TREND_EXPORT_RESPONSE,
    response,
  }),
  trendExportError: (error) => ({ type: TREND_EXPORT_ERROR, error }),

  saveSearchInput: (value) => ({ type: SAVE_SEARCH_INPUT, value }),

  logout: () => ({ type: LOGOUT }),

  selectAll: (flag) => ({ type: SELECT_ALL, flag }),

  loadAll: (page) => ({ type: LOAD_ALL, page }),

  saveAll: (results) => ({ type: SAVE_ALL, results }),

  allLoaded: () => ({ type: ALL_LOADED }),

  getUserPlayerInformation: (id_invitation) => ({
    type: GET_USER_PLAYER_INFORMATION,
    id_invitation,
  }),
  getUserPlayerInformationResponse: (response) => ({
    type: GET_USER_PLAYER_INFORMATION_RESPONSE,
    response,
  }),

  addNote: (body) => ({
    type: ADD_NOTE,
    body,
  }),
  addNoteResponse: (response) => ({
    type: ADD_NOTE_RESPONSE,
    response,
  }),
  addNoteError: (error) => ({
    type: ADD_NOTE_ERROR,
    error,
  }),

  getNotes: (id_user_player) => ({
    type: GET_NOTES,
    id_user_player,
  }),
  getNotesResponse: (response) => ({
    type: GET_NOTES_RESPONSE,
    response,
  }),
  getNotesError: (error) => ({
    type: GET_NOTES_ERROR,
    error,
  }),

  updateNote: (body) => ({
    type: UPDATE_NOTE,
    body,
  }),
  updateNoteResponse: (response) => ({
    type: UPDATE_NOTE_RESPONSE,
    response,
  }),
  updateNoteError: (error) => ({
    type: UPDATE_NOTE_ERROR,
    error,
  }),

  deleteNote: (body) => ({
    type: DELETE_NOTE,
    body,
  }),
  deleteNoteResponse: (response) => ({
    type: DELETE_NOTE_RESPONSE,
    response,
  }),
  deleteNoteError: (error) => ({
    type: DELETE_NOTE_ERROR,
    error,
  }),

  addCV: (body) => ({ type: ADD_CV, body }),
  addCVResponse: (response) => ({ type: ADD_CV_RESPONSE, response }),
  addCVError: (error) => ({ type: ADD_CV_ERROR, error }),

  getCV: (id_user_player) => ({ type: GET_CV, id_user_player }),
  getCVResponse: (response) => ({ type: GET_CV_RESPONSE, response }),
  getCVError: (error) => ({ type: GET_CV_ERROR, error }),

  clearCV: () => ({ type: CLEAR_CV }),

  // updateCV: (body) => ({ type: UPDATE_CV, body }),
  // updateCVResponse: (response) => ({ type: UPDATE_CV_RESPONSE, response }),
  // updateCVError: (error) => ({ type: UPDATE_CV_ERROR, error }),

  deleteAttached: (id_user_player, fileId) => ({
    type: DELETE_ATTACHED,
    id_user_player,
    fileId,
  }),
  deleteAttachedResponse: (response) => ({
    type: DELETE_ATTACHED_RESPONSE,
    response,
  }),
  deleteAttachedError: (error) => ({ type: DELETE_ATTACHED_ERROR, error }),

  shareFile: (body) => ({ type: SHARE_FILE, body }),
  shareFileResponse: (response) => ({ type: SHARE_FILE_RESPONSE, response }),
  shareFileError: (error) => ({ type: SHARE_FILE_ERROR, error }),
  addIeInvitation: (body) => ({ type: ADD_IE_INVITATION, body }),
  addIeInvitationResponse: () => ({
    type: ADD_IE_INVITATION_RESPONSE,
  }),
  addIeInvitationError: (error) => ({ type: ADD_IE_INVITATION_ERROR, error }),
  massiveIeInvitation: (body) => ({ type: MASSIVE_IE_INVITATION, body }),
  massiveIeInvitationResponse: () => ({
    type: MASSIVE_IE_INVITATION_RESPONSE,
  }),
  massiveIeInvitationError: (error) => ({
    type: MASSIVE_IE_INVITATION_ERROR,
    error,
  }),
};
