import React, { Component } from 'react';
import './BaproScreen.css';
import UploadFiles from '../../../components/common/UploadFiles/UploadFiles';
import { I18n } from 'react-redux-i18n';
import Button from '../../../components/common/Button';
import { notify } from 'react-notify-toast';
import {
  SnackBar,
  successMessage,
  errorMessage,
} from '../../../utils/snackbar';
import { withRouter } from 'react-router-dom';

class BaproScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: {
        answer: {
          inputValue: '',
        },
      },
      bapro: {
        text: false,
        video: false,
      },
      attachedFile: [],
    };
  }

  componentDidMount() {
    this.props.getBaproFiles({
      id_user_player: this.props.user.player.id,
      partner: 'bapro',
    });

    this.checkUploadedFiles();

    // Manipulación del historial del navegador
    this.disableGoBack();
    window.addEventListener('popstate', this.handleBackButton);
  }

  componentWillUnmount() {
    // Limpiar la manipulación del historial
    window.removeEventListener('popstate', this.handleBackButton);
  }

  disableGoBack = () => {
    // Agregar una entrada al historial y luego reemplazarla con la misma para evitar el retroceso
    window.history.pushState(null, null, window.location.href);
    window.history.pushState(null, null, window.location.href);
  };

  handleBackButton = (event) => {
    event.preventDefault();
    this.disableGoBack();
  };

  checkUploadedFiles() {
    const { baproFiles } = this.props;
    let hasTextFile =
      baproFiles.length &&
      baproFiles.find((file) => file.directory === 'text/plain');
    let hasVideoFile =
      baproFiles.length &&
      baproFiles.find((file) => file.directory.includes('video'));

    if (hasTextFile) {
      const base64Content = hasTextFile.path.split(',')[1];
      const textContent = decodeURIComponent(escape(atob(base64Content)));
      this.setState((prevState) => ({
        values: {
          ...prevState.values,
          answer: {
            ...prevState.values.answer,
            inputValue: textContent,
          },
        },
        bapro: {
          ...prevState.bapro,
          text: true,
        },
      }));
    }

    if (hasVideoFile && hasTextFile) {
      this.setState((prevState) => ({
        bapro: {
          ...prevState.bapro,
          text: true,
          video: true,
        },
        attachedFile: [hasVideoFile],
      }));
    } else if (hasVideoFile) {
      this.setState((prevState) => ({
        bapro: {
          ...prevState.bapro,
          video: true,
        },
        attachedFile: [hasVideoFile],
      }));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.baproFiles !== this.props.baproFiles) {
      this.checkUploadedFiles();
    }

    if (prevProps.uploadFilesStatus !== this.props.uploadFilesStatus) {
      this.showNotifications();
    }
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      values: {
        ...prevState.values,
        answer: {
          ...prevState.values.answer,
          [name]: value,
        },
      },
    }));
  };

  sendTxtFile = () => {
    const fileName = `${this.props.user.full_name
      .split(' ')
      .join('')}-response.txt`;
    const lastModifiedDate = new Date();

    const textContent = this.state.values.answer.inputValue;
    const fileObj = new Blob([textContent]);

    const file = new File([fileObj], fileName, {
      lastModified: lastModifiedDate.getTime(),
      type: 'text/plain',
    });

    this.setState({ uploadedFile: file }, () => {
      this.uploadFileToServer();
    });
  };

  uploadFileToServer = () => {
    const file = this.state.uploadedFile;

    if (!file) {
      console.error('No file to upload.');
      return;
    }

    this.props.uploadFile({
      id_user_player: this.props.user.player.id,
      curriculum: file,
      partner: 'bapro',
    });
  };

  sendVideoFile = async (file) => {
    await this.uploadVideoToServer(file[0]);
  };

  uploadVideoToServer = async (fileToSend) => {
    if (!fileToSend) {
      console.error('No video file to upload.');
      return;
    }

    if (typeof this.props.uploadFile === 'function') {
      this.props.uploadFile({
        id_user_player: this.props.user.player.id,
        curriculum: fileToSend,
        partner: 'bapro',
      });
    } else {
      console.error('uploadFile is not a function');
    }
  };

  showNotifications() {
    if (this.props.uploadFilesStatus.success) {
      notify.show(
        <SnackBar
          icon={'priority'}
          title={'Excelente'}
          body={'Archivo cargado con éxito'}
          type={'success'}
        />,
        'custom',
        5000,
        successMessage,
      );
    }
    if (this.props.uploadFilesStatus.error) {
      notify.show(
        <SnackBar
          icon={'report'}
          title={'Error'}
          body={'No se pudo cargar el archivo'}
          type={'error'}
        />,
        'custom',
        5000,
        errorMessage,
      );
    }
  }

  render() {
    const { bapro, values } = this.state;
    const { inputValue } = values.answer;

    return (
      <div>
        <h2>{`Bienvenido/a ${this.props.user?.full_name}`}</h2>
        <div className="bapro-questions-container">
          <div className="card bapro-mail-container">
            <h3>Consigna email</h3>
            <h5>
              Redacta un correo dirigido a la tripulación del barco, expresando
              tu agradecimiento por el trabajo realizado y motivándolos a seguir
              esforzándose. El texto debe ser de no más de 350 caracteres.
            </h5>
            <div className="add-answer">
              <textarea
                placeholder={'Redacta tu respuesta'}
                value={this.state.values.answer.inputValue}
                onChange={(e) => {
                  e.preventDefault();
                  this.setState({
                    ...this.state,
                    values: {
                      ...this.state.values,
                      answer: { inputValue: e.target.value },
                    },
                    postLength: e.target.value.length,
                  });
                }}
                disabled={
                  this.state.bapro.text ||
                  (this.props.uploadFilesStatus.loading &&
                    !this.state.bapro.text) ||
                  (this.props.uploadFilesStatus.loading &&
                    this.state.bapro.text) ||
                  (this.props.getBaproFile.loading && !this.state.bapro.text) ||
                  (this.props.getBaproFile.success && this.state.bapro.text)
                }
              ></textarea>
              <div className="send">
                {!this.props.user.user_admin ? (
                  <span
                    className={`${
                      this.state.values.answer.inputValue.length > 350
                        ? 'error-note'
                        : ''
                    }`}
                  >
                    {this.state.values.answer.inputValue.length}/350{' '}
                    {I18n.t('persons.characters')}
                  </span>
                ) : (
                  <div></div>
                )}
                <Button
                  className="primary"
                  text={I18n.t('persons.send')}
                  disabled={
                    this.state.bapro.text ||
                    (this.props.uploadFilesStatus.loading &&
                      !this.state.bapro.text) ||
                    (this.props.uploadFilesStatus.loading &&
                      this.state.bapro.text) ||
                    (this.props.getBaproFile.loading &&
                      !this.state.bapro.text) ||
                    (this.props.getBaproFile.success &&
                      this.state.bapro.text) ||
                    this.state.values.answer.inputValue.length > 350
                  }
                  iconClass="send"
                  iconButton
                  onClick={this.sendTxtFile}
                />
              </div>
            </div>
          </div>
          <div className="card bapro-video-container">
            <h3>Consigna video</h3>
            <h5>
              Realiza un video de presentación en el que nos cuentes cuáles son
              las características que consideras destacan tu postulación para el
              puesto de Ejecutivo/a de Cuentas. Este video deberá tener una
              duración máxima de 1 minuto y te pedimos que lo grabes en baja
              resolución.
            </h5>
            <UploadFiles
              buttonIcon="attach_file"
              withPreview={false}
              extensions={'.mp4,.MP4,.mov,.MOV,.mkv,.MKV'}
              title={I18n.t('persons.CV.title')}
              textButton={I18n.t('persons.CV.textButton')}
              textButtonWithFile={I18n.t('persons.CV.textButtonWithFile')}
              messageNoFile={I18n.t('persons.CV.messageNoFile')}
              icon="attach_file"
              rotateIcon={45}
              maxSize={5000000}
              disabled={
                this.state.bapro.video ||
                (this.props.uploadFilesStatus.loading &&
                  !this.state.bapro.video) ||
                (this.props.getBaproFile.loading && !this.state.bapro.video) ||
                (this.props.getBaproFile.success && this.state.bapro.video)
              }
              file={this.state.attachedFile}
              deleteAttached={this.handleDeleteAttached}
              onChange={(file) => this.sendVideoFile(file)}
              // loading={this.props.uploadFilesStatus.loading}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(BaproScreen);
