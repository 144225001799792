import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { I18n } from 'react-redux-i18n';
import './UpdateCredits.css';
import Button from '../../../components/common/Button';
import Input from '../../../components/common/Input';
import { notify } from 'react-notify-toast';
import { success, error } from '../../../utils/modals';
import {
  errorMessage,
  successMessage,
  SnackBar,
} from '../../../utils/snackbar';
import RadioButton from '../../../components/common/RadioButton/RadioButton';
import { NumberLabel } from '../../../components/common/NumberLabel/NumberLabel';

const TX_TYPES = {
  ADD: 'ADD',
  REMOVE: 'REMOVE',
};
class UpdateCredits extends Component {
  constructor(props) {
    super(props);

    this.state = {
      values: {
        amount: { inputValue: '' },
        transactionType: { inputValue: TX_TYPES.ADD },
      },
      cantSubstractError: false,
      updated: false,
      adding: false,
    };
  }

  getValidAmount = (amount, adding) => {
    const {
      isSuperDashboard,
      affiliateCreditsAvaible,
      creditsAvailable,
      isAdmin,
    } = this.props;
    let validAmount = amount;
    if (amount < 0) {
      validAmount = 0;
    }
    if (!adding) {
      if (isSuperDashboard) {
        if (validAmount >= affiliateCreditsAvaible) {
          return affiliateCreditsAvaible;
        } else {
          return validAmount;
        }
      } else {
        if (validAmount >= creditsAvailable) {
          return creditsAvailable;
        } else {
          return validAmount;
        }
      }
    } else {
      if (validAmount > creditsAvailable && !isAdmin) {
        return creditsAvailable;
      } else {
        return validAmount;
      }
    }
  };

  handleInputChange = (key, value) => {
    const { transactionType, amount } = this.state.values;
    const {
      creditsAvailable,
      isAdmin,
      isSuperDashboard,
      affiliateCreditsAvaible,
    } = this.props;
    const adding =
      key !== 'transactionType'
        ? transactionType?.inputValue === TX_TYPES.ADD
        : value === TX_TYPES.ADD;
    this.setState({
      ...this.state,
      values: {
        ...this.state.values,
        transactionType: {
          inputValue:
            key === 'transactionType' ? value : transactionType.inputValue,
        },
        amount: {
          inputValue:
            key === 'amount'
              ? this.getValidAmount(value, adding)
              : this.getValidAmount(amount.inputValue, adding),
        },
      },
      cantSubstractError: adding && value > creditsAvailable && !isAdmin,
      negativeValueError: value < 0,
      cantRemoveMoreThanAcquired:
        !adding &&
        (isSuperDashboard
          ? value > affiliateCreditsAvaible
          : value > creditsAvailable),
    });
  };

  handleUpdateCreditsConfirm = (e) => {
    e.preventDefault();
    const adding =
      this.state.values.transactionType.inputValue === TX_TYPES.ADD;
    const amount = this.state.values.amount.inputValue * (adding ? 1 : -1);
    this.props.updateCredits(this.props.clientId, amount);
    this.setState({
      ...this.state,
      updated: true,
      adding,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    // De esta forma se que se ejecutó la actualizacion de creditos y
    // garantizo mostrar el snackbar correspondiente 1 unica vez.
    if (
      prevProps.updateCreditsStatus !== this.props.updateCreditsStatus &&
      this.state.updated &&
      this.props.updateCreditsStatus.loading === false
    ) {
      this.checkNotificationsRenders(this.state.adding);
    }
  }

  checkNotificationsRenders(action) {
    this.setState({
      ...this.state,
      updated: false,
      adding: !this.state.adding,
    });

    // Agrego creditos
    if (this.props.updateCreditsStatus?.success && action) {
      notify.show(
        <SnackBar
          icon={'priority'}
          title={I18n.t('common.excellent')}
          body={I18n.t('clients.notifications.addCreditsSuccess')}
          type="success"
          iconFilled
        />,
        'custom',
        5000,
        successMessage,
      );
      this.props.history.push('/main/clients');
      this.props.clearUiValue('updateCreditsStatus', 'success');
    }
    // Resto creditos
    if (this.props.updateCreditsStatus?.success && !action) {
      notify.show(
        <SnackBar
          icon={'priority'}
          title={I18n.t('common.excellent')}
          body={I18n.t('clients.notifications.subCreditsSuccess')}
          type="success"
          iconFilled
        />,
        'custom',
        5000,
        successMessage,
      );
      this.props.history.push('/main/clients');
      this.props.clearUiValue('updateCreditsStatus', 'success');
    }
    // Error al agregar
    if (this.props.updateCreditsStatus?.error && action) {
      notify.show(
        <SnackBar
          icon={'report'}
          title={I18n.t('clients.notifications.error')}
          body={I18n.t('clients.notifications.addCreditsError')}
          type="error"
          iconFilled
        />,
        'custom',
        5000,
        errorMessage,
      );
      this.props.clearUiValue('updateCreditsStatus', 'error');
    }

    // Error al quitar
    if (
      this.props.updateCreditsStatus?.error &&
      this.props.updateCreditsStatus?.message !== 'no_remove_credits' &&
      !action
    ) {
      notify.show(
        <SnackBar
          icon={'report'}
          title={I18n.t('clients.notifications.error')}
          body={I18n.t('clients.notifications.subCreditsError')}
          type="error"
          iconFilled
        />,
        'custom',
        5000,
        errorMessage,
      );
      this.props.clearUiValue('updateCreditsStatus', 'error');
    }

    // Error al quitar
    if (
      this.props.updateCreditsStatus?.error &&
      this.props.updateCreditsStatus?.message === 'no_remove_credits' &&
      !action
    ) {
      notify.show(
        <SnackBar
          icon={'report'}
          title={I18n.t('clients.notifications.error')}
          body={I18n.t('clients.notifications.no_remove_credits')}
          type="error"
          iconFilled
        />,
        'custom',
        5000,
        errorMessage,
      );
      this.props.clearUiValue('updateCreditsStatus', 'error');
    }
  }

  render() {
    // this.checkNotificationsRenders();
    const { amount } = this.state.values;
    return (
      <div
        className={`update-credit-main ${
          this.props.clients_screen !== 'detail' &&
          this.props.clients_screen !== 'edit-client' &&
          this.props.clients_screen !== 'edit-branch' &&
          'flex-container'
        }`}
      >
        <div
          className={`update-credits ${
            this.props.clients_screen !== 'detail' &&
            this.props.clients_screen !== 'edit-client' &&
            this.props.clients_screen !== 'edit-branch' &&
            'card'
          }`}
        >
          <div className="card-header">
            <div className="back">
              {this.props.clients_screen !== 'detail' &&
                this.props.clients_screen !== 'edit-client' &&
                this.props.clients_screen !== 'edit-branch' && (
                  <Button
                    className={'bg-primary text-white p-0'}
                    iconButton={true}
                    iconClass={'fa fa-arrow-left fa-lg'}
                    onClick={() => this.props.history.push('/main/client')}
                  />
                )}
              <div className="container-title">
                <p>{I18n.t('clients.addCredits')}</p>
              </div>
              <div className="input-text-container">
                {/* <label className="code-quantity-label">
                  {I18n.t('credits.code_quantity')}
                </label> */}
                {this.props.clients_screen !== 'detail' &&
                  this.props.clients_screen !== 'edit-branch' && (
                    <label>
                      <span>
                        {I18n.t('campaigns.credits.acquired')}:{' '}
                        {this.props.client.credits}
                      </span>
                      {/* <NumberLabel
                        value={
                          this.state.values.transactionType.inputValue ===
                          TX_TYPES.ADD
                            ? this.props.creditsAvailable
                            : this.props.isSuperDashboard
                            ? this.props.affiliateCreditsAvaible
                            : this.props.creditsAvailable
                        }
                      /> */}
                    </label>
                  )}
              </div>
            </div>
          </div>
          <form id="update-credits-form">
            <div className="form-body user-input">
              <div className="form-group flex-dcolumn">
                <Input
                  name="amount"
                  type="number"
                  autocomplete="off"
                  value={amount}
                  change={this.handleInputChange}
                  placeholder={I18n.t('clients.credits_acquired_placeholder')}
                />
              </div>
            </div>
            <div className="form-body radio-select">
              <div className="form-group flex-drow">
                <RadioButton
                  value={this.state.values.transactionType.inputValue}
                  change={this.handleInputChange}
                  label={I18n.t('credits.add')}
                  name={'transactionType'}
                  setValue={TX_TYPES.ADD}
                />
                <RadioButton
                  value={this.state.values.transactionType.inputValue}
                  change={this.handleInputChange}
                  label={I18n.t('credits.remove')}
                  name={'transactionType'}
                  setValue={TX_TYPES.REMOVE}
                />
              </div>
            </div>
            {this.state.cantSubstractError && (
              <div className="text-warning update-credits-error">
                {I18n.t('credits.cantSubstractError')}
              </div>
            )}
            {this.state.negativeValueError && (
              <div className="text-warning update-credits-error">
                {I18n.t('credits.negativeValueError')}
              </div>
            )}
            {this.state.cantRemoveMoreThanAcquired && (
              <div className="text-warning update-credits-error">
                {I18n.t('credits.cantRemoveMoreThanAcquired')}
              </div>
            )}
            <div className="form-footer">
              <Button
                className="secondary"
                text={I18n.t('credits.confirm')}
                onClick={this.handleUpdateCreditsConfirm}
                disabled={
                  this.state.values.amount.inputValue.length < 1 ||
                  isNaN(this.state.values.amount.inputValue)
                }
                iconButton
                iconClass={'check'}
              />
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withRouter(UpdateCredits);
