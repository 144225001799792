import {
  GET_FILTER_DATES,
  GET_CREDITS_HISTORY,
  GET_CREDITS_HISTORY_RESPONSE,
  GET_CREDITS_HISTORY_ERROR,
  UPDATE_PAGE_CREDITS_HISTORY,
  UPDATE_PAGE_SIZE_CREDITS_HISTORY,
  LOGOUT,
  UPDATE_FILTER_VALUES_CREDITS_HISTORY,
  CREDIT_FILTER_CLEAR,
  SET_CREDIT_OPTION,
  GET_ACTUAL_LOCATION,
} from './common.actions';
import { REHYDRATE } from 'redux-persist/lib/constants';

/* response: {
    links: {next: null, previous: null}
    count: 0
    total_pages: 1
    current: 1
    results: []
} */
const initialState = {
  creditsHistory: [],
  getCreditsHistoryStatus: { loading: false, error: false, success: false },
  creditsfirstusers: [],
  currentPage: 1,
  totalItems: 1,
  totalPages: 1,
  pageSize: 10,
  start_date: 1,
  filterValues: {
    ordering: '-created_at',
    search: { inputValue: [] },
    branchs: { inputValue: [] },
    accounts: { inputValue: [] },
  },
  filterDates: { start_value: '', end_value: '' },
  creditFilterClear: true,
  actualLocation: '',
};

const common = (state = initialState, action) => {
  switch (action.type) {
    case SET_CREDIT_OPTION:
      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          search: action.state.search,
          branchs: action.state.branchs,
          accounts: action.state.accounts,
        },
      };
    case CREDIT_FILTER_CLEAR:
      return {
        ...state,
        creditFilterClear: action.value,
      };
    case GET_FILTER_DATES:
      return {
        ...state,
        filterDates: {
          start_value: action.filterDates.start_value, //action.payload.start_value
          end_value: action.filterDates.end_value, //action.payload.end_value
        },
      };
    case GET_CREDITS_HISTORY:
      return {
        ...state,
        getCreditsHistoryStatus: {
          loading: true,
          error: false,
          success: false,
        },
      };
    case GET_CREDITS_HISTORY_RESPONSE:
      const { count, total_pages, current, results, creditsfirstuser } =
        action.response;
      return {
        ...state,
        getCreditsHistoryStatus: {
          loading: false,
          error: false,
          success: true,
        },
        creditsHistory: results,
        currentPage: current,
        totalItems: count,
        totalPages: total_pages,
        creditsfirstusers: creditsfirstuser,
        // pageSize: action.pageSize || state.pageSize,
      };
    case GET_CREDITS_HISTORY_ERROR:
      return {
        ...state,
        getCreditsHistoryStatus: {
          loading: false,
          error: true,
          success: false,
        },
      };

    case UPDATE_FILTER_VALUES_CREDITS_HISTORY:
      return {
        ...state,
        filterValues: { ...state.filterValues, ...action.filter },
      };
    case UPDATE_PAGE_CREDITS_HISTORY:
      return { ...state, currentPage: action.pageNumber };
    case UPDATE_PAGE_SIZE_CREDITS_HISTORY:
      return { ...state, pageSize: action.pageSize, current: 1 };

    case LOGOUT:
      return initialState;

    case REHYDRATE:
      let incoming = action.payload;
      if (incoming) {
        return {
          ...state,
          getCreditsHistoryStatus: {
            loading: false,
            error: false,
            success: false,
          },
          filterValues: initialState.filterValues,
        };
      }
      return state;

    case GET_ACTUAL_LOCATION:
      return {
        ...state,
        actualLocation: action.location,
      };

    default:
      return state;
  }
};

export default common;
