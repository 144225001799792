import { connect } from 'react-redux';
import ExternalLoginScreen from '../components/ExternalLoginScreen/ExternalLoginScreen';
import actions from '../../actions';
import { withRouter } from 'react-router';

const mapStateToProps = (state) => ({
  loginStatus: state.session.loginStatus,
  response: state.session.response,

  // ! FRONT QUICK FIX !
  tooManyLoginAttempts: state.session.tooManyLoginAttempts,

  // AUTHENTICATION THROUGH EXTERNAL USER AUTHENTICATION
  userservicelogin: state.session.userservicelogin,
});

const mapDispatchToProps = (dispatch) => ({
  login: (email, password) => {
    dispatch(actions.session.login(email, password));
  },
  openIdConnect: (access_token, expires_in) => {
    dispatch(actions.session.openIdAuthResponse(access_token, expires_in));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ExternalLoginScreen));
