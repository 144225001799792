import React, { Component } from 'react';
import './Loader.scss';

class Loader extends Component {
  static defaultProps;

  render() {
    return (
      <div className={'loader'}>
        {this.props.mini ? (
          <div className="nwm-loader-mini">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        ) : (
          <div className="nwm-loader">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        )}
      </div>
    );
  }
}

Loader.defaultProps = {};
Loader.propTypes = {};

export default Loader;
