import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-redux-i18n';
import './RadioButton.css';

class RadioButton extends Component {
  static defaultProps;

  render() {
    return (
      <div
        className={`radio-button ${this.props.disabled && 'disabled'}`}
        key={this.props.label}
      >
        <button
          type={'button'}
          onClick={() => {
            !this.props.disabled &&
              this.props.change(this.props.name, this.props.setValue);
          }}
        >
          {this.props.value === this.props.setValue && (
            <span className="inner" />
          )}
        </button>
        {this.props.label && (
          <label
            className={
              (this.props.required ? 'required' : '') +
              (this.props.boldLabel ? 'bold' : '')
            }
          >
            <Translate value={this.props.label} />
          </label>
        )}
      </div>
    );
  }
}

RadioButton.defaultProps = {};
RadioButton.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.bool,
  change: PropTypes.func,
  setValue: PropTypes.any,
  required: PropTypes.bool,
  boldLabel: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default RadioButton;
