import {
  GET_JOBS,
  GET_JOBS_RESPONSE,
  GET_JOBS_ERROR,
  CREATE_JOB,
  CREATE_JOB_RESPONSE,
  CREATE_JOB_ERROR,
  UPDATE_JOB,
  UPDATE_JOB_RESPONSE,
  UPDATE_JOB_ERROR,
  DELETE_JOB,
  DELETE_JOB_RESPONSE,
  DELETE_JOB_ERROR,
  CLEAN_JOB_ERROR,
  SELECT_JOB,
  UPDATE_JOBS_FILTER_VALUES,
  CLEAR_UI_VALUE_JOBS,
  UPDATE_PAGE,
  UPDATE_PAGE_SIZE,
  LOGOUT,
} from './jobs.actions';
import { REHYDRATE } from 'redux-persist/lib/index';
import {
  GET_JOB_COMPATIBILITY,
  GET_JOB_COMPATIBILITY_ERROR,
  GET_JOB_COMPATIBILITY_RESPONSE,
} from '../persons/persons.actions';

const initialState = {
  count: 0,
  current: 1,
  total_pages: 1,
  page_size: 10,
  desc: false,
  newJob: false,
  createCampaing: {
    idPosition: null,
    ok: false,
  },
  filterValues: {
    ordering: '',
    page: 0,
    page_size: 100,
    start_date: '',
    end_date: '',
  },
  profiles: [],
  selectedJob: {},
  jobs: [],
  jobsStatus: { loading: false, error: false, success: false },
  jobsCompatibilityStatus: { loading: false, error: false, success: false },
  create: { loading: false, error: false, success: false, errorCode: {} },
  update: { loading: false, error: false, success: false },
  delete: { loading: false, error: false, success: false },
};

const jobs = (state = initialState, action) => {
  switch (action.type) {
    /** Get jobs */
    case GET_JOBS:
      return {
        ...state,
        jobsStatus: { loading: true, error: false, success: false },
      };
    case GET_JOBS_RESPONSE:
      return {
        ...state,
        jobsStatus: { loading: false, error: false, success: true },
        ...action.response,
        jobs: action.response.results,
      };
    case GET_JOBS_ERROR:
      return {
        ...state,
        jobsStatus: { loading: false, error: true, success: false },
        ...action.response,
      };

    /** Create a job */
    case CREATE_JOB:
      return {
        ...state,
        create: { loading: true, error: false, success: false },
      };
    case CREATE_JOB_RESPONSE:
      if (action.typeCreate === 'campaign')
        return {
          ...state,
          create: { loading: false, error: false, success: true },
          createCampaing: {
            idPosition: action.response.id,
            ok: true,
          },
          ...action.response,
        };
      return {
        ...state,
        create: { loading: false, error: false, success: true },
        ...action.response,
      };
    case CREATE_JOB_ERROR:
      return {
        ...state,
        create: {
          loading: false,
          error: true,
          success: false,
          errorCode: action.response.data,
        },
      };

    /** Update a job */
    case UPDATE_JOB:
      return {
        ...state,
        update: { loading: true, error: false, success: false },
      };
    case UPDATE_JOB_RESPONSE:
      if (action.typeUpdate === 'campaign') {
        return {
          ...state,
          update: {
            loading: false,
            error: false,
            success: true,
          },
          createCampaing: {
            idPosition: action.response.id,
            ok: true,
          },
        };
      }
      return {
        ...state,
        update: {
          loading: false,
          error: false,
          success: true,
        },
      };
    case UPDATE_JOB_ERROR:
      return {
        ...state,
        update: {
          loading: false,
          error: true,
          success: false,
          errorCode: action.error.data,
        },
      };

    /** Job compatibility */
    case GET_JOB_COMPATIBILITY:
      return {
        ...state,
        jobsCompatibilityStatus: {
          loading: true,
          error: false,
          success: false,
        },
      };
    case GET_JOB_COMPATIBILITY_RESPONSE:
      return {
        ...state,
        jobsCompatibilityStatus: {
          loading: false,
          error: false,
          success: true,
        },
      };
    case GET_JOB_COMPATIBILITY_ERROR:
      return {
        ...state,
        jobsCompatibilityStatus: {
          loading: false,
          error: true,
          success: false,
        },
      };

    /** Delete a job */
    case DELETE_JOB:
      return {
        ...state,
        delete: { loading: true, error: false, success: false },
      };
    case DELETE_JOB_RESPONSE:
      return {
        ...state,
        delete: { loading: false, error: false, success: true },
      };
    case DELETE_JOB_ERROR:
      return {
        ...state,
        delete: { loading: false, error: true, success: false },
      };

    case CLEAN_JOB_ERROR:
      return {
        ...state,
        create: { loading: false, error: false, success: false, errorCode: {} },
        update: { loading: false, error: false, success: false },
        delete: { loading: false, error: false, success: false },
      };

    /**  */
    case SELECT_JOB:
      return {
        ...state,
        selectedJob: state.jobs.filter((t) => t.id === action.id)[0],
      };

    /**  */
    case UPDATE_JOBS_FILTER_VALUES:
      return {
        ...state,
        filterValues: action.filterValues || state.filterValues,
        desc: !state.desc,
      };

    /**  */
    case UPDATE_PAGE:
      return { ...state, current: action.pageNumber };
    case UPDATE_PAGE_SIZE:
      return { ...state, page_size: action.pageSize, current: 1 };

    /**  */
    case CLEAR_UI_VALUE_JOBS:
      return {
        ...state,
        [action.op_type]: {
          ...action.op_type,
          [action.condition]: false,
        },
      };

    /**  */
    case LOGOUT:
      return { ...state, ...initialState };

    /**  */
    case REHYDRATE:
      let incoming = action.payload;
      if (incoming) {
        return {
          ...state,
          ...incoming.jobs,
          jobsStatus: { loading: false, error: false, success: false },
          jobsCompatibilityStatus: {
            loading: false,
            error: false,
            success: false,
          },
          create: { loading: false, error: false, success: false },
          update: { loading: false, error: false, success: false },
          delete: { loading: false, error: false, success: false },
        };
      }
      return state;

    default:
      return state;
  }
};

export default jobs;
