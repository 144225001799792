import { connect } from 'react-redux';

import actions from '../../actions';
import { withRouter } from 'react-router';
import BaproLoginScreen from '../components/BaproLoginScreen/BaproLoginScreen';

const mapStateToProps = (state) => ({
  loginStatus: state.session.loginStatus,
  response: state.session.response,
  recovery: state.session.emailStatus,

  // ! FRONT QUICK FIX !
  tooManyLoginAttempts: state.session.tooManyLoginAttempts,

  // AUTHENTICATION THROUGH EXTERNAL USER AUTHENTICATION
  userservicelogin: state.session.userservicelogin,
});

const mapDispatchToProps = (dispatch) => ({
  baproLogin: (email, password) => {
    dispatch(actions.session.baproLogin(email, password));
  },
  googleLogin: (code) => {
    dispatch(actions.session.googleLogin(code));
  },
  openIdConnect: (access_token, expires_in) => {
    dispatch(actions.session.openIdAuthResponse(access_token, expires_in));
  },
  loginFormReset: () => {
    dispatch(actions.session.loginFormReset());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(BaproLoginScreen));
